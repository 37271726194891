import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs'
import { environment } from '../../environments/environment'

@Injectable({
  providedIn: 'root',
})
export class ApprovePlanningService {
  constructor(private http: HttpClient) {}

  public load(caregiverId: any, data: {}): Observable<[]> {
    return this.http.get<[]>(
      `${environment.api}/approve-planning/${caregiverId}`,
      {
        params: {
          ...data,
        },
      }
    )
  }

  public store(caregiverId: any, data: {}): Observable<[]> {
    return this.http.post<[]>(
      `${environment.api}/approve-planning/${caregiverId}`,
      data
    )
  }

  public update(id: any, comment: string): Observable<[]> {
    return this.http.put<[]>(`${environment.api}/approve-planning/${id}`, {
      comment,
    })
  }

  public delete(id: any): Observable<[]> {
    return this.http.delete<[]>(`${environment.api}/approve-planning/${id}`)
  }
}
