<app-loader [loading]="loading"></app-loader>

<div class="content" *ngIf="!loading">
  <header class="content-header">
    <span>Alle Bewerber</span>

    <div class="content-header-buttons">
      <a
        routerLink="/caregivers/new"
        *ngIf="authService.can('Betreuungskraft.Betreuungskraft erstellen')"
      >
        <button
          pButton
          label="Neuer Bewerber"
          type="button"
          icon="pi pi-plus"
          iconPos="left"
          class="p-button-outlined"
        ></button>
      </a>
      <div
        class="table-search-container"
        [class.searchfield-active]="globalFilterValue != ''"
      >
        <div class="p-input-icon-left">
          <i class="pi pi-search"></i>
          <input
            pInputText
            (input)="filterTableGlobal($event)"
            placeholder="Suche..."
            [(ngModel)]="globalFilterValue"
          />
        </div>
      </div>
    </div>
  </header>
</div>

<div class="content" style="margin-bottom: 0" *ngIf="!loading">
  <div class="content-body content-body-full-height-with-tabs without-padding">
    <p-table
      #dt
      [value]="caregivers"
      [globalFilterFields]="[
        'id',
        'first_name',
        'last_name',
        'zipcode',
        'city',
        'current_job_scope.job_scope',
        'current_car.car.full_name'
      ]"
      sortMode="multiple"
      [(first)]="firstPage"
      [rowHover]="true"
      stateStorage="local"
      stateKey="state-caregivers-list-active-applicants-3"
      [showCurrentPageReport]="true"
      currentPageReportTemplate="{totalRecords} Bewerber"
      [rows]="50"
      [paginator]="true"
      scrollHeight="100%"
      [scrollable]="true"
    >
      <ng-template pTemplate="header">
        <tr class="table-headline-highlight">
          <th style="max-width: 140px"></th>
          <th style="max-width: 50px"></th>
          <th style="min-width: 160px" pSortableColumn="current_status.status">
            Status <p-sortIcon field="current_status.status"></p-sortIcon>
          </th>
          <th style="min-width: 200px" pSortableColumn="last_name">
            Name <p-sortIcon field="last_name"></p-sortIcon>
          </th>
          <th>Kontaktiert</th>
          <th
            style="min-width: 180px"
            pSortableColumn="current_status.job_interview_at"
          >
            Bewerbungsgespräch
            <p-sortIcon field="current_status.job_interview_at"></p-sortIcon>
          </th>
          <th pSortableColumn="current_status.hospitation_from">
            Hospitation
            <p-sortIcon field="current_status.hospitation_from"></p-sortIcon>
          </th>
          <th style="max-width: 60px" pTooltip="Checkliste">
            <i class="pi pi-check-circle"></i>
          </th>
          <th pSortableColumn="city">
            Wohnort <p-sortIcon field="city"></p-sortIcon>
          </th>
          <th>Telefon</th>
          <th pSortableColumn="created_at">
            Erstellt am
            <p-sortIcon field="created_at"></p-sortIcon>
          </th>
          <th style="max-width: 50px"></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-caregiver>
        <tr
          [class.background-hightlight-alert-no-opacity]="
            caregiver.current_status.status === 'HOSPITATION' &&
            !caregiver.current_status.hospitation_confirmed
          "
        >
          <td style="max-width: 140px">
            <span
              *ngIf="caregiver.unseen"
              class="p-tag p-tag-rounded p-tag-OPEN"
              >NEU</span
            >

            <span
              *ngIf="
                caregiver.current_status?.caregiver_hospitation_feedback?.unseen
              "
              (click)="openHospitationFeedbackDialog(caregiver)"
              class="p-tag p-tag-rounded p-tag-OPEN"
              >FEEDBACK</span
            >
          </td>
          <td style="max-width: 50px">
            <i
              class="pi pi-exclamation-triangle color-alert"
              pTooltip="E-Mail bereits vorhanden"
              *ngIf="caregiver.caregivers_with_same_email.length > 0"
            ></i>
          </td>
          <td style="min-width: 160px">
            <span
              class="p-tag p-tag-rounded p-tag-{{
                caregiver.current_status.status
              }}"
              >{{ caregiver.current_status.status_text }}</span
            >
            <i
              class="pi pi-info-circle"
              [escape]="false"
              style="font-size: 14px; margin: 0 0 0 5px"
              *ngIf="caregiver.current_status.status === 'EDIT'"
              [pTooltip]="
                'Seit ' +
                caregiver.current_status.edit_at_string +
                '\n' +
                caregiver.current_status.edit_info
              "
            ></i>
          </td>
          <td style="min-width: 200px">
            <a class="color-main1" routerLink="/caregivers/{{ caregiver.id }}">
              {{ caregiver.last_first_name }}
            </a>
          </td>

          <td>
            <i
              class="pi pi-send"
              pTooltip="Kontaktiert per E-Mail"
              *ngIf="caregiver.application_contact_via?.includes('E-Mail')"
            ></i>
            <i
              class="pi pi-phone"
              [escape]="false"
              pTooltip="Kontaktiert per Telefon {{
                caregiver.application_ne ? '\nn.E.' : ''
              }} {{ caregiver.application_ne_ab ? '\nn.E. + AB' : '' }}"
              *ngIf="caregiver.application_contact_via?.includes('Telefon')"
            ></i>

            <i
              class="pi pi-info-circle"
              style="margin: 0 0 0 10px; font-size: 14px"
              *ngIf="caregiver.application_information"
              [pTooltip]="caregiver.application_information"
            ></i>
          </td>

          <!-- Bewerbungsgespräch -->
          <td
            style="min-width: 180px"
            *ngIf="caregiver.current_status.status === 'JOB_INTERVIEW'"
          >
            <div style="display: flex; flex-direction: column">
              {{ caregiver.current_status.job_interview_at_string }}
              <small>{{
                caregiver.current_status.job_interview_with || "?"
              }}</small>
            </div>
          </td>
          <td
            style="min-width: 180px"
            *ngIf="caregiver.current_status.status !== 'JOB_INTERVIEW'"
          ></td>

          <!-- Hospitation gestartet -->
          <td *ngIf="caregiver.current_status.status === 'HOSPITATION_STARTED'">
            <div style="display: flex; flex-direction: column">
              <small
                >({{
                  caregiver.current_status.hospitation_with_caregiver
                    .first_last_name || "?"
                }})</small
              >
            </div>
          </td>
          <!-- Hospitation -->
          <td *ngIf="caregiver.current_status.status === 'HOSPITATION'">
            <div style="display: flex; flex-direction: column">
              {{ caregiver.current_status.hospitation_from_string }} -
              <br />
              {{ caregiver.current_status.hospitation_to_string || "" }}
              <small>{{
                caregiver.current_status.hospitation_with_caregiver
                  .first_last_name || "?"
              }}</small>
            </div>
          </td>
          <td
            *ngIf="
              caregiver.current_status.status !== 'HOSPITATION' &&
              caregiver.current_status.status !== 'HOSPITATION_STARTED'
            "
          ></td>

          <td style="max-width: 60px">
            <i
              class="pi pi-check-circle color-main1"
              [escape]="false"
              tooltipStyleClass="tooltip-wider"
              [pTooltip]="
                'Bewerbungsgespräch vereinbart (' +
                caregiver.current_status.job_interview_at_string +
                ' Uhr) mit ' +
                caregiver.current_status.job_interview_with +
                '\n\nBewerbungsunterlagen sind vollständig'
              "
              *ngIf="
                caregiver.current_status.job_interview_with &&
                caregiver.current_status.job_interview_at_string &&
                caregiver.application_documents_complete === 'Ja'
              "
            ></i>

            <i
              class="pi pi-check-circle color-orange"
              [escape]="false"
              tooltipStyleClass="tooltip-wider"
              [pTooltip]="
                'Bewerbungsgespräch vereinbart (' +
                caregiver.current_status.job_interview_at_string +
                ' Uhr) mit ' +
                caregiver.current_status.job_interview_with +
                '\n\nEs fehlen Bewerbungsunterlagen:\n' +
                (caregiver.missed_application_documents ||
                  (!caregiver.application_documents_complete
                    ? 'Noch nicht angefragt'
                    : '-'))
              "
              *ngIf="
                caregiver.current_status.job_interview_with &&
                caregiver.current_status.job_interview_at_string &&
                (caregiver.application_documents_complete === 'Nein' ||
                  !caregiver.application_documents_complete)
              "
            ></i>

            <i
              class="pi pi-check-circle color-gray"
              [escape]="false"
              tooltipStyleClass="tooltip-wider"
              [pTooltip]="'Bewerbungsgespräch noch nicht vereinbart'"
              *ngIf="
                !caregiver.current_status.job_interview_with &&
                !caregiver.current_status.job_interview_at_string
              "
            ></i>
          </td>

          <td>{{ caregiver.city }}</td>
          <td>
            <div style="display: flex; flex-direction: column">
              <span *ngIf="caregiver.phone_1">
                <a
                  (click)="phoneCallService.createCalling(caregiver.phone_1)"
                  href="tel:{{ caregiver.phone_1 }}"
                  >{{ caregiver.phone_1 }}</a
                >
                <i
                  style="margin: 0 0 0 5px; font-size: 13px"
                  class="pi pi-info-circle color-gray"
                  *ngIf="caregiver.phone_1_info"
                  [pTooltip]="caregiver.phone_1_info"
                ></i>
              </span>
              <span *ngIf="caregiver.phone_2">
                <a
                  (click)="phoneCallService.createCalling(caregiver.phone_2)"
                  href="tel:{{ caregiver.phone_2 }}"
                  >{{ caregiver.phone_2 }}</a
                >
                <i
                  style="margin: 0 0 0 5px; font-size: 13px"
                  class="pi pi-info-circle color-gray"
                  *ngIf="caregiver.phone_2_info"
                  [pTooltip]="caregiver.phone_2_info"
                ></i>
              </span>
              <span *ngIf="caregiver.phone_3">
                <a
                  (click)="phoneCallService.createCalling(caregiver.phone_3)"
                  href="tel:{{ caregiver.phone_3 }}"
                  >{{ caregiver.phone_3 }}</a
                >
                <i
                  style="margin: 0 0 0 5px; font-size: 13px"
                  class="pi pi-info-circle color-gray"
                  *ngIf="caregiver.phone_3_info"
                  [pTooltip]="caregiver.phone_3_info"
                ></i>
              </span>
            </div>
          </td>

          <td>{{ caregiver.created_at | date: "dd.MM.y H:mm" }}</td>

          <td class="table-action" style="max-width: 50px">
            <i
              (click)="confirmHospitation(caregiver.current_status)"
              *ngIf="
                caregiver.current_status.status === 'HOSPITATION' &&
                !caregiver.current_status.hospitation_confirmed
              "
              class="pi pi-check"
              pTooltip="Hospitation bestätigen"
              tooltipPosition="left"
            ></i>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
