import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core'
import { ActivatedRoute, ParamMap, Router } from '@angular/router'
import { HistoryService } from '../../../../../services/history.service'
import { LatestChangeModel } from '../../../../../models/history/latest-change.model'
import { DialogService } from 'primeng/dynamicdialog'
import { CustomerDetailGeneralModel } from '../../../../../models/customer-patient/customer-detail-general.model'
import { PatientService } from '../../../../../services/patient.service'
import {
  EventBusService,
  GlobalEvent,
} from '../../../../../services/eventbus.service'
import { Subscription } from 'rxjs'
import { PhoneCallService } from '../../../../../services/phone-call.service'
import { HistoryListModel } from '../../../../../models/history/history-list.model'
import { HistoryManualDialogComponent } from '../../../../../components/dialogs/history-manual-dialog/history-manual-dialog.component'
import { OfferService } from '../../../../../services/offer.service'
import { SendOfferService } from '../../../../../services/send-offer.service'
import { SendAppointmentsService } from '../../../../../services/send-appointments.service'
import * as dayjs from 'dayjs'
import { SendLetterService } from '../../../../../services/send-letter.service'
import { UserMultiplierService } from '../../../../../services/user-multiplier.service'

@Component({
  selector: 'app-patients-detail-general',
  templateUrl: './user-multiplier-patients-detail-general.component.html',
})
export class UserMultiplierPatientsDetailGeneralComponent
  implements OnInit, OnDestroy {
  @ViewChild('op') overlayPanel: ElementRef | any = null
  @ViewChild('opNearPatients') overlayPanelNearPatients: ElementRef | any = null

  public loading = true
  public customer: CustomerDetailGeneralModel = new CustomerDetailGeneralModel()
  public area = 1
  public nearPatientsLoading = false
  public nearPatients = []
  public latestChanges = new LatestChangeModel()
  public openedContactPersons: { [index: number]: boolean } = {}
  private eventBusSubscription: Subscription = new Subscription()
  public onlyImportant = false

  private persplanDates: any = {}
  public persplanData = new LatestChangeModel()
  public selectedPatient: 'first_patient' | 'second_patient' = 'first_patient'

  public currentBudgetTable = ''

  constructor(
    private offerService: OfferService,
    private patientService: PatientService,
    private userMultiplierService: UserMultiplierService,
    private route: ActivatedRoute,
    public phoneCallService: PhoneCallService,
    private eventbus: EventBusService,
    private dialogService: DialogService,
    private router: Router,
    private sendOfferService: SendOfferService,
    private sendAppointmentsService: SendAppointmentsService,
    private sendLetterService: SendLetterService,
    private historyService: HistoryService
  ) {}

  ngOnInit(): void {
    dayjs.locale('de')

    this.persplanDates = {
      last: {
        month: dayjs().subtract(1, 'month').format('MMMM'),
        year: dayjs().subtract(1, 'month').format('YYYY'),
      },
      current: {
        month: dayjs().format('MMMM'),
        year: dayjs().format('YYYY'),
      },
      next: {
        month: dayjs().add(1, 'month').format('MMMM'),
        year: dayjs().add(1, 'month').format('YYYY'),
      },
    }

    this.loadCustomer()
    this.listenForEventbus()
  }

  ngOnDestroy(): void {
    this.eventBusSubscription.unsubscribe()
  }

  public getPersplanBudget(
    patientId: number | null,
    type: 'current' | 'next' | 'last'
  ): void {
    if (this.persplanData[type + patientId]) {
      return
    }

    this.userMultiplierService
      .getPersplanMonth(
        patientId ? patientId.toString() : '',
        this.persplanDates[type].month,
        this.persplanDates[type].year,
        true
      )
      .subscribe((response: string) => {
        this.persplanData[type + patientId] = response
      })
  }

  public openBudgetOverlay(event: any, text: string): void {
    this.currentBudgetTable = text

    this.overlayPanel.toggle(event)
  }

  public changePatient(type: 'first_patient' | 'second_patient'): void {
    this.selectedPatient = type
  }

  public loadCustomer(withLoading: boolean = true): void {
    if (withLoading) {
      this.loading = true
    }

    this.route.paramMap.subscribe((params: ParamMap) => {
      this.userMultiplierService
        .loadPatient(params.get('id'))
        .subscribe((data: CustomerDetailGeneralModel) => {
          this.customer = data

          if (withLoading) {
            this.loading = false
          }
        })
    })
  }

  public openManualHistoryDialog(
    edit: boolean,
    history?: HistoryListModel
  ): void {
    this.dialogService.open(HistoryManualDialogComponent, {
      data: {
        edit,
        history: history || new HistoryListModel(),
        type: 'patient',
        type_id: this.customer[this.selectedPatient].id,
        patients: this.customer,
      },
      header: history ? 'Eintrag bearbeiten' : 'Neuer Eintrag',
      styleClass: 'dialog-container',
      dismissableMask: false,
      width: '520px',
    })
  }

  private listenForEventbus(): void {
    this.eventBusSubscription = this.eventbus.subject.subscribe((event) => {
      switch (event) {
        case GlobalEvent.ContactPersonChanged:
        case GlobalEvent.QualificationFinished:
        case GlobalEvent.CustomerServiceChanged:
        case GlobalEvent.NoticeChanged:
        case GlobalEvent.PatientStatusChanged:
        case GlobalEvent.TodoChanged:
        case GlobalEvent.HistoryListReload:
        case GlobalEvent.OfferSent:
          this.loadCustomer(false)
          break
      }
    })
  }
}
