import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core'
import { Router } from '@angular/router'
import { PhoneCallService } from '../../../services/phone-call.service'
import { HelperService } from '../../../services/helper.service'
import { PlanningService } from '../../../services/planning.service'
import * as dayjs from 'dayjs'
import * as customParseFormat from 'dayjs/plugin/customParseFormat'
import * as utc from 'dayjs/plugin/utc'
import { DialogService } from 'primeng/dynamicdialog'
import {
  EventBusService,
  GlobalEvent,
} from '../../../services/eventbus.service'
import { Subscription } from 'rxjs'
import { PatientService } from '../../../services/patient.service'

@Component({
  selector: 'app-change-required-plannings',
  templateUrl: './change-required-plannings.component.html',
})
export class ChangeRequiredPlanningsComponent implements OnInit, OnDestroy {
  @ViewChild('op') overlayPanel: ElementRef | any = null
  @ViewChild('dt') table: ElementRef | any = null
  @ViewChild('opNearPatients') overlayPanelNearPatients: ElementRef | any = null

  public globalFilterValue = ''

  public loading = true
  public contentLoading = true
  private eventBusSubscription: Subscription = new Subscription()

  public area = 1
  public nearPatientsLoading: any = {}
  public nearPatients = []
  public currentSelectedCustomerId: any = null

  // TODO: Model erstellen
  public data: any[] = []

  public filters = {
    only_today: false,
  }

  constructor(
    public phoneCallService: PhoneCallService,
    private helperService: HelperService,
    private planningService: PlanningService,
    private patientService: PatientService,
    private eventbus: EventBusService,
    private dialogService: DialogService,
    private router: Router
  ) {
    dayjs.locale('de')
    dayjs.extend(customParseFormat)
    dayjs.extend(utc)
  }

  ngOnInit(): void {
    this.loadAppointments()
    this.listenForEventbus()
  }

  ngOnDestroy(): void {
    this.eventBusSubscription.unsubscribe()
  }

  public loadAppointments(withLoading: boolean = true): void {
    this.contentLoading = true

    if (withLoading) {
      this.loading = true
    }

    this.planningService
      .loadChangesRequired(this.filters)
      .subscribe((data: any) => {
        this.data = data

        // Wert für das Datum muss in ein Date Objekt umgewandelt werden,
        // sonst funktioniert der Datums-Filter nicht.
        this.data.forEach((item: any) => {
          item.real_from_formatted = new Date(item.real_from)
        })

        this.contentLoading = false

        if (withLoading) {
          this.loading = false
        }
      })
  }

  public filterTableGlobal(event: any): void {
    this.table && this.table.filterGlobal(event.target.value.trim(), 'contains')
  }

  public openNearPatients(
    customerId: any = null,
    event: any = null,
    withLoading = true
  ): void {
    this.nearPatientsLoading = {}

    if (withLoading) {
      this.nearPatients = []
      this.nearPatientsLoading[customerId] = true
    }

    if (customerId) {
      this.currentSelectedCustomerId = customerId
    }

    this.patientService
      .getNearPatients(this.currentSelectedCustomerId, this.area)
      .subscribe((patients: any) => {
        this.nearPatients = patients

        this.nearPatientsLoading = {}

        if (event) {
          this.overlayPanelNearPatients.toggle(event)
        }
      })
  }

  private listenForEventbus(): void {
    this.eventBusSubscription = this.eventbus.subject.subscribe(
      (event: GlobalEvent) => {
        switch (event) {
          case GlobalEvent.PlanningChanged:
            this.loadAppointments(false)
        }
      }
    )
  }
}
