import { AuthGuard } from '../guards/auth.guard'
import { CaregiversListComponent } from '../views/caregivers/list/caregivers-list.component'
import { CaregiversListActiveComponent } from '../views/caregivers/list/active/caregivers-list-active.component'
import { CaregiversListDeactivatedComponent } from '../views/caregivers/list/deactivated/caregivers-list-deactivated.component'
import { CaregiversFormComponent } from '../views/caregivers/form/caregivers-form.component'
import { DirtyCheckGuard } from '../guards/dirty-check.guard'
import { CaregiversDetailComponent } from '../views/caregivers/detail/caregivers-detail.component'
import { CaregiversDetailGeneralComponent } from '../views/caregivers/detail/general/caregivers-detail-general.component'
import { CaregiversDetailMasterDataComponent } from '../views/caregivers/detail/general/master-data/caregivers-detail-master-data.component'
import { CaregiversDetailContractDataComponent } from '../views/caregivers/detail/general/contract-data/caregivers-detail-contract-data.component'
import { CaregiversDetailQualificationsComponent } from '../views/caregivers/detail/general/qualifications/caregivers-detail-qualifications.component'
import { CaregiversDetailOverviewComponent } from '../views/caregivers/detail/overview/caregivers-detail-overview.component'
import { CaregiversDetailCalendarComponent } from '../views/caregivers/detail/overview/calendar/caregivers-detail-calendar.component'
import { CaregiversDetailAnalysesComponent } from '../views/caregivers/detail/overview/analyses/caregivers-detail-analyses.component'
import { CaregiversDetailProofsComponent } from '../views/caregivers/detail/overview/proofs/caregivers-detail-proofs.component'
import { CaregiversDetailDatesComponent } from '../views/caregivers/detail/overview/dates/caregivers-detail-dates.component'
import { CaregiversDetailHistoryComponent } from '../views/caregivers/detail/history/caregivers-detail-history.component'
import { CaregiversDetailVetoComponent } from '../views/caregivers/detail/veto/caregivers-detail-veto.component'
import { CaregiversDetailDocumentsComponent } from '../views/caregivers/detail/documents/caregivers-detail-documents.component'
import { CaregiversDetailListComponent } from '../views/caregivers/detail/overview/list/caregivers-detail-list.component'
import { CaregiversListApplicantsComponent } from '../views/caregivers/list-applicants/caregivers-list-applicants.component'
import { CaregiversListApplicantsApplicantsComponent } from '../views/caregivers/list-applicants/applicants/caregivers-list-applicants-applicants.component'
import { CaregiversListApplicantsDeactivatedComponent } from '../views/caregivers/list-applicants/deactivated/caregivers-list-applicants-deactivated.component'
import { CaregiversDetailRecommendationsComponent } from '../views/caregivers/detail/overview/recommendations/caregivers-detail-recommendations.component'
import {
  CaregiversDetailPlanningComponent
} from "../views/caregivers/detail/planning/caregivers-detail-planning.component";

export const caregiversRoutes = [
  {
    path: 'applicants',
    component: CaregiversListApplicantsComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: CaregiversListApplicantsApplicantsComponent,
      },
      {
        path: 'deactivated',
        component: CaregiversListApplicantsDeactivatedComponent,
      },
    ],
  },
  {
    path: 'caregivers',
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: CaregiversListComponent,
        children: [
          {
            path: '',
            component: CaregiversListActiveComponent,
          },
          {
            path: 'deactivated',
            component: CaregiversListDeactivatedComponent,
          },
        ],
      },
      {
        path: 'new',
        component: CaregiversFormComponent,
        canDeactivate: [DirtyCheckGuard],
      },
      {
        path: ':id',
        component: CaregiversDetailComponent,
        children: [
          {
            path: '',
            component: CaregiversDetailGeneralComponent,
            children: [
              {
                path: '',
                component: CaregiversDetailMasterDataComponent,
              },
              {
                path: 'contract-data',
                component: CaregiversDetailContractDataComponent,
              },
              {
                path: 'qualifications',
                component: CaregiversDetailQualificationsComponent,
              },
            ],
          },
          {
            path: 'overview',
            component: CaregiversDetailOverviewComponent,
            children: [
              {
                path: '',
                component: CaregiversDetailListComponent,
              },
              {
                path: 'calendar',
                component: CaregiversDetailCalendarComponent,
              },
              {
                path: 'analyses',
                component: CaregiversDetailAnalysesComponent,
              },
              {
                path: 'recommendations',
                component: CaregiversDetailRecommendationsComponent,
              },
              {
                path: 'proofs',
                component: CaregiversDetailProofsComponent,
              },
              {
                path: 'dates',
                component: CaregiversDetailDatesComponent,
              },
            ],
          },
          {
            path: 'history',
            component: CaregiversDetailHistoryComponent,
          },
          {
            path: 'veto',
            component: CaregiversDetailVetoComponent,
          },
          {
            path: 'planning',
            component: CaregiversDetailPlanningComponent,
          },
          {
            path: 'documents',
            component: CaregiversDetailDocumentsComponent,
          },
        ],
      },
      {
        path: ':id/edit',
        component: CaregiversFormComponent,
        canDeactivate: [DirtyCheckGuard],
      },
    ],
  },
]
