import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { environment } from '../../environments/environment'
import { Observable } from 'rxjs'
import { OfferDependenciesModel } from '../models/offer/offer-dependencies.model'
import { CarFormModel } from '../models/car/car-form.model'
import { CaregiverFormModel } from '../models/caregiver/caregiver-form.model'
import { OfferFormModel } from '../models/offer/offer-form.model'
import { CustomerModel } from '../models/customer-patient/customer.model'
import { PatientModel } from '../models/customer-patient/patient.model'

@Injectable({
  providedIn: 'root',
})
export class OfferService {
  constructor(private http: HttpClient) {}

  public dependencies(): Observable<OfferDependenciesModel> {
    return this.http.get<OfferDependenciesModel>(
      `${environment.api}/offer/dependencies`
    )
  }

  public store(
    data: OfferFormModel,
    previewConsulting: any
  ): Observable<PatientModel> {
    return this.http.post<any>(`${environment.api}/offer`, {
      ...data,
      previewConsulting,
    })
  }

  public storeFromMultiplier(data: OfferFormModel): Observable<PatientModel> {
    return this.http.post<any>(`${environment.api}/offer/multiplier`, data)
  }

  public storeFromCaregiver(data: OfferFormModel): Observable<PatientModel> {
    return this.http.post<any>(`${environment.api}/offer/caregiver`, data)
  }

  public update(data: OfferFormModel): Observable<PatientModel> {
    return this.http.put<PatientModel>(
      `${environment.api}/offer/${data.id}`,
      data
    )
  }

  public adminClicked(data: OfferFormModel): Observable<[]> {
    return this.http.put<[]>(
      `${environment.api}/offer/${data.id}/admin-clicked`,
      {}
    )
  }

  public updateFromMultiplier(data: OfferFormModel): Observable<PatientModel> {
    return this.http.put<PatientModel>(
      `${environment.api}/offer/multiplier/${data.id}`,
      data
    )
  }

  public loadForEditMode(id: string | null): Observable<OfferFormModel> {
    return this.http.get<OfferFormModel>(`${environment.api}/offer/${id}/edit`)
  }

  public loadForEditModeFromMultiplier(
    id: string | null
  ): Observable<OfferFormModel> {
    return this.http.get<OfferFormModel>(
      `${environment.api}/offer/multiplier/${id}/edit`
    )
  }
}
