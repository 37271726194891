<form #form="ngForm" class="dialog-form">
  <div class="dialog-form-body">
    <!-- Feiertage -->
    <div class="caregiver-times-container">
      <p-checkbox
        [(ngModel)]="worksOnHolidays"
        [binary]="true"
        label="An Feiertagen"
        name="holiday"
      ></p-checkbox>
    </div>
  </div>

  <div class="dialog-form-body">
    <!-- Montag -->
    <div class="caregiver-times-container">
      <p-checkbox
        [(ngModel)]="values.days_with_key.monday.checked"
        [binary]="true"
        label="Montag"
        name="monday"
      ></p-checkbox>
      <div class="caregiver-times-lines">
        <!-- Montag erster Block -->
        <div class="caregiver-times-line">
          <span>von</span>
          <p-dropdown
            (onChange)="checkForTimes($event, 'monday')"
            [(ngModel)]="values.days_with_key.monday.start_first"
            [disabled]="!values.days_with_key.monday.checked"
            scrollHeight="500px"
            appendTo="body"
            name="monday_start_first"
            [options]="times"
            optionValue="value"
            optionLabel="value"
            [editable]="true"
            [required]="values.days_with_key.monday.checked"
            #monday_start_first="ngModel"
            [class.p-invalid]="
              errors.monday_start_first ||
              (monday_start_first.invalid &&
                (monday_start_first.dirty || monday_start_first.touched))
            "
          ></p-dropdown>
          <span>bis</span>
          <p-dropdown
            (onChange)="checkForTimes($event, 'monday')"
            [(ngModel)]="values.days_with_key.monday.end_first"
            [disabled]="!values.days_with_key.monday.checked"
            scrollHeight="500px"
            appendTo="body"
            name="monday_end_first"
            [options]="times"
            optionValue="value"
            optionLabel="value"
            [editable]="true"
            [required]="values.days_with_key.monday.checked"
            #monday_end_first="ngModel"
            [class.p-invalid]="
              errors.monday_end_first ||
              (monday_end_first.invalid &&
                (monday_end_first.dirty || monday_end_first.touched))
            "
          ></p-dropdown>
          <i
            (click)="setSecondBlock('monday')"
            *ngIf="
              values.days_with_key.monday.checked &&
              !values.days_with_key.monday.has_second_block
            "
            pTooltip="Neuer Block"
            class="pi pi-plus"
          ></i>
        </div>

        <!-- Montag zweiter Block -->
        <div
          class="caregiver-times-line"
          *ngIf="values.days_with_key.monday.has_second_block"
        >
          <span>von</span>
          <p-dropdown
            (onChange)="checkForTimes($event, 'monday')"
            [(ngModel)]="values.days_with_key.monday.start_second"
            [disabled]="!values.days_with_key.monday.checked"
            scrollHeight="500px"
            appendTo="body"
            name="monday_start_second"
            [options]="times"
            optionValue="value"
            optionLabel="value"
            [editable]="true"
            [required]="values.days_with_key.monday.checked"
            #monday_start_second="ngModel"
            [class.p-invalid]="
              errors.monday_start_second ||
              (monday_start_second.invalid &&
                (monday_start_second.dirty || monday_start_second.touched))
            "
          ></p-dropdown>
          <span>bis</span>
          <p-dropdown
            (onChange)="checkForTimes($event, 'monday')"
            [(ngModel)]="values.days_with_key.monday.end_second"
            [disabled]="!values.days_with_key.monday.checked"
            scrollHeight="500px"
            appendTo="body"
            name="monday_end_second"
            [options]="times"
            optionValue="value"
            optionLabel="value"
            [editable]="true"
            [required]="values.days_with_key.monday.checked"
            #monday_end_second="ngModel"
            [class.p-invalid]="
              errors.monday_end_second ||
              (monday_end_second.invalid &&
                (monday_end_second.dirty || monday_end_second.touched))
            "
          ></p-dropdown>
          <i
            *ngIf="
              values.days_with_key.monday.checked &&
              values.days_with_key.monday.has_second_block
            "
            (click)="removeSecondBlock('monday')"
            pTooltip="Block entfernen"
            class="pi pi-minus"
          ></i>
        </div>
      </div>
    </div>

    <!-- Dienstag -->
    <div class="caregiver-times-container">
      <p-checkbox
        [(ngModel)]="values.days_with_key.tuesday.checked"
        [binary]="true"
        label="Dienstag"
        name="tuesday"
      ></p-checkbox>
      <div class="caregiver-times-lines">
        <!-- Dienstag erster Block -->
        <div class="caregiver-times-line">
          <span>von</span>
          <p-dropdown
            (onChange)="checkForTimes($event, 'tuesday')"
            [(ngModel)]="values.days_with_key.tuesday.start_first"
            [disabled]="!values.days_with_key.tuesday.checked"
            scrollHeight="500px"
            appendTo="body"
            name="tuesday_start_first"
            [options]="times"
            optionValue="value"
            optionLabel="value"
            [editable]="true"
            [required]="values.days_with_key.tuesday.checked"
            #tuesday_start_first="ngModel"
            [class.p-invalid]="
              errors.tuesday_start_first ||
              (tuesday_start_first.invalid &&
                (tuesday_start_first.dirty || tuesday_start_first.touched))
            "
          ></p-dropdown>
          <span>bis</span>
          <p-dropdown
            (onChange)="checkForTimes($event, 'tuesday')"
            [(ngModel)]="values.days_with_key.tuesday.end_first"
            [disabled]="!values.days_with_key.tuesday.checked"
            scrollHeight="500px"
            appendTo="body"
            name="tuesday_end_first"
            [options]="times"
            optionValue="value"
            optionLabel="value"
            [editable]="true"
            [required]="values.days_with_key.tuesday.checked"
            #tuesday_end_first="ngModel"
            [class.p-invalid]="
              errors.tuesday_end_first ||
              (tuesday_end_first.invalid &&
                (tuesday_end_first.dirty || tuesday_end_first.touched))
            "
          ></p-dropdown>
          <i
            (click)="setSecondBlock('tuesday')"
            *ngIf="
              values.days_with_key.tuesday.checked &&
              !values.days_with_key.tuesday.has_second_block
            "
            pTooltip="Neuer Block"
            class="pi pi-plus"
          ></i>
        </div>

        <!-- Dienstag zweiter Block -->
        <div
          class="caregiver-times-line"
          *ngIf="values.days_with_key.tuesday.has_second_block"
        >
          <span>von</span>
          <p-dropdown
            (onChange)="checkForTimes($event, 'tuesday')"
            [(ngModel)]="values.days_with_key.tuesday.start_second"
            [disabled]="!values.days_with_key.tuesday.checked"
            scrollHeight="500px"
            appendTo="body"
            name="tuesday_start_second"
            [options]="times"
            optionValue="value"
            optionLabel="value"
            [editable]="true"
            [required]="values.days_with_key.tuesday.checked"
            #tuesday_start_second="ngModel"
            [class.p-invalid]="
              errors.tuesday_start_second ||
              (tuesday_start_second.invalid &&
                (tuesday_start_second.dirty || tuesday_start_second.touched))
            "
          ></p-dropdown>
          <span>bis</span>
          <p-dropdown
            (onChange)="checkForTimes($event, 'tuesday')"
            [(ngModel)]="values.days_with_key.tuesday.end_second"
            [disabled]="!values.days_with_key.tuesday.checked"
            scrollHeight="500px"
            appendTo="body"
            name="tuesday_end_second"
            [options]="times"
            optionValue="value"
            optionLabel="value"
            [editable]="true"
            [required]="values.days_with_key.tuesday.checked"
            #tuesday_end_second="ngModel"
            [class.p-invalid]="
              errors.tuesday_end_second ||
              (tuesday_end_second.invalid &&
                (tuesday_end_second.dirty || tuesday_end_second.touched))
            "
          ></p-dropdown>
          <i
            *ngIf="
              values.days_with_key.tuesday.checked &&
              values.days_with_key.tuesday.has_second_block
            "
            (click)="removeSecondBlock('tuesday')"
            pTooltip="Block entfernen"
            class="pi pi-minus"
          ></i>
        </div>
      </div>
    </div>

    <!-- Mittwoch -->
    <div class="caregiver-times-container">
      <p-checkbox
        [(ngModel)]="values.days_with_key.wednesday.checked"
        [binary]="true"
        label="Mittwoch"
        name="wednesday"
      ></p-checkbox>
      <div class="caregiver-times-lines">
        <!-- Mittwoch erster Block -->
        <div class="caregiver-times-line">
          <span>von</span>
          <p-dropdown
            (onChange)="checkForTimes($event, 'wednesday')"
            [(ngModel)]="values.days_with_key.wednesday.start_first"
            [disabled]="!values.days_with_key.wednesday.checked"
            scrollHeight="500px"
            appendTo="body"
            name="wednesday_start_first"
            [options]="times"
            optionValue="value"
            optionLabel="value"
            [editable]="true"
            [required]="values.days_with_key.wednesday.checked"
            #wednesday_start_first="ngModel"
            [class.p-invalid]="
              errors.wednesday_start_first ||
              (wednesday_start_first.invalid &&
                (wednesday_start_first.dirty || wednesday_start_first.touched))
            "
          ></p-dropdown>
          <span>bis</span>
          <p-dropdown
            (onChange)="checkForTimes($event, 'wednesday')"
            [(ngModel)]="values.days_with_key.wednesday.end_first"
            [disabled]="!values.days_with_key.wednesday.checked"
            scrollHeight="500px"
            appendTo="body"
            name="wednesday_end_first"
            [options]="times"
            optionValue="value"
            optionLabel="value"
            [editable]="true"
            [required]="values.days_with_key.wednesday.checked"
            #wednesday_end_first="ngModel"
            [class.p-invalid]="
              errors.wednesday_end_first ||
              (wednesday_end_first.invalid &&
                (wednesday_end_first.dirty || wednesday_end_first.touched))
            "
          ></p-dropdown>
          <i
            (click)="setSecondBlock('wednesday')"
            *ngIf="
              values.days_with_key.wednesday.checked &&
              !values.days_with_key.wednesday.has_second_block
            "
            pTooltip="Neuer Block"
            class="pi pi-plus"
          ></i>
        </div>

        <!-- Mittwoch zweiter Block -->
        <div
          class="caregiver-times-line"
          *ngIf="values.days_with_key.wednesday.has_second_block"
        >
          <span>von</span>
          <p-dropdown
            (onChange)="checkForTimes($event, 'wednesday')"
            [(ngModel)]="values.days_with_key.wednesday.start_second"
            [disabled]="!values.days_with_key.wednesday.checked"
            scrollHeight="500px"
            appendTo="body"
            name="wednesday_start_second"
            [options]="times"
            optionValue="value"
            optionLabel="value"
            [editable]="true"
            [required]="values.days_with_key.wednesday.checked"
            #wednesday_start_second="ngModel"
            [class.p-invalid]="
              errors.wednesday_start_second ||
              (wednesday_start_second.invalid &&
                (wednesday_start_second.dirty ||
                  wednesday_start_second.touched))
            "
          ></p-dropdown>
          <span>bis</span>
          <p-dropdown
            (onChange)="checkForTimes($event, 'wednesday')"
            [(ngModel)]="values.days_with_key.wednesday.end_second"
            [disabled]="!values.days_with_key.wednesday.checked"
            scrollHeight="500px"
            appendTo="body"
            name="wednesday_end_second"
            [options]="times"
            optionValue="value"
            optionLabel="value"
            [editable]="true"
            [required]="values.days_with_key.wednesday.checked"
            #wednesday_end_second="ngModel"
            [class.p-invalid]="
              errors.wednesday_end_second ||
              (wednesday_end_second.invalid &&
                (wednesday_end_second.dirty || wednesday_end_second.touched))
            "
          ></p-dropdown>
          <i
            *ngIf="
              values.days_with_key.wednesday.checked &&
              values.days_with_key.wednesday.has_second_block
            "
            (click)="removeSecondBlock('wednesday')"
            pTooltip="Block entfernen"
            class="pi pi-minus"
          ></i>
        </div>
      </div>
    </div>

    <!-- Donnerstag -->
    <div class="caregiver-times-container">
      <p-checkbox
        [(ngModel)]="values.days_with_key.thursday.checked"
        [binary]="true"
        label="Donnerstag"
        name="thursday"
      ></p-checkbox>
      <div class="caregiver-times-lines">
        <!-- Donnerstag erster Block -->
        <div class="caregiver-times-line">
          <span>von</span>
          <p-dropdown
            (onChange)="checkForTimes($event, 'thursday')"
            [(ngModel)]="values.days_with_key.thursday.start_first"
            [disabled]="!values.days_with_key.thursday.checked"
            scrollHeight="500px"
            appendTo="body"
            name="thursday_start_first"
            [options]="times"
            optionValue="value"
            optionLabel="value"
            [editable]="true"
            [required]="values.days_with_key.thursday.checked"
            #thursday_start_first="ngModel"
            [class.p-invalid]="
              errors.thursday_start_first ||
              (thursday_start_first.invalid &&
                (thursday_start_first.dirty || thursday_start_first.touched))
            "
          ></p-dropdown>
          <span>bis</span>
          <p-dropdown
            (onChange)="checkForTimes($event, 'thursday')"
            [(ngModel)]="values.days_with_key.thursday.end_first"
            [disabled]="!values.days_with_key.thursday.checked"
            scrollHeight="500px"
            appendTo="body"
            name="thursday_end_first"
            [options]="times"
            optionValue="value"
            optionLabel="value"
            [editable]="true"
            [required]="values.days_with_key.thursday.checked"
            #thursday_end_first="ngModel"
            [class.p-invalid]="
              errors.thursday_end_first ||
              (thursday_end_first.invalid &&
                (thursday_end_first.dirty || thursday_end_first.touched))
            "
          ></p-dropdown>
          <i
            (click)="setSecondBlock('thursday')"
            *ngIf="
              values.days_with_key.thursday.checked &&
              !values.days_with_key.thursday.has_second_block
            "
            pTooltip="Neuer Block"
            class="pi pi-plus"
          ></i>
        </div>

        <!-- Donnerstag zweiter Block -->
        <div
          class="caregiver-times-line"
          *ngIf="values.days_with_key.thursday.has_second_block"
        >
          <span>von</span>
          <p-dropdown
            (onChange)="checkForTimes($event, 'thursday')"
            [(ngModel)]="values.days_with_key.thursday.start_second"
            [disabled]="!values.days_with_key.thursday.checked"
            scrollHeight="500px"
            appendTo="body"
            name="thursday_start_second"
            [options]="times"
            optionValue="value"
            optionLabel="value"
            [editable]="true"
            [required]="values.days_with_key.thursday.checked"
            #thursday_start_second="ngModel"
            [class.p-invalid]="
              errors.thursday_start_second ||
              (thursday_start_second.invalid &&
                (thursday_start_second.dirty || thursday_start_second.touched))
            "
          ></p-dropdown>
          <span>bis</span>
          <p-dropdown
            (onChange)="checkForTimes($event, 'thursday')"
            [(ngModel)]="values.days_with_key.thursday.end_second"
            [disabled]="!values.days_with_key.thursday.checked"
            scrollHeight="500px"
            appendTo="body"
            name="thursday_end_second"
            [options]="times"
            optionValue="value"
            optionLabel="value"
            [editable]="true"
            [required]="values.days_with_key.thursday.checked"
            #thursday_end_second="ngModel"
            [class.p-invalid]="
              errors.thursday_end_second ||
              (thursday_end_second.invalid &&
                (thursday_end_second.dirty || thursday_end_second.touched))
            "
          ></p-dropdown>
          <i
            *ngIf="
              values.days_with_key.thursday.checked &&
              values.days_with_key.thursday.has_second_block
            "
            (click)="removeSecondBlock('thursday')"
            pTooltip="Block entfernen"
            class="pi pi-minus"
          ></i>
        </div>
      </div>
    </div>

    <!-- Freitag -->
    <div class="caregiver-times-container">
      <p-checkbox
        [(ngModel)]="values.days_with_key.friday.checked"
        [binary]="true"
        label="Freitag"
        name="friday"
      ></p-checkbox>
      <div class="caregiver-times-lines">
        <!-- Freitag erster Block -->
        <div class="caregiver-times-line">
          <span>von</span>
          <p-dropdown
            (onChange)="checkForTimes($event, 'friday')"
            [(ngModel)]="values.days_with_key.friday.start_first"
            [disabled]="!values.days_with_key.friday.checked"
            scrollHeight="500px"
            appendTo="body"
            name="friday_start_first"
            [options]="times"
            optionValue="value"
            optionLabel="value"
            [editable]="true"
            [required]="values.days_with_key.friday.checked"
            #friday_start_first="ngModel"
            [class.p-invalid]="
              errors.friday_start_first ||
              (friday_start_first.invalid &&
                (friday_start_first.dirty || friday_start_first.touched))
            "
          ></p-dropdown>
          <span>bis</span>
          <p-dropdown
            (onChange)="checkForTimes($event, 'friday')"
            [(ngModel)]="values.days_with_key.friday.end_first"
            [disabled]="!values.days_with_key.friday.checked"
            scrollHeight="500px"
            appendTo="body"
            name="friday_end_first"
            [options]="times"
            optionValue="value"
            optionLabel="value"
            [editable]="true"
            [required]="values.days_with_key.friday.checked"
            #friday_end_first="ngModel"
            [class.p-invalid]="
              errors.friday_end_first ||
              (friday_end_first.invalid &&
                (friday_end_first.dirty || friday_end_first.touched))
            "
          ></p-dropdown>
          <i
            (click)="setSecondBlock('friday')"
            *ngIf="
              values.days_with_key.friday.checked &&
              !values.days_with_key.friday.has_second_block
            "
            pTooltip="Neuer Block"
            class="pi pi-plus"
          ></i>
        </div>

        <!-- Freitag zweiter Block -->
        <div
          class="caregiver-times-line"
          *ngIf="values.days_with_key.friday.has_second_block"
        >
          <span>von</span>
          <p-dropdown
            (onChange)="checkForTimes($event, 'friday')"
            [(ngModel)]="values.days_with_key.friday.start_second"
            [disabled]="!values.days_with_key.friday.checked"
            scrollHeight="500px"
            appendTo="body"
            name="friday_start_second"
            [options]="times"
            optionValue="value"
            optionLabel="value"
            [editable]="true"
            [required]="values.days_with_key.friday.checked"
            #friday_start_second="ngModel"
            [class.p-invalid]="
              errors.friday_start_second ||
              (friday_start_second.invalid &&
                (friday_start_second.dirty || friday_start_second.touched))
            "
          ></p-dropdown>
          <span>bis</span>
          <p-dropdown
            (onChange)="checkForTimes($event, 'friday')"
            [(ngModel)]="values.days_with_key.friday.end_second"
            [disabled]="!values.days_with_key.friday.checked"
            scrollHeight="500px"
            appendTo="body"
            name="friday_end_second"
            [options]="times"
            optionValue="value"
            optionLabel="value"
            [editable]="true"
            [required]="values.days_with_key.friday.checked"
            #friday_end_second="ngModel"
            [class.p-invalid]="
              errors.friday_end_second ||
              (friday_end_second.invalid &&
                (friday_end_second.dirty || friday_end_second.touched))
            "
          ></p-dropdown>
          <i
            *ngIf="
              values.days_with_key.friday.checked &&
              values.days_with_key.friday.has_second_block
            "
            (click)="removeSecondBlock('friday')"
            pTooltip="Block entfernen"
            class="pi pi-minus"
          ></i>
        </div>
      </div>
    </div>

    <!-- Samstag -->
    <div class="caregiver-times-container">
      <p-checkbox
        [(ngModel)]="values.days_with_key.saturday.checked"
        [binary]="true"
        label="Samstag"
        name="saturday"
      ></p-checkbox>
      <div class="caregiver-times-lines">
        <!-- Samstag erster Block -->
        <div class="caregiver-times-line">
          <span>von</span>
          <p-dropdown
            (onChange)="checkForTimes($event, 'saturday')"
            [(ngModel)]="values.days_with_key.saturday.start_first"
            [disabled]="!values.days_with_key.saturday.checked"
            scrollHeight="500px"
            appendTo="body"
            name="saturday_start_first"
            [options]="times"
            optionValue="value"
            optionLabel="value"
            [editable]="true"
            [required]="values.days_with_key.saturday.checked"
            #saturday_start_first="ngModel"
            [class.p-invalid]="
              errors.saturday_start_first ||
              (saturday_start_first.invalid &&
                (saturday_start_first.dirty || saturday_start_first.touched))
            "
          ></p-dropdown>
          <span>bis</span>
          <p-dropdown
            (onChange)="checkForTimes($event, 'saturday')"
            [(ngModel)]="values.days_with_key.saturday.end_first"
            [disabled]="!values.days_with_key.saturday.checked"
            scrollHeight="500px"
            appendTo="body"
            name="saturday_end_first"
            [options]="times"
            optionValue="value"
            optionLabel="value"
            [editable]="true"
            [required]="values.days_with_key.saturday.checked"
            #saturday_end_first="ngModel"
            [class.p-invalid]="
              errors.saturday_end_first ||
              (saturday_end_first.invalid &&
                (saturday_end_first.dirty || saturday_end_first.touched))
            "
          ></p-dropdown>
          <i
            (click)="setSecondBlock('saturday')"
            *ngIf="
              values.days_with_key.saturday.checked &&
              !values.days_with_key.saturday.has_second_block
            "
            pTooltip="Neuer Block"
            class="pi pi-plus"
          ></i>
        </div>

        <!-- Samstag zweiter Block -->
        <div
          class="caregiver-times-line"
          *ngIf="values.days_with_key.saturday.has_second_block"
        >
          <span>von</span>
          <p-dropdown
            (onChange)="checkForTimes($event, 'saturday')"
            [(ngModel)]="values.days_with_key.saturday.start_second"
            [disabled]="!values.days_with_key.saturday.checked"
            scrollHeight="500px"
            appendTo="body"
            name="saturday_start_second"
            [options]="times"
            optionValue="value"
            optionLabel="value"
            [editable]="true"
            [required]="values.days_with_key.saturday.checked"
            #saturday_start_second="ngModel"
            [class.p-invalid]="
              errors.saturday_start_second ||
              (saturday_start_second.invalid &&
                (saturday_start_second.dirty || saturday_start_second.touched))
            "
          ></p-dropdown>
          <span>bis</span>
          <p-dropdown
            (onChange)="checkForTimes($event, 'saturday')"
            [(ngModel)]="values.days_with_key.saturday.end_second"
            [disabled]="!values.days_with_key.saturday.checked"
            scrollHeight="500px"
            appendTo="body"
            name="saturday_end_second"
            [options]="times"
            optionValue="value"
            optionLabel="value"
            [editable]="true"
            [required]="values.days_with_key.saturday.checked"
            #saturday_end_second="ngModel"
            [class.p-invalid]="
              errors.saturday_end_second ||
              (saturday_end_second.invalid &&
                (saturday_end_second.dirty || saturday_end_second.touched))
            "
          ></p-dropdown>
          <i
            *ngIf="
              values.days_with_key.saturday.checked &&
              values.days_with_key.saturday.has_second_block
            "
            (click)="removeSecondBlock('saturday')"
            pTooltip="Block entfernen"
            class="pi pi-minus"
          ></i>
        </div>
      </div>
    </div>

    <!-- Bemerkung -->
    <div class="p-fluid p-formgrid p-grid">
      <div class="p-field p-col-12">
        <label for="comment">Bemerkung *</label>
        <textarea
          id="comment"
          name="comment"
          [rows]="3"
          pInputTextarea
          [(ngModel)]="values.comment"
          [required]="true"
          #comment="ngModel"
          [class.p-invalid]="
            comment.invalid && (comment.dirty || comment.touched)
          "
        ></textarea>
      </div>
    </div>
  </div>

  <div class="dialog-form-footer">
    <i></i>

    <button
      (click)="store()"
      pButton
      label="Speichern"
      type="button"
      [disabled]="submitted"
      [icon]="submitted ? 'pi pi-spin pi-spinner' : 'pi pi-check'"
    ></button>
  </div>
</form>
