import { Component, OnInit } from '@angular/core'
import { TitleService } from 'src/app/services/title.service'

@Component({
  selector: 'app-caregivers-list',
  templateUrl: './caregivers-list.component.html',
})
export class CaregiversListComponent implements OnInit {
  constructor(private titleService: TitleService) {}

  ngOnInit(): void {
    this.titleService.setTitle(`Alle Betreuungskräfte`)
  }
}
