import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core'
import { Router } from '@angular/router'
import { TitleService } from 'src/app/services/title.service'
import { DialogService } from 'primeng/dynamicdialog'
import { AddDesiredDateDialogComponent } from '../../../components/dialogs/add-desired-date-dialog/add-desired-date-dialog.component'
import { DesiredDateService } from '../../../services/desired-date.service'
import {
  DesiredDateModel,
  DesiredDatesResponse,
} from '../../../models/desired-date/desired-date.model'
import { PhoneCallService } from '../../../services/phone-call.service'
import {
  EventBusService,
  GlobalEvent,
} from '../../../services/eventbus.service'
import { Subscription } from 'rxjs'

@Component({
  selector: 'app-desired-dates-incoming',
  templateUrl: './desired-dates-incoming.component.html',
})
export class DesiredDatesIncomingComponent implements OnInit, OnDestroy {
  @ViewChild('dt1') table1: ElementRef | any = null
  // @ViewChild('dt2') table2: ElementRef | any = null

  public globalFilterValue1 = ''
  public globalFilterValue2 = ''
  public loading = false
  private eventBusSubscription: Subscription = new Subscription()

  public desiredDates: DesiredDateModel[] = []

  constructor(
    public phoneCallService: PhoneCallService,
    private dialogService: DialogService,
    private router: Router,
    private eventbus: EventBusService,
    private desiredDateService: DesiredDateService,
    private titleService: TitleService
  ) {}

  ngOnInit(): void {
    this.loadDesiredDates()
    this.listenForEventbus()
  }

  ngOnDestroy(): void {
    this.eventBusSubscription.unsubscribe()
  }

  public filterTable1Global(event: any): void {
    this.table1.filterGlobal(event.target.value, 'contains')
  }

  public openDesiredDateDialog(
    desiredDate: DesiredDateModel | null = null
  ): void {
    this.dialogService.open(AddDesiredDateDialogComponent, {
      header: desiredDate
        ? 'Wunschtermin bearbeiten'
        : 'Wunschtermin erstellen',
      width: '620px',
      styleClass: 'dialog-container',
      data: {
        isEdit: desiredDate !== null,
        ...desiredDate,
      },
    })
  }

  private listenForEventbus(): void {
    this.eventBusSubscription = this.eventbus.subject.subscribe(
      (event: GlobalEvent) => {
        switch (event) {
          case GlobalEvent.DesiredDateChanged:
            this.loadDesiredDates(false)
        }
      }
    )
  }

  private loadDesiredDates(withLoading: boolean = true): void {
    if (withLoading) {
      this.loading = true
    }

    this.desiredDateService.load().subscribe((data: DesiredDateModel[]) => {
      this.desiredDates = data

      if (withLoading) {
        this.loading = false
      }
      setTimeout(() => {
        this.table1 && this.table1.filterGlobal('', 'contains')
      })
    })
  }
}
