import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core'
import { CaregiverService } from '../../../../../services/caregivers.service'
import { ActivatedRoute, ParamMap } from '@angular/router'
import { DialogService } from 'primeng/dynamicdialog'
import {
  EventBusService,
  GlobalEvent,
} from '../../../../../services/eventbus.service'
import { Subscription } from 'rxjs'
import { AccountingReturnModel } from '../../../../../models/accounting/accounting-return.model'
import { DocumentService } from '../../../../../services/document.service'
import { AccountingReturnService } from '../../../../../services/accounting-return.service'
import { HelperService } from '../../../../../services/helper.service'
import { InvoiceDialogComponent } from '../../../../../components/dialogs/invoice-dialog/invoice-dialog.component'
import { InvoiceViewDialogComponent } from '../../../../../components/dialogs/invoice-view-dialog/invoice-view-dialog.component'
import { InvoiceNewDialogComponent } from '../../../../../components/dialogs/invoice-new-dialog/invoice-new-dialog.component'

@Component({
  selector: 'app-patients-detail-accounting-consultings',
  templateUrl: './patients-detail-accounting-consultings.component.html',
  providers: [DialogService],
})
export class PatientsDetailAccountingConsultingsComponent
  implements OnInit, OnDestroy {
  @ViewChild('dt') table: ElementRef | any = null

  public loading = true
  public data: AccountingReturnModel[] = []
  public patientId: string | null = null
  public globalFilterValue = ''

  public correctionReasons: any = {}

  private eventBusSubscription: Subscription = new Subscription()
  private routeSubscription: any = null

  constructor(
    public caregiverService: CaregiverService,
    public documentService: DocumentService,
    private accountingService: AccountingReturnService,
    private route: ActivatedRoute,
    private eventbus: EventBusService,
    private dialogService: DialogService,
    private helperService: HelperService
  ) {}

  ngOnInit(): void {
    this.loadData()
    this.listenForEventbus()
  }

  ngOnDestroy(): void {
    this.eventBusSubscription.unsubscribe()
    this.routeSubscription && this.routeSubscription.unsubscribe()
  }

  public openInvoiceDialog(invoice: any): void {
    if (invoice.status !== 'READY') {
      this.dialogService.open(InvoiceViewDialogComponent, {
        header: 'Rechnung',
        width: '96%',
        height: '90%',
        styleClass: 'dialog-container',
        data: {
          invoice,
        },
      })
    } else {
      this.dialogService.open(InvoiceNewDialogComponent, {
        header: 'Rechnung',
        width: '96%',
        height: '90%',
        styleClass: 'dialog-container',
        data: {
          invoice,
        },
      })
    }
  }

  private listenForEventbus(): void {
    this.eventBusSubscription = this.eventbus.subject.subscribe(
      (event: GlobalEvent) => {
        switch (event) {
          case GlobalEvent.InvoiceChanged:
            this.loadData(false)
        }
      }
    )
  }

  public filterTableGlobal(event: any): void {
    this.table && this.table.filterGlobal(event.target.value.trim(), 'contains')
  }

  public loadData(withLoading: boolean = true): void {
    if (withLoading) {
      this.loading = true
    }

    if (this.route.parent?.parent) {
      this.routeSubscription = this.route.parent.parent.paramMap.subscribe(
        (params: ParamMap) => {
          this.patientId = params.get('id')

          this.accountingService
            .loadConsultingsForPatient(this.patientId || '')
            .subscribe((data: any[]) => {
              this.data = data

              if (withLoading) {
                this.loading = false
              }
            })
        }
      )
    }
  }
}
