import {Component, OnDestroy, OnInit} from '@angular/core'
import { DialogService } from 'primeng/dynamicdialog'
import { debounce } from 'lodash-es'
import { DocumentService } from '../../../services/document.service'
import { PatientService } from '../../../services/patient.service'
import { InvoiceService } from '../../../services/invoice.service'
import { InvoiceViewDialogComponent } from '../../../components/dialogs/invoice-view-dialog/invoice-view-dialog.component'
import { Event, NavigationEnd, Router } from '@angular/router'
import {AuthService} from "../../../services/auth.service";
import {EventBusService, GlobalEvent} from "../../../services/eventbus.service";
import {Subscription} from "rxjs";

@Component({
  selector: 'app-invoices-appointments',
  templateUrl: './invoices-appointments.component.html',
})
export class InvoicesAppointmentsComponent implements OnInit, OnDestroy {
  public globalFilterValue = ''
  public loading = false
  public correctionReasons: any = {}

  public results: any = {}
  public showSearchField = false
  private eventBusSubscription: Subscription = new Subscription()

  constructor(
    public documentService: DocumentService,
    private dialogService: DialogService,
    private router: Router,
    public authService: AuthService,
    private eventbus: EventBusService,
    public patientService: PatientService,
    private invoiceService: InvoiceService
  ) {}

  ngOnInit(): void {
    this.filterTableGlobal = debounce(this.filterTableGlobal, 600)

    // Das Suchfeld soll nur für bestimmte Views angezeigt werden.
    this.setSearchFieldAsActive(this.router.routerState.snapshot.url)
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.setSearchFieldAsActive(event.url)
      }
    })

    this.listenForEventbus()
  }

  ngOnDestroy(): void {
    this.eventBusSubscription.unsubscribe()
  }

  private listenForEventbus(): void {
    this.eventBusSubscription = this.eventbus.subject.subscribe((event) => {
      switch (event) {
        case GlobalEvent.InvoiceChanged:
          this.filterTableGlobal()
          break
      }
    })
  }

  private setSearchFieldAsActive(url: string): void {
    this.globalFilterValue = ''
    this.showSearchField = false

    this.showSearchField = ['/finished', '/storno', '/credit', '/opened'].some(
      (type: string) => {
        return url.includes(type)
      }
    )
  }

  public openInvoiceDialog(invoice: any): void {
    if (invoice.is_storno || invoice.is_credit) {
      return
    }

    this.dialogService.open(InvoiceViewDialogComponent, {
      header: 'Rechnung',
      width: '96%',
      height: '90%',
      styleClass: 'dialog-container',
      data: {
        invoice,
      },
    })
  }

  public filterTableGlobal(): void {
    this.loading = true

    this.invoiceService
      .searchAppointments(this.globalFilterValue)
      .subscribe((results: any) => {
        this.results = results

        this.loading = false
      })
  }
}
