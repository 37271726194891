import { PermissionModel } from './permission.model'
import { UserModel } from '../user/user.model'

export class RoleModel {
  id = 0
  name = ''
  guard_name = ''
  comment = ''
  permission_text = ''
  users_count = 0
  created_at: Date | null = null
  updated_at: Date | null = null
  users: UserModel[] = []
  permissions: PermissionModel[] = []
  permission_ids = []
}
