import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core'
import { CaregiverService } from '../../../../../services/caregivers.service'
import { ActivatedRoute, ParamMap, Router } from '@angular/router'
import { HistoryService } from '../../../../../services/history.service'
import { LatestChangeModel } from '../../../../../models/history/latest-change.model'
import { CaregiverDetailGeneralModel } from '../../../../../models/caregiver/caregiver-detail-general.model'
import { DialogService } from 'primeng/dynamicdialog'
import {
  EventBusService,
  GlobalEvent,
} from '../../../../../services/eventbus.service'
import { Subscription } from 'rxjs'
import { PhoneCallService } from '../../../../../services/phone-call.service'
import { AddCaregiverCarDialogComponent } from '../../../../../components/dialogs/add-caregiver-car-dialog/add-caregiver-car-dialog.component'
import { CaregiverCarModel } from '../../../../../models/car/caregiver-car.model'
import { AddCaregiverJobScopeDialogComponent } from '../../../../../components/dialogs/add-caregiver-job-scope-dialog/add-caregiver-job-scope-dialog.component'
import { AddCaregiverJobDaysDialogComponent } from '../../../../../components/dialogs/add-caregiver-job-days-dialog/add-caregiver-job-days-dialog.component'
import { DiffViewHistoryDialogComponent } from '../../../../../components/dialogs/diff-view-history-dialog/diff-view-history-dialog.component'
import { AddCaregiverEmployeedDialogComponent } from '../../../../../components/dialogs/add-caregiver-employeed-dialog/add-caregiver-employeed-dialog.component'
import { CaregiverEmployeedModel } from '../../../../../models/caregiver/caregiver-employeed.model'
import { AddCaregiverTimesDialogComponent } from '../../../../../components/dialogs/add-caregiver-times-dialog/add-caregiver-times-dialog.component'
import * as dayjs from 'dayjs'

@Component({
  selector: 'app-caregivers-detail-contract-data',
  templateUrl: './caregivers-detail-contract-data.component.html',
})
export class CaregiversDetailContractDataComponent
  implements OnInit, OnDestroy {
  @ViewChild('op') overlayPanel: ElementRef | any = null

  public loading = true
  public caregiver: CaregiverDetailGeneralModel = new CaregiverDetailGeneralModel()
  public latestChanges: LatestChangeModel = new LatestChangeModel()

  private routeSubscription: any = null
  private eventBusSubscription: Subscription = new Subscription()
  public caregiverId: string | null = null

  public persplanData = new LatestChangeModel()
  public currentBudgetTable = ''
  private persplanDates: any = {}

  public openedDetails = {
    job_scope: true,
    employments: true,
    job_days: true,
    past_times: false,
  }

  constructor(
    private caregiverService: CaregiverService,
    public phoneCallService: PhoneCallService,
    private route: ActivatedRoute,
    private eventbus: EventBusService,
    private router: Router,
    private historyService: HistoryService,
    private dialogService: DialogService
  ) {}

  ngOnInit(): void {
    dayjs.locale('de')

    this.persplanDates = {
      last: {
        month: dayjs().subtract(1, 'month').format('MMMM'),
        year: dayjs().subtract(1, 'month').format('YYYY'),
      },
      current: {
        month: dayjs().format('MMMM'),
        year: dayjs().format('YYYY'),
      },
      next: {
        month: dayjs().add(1, 'month').format('MMMM'),
        year: dayjs().add(1, 'month').format('YYYY'),
      },
    }

    if (this.route.parent) {
      this.routeSubscription = this.route.parent.paramMap.subscribe(
        (params: ParamMap) => {
          this.caregiverId = params.get('id')

          this.loadCaregiver()
        }
      )
    }

    this.listenForEventbus()
  }

  ngOnDestroy(): void {
    this.eventBusSubscription.unsubscribe()
    this.routeSubscription && this.routeSubscription.unsubscribe()
  }

  public getLatestChange(
    id: number | string | null | undefined,
    type: 'caregiver' | 'contact_person',
    data: { [key: string]: any }
  ): void {
    const keys = Object.keys(data)

    for (let key of keys) {
      data[key] = data[key] != null ? encodeURIComponent(data[key]) : ''
    }

    this.historyService
      .getLatestChangeMultiple(id || '', type, data)
      .subscribe((date: string) => {
        for (let key of keys) {
          this.latestChanges[key] = date
        }
      })
  }

  public openTimesDialog(): void {
    this.dialogService.open(AddCaregiverTimesDialogComponent, {
      header: 'Arbeitszeiten ändern',
      width: '620px',
      styleClass: 'dialog-container',
      data: {
        // Wir brauchen ein deep clone davon, damit die vorhanden Werte
        // in der Liste nicht überschrieben werden.
        times: JSON.parse(
          JSON.stringify(this.caregiver.caregiver.current_time)
        ),
        worksOnHolidays: this.caregiver.caregiver.works_on_holidays,
        caregiver_id: this.caregiver.caregiver.id,
      },
    })
  }

  public openDiffViewHistory(
    id: number | string | null | undefined,
    type: 'caregiver' | 'contact_person',
    data: { [key: string]: any }
  ): void {
    const keys = Object.keys(data)

    for (let key of keys) {
      data[key] = data[key] != null ? encodeURIComponent(data[key]) : ''
    }

    this.dialogService.open(DiffViewHistoryDialogComponent, {
      data: {
        id,
        type,
        data,
      },
      header: 'Änderungen ansehen',
      styleClass: 'dialog-diff-view',
      dismissableMask: true,
    })
  }

  public loadCaregiver(withLoading: boolean = true): void {
    if (withLoading) {
      this.loading = true
    }

    this.caregiverService
      .load(this.caregiverId)
      .subscribe((data: CaregiverDetailGeneralModel) => {
        this.caregiver = data

        if (withLoading) {
          this.loading = false
        }
      })
  }

  public openAddCaregiverCarDialog(car: CaregiverCarModel | null = null): void {
    this.dialogService.open(AddCaregiverCarDialogComponent, {
      header: car ? 'PKW bearbeiten' : 'PKW hinzufügen',
      width: '450px',
      styleClass: 'dialog-container',
      data: {
        isEdit: !!car,
        from_type: 'caregiver',
        caregiver: this.caregiver.caregiver,
        car: { ...car },
      },
    })
  }

  public openAddCaregiverJobScopeDialog(): void {
    this.dialogService.open(AddCaregiverJobScopeDialogComponent, {
      header: 'Stellenumfang ändern',
      width: '550px',
      styleClass: 'dialog-container',
      data: {
        isEdit: false,
        caregiver: this.caregiver.caregiver,
      },
    })
  }

  public openAddCaregiverJobDaysDialog(): void {
    this.dialogService.open(AddCaregiverJobDaysDialogComponent, {
      header: 'Arbeitszeit ändern',
      width: '550px',
      styleClass: 'dialog-container',
      data: {
        isEdit: false,
        caregiver: this.caregiver.caregiver,
      },
    })
  }

  public openBudgetOverlay(event: any, text: string): void {
    this.currentBudgetTable = text

    this.overlayPanel.toggle(event)
  }

  public getPersplanBudget(
    caregiverId: number | null | undefined,
    type: 'current' | 'next' | 'last'
  ): void {
    if (this.persplanData[type + caregiverId]) {
      return
    }

    this.caregiverService
      .getPersplanMonth(
        caregiverId ? caregiverId.toString() : '',
        this.persplanDates[type].month,
        this.persplanDates[type].year,
        true
      )
      .subscribe((response: string) => {
        this.persplanData[type + caregiverId] = response
      })
  }

  public openAddCaregiverEmployeedDialog(
    employment: CaregiverEmployeedModel | null = null
  ): void {
    this.dialogService.open(AddCaregiverEmployeedDialogComponent, {
      header: employment ? 'Anstellung bearbeiten' : 'Anstellung hinzufügen',
      width: '620px',
      styleClass: 'dialog-container',
      data: {
        isEdit: !!employment,
        caregiver: this.caregiver.caregiver,
        employment: { ...employment },
      },
    })
  }

  private listenForEventbus(): void {
    this.eventBusSubscription = this.eventbus.subject.subscribe(
      (event: GlobalEvent) => {
        switch (event) {
          case GlobalEvent.CaregiverDetailReload:
          case GlobalEvent.TimesChanged:
          case GlobalEvent.CaregiverStatusChanged:
          case GlobalEvent.NoticeChanged:
            this.loadCaregiver(false)
        }
      }
    )
  }
}
