<form #form="ngForm" class="dialog-form">
  <div class="dialog-form-body">
    <div class="p-fluid p-formgrid p-grid without-border">
      <div class="p-field p-col-4">
        <label>Benutzername *</label>
        <input
          [(ngModel)]="values.name"
          id="name"
          name="name"
          pInputText
          [required]="true"
          #name="ngModel"
          [class.p-invalid]="name.invalid && (name.dirty || name.touched)"
        />
      </div>

      <div class="p-field p-col-4">
        <label for="email_multiplier"
          >E-Mail *<i class="pi pi-info-circle" pTooltip="Für den Login."></i
        ></label>
        <input
          [(ngModel)]="values.email"
          id="email_multiplier"
          name="email_multiplier"
          type="email"
          pInputText
          [required]="true"
          #email="ngModel"
          [class.p-invalid]="email.invalid && (email.dirty || email.touched)"
          email
        />

        <div
          *ngIf="email.invalid && (email.dirty || email.touched)"
          class="p-invalid"
        >
          <div *ngIf="email.errors?.email">
            <small>Keine richtige E-Mail</small>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="dialog-form-footer">
    <div class="dialog-footer-action-container">
      <div class="p-field-checkbox">
        <p-checkbox
          name="todo"
          [binary]="true"
          label="Account aktiv"
          [(ngModel)]="values.is_active"
        ></p-checkbox>
      </div>
    </div>

    <button
      (click)="save()"
      pButton
      label="Speichern"
      type="button"
      [disabled]="submitted"
      [icon]="submitted ? 'pi pi-spin pi-spinner' : 'pi pi-check'"
    ></button>
  </div>
</form>
