import { CustomerModel } from './customer.model'
import { PatientModel } from './patient.model'
import { TodoModel } from '../todo/todo.model'
import { HistoryListModel } from '../history/history-list.model'

export class CustomerDetailGeneralModel {
  customer: CustomerModel = new CustomerModel()
  todos: TodoModel[] = []
  last_histories: CustomerDetailGeneralLastHistoriesModel = new CustomerDetailGeneralLastHistoriesModel()
  first_patient: PatientModel = new PatientModel()
  second_patient: PatientModel = new PatientModel()
}

export class CustomerDetailGeneralLastHistoriesModel {
  first_patient: PatientDetailHistoryModel = new PatientDetailHistoryModel()
  second_patient: PatientDetailHistoryModel = new PatientDetailHistoryModel()
}

export class PatientDetailHistoryModel {
  patient_type = ''
  full_name = ''
  id = 0
  status = ''
  status_string = ''
  histories: HistoryListModel[] = []
}
