import { Component, ElementRef, OnInit, ViewChild } from '@angular/core'
import { CaregiverService } from '../../../../services/caregivers.service'
import { CaregiverListDeactivatedModel } from '../../../../models/caregiver/caregiver-list-deactivated.model'
import {AuthService} from "../../../../services/auth.service";

@Component({
  selector: 'app-caregivers-list-applicants-deactivated',
  templateUrl: './caregivers-list-applicants-deactivated.component.html',
  styleUrls: ['./caregivers-list-applicants-deactivated.component.scss'],
})
export class CaregiversListApplicantsDeactivatedComponent implements OnInit {
  @ViewChild('dt') table: ElementRef | any = null

  public globalFilterValue = ''
  public loading = true
  public caregivers: CaregiverListDeactivatedModel[] = []
  public firstPage = 0

  constructor(public authService: AuthService, private caregiverService: CaregiverService) {}

  ngOnInit(): void {
    this.loadCaregivers()
  }

  public filterTableGlobal(event: any): void {
    this.table && this.table.filterGlobal(event.target.value.trim(), 'contains')
  }

  public loadCaregivers(): void {
    this.loading = true

    this.caregiverService
      .listApplicantsDeactivated()
      .subscribe((data: CaregiverListDeactivatedModel[]) => {
        this.caregivers = data

        this.loading = false

        setTimeout(() => {
          if (this.table) {
            const totalItems = this.table._totalRecords

            this.globalFilterValue = this.table.filters.global?.value ?? ''

            if (
              this.table._first > totalItems ||
              this.table._rows > totalItems
            ) {
              this.firstPage = 0
            }
          }
        })
      })
  }
}
