<section class="auth-section">
  <i class="blob-1"></i>
  <i class="blob-2"></i>

  <h2>Neues Passwort vergeben</h2>

  <div class="login-body">
    <p>Bitte ändern Sie Ihr initiales Passwort.</p>

    <form (ngSubmit)="form.form.valid && confirm()" #form="ngForm">
      <div class="p-field">
        <label for="new_password">Neues Passwort</label>
        <p-password
          name="new_password"
          [(ngModel)]="data.password"
          [toggleMask]="true"
          [feedback]="false"
          [required]="true"
        ></p-password>
        <small class="color-gray"
          >Das Passwort muss mindestens <b>8 Zeichen</b> lang sein. <br />
          Es muss <b>Groß- und Kleinbuchstaben</b> sowie
          <b>Sonderzeichen</b> enthalten.</small
        >
      </div>

      <div class="p-field">
        <label for="new_password_confirmation">Neues Passwort bestätigen</label>
        <p-password
          name="new_password_confirmation"
          [(ngModel)]="data.password_confirmation"
          [feedback]="false"
          [required]="true"
          [toggleMask]="true"
        ></p-password>
      </div>

      <small class="color-alert auth-message" *ngIf="errorMessage">{{
        errorMessage
      }}</small>

      <div class="login-footer">
        <i></i>
        <button
          pButton
          label="Speichern und einloggen"
          type="submit"
          iconPos="right"
          [icon]="submitted ? 'pi pi-spin pi-spinner' : 'pi pi-key'"
        ></button>
      </div>
    </form>
  </div>
</section>
