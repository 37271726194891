import { Component, OnDestroy, OnInit } from '@angular/core'
import { ActivatedRoute, ParamMap } from '@angular/router'
import { DialogService } from 'primeng/dynamicdialog'
import {
  EventBusService,
  GlobalEvent,
} from '../../../../services/eventbus.service'
import { Subscription } from 'rxjs'
import { AddVetoDialogComponent } from '../../../../components/dialogs/add-veto-dialog/add-veto-dialog.component'
import { VetoModel } from '../../../../models/veto/veto.model'
import { PatientService } from '../../../../services/patient.service'
import { CustomerDetailVetoModel } from '../../../../models/customer-patient/patient-detail-veto.model'

@Component({
  selector: 'app-patients-detail-veto',
  templateUrl: './patients-detail-veto.component.html',
  providers: [DialogService],
})
export class PatientsDetailVetoComponent implements OnInit, OnDestroy {
  public loading = true

  public patients: CustomerDetailVetoModel = new CustomerDetailVetoModel()
  private patientId: string | null = null
  public selectedPatient: 'first_patient' | 'second_patient' = 'first_patient'

  private eventBusSubscription: Subscription = new Subscription()

  constructor(
    public patientService: PatientService,
    private route: ActivatedRoute,
    private eventbus: EventBusService,
    private dialogService: DialogService
  ) {}

  ngOnInit(): void {
    this.loadVetos()
    this.listenForEventbus()
  }

  ngOnDestroy(): void {
    this.eventBusSubscription.unsubscribe()
  }

  private listenForEventbus(): void {
    this.eventBusSubscription = this.eventbus.subject.subscribe(
      (event: GlobalEvent) => {
        switch (event) {
          case GlobalEvent.VetoChanged:
            this.loadVetos(false)
        }
      }
    )
  }

  public openVetoDialog(veto: VetoModel | null = null): void {
    this.dialogService.open(AddVetoDialogComponent, {
      header: veto ? 'Veto bearbeiten' : 'Neues Veto',
      width: '450px',
      styleClass: 'dialog-container',
      data: {
        isEdit: veto !== null,
        veto: { ...veto },
        type: 'patient',
        patient_id: this.patients[this.selectedPatient].id,
      },
    })
  }

  public changePatient(type: 'first_patient' | 'second_patient'): void {
    this.selectedPatient = type
  }

  public loadVetos(withLoading: boolean = true): void {
    if (withLoading) {
      this.loading = true
    }

    if (this.route.parent) {
      this.patientId = this.route.parent.snapshot.params.id

      this.patientService
        .vetos(this.patientId)
        .subscribe((data: CustomerDetailVetoModel) => {
          this.patients = data

          if (withLoading) {
            this.loading = false
          }
        })
    }
  }
}
