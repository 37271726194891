import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { environment } from '../../environments/environment'
import { HttpClient } from '@angular/common/http'

@Injectable({
  providedIn: 'root',
})
export class NoticeService {
  constructor(private http: HttpClient) {}

  public updateNotice(
    type_id: number | null,
    type: string,
    notice: string | null
  ): Observable<[]> {
    return this.http.put<[]>(`${environment.api}/notice`, {
      notice,
      type_id,
      type,
    })
  }

  public updateNoticeAccounting(
    type_id: number | null,
    type: string,
    notice: string | null
  ): Observable<[]> {
    return this.http.put<[]>(`${environment.api}/notice-accounting`, {
      notice,
      type_id,
      type,
    })
  }

  public loadSepaDocuments(customerId: number | null): Observable<any> {
    return this.http.get<any>(
      `${environment.api}/notice-sepa-documents/${customerId}`
    )
  }
}
