import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, ParamMap, Router } from '@angular/router'
import { HistoryService } from '../../../../../services/history.service'
import { LatestChangeModel } from '../../../../../models/history/latest-change.model'
import { UserSystemService } from '../../../../../services/user-system.service'
import { UserSystemDetailGeneralModel } from '../../../../../models/user/user-system-detail-general.model'
import { NoticeDialogComponent } from '../../../../../components/dialogs/notice-dialog/notice-dialog.component'
import { DialogService } from 'primeng/dynamicdialog'
import { MenuItem } from 'primeng/api'
import { PhoneCallService } from '../../../../../services/phone-call.service'
import { DiffViewHistoryDialogComponent } from '../../../../../components/dialogs/diff-view-history-dialog/diff-view-history-dialog.component'

@Component({
  selector: 'app-users-detail-general',
  templateUrl: './users-detail-general.component.html',
})
export class UsersDetailGeneralComponent implements OnInit {
  public loading = true
  public userSystem: UserSystemDetailGeneralModel = new UserSystemDetailGeneralModel()
  public latestChanges: LatestChangeModel = new LatestChangeModel()
  public settingMenuItems: MenuItem[] = [
    {
      label: 'Notiz',
      icon: 'pi pi-align-left',
      command: () => this.openNoticeDialog(),
    },
  ]

  constructor(
    private userSystemService: UserSystemService,
    private route: ActivatedRoute,
    private dialogService: DialogService,
    public phoneCallService: PhoneCallService,
    private router: Router,
    private historyService: HistoryService
  ) {}

  ngOnInit(): void {
    this.loadUserSystem()
  }

  public getLatestChange(
    id: number | string | null | undefined,
    type: 'user_system',
    data: { [key: string]: any }
  ): void {
    const keys = Object.keys(data)

    for (let key of keys) {
      data[key] = data[key] != null ? encodeURIComponent(data[key]) : ''
    }

    this.historyService
      .getLatestChangeMultiple(id || '', type, data)
      .subscribe((date: string) => {
        for (let key of keys) {
          this.latestChanges[key] = date
        }
      })
  }

  public openDiffViewHistory(
    id: number | string | null | undefined,
    type: 'user_system',
    data: { [key: string]: any }
  ): void {
    const keys = Object.keys(data)

    for (let key of keys) {
      data[key] = data[key] != null ? encodeURIComponent(data[key]) : ''
    }

    this.dialogService.open(DiffViewHistoryDialogComponent, {
      data: {
        id,
        type,
        data,
      },
      header: 'Änderungen ansehen',
      styleClass: 'dialog-diff-view',
      dismissableMask: true,
    })
  }

  public openNoticeDialog(): void {
    this.dialogService.open(NoticeDialogComponent, {
      header: 'Notiz bearbeiten',
      width: '450px',
      styleClass: 'dialog-container',
      data: {
        type: 'user_system',
        type_id: this.userSystem.id,
        notice: this.userSystem.notice,
      },
    })
  }

  public loadUserSystem(): void {
    this.loading = true

    this.route.paramMap.subscribe((params: ParamMap) => {
      this.userSystemService
        .load(params.get('id'))
        .subscribe((data: UserSystemDetailGeneralModel) => {
          this.userSystem = data

          this.loading = false
        })
    })
  }
}
