import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core'
import { PhoneCallService } from '../../../services/phone-call.service'
import { AuthService } from '../../../services/auth.service'
import { ConsultingService } from '../../../services/consulting.service'
import { InvoiceDialogComponent } from '../../../components/dialogs/invoice-dialog/invoice-dialog.component'
import { ConsultingConfirmDialogComponent } from '../../../components/dialogs/consulting-confirm-dialog/consulting-confirm-dialog.component'
import { ConsultingDialogComponent } from '../../../components/dialogs/consulting-dialog/consulting-dialog.component'
import { DialogService } from 'primeng/dynamicdialog'
import {
  EventBusService,
  GlobalEvent,
} from '../../../services/eventbus.service'
import { Subscription } from 'rxjs'
import { InvoiceViewDialogComponent } from '../../../components/dialogs/invoice-view-dialog/invoice-view-dialog.component'
import { InvoiceNewDialogComponent } from '../../../components/dialogs/invoice-new-dialog/invoice-new-dialog.component'

@Component({
  selector: 'app-consultings-list-finished',
  templateUrl: './consultings-list-finished.component.html',
  styleUrls: ['./consultings-list-finished.component.scss'],
})
export class ConsultingsListFinishedComponent implements OnInit, OnDestroy {
  @ViewChild('dt') table: ElementRef | any = null

  public isSuperAdmin = false
  private eventBusSubscription: Subscription = new Subscription()

  public globalFilterValue = ''
  public loading = true
  public consultings: any[] = []
  public firstPage = 0

  constructor(
    public phoneCallService: PhoneCallService,
    private authService: AuthService,
    private dialogService: DialogService,
    private eventbus: EventBusService,
    private consultingService: ConsultingService
  ) {}

  ngOnInit(): void {
    this.isSuperAdmin = this.authService.isSuperAdmin()

    this.loadData()
    this.listenForEventbus()
  }

  ngOnDestroy(): void {
    this.eventBusSubscription.unsubscribe()
  }

  private listenForEventbus(): void {
    this.eventBusSubscription = this.eventbus.subject.subscribe(
      (event: GlobalEvent) => {
        switch (event) {
          case GlobalEvent.InvoiceChanged:
          case GlobalEvent.ConsultingChanged:
            this.loadData(false)
        }
      }
    )
  }

  public filterTableGlobal(event: any): void {
    this.table && this.table.filterGlobal(event.target.value.trim(), 'contains')
  }

  public openMediaFromUuid(uuid: string): void {
    window.open(this.consultingService.getDocumentDownloadLink(uuid))
  }

  public openInvoiceDialog(invoice: any): void {
    if (invoice.status !== 'READY') {
      this.dialogService.open(InvoiceViewDialogComponent, {
        header: 'Rechnung',
        width: '96%',
        height: '90%',
        styleClass: 'dialog-container',
        data: {
          invoice,
        },
      })
    } else {
      this.dialogService.open(InvoiceNewDialogComponent, {
        header: 'Rechnung',
        width: '96%',
        height: '90%',
        styleClass: 'dialog-container',
        data: {
          invoice,
        },
      })
    }
  }

  public openConsultingConfirmDialog(consulting: any): void {
    this.dialogService.open(ConsultingConfirmDialogComponent, {
      header: 'Beratungseinsatz bestätigen',
      width: '490px',
      styleClass: 'dialog-container',
      data: {
        consulting: { ...consulting },
      },
    })
  }

  public openConsultingEditDialog(consulting: any): void {
    this.dialogService.open(ConsultingDialogComponent, {
      header: 'Beratungseinsatz bearbeiten',
      width: '650px',
      styleClass: 'dialog-container',
      data: {
        isNew: false,
        consulting: { ...consulting },
        patient: { ...consulting.patient },
      },
    })
  }

  public loadData(withLoading: boolean = true): void {
    if (withLoading) {
      this.loading = true
    }
    this.consultingService.loadFinished().subscribe((data: any[]) => {
      this.consultings = data

      if (withLoading) {
        this.loading = false
      }

      setTimeout(() => {
        if (this.table) {
          const totalItems = this.table._totalRecords

          this.globalFilterValue = this.table.filters.global?.value ?? ''

          if (
            this.table._first > totalItems ||
            this.table._rows > totalItems
          ) {
            this.firstPage = 0
          }
        }
      })
    })
  }
}
