<div class="content-actions">
  <h2>
    <i class="tio">heart_outlined</i>
    Betreuungskräfte
  </h2>
</div>

<header class="content-header-tabs">
  <a
    routerLink="/caregivers"
    routerLinkActive="active"
    [routerLinkActiveOptions]="{ exact: true }"
  >
    Aktiv
  </a>
  <a
    routerLink="/caregivers/deactivated"
    routerLinkActive="active"
    [routerLinkActiveOptions]="{ exact: true }"
  >
    Abgesagt / KI
  </a>
</header>

<router-outlet></router-outlet>
