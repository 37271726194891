import { ContactPersonModel } from '../contact-person/contact-person.model'
import { HouseholdModel } from './household.model'
import { QmModel } from './qm.model'

export class CustomerModel {
  id: number | null = null
  patient_type = ''
  offer_type = 'OFFER'
  created_from_multiplier = false
  created_from_caregiver = false
  created_as_offer_type = 'OFFER'
  consulting_assignment = false
  created_as_consulting_assignment = false
  customer_service = ''
  start_of_operation = ''
  service_scope = ''
  notice = ''
  notice_accounting = ''
  start_of_operation_date: Date | null = null
  start_of_operation_date_string = ''
  summary_customer = ''
  summary_from_recommendation = ''
  comment_customer = ''
  summary_intern = ''
  attention_from_id: number | null = null
  attention_from_string = ''
  attention_from_info = ''
  recommendation_type: string | null = null
  recommendation_id = null
  recommendation: any = {}
  lng_location: number | null = null
  lat_location: number | null = null
  deleted_at: Date | null = null
  created_at: Date | null = null
  updated_at: Date | null = null
  contact_persons: ContactPersonModel[] = []
  household: HouseholdModel = new HouseholdModel()
  qm: QmModel = new QmModel()
}
