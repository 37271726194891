import { Injectable } from '@angular/core'
import { HttpClient, HttpParams } from '@angular/common/http'
import { environment } from '../../environments/environment'
import { Observable } from 'rxjs'
import { QmModel } from '../models/customer-patient/qm.model'

@Injectable({
  providedIn: 'root',
})
export class CalendarService {
  constructor(private http: HttpClient) {}

  public load(id: number): Observable<any> {
    return this.http.get<any>(`${environment.api}/calendar/${id}`)
  }

  public update(id: number, data: any): Observable<any> {
    return this.http.put<any>(`${environment.api}/calendar/${id}`, data)
  }

  public store(data: any, calendarType: string): Observable<any> {
    return this.http.post<any>(`${environment.api}/calendar`, {
      ...data,
      calendarType,
    })
  }

  public remove(calendarId: number): Observable<any> {
    return this.http.delete<any>(`${environment.api}/calendar/${calendarId}`)
  }
}
