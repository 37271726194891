<div class="content">
  <header class="content-header">
    <div class="content-header-menu-tabs">
      <a
        routerLink="/invoices/appointments"
        routerLinkActive="active"
        [routerLinkActiveOptions]="{ exact: true }"
      >
        Entwürfe
      </a>

      <a
        routerLink="/invoices/appointments/opened"
        routerLinkActive="active"
        [routerLinkActiveOptions]="{ exact: true }"
      >
        Offen
      </a>

      <a
        routerLink="/invoices/appointments/finished"
        routerLinkActive="active"
        [routerLinkActiveOptions]="{ exact: true }"
      >
        Abgeschlossen
      </a>

      <a
        routerLink="/invoices/appointments/credits"
        routerLinkActive="active"
        [routerLinkActiveOptions]="{ exact: true }"
      >
        Gutschriften
      </a>

      <a
        routerLink="/invoices/appointments/storno"
        routerLinkActive="active"
        [routerLinkActiveOptions]="{ exact: true }"
      >
        Nicht fakturiert
      </a>
    </div>

    <div class="content-header-buttons" *ngIf="showSearchField">
      <div class="table-search-container">
        <div class="p-input-icon-left">
          <i class="pi pi-search"></i>
          <input
            pInputText
            (input)="loading = true; filterTableGlobal()"
            placeholder="Globale Suche..."
            [(ngModel)]="globalFilterValue"
          />
        </div>
      </div>
    </div>
  </header>
</div>

<app-loader *ngIf="globalFilterValue" [loading]="loading"></app-loader>

<div class="content" *ngIf="!loading && globalFilterValue">
  <header class="content-header" *ngIf="results.invoices.length > 0">
    <span></span>
  </header>

  <div class="content-body" *ngIf="results.invoices.length == 0">
    <span class="content-no-message">Keine Rechnungen gefunden</span>
  </div>

  <div class="content-body without-padding" *ngIf="results.invoices.length > 0">
    <p-table
      #dt
      [value]="results.invoices"
      scrollHeight="100%"
      [showCurrentPageReport]="true"
      currentPageReportTemplate="{totalRecords} Einträge"
      [scrollable]="true"
      [rowHover]="true"
      [paginator]="true"
      [rows]="50"
    >
      <ng-template pTemplate="header">
        <tr>
          <th style="max-width: 150px">Status</th>
          <th pSortableColumn="invoice_type">
            Typ <p-sortIcon field="invoice_type"></p-sortIcon>
          </th>
          <th style="max-width: 150px" pSortableColumn="invoice_number">
            Re-Nr
            <p-sortIcon field="invoice_number"></p-sortIcon>
          </th>
          <th pSortableColumn="invoiceable.patient.last_name">
            Patient
            <p-sortIcon field="invoiceable.patient.last_name"></p-sortIcon>
          </th>
          <th pSortableColumn="invoiceable.month_sort">
            Leistungsmonat
            <p-sortIcon field="invoiceable.month_sort"></p-sortIcon>
          </th>
          <th pSortableColumn="invoiceable.budget_type">
            Leistungsart
            <p-sortIcon field="invoiceable.budget_type"></p-sortIcon>
          </th>
          <th pSortableColumn="invoiceable.accounting_type">
            Abrechnungsstelle
            <p-sortIcon field="invoiceable.accounting_type"></p-sortIcon>
          </th>
          <th pSortableColumn="invoiceable.invoice_receiver">
            Rechnungsempfänger
            <p-sortIcon field="invoiceable.invoice_receiver"></p-sortIcon>
          </th>
          <th style="max-width: 140px" pSortableColumn="total_price">
            Gesamtkosten
            <p-sortIcon field="total_price"></p-sortIcon>
          </th>
          <th style="max-width: 100px" pSortableColumn="part_payments_sum">
            Bezahlt
            <p-sortIcon field="part_payments_sum"></p-sortIcon>
          </th>
          <th style="max-width: 100px"></th>
        </tr>
        <tr *ngIf="authService.isSuperAdmin()">
          <th style="max-width: 150px"></th>
          <th></th>
          <th style="max-width: 150px"></th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th style="max-width: 140px">{{ results.total.total_sum }}</th>
          <th style="max-width: 100px">
            {{ results.total.part_payments_sum }}
          </th>
          <th style="max-width: 100px"></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-item>
        <tr class="clickable">
          <td (click)="openInvoiceDialog(item)" style="max-width: 150px">
            <span class="p-tag p-tag-rounded p-tag-{{ item.status }}">{{
              item.status_text
            }}</span>
          </td>
          <td (click)="openInvoiceDialog(item)">
            {{ item.invoice_type }}
          </td>
          <td (click)="openInvoiceDialog(item)" style="max-width: 150px">
            {{ item.invoice_number_complete }}
          </td>
          <td (click)="openInvoiceDialog(item)">
            <a
              routerLink="/patients/{{
                item.invoiceable.patient.id
              }}/consulting"
              >{{ item.invoiceable.patient.last_name }},
              {{ item.invoiceable.patient.first_name }}</a
            >
          </td>
          <td (click)="openInvoiceDialog(item)">
            {{ item.invoiceable?.month }} {{ item.invoiceable?.year }}
          </td>
          <td (click)="openInvoiceDialog(item)">
            {{ item.invoiceable.budget_type_formatted }}
          </td>
          <td (click)="openInvoiceDialog(item)">
            {{ item.invoiceable.accounting_type_string }}
          </td>
          <td (click)="openInvoiceDialog(item)">
            {{ item.invoiceable.invoice_receiver_string }}
          </td>
          <td (click)="openInvoiceDialog(item)" style="max-width: 140px">
            {{ item.total_price_euro_formatted }}
          </td>
          <td style="max-width: 100px" (click)="openInvoiceDialog(item)">
            {{ item.part_payments_sum_euro_formatted }}
          </td>
          <td class="table-action" style="max-width: 100px">
            <a
              *ngIf="item.media[0]"
              [href]="
                documentService.getDocumentDownloadLink(item.media[0].uuid)
              "
              target="_blank"
            >
              <i
                tooltipPosition="left"
                pTooltip="Herunterladen"
                class="pi pi-download"
              ></i>
            </a>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>

<ng-container *ngIf="!globalFilterValue">
  <router-outlet></router-outlet>
</ng-container>
