<div class="content-actions">
  <h2>
    <i class="tio">explore_outlined</i>
    Übersicht

    <span *ngIf="authService.isSuperAdmin()"
      >{{ data.price_changes?.medias_count }} /
      {{ data.price_changes?.patients_count }} Preisänderungen</span
    >

    <span *ngIf="authService.isSuperAdmin()"
      >{{ data.price_changes?.stornos_count }} Kündigen</span
    >
  </h2>
</div>

<div class="content-grid new-content-container" dragula="DASHBOARD">
  <ng-container *ngFor="let type of types">
    <div
      class="content"
      *ngIf="type === 'pause_ending'"
      data-type="pause_ending"
    >
      <header class="content-header">
        <span
          >Pausen die bald Enden
          <i
            class="pi pi-info-circle color-gray"
            pTooltip="Alle Kunden in der die Pausierung vor 3 Tagen oder in den nächsten 7 Tagen beendet sind."
          ></i
        ></span>
        <i
          class="handle pi pi-arrows-alt"
          tooltipPosition="left"
          pTooltip="Verschieben"
        ></i>
      </header>

      <div
        class="content-body content-base-height without-padding has-loading-logo"
        *ngIf="loadings.pause_ending"
      >
        <i class="loading-logo"></i>
      </div>

      <div
        class="content-body content-base-height without-padding"
        *ngIf="data.pause_ending.length === 0 && !loadings.pause_ending"
      >
        <span class="content-no-message">Keine Einträge vorhanden</span>
      </div>

      <div
        class="content-body content-base-height without-padding"
        *ngIf="data.pause_ending.length > 0 && !loadings.pause_ending"
      >
        <p-table
          [value]="data.pause_ending"
          [rowHover]="true"
          [scrollable]="true"
          scrollHeight="100%"
          stateStorage="local"
          stateKey="state-dashboard-pause-ending"
        >
          <ng-template pTemplate="header">
            <tr class="table-headline-highlight">
              <th style="min-width: 300px" pSortableColumn="first_name">
                Name <p-sortIcon field="first_name"></p-sortIcon>
              </th>
              <th pSortableColumn="pause_that_will_end.pause_from">
                von
                <p-sortIcon field="pause_that_will_end.pause_from"></p-sortIcon>
              </th>
              <th pSortableColumn="pause_that_will_end.pause_to">
                bis
                <p-sortIcon field="pause_that_will_end.pause_to"></p-sortIcon>
              </th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-patient>
            <tr>
              <td style="min-width: 300px">
                <a routerLink="/patients/{{ patient.id }}"
                  >{{ patient.first_name }} {{ patient.last_name }}</a
                >
              </td>
              <td>{{ patient.pause_that_will_end.pause_from_string }}</td>
              <td>{{ patient.pause_that_will_end.pause_to_string }}</td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>

    <div
      class="content"
      *ngIf="type === 'important_histories'"
      data-type="important_histories"
    >
      <header class="content-header">
        <span
          >Wichtige Ereignisse
          <i class="pi pi-info-circle color-gray" pTooltip="Die letzten 100"></i
        ></span>
        <i
          class="handle pi pi-arrows-alt"
          tooltipPosition="left"
          pTooltip="Verschieben"
        ></i>
      </header>

      <div
        class="content-body content-base-height without-padding has-loading-logo"
        *ngIf="loadings.important_histories"
      >
        <i class="loading-logo"></i>
      </div>

      <div
        class="content-body content-base-height without-padding"
        *ngIf="
          data.important_histories.length === 0 && !loadings.important_histories
        "
      >
        <span class="content-no-message"
          >Keine wichtigen Ereignisse vorhanden</span
        >
      </div>

      <div
        class="content-body content-base-height without-padding"
        *ngIf="
          data.important_histories.length > 0 && !loadings.important_histories
        "
      >
        <p-table
          #dt
          [value]="data.important_histories"
          [rowHover]="true"
          [scrollable]="true"
          scrollHeight="100%"
        >
          <ng-template pTemplate="header">
            <tr class="table-headline-highlight">
              <th>Name</th>
              <!--            <th pSortableColumn="created_at">-->
              <!--              am <p-sortIcon field="created_at"></p-sortIcon>-->
              <!--            </th>-->
              <!--            <th pSortableColumn="user_system.first_name">-->
              <!--              von <p-sortIcon field="user_system.first_name"></p-sortIcon>-->
              <!--            </th>-->
              <th style="max-width: 40px"></th>
              <th>Informationen</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-history>
            <tr>
              <td>
                <span
                  *ngIf="
                    history.type === 'PHONE_CALL' &&
                    history.historiable_type.includes('Customer')
                  "
                  >{{ history.phone_call.phone_caller_name }}</span
                >

                <a
                  routerLink="/patients/{{ history.historiable_id }}"
                  *ngIf="history.historiable_type.includes('Patient')"
                  >{{ history.historiable?.first_name }}
                  {{ history.historiable?.last_name }}</a
                >

                <a
                  routerLink="/caregivers/{{ history.historiable_id }}"
                  *ngIf="history.historiable_type.includes('Caregiver')"
                  >{{ history.historiable?.first_name }}
                  {{ history.historiable?.last_name }}</a
                >

                <a
                  routerLink="/multipliers/{{ history.historiable_id }}"
                  *ngIf="history.historiable_type.includes('Multiplier')"
                  >{{ history.historiable.name }}</a
                >

                <a
                  routerLink="/administration/users/{{
                    history.historiable_id
                  }}"
                  *ngIf="history.historiable_type.includes('UserSystem')"
                  >{{ history.historiable.first_name }}
                  {{ history.historiable.last_name }}</a
                >

                <a
                  routerLink="/cars/{{ history.historiable_id }}"
                  *ngIf="
                    history.historiable_type.includes('Car') &&
                    !history.historiable_type.includes('Caregiver')
                  "
                  >{{ history.historiable.brand }}
                  {{ history.historiable.model }}</a
                >
              </td>

              <td style="max-width: 40px">
                <i
                  tooltipPosition="left"
                  [pTooltip]="history.manual_description"
                  style="font-size: 16px"
                  class="pi pi-info-circle color-gray"
                  *ngIf="history.manual_description"
                ></i>
                <i
                  tooltipPosition="left"
                  [pTooltip]="history.phone_call.comment"
                  class="pi pi-info-circle color-gray"
                  style="font-size: 16px"
                  *ngIf="
                    !history.manual_description &&
                    history.type === 'PHONE_CALL' &&
                    history.phone_call.comment
                  "
                ></i>
              </td>
              <td>
                <span class="history-information">
                  <i
                    *ngIf="
                      history.type === 'PHONE_CALL' && !history.manual_title
                    "
                    >Telefonat</i
                  >
                  <i *ngIf="history.manual_title">{{ history.manual_title }}</i>
                </span>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>

    <div class="content" *ngIf="type === 'new_created'" data-type="new_created">
      <header class="content-header">
        <span
          >Neu erstellt
          <i
            class="pi pi-info-circle color-gray"
            pTooltip="Alle Kunden die noch qualifiziert werden müssen"
          ></i
        ></span>
        <i
          class="handle pi pi-arrows-alt"
          tooltipPosition="left"
          pTooltip="Verschieben"
        ></i>
      </header>

      <div
        class="content-body content-base-height without-padding has-loading-logo"
        *ngIf="loadings.new_created"
      >
        <i class="loading-logo"></i>
      </div>

      <div
        class="content-body content-base-height without-padding"
        *ngIf="data.new_created.length === 0 && !loadings.new_created"
      >
        <span class="content-no-message">Keine Qualifizierungen offen</span>
      </div>

      <div
        class="content-body content-base-height without-padding"
        *ngIf="data.new_created.length > 0 && !loadings.new_created"
      >
        <p-table
          [value]="data.new_created"
          [rowHover]="true"
          [scrollable]="true"
          scrollHeight="100%"
          stateStorage="local"
          stateKey="state-dashboard-new-created"
        >
          <ng-template pTemplate="header">
            <tr class="table-headline-highlight">
              <th pSortableColumn="first_name">
                Name <p-sortIcon field="first_name"></p-sortIcon>
              </th>
              <th pSortableColumn="customer.qm.initial_consultation_type">
                Beratung
                <p-sortIcon
                  field="customer.qm.initial_consultation_type"
                ></p-sortIcon>
              </th>
              <th
                style="max-width: 150px"
                pSortableColumn="customer.qm.initial_consultation_date"
              >
                Beratung am
                <p-sortIcon
                  field="customer.qm.initial_consultation_date"
                ></p-sortIcon>
              </th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-patient>
            <tr>
              <td>
                <a routerLink="/patients/{{ patient.id }}"
                  >{{ patient.first_name }} {{ patient.last_name }}</a
                >
              </td>
              <td *ngIf="!patient.customer.qm.initial_consultation_type">-</td>
              <td *ngIf="patient.customer.qm.initial_consultation_type">
                {{ patient.customer.qm.initial_consultation_type }} von
                {{ patient.customer.qm.initial_consultation_from || "?" }}
              </td>

              <td style="max-width: 150px">
                {{
                  patient.customer.qm.initial_consultation_date_string || "-"
                }}
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>

    <div
      class="content"
      *ngIf="type === 'vacation_ill'"
      data-type="vacation_ill"
    >
      <header class="content-header">
        <span
          >Urlaub / AU / Wunschfrei
          <i
            class="pi pi-info-circle color-gray"
            pTooltip="Die nächsten 60 Tage"
          ></i>
        </span>
        <i
          class="handle pi pi-arrows-alt"
          tooltipPosition="left"
          pTooltip="Verschieben"
        ></i>
      </header>

      <div
        class="content-body content-base-height without-padding has-loading-logo"
        *ngIf="loadings.vacation_ill"
      >
        <i class="loading-logo"></i>
      </div>

      <div
        class="content-body content-base-height without-padding"
        *ngIf="data.vacation_ill.length === 0 && !loadings.vacation_ill"
      >
        <span class="content-no-message">Keine Einträge vorhanden</span>
      </div>

      <div
        class="content-body content-base-height without-padding"
        *ngIf="data.vacation_ill.length > 0 && !loadings.vacation_ill"
      >
        <p-table
          [value]="data.vacation_ill"
          [rowHover]="true"
          [scrollable]="true"
          scrollHeight="100%"
        >
          <ng-template pTemplate="header">
            <tr class="table-headline-highlight">
              <th style="max-width: 150px" pSortableColumn="date_type">
                Typ <p-sortIcon field="date_type"></p-sortIcon>
              </th>
              <th pSortableColumn="caregiver.first_name">
                Name <p-sortIcon field="caregiver.first_name"></p-sortIcon>
              </th>
              <th pSortableColumn="from">
                von <p-sortIcon field="from"></p-sortIcon>
              </th>
              <th pSortableColumn="to">
                bis <p-sortIcon field="to"></p-sortIcon>
              </th>
              <th style="max-width: 50px"></th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-vacationIll>
            <tr>
              <td style="max-width: 150px">
                <span
                  class="p-tag p-tag-rounded p-tag-{{ vacationIll.date_type }}"
                  >{{ vacationIll.date_type_string }}</span
                >
              </td>
              <td>
                <a routerLink="/caregivers/{{ vacationIll.caregiver_id }}"
                  >{{ vacationIll.caregiver.first_name }}
                  {{ vacationIll.caregiver.last_name }}</a
                >
              </td>
              <td>{{ vacationIll.from | date: "dd.MM.y" }}</td>
              <td>{{ vacationIll.to | date: "dd.MM.y" }}</td>
              <td style="max-width: 50px">
                <i
                  tooltipPosition="left"
                  [pTooltip]="vacationIll.comment"
                  style="font-size: 16px"
                  class="pi pi-info-circle color-gray"
                  *ngIf="vacationIll.comment"
                ></i>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>

    <div class="content" *ngIf="type === 'phone_calls'" data-type="phone_calls">
      <header class="content-header">
        <span
          >Deine letzten Telefonate
          <i class="pi pi-info-circle color-gray" pTooltip="Die letzten 100"></i
        ></span>
        <i
          class="handle pi pi-arrows-alt"
          tooltipPosition="left"
          pTooltip="Verschieben"
        ></i>
      </header>

      <div
        class="content-body content-base-height without-padding has-loading-logo"
        *ngIf="loadings.phone_calls"
      >
        <i class="loading-logo"></i>
      </div>

      <div
        class="content-body content-base-height without-padding"
        *ngIf="data.phone_calls.length === 0 && !loadings.phone_calls"
      >
        <span class="content-no-message">Keine Telefonate vorhanden</span>
      </div>

      <div
        class="content-body content-base-height without-padding"
        *ngIf="data.phone_calls.length > 0 && !loadings.phone_calls"
      >
        <p-table
          [value]="data.phone_calls"
          [rowHover]="true"
          [scrollable]="true"
          scrollHeight="100%"
        >
          <ng-template pTemplate="header">
            <tr class="table-headline-highlight">
              <th style="max-width: 120px">Anruf</th>
              <th>Nummer</th>
              <th>Name</th>
              <th pSortableColumn="created_at">
                um <p-sortIcon field="created_at"></p-sortIcon>
              </th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-call>
            <tr>
              <td style="max-width: 120px">
                <span
                  *ngIf="call.we_called"
                  class="p-tag p-tag-success p-tag-rounded"
                  >Von uns</span
                >
                <span *ngIf="!call.we_called" class="p-tag p-tag-rounded"
                  >An uns</span
                >
              </td>

              <td *ngIf="call.number">
                <a
                  (click)="phoneCallService.createCalling(call.number)"
                  href="tel:{{ call.number }}"
                  >{{ call.number }}</a
                >
              </td>
              <td *ngIf="!call.number">Unbekannt</td>

              <td>{{ call.phone_caller_name || "Unbekannt" }}</td>
              <td>{{ call.created_at | date: "dd.MM.y, H:mm" }} Uhr</td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>

    <div class="content" *ngIf="type === 'birthdays'" data-type="birthdays">
      <header class="content-header">
        <span
          >Geburtstage / Jubiläum
          <i
            class="pi pi-info-circle color-gray"
            pTooltip="In den letzten 3 und nächsten 7 Tagen"
          ></i
        ></span>
        <i
          class="handle pi pi-arrows-alt"
          tooltipPosition="left"
          pTooltip="Verschieben"
        ></i>
      </header>

      <div
        class="content-body content-base-height without-padding has-loading-logo"
        *ngIf="loadings.birthdays"
      >
        <i class="loading-logo"></i>
      </div>

      <div
        class="content-body content-base-height without-padding"
        *ngIf="data.birthdays.length === 0 && !loadings.birthdays"
      >
        <span class="content-no-message">Keine anstehenden Geburtstage</span>
      </div>

      <div
        class="content-body content-base-height without-padding"
        *ngIf="data.birthdays.length > 0 && !loadings.birthdays"
      >
        <p-table
          [value]="data.birthdays"
          [rowHover]="true"
          [scrollable]="true"
          scrollHeight="100%"
        >
          <ng-template pTemplate="header">
            <tr class="table-headline-highlight">
              <th style="max-width: 150px">Status</th>
              <th>Name</th>
              <th pSortableColumn="birthday_day_month_format">
                Datum
                <p-sortIcon field="birthday_day_month_format"></p-sortIcon>
              </th>
              <th style="max-width: 50px"></th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-data>
            <tr [class.finished]="data.already_wished">
              <td style="max-width: 150px">
                <span
                  *ngIf="
                    data.status && data.type === 'patient' && !data.is_paused
                  "
                  class="p-tag p-tag-rounded p-tag-{{ data.status }}"
                  >{{ data.status_string }}</span
                >
                <span
                  *ngIf="
                    data.status && data.type === 'patient' && data.is_paused
                  "
                  class="p-tag p-tag-rounded p-tag-PAUSED"
                  >Pausiert</span
                >

                <span
                  *ngIf="data.type === 'caregiver' && data.anniversary"
                  class="p-tag p-tag-rounded p-tag-GRAY"
                  >BK: Jubiläum</span
                >
                <span
                  *ngIf="data.type === 'caregiver' && !data.anniversary"
                  class="p-tag p-tag-rounded p-tag-GRAY"
                  >BK: Geburtstag</span
                >

                <span
                  *ngIf="data.type === 'user_system'"
                  class="p-tag p-tag-rounded p-tag-GRAY"
                  >Benutzer</span
                >
              </td>
              <td>
                <a
                  routerLink="/patients/{{ data.id }}"
                  *ngIf="data.type === 'patient'"
                  >{{ data.first_name }} {{ data.last_name }}</a
                >

                <a
                  routerLink="/caregivers/{{ data.id }}"
                  *ngIf="data.type === 'caregiver'"
                  >{{ data.first_name }} {{ data.last_name }}</a
                >

                <a
                  routerLink="/administration/users/{{ data.id }}"
                  *ngIf="data.type === 'user_system'"
                  >{{ data.first_name }} {{ data.last_name }}</a
                >
              </td>
              <td>
                {{ data.birthday_date_string || data.anniversary_date_string }}
              </td>

              <td
                [pTooltip]="
                  data.already_wished
                    ? data.already_wished.manual_description +
                      ' von ' +
                      data.already_wished.created_from_name
                    : 'Eintrag erstellen'
                "
                [class.color-main1]="data.already_wished"
                [class.clickable]="!data.already_wished"
                (click)="openManualHistoryDialog(data)"
                tooltipPosition="left"
                style="max-width: 50px"
              >
                <i class="pi pi-check"></i>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>

    <div class="content" *ngIf="type === 'todos'" data-type="todos">
      <header class="content-header">
        <span
          >Offene Todos<i
            class="pi pi-info-circle color-gray"
            tooltipStyleClass="tooltip-wider"
            pTooltip="Allgemeine, dir zugewiesene oder keinem zugewiesen"
          ></i
        ></span>

        <div class="content-header-buttons">
          <span>
            <p-checkbox
              label="nur mir zugewiesene"
              [(ngModel)]="settings.only_assigned"
              (onChange)="changedSettings()"
              [binary]="true"
              name="only_assigned"
            ></p-checkbox>
          </span>
          <i
            class="handle pi pi-arrows-alt"
            tooltipPosition="left"
            pTooltip="Verschieben"
          ></i>
        </div>
      </header>

      <div
        class="content-body content-base-height without-padding has-loading-logo"
        *ngIf="loadings.todos"
      >
        <i class="loading-logo"></i>
      </div>

      <div
        class="content-body content-base-height without-padding"
        *ngIf="data.todos.length === 0 && !loadings.todos"
      >
        <span class="content-no-message">Keine offenen Todos vorhanden</span>
      </div>

      <div
        class="content-body content-base-height without-padding"
        *ngIf="data.todos.length > 0 && !loadings.todos"
      >
        <p-table
          [value]="data.todos"
          [rowHover]="true"
          [scrollable]="true"
          scrollHeight="100%"
        >
          <ng-template pTemplate="header">
            <tr class="table-headline-highlight">
              <th style="max-width: 50px"></th>
              <th style="max-width: 50px" pSortableColumn="calculated_priority">
                <i
                  style="font-size: 15px"
                  class="pi pi-flag"
                  pTooltip="Nach Priorität"
                ></i>
                <p-sortIcon field="calculated_priority"></p-sortIcon>
              </th>
              <th style="max-width: 120px" pSortableColumn="name">
                Name <p-sortIcon field="name"></p-sortIcon>
              </th>
              <th style="max-width: 40px"></th>
              <th>Titel</th>
              <th style="max-width: 140px" pSortableColumn="for_when">
                Zu erledigen <p-sortIcon field="for_when"></p-sortIcon>
              </th>
              <th style="max-width: 50px" pTooltip="Kommentare">
                <i class="pi pi-comment"></i>
              </th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-todo>
            <tr
              class="clickable"
              [class.background-hightlight-yellow]="
                todo.calculated_priority == 1
              "
            >
              <td style="max-width: 50px" (click)="openShowTodoDialog(todo)">
                <i
                  style="font-size: 15px"
                  class="pi pi-clock color-alert"
                  *ngIf="todo.exceeded"
                  pTooltip="Datum überschritten"
                ></i>
              </td>
              <td style="max-width: 50px" (click)="openShowTodoDialog(todo)">
                <i
                  style="font-size: 15px"
                  class="pi pi-flag color-alert"
                  *ngIf="todo.calculated_priority == 1"
                  pTooltip="Hohe Priorität"
                ></i>

                <i
                  style="font-size: 15px"
                  class="pi pi-arrow-down color-gray"
                  *ngIf="todo.calculated_priority == 3"
                  pTooltip="Niedrige Priorität"
                ></i>
              </td>
              <td
                style="max-width: 120px"
                *ngIf="todo.assignment_type === 'PATIENT'"
              >
                <a routerLink="/patients/{{ todo.patient.id }}">{{
                  todo.name
                }}</a>
              </td>
              <td
                style="max-width: 120px"
                *ngIf="todo.assignment_type === 'CAREGIVER'"
              >
                <a routerLink="/caregivers/{{ todo.caregiver.id }}">{{
                  todo.name
                }}</a>
              </td>
              <td
                style="max-width: 120px"
                *ngIf="todo.assignment_type === 'CAR'"
              >
                <a routerLink="/cars/{{ todo.car.id }}">{{ todo.name }}</a>
              </td>
              <td
                style="max-width: 120px"
                (click)="openShowTodoDialog(todo)"
                *ngIf="todo.assignment_type === 'GENERAL'"
              >
                {{ todo.name }}
              </td>

              <td (click)="openShowTodoDialog(todo)" style="max-width: 40px">
                <i
                  tooltipPosition="left"
                  [pTooltip]="todo.comment"
                  style="font-size: 16px"
                  class="pi pi-info-circle color-gray"
                  *ngIf="todo.comment"
                ></i>
              </td>
              <td (click)="openShowTodoDialog(todo)">
                {{ todo.title }}
              </td>
              <td style="max-width: 140px" (click)="openShowTodoDialog(todo)">
                {{ todo.for_when_type === "FINISH_AT" ? "am" : "bis" }}
                {{ todo.for_when_string }}
              </td>
              <td style="max-width: 50px" (click)="openShowTodoDialog(todo)">
                {{ todo.comments_count }}
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>
  </ng-container>
</div>
