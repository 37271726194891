import { Injectable } from '@angular/core'
import * as dayjs from 'dayjs'
import 'dayjs/locale/de'
import * as customParseFormat from 'dayjs/plugin/customParseFormat'
import * as utc from 'dayjs/plugin/utc'
import { OfferDependenciesModel } from '../models/offer/offer-dependencies.model'
import { CurrencyPipe } from '@angular/common'

@Injectable({
  providedIn: 'root',
})
export class BudgetService {
  constructor(private currencyPipe: CurrencyPipe) {
    dayjs.locale('de')
    dayjs.extend(customParseFormat)
    dayjs.extend(utc)
  }

  /**
   * Berechnet das Budget für §42.
   */
  public get42(
    degreeOfCare: string,
    degreeOfCareSince: string,
    nursingPerson: string,
    request42Kzpf: string,
    request39Vpf: string,
    dependencies: OfferDependenciesModel
  ): { tooltip: string; value: string; request: boolean } {
    // Nicht möglich, wenn Pflegegrad nicht eingetragen oder I ist.
    if (!degreeOfCare || degreeOfCare === 'I') {
      return {
        tooltip: 'Kein Pflegegrad ausgewählt bzw. Pflegegrad I ausgewählt',
        value: 'nicht möglich',
        request: false,
      }
    }

    // Falls kein Datum für den Pflegegrad ausgewählt ist.
    if (!degreeOfCareSince) {
      return {
        tooltip: 'Kein Datum für Pflegegrad ausgewählt',
        value: 'nicht möglich',
        request: false,
      }
    }

    // Auch nicht möglich, wenn keine Pflegeperson hinterlegt ist.
    if (!nursingPerson) {
      return {
        tooltip: 'Keine Pflegeperson ausgewählt',
        value: 'nicht möglich',
        request: false,
      }
    }

    if (nursingPerson === 'Nein') {
      return {
        tooltip: 'Keine Pflegeperson vorhanden',
        value: 'nicht möglich',
        request: false,
      }
    }

    // Stand vom 28.04.2022: Der Check für 6 Monate muss nicht mehr eingefügt werden.
    // Den ersten Tag des Monats nehmen, an dem der Pflegegrad erteilt wurde.
    // Pflegegrad bezieht immer die Gültigkeit des 1. des Monats, egal wann erteilt wurde.
    // const degreeOfCareSinceAsDate = dayjs
    //   .utc(degreeOfCareSince, 'DD.MM.YYYY')
    //   .startOf('month')
    // const degreeOfCareWith6MonthsTimespan = degreeOfCareSinceAsDate.add(
    //   dependencies['42_degree_of_care_since_months'],
    //   'month'
    // )
    //
    // // Prüfung ob der Pflegegrad länger als 6 Monate vorhanden ist.
    // if (dayjs().isBefore(degreeOfCareWith6MonthsTimespan)) {
    //   return {
    //     tooltip: 'Pflegegrad nicht älter als 6 Monate',
    //     value: 'nicht möglich',
    //     request: false,
    //   }
    // }

    if (request39Vpf !== 'Ja') {
      return {
        tooltip: '§39 VPF muss eingeplant sein',
        value: 'nicht möglich',
        request: false,
      }
    }

    const sum = this.transformEuro(dependencies['42_budget'])

    if (!request42Kzpf) {
      return {
        tooltip: 'Nachfragen ob eingeplant werden soll',
        value: `(${sum})`,
        request: false,
      }
    }

    if (request42Kzpf === 'Nein') {
      return {
        tooltip: '',
        value: 'nicht erwünscht',
        request: false,
      }
    }

    return {
      tooltip: '',
      value: sum,
      request: true,
    }
  }

  /**
   * Berechnet das Budget für §39.
   */
  public get39(
    degreeOfCare: string,
    degreeOfCareSince: string,
    nursingPerson: string,
    request39Vpf: string,
    dependencies: OfferDependenciesModel
  ): { tooltip: string; value: string; request: boolean } {
    // Nicht möglich, wenn Pflegegrad nicht eingetragen oder I ist.
    if (!degreeOfCare || degreeOfCare === 'I') {
      return {
        tooltip: 'Kein Pflegegrad ausgewählt bzw. Pflegegrad I ausgewählt',
        value: 'nicht möglich',
        request: false,
      }
    }

    // Falls kein Datum für den Pflegegrad ausgewählt ist.
    if (!degreeOfCareSince) {
      return {
        tooltip: 'Kein Datum für Pflegegrad ausgewählt',
        value: 'nicht möglich',
        request: false,
      }
    }

    // Auch nicht möglich, wenn keine Pflegeperson hinterlegt ist.
    if (!nursingPerson) {
      return {
        tooltip: 'Keine Pflegeperson ausgewählt',
        value: 'nicht möglich',
        request: false,
      }
    }

    if (nursingPerson === 'Nein') {
      return {
        tooltip: 'Keine Pflegeperson vorhanden',
        value: 'nicht möglich',
        request: false,
      }
    }

    // Den ersten Tag des Monats nehmen, an dem der Pflegegrad erteilt wurde.
    // Pflegegrad bezieht immer die Gültigkeit des 1. des Monats, egal wann erteilt wurde.
    // Stand 29.11.2021: Der 6-Monate-Check soll rausgenommen werden.
    // const degreeOfCareSinceAsDate = dayjs
    //   .utc(degreeOfCareSince, 'DD.MM.YYYY')
    //   .startOf('month')
    // const degreeOfCareWith6MonthsTimespan = degreeOfCareSinceAsDate.add(
    //   dependencies['39_degree_of_care_since_months'],
    //   'month'
    // )
    //
    // // Prüfung ob der Pflegegrad länger als 6 Monate vorhanden ist.
    // if (dayjs().isBefore(degreeOfCareWith6MonthsTimespan)) {
    //   return {
    //     tooltip: 'Pflegegrad nicht älter als 6 Monate',
    //     value: 'nicht möglich',
    //     request: false,
    //   }
    // }

    const sum = this.transformEuro(dependencies['39_budget'])

    if (!request39Vpf) {
      return {
        tooltip: 'Nachfragen ob eingeplant werden soll',
        value: `(${sum})`,
        request: false,
      }
    }

    if (request39Vpf === 'Nein') {
      return {
        tooltip: '',
        value: 'nicht erwünscht',
        request: false,
      }
    }

    return {
      tooltip: '',
      value: sum,
      request: true,
    }
  }

  /**
   * Berechnet das Budget für §37.
   */
  public get37(
    degreeOfCare: string,
    nursingPerson: string,
    request37: string,
    request45a: string,
    dependencies: OfferDependenciesModel
  ): { tooltip: string; value: string; request: boolean } {
    // Nicht möglich, wenn Pflegegrad nicht eingetragen oder I ist.
    if (!degreeOfCare || degreeOfCare === 'I') {
      return {
        tooltip: 'Kein Pflegegrad ausgewählt bzw. Pflegegrad I ausgewählt',
        value: 'nicht möglich',
        request: false,
      }
    }

    // Auch nicht möglich, wenn keine Pflegeperson hinterlegt ist.
    if (!nursingPerson) {
      return {
        tooltip: 'Keine Pflegeperson ausgewählt',
        value: 'nicht möglich',
        request: false,
      }
    }

    if (nursingPerson === 'Nein') {
      return {
        tooltip: 'Keine Pflegeperson vorhanden',
        value: 'nicht möglich',
        request: false,
      }
    }

    if (request37 === 'Nein') {
      return {
        tooltip: '',
        value: 'nicht erwünscht',
        request: false,
      }
    }

    const sum = this.transformEuro(dependencies['37_budgets'][degreeOfCare])
    const sum2 = this.transformEuro(
      dependencies['37_budgets_60%_with_45a'][degreeOfCare]
    )

    let sumText = ''

    // Falls 45a noch nicht ausgewählt wurde.
    if (!request45a) {
      sumText = `${sum} / ${sum2}`
    }

    // Wenn 45a mit Nein ausgewählt wurde, dann normalen §37 Satz nehmen.
    if (request45a === 'Nein') {
      sumText = sum
    }

    // Wenn 45a mit Ja ausgewählt wurde, dann mit % ausgerechneten §37 Satz nehmen.
    if (request45a === 'Ja') {
      sumText = sum2
    }

    // Wenn nicht ausgewählt wurde, ob Pflegegeld eingeplant
    // werden soll, soll die Summe dennoch in Klammern stehen.
    if (!request37) {
      return {
        tooltip: 'Nachfragen ob Pflegegeld eingeplant werden soll',
        value: `(${sumText})`,
        request: false,
      }
    }

    return {
      tooltip: '',
      value: `${sumText}`,
      request: false,
    }
  }

  /**
   * Berechnet das Budget für §45a.
   */
  public get45a(
    degreeOfCare: string,
    request45a: string,
    dependencies: OfferDependenciesModel,
    isGkvOrPkvSelected: boolean
  ): { tooltip: string; value: string; request: boolean } {
    if (!isGkvOrPkvSelected) {
      return {
        tooltip: 'GKV oder PKV muss ausgewählt werden',
        value: 'nicht möglich',
        request: false,
      }
    }

    // Falls der Pflegegrad nicht ausgewählt ist bzw. Pflegegrad I ausgewählt ist.
    if (!degreeOfCare || degreeOfCare === 'I') {
      return {
        tooltip: 'Kein Pflegegrad ausgewählt bzw. Pflegegrad I ausgewählt',
        value: 'nicht möglich',
        request: false,
      }
    }

    if (request45a === 'Nein') {
      return {
        tooltip: '',
        value: 'nicht erwünscht',
        request: false,
      }
    }

    const sum = this.transformEuro(
      dependencies['45a_budgets_40%'][degreeOfCare]
    )

    // Wenn nichts ausgewählt wurde, soll die
    // Summe dennoch in Klammern angezeigt werden.
    if (!request45a) {
      return {
        tooltip: 'Nachfragen ob eingeplant werden soll',
        value: `(${sum})`,
        request: false,
      }
    }

    return {
      tooltip: '',
      value: sum,
      request: false,
    }
  }

  /**
   * Berechnet das Budget für §45b aktuelles Jahr.
   */
  public get45bCurrent(
    request45b: string,
    degreeOfCareSince: string,
    degreeOfCare: string,
    dependencies: OfferDependenciesModel
  ): { tooltip: string; value: string; request: boolean } {
    if (!degreeOfCare) {
      return {
        tooltip: 'Kein Pflegegrad ausgewählt',
        value: 'nicht möglich',
        request: false,
      }
    }

    // Falls kein Datum für den Pflegegrad ausgewählt wurde.
    if (!degreeOfCareSince) {
      return {
        tooltip: 'Kein Datum für Pflegegrad ausgewählt',
        value: 'nicht möglich',
        request: false,
      }
    }

    if (request45b === 'Nein') {
      return {
        tooltip: '',
        value: 'nicht erwünscht',
        request: false,
      }
    }

    const degreeOfCareSinceAsDate = dayjs.utc(degreeOfCareSince, 'DD.MM.YYYY')

    // Keine Berechnung möglich, falls Pflegegrad erst nächstes Jahr erteilt wird.
    if (dayjs().isBefore(dayjs(degreeOfCareSinceAsDate), 'year')) {
      return {
        tooltip: 'Pflegegrad erst nächstes Jahr',
        value: 'nicht möglich',
        request: false,
      }
    }

    let start = null

    // Prüfung, ob der Pflegegrad dieses Jahr erteilt wurde.
    if (dayjs(degreeOfCareSinceAsDate).isSame(dayjs(), 'year')) {
      start = degreeOfCareSinceAsDate.startOf('month')
    } else {
      // Ansonsten wurde der Pflegegrad vorher erteilt und
      // wir können ab dem 01. Januar von diesem Jahr abrechnen.
      start = dayjs().startOf('year')
    }

    // Das Datum bis wann abgerechnet werden darf für das aktuelle Jahr.
    const end = dayjs()

    // Differenz der Monate wird nun berechnet. Es muss ein Monat
    // extra dazuaddiert werden, damit die richtige Anzahl rauskommt.
    const amountMonths = end.diff(start, 'month') + 1

    const money = amountMonths * dependencies['45_currently_monthly_budget']

    if (!request45b) {
      return {
        tooltip: 'Nachfragen ob eingeplant werden soll',
        value: `(${this.transformEuro(money)})`,
        request: false,
      }
    }

    return {
      tooltip: '',
      value: this.transformEuro(money),
      request: false,
    }
  }

  /**
   * Berechnet das Budget für §45b altes Jahr.
   */
  public get45bOld(
    request45b: string,
    degreeOfCareSince: string,
    degreeOfCare: string,
    dependencies: OfferDependenciesModel
  ): { tooltip: string; value: string; request: boolean } {
    if (!degreeOfCare) {
      return {
        tooltip: 'Kein Pflegegrad ausgewählt',
        value: 'nicht möglich',
        request: false,
      }
    }

    // Falls kein Datum für den Pflegegrad ausgewählt wurde.
    if (!degreeOfCareSince) {
      return {
        tooltip: 'Kein Datum für Pflegegrad ausgewählt',
        value: 'nicht möglich',
        request: false,
      }
    }

    if (request45b === 'Nein') {
      return {
        tooltip: '',
        value: 'nicht erwünscht',
        request: false,
      }
    }

    const degreeOfCareSinceAsDate = dayjs.utc(degreeOfCareSince, 'DD.MM.YYYY')

    // Falls Pflegegrad erst dieses Jahr erteilt wurde oder sogar erst
    // nächstes Jahr, ist es natürlich für letztes Jahr nicht vorhanden.
    if (
      dayjs().isSame(dayjs(degreeOfCareSinceAsDate), 'year') ||
      dayjs().isBefore(dayjs(degreeOfCareSinceAsDate), 'year')
    ) {
      return {
        tooltip: '',
        value: 'nicht vorhanden',
        request: false,
      }
    }

    // Der Pflegegrad wurde vor diesem Jahr erteilt, jetzt können
    // wir die Berechnung für das Budget für letztes Jahr starten.
    const lastYear = dayjs().subtract(1, 'year')
    let start = null

    // Prüfung, ob der Pflegegrad erst letztes Jahr erteilt wurde.
    if (dayjs(degreeOfCareSinceAsDate).isSame(lastYear, 'year')) {
      start = degreeOfCareSinceAsDate.startOf('month')
    } else {
      // Ansonsten wurde der Pflegegrad vor dem letzten Jahr erteilt
      // und wir können ab dem 01. Januar vom letzten Jahr abrechnen.
      start = lastYear.startOf('year')
    }

    // Das Datum bis wann abgerechnet werden darf für letztes Jahr.
    // Ist immer bis 31.12 des letzten Jahres gültig.
    const end = lastYear.endOf('year')

    // Prüfen, ob das Ablaufdatum für das alte Jahr noch gültig ist.
    const expiredAt = dayjs()
      .startOf('year')
      .add(
        dependencies['45b_old_expiration_month'][lastYear.get('year')],
        'month'
      )
      .endOf('month')

    if (dayjs().isAfter(expiredAt)) {
      return {
        tooltip: '',
        value: 'verfallen',
        request: false,
      }
    }

    // Differenz der Monate wird nun berechnet. Es muss ein Monat
    // extra dazuaddiert werden, damit die richtige Anzahl rauskommt.
    const amountMonths = end.diff(start, 'month') + 1

    const money = amountMonths * dependencies['45_old_monthly_budget']

    if (!request45b) {
      return {
        tooltip: 'Nachfragen ob eingeplant werden soll',
        value: `(${this.transformEuro(money)})`,
        request: false,
      }
    }

    return {
      tooltip: '',
      value: this.transformEuro(money),
      request: false,
    }
  }

  /**
   * Formatiert eine Zahl in einen Euro-String Wert.
   */
  private transformEuro(money: number): string {
    return this.currencyPipe.transform(money, 'EUR') || ''
  }
}
