import { Component, OnInit } from '@angular/core'
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog'
import { CustomerDetailGeneralModel } from '../../../models/customer-patient/customer-detail-general.model'
import { DocumentService } from '../../../services/document.service'

@Component({
  selector: 'app-print-address-dialog',
  templateUrl: './print-address-dialog.component.html',
})
export class PrintAddressDialogComponent implements OnInit {
  public customer: CustomerDetailGeneralModel = new CustomerDetailGeneralModel()

  constructor(
    public documentService: DocumentService,
    private ref: DynamicDialogRef,
    private config: DynamicDialogConfig
  ) {}

  public ngOnInit(): void {
    this.customer = this.config.data.customer
  }
}
