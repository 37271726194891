import { HistoryListModel } from '../history/history-list.model'

export class PhoneCallModel {
  id: number | null = null
  user_id = 0
  status = ''
  user_phone_identifier = ''
  number = ''
  original_number = ''
  is_redirect = false
  is_consulting = false
  not_reached = false
  from_appointment = false
  from_appointment_patient_id = null
  from_appointment_month = null
  from_appointment_year = null
  strong_interest = false
  attention_from_id: number | null = null
  comment = ''
  appointment_transfer_history_id: number | null = null
  history_appointment_transfer: HistoryListModel | null = null
  phone_caller_name: string | null = null
  phone_caller_type: string | null = null
  phone_caller_id: number | null = null
  we_called = false
  created_as_active = false
  activated_at: Date | null = null
  finished_at: Date | null = null
  saved_at: Date | null = null
  created_at: Date | null = null
  updated_at: Date | null = null

  history: HistoryListModel = new HistoryListModel()
}
