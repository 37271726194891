<app-loader [loading]="loading"></app-loader>

<div class="content has-min-width" *ngIf="!loading">
  <header class="content-header">
    <span>
      <p-selectButton
        (onChange)="loadCalls(false)"
        [options]="intervalDaysOptions"
        [(ngModel)]="fields.selected"
        name="days"></p-selectButton>

      <div style="margin: 0 0 0 20px">
         <p-calendar
           (onSelect)="loadCalls(false)"
           (onBlur)="loadCalls(false)"
           *ngIf="fields.selected === 'INDIVIDUAL'"
           [(ngModel)]="fields.dates"
           selectionMode="range"
           dateFormat="dd.mm.yy"
           appendTo="body"
           dataType="string"
           placeholder="Datum auswählen"
           [showIcon]="true"
         ></p-calendar>
      </div>
    </span>

    <div class="table-search-container" *ngIf="data.length > 0">
      <div class="p-input-icon-left">
        <i class="pi pi-search"></i>
        <input
          pInputText
          (input)="filterTableGlobal($event)"
          placeholder="Suche..."
          [value]="globalFilterValue"
        />
      </div>
    </div>
  </header>

  <div class="content-body" *ngIf="data.length === 0">
    <span class="content-no-message">Keine Auswertung vorhanden</span>
  </div>

  <div
    class="content-body without-padding"
    *ngIf="data.length > 0"
    [class.content-loading]="contentLoading"
  >
    <p-table #dt [value]="data" [rowHover]="true" [globalFilterFields]="[
        'name',
      ]">
      <ng-template pTemplate="header">
        <tr>
          <th pSortableColumn="name">
            Name
            <p-sortIcon field="name"></p-sortIcon>
          </th>
          <th pSortableColumn="amount">
            Anzahl <p-sortIcon field="amount"></p-sortIcon>
          </th>
          <th pSortableColumn="average_as_number">
            Ø Dauer <p-sortIcon field="average_as_number"></p-sortIcon>
          </th>
          <th pSortableColumn="amount_incoming">
            Eingehende <p-sortIcon field="amount_incoming"></p-sortIcon>
          </th>
          <th pSortableColumn="amount_calling">
            Ausgehende <p-sortIcon field="amount_calling"></p-sortIcon>
          </th>
          <th pSortableColumn="amount_redirect">
            Weiterleitungen <p-sortIcon field="amount_redirect"></p-sortIcon>
          </th>
        </tr>
<!--        <tr>-->
<!--          <th>-->
<!--            <p-columnFilter placeholder="Suche Name" [showMenu]="false" type="text" field="caregiver.full_name" matchMode="contains"></p-columnFilter>-->
<!--          </th>-->
<!--          <th>-->
<!--            <p-columnFilter placeholder="Suche Name" [showMenu]="false" type="text" field="patient.full_name" matchMode="contains"></p-columnFilter>-->
<!--          </th>-->
<!--          <th>-->
<!--            <p-columnFilter placeholder="Suche Ort oder Bezirke" [showMenu]="false" type="text" field="patient.full_address_complete" matchMode="contains"></p-columnFilter>-->
<!--          </th>-->
<!--          <th>-->
<!--            <p-columnFilter type="date" field="real_from_formatted" [showMenu]="true">-->
<!--              <ng-template pTemplate="filter" let-value let-filter="filterCallback">-->
<!--                <p-calendar placeholder="Datum" #fd [ngModel]="value" appendTo="body" dateFormat="dd.mm.yy" (onBlur)="filter(fd.value)" (onSelect)="filter(fd.value)"></p-calendar>-->
<!--              </ng-template>-->
<!--            </p-columnFilter>-->
<!--          </th>-->
<!--          <th></th>-->
<!--          <th></th>-->
<!--        </tr>-->
      </ng-template>
      <ng-template pTemplate="body" let-item>
        <tr>
          <td>{{ item.name }}</td>
          <td>{{ item.amount }}</td>
          <td>{{ item.average }}</td>
          <td>{{ item.amount_incoming }}</td>
          <td>{{ item.amount_calling }}</td>
          <td>{{ item.amount_redirect }}</td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
