<app-loader [loading]="loading"></app-loader>

<div class="content" *ngIf="!loading">
  <header class="content-header">
    <span>
      <i class="pi pi-clock"></i>
      Komplette Historie
    </span>
    <div class="content-header-buttons">
      <p-checkbox
        label="nur wichtige Ereignisse"
        [binary]="true"
        [(ngModel)]="onlyImportant"
        name="only_assigned"
      ></p-checkbox>
      <button
        pButton
        label="Neuer Eintrag"
        (click)="openManualHistoryDialog(false)"
        type="button"
        class="p-button-outlined"
        icon="pi pi-plus"
        iconPos="left"
      ></button>
    </div>
  </header>
</div>

<div class="content" style="margin: 0" *ngIf="!loading">
  <div class="content-body without-padding auto-height">
    <p-table #dt [value]="histories" [rowHover]="true">
      <ng-template pTemplate="header">
        <tr class="table-headline-highlight">
          <th style="width: 200px" pSortableColumn="type_text">
            Typ <p-sortIcon field="type_text"></p-sortIcon>
          </th>
          <th style="width: 200px" pSortableColumn="created_at">
            am <p-sortIcon field="created_at"></p-sortIcon>
          </th>
          <th style="width: 200px" pSortableColumn="created_from_name">
            von <p-sortIcon field="created_from_name"></p-sortIcon>
          </th>
          <th style="width: 50px"></th>
          <th>Informationen</th>
          <th style="width: 100px"></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-history>
        <tr
          [class.background-hightlight-yellow]="history.important"
          [hidden]="onlyImportant && !history.important"
        >
          <td>
            <strong>
              <!--              <i-->
              <!--                class="pi pi-star color-orange"-->
              <!--                *ngIf="history.important"-->
              <!--                pTooltip="Wichtiges Ereignis"-->
              <!--              ></i>-->

              {{ history.type_text }}
            </strong>
          </td>

          <td>{{ history.occurred_at_string }} Uhr</td>

          <td>{{ history.created_from_name || "??" }}</td>

          <td></td>
          <td>
            <span
              class="history-information"
              *ngIf="
                history.type === 'MANUAL' ||
                history.type === 'CAREGIVER_DELETED'
              "
            >
              <i
                ><b>{{ history.manual_title }}</b></i
              >
              <p *ngIf="history.manual_description">
                {{ history.manual_description }}
              </p>
            </span>
          </td>
          <td class="table-action">
            <i
              (click)="openDiffView(history.id)"
              pTooltip="Änderungen ansehen"
              tooltipPosition="left"
              *ngIf="
                history.type === 'CHANGED' || history.type === 'NOTICE_CHANGED'
              "
              class="pi pi-eye"
            ></i>
            <i
              *ngIf="history.type === 'MANUAL'"
              (click)="openManualHistoryDialog(true, history)"
              class="pi pi-pencil"
            ></i>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
