<app-loader [loading]="loading"></app-loader>

<div class="content-actions">
  <h2>
    <i class="tio">call_talking</i>
    Telefonate
  </h2>
</div>

<div class="content phone-call-finished-content" *ngIf="!loading">
  <header class="content-header">
    <span>Abgeschlossene Telefonate </span>
  </header>

  <div class="content-body" *ngIf="phoneCalls.length == 0">
    <span class="content-no-message">Keine Telefonate vorhanden</span>
  </div>

  <div
    class="content-body without-padding scrollable-horizontal"
    *ngIf="phoneCalls.length > 0"
  >
    <p-table
      #dt
      [style]="{ minWidth: '720px' }"
      [value]="phoneCalls"
      [globalFilterFields]="[
        'id',
        'number',
        'phone_caller_name',
        'phone_caller_id',
        'user_system.first_name',
        'user_system.last_name'
      ]"
      [rowHover]="true"
      [paginator]="true"
      [autoLayout]="true"
      [rows]="25"
    >
      <ng-template pTemplate="header">
        <tr>
          <th pSortableColumn="created_at">
            Anruf am <p-sortIcon field="created_at"></p-sortIcon>
          </th>
          <th style="width: 120px" pSortableColumn="duration">
            Dauer <p-sortIcon field="duration"></p-sortIcon>
          </th>
          <th pSortableColumn="user_system.first_name">
            Gesprochen mit
            <p-sortIcon field="user_system.first_name"></p-sortIcon>
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-call>
        <tr>
          <td>{{ call.created_at | date: "dd.MM.y, H:mm" }} Uhr</td>
          <td>{{ call.duration || "N/A" }}</td>
          <td>
            {{ call.user_system?.first_name }} {{ call.user_system?.last_name }}
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
