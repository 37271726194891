<form #form="ngForm" class="dialog-form">
  <div class="dialog-form-body">
    <div class="p-fluid p-formgrid p-grid without-border">
      <div class="p-field p-col-6">
        <label for="month">Monat *</label>
        <p-dropdown
          scrollHeight="500px"
          inputId="month"
          name="month"
          appendTo="body"
          (onChange)="loadApproval()"
          [options]="monthOptions"
          [(ngModel)]="values.month"
        ></p-dropdown>
      </div>

      <div class="p-field p-col-6">
        <label for="year">Jahr *</label>
        <p-dropdown
          inputId="year"
          name="year"
          (onChange)="loadApproval()"
          [options]="yearOptions"
          [(ngModel)]="values.year"
        ></p-dropdown>
      </div>
    </div>

    <div class="p-field">
      <label for="comment">Bemerkung</label>
      <textarea
        id="comment"
        name="comment"
        [(ngModel)]="values.comment"
        [rows]="3"
        pInputTextarea
      ></textarea>
    </div>
  </div>
  <div class="dialog-form-footer">
    <i *ngIf="!isEdit"></i>
    <i
      class="pi pi-trash"
      *ngIf="isEdit"
      [class.pi-trash]="!submittedDelete"
      [class.pi-spin]="submittedDelete"
      [class.pi-spinner]="submittedDelete"
      tooltipPosition="left"
      tooltipZIndex="20000"
      [pTooltip]="submittedDelete ? 'Wird gelöscht' : 'Freigabe zurückziehen'"
      (click)="remove($event)"
    ></i>

    <button
      (click)="store()"
      pButton
      [label]="isEdit ? 'Speichern' : 'Abschließen'"
      type="button"
      [disabled]="submitted"
      [icon]="submitted ? 'pi pi-spin pi-spinner' : 'pi pi-check'"
    ></button>
  </div>
</form>

<p-confirmPopup></p-confirmPopup>
