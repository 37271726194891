<form #form="ngForm" class="dialog-form">
  <div class="dialog-form-body">
    <div class="p-formgroup-inline">
      <div class="p-field-checkbox">
        <p-checkbox
          label="Telefon"
          value="Telefon"
          name="type"
          [(ngModel)]="types"
        ></p-checkbox>
      </div>

      <div class="p-field-checkbox">
        <p-checkbox
          label="E-Mail"
          value="E-Mail"
          name="type"
          [(ngModel)]="types"
        ></p-checkbox>
      </div>

      <div class="p-field-checkbox">
        <p-checkbox
          label="Post"
          value="Post"
          name="type"
          [(ngModel)]="types"
        ></p-checkbox>
      </div>
    </div>

    <div class="p-fluid p-formgrid p-grid without-border">
      <div class="p-field p-col-12">
        <label for="receiver">an</label>
        <p-multiSelect
          inputId="receiver"
          name="receiver"
          [options]="receiverOptions"
          [(ngModel)]="receiver"
          scrollHeight="500px"
          appendTo="body"
        >
          <ng-template let-receiver pTemplate="item">
            <div>{{ receiver.label }}</div>
          </ng-template>
        </p-multiSelect>
      </div>
    </div>

    <div class="p-fluid p-formgrid p-grid without-border">
      <div class="p-field p-col-12">
        <label for="comment">Bemerkung</label>
        <textarea
          id="comment"
          name="comment"
          [rows]="3"
          [(ngModel)]="comment"
          pInputTextarea
        ></textarea>
      </div>
    </div>
  </div>
  <div class="dialog-form-footer">
    <i></i>
    <button
      pButton
      label="Speichern"
      type="submit"
      (click)="save()"
      [disabled]="submitted"
      [icon]="submitted ? 'pi pi-spin pi-spinner' : 'pi pi-check'"
    ></button>
  </div>
</form>
<p-confirmPopup></p-confirmPopup>
