import { Component, OnInit } from '@angular/core'
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog'
import { ToastService } from 'src/app/services/toast.service'
import {
  EventBusService,
  GlobalEvent,
} from '../../../services/eventbus.service'
import { PlanningService } from '../../../services/planning.service'
import { ProofsService } from '../../../services/proofs.service'

@Component({
  selector: 'app-finish-proof-rework-dialog',
  templateUrl: './finish-proof-rework-dialog.component.html',
})
export class FinishProofReworkDialogComponent implements OnInit {
  comment = ''
  submitted = false
  submittedDelete = false

  constructor(
    private ref: DynamicDialogRef,
    private planningService: PlanningService,
    private proofsService: ProofsService,
    public config: DynamicDialogConfig,
    private eventbus: EventBusService,
    private toastService: ToastService
  ) {}

  public ngOnInit(): void {}

  public save(): void {
    if (!this.config.data.finished_comment) {
      alert('Bitte Kommentar eintragen')
      return
    }

    this.submitted = true

    this.proofsService.finishProofRework(this.config.data).subscribe(
      (response: any) => {
        this.submitted = false
        this.eventbus.emit(GlobalEvent.ProofsChanged)
        this.ref.close(response)
        this.toastService.success('Nachbearbeitung erledigt')
      },
      () => {
        this.toastService.error(
          'Etwas ist schiefgelaufen...',
          'Bitte wenden Sie sich an den Support'
        )

        this.submitted = false
      }
    )
  }
}
