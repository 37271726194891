import { HttpErrorResponse } from '@angular/common/http'
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core'
import { StatusCodes } from 'http-status-codes'
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog'
import { EventBusService, GlobalEvent } from 'src/app/services/eventbus.service'
import { ToastService } from 'src/app/services/toast.service'
import { NgForm } from '@angular/forms'
import { Subscription } from 'rxjs'
import { SearchResultModel } from '../../../models/search/search-result.model'
import { CaregiverDependenciesModel } from '../../../models/caregiver/caregiver-dependencies.model'
import { CaregiverService } from '../../../services/caregivers.service'
import { CaregiverTimeService } from '../../../services/caregiver-time.service'
import { SelectItem } from 'primeng/api'
import { CaregiverTimeErrorsModel } from '../../../models/caregiver/caregiver-time-errors.model'
import { CaregiverTimeModel } from '../../../models/caregiver/caregiver-time.model'

@Component({
  selector: 'app-add-caregiver-times-dialog',
  templateUrl: './add-caregiver-times-dialog.component.html',
})
export class AddCaregiverTimesDialogComponent implements OnInit, OnDestroy {
  @ViewChild('form', { static: true }) form!: NgForm

  public times: SelectItem[] = []

  private formSubscription: Subscription | null | undefined = null
  private isDirty = false
  public patients: SearchResultModel[] = []
  public caregivers: SearchResultModel[] = []

  public data: any = {}
  public values = new CaregiverTimeModel()

  public worksOnHolidays = false

  public errors: any = new CaregiverTimeErrorsModel()

  public submitted = false
  public submittedDelete = false

  constructor(
    private ref: DynamicDialogRef,
    private config: DynamicDialogConfig,
    private caregiverService: CaregiverService,
    private caregiverTimeService: CaregiverTimeService,
    private eventbus: EventBusService,
    private toastService: ToastService
  ) {}

  ngOnInit(): void {
    this.formSubscription = this.form.valueChanges?.subscribe(() => {
      if (!this.form.pristine) {
        this.isDirty = true
      }
    })

    this.data = this.config.data

    this.values = this.data.times
    this.values.comment = ''

    this.worksOnHolidays = this.data.worksOnHolidays

    this.loadTimes()
  }

  private loadTimes(): void {
    this.caregiverService
      .dependencies()
      .subscribe((dependencies: CaregiverDependenciesModel) => {
        this.times = dependencies.times
      })
  }

  /**
   * Prüft bei jeder Änderung im Uhrzeitfeld ob die Werte im richtigen
   * Format sind und ob die Uhrzeiten Sinn machen.
   *
   * So soll der "von" Wert kleiner als der zugehörige "bis" Wert sein.
   * Außerdem muss der "von" Wert vom zweichen Block (falls vorhanden)
   * größer sein, als der "bis" Wert vom ersten Block.
   *
   * @param event
   * @param day
   */
  public checkForTimes(
    event: any,
    day: 'monday' | 'tuesday' | 'wednesday' | 'thursday' | 'friday' | 'saturday'
  ): void {
    // Wir setzen alle Fehler nochmal zurück,
    // damit diese einfacher neu berechnet werden können.
    this.resetErrors(day)

    const data = this.values.days_with_key[day]

    // Die Uhrzeiten werden als integer umgewandelt, dadurch
    // lässt es sich leicht nach größer/kleiner überprüfen.
    const integerStartFirst = data.start_first
      ? +data.start_first.replace(':', '')
      : 0
    const integerEndFirst = data.end_first
      ? +data.end_first.replace(':', '')
      : 0
    const integerStartSecond = data.start_second
      ? +data.start_second.replace(':', '')
      : 0
    const integerEndSecond = data.end_second
      ? +data.end_second.replace(':', '')
      : 0

    this.checkForTimeFormat(day)

    // Prüfen das die Uhrzeit für "von" kleiner ist als von "bis" für den ersten Block.
    // Nur wenn "bis" Datum eingetragen wurde und es noch keinen Fehler gibt.
    if (
      integerEndFirst &&
      integerStartFirst > integerEndFirst &&
      !this.errors[`${day}_start_first`]
    ) {
      this.errors[`${day}_start_first`] = true
    }

    // Prüfen das die Uhrzeit für "von" kleiner ist als von "bis" für den zweiten Block.
    // Nur wenn "bis" Datum eingetragen wurde und es noch keinen Fehler gibt.
    if (
      data.has_second_block &&
      integerEndSecond &&
      integerStartSecond > integerEndSecond &&
      !this.errors[`${day}_start_second`]
    ) {
      this.errors[`${day}_start_second`] = true
    }

    // Prüfen das die Uhrzeit für "von" im zweiten Block größer als
    // die "bis" Uhrzeit vom ersten Block ist und es noch keinen Fehler gibt.
    if (
      data.has_second_block &&
      integerEndFirst &&
      integerStartSecond &&
      integerEndFirst > integerStartSecond &&
      !this.errors[`${day}_start_second`]
    ) {
      this.errors[`${day}_start_second`] = true
    }
  }

  /**
   * Prüft für jedes Feld für den Tag ob das Format in HH:MM ist.
   *
   * @param day
   * @private
   */
  private checkForTimeFormat(
    day: 'monday' | 'tuesday' | 'wednesday' | 'thursday' | 'friday' | 'saturday'
  ): void {
    const timeWithLeadingZero = /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/
    const types = ['start_first', 'end_first', 'start_second', 'end_second']

    types.forEach((type: string) => {
      // @ts-ignore
      const data = this.values.days_with_key[day][type]

      if (data) {
        this.errors[`${day}_${type}`] = !data.match(timeWithLeadingZero)
      }
    })
  }

  public setSecondBlock(
    day: 'monday' | 'tuesday' | 'wednesday' | 'thursday' | 'friday' | 'saturday'
  ): void {
    this.values.days_with_key[day].has_second_block = true
  }

  public removeSecondBlock(
    day: 'monday' | 'tuesday' | 'wednesday' | 'thursday' | 'friday' | 'saturday'
  ): void {
    this.values.days_with_key[day].has_second_block = false
    this.values.days_with_key[day].start_second = ''
    this.values.days_with_key[day].end_second = ''

    this.checkForTimes(null, day)
  }

  private resetErrors(day: string): void {
    this.errors[`${day}_start_first`] = false
    this.errors[`${day}_end_first`] = false
    this.errors[`${day}_start_second`] = false
    this.errors[`${day}_end_second`] = false
  }

  ngOnDestroy(): void {
    this.formSubscription?.unsubscribe()
  }

  public store(): void {
    if (!this.form.form.valid) {
      this.submitted = false
      this.form.form.markAllAsTouched()
      return
    }

    if (Object.values(this.errors).includes(true)) {
      alert('Bitte prüfen Sie die Uhrzeiten!')
      return
    }

    this.submitted = true

    this.caregiverTimeService
      .store(this.data.caregiver_id, this.worksOnHolidays, this.values)
      .subscribe(
        () => {
          this.submitted = false
          this.eventbus.emit(GlobalEvent.TimesChanged)
          this.ref.close()
          this.toastService.success(
            'Arbeitszeiten geändert',
            'Die Arbeitszeiten wurde erfolgreich geändert'
          )
        },
        (error: HttpErrorResponse) => {
          if (error.status === StatusCodes.UNPROCESSABLE_ENTITY) {
            this.toastService.error('Bitte füllen Sie alle Pflichtfelder aus')
          } else {
            this.toastService.error(
              'Etwas ist schief gelaufen...',
              'Bitte wenden Sie sich an den Support'
            )
          }
          this.submitted = false
        }
      )
  }
}
