<app-loader [loading]="loading"></app-loader>

<div class="content" *ngIf="!loading">
  <header class="content-header">
    <span>
      <i (click)="goToPreviousMonth()" class="pi pi-chevron-left"></i>

      <p-dropdown
        inputId="month"
        name="month"
        scrollHeight="500px"
        (onChange)="loadCaregivers(false)"
        [options]="monthOptions"
        [(ngModel)]="month"
        [style]="{ width: '150px', zIndex: '10' }"
      ></p-dropdown>

      <p-dropdown
        inputId="year"
        name="year"
        (onChange)="loadCaregivers(false)"
        [options]="yearOptions"
        [(ngModel)]="year"
        [style]="{ margin: '0 0 0 -1px' }"
      ></p-dropdown>

      <i
        (click)="goToNextMonth()"
        class="pi pi-chevron-right"
        style="margin: 0 10px 0 10px"
      ></i>
    </span>

    <div
      class="table-search-container"
      [class.searchfield-active]="globalFilterValue != ''"
    >
      <div class="p-input-icon-left">
        <i class="pi pi-search"></i>
        <input
          pInputText
          (input)="filterTableGlobal($event)"
          placeholder="Suche..."
          [(ngModel)]="globalFilterValue"
        />
      </div>
    </div>
  </header>

  <div
    class="content-body content-body-full-height without-padding"
    [class.content-loading]="contentLoading"
  >
    <p-table
      #dt
      [value]="caregivers"
      [globalFilterFields]="[
        'id',
        'persplan_id',
        'first_name',
        'last_name',
        'zipcode',
        'city',
        'current_job_scope.job_scope',
        'current_car.car.full_name'
      ]"
      [rowHover]="true"
      [showCurrentPageReport]="true"
      currentPageReportTemplate="{totalRecords} Einträge"
      [rows]="50"
      [paginator]="true"
      scrollHeight="100%"
      [scrollable]="true"
    >
      <ng-template pTemplate="header">
        <tr>
          <th style="width: 200px" pSortableColumn="approved_overview.released">
            Freigegeben
            <p-sortIcon field="approved_overview.released"></p-sortIcon>
          </th>
          <th pSortableColumn="last_name">
            Name <p-sortIcon field="last_name"></p-sortIcon>
          </th>
          <th>
            <div class="text-center-container">
              <i class="pi pi-envelope" style="font-size: 14px"></i>
              Feedback
            </div>
          </th>
          <th>
            <div class="text-center-container">
              <i class="pi pi-car" style="font-size: 14px"></i>
              KM-Fahrten
            </div>
          </th>
          <th>
            <div class="text-center-container">
              <i class="pi pi-clock" style="font-size: 14px"></i>
              Fahrzeiten
            </div>
          </th>
          <th>
            <div class="text-center-container">
              <i class="pi pi-clock" style="font-size: 14px"></i>
              Dauer
            </div>
          </th>
          <th style="width: 60px" pSortableColumn="approved_overview.comment">
            Kommentar
            <p-sortIcon field="approved_overview.comment"></p-sortIcon>
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-caregiver>
        <tr
          class="clickable"
          [class.background-hightlight-main2]="
            caregiver.approved_overview?.released &&
            !caregiver.approved_overview.release_reopened
          "
          [class.background-hightlight-orange]="
            caregiver.approved_overview?.released &&
            caregiver.approved_overview.release_reopened
          "
        >
          <td
            (click)="openOverviewReleaseDialog(caregiver)"
            *ngIf="
              caregiver.approved_overview?.released &&
              !caregiver.approved_overview.release_reopened
            "
          >
            <div style="display: flex; align-items: center; gap: 10px">
              <span class="p-tag p-tag-rounded p-tag-REGULAR">
                Freigegeben
              </span>
              <i
                class="pi pi-check-circle color-main2"
                pTooltip="Vom Admin bestätigt"
                *ngIf="caregiver.approved_overview.release_confirmed"
              ></i>
            </div>
          </td>
          <td
            (click)="openOverviewReleaseDialog(caregiver)"
            *ngIf="
              caregiver.approved_overview?.released &&
              caregiver.approved_overview.release_reopened
            "
          >
            <span class="p-tag p-tag-rounded p-tag-EDITED">
              Wieder geöffnet
            </span>
            <!--            <i class="pi pi-check-circle color-orange"></i>-->
          </td>
          <td
            (click)="openOverviewReleaseDialog(caregiver)"
            *ngIf="!caregiver.approved_overview?.released"
          >
            <!--            <span class="p-tag p-tag-rounded p-tag-danger">-->
            <!--              Keine Freigabe-->
            <!--            </span>-->
            <i class="pi pi-times color-alert"></i>
            <span class="appointment-release-amount"
              >{{ caregiver.caregiver_appointment_releases.length }}/{{
                caregiver.amount_appointments
              }}
              freigegeben</span
            >
          </td>

          <td (click)="openOverviewReleaseDialog(caregiver)">
            <a
              class="color-main1"
              routerLink="/caregivers/{{ caregiver.id }}"
              >{{ caregiver.last_first_name }}</a
            >
          </td>

          <!-- Feedback -->
          <td
            (click)="openOverviewReleaseDialog(caregiver)"
            *ngIf="caregiver.caregiver_appointment_feedback.length > 0"
          >
            <div class="text-center-container">
              <strong>{{
                caregiver.caregiver_appointment_feedback.length
              }}</strong>
              {{
                caregiver.caregiver_appointment_feedback.length === 1
                  ? "Eintrag"
                  : "Einträge"
              }}
            </div>
          </td>
          <td
            (click)="openOverviewReleaseDialog(caregiver)"
            *ngIf="caregiver.caregiver_appointment_feedback.length === 0"
          >
            -
          </td>

          <!-- KM-Fahrten -->
          <td
            (click)="openOverviewReleaseDialog(caregiver)"
            *ngIf="caregiver.caregiver_appointment_trips.length > 0"
          >
            <div class="text-center-container">
              <strong>{{
                caregiver.caregiver_appointment_trips.length
              }}</strong>
              {{
                caregiver.caregiver_appointment_trips.length === 1
                  ? "Eintrag"
                  : "Einträge"
              }}
            </div>
          </td>
          <td
            (click)="openOverviewReleaseDialog(caregiver)"
            *ngIf="caregiver.caregiver_appointment_trips.length === 0"
          >
            -
          </td>

          <!-- Fahrzeiten -->
          <td
            (click)="openOverviewReleaseDialog(caregiver)"
            *ngIf="
              caregiver.caregiver_appointment_changes_drive_time.length > 0
            "
          >
            <div class="text-center-container">
              <strong>{{
                caregiver.caregiver_appointment_changes_drive_time.length
              }}</strong>
              {{
                caregiver.caregiver_appointment_changes_drive_time.length === 1
                  ? "Änderung"
                  : "Änderungen"
              }}
            </div>
          </td>
          <td
            (click)="openOverviewReleaseDialog(caregiver)"
            *ngIf="
              caregiver.caregiver_appointment_changes_drive_time.length === 0
            "
          >
            -
          </td>

          <!-- Dauer -->
          <td
            (click)="openOverviewReleaseDialog(caregiver)"
            *ngIf="caregiver.caregiver_appointment_changes_time.length > 0"
          >
            <div class="text-center-container">
              <strong>{{
                caregiver.caregiver_appointment_changes_time.length
              }}</strong>
              {{
                caregiver.caregiver_appointment_changes_time.length === 1
                  ? "Änderung"
                  : "Änderungen"
              }}
            </div>
          </td>
          <td
            (click)="openOverviewReleaseDialog(caregiver)"
            *ngIf="caregiver.caregiver_appointment_changes_time.length === 0"
          >
            -
          </td>

          <!-- Kommentar -->
          <td
            (click)="openOverviewReleaseDialog(caregiver)"
            *ngIf="caregiver.approved_overview?.comment"
          >
            <i
              tooltipPosition="left"
              style="font-size: 14px"
              class="pi pi-info-circle color-gray"
              [pTooltip]="caregiver.approved_overview.comment"
            ></i>
          </td>
          <td
            (click)="openOverviewReleaseDialog(caregiver)"
            *ngIf="!caregiver.approved_overview?.comment"
          >
            -
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
