import { CarsDetailModel } from './cars-detail.model'
import { CaregiverCarModel } from './caregiver-car.model'

export class CarStatusModel {
  id: number | null = null
  car_id: number | null = null
  caregiver_car_id: number | null = null
  mileage = ''
  status = ''
  status_text = ''
  comment = ''
  created_at: Date | null = null
  updated_at: Date | null = null
  deleted_at: Date | null = null
  since: Date | string | null = null

  caregiver_car: CaregiverCarModel = new CaregiverCarModel()
}
