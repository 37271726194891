import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs'
import { environment } from '../../environments/environment'

@Injectable({
  providedIn: 'root',
})
export class TrainingService {
  constructor(private http: HttpClient) {}

  public upload(caregiverId: number | string, form: FormData): Observable<[]> {
    return this.http.post<[]>(
      `${environment.api}/training/${caregiverId}`,
      form
    )
  }

  public update(trainingId: number | string, form: FormData): Observable<[]> {
    return this.http.post<[]>(
      `${environment.api}/training/update/${trainingId}`,
      form
    )
  }

  public delete(trainingId: number | string): Observable<[]> {
    return this.http.delete<[]>(`${environment.api}/training/${trainingId}`)
  }
}
