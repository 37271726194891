import { CarsDetailModel } from './cars-detail.model'

export class CarCostModel {
  id: number | null = null
  car_id: number | null = null
  cost = 0.0
  cost_formatted = ''
  name = ''
  comment = ''
  created_at: Date | null = null
  updated_at: Date | null = null
  deleted_at: Date | null = null
  from: Date | string = ''
  from_string: Date | string = ''
  to: Date | string = ''
  to_string: Date | string = ''
  car: CarsDetailModel = new CarsDetailModel()
}
