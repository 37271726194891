import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs'
import { environment } from '../../environments/environment'
import { VetoModel } from '../models/veto/veto.model'

@Injectable({
  providedIn: 'root',
})
export class VetoService {
  constructor(private http: HttpClient) {}

  public store(veto: VetoModel): Observable<[]> {
    return this.http.post<[]>(`${environment.api}/veto`, { ...veto })
  }

  public update(veto: VetoModel): Observable<[]> {
    return this.http.put<[]>(`${environment.api}/veto/${veto.id}`, { ...veto })
  }

  public delete(id: number | null): Observable<[]> {
    return this.http.delete<[]>(`${environment.api}/veto/${id}`)
  }
}
