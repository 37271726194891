<app-loader [loading]="loading"></app-loader>

<div class="content" *ngIf="!loading">
  <header class="content-header">
    <span>
      <i class="pi pi-calendar-times"></i>
      Leistungsnachweise
    </span>
    <button
      pButton
      (click)="openProofDialog()"
      label="Neu"
      type="button"
      class="p-button-outlined"
      icon="pi pi-plus"
      iconPos="left"
    ></button>
  </header>
  <div class="content-body auto-height" *ngIf="keys(proofs).length === 0">
    <span class="content-no-message">Keine Einträge vorhanden</span>
  </div>

  <ng-container *ngFor="let date of keys(proofs)">
    <div class="detail-information-header is-accordion">
      <span
        class="accordion-clickable-area"
        (click)="openedAccordions[date] = !openedAccordions[date]"
      >
        <i
          [class.pi-chevron-down]="openedAccordions[date]"
          [class.pi-chevron-up]="!openedAccordions[date]"
          class="pi"
        ></i>
        {{ date }}
      </span>

      <span
        pTooltip="Alle herunterladen"
        tooltipPosition="left"
        (click)="openZipDownload(date)"
        ><i class="pi pi-download"></i
      ></span>
    </div>

    <div
      class="content-body without-padding auto-height"
      *ngIf="openedAccordions[date]"
    >
      <p-table [value]="proofs[date]" [rowHover]="true">
        <ng-template pTemplate="header">
          <tr>
            <th>Patient</th>
            <th>Dokument</th>
            <th style="width: 200px">Erstellt am</th>
            <th style="width: 200px">Erstellt von</th>
            <th style="width: 100px"></th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-proof>
          <tr>
            <td>
              <a routerLink="/patients/{{ proof.patient_id }}"
                >{{ proof.patient.last_name }},
                {{ proof.patient.first_name }}</a
              >
            </td>
            <td>
              {{ proof.media.name }}
            </td>
            <td>{{ proof.media.created_at | date: "dd.MM.y, H:mm" }} Uhr</td>
            <td>
              {{ proof.media.custom_properties.created_from_name || "-" }}
            </td>
            <td class="table-action" style="width: 100px">
              <a
                [href]="
                  caregiverService.getDocumentDownloadLink(proof.media.uuid)
                "
                target="_blank"
              >
                <i
                  pTooltip="Herunterladen"
                  tooltipPosition="left"
                  class="pi pi-download"
                ></i>
              </a>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </ng-container>
</div>
