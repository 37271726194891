import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core'
import { Router } from '@angular/router'
import { PhoneCallService } from '../../../services/phone-call.service'
import { HelperService } from '../../../services/helper.service'
import { PlanningService } from '../../../services/planning.service'
import * as dayjs from 'dayjs'
import * as customParseFormat from 'dayjs/plugin/customParseFormat'
import * as utc from 'dayjs/plugin/utc'
import { DialogService } from 'primeng/dynamicdialog'
import {
  EventBusService,
  GlobalEvent,
} from '../../../services/eventbus.service'
import { Subscription } from 'rxjs'
import { GlobalAnalysesService } from '../../../services/global-analyses.service'
import { SelectItem } from 'primeng/api'
import { AuthService } from '../../../services/auth.service'
import { CaregiverService } from '../../../services/caregivers.service'

@Component({
  selector: 'app-analyses-recommendations',
  templateUrl: './analyses-recommendations.component.html',
})
export class AnalysesRecommendationsComponent implements OnInit, OnDestroy {
  @ViewChild('op') overlayPanel: ElementRef | any = null
  @ViewChild('dt') table: ElementRef | any = null

  public globalFilterValue = ''

  public firstPage = 0

  public loading = true
  public contentLoading = true
  private eventBusSubscription: Subscription = new Subscription()

  public data: any[] = []

  public fields = {}

  public intervalDaysOptions: SelectItem[] = [
    {
      label: 'Heute',
      value: 'TODAY',
    },
    {
      label: 'Gestern',
      value: 'YESTERDAY',
    },
    {
      label: 'Letzte 7 Tage',
      value: 'LAST_7',
    },
    {
      label: 'Letzte 30 Tage',
      value: 'LAST_30',
    },
    {
      label: 'Individuell',
      value: 'INDIVIDUAL',
    },
  ]

  constructor(
    private caregiverService: CaregiverService,
    public authService: AuthService,
    public phoneCallService: PhoneCallService,
    private helperService: HelperService,
    private planningService: PlanningService,
    private globalAnalysesService: GlobalAnalysesService,
    private eventbus: EventBusService,
    private dialogService: DialogService,
    private router: Router
  ) {
    dayjs.locale('de')
    dayjs.extend(customParseFormat)
    dayjs.extend(utc)
  }

  ngOnInit(): void {
    this.loadData()
    this.listenForEventbus()
  }

  ngOnDestroy(): void {
    this.eventBusSubscription.unsubscribe()
  }

  public loadData(withLoading: boolean = true): void {
    this.contentLoading = true

    if (withLoading) {
      this.loading = true
    }

    this.globalAnalysesService
      .recommendations(this.fields)
      .subscribe((data: any) => {
        this.data = data

        this.contentLoading = false

        if (withLoading) {
          this.loading = false
        }

        setTimeout(() => {
          if (this.table) {
            const totalItems = this.table._totalRecords

            this.globalFilterValue = this.table.filters.global?.value ?? ''

            if (
              this.table._first > totalItems ||
              this.table._rows > totalItems
            ) {
              this.firstPage = 0
            }
          }
        })
      })
  }

  public togglePaid(patient: any): void {
    if (patient.caregiver_paid_recommendation) {
      if (window.confirm('Bezahlung zurückziehen?')) {
        this.caregiverService
          .removePaidRecommendation(patient.caregiver_paid_recommendation.id)
          .subscribe(() => {
            this.loadData(false)
          })
      }
    } else {
      if (window.confirm('Als bezahlt markieren?')) {
        this.caregiverService
          .addPaidRecommendation(patient.customer.recommendation_id, patient.id)
          .subscribe(() => {
            this.loadData(false)
          })
      }
    }
  }

  public filterTableGlobal(event: any): void {
    this.table && this.table.filterGlobal(event.target.value.trim(), 'contains')
  }

  private listenForEventbus(): void {
    this.eventBusSubscription = this.eventbus.subject.subscribe(
      (event: GlobalEvent) => {
        switch (event) {
          case GlobalEvent.PlanningChanged:
            this.loadData(false)
        }
      }
    )
  }
}
