<form class="dialog-form">
  <div class="dialog-sidebar secondary-dialog-sidebar bigger-dialog-sidebar">
    <div class="dialog-sidebar-information" *ngIf="!loading">
      <div>
        <b>{{ data.caregiver.first_last_name }}</b>
      </div>
      <div>
        <b>{{ data.month }} {{ data.year }}</b>
      </div>
      <div>
        <b>{{ hoursTotal }} Std Gesamt ({{ totalPlannings }} Schichten)</b>
      </div>
      <div>
        <span class="color-gray"
          >{{ hoursGoogle }} Std Fahrzeiten von Google</span
        >
      </div>
    </div>

    <div class="dialog-sidebar-change-information" *ngIf="!loading">
      <div>
        <span class="color-gray">KM-Fahrten</span>
        <strong>+{{ data.caregiver.amount_trips }} km</strong>
      </div>

      <div>
        <span class="color-gray">Fahrzeiten</span>
        <strong
          >{{ data.caregiver.amount_drive_time > 0 ? "+" : ""
          }}{{ data.caregiver.amount_drive_time }} min</strong
        >
      </div>

      <div>
        <span class="color-gray">Dauer</span>
        <strong
          >{{ data.caregiver.amount_time > 0 ? "+" : ""
          }}{{ data.caregiver.amount_time }} h</strong
        >
      </div>
    </div>

    <div class="dialog-sidebar-accordion-container" *ngIf="!loading">
      <div class="dialog-sidebar-accordion">
        <div
          class="dialog-sidebar-accordion-header"
          (click)="accordions.feedback = !accordions.feedback"
        >
          <i class="pi pi-envelope"></i>Feedback ({{
            data.caregiver.caregiver_appointment_feedback?.length || "0"
          }})
          <i
            class="pi"
            [class.pi-chevron-down]="!accordions.feedback"
            [class.pi-chevron-up]="accordions.feedback"
          ></i>
        </div>
        <div
          class="dialog-sidebar-accordion-content"
          *ngIf="accordions.feedback"
        >
          <div
            class="dialog-no-message"
            *ngIf="data.caregiver.caregiver_appointment_feedback.length === 0"
          >
            Kein Feedback hinterlegt
          </div>

          <div
            class="dialog-sidebar-accordion-list"
            (click)="scrollToPersplanBudget(item.persplan_budget_id)"
            *ngFor="let item of data.caregiver.caregiver_appointment_feedback"
          >
            <span>{{ item.comment }}</span>
            <small
              >{{ item.persplan_budget.real_from | date: "dd.MM.y" }},
              {{ item.persplan_budget.patient.first_name }}
              {{ item.persplan_budget.patient.last_name }}</small
            >
          </div>
        </div>
      </div>
      <div class="dialog-sidebar-accordion">
        <div
          class="dialog-sidebar-accordion-header"
          (click)="accordions.trip = !accordions.trip"
        >
          <i class="pi pi-car"></i>KM-Fahrten ({{
            data.caregiver.caregiver_appointment_trip_comments?.length || "0"
          }})
          <i
            class="pi"
            [class.pi-chevron-down]="!accordions.trip"
            [class.pi-chevron-up]="accordions.trip"
          ></i>
        </div>
        <div class="dialog-sidebar-accordion-content" *ngIf="accordions.trip">
          <div
            class="dialog-no-message"
            *ngIf="
              data.caregiver.caregiver_appointment_trip_comments.length === 0
            "
          >
            Keine KM-Fahrten hinterlegt
          </div>

          <div
            class="dialog-sidebar-accordion-list"
            (click)="
              scrollToPersplanBudget(
                item.caregiver_appointment_trip.persplan_budget_id
              )
            "
            *ngFor="
              let item of data.caregiver.caregiver_appointment_trip_comments
            "
          >
            <span
              >{{ item.comment }} ({{
                item.caregiver_appointment_trip.mileage
              }}
              KM)</span
            >
            <small
              >{{
                item.caregiver_appointment_trip.persplan_budget.real_from
                  | date: "dd.MM.y"
              }},
              {{
                item.caregiver_appointment_trip.persplan_budget.patient
                  .first_name
              }}
              {{
                item.caregiver_appointment_trip.persplan_budget.patient
                  .last_name
              }}</small
            >
          </div>
        </div>
      </div>
      <div class="dialog-sidebar-accordion">
        <div
          class="dialog-sidebar-accordion-header"
          (click)="accordions.drive_time = !accordions.drive_time"
        >
          <i class="pi pi-clock"></i>Fahrzeiten ({{
            data.caregiver.caregiver_appointment_changes_drive_time?.length ||
              "0"
          }})
          <i
            class="pi"
            [class.pi-chevron-down]="!accordions.drive_time"
            [class.pi-chevron-up]="accordions.drive_time"
          ></i>
        </div>
        <div
          class="dialog-sidebar-accordion-content"
          *ngIf="accordions.drive_time"
        >
          <div
            class="dialog-no-message"
            *ngIf="
              data.caregiver.caregiver_appointment_changes_drive_time.length ===
              0
            "
          >
            Keine Fahrzeiten geändert
          </div>

          <div
            class="dialog-sidebar-accordion-list"
            (click)="scrollToPersplanBudget(item.persplan_budget_id)"
            *ngFor="
              let item of data.caregiver
                .caregiver_appointment_changes_drive_time
            "
          >
            <span
              >{{ item.drive_time > 0 ? "+" : ""
              }}{{ item.drive_time }} min</span
            >
            <small
              >{{ item.persplan_budget.real_from | date: "dd.MM.y" }},
              {{ item.persplan_budget.patient.first_name }}
              {{ item.persplan_budget.patient.last_name }}</small
            >
          </div>
        </div>
      </div>
      <div class="dialog-sidebar-accordion">
        <div
          class="dialog-sidebar-accordion-header"
          (click)="accordions.time = !accordions.time"
        >
          <i class="pi pi-clock"></i>Dauer ({{
            data.caregiver.caregiver_appointment_changes_time?.length || "0"
          }})
          <i
            class="pi"
            [class.pi-chevron-down]="!accordions.time"
            [class.pi-chevron-up]="accordions.time"
          ></i>
        </div>
        <div class="dialog-sidebar-accordion-content" *ngIf="accordions.time">
          <div
            class="dialog-no-message"
            *ngIf="
              data.caregiver.caregiver_appointment_changes_time.length === 0
            "
          >
            Keine Dauer geändert
          </div>

          <div
            class="dialog-sidebar-accordion-list"
            (click)="scrollToPersplanBudget(item.persplan_budget_id)"
            *ngFor="
              let item of data.caregiver.caregiver_appointment_changes_time
            "
          >
            <span>{{ item.time > 0 ? "+" : "" }}{{ item.time }} h</span>
            <small
              >{{ item.persplan_budget.real_from | date: "dd.MM.y" }},
              {{ item.persplan_budget.patient.first_name }}
              {{ item.persplan_budget.patient.last_name }}</small
            >
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="dialog-loader" *ngIf="loading">
    <i class="loading-logo"></i>
    WIRD GELADEN
  </div>

  <div class="dialog-form-body" *ngIf="!loading && listData?.length === 0">
    <span class="content-no-message">Keine Daten vorhanden</span>
  </div>

  <div
    class="content-body dialog-max-height without-padding"
    *ngIf="!loading && listData?.length > 0"
  >
    <p-table
      #dt
      [scrollable]="true"
      [value]="listData"
      [autoLayout]="true"
      [rowHover]="true"
      scrollHeight="504px"
      [globalFilterFields]="[
        'date',
        'data.real_from_h',
        'data.real_to_h',
        'data.patient.first_name',
        'data.patient.last_name',
        'data.current_patient_address',
        'data.persplan_function',
        'data.task',
      ]"
    >
      <ng-template pTemplate="header">
        <tr>
          <th style=""></th>
          <th style="" pSortableColumn="date">
            Datum <p-sortIcon field="date"></p-sortIcon>
          </th>
          <th style="" pSortableColumn="data.real_from_h">
            Uhrzeit <p-sortIcon field="data.real_from_h"></p-sortIcon>
          </th>
          <th style="" pSortableColumn="data.real_hours">
            Dauer <p-sortIcon field="data.real_hours"></p-sortIcon>
          </th>
          <th style="" pSortableColumn="google_drive_time_seconds_for_pdf">
            Fahrzeit
            <p-sortIcon field="google_drive_time_seconds_for_pdf"></p-sortIcon>
          </th>
          <th style="" pSortableColumn="data.patient.first_name">
            Patient <p-sortIcon field="data.patient.first_name"></p-sortIcon>
          </th>
          <th style="justify-content: flex-end">Ort</th>
          <th style="max-width: 50px"></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-data let-rowIndex="rowIndex">
        <tr
          [class.background-hightlight-yellow]="data.type === 'TYPE_HOLIDAY'"
          [class.background-hightlight-blue]="data.type === 'TYPE_VACATION'"
          [class.background-hightlight-main2]="
            data.type === 'TYPE_FREE_TIME_REQUEST' ||
            data.type === 'TYPE_OTHER' ||
            data.type === 'TYPE_TRAINING'
          "
          [class.background-hightlight-alert]="data.type === 'TYPE_ILL'"
          [class.background-hightlight-gray]="data.is_weekend"
          [attr.data-persplan-budget-id]="data.data?.id"
        >
          <td style="position: relative">
            <div style="display: flex; gap: 30px">
              <i
                (click)="openTripsDialog(data)"
                pTooltip="KM ansehen"
                *ngIf="
                  data.data &&
                  data.data.caregiver_appointment_trip?.comments.length > 0
                "
                class="pi pi-car color-gray caregiver-appointment-icon-container"
                style="font-size: 17px"
              >
                <p-badge
                  [severity]="
                    data.data.caregiver_appointment_trip.from_admin_user_id
                      ? 'danger'
                      : ''
                  "
                  [value]="data.data.caregiver_appointment_trip.comments.length"
                ></p-badge>
              </i>

              <i
                (click)="openTripsDialog(data)"
                pTooltip="KM eintragen"
                *ngIf="
                  data.data &&
                  !data.data.caregiver_appointment_trip &&
                  authService.isSuperAdmin()
                "
                class="pi pi-car color-gray caregiver-appointment-icon-container"
                style="font-size: 17px"
              >
              </i>

              <i
                (click)="openFeedbackDialog(data)"
                pTooltip="Feedback ansehen"
                *ngIf="
                  data.data &&
                  data.data.caregiver_appointment_feedback.length > 0
                "
                class="pi pi-envelope color-gray caregiver-appointment-icon-container"
                style="font-size: 17px"
              >
                <p-badge
                  [value]="data.data.caregiver_appointment_feedback.length"
                ></p-badge>
              </i>
            </div>

            <!-- Feiertag -->
            <span
              style="display: flex; align-items: center"
              *ngIf="
                data.type === 'TYPE_HOLIDAY' ||
                data.type === 'TYPE_VACATION' ||
                data.type === 'TYPE_FREE_TIME_REQUEST' ||
                data.type === 'TYPE_OTHER' ||
                data.type === 'TYPE_TRAINING' ||
                data.type === 'TYPE_ILL'
              "
              >{{ data.value }}
              <i
                style="font-size: 18px; margin: 0 0 0 5px"
                class="pi pi-info-circle"
                *ngIf="data.tooltip"
                [pTooltip]="data.tooltip"
              ></i
            ></span>
            <!-- Keine Planung -->
            <span style="display: flex; align-items: center" *ngIf="!data.type"
              >Keine Planung</span
            >
          </td>
          <!-- Datum -->
          <td style="vertical-align: top">{{ data.date }} ({{ data.day }})</td>
          <!-- Uhrzeit -->
          <td style="vertical-align: top" *ngIf="data.data">
            {{ data.data.real_from_h }} - {{ data.data.real_to_h }}
          </td>
          <td *ngIf="!data.data"></td>
          <!-- Dauer -->
          <td style="vertical-align: top" *ngIf="data.data">
            <div>
              {{ data.data.real_hours_as_float }} Std
              <!-- Angepasste Dauer der BK selbst -->
              <span
                class="color-blue"
                tooltipStyleClass="tooltip-wider"
                pTooltip="Geänderte Dauer durch BK"
                *ngIf="data.data.caregiver_appointment_change.time != 0"
              >
                <br />
                <span *ngIf="data.data.caregiver_appointment_change.time > 0">
                  +</span
                >{{
                  data.data.caregiver_appointment_change.time
                    .toString()
                    .replace(".", ",")
                }}
                Std</span
              >
            </div>

            <i
              (click)="openChangeFeedbackDialog(data, 'time')"
              pTooltip="Kommentar"
              class="pi pi-envelope color-gray caregiver-appointment-icon-container"
              style="font-size: 17px; margin: 0 0 0 10px"
              *ngIf="data.data.caregiver_appointment_change.time_feedback"
            >
              <p-badge value="1"></p-badge>
            </i>
          </td>
          <td *ngIf="!data.data"></td>
          <!-- Fahrzeit -->
          <td style="vertical-align: top" *ngIf="data.data">
            <div>
              <span
                pTooltip="Fahrzeit von Google"
                *ngIf="data.calculate_google_drive_time_for_pdf"
                >{{ data.google_drive_time_formatted_for_pdf }}</span
              >
              <!-- Fahrzeit angepasst durch BK selbst -->
              <span
                class="color-blue"
                tooltipStyleClass="tooltip-wider"
                pTooltip="Anpassung Fahrzeit durch BK"
                *ngIf="data.data.caregiver_appointment_change.drive_time"
              >
                <br />
                <span
                  *ngIf="data.data.caregiver_appointment_change.drive_time > 0"
                >
                  +</span
                >{{
                  data.data.caregiver_appointment_change.drive_time
                }}
                min</span
              >
            </div>

            <i
              (click)="openChangeFeedbackDialog(data, 'drive_time')"
              pTooltip="Kommentar"
              class="pi pi-envelope color-gray caregiver-appointment-icon-container"
              style="font-size: 17px; margin: 0 0 0 10px"
              *ngIf="data.data.caregiver_appointment_change.drive_time_feedback"
            >
              <p-badge value="1"></p-badge>
            </i>
          </td>
          <td *ngIf="!data.data"></td>
          <!-- Patient -->
          <td style="vertical-align: top" *ngIf="data.data">
            <a target="_blank" routerLink="/patients/{{ data.data.patient_id }}"
              ><span class="table-data-highlight"
                >{{ data.data.patient.first_name }}
                {{ data.data.patient.last_name }}</span
              ></a
            >
          </td>
          <td *ngIf="!data.data"></td>
          <!-- Ort -->
          <td
            style="vertical-align: top; justify-content: flex-end"
            *ngIf="data.data"
          >
            <i
              class="pi pi-info-circle"
              tooltipPosition="left"
              [pTooltip]="data.address"
            ></i>
          </td>
          <td *ngIf="!data.data"></td>

          <!-- Freigabe der Betreuungskraft -->
          <td *ngIf="data.data" style="max-width: 50px">
            <i
              *ngIf="data.data.caregiver_appointment_release"
              class="pi pi-check-circle"
              [class.color-main1]="
                !data.data.caregiver_appointment_release.from_admin
              "
              [class.color-blue]="
                data.data.caregiver_appointment_release.from_admin
              "
              tooltipPosition="left"
              [pTooltip]="
                data.data.caregiver_appointment_release.from_admin
                  ? 'Durch Admin freigegeben'
                  : 'Durch BK freigegeben'
              "
            ></i>
          </td>
          <td *ngIf="!data.data" style="max-width: 50px"></td>
        </tr>
      </ng-template>
    </p-table>
  </div>

  <div class="dialog-form-footer" *ngIf="!loading && listData?.length > 0">
    <i></i>

    <small class="color-alert" *ngIf="!data.caregiver.approved_overview">
      Dienstplan wurde noch nicht freigegeben
    </small>

    <div
      class="dialog-footer-action-container"
      *ngIf="data.caregiver.approved_overview?.released"
    >
      <button
        *ngIf="!data.caregiver.approved_overview?.release_reopened"
        pButton
        label="Freigabe zurücksetzen"
        type="submit"
        class="p-button-outlined"
        (click)="reopenRelease()"
        [disabled]="submittedReopen"
        [icon]="submittedReopen ? 'pi pi-spin pi-spinner' : 'pi pi-times'"
      ></button>

      <button
        pButton
        *ngIf="!data.caregiver.approved_overview.release_confirmed"
        label="Freigabe bestätigen"
        type="submit"
        (click)="confirmRelease()"
        [disabled]="submittedConfirmed"
        [icon]="submittedConfirmed ? 'pi pi-spin pi-spinner' : 'pi pi-check'"
      ></button>

      <small
        class="color-main1"
        *ngIf="data.caregiver.approved_overview.release_confirmed"
      >
        Vom Admin bestätigt
      </small>
    </div>
  </div>
</form>
