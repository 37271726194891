import { Component, OnInit } from '@angular/core'
import { CaregiverDocumentTypesModel } from '../../../models/caregiver/caregiver-detail-documents.model'
import { ToastService } from '../../../services/toast.service'
import {
  DocumentModel,
  DocumentModelSimple,
} from '../../../models/document/document.model'
import { MediaModel } from '../../../models/document/media.model'
import { TitleService } from '../../../services/title.service'
import { UserCaregiverService } from '../../../services/user-caregiver.service'

@Component({
  selector: 'app-user-caregiver-documents',
  templateUrl: './user-caregiver-documents.component.html',
})
export class UserCaregiverDocumentsComponent implements OnInit {
  public loading = true

  public documents: DocumentModelSimple[] = []
  public documentNames: string[] = []

  constructor(
    public userCaregiverService: UserCaregiverService,
    private toastService: ToastService,
    private titleService: TitleService
  ) {}

  ngOnInit(): void {
    document.body.classList.remove('mobile-nav-visible')

    this.titleService.setTitle(`Leistungsnachweise`)

    this.loadDocuments()
  }

  public openDownloadTab(document: MediaModel): void {
    window.open(
      this.userCaregiverService.getDownloadDocumentLink(document.uuid)
    )
  }

  public loadDocuments(withLoading: boolean = true): void {
    if (withLoading) {
      this.loading = true
    }

    this.userCaregiverService
      .loadDocuments()
      .subscribe((data: DocumentModelSimple[]) => {
        this.documents = data
        this.documentNames = Object.keys(data)

        if (withLoading) {
          this.loading = false
        }
      })
  }
}
