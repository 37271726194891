<div *ngIf="multiplierDetail.notice" class="notice-wrap">
  <i class="pi pi-paperclip notice-wrap-drag-handle notice-icon"></i>
  <div (click)="openNoticeDialog()" class="notice-text">
    {{ multiplierDetail.notice }}
  </div>
</div>

<div class="content-actions">
  <h2>
    {{ multiplierDetail.name }}

    <i
      *ngIf="multiplierDetail.user && multiplierDetail.user.deleted_at"
      class="p-tag p-tag-danger p-tag-rounded"
    ><i class="pi pi-user"></i>Account inaktiv</i
    >

    <i
      *ngIf="multiplierDetail.user && !multiplierDetail.user.deleted_at"
      class="p-tag p-tag-rounded"
    ><i class="pi pi-user"></i>Account aktiv</i
    >

    <i
      *ngIf="multiplierDetail.deleted_at"
      class="p-tag p-tag-danger p-tag-rounded"
      >Deaktiviert</i
    >
  </h2>

  <a
    routerLink="/multipliers/{{ multiplierDetail.id }}/edit"
    *ngIf="multiplierDetail.id"
  >
    <button
      pButton
      label="Bearbeiten"
      class="p-button-text"
      type="button"
      icon="pi pi-pencil"
      iconPos="right"
    ></button>
  </a>
</div>

<header class="content-header-tabs" [class.disabled]="!multiplierDetail.id">
  <a
    routerLink="/multipliers/{{ multiplierDetail.id }}"
    routerLinkActive="active"
    [routerLinkActiveOptions]="{ exact: true }"
    >Allgemeines</a
  >
  <a
    routerLink="/multipliers/{{ multiplierDetail.id }}/history"
    routerLinkActive="active"
    [routerLinkActiveOptions]="{ exact: true }"
    >Historie</a
  >
</header>

<router-outlet></router-outlet>
