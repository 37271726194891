import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { environment } from '../../environments/environment'
import { Observable } from 'rxjs'
import { RoleModel } from '../models/permission/role.model'
import { PermissionsNamespaced } from '../models/permission/permission.model'
import { HistoryListModel } from '../models/history/history-list.model'

@Injectable({
  providedIn: 'root',
})
export class RolePermissionService {
  constructor(private http: HttpClient) {}

  public roles(): Observable<RoleModel[]> {
    return this.http.get<RoleModel[]>(`${environment.api}/permissions/roles`)
  }

  public history(id: string | null): Observable<HistoryListModel[]> {
    return this.http.get<HistoryListModel[]>(
      `${environment.api}/permissions/${id}/history`
    )
  }

  public role(roleId: string | null): Observable<RoleModel> {
    return this.http.get<RoleModel>(
      `${environment.api}/permissions/${roleId}/role`
    )
  }

  public permissions(): Observable<PermissionsNamespaced> {
    return this.http.get<PermissionsNamespaced>(
      `${environment.api}/permissions`
    )
  }

  public update(data: any): Observable<RoleModel> {
    return this.http.put<RoleModel>(
      `${environment.api}/permissions/${data.id}`,
      data
    )
  }

  public store(data: any): Observable<RoleModel> {
    return this.http.post<RoleModel>(`${environment.api}/permissions`, data)
  }
}
