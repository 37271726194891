import { UserSystemModel } from '../user/user-system.model'
import { CaregiverDetailModel } from '../caregiver/caregiver-detail.model'
import { PatientDetailModel } from '../customer-patient/patient-detail.model'

export class VetoModel {
  id?: number = undefined
  comment = ''
  created_from: number | null = null
  caregiver_id: number | null = null
  patient_id: number | null = null
  created_from_patient = false
  created_from_caregiver = false
  caregiver: CaregiverDetailModel = new CaregiverDetailModel()
  patient: PatientDetailModel = new PatientDetailModel()
  user: UserSystemModel[] | any[] = []
  deleted_at: Date | null = null
  created_at: Date | null = null
  updated_at: Date | null = null
}
