import { AuthGuard } from '../guards/auth.guard'
import { AccountingComponent } from '../views/accounting/accounting.component'
import { AccountingReturnsComponent } from '../views/accounting/returns/accounting-returns.component'
import { AccountingReturnsCreatedComponent } from '../views/accounting/returns/created/accounting-returns-created.component'
import { AccountingReturnsEditedComponent } from '../views/accounting/returns/edited/accounting-returns-edited.component'
import { AccountingReturnsFinishedComponent } from '../views/accounting/returns/finished/accounting-returns-finished.component'

export const accountingRoutes = [
  {
    path: 'accounting',
    component: AccountingComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        redirectTo: 'returns',
        pathMatch: 'full',
      },
      {
        path: 'returns',
        component: AccountingReturnsComponent,
        children: [
          {
            path: '',
            component: AccountingReturnsCreatedComponent,
          },
          {
            path: 'edited',
            component: AccountingReturnsEditedComponent,
          },
          {
            path: 'finished',
            component: AccountingReturnsFinishedComponent,
          },
        ],
      },
    ],
  },
]
