import { Component, OnInit } from '@angular/core'
import { DocumentService } from '../../../services/document.service'
import {
  DialogService,
  DynamicDialogConfig,
  DynamicDialogRef,
} from 'primeng/dynamicdialog'
import { CaregiverOverviewService } from '../../../services/caregiver-overview.service'
import { AppointmentFeedbackDialogComponent } from '../appointment-feedback-dialog/appointment-feedback-dialog.component'
import { AppointmentTripsDialogComponent } from '../appointment-trips-dialog/appointment-trips-dialog.component'
import {
  EventBusService,
  GlobalEvent,
} from '../../../services/eventbus.service'
import { ToastService } from '../../../services/toast.service'
import { AppointmentChangeFeedbackDialogComponent } from '../appointment-change-feedback-dialog/appointment-change-feedback-dialog.component'
import { AuthService } from '../../../services/auth.service'

@Component({
  selector: 'app-show-caregiver-overview-release-dialog',
  styleUrls: ['./show-caregiver-overview-release-dialog.component.scss'],
  templateUrl: './show-caregiver-overview-release-dialog.component.html',
})
export class ShowCaregiverOverviewReleaseDialogComponent implements OnInit {
  public listData: any
  public hoursGoogle = 0
  public hoursTotal = 0
  public totalPlannings = 0

  public submittedConfirmed = false
  public submittedReopen = false
  public data: any
  public loading = true

  public accordions = {
    feedback: false,
    trip: false,
    time: false,
    drive_time: false,
  }

  constructor(
    public authService: AuthService,
    public documentService: DocumentService,
    private ref: DynamicDialogRef,
    private eventbus: EventBusService,
    private toastService: ToastService,
    private caregiverOverviewService: CaregiverOverviewService,
    private config: DynamicDialogConfig,
    private dialogService: DialogService
  ) {}

  public ngOnInit(): void {
    this.data = this.config.data

    this.load()
  }

  public confirmRelease(): void {
    this.submittedConfirmed = true

    this.caregiverOverviewService
      .confirmRelease(this.data.caregiver.approved_overview.id)
      .subscribe(
        () => {
          this.submittedConfirmed = false
          this.eventbus.emit(GlobalEvent.CaregiverOverviewChanged)
          this.ref.close()
          this.toastService.success(
            'Freigabe bestätigt',
            'Der Dienstplan wurde erfolgreich bestätigt'
          )
        },
        () => {
          this.toastService.error(
            'Etwas ist schiefgelaufen...',
            'Bitte wenden Sie sich an den Support'
          )

          this.submittedConfirmed = false
        }
      )
  }

  private load(): void {
    this.caregiverOverviewService
      .list(this.data.caregiver.id, this.data.month, this.data.year)
      .subscribe((data: any) => {
        this.listData = data.list_data
        this.hoursTotal = data.hours_total
        this.totalPlannings = data.total_plannings
        this.hoursGoogle = data.hours_google

        this.loading = false
      })
  }

  public reopenRelease(): void {
    this.submittedReopen = true

    this.caregiverOverviewService
      .reopenRelease(this.data.caregiver.approved_overview.id)
      .subscribe(
        () => {
          this.submittedReopen = false
          this.eventbus.emit(GlobalEvent.CaregiverOverviewChanged)
          this.ref.close()
          this.toastService.success(
            'Freigabe zurückgesetzt',
            'Der Dienstplan wurde erfolgreich zurückgesetzt'
          )
        },
        () => {
          this.toastService.error(
            'Etwas ist schiefgelaufen...',
            'Bitte wenden Sie sich an den Support'
          )

          this.submittedReopen = false
        }
      )
  }

  public scrollToPersplanBudget(persplanBudgetId: number): void {
    // setTimeout(() => {
    const element = document.querySelector(
      `[data-persplan-budget-id="${persplanBudgetId}"]`
    )
    if (element) {
      element.scrollIntoView({ block: 'end', behavior: 'smooth' })

      setTimeout(() => {
        element.classList.add('table-row-highlight')
      }, 200)

      setTimeout(() => {
        element.classList.remove('table-row-highlight')
      }, 3000)
    }
    // })
  }

  public openFeedbackDialog(data: any): void {
    this.dialogService.open(AppointmentFeedbackDialogComponent, {
      header: 'Feedback',
      width: '520px',
      styleClass: 'dialog-container',
      data: {
        ...data,
        readonly: true,
      },
      dismissableMask: true,
    })
  }

  public openChangeFeedbackDialog(
    data: any,
    type: 'time' | 'drive_time'
  ): void {
    this.dialogService.open(AppointmentChangeFeedbackDialogComponent, {
      header: 'Kommentar',
      width: '520px',
      styleClass: 'dialog-container',
      data: {
        ...data,
        type,
        readonly: true,
      },
      dismissableMask: true,
    })
  }

  public openTripsDialog(data: any): void {
    const ref = this.dialogService.open(AppointmentTripsDialogComponent, {
      header: 'Zusätzliche Fahrten',
      width: '520px',
      styleClass: 'dialog-container',
      data: {
        ...data,
        fromAdmin: true,
        readonly: false,
      },
      dismissableMask: true,
    })

    ref.onClose.subscribe((value: boolean) => {
      if (value) {
        this.load()
        this.eventbus.emit(GlobalEvent.CaregiverOverviewChanged)
      }
    })
  }
}
