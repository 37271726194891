import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core'
import { PhoneCallService } from '../../services/phone-call.service'
import {PhoneCallsListItemModel, PhoneCallsListModel} from '../../models/phone-call/phone-calls-list.model'
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog'
import { PhoneCallEditDialogComponent } from 'src/app/components/dialogs/phone-call-edit-dialog/phone-call-edit-dialog.component'
import { GlobalEvent, EventBusService } from 'src/app/services/eventbus.service'
import { Subscription } from 'rxjs'
import { AuthService } from '../../services/auth.service'
import { TitleService } from 'src/app/services/title.service'
import { AuthModel } from '../../models/user/auth.model'
import {ToastService} from "../../services/toast.service";

@Component({
  selector: 'app-phone-calls',
  templateUrl: './phone-calls.component.html',
  styleUrls: ['./phone-calls.component.scss'],
})
export class PhoneCallsComponent implements OnInit, OnDestroy {
  @ViewChild('dt') table: ElementRef | any = null

  private channel: any = null
  public globalFilterValue = ''
  public phoneCalls = new PhoneCallsListModel()
  public loading = true
  private eventBusSubscription: Subscription = new Subscription()

  public firstPage = 0

  public user: AuthModel | null = new AuthModel()

  constructor(
    public phoneCallService: PhoneCallService,
    public authService: AuthService,
    private dialogService: DialogService,
    private toastService: ToastService,
    private eventbus: EventBusService,
    private titleService: TitleService
  ) {}

  ngOnInit(): void {
    this.user = this.authService.getUser()

    this.listenForEventbus()
    this.loadList()
    this.titleService.setTitle(`Telefonate`)
  }

  ngOnDestroy(): void {
    this.eventBusSubscription.unsubscribe()
  }

  public deleteActiveCall(call: PhoneCallsListItemModel): void {
    if (!window.confirm('Telefonat wirklich löschen?')) {
      return
    }

    this.phoneCallService.delete(call.id).subscribe(
      () => {
        this.loadList(false)
      },
      () => {
        this.toastService.error(
          'Telefonat konnte nicht gelöscht werden',
          'Bitte wenden Sie sich an den Support'
        )
      }
    )
  }

  public filterTableGlobal(event: any): void {
    this.table && this.table.filterGlobal(event.target.value.trim(), 'contains')
  }

  /**
   * Öffnet das Modal zum Bearbeiten eines Phonecalls.
   */
  public openPhonecallEditModal(phonecall: PhoneCallsListModel): void {
    this.dialogService.open(PhoneCallEditDialogComponent, {
      header: 'Telefonat bearbeiten',
      width: '540px',
      styleClass: 'dialog-container',
      dismissableMask: false,
      data: phonecall,
    })
  }

  /**
   * Hier reagieren wir auf Events im EventBusService, wie z.B. das Event,
   * dass die PhoneCall-Liste die Daten neu laden soll (wenn z.B. ein Telefonat
   * gelöscht oder bearbeitet wurde).
   */
  private listenForEventbus(): void {
    this.eventBusSubscription = this.eventbus.subject.subscribe(
      (event: GlobalEvent) => {
        switch (event) {
          case GlobalEvent.PhoneCallListReload:
            this.loadList(false)
        }
      }
    )
  }

  private loadList(withLoading: boolean = true): void {
    if (withLoading) {
      this.loading = true
    }

    this.phoneCallService.list().subscribe((data: PhoneCallsListModel) => {
      this.phoneCalls.active = data.active
      this.phoneCalls.finished = data.finished

      if (withLoading) {
        this.loading = false
      }

      setTimeout(() => {
        if (this.table) {
          const totalItems = this.table._totalRecords

          this.globalFilterValue = this.table.filters.global?.value ?? ''

          if (
            this.table._first > totalItems ||
            this.table._rows > totalItems
          ) {
            this.firstPage = 0
          }
        }
      })
    })
  }
}
