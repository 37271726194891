<app-loader [loading]="loading"></app-loader>

<div class="content" *ngIf="!loading">
  <header class="content-header">
    <span>Abgeschlossene Termine</span>

    <div class="content-header-buttons">
      <button
        pButton
        label="Neuer Wunschtermin"
        type="button"
        icon="pi pi-plus"
        iconPos="left"
        (click)="openDesiredDateDialog()"
        class="p-button-outlined"
      ></button>
      <div
        class="table-search-container"
        [class.searchfield-active]="globalFilterValue1 != ''"
        *ngIf="desiredDates.length > 0"
      >
        <div class="p-input-icon-left">
          <i class="pi pi-search"></i>
          <input
            pInputText
            (input)="filterTable1Global($event)"
            placeholder="Suche..."
            [(ngModel)]="globalFilterValue1"
          />
        </div>
      </div>
    </div>
  </header>
</div>

<div class="content" style="margin-bottom: 0" *ngIf="!loading">
  <div class="content-body" *ngIf="desiredDates.length == 0">
    <span class="content-no-message"
      >Keine abgeschlossenen Termine vorhanden</span
    >
  </div>

  <div
    class="content-body content-body-full-height-with-tabs without-padding"
    *ngIf="desiredDates.length > 0"
  >
    <p-table
      #dt1
      [value]="desiredDates"
      [autoLayout]="true"
      [globalFilterFields]="[
        'full_name',
        'caregiver.full_name',
        'address',
        'phone_number_string',
        'comment'
      ]"
      [(first)]="firstPage"
      [rowHover]="true"
      [showCurrentPageReport]="true"
      currentPageReportTemplate="{totalRecords} Terminwünsche"
      [rows]="50"
      [paginator]="true"
      scrollHeight="100%"
      [scrollable]="true"
    >
      <ng-template pTemplate="header">
        <tr class="table-headline-highlight">
          <th style="min-width: 300px" pSortableColumn="full_name">
            Name <p-sortIcon field="full_name"></p-sortIcon>
          </th>
          <th style="min-width: 200px" pSortableColumn="address">
            Wohnort <p-sortIcon field="address"></p-sortIcon>
          </th>
          <th style="min-width: 150px">Telefon</th>
          <th style="min-width: 200px" pSortableColumn="date_at">
            Termin
            <p-sortIcon field="date_at"></p-sortIcon>
          </th>
          <th>Bemerkung</th>
          <th pSortableColumn="caregiver.first_name">
            Betreuungskraft
            <p-sortIcon field="caregiver.first_name"></p-sortIcon>
          </th>
          <th style="max-width: 50px"></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-date>
        <tr>
          <td style="min-width: 300px" *ngIf="!date.patient_id">
            {{ date.full_name }}
          </td>
          <td style="min-width: 300px" *ngIf="date.patient_id">
            <a target="_blank" routerLink="/patients/{{ date.patient_id }}">{{
              date.full_name
            }}</a>
          </td>

          <td style="min-width: 200px">{{ date.address }}</td>

          <td style="min-width: 150px" *ngIf="date.phone_number_string">
            <a
              href="tel:{{ date.phone_number_string }}"
              (click)="phoneCallService.createCalling(date.phone_number_string)"
              >{{ date.phone_number_string }}</a
            >
          </td>
          <td style="min-width: 150px" *ngIf="!date.phone_number_string">-</td>

          <td style="min-width: 200px" *ngIf="!date.no_time">
            <div style="display: flex; flex-direction: column">
              {{ date.time_from | date: "dd.MM.y, H:mm" }} -
              {{ date.time_to | date: "H:mm" }}
              <span class="color-gray"
                >{{ date.date_at | date: "EEEE" }} (KW
                {{ date.week_of_year }})</span
              >
            </div>
          </td>
          <td style="min-width: 200px" *ngIf="date.no_time">
            <div style="display: flex; flex-direction: column">
              {{ date.date_at | date: "dd.MM.y" }}
              <span class="color-gray"
                >{{ date.date_at | date: "EEEE" }} (KW
                {{ date.week_of_year }})</span
              >
            </div>
          </td>

          <td>{{ date.comment }}</td>

          <td *ngIf="date.caregiver">
            {{ date.caregiver.first_name }} {{ date.caregiver.last_name }}
          </td>
          <td *ngIf="!date.caregiver">-</td>

          <td style="max-width: 50px" class="table-action">
            <i (click)="openDesiredDateDialog(date)" class="pi pi-pencil"></i>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
