<div class="content-actions">
  <h2>
    <i class="tio">appointment_cancelled</i>
    Terminabsagen
  </h2>
</div>

<header class="content-header-tabs">
  <a
    routerLink="/cancelled-dates"
    routerLinkActive="active"
    [routerLinkActiveOptions]="{ exact: true }"
  >
    Letzte 30 Tage
  </a>
  <a
    routerLink="/cancelled-dates/all"
    routerLinkActive="active"
    [routerLinkActiveOptions]="{ exact: true }"
  >
    Alle
  </a>
</header>

<router-outlet></router-outlet>
