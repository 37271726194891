import { Injectable } from '@angular/core'
import { HttpClient, HttpParams } from '@angular/common/http'
import { environment } from '../../environments/environment'
import { Observable } from 'rxjs'
import { QmModel } from '../models/customer-patient/qm.model'

@Injectable({
  providedIn: 'root',
})
export class CustomerService {
  constructor(private http: HttpClient) {}

  public loadQm(id: number): Observable<QmModel> {
    return this.http.get<QmModel>(`${environment.api}/qm/${id}`)
  }

  public loadQmForPatient(id: number): Observable<QmModel> {
    return this.http.get<QmModel>(`${environment.api}/qm/patient/${id}`)
  }

  public finishQualification(
    customerId: number | null,
    form: FormData
  ): Observable<[]> {
    return this.http.post<[]>(
      `${environment.api}/patients/${customerId}/finish-qualification`,
      form
    )
  }

  public getThcQmPreviewLink(id: number): string {
    return `${environment.api}/qm/${id}/thc-pdf`
  }

  public updateThcQm(id: number, data: any): Observable<QmModel> {
    return this.http.put<QmModel>(`${environment.api}/qm/${id}/thc`, data)
  }

  public storeThcQm(patientId: number, data: any): Observable<QmModel> {
    return this.http.post<QmModel>(
      `${environment.api}/qm/${patientId}/thc`,
      data
    )
  }

  public removeThcQm(qmId: number): Observable<QmModel> {
    return this.http.delete<QmModel>(`${environment.api}/qm/${qmId}/thc`)
  }

  public getThcMailPreviewLink(values: any): string {
    const params = new HttpParams({ fromObject: values }).toString()

    return `${environment.api}/qm/thc-preview/${values.patient?.id}?${params}`
  }
}
