import { VetoModel } from '../veto/veto.model'
import { AppointmentLockModel } from '../appointments/appointment-lock.model'

export class CustomerDetailVetoModel {
  first_patient: PatientDetailVetoModel = new PatientDetailVetoModel()
  second_patient: PatientDetailVetoModel = new PatientDetailVetoModel()
}

export class PatientDetailVetoModel {
  patient_type = ''
  full_name = ''
  id = 0
  is_paused = false
  status = ''
  status_string = ''
  vetos: VetoModel[] = []
  appointment_lock: AppointmentLockModel | null = null
}
