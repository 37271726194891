import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs'
import { environment } from '../../environments/environment'

@Injectable({
  providedIn: 'root',
})
export class CaregiverDateService {
  constructor(private http: HttpClient) {}

  public upload(caregiverId: number | string, form: FormData): Observable<[]> {
    return this.http.post<[]>(
      `${environment.api}/caregiver-date/${caregiverId}`,
      form
    )
  }

  public update(
    caregiverDateId: number | string,
    form: FormData
  ): Observable<[]> {
    return this.http.post<[]>(
      `${environment.api}/caregiver-date/update/${caregiverDateId}`,
      form
    )
  }

  public delete(caregiverDateId: number | string): Observable<[]> {
    return this.http.delete<[]>(
      `${environment.api}/caregiver-date/${caregiverDateId}`
    )
  }
}
