import { Injectable } from '@angular/core'
import { HttpClient, HttpParams } from '@angular/common/http'
import { environment } from '../../environments/environment'
import { Observable } from 'rxjs'
import { CaregiverFormModel } from '../models/caregiver/caregiver-form.model'
import { CaregiverListActivatedModel } from '../models/caregiver/caregiver-list-activated.model'
import { CaregiverDetailModel } from '../models/caregiver/caregiver-detail.model'
import { HistoryListModel } from '../models/history/history-list.model'
import { CaregiverListDeactivatedModel } from '../models/caregiver/caregiver-list-deactivated.model'
import { CaregiverDetailDocumentsModel } from '../models/caregiver/caregiver-detail-documents.model'
import { CaregiverDetailGeneralModel } from '../models/caregiver/caregiver-detail-general.model'
import { CaregiverDependenciesModel } from '../models/caregiver/caregiver-dependencies.model'
import { VacationIllModel } from '../models/vacation-ill/vacation-ill.model'
import { VetoModel } from '../models/veto/veto.model'
import { CaregiverDatesModel } from '../models/caregiver/caregiver-dates.model'
import { CaregiverProofGroupedModel } from '../models/caregiver/caregiver-proof.model'
import { PatientModel } from '../models/customer-patient/patient.model'

@Injectable({
  providedIn: 'root',
})
export class CaregiverService {
  constructor(private http: HttpClient) {}

  public list(): Observable<CaregiverListActivatedModel[]> {
    return this.http.get<CaregiverListActivatedModel[]>(
      `${environment.api}/caregivers`
    )
  }

  public listDeactivated(): Observable<CaregiverListDeactivatedModel[]> {
    return this.http.get<CaregiverListDeactivatedModel[]>(
      `${environment.api}/caregivers/deactivated`
    )
  }

  public loadDataForMessage(id: any): Observable<any> {
    return this.http.get<any>(
      `${environment.api}/caregivers/${id}/data-for-message`
    )
  }

  public getMailPreviewLinkForMessage(values: any): string {
    const params = new HttpParams({ fromObject: values }).toString()

    return `${environment.api}/caregivers/send-message/preview?${params}`
  }

  public getJobInterviewMailPreviewLink(caregiverId: any, values: any): string {
    const params = new HttpParams({ fromObject: values }).toString()

    return `${environment.api}/caregivers/${caregiverId}/job-interview-preview?${params}`
  }

  public listApplicantsDeactivated(): Observable<
    CaregiverListDeactivatedModel[]
  > {
    return this.http.get<CaregiverListDeactivatedModel[]>(
      `${environment.api}/caregivers/applicants/deactivated`
    )
  }

  public listApplicants(): Observable<CaregiverListActivatedModel[]> {
    return this.http.get<CaregiverListActivatedModel[]>(
      `${environment.api}/caregivers/applicants`
    )
  }

  public editThcDate(id: string | number, newDate: any): Observable<any[]> {
    return this.http.put<any[]>(
      `${environment.api}/caregivers/thc-dates/${id}`,
      {
        newDate,
      }
    )
  }

  public readHospitationFeedback(
    hospitationFeedbackId: number
  ): Observable<any[]> {
    return this.http.put<any[]>(
      `${environment.api}/caregivers/read-hospitation-feedback/${hospitationFeedbackId}`,
      {}
    )
  }

  public confirmHospitation(caregiverStatusId: number): Observable<any[]> {
    return this.http.put<any[]>(
      `${environment.api}/caregivers/confirm-hospitation/${caregiverStatusId}`,
      {}
    )
  }

  public dependencies(): Observable<CaregiverDependenciesModel> {
    return this.http.get<CaregiverDependenciesModel>(
      `${environment.api}/caregivers/dependencies`
    )
  }

  public load(id: string | null): Observable<CaregiverDetailGeneralModel> {
    return this.http.get<CaregiverDetailGeneralModel>(
      `${environment.api}/caregivers/${id}`
    )
  }

  public history(id: string | null): Observable<HistoryListModel[]> {
    return this.http.get<HistoryListModel[]>(
      `${environment.api}/caregivers/${id}/history`
    )
  }

  public vacation(
    id: string | null
  ): Observable<{
    vacation: VacationIllModel[]
    free_time_request: VacationIllModel[]
  }> {
    return this.http.get<{
      vacation: VacationIllModel[]
      free_time_request: VacationIllModel[]
    }>(`${environment.api}/caregivers/${id}/vacation`)
  }

  public ill(id: string | null): Observable<VacationIllModel[]> {
    return this.http.get<VacationIllModel[]>(
      `${environment.api}/caregivers/${id}/ill`
    )
  }

  public changeStatus(
    caregiver: CaregiverDetailModel,
    values: any
  ): Observable<[]> {
    return this.http.post<[]>(
      `${environment.api}/caregivers/${caregiver.id}/change-status`,
      {
        ...values,
        caregiver,
      }
    )
  }

  public sendMessage(id: any, values: any): Observable<[]> {
    return this.http.post<[]>(
      `${environment.api}/caregivers/${id}/send-message`,
      {
        ...values,
      }
    )
  }

  public changeStatusEdit(id: number | null, values: any): Observable<[]> {
    return this.http.put<[]>(
      `${environment.api}/caregivers/${id}/change-status`,
      {
        ...values,
      }
    )
  }

  public dates(id: string | null): Observable<CaregiverDatesModel[]> {
    return this.http.get<CaregiverDatesModel[]>(
      `${environment.api}/caregivers/${id}/dates`
    )
  }

  public recommendations(id: string | null): Observable<PatientModel[]> {
    return this.http.get<PatientModel[]>(
      `${environment.api}/caregivers/${id}/recommendations`
    )
  }

  public proofs(id: string | null): Observable<CaregiverProofGroupedModel> {
    return this.http.get<CaregiverProofGroupedModel>(
      `${environment.api}/caregivers/${id}/proofs`
    )
  }

  // TODO: model erstellen
  public loadPatientsForMonth(id: string | null, data: any): Observable<any> {
    const params = new HttpParams({ fromObject: data }).toString()

    return this.http.get<any>(
      `${environment.api}/caregivers/${id}/patients-for-month?${params}`
    )
  }

  public loadPatientsForDay(
    id: string | null,
    date: string
  ): Observable<string[]> {
    return this.http.get<string[]>(
      `${environment.api}/caregivers/${id}/patients-for-day`,
      {
        params: {
          date,
        },
      }
    )
  }

  public loadPatientsForDateRange(
    id: string | null,
    from: string,
    to: string
  ): Observable<string[]> {
    return this.http.get<string[]>(
      `${environment.api}/caregivers/${id}/patients-for-date-range`,
      {
        params: {
          from,
          to,
        },
      }
    )
  }

  public loadPatientsSinceDate(
    id: number | null,
    from: string
  ): Observable<string[]> {
    return this.http.get<string[]>(
      `${environment.api}/caregivers/${id}/patients-since-date`,
      {
        params: {
          from,
        },
      }
    )
  }

  public vetos(id: string | null): Observable<VetoModel[]> {
    return this.http.get<VetoModel[]>(
      `${environment.api}/caregivers/${id}/vetos`
    )
  }

  public documents(
    id: string | null,
    onlyArchived: boolean,
    listView: boolean
  ): Observable<CaregiverDetailDocumentsModel> {
    return this.http.get<CaregiverDetailDocumentsModel>(
      `${environment.api}/caregivers/${id}/documents?only_archived=${onlyArchived}&list_view=${listView}`
    )
  }

  public storeJobScope(id: string | null, values: any): Observable<[]> {
    return this.http.post<[]>(
      `${environment.api}/caregivers/${id}/job-scope`,
      values
    )
  }

  public storeJobDays(id: string | null, values: any): Observable<[]> {
    return this.http.post<[]>(
      `${environment.api}/caregivers/${id}/job-days`,
      values
    )
  }

  public storeProofs(id: string | null, values: any): Observable<[]> {
    return this.http.post<[]>(
      `${environment.api}/caregivers/${id}/proofs`,
      values
    )
  }

  public storeEmployment(id: string | null, values: any): Observable<[]> {
    return this.http.post<[]>(
      `${environment.api}/caregivers/${id}/employment`,
      values
    )
  }

  public updateEmployment(id: string | null, values: any): Observable<[]> {
    return this.http.put<[]>(
      `${environment.api}/caregivers/employment/${id}`,
      values
    )
  }

  public deleteEmployment(id: string | null): Observable<[]> {
    return this.http.delete<[]>(
      `${environment.api}/caregivers/employment/${id}`
    )
  }

  public removePaidRecommendation(id: string | null): Observable<[]> {
    return this.http.delete<[]>(
      `${environment.api}/caregivers/paid-recommendation/${id}`
    )
  }

  public details(id: string | null): Observable<CaregiverDetailModel> {
    return this.http.get<CaregiverDetailModel>(
      `${environment.api}/caregivers/${id}/details`
    )
  }

  public loadForEditMode(id: string | null): Observable<CaregiverFormModel> {
    return this.http.get<CaregiverFormModel>(
      `${environment.api}/caregivers/${id}/edit`
    )
  }

  public loadForSimpleMode(
    id: string | null
  ): Observable<CaregiverDetailModel> {
    return this.http.get<CaregiverDetailModel>(
      `${environment.api}/caregivers/${id}/simple-mode`
    )
  }

  public update(data: CaregiverFormModel): Observable<CaregiverFormModel> {
    return this.http.put<CaregiverFormModel>(
      `${environment.api}/caregivers/${data.id}`,
      data
    )
  }

  public store(data: CaregiverFormModel): Observable<CaregiverFormModel> {
    return this.http.post<CaregiverFormModel>(
      `${environment.api}/caregivers`,
      data
    )
  }

  public addPaidRecommendation(
    caregiverId: any,
    patientId: any
  ): Observable<CaregiverFormModel> {
    return this.http.post<CaregiverFormModel>(
      `${environment.api}/caregivers/${caregiverId}/paid-recommendation`,
      {
        patientId,
      }
    )
  }

  public deactivate(id: string | null): Observable<CaregiverDetailModel> {
    return this.http.put<CaregiverDetailModel>(
      `${environment.api}/caregivers/${id}/deactivate`,
      {}
    )
  }

  public delete(id: string | null): Observable<[]> {
    return this.http.put<[]>(`${environment.api}/caregivers/${id}/delete`, {})
  }

  public activate(id: string | null): Observable<CaregiverDetailModel> {
    return this.http.put<CaregiverDetailModel>(
      `${environment.api}/caregivers/${id}/activate`,
      {}
    )
  }

  public getDocumentDownloadLink(uuid: string): string {
    return `${environment.api}/documents/${uuid}`
  }

  public getPersplanMonth(
    caregiverId: string,
    budgetMonth: string,
    budgetYear: string,
    withFunction: boolean
  ): Observable<string> {
    return this.http.get(
      `${environment.api}/caregivers/${caregiverId}/persplan-month`,
      {
        responseType: 'text',
        params: {
          budgetMonth,
          budgetYear,
          withFunction: withFunction.toString(),
        },
      }
    )
  }
}
