import { Injectable } from '@angular/core'
import { HttpClient, HttpParams } from '@angular/common/http'
import { environment } from '../../environments/environment'
import { Observable } from 'rxjs'

@Injectable({
  providedIn: 'root',
})
export class SendLetterService {
  constructor(private http: HttpClient) {}

  public send(values: any): Observable<[]> {
    return this.http.post<[]>(
      `${environment.api}/send-letter/${values.patient.id}`,
      values
    )
  }

  public getHtmlTables(): Observable<[]> {
    return this.http.get<[]>(`${environment.api}/send-letter/html-tables`)
  }

  public getLetterMailPreviewLink(values: any): string {
    const params = new HttpParams({ fromObject: values }).toString()

    return `${environment.api}/send-letter/preview/mail/${values.patient.id}?${params}`
  }

  public getMailLink(id: string): string {
    return `${environment.api}/send-letter/letter-mail/${id}`
  }
}
