<app-loader [loading]="loading"></app-loader>

<div class="content" *ngIf="!loading">
  <header class="content-header without-border">
    <div class="content-header-patients-tabs">
      <span
        (click)="changePatient('first_patient')"
        [class.active]="selectedPatient === 'first_patient'"
      >
        <i class="pi pi-user"></i>
        {{ customer.first_patient.full_name }}
        <i
          *ngIf="customer.first_patient.status"
          class="p-tag p-tag-rounded p-tag-{{ customer.first_patient.status }}"
          >{{ customer.first_patient.status_string }}</i
        >
        <i
          *ngIf="customer.first_patient.is_paused"
          class="p-tag p-tag-rounded p-tag-PAUSED"
          >Pausiert</i
        >
      </span>
      <span
        (click)="changePatient('second_patient')"
        [class.active]="selectedPatient === 'second_patient'"
        *ngIf="customer.second_patient"
      >
        <i class="pi pi-users"></i>
        {{ customer.second_patient.full_name }}

        <i
          *ngIf="customer.second_patient.status"
          class="p-tag p-tag-rounded p-tag-{{ customer.second_patient.status }}"
          >{{ customer.second_patient.status_string }}</i
        >
        <i
          *ngIf="customer.second_patient.is_paused"
          class="p-tag p-tag-rounded p-tag-PAUSED"
          >Pausiert</i
        >
      </span>
    </div>
  </header>
  <div
    class="content-body without-padding p-flex-row"
    *ngIf="customer[selectedPatient]"
  >
    <div class="detail-information-column border-right">
      <div class="detail-information-container">
        <div class="detail-information-header">Stammdaten</div>
        <div class="detail-information-body">
          <div
            class="detail-information-banner background-orange"
            *ngIf="customer[selectedPatient].pause_in_current_month_active"
          >
            <span
              >Pause von
              {{
                customer[selectedPatient].pause_in_current_month_active
                  ?.pause_from_string
              }}
              bis
              {{
                customer[selectedPatient].pause_in_current_month_active
                  ?.pause_to_string
              }}
            </span>
          </div>

          <p-overlayPanel
            #op
            styleClass="with-border"
            [showCloseIcon]="true"
            [style]="{
              maxWidth: '800px',
              width: 'max-content',
              maxHeight: '420px',
              overflowY: 'auto'
            }"
          >
            <ng-template pTemplate>
              <div [innerHTML]="currentBudgetTable"></div>
            </ng-template>
          </p-overlayPanel>

<!--          <div class="detail-information">-->
<!--            <div class="detail-key">-->
<!--              <span>Patienten ID / Kunden ID</span>-->
<!--            </div>-->
<!--            <div class="detail-value">-->
<!--              <strong-->
<!--                >{{ customer[selectedPatient].id }} /-->
<!--                {{ customer.customer.id }}</strong-->
<!--              >-->
<!--            </div>-->
<!--          </div>-->

          <!-- Sobald der Patient mindestens Neukunde ist, werden die Stunden aus Persplan genommen (SOLL oder IST) -->
          <div
            class="detail-information"
            style="align-items: center"
            *ngIf="customer[this.selectedPatient].status"
          >
            <div class="detail-key">
              <span>Stunden</span>
            </div>
            <div class="detail-value">
              <i
                (click)="
                  openBudgetOverlay(
                    $event,
                    persplanData['last' + customer[selectedPatient].id]
                  )
                "
                class="p-tag p-tag-rounded p-tag-outline p-tag-clickable"
                pTooltip="Stunden ansehen"
                (mouseenter)="
                  getPersplanBudget(customer[selectedPatient].id, 'last')
                "
                style="margin: 0 10px 0 0"
                >Letzter:
                {{
                  customer[selectedPatient].persplan_hours_real_for_last_month
                }}</i
              >
              <i
                (click)="
                  openBudgetOverlay(
                    $event,
                    persplanData['current' + customer[selectedPatient].id]
                  )
                "
                class="p-tag p-tag-rounded p-tag-outline p-tag-clickable"
                style="margin: 0 10px 0 0"
                pTooltip="Stunden ansehen"
                (mouseenter)="
                  getPersplanBudget(customer[selectedPatient].id, 'current')
                "
                >Aktueller:
                {{
                  customer[selectedPatient]
                    .persplan_hours_real_for_current_month
                }}</i
              >
              <i
                (click)="
                  openBudgetOverlay(
                    $event,
                    persplanData['next' + customer[selectedPatient].id]
                  )
                "
                class="p-tag p-tag-rounded p-tag-outline p-tag-clickable"
                pTooltip="Stunden ansehen"
                (mouseenter)="
                  getPersplanBudget(customer[selectedPatient].id, 'next')
                "
                >Nächster:
                {{
                  customer[selectedPatient].persplan_hours_real_for_next_month
                }}</i
              >
            </div>
          </div>

          <div class="detail-information">
            <div class="detail-key">
              <span>Leistungsbeginn</span>
            </div>
            <div class="detail-value">
              <strong
                >{{ customer.customer.start_of_operation || "-" }}
                {{
                  customer.customer.start_of_operation_date_string
                    ? "(" +
                      customer.customer.start_of_operation_date_string +
                      ")"
                    : ""
                }}</strong
              >
            </div>
          </div>

<!--          <div class="detail-information">-->
<!--            <div class="detail-key">-->
<!--              <span>Leistungsumfang</span>-->
<!--            </div>-->
<!--            <div class="detail-value">-->
<!--              <strong>{{ customer.customer.service_scope || "-" }}</strong>-->
<!--            </div>-->
<!--          </div>-->

<!--          <div class="detail-information background-hightlight-main1">-->
<!--            <div class="detail-key">-->
<!--              <span>Leistungstage</span>-->
<!--            </div>-->
<!--            <div class="detail-value p-flex-column">-->
<!--              <strong *ngIf="customer[selectedPatient].appointment_interval">-->
<!--                Intervall: {{ customer[selectedPatient].appointment_interval-->
<!--                }}<br />-->
<!--              </strong>-->
<!--              <strong>{{-->
<!--                customer[selectedPatient].times?.days_as_string || "-"-->
<!--              }}</strong>-->
<!--            </div>-->
<!--          </div>-->

          <div class="detail-information">
            <div class="detail-key">
              <span>Anrede</span>
            </div>
            <div class="detail-value">
              <strong>{{
                customer[selectedPatient].form_of_address || "-"
              }}</strong>
            </div>
          </div>

          <div class="detail-information">
            <div class="detail-key">
              <span>Vorname</span>
            </div>
            <div class="detail-value">
              <strong>{{ customer[selectedPatient].first_name }}</strong>
            </div>
          </div>

          <div class="detail-information">
            <div class="detail-key">
              <span>Nachname</span>
            </div>
            <div class="detail-value">
              <strong>{{ customer[selectedPatient].last_name }}</strong>
            </div>
          </div>

<!--          <div class="detail-information">-->
<!--            <div class="detail-key">-->
<!--              <span>Geburtstag</span>-->
<!--            </div>-->
<!--            <div class="detail-value">-->
<!--              <strong>{{-->
<!--                customer[selectedPatient].birthday_date_string || "-"-->
<!--              }}</strong>-->
<!--            </div>-->
<!--          </div>-->

          <div class="detail-information">
            <div class="detail-key">
              <span>Adresse</span>
            </div>
            <div class="detail-value detail-value-with-actions">
              <strong
                >{{ customer[selectedPatient].street_house_number || "-"
                }}<br />{{ customer[selectedPatient].zipcode }}
                {{ customer[selectedPatient].city }}
                {{ customer[selectedPatient].city_part }}</strong
              >
            </div>
          </div>

          <div class="detail-information">
            <div class="detail-key">
              <span>Landkreis / Bundesland</span>
            </div>
            <div class="detail-value">
              <strong
                >{{ customer[selectedPatient].county || "-" }} /
                {{ customer[selectedPatient].state || "-" }}</strong
              >
            </div>
          </div>

<!--          <div class="detail-information">-->
<!--            <div class="detail-key">-->
<!--              <span>Gewicht / Größe</span>-->
<!--            </div>-->
<!--            <div class="detail-value">-->
<!--              <strong-->
<!--                >{{-->
<!--                  customer[selectedPatient].weight-->
<!--                    ? customer[selectedPatient].weight + " kg"-->
<!--                    : "-"-->
<!--                }}-->
<!--                /-->
<!--                {{-->
<!--                  customer[selectedPatient].size-->
<!--                    ? customer[selectedPatient].size + " cm"-->
<!--                    : "-"-->
<!--                }}</strong-->
<!--              >-->
<!--            </div>-->
<!--          </div>-->

          <div class="detail-information">
            <div class="detail-key">
              <span>Zusammenfassung</span>
            </div>
            <div class="detail-value">
              <strong>{{
                customer.customer.summary_from_recommendation || "-"
              }}</strong>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="detail-information-column">
      <div class="detail-information-container">
        <div class="detail-information-header">Pflegedaten</div>
        <div class="detail-information-body">
          <div class="detail-information">
            <div class="detail-key">
              <span>Leistung als</span>
            </div>
            <div class="detail-value">
              <strong>{{
                customer[selectedPatient].service_for_formatted || "-"
              }}</strong>
            </div>
          </div>

<!--          <div class="detail-information">-->
<!--            <div class="detail-key">-->
<!--              <span>Abrechnungsstatus</span>-->
<!--            </div>-->
<!--            <div class="detail-value p-flex-column">-->
<!--              <strong-->
<!--                >{{ customer[selectedPatient].insured_via_string || "-" }}-->
<!--                {{-->
<!--                  customer[selectedPatient].insured_via_other_text-->
<!--                    ? "(" +-->
<!--                      customer[selectedPatient].insured_via_other_text +-->
<!--                      ")"-->
<!--                    : ""-->
<!--                }}</strong-->
<!--              >-->
<!--              <strong-->
<!--                *ngIf="-->
<!--                  customer[selectedPatient].insured_via.includes(-->
<!--                    'Selbstzahler'-->
<!--                  ) && customer[selectedPatient].selbstzahler_budget_type-->
<!--                "-->
<!--              >-->
<!--                Selbstzahler-->
<!--                {{ customer[selectedPatient].selbstzahler_budget_type }}-->
<!--                {{-->
<!--                  customer[selectedPatient].selbstzahler_budget_type === "Max"-->
<!--                    ? customer[selectedPatient].selbstzahler_budget_max + " Std"-->
<!--                    : ""-->
<!--                }}</strong-->
<!--              >-->
<!--            </div>-->
<!--          </div>-->

<!--          <div class="detail-information">-->
<!--            <div class="detail-key">-->
<!--              <span>VS-Nummer</span>-->
<!--            </div>-->
<!--            <div class="detail-value">-->
<!--              <strong>{{-->
<!--                customer[selectedPatient].insurer_number || "-"-->
<!--              }}</strong>-->
<!--            </div>-->
<!--          </div>-->

<!--          <div class="detail-information">-->
<!--            <div class="detail-key">-->
<!--              <span>IK-Nummer</span>-->
<!--            </div>-->
<!--            <div class="detail-value">-->
<!--              <strong>{{-->
<!--                customer[selectedPatient].care_insurance_pflege_ik || "-"-->
<!--              }}</strong>-->
<!--            </div>-->
<!--          </div>-->

<!--          <div class="detail-information">-->
<!--            <div class="detail-key">-->
<!--              <span>Pflegekasse</span>-->
<!--            </div>-->
<!--            <div class="detail-value">-->
<!--              <strong>{{-->
<!--                customer[selectedPatient].care_insurance_name || "-"-->
<!--              }}</strong>-->
<!--            </div>-->
<!--          </div>-->

<!--          <div class="detail-information">-->
<!--            <div class="detail-key">-->
<!--              <span>Pflegeperson</span>-->
<!--            </div>-->
<!--            <div class="detail-value">-->
<!--              <strong-->
<!--                >{{ customer[selectedPatient].nursing_person }}-->
<!--                {{ customer[selectedPatient].nursing_person_name }}</strong-->
<!--              >-->
<!--            </div>-->
<!--          </div>-->

<!--          <div class="detail-information">-->
<!--            <div class="detail-key">-->
<!--              <span>Pflegegrad</span>-->
<!--            </div>-->
<!--            <div class="detail-value">-->
<!--              <strong-->
<!--                >{{ customer[selectedPatient].degree_of_care || "-" }}-->
<!--                {{-->
<!--                  customer[selectedPatient].degree_of_care_since_string-->
<!--                    ? "(" +-->
<!--                      customer[selectedPatient].degree_of_care_since_string +-->
<!--                      ")"-->
<!--                    : ""-->
<!--                }}</strong-->
<!--              >-->
<!--            </div>-->
<!--          </div>-->

<!--          <div class="detail-information">-->
<!--            <div class="detail-key">-->
<!--              <span>Alter Pflegegrad</span>-->
<!--            </div>-->
<!--            <div class="detail-value">-->
<!--              <strong-->
<!--                >{{ customer[selectedPatient].degree_of_care_old || "-" }}-->
<!--                {{-->
<!--                  customer[selectedPatient].degree_of_care_old_since_string-->
<!--                    ? "(" +-->
<!--                      customer[selectedPatient]-->
<!--                        .degree_of_care_old_since_string +-->
<!--                      ")"-->
<!--                    : ""-->
<!--                }}</strong-->
<!--              >-->
<!--            </div>-->
<!--          </div>-->

<!--          <div class="detail-information">-->
<!--            <div class="detail-key">-->
<!--              <span>Führerschein notwendig?</span>-->
<!--            </div>-->
<!--            <div class="detail-value">-->
<!--              <strong-->
<!--                >{{ customer[selectedPatient].driving_license_needed || "-" }}-->
<!--              </strong>-->
<!--            </div>-->
<!--          </div>-->

<!--          <div class="detail-information">-->
<!--            <div class="detail-key">-->
<!--              <span>Raucher erlaubt?</span>-->
<!--            </div>-->
<!--            <div class="detail-value">-->
<!--              <strong-->
<!--                >{{ customer[selectedPatient].smoker_allowed || "-" }}-->
<!--              </strong>-->
<!--            </div>-->
<!--          </div>-->

<!--          <div class="detail-information">-->
<!--            <div class="detail-key">-->
<!--              <span>Soll geimpft sein?</span>-->
<!--            </div>-->
<!--            <div class="detail-value">-->
<!--              <strong-->
<!--                >{{ customer[selectedPatient].should_be_vaccinated || "-" }}-->
<!--              </strong>-->
<!--            </div>-->
<!--          </div>-->

<!--          <div class="detail-information">-->
<!--            <div class="detail-key">-->
<!--              <span>Leistungsart</span>-->
<!--            </div>-->
<!--            <div class="detail-value">-->
<!--              <strong-->
<!--                >{{ customer[selectedPatient].services_string || "-" }}-->
<!--                {{-->
<!--                  customer[selectedPatient].services_text-->
<!--                    ? "(" + customer[selectedPatient].services_text + ")"-->
<!--                    : ""-->
<!--                }}</strong-->
<!--              >-->
<!--            </div>-->
<!--          </div>-->

<!--          <div class="detail-information">-->
<!--            <div class="detail-key">-->
<!--              <span>Ansteckende Krankheiten</span>-->
<!--            </div>-->
<!--            <div class="detail-value">-->
<!--              <strong-->
<!--                >{{ customer[selectedPatient].infectious_diseases }}-->
<!--                <span *ngIf="customer[selectedPatient].infectious_diseases_info"-->
<!--                  >({{-->
<!--                    customer[selectedPatient].infectious_diseases_info-->
<!--                  }})</span-->
<!--                ></strong-->
<!--              >-->
<!--            </div>-->
<!--          </div>-->

<!--          <div class="detail-information">-->
<!--            <div class="detail-key">-->
<!--              <span>Blutverdünner</span>-->
<!--            </div>-->
<!--            <div class="detail-value">-->
<!--              <strong-->
<!--                >{{ customer[selectedPatient].blood_thinner }}-->
<!--                <span *ngIf="customer[selectedPatient].blood_thinner_info"-->
<!--                  >({{ customer[selectedPatient].blood_thinner_info }})</span-->
<!--                ></strong-->
<!--              >-->
<!--            </div>-->
<!--          </div>-->

<!--          <div class="detail-information">-->
<!--            <div class="detail-key">-->
<!--              <span>Krankheitsmerkmale</span>-->
<!--            </div>-->
<!--            <div class="detail-value">-->
<!--              <strong-->
<!--                >{{ customer[selectedPatient].diseases_string || "-" }}-->
<!--                {{-->
<!--                  customer[selectedPatient].diseases_text-->
<!--                    ? "(" + customer[selectedPatient].diseases_text + ")"-->
<!--                    : ""-->
<!--                }}-->
<!--                {{-->
<!--                  customer[selectedPatient].stroke_left-->
<!--                    ? "Schlaganfall links"-->
<!--                    : ""-->
<!--                }}-->
<!--                {{-->
<!--                  customer[selectedPatient].stroke_right-->
<!--                    ? "Schlaganfall rechts"-->
<!--                    : ""-->
<!--                }}-->
<!--              </strong>-->
<!--            </div>-->
<!--          </div>-->

<!--          <div class="detail-information">-->
<!--            <div class="detail-key">-->
<!--              <span>Hilfsmittel / Mobilität</span>-->
<!--            </div>-->
<!--            <div class="detail-value">-->
<!--              <strong-->
<!--                >{{ customer[selectedPatient].tools_string || "-" }}-->
<!--                {{-->
<!--                  customer[selectedPatient].tools_text-->
<!--                    ? "(" + customer[selectedPatient].tools_text + ")"-->
<!--                    : ""-->
<!--                }}</strong-->
<!--              >-->
<!--            </div>-->
<!--          </div>-->

<!--          <div class="detail-information">-->
<!--            <div class="detail-key">-->
<!--              <span>Transfer notwendig?</span>-->
<!--            </div>-->
<!--            <div class="detail-value">-->
<!--              <strong-->
<!--                >{{ customer[selectedPatient].transfer_needed_general || "-" }}-->
<!--                {{-->
<!--                  customer[selectedPatient].transfer_needed_general_info-->
<!--                    ? "(" +-->
<!--                      customer[selectedPatient].transfer_needed_general_info +-->
<!--                      ")"-->
<!--                    : ""-->
<!--                }}</strong-->
<!--              >-->
<!--            </div>-->
<!--          </div>-->

<!--          <div class="detail-information">-->
<!--            <div class="detail-key">-->
<!--              <span>Inkontinenz</span>-->
<!--            </div>-->
<!--            <div class="detail-value">-->
<!--              <strong *ngIf="customer[selectedPatient].no_incontinence"-->
<!--                >Keine Inkontinenz</strong-->
<!--              >-->
<!--              <strong *ngIf="!customer[selectedPatient].no_incontinence">-->
<!--                Urin: {{ customer[selectedPatient].incontinence_urine || "-" }}-->
<!--                <br />-->
<!--                Stuhl: {{ customer[selectedPatient].incontinence_stool || "-" }}-->
<!--                <br />-->
<!--                Erläuterung:-->
<!--                {{ customer[selectedPatient].incontinence_text || "-" }}</strong-->
<!--              >-->
<!--            </div>-->
<!--          </div>-->

<!--          <div class="detail-information">-->
<!--            <div class="detail-key">-->
<!--              <span>Kommunikationsfähigkeit</span>-->
<!--            </div>-->
<!--            <div class="detail-value">-->
<!--              <strong-->
<!--                *ngIf="-->
<!--                  customer[selectedPatient]-->
<!--                    .communication_complete_maintained === 'Ja'-->
<!--                "-->
<!--                >Vollständig erhalten</strong-->
<!--              >-->
<!--              <strong-->
<!--                *ngIf="-->
<!--                  customer[selectedPatient]-->
<!--                    .communication_complete_maintained !== 'Ja'-->
<!--                "-->
<!--                >Eingeschränkt:-->
<!--                {{ customer[selectedPatient].communication_types_string }}-->
<!--                <br />-->
<!--                Erläuterung: {{ customer[selectedPatient].communication_text }}-->
<!--              </strong>-->
<!--            </div>-->
<!--          </div>-->
        </div>
      </div>
      <div class="detail-information-container">
        <div class="detail-information-header">Kontakt</div>
        <div class="detail-information-body">
          <div class="detail-information">
            <div class="detail-key">
              <span>Telefon 1</span>
            </div>
            <div class="detail-value">
              <strong *ngIf="!customer[selectedPatient].phone_1">-</strong>
              <strong class="p-d-flex p-align-center">
                <a
                  *ngIf="customer[selectedPatient].phone_1"
                  href="tel:{{ customer[selectedPatient].phone_1 }}"
                >
                  {{ customer[selectedPatient].phone_1 }}
                </a>

                <i
                  style="margin: 0 0 0 10px"
                  class="pi pi-info-circle color-gray"
                  *ngIf="customer[selectedPatient].phone_1_info"
                  [pTooltip]="customer[selectedPatient].phone_1_info"
                ></i>
              </strong>
            </div>
          </div>

          <div class="detail-information">
            <div class="detail-key">
              <span>Telefon 2</span>
            </div>
            <div class="detail-value">
              <strong *ngIf="!customer[selectedPatient].phone_2">-</strong>
              <strong class="p-d-flex p-align-center">
                <a
                  *ngIf="customer[selectedPatient].phone_2"
                  href="tel:{{ customer[selectedPatient].phone_2 }}"
                >
                  {{ customer[selectedPatient].phone_2 }}
                </a>

                <i
                  style="margin: 0 0 0 10px"
                  class="pi pi-info-circle color-gray"
                  *ngIf="customer[selectedPatient].phone_2_info"
                  [pTooltip]="customer[selectedPatient].phone_2_info"
                ></i>
              </strong>
            </div>
          </div>

          <div class="detail-information">
            <div class="detail-key">
              <span>E-Mail</span>
            </div>
            <div class="detail-value">
              <strong *ngIf="!customer[selectedPatient].email">-</strong>
              <strong class="p-d-flex p-align-center">
                <a
                  *ngIf="customer[selectedPatient].email"
                  href="mailto:{{ customer[selectedPatient].email }}"
                >
                  {{ customer[selectedPatient].email }}
                </a>

                <i
                  style="margin: 0 0 0 10px"
                  class="pi pi-info-circle color-gray"
                  *ngIf="customer[selectedPatient].email_info"
                  [pTooltip]="customer[selectedPatient].email_info"
                ></i>
              </strong>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!--<div-->
<!--  class="content"-->
<!--  *ngIf="!loading && customer.customer.offer_type === 'OFFER'"-->
<!--&gt;-->
<!--  <header class="content-header">-->
<!--    <span>-->
<!--      <i class="pi pi-home"></i>-->
<!--      Haushalt-->
<!--    </span>-->
<!--  </header>-->
<!--  <div class="content-body without-padding">-->
<!--    <div class="detail-information-body inline-information-body" style="padding-top: 0">-->
<!--      <div class="detail-information">-->
<!--        <div class="detail-key">-->
<!--          <span>Details</span>-->
<!--        </div>-->
<!--        <div class="detail-value">-->
<!--          <strong-->
<!--            >{{ customer.customer.household.type }},-->
<!--            {{-->
<!--              customer.customer.household.living_space-->
<!--                ? customer.customer.household.living_space + " qm"-->
<!--                : ""-->
<!--            }}, {{ customer.customer.household.living_floor_number }}-->
<!--            {{ customer.customer.household.living_floor }}</strong-->
<!--          >-->
<!--        </div>-->
<!--      </div>-->

<!--      <div class="detail-information">-->
<!--        <div class="detail-key">-->
<!--          <span>Haustiere im Haushalt</span>-->
<!--        </div>-->
<!--        <div class="detail-value">-->
<!--          <strong-->
<!--            >{{ customer.customer.household.pets || "-" }}-->
<!--            {{ customer.customer.household.pets_info_string ? " - " : "" }}-->
<!--            {{ customer.customer.household.pets_info_string }}</strong-->
<!--          >-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->

<!--    <div class="detail-information-body inline-information-body">-->
<!--      <div class="detail-information">-->
<!--        <div class="detail-key">-->
<!--          <span>Transfer zwischen Stockwerken</span>-->
<!--        </div>-->
<!--        <div class="detail-value">-->
<!--          <strong>{{-->
<!--            customer.customer.household.transfer_needed || "-"-->
<!--          }}</strong>-->
<!--        </div>-->
<!--      </div>-->

<!--      <div class="detail-information">-->
<!--        <div class="detail-key">-->
<!--          <span>Rauchen im Haushalt</span>-->
<!--        </div>-->
<!--        <div class="detail-value">-->
<!--          <strong>{{ customer.customer.household.smoking || "-" }}</strong>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="detail-information">-->
<!--        <div class="detail-key">-->
<!--          <span>Zustand Haushalt</span>-->
<!--        </div>-->
<!--        <div class="detail-value">-->
<!--          <strong>{{ customer.customer.household.condition || "-" }}</strong>-->
<!--        </div>-->
<!--      </div>-->

<!--      <div class="detail-information">-->
<!--        <div class="detail-key">-->
<!--          <span>Schlüsselkasten</span>-->
<!--        </div>-->
<!--        <div class="detail-value">-->
<!--          <strong>{{ customer.customer.household.key_case || "-" }}</strong>-->
<!--        </div>-->
<!--      </div>-->

<!--      <div class="detail-information">-->
<!--        <div class="detail-key">-->
<!--          <span>Reinigungsmittel</span>-->
<!--        </div>-->
<!--        <div class="detail-value">-->
<!--          <strong>{{-->
<!--            customer.customer.household.cleaning_stuff || "-"-->
<!--          }}</strong>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->

<!--    <div class="detail-information-body inline-information-body" style="padding-bottom: 0">-->
<!--      <div class="detail-information">-->
<!--        <div class="detail-key">-->
<!--          <span>Besonderheiten</span>-->
<!--        </div>-->
<!--        <div class="detail-value">-->
<!--          <strong>{{ customer.customer.household.specials || "-" }}</strong>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
<!--</div>-->

<!--<div-->
<!--  class="content"-->
<!--  *ngIf="!loading && customer.customer.offer_type === 'OFFER'"-->
<!--&gt;-->
<!--  <header class="content-header">-->
<!--    <span>-->
<!--      <i class="pi pi-phone"></i>-->
<!--      Ansprechpartner-->
<!--      <span-->
<!--        style="margin: 0 0 0 20px"-->
<!--        class="p-tag p-tag-rounded p-tag-outline"-->
<!--        *ngIf="-->
<!--          customer.first_patient.is_contact_person ||-->
<!--          customer.second_patient?.is_contact_person-->
<!--        "-->
<!--        >Patient ist AP</span-->
<!--      >-->
<!--    </span>-->
<!--  </header>-->
<!--  <div-->
<!--    class="content-body"-->
<!--    *ngIf="customer.customer.contact_persons.length === 0"-->
<!--  >-->
<!--    <span class="content-no-message"> Kein Ansprechpartner vorhanden </span>-->
<!--  </div>-->

<!--  <div-->
<!--    class="content-body without-padding p-flex-row p-flex-wrap"-->
<!--    *ngIf="customer.customer.contact_persons.length > 0"-->
<!--  >-->
<!--    <div-->
<!--      *ngFor="-->
<!--        let contactPerson of customer.customer.contact_persons;-->
<!--        let i = index-->
<!--      "-->
<!--      class="detail-information-column contact-person-column border-right"-->
<!--    >-->
<!--      <div class="detail-information-container">-->
<!--        <div class="detail-information-header for-contact-person">-->
<!--          Ansprechpartner {{ i + 1 }}-->
<!--        </div>-->
<!--        <div class="detail-information-body">-->
<!--          <div class="detail-information">-->
<!--            <div class="detail-key">-->
<!--              <span>Infos</span>-->
<!--            </div>-->
<!--            <div class="detail-value">-->
<!--              <strong>{{ contactPerson.informations_string || "-" }}</strong>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="detail-information">-->
<!--            <div class="detail-key">-->
<!--              <span>Name</span>-->
<!--            </div>-->
<!--            <div class="detail-value">-->
<!--              <strong-->
<!--                >{{ contactPerson.form_of_address }}-->
<!--                {{ contactPerson.first_name }}-->
<!--                {{ contactPerson.last_name }}</strong-->
<!--              >-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="detail-information">-->
<!--            <div class="detail-key">-->
<!--              <span>Beziehung</span>-->
<!--            </div>-->
<!--            <div class="detail-value">-->
<!--              <strong>{{ contactPerson.relation }}</strong>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="detail-information">-->
<!--            <div class="detail-key">-->
<!--              <span>Telefon 1</span>-->
<!--            </div>-->
<!--            <div class="detail-value">-->
<!--              <strong class="p-d-flex p-align-center">-->
<!--                <a href="tel:{{ contactPerson.phone_1 }}">-->
<!--                  {{ contactPerson.phone_1 }}-->
<!--                </a>-->

<!--                <i-->
<!--                  style="margin: 0 0 0 10px"-->
<!--                  class="pi pi-info-circle color-gray"-->
<!--                  *ngIf="contactPerson.phone_1_info"-->
<!--                  [pTooltip]="contactPerson.phone_1_info"-->
<!--                ></i>-->
<!--              </strong>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="detail-information" *ngIf="openedContactPersons[i]">-->
<!--            <div class="detail-key">-->
<!--              <span>Telefon 2</span>-->
<!--            </div>-->
<!--            <div class="detail-value">-->
<!--              <strong class="p-d-flex p-align-center">-->
<!--                <a-->
<!--                  (click)="-->
<!--                    phoneCallService.createCalling(contactPerson.phone_2)-->
<!--                  "-->
<!--                  href="tel:{{ contactPerson.phone_2 }}"-->
<!--                >-->
<!--                  {{ contactPerson.phone_2 }}-->
<!--                </a>-->

<!--                <i-->
<!--                  style="margin: 0 0 0 10px"-->
<!--                  class="pi pi-info-circle color-gray"-->
<!--                  *ngIf="contactPerson.phone_2_info"-->
<!--                  [pTooltip]="contactPerson.phone_2_info"-->
<!--                ></i>-->
<!--              </strong>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="detail-information" *ngIf="openedContactPersons[i]">-->
<!--            <div class="detail-key">-->
<!--              <span>Geburtstag</span>-->
<!--            </div>-->
<!--            <div class="detail-value">-->
<!--              <strong>{{ contactPerson.birthday_string }}</strong>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="detail-information">-->
<!--            <div class="detail-key">-->
<!--              <span>E-Mail Privat</span>-->
<!--            </div>-->
<!--            <div class="detail-value">-->
<!--              <strong class="p-d-flex p-align-center">-->
<!--                <a href="mailto:{{ contactPerson.email_private }}">-->
<!--                  {{ contactPerson.email_private }}-->
<!--                </a>-->

<!--                <i-->
<!--                  style="margin: 0 0 0 10px"-->
<!--                  class="pi pi-info-circle color-gray"-->
<!--                  *ngIf="contactPerson.email_private_info"-->
<!--                  [pTooltip]="contactPerson.email_private_info"-->
<!--                ></i>-->
<!--              </strong>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="detail-information" *ngIf="openedContactPersons[i]">-->
<!--            <div class="detail-key">-->
<!--              <span>E-Mail Geschäftlich</span>-->
<!--            </div>-->
<!--            <div class="detail-value">-->
<!--              <strong class="p-d-flex p-align-center">-->
<!--                <a href="mailto:{{ contactPerson.email_work }}">-->
<!--                  {{ contactPerson.email_work }}-->
<!--                </a>-->

<!--                <i-->
<!--                  style="margin: 0 0 0 10px"-->
<!--                  class="pi pi-info-circle color-gray"-->
<!--                  *ngIf="contactPerson.email_work_info"-->
<!--                  [pTooltip]="contactPerson.email_work_info"-->
<!--                ></i>-->
<!--              </strong>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="detail-information" *ngIf="openedContactPersons[i]">-->
<!--            <div class="detail-key">-->
<!--              <span>PLZ / Ort</span>-->
<!--            </div>-->
<!--            <div class="detail-value">-->
<!--              <strong-->
<!--                >{{ contactPerson.zipcode }} {{ contactPerson.city }}-->
<!--                {{ contactPerson.city_part }}</strong-->
<!--              >-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="detail-information" *ngIf="openedContactPersons[i]">-->
<!--            <div class="detail-key">-->
<!--              <span>Adresse</span>-->
<!--            </div>-->
<!--            <div class="detail-value">-->
<!--              <strong>{{ contactPerson.street_house_number || "-" }}</strong>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="detail-information" *ngIf="openedContactPersons[i]">-->
<!--            <div class="detail-key">-->
<!--              <span>Örtliche Besonderheiten</span>-->
<!--            </div>-->
<!--            <div class="detail-value">-->
<!--              <strong>{{ contactPerson.local_specialties || "-" }}</strong>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="detail-information" *ngIf="openedContactPersons[i]">-->
<!--            <div class="detail-key">-->
<!--              <span>Kommentar</span>-->
<!--            </div>-->
<!--            <div class="detail-value">-->
<!--              <strong>{{ contactPerson.comment || "-" }}</strong>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->

<!--        <span-->
<!--          class="show-more-btn"-->
<!--          (click)="openedContactPersons[i] = !openedContactPersons[i]"-->
<!--        >-->
<!--          {{ openedContactPersons[i] ? "Weniger anzeigen" : "Mehr anzeigen" }}-->
<!--        </span>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
<!--</div>-->
