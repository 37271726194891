import { Component, ElementRef, OnInit, ViewChild } from '@angular/core'
import { TitleService } from 'src/app/services/title.service'
import { PatientService } from '../../../services/patient.service'
import { FilterService } from 'primeng/api'

@Component({
  selector: 'app-patients-paused',
  templateUrl: './patients-paused.component.html',
})
export class PatientsPausedComponent implements OnInit {
  @ViewChild('dt') table: ElementRef | any = null

  public globalFilterValue = ''
  public firstPage = 0

  public loading = false

  // TODO: model erstellen
  public patients: any[] = []

  constructor(
    private filterService: FilterService,
    private patientService: PatientService,
    private titleService: TitleService
  ) {}

  ngOnInit(): void {
    this.loadUsers()
    this.titleService.setTitle('Pausierte Kunden')
  }

  public filterTableGlobal(event: any): void {
    this.table && this.table.filterGlobal(event.target.value.trim(), 'contains')
  }

  private loadUsers(): void {
    this.loading = true

    this.patientService.paused().subscribe((data: any[]) => {
      this.patients = data

      this.loading = false

      setTimeout(() => {
        if (this.table) {
          const totalItems = this.table._totalRecords

          this.globalFilterValue = this.table.filters.global?.value ?? ''

          if (this.table._first > totalItems || this.table._rows > totalItems) {
            this.firstPage = 0
          }
        }
      })
    })
  }
}
