<app-loader [loading]="loading"></app-loader>

<div class="content" *ngIf="!loading">
  <div class="content-body without-padding p-flex-row">
    <div class="detail-information-column border-right">
      <div class="detail-information-container">
        <div class="detail-information-header">
          <span><i class="pi pi-bars"></i> Daten</span>
        </div>

        <div class="detail-information-body">
          <div class="detail-information">
            <div class="detail-key clickable">
              <span
                tooltipPosition="left"
                (mouseenter)="
                  getLatestChange(caregiver.caregiver.id, 'caregiver', {
                    experiences_string: caregiver.caregiver.experiences_string
                  })
                "
                (click)="
                  openDiffViewHistory(caregiver.caregiver.id, 'caregiver', {
                    experiences_string: caregiver.caregiver.experiences_string
                  })
                "
                [pTooltip]="latestChanges.experiences_string"
                >Erfahrungen</span
              >
            </div>
            <div class="detail-value">
              <strong>{{
                caregiver.caregiver.experiences_string || "-"
              }}</strong>
            </div>
          </div>
          <div class="detail-information">
            <div class="detail-key clickable">
              <span
                tooltipPosition="left"
                (mouseenter)="
                  getLatestChange(caregiver.caregiver.id, 'caregiver', {
                    experience_info: caregiver.caregiver.experience_info
                  })
                "
                (click)="
                  openDiffViewHistory(caregiver.caregiver.id, 'caregiver', {
                    experience_info: caregiver.caregiver.experience_info
                  })
                "
                [pTooltip]="latestChanges.experience_info"
                >Zusatzinfo</span
              >
            </div>
            <div class="detail-value">
              <strong>{{ caregiver.caregiver.experience_info || "-" }}</strong>
            </div>
          </div>
          <div class="detail-information">
            <div class="detail-key clickable">
              <span
                tooltipPosition="left"
                (mouseenter)="
                  getLatestChange(caregiver.caregiver.id, 'caregiver', {
                    education_as: caregiver.caregiver.education_as
                  })
                "
                (click)="
                  openDiffViewHistory(caregiver.caregiver.id, 'caregiver', {
                    education_as: caregiver.caregiver.education_as
                  })
                "
                [pTooltip]="latestChanges.education_as"
                >Grundausbildung als</span
              >
            </div>
            <div class="detail-value">
              <strong>{{ caregiver.caregiver.education_as || "-" }}</strong>
            </div>
          </div>
          <div class="detail-information">
            <div class="detail-key clickable">
              <span
                tooltipPosition="left"
                (mouseenter)="
                  getLatestChange(caregiver.caregiver.id, 'caregiver', {
                    initial_training: caregiver.caregiver.initial_training,
                    last_training_date_string:
                      caregiver.caregiver.last_training_date_string
                  })
                "
                (click)="
                  openDiffViewHistory(caregiver.caregiver.id, 'caregiver', {
                    initial_training: caregiver.caregiver.initial_training,
                    last_training_date_string:
                      caregiver.caregiver.last_training_date_string
                  })
                "
                [pTooltip]="
                  latestChanges.last_training_date_string ||
                  latestChanges.initial_training
                "
                >Erstschulung vollständig?</span
              >
            </div>
            <div class="detail-value">
              <strong
                >{{ caregiver.caregiver.initial_training }}
                {{
                  caregiver.caregiver.last_training_date_string
                    ? "(" + caregiver.caregiver.last_training_date_string + ")"
                    : ""
                }}</strong
              >
            </div>
          </div>
          <div class="detail-information">
            <div class="detail-key clickable">
              <span
                tooltipPosition="left"
                (mouseenter)="
                  getLatestChange(caregiver.caregiver.id, 'caregiver', {
                    first_aid: caregiver.caregiver.first_aid,
                    first_aid_date_string:
                      caregiver.caregiver.first_aid_date_string
                  })
                "
                (click)="
                  openDiffViewHistory(caregiver.caregiver.id, 'caregiver', {
                    first_aid: caregiver.caregiver.first_aid,
                    first_aid_date_string:
                      caregiver.caregiver.first_aid_date_string
                  })
                "
                [pTooltip]="
                  latestChanges.first_aid_date_string || latestChanges.first_aid
                "
                >Erste Hilfe-Kurs?</span
              >
            </div>
            <div class="detail-value">
              <strong
                >{{ caregiver.caregiver.first_aid }}
                {{
                  caregiver.caregiver.first_aid_date_string
                    ? "(" + caregiver.caregiver.first_aid_date_string + ")"
                    : ""
                }}</strong
              >
            </div>
          </div>
          <div class="detail-information">
            <div class="detail-key clickable">
              <span
                tooltipPosition="left"
                (mouseenter)="
                  getLatestChange(caregiver.caregiver.id, 'caregiver', {
                    training_administration_date_string:
                      caregiver.caregiver.training_administration_date_string,
                    training_administration:
                      caregiver.caregiver.training_administration,
                    training_administration_from:
                      caregiver.caregiver.training_administration_from
                  })
                "
                (click)="
                  openDiffViewHistory(caregiver.caregiver.id, 'caregiver', {
                    training_administration_date_string:
                      caregiver.caregiver.training_administration_date_string,
                    training_administration:
                      caregiver.caregiver.training_administration,
                    training_administration_from:
                      caregiver.caregiver.training_administration_from
                  })
                "
                [pTooltip]="
                  latestChanges.training_administration_date_string ||
                  latestChanges.training_administration ||
                  latestChanges.training_administration_from
                "
                >Einarbeitung Administration?</span
              >
            </div>
            <div class="detail-value">
              <strong
                >{{ caregiver.caregiver.training_administration }}
                {{
                  caregiver.caregiver.training_administration_date_string
                    ? "(" +
                      caregiver.caregiver.training_administration_date_string +
                      " von " +
                      (caregiver.caregiver.training_administration_from ||
                        "??") +
                      ")"
                    : ""
                }}</strong
              >
            </div>
          </div>

          <div class="detail-information">
            <div class="detail-key clickable">
              <span
                tooltipPosition="left"
                (mouseenter)="
                  getLatestChange(caregiver.caregiver.id, 'caregiver', {
                    instruction_infection_act:
                      caregiver.caregiver.instruction_infection_act,
                    instruction_infection_act_date_string:
                      caregiver.caregiver.instruction_infection_act_date_string
                  })
                "
                (click)="
                  openDiffViewHistory(caregiver.caregiver.id, 'caregiver', {
                    instruction_infection_act:
                      caregiver.caregiver.instruction_infection_act,
                    instruction_infection_act_date_string:
                      caregiver.caregiver.instruction_infection_act_date_string
                  })
                "
                [pTooltip]="
                  latestChanges.first_aid_date_string ||
                  latestChanges.instruction_infection_act
                "
                >Belehrung Infektionsschutzgesetz?</span
              >
            </div>
            <div class="detail-value">
              <strong
                >{{ caregiver.caregiver.instruction_infection_act || "-" }}
                {{
                  caregiver.caregiver.instruction_infection_act_date_string
                    ? "(" +
                      caregiver.caregiver
                        .instruction_infection_act_date_string +
                      ")"
                    : ""
                }}</strong
              >
            </div>
          </div>

          <div class="detail-information">
            <div class="detail-key clickable">
              <span
                tooltipPosition="left"
                (mouseenter)="
                  getLatestChange(caregiver.caregiver.id, 'caregiver', {
                    extended_certificate_of_good_conduct:
                      caregiver.caregiver.extended_certificate_of_good_conduct,
                    extended_certificate_of_good_conduct_date_string:
                      caregiver.caregiver
                        .extended_certificate_of_good_conduct_date_string
                  })
                "
                (click)="
                  openDiffViewHistory(caregiver.caregiver.id, 'caregiver', {
                    extended_certificate_of_good_conduct:
                      caregiver.caregiver.extended_certificate_of_good_conduct,
                    extended_certificate_of_good_conduct_date_string:
                      caregiver.caregiver
                        .extended_certificate_of_good_conduct_date_string
                  })
                "
                [pTooltip]="
                  latestChanges.extended_certificate_of_good_conduct ||
                  latestChanges.extended_certificate_of_good_conduct_date_string
                "
                >Erweitertes Führungszeugnis?</span
              >
            </div>
            <div class="detail-value">
              <strong
                >{{
                  caregiver.caregiver.extended_certificate_of_good_conduct ||
                    "-"
                }}
                {{
                  caregiver.caregiver
                    .extended_certificate_of_good_conduct_date_string
                    ? "(" +
                      caregiver.caregiver
                        .extended_certificate_of_good_conduct_date_string +
                      ")"
                    : ""
                }}
              </strong>
            </div>
          </div>

          <!-- Hospitation -->
          <div
            class="detail-information"
            *ngIf="caregiver.caregiver.last_status_with_hospitation"
          >
            <div class="detail-key">
              <span>Hospitation</span>
            </div>
            <div class="detail-value">
              <strong
                >Mit
                {{
                  caregiver.caregiver.last_status_with_hospitation.caregiver
                    ?.full_name || "-"
                }}
                am
                {{
                  caregiver.caregiver.last_status_with_hospitation
                    .hospitation_from_string || "-"
                }}
                bis
                {{
                  caregiver.caregiver.last_status_with_hospitation
                    .hospitation_to_string || "-"
                }}.
                <br />
                Koordination von
                {{
                  caregiver.caregiver.last_status_with_hospitation
                    .coordination_from_user?.name || "-"
                }}
                am
                {{
                  caregiver.caregiver.last_status_with_hospitation
                    .hospitation_coordination_at_string || "-"
                }}
                durch
                {{
                  caregiver.caregiver.last_status_with_hospitation
                    .hospitation_coordination_type || "-"
                }}.
              </strong>
            </div>
          </div>

          <!-- Hospitation Feedback -->
          <div
            class="detail-information"
            *ngIf="caregiver.caregiver.last_status_with_hospitation_feedback"
          >
            <div class="detail-key">
              <span>Hospitation Feedback</span>
            </div>
            <div class="detail-value">
              <strong
                >{{
                  caregiver.caregiver.last_status_with_hospitation_feedback
                    .hospitation_feedback || "-"
                }}
              </strong>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
