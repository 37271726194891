<form #form="ngForm" class="dialog-form">
  <div class="dialog-form-body">
    <div class="p-fluid p-formgrid p-grid">
      <div class="p-field p-col-4">
        <label>Kennzeichen</label>
        <strong>{{ data.cover_key }}</strong>
      </div>

      <div class="p-field p-col-4">
        <label>von</label>
        <strong>{{ data.created_from_user.name }}</strong>
      </div>

      <div class="p-field p-col-4">
        <label>am</label>
        <strong>{{ data.created_at | date: "dd.MM.y, H:mm" }}</strong>
      </div>
    </div>
  </div>

  <div
    class="content-body dialog-max-height without-padding"
    style="margin-bottom: 20px"
    *ngIf="data.issue_items.length > 0"
  >
    <strong style="padding: 0 0 5px 20px" class="color-gray">FEHLERHAFT</strong>
    <p-table
      scrollHeight="200px"
      [value]="data.issue_items"
      [rowHover]="true"
      [scrollable]="true"
      [globalFilterFields]="[
          'name',
        ]"
    >
      <ng-template pTemplate="header">
        <tr>
          <th style="min-width: 300px">Grund</th>
          <th style="max-width: 100px">Seite</th>
          <th style="max-width: 100px">Scan</th>
          <th style="max-width: 50px"></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-item>
        <tr>
          <td
            *ngIf="!item.edited_at"
            style="min-width: 300px"
            class="color-alert"
          >
            {{ item.message }}
          </td>
          <td
            *ngIf="item.edited_at"
            style="min-width: 300px"
            class="color-orange"
          >
            <div style="display: flex; align-items: center; gap: 10px">
              {{ item.patient_name }} ({{ item.budget_type }})
              <i
                style="font-size: 12px"
                class="pi pi-info-circle"
                [pTooltip]="item.message"
              ></i>
            </div>
          </td>

          <td style="max-width: 100px">Seite {{ item.page }}</td>
          <td style="max-width: 100px">
            <a
              [href]="proofService.getImageDownloadLink(item.image_name)"
              target="_blank"
              >ansehen</a
            >
          </td>

          <td style="max-width: 50px">
            <!--            <i-->
            <!--              pTooltip="Bearbeiten"-->
            <!--              class="pi pi-pencil"-->
            <!--              (click)="openSelectPatientForProofDialog(item)"-->
            <!--              style="display: block"-->
            <!--            ></i>-->
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>

  <div class="content-body dialog-max-height without-padding">
    <div
      style="
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 20px 5px 20px;
      "
    >
      <strong class="color-gray">GELESENE DATEN</strong>

      <div class="content-header-buttons">
        <div class="p-input-icon-left">
          <i class="pi pi-search"></i>
          <input
            pInputText
            (input)="filterTableGlobal($event)"
            placeholder="Suche..."
            [value]="globalFilterValue"
          />
        </div>
      </div>
    </div>

    <p-table
      #dt
      [value]="data.not_issue_items"
      [rowHover]="true"
      [scrollable]="true"
      scrollHeight="320px"
      [globalFilterFields]="[
        'patient_id',
        'patient_name',
        'budget',
      ]"
    >
      <ng-template pTemplate="header">
        <tr>
          <th pSortableColumn="patient_name">
            Patient <p-sortIcon field="patient_name"></p-sortIcon>
          </th>
          <th>Zeitraum</th>
          <th style="min-width: 200px" pSortableColumn="budget">
            Budget <p-sortIcon field="budget"></p-sortIcon>
          </th>
          <th style="max-width: 100px" pSortableColumn="page">
            Seite <p-sortIcon field="page"></p-sortIcon>
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-item>
        <tr>
          <td>
            <a target="_blank" routerLink="/patients/{{ item.patient_id }}">
              {{ item.patient_name }}
            </a>
          </td>
          <td>{{ item.month }} {{ item.year }}</td>
          <td style="min-width: 200px">
            {{ item.budget_type }}
            {{ item.beihilfe == "JA" ? " (Beihilfe)" : "" }}
          </td>
          <td style="max-width: 100px">Seite {{ item.page }}</td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</form>
