<form #form="ngForm" class="dialog-form">
  <div
    class="dialog-sidebar space-between"
    *ngIf="values.letter_type && values.letter_type !== 'rückläufer'"
  >
    <div class="p-fluid p-formgrid p-grid without-border">
      <div class="p-field p-col-12">
        <label for="documents">Auswahl Anlage</label>
        <p-dropdown
          inputId="documents"
          name="documents"
          (onChange)="documentSelected($event)"
          [options]="documentOptions"
          scrollHeight="350px"
          optionLabel="name"
          [group]="true"
          optionGroupLabel="name"
          optionGroupChildren="documents"
          appendTo="body"
        ></p-dropdown>
      </div>
      <div class="p-field p-col-12">
        <div class="dialog-text-link">
          oder
          <span (click)="openAddDocumentDialog()"
            >neues Dokument hochladen</span
          >
        </div>
      </div>
    </div>
    <div class="p-fluid p-formgrid p-grid without-border">
      <div class="p-field p-col-12">
        <label class="highlight-label">Ausgewählte Dokumente</label>
        <div class="dialog-no-message" *ngIf="values.documents.length === 0">
          Keine Dokumente ausgewählt
        </div>
        <div class="document-list" *ngFor="let document of values.documents">
          <span
            >{{ document.name }}
            <a
              [href]="documentService.getDocumentDownloadLink(document.uuid)"
              target="_blank"
              >(ansehen)</a
            ></span
          >
          <i
            (click)="removeDocument(document)"
            pTooltip="Entfernen"
            class="pi pi-times"
          ></i>
        </div>
      </div>
    </div>
  </div>

  <div class="dialog-form-body">
    <div class="p-field">
      <label>Versand per *</label>
      <div class="p-formgroup-inline">
        <div class="p-field-radiobutton">
          <p-radioButton
            label="E-Mail"
            name="type"
            (onClick)="getReceivers()"
            [(ngModel)]="values.type"
            value="email"
          ></p-radioButton>
        </div>
        <div class="p-field-radiobutton">
          <p-radioButton
            label="Post"
            name="type"
            (onClick)="getReceivers()"
            value="post"
            [(ngModel)]="values.type"
          ></p-radioButton>
        </div>
      </div>
    </div>

    <div class="p-fluid p-formgrid p-grid without-border">
      <div class="p-field p-col-12" *ngIf="!fromOtherDialog">
        <label for="patient">Patient * </label>
        <p-autoComplete
          field="full_name"
          inputId="patient"
          name="patient"
          appendTo="body"
          [forceSelection]="true"
          [(ngModel)]="values.patient"
          (onSelect)="getReceivers()"
          [suggestions]="patients"
          (completeMethod)="searchPatients($event)"
          [required]="true"
          #patient="ngModel"
          [class.p-invalid]="
            patient.invalid && (patient.dirty || patient.touched)
          "
        >
          <ng-template let-patient pTemplate="item">
            <div class="search-result-data">
              <span class="search-result-name">
                <b>{{ patient.full_name }}</b>
                <span class="search-result-id">#{{ patient.id }}</span>
                <i
                  *ngIf="patient.status"
                  class="p-tag p-tag-rounded p-tag-{{ patient.status }}"
                  >{{ patient.status_string }}</i
                >
              </span>
              <span class="search-result-address">
                {{ patient.zipcode }} {{ patient.city }}
              </span>
            </div>
          </ng-template>
        </p-autoComplete>
      </div>

      <div class="p-field p-col-12" *ngIf="fromOtherDialog">
        <label>Patient</label>
        <strong>{{ values.patient.full_name }}</strong>
      </div>
    </div>

    <div class="p-fluid p-formgrid p-grid without-border">
      <div class="p-field p-col-6">
        <label for="letter_type">Typ *</label>
        <p-dropdown
          inputId="letter_type"
          name="letter_type"
          [placeholder]="
            values.patient ? 'Bitte wählen' : 'Bitte Patient wählen'
          "
          (onChange)="setText()"
          [options]="options"
          [(ngModel)]="values.letter_type"
          [disabled]="!values.patient || fromOtherDialog"
          [required]="true"
          #type="ngModel"
          [class.p-invalid]="type.invalid && (type.dirty || type.touched)"
        ></p-dropdown>
      </div>

      <div
        class="p-field p-col-6"
        *ngIf="
          values.letter_type === 'mahnung' ||
          values.letter_type === 'rückläufer'
        "
      >
        <label for="payment_for">Zahlung bis *</label>
        <p-calendar
          [firstDayOfWeek]="1"
          [showWeek]="true"
          inputId="payment_for"
          name="payment_for"
          [(ngModel)]="values.payment_for"
          [showButtonBar]="true"
          dateFormat="dd.mm.yy"
          appendTo="body"
          dataType="string"
          [showIcon]="true"
          [required]="true"
          #payment_for="ngModel"
          [class.p-invalid]="
            payment_for.invalid && (payment_for.dirty || payment_for.touched)
          "
        ></p-calendar>
      </div>
    </div>

    <div
      class="p-fluid p-formgrid p-grid without-border"
      *ngIf="values.letter_type !== 'opta_data'"
    >
      <!-- Empfänger -->
      <div class="p-field p-col-6">
        <label for="receiver">Empfänger *</label>
        <p-dropdown
          [placeholder]="
            values.patient ? 'Bitte wählen' : 'Bitte Patient wählen'
          "
          inputId="receiver"
          name="receiver"
          scrollHeight="500px"
          appendTo="body"
          [options]="receiverOptions"
          [(ngModel)]="values.receiver"
          [required]="true"
          [disabled]="!values.patient"
          #receiver="ngModel"
          [class.p-invalid]="
            receiver.invalid && (receiver.dirty || receiver.touched)
          "
        >
          <ng-template let-receiver pTemplate="item">
            <div>{{ receiver.label }}</div>
            <span *ngIf="values.type === 'email'">{{ receiver.email }}</span>
          </ng-template>
        </p-dropdown>
      </div>

      <!-- CC an -->
      <div class="p-field p-col-6" *ngIf="values.type === 'email'">
        <label for="cc">CC an</label>
        <p-multiSelect
          inputId="cc"
          placeholder="Optional"
          [options]="ccOptions"
          [filter]="false"
          [showHeader]="false"
          name="cc"
          scrollHeight="500px"
          appendTo="body"
          [disabled]="!values.patient"
          [(ngModel)]="values.cc"
        ></p-multiSelect>
      </div>
    </div>

    <div
      class="p-fluid p-formgrid p-grid without-border"
      *ngIf="values.letter_type === 'rückläufer'"
    >
      <div class="p-field p-col-12">
        <label for="case_number">Fallnummer</label>
        <input
          id="case_number"
          name="case_number"
          type="text"
          [(ngModel)]="values.case_number"
          pInputText
        />
      </div>
    </div>

    <div
      class="p-fluid p-formgrid p-grid without-border"
      *ngIf="
        values.letter_type === 'rückläufer' || values.letter_type === 'mahnung'
      "
    >
      <div class="p-field p-col-12">
        <label for="subject">Betreff *</label>
        <input
          id="subject"
          name="subject"
          type="text"
          [(ngModel)]="values.subject"
          pInputText
          #subject="ngModel"
          required
        />
        <div
          *ngIf="subject.invalid && (subject.dirty || subject.touched)"
          class="p-invalid"
        >
          <div *ngIf="subject.errors?.required">
            <small>Bitte geben Sie einen Betreff ein</small>
          </div>
        </div>
      </div>

      <div class="p-field p-col-12">
        <div class="p-field-checkbox">
          <p-checkbox
            label="Ohne Bearbeitungsgebühr"
            [binary]="true"
            [(ngModel)]="values.without_fee"
            name="budget_type"
          ></p-checkbox>
        </div>
      </div>
    </div>

    <div class="p-fluid p-formgrid p-grid without-border">
      <div class="p-field p-col-12">
        <label for="text">Inhalt</label>

        <ngx-jodit [(value)]="values.text" [options]="optionsJodit"></ngx-jodit>
      </div>
    </div>
  </div>
  <div class="dialog-form-footer">
    <i *ngIf="values.type === 'post' || !values.patient"></i>
    <a
      *ngIf="values.type === 'email' && values.patient"
      target="_blank"
      (click)="clickedOnPreview = true"
      [href]="sendLetterService.getLetterMailPreviewLink(values)"
    >
      <button
        pButton
        label="Mail Vorschau"
        type="button"
        icon="pi pi-eye"
        iconPos="left"
        class="p-button p-button-info"
      ></button>
    </a>

    <a
      (click)="downloadAndClose()"
      *ngIf="documentLink"
      target="_blank"
      [href]="documentLink"
    >
      <button
        pButton
        label="Dokument herunterladen und schließen"
        type="button"
        icon="pi pi-download"
        iconPos="left"
        class="p-button p-button-info"
      ></button>
    </a>

    <button
      pButton
      [label]="values.type === 'email' ? 'Versenden' : 'Erstellen'"
      type="submit"
      *ngIf="!documentLink"
      (click)="save()"
      [disabled]="submitted || (!clickedOnPreview && values.type === 'email')"
      [icon]="submitted ? 'pi pi-spin pi-spinner' : 'pi pi-check'"
    ></button>
  </div>
</form>
