<form #form="ngForm" class="dialog-form">
  <div
    class="dialog-sidebar secondary-dialog-sidebar middle-dialog-sidebar"
    *ngIf="values.status === 'STORNO' || values.status === 'KI'"
  >
    <span class="content-sub-headline">Vorhandene Einsätze seit heute</span>

    <small class="color-gray" *ngIf="appointments.length === 0"
      >Keine Einsätze vorhanden</small
    >

    <p-table
      *ngIf="appointments.length > 0"
      #dt
      [value]="appointments"
      [rowHover]="true"
      [autoLayout]="true"
      [globalFilterFields]="[]"
    >
      <ng-template pTemplate="header">
        <tr>
          <th style="min-width: 150px" pSortableColumn="caregiver.last_name">
            Betreuungskraft
            <p-sortIcon field="caregiver.last_name"></p-sortIcon>
          </th>
          <th pSortableColumn="real_from">
            Datum <p-sortIcon field="real_from"></p-sortIcon>
          </th>
          <th>Uhrzeit</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-appointment>
        <tr>
          <td>
            <a
              class="color-main1"
              target="_blank"
              href="/caregivers/{{ appointment.caregiver.id }}/planning"
            >
              {{ appointment.caregiver.last_name }},
              {{ appointment.caregiver.first_name }}
            </a>
          </td>
          <td>
            {{ appointment.real_from | date: "dd.MM.y" }} ({{
              appointment.weekday
            }})
          </td>
          <td>{{ appointment.real_from_h }} - {{ appointment.real_to_h }}</td>
        </tr>
      </ng-template>
    </p-table>
  </div>

  <div class="dialog-form-body">
    <div class="p-fluid p-formgrid p-grid">
      <div class="p-field">
        <div class="p-formgroup-inline">
          <div class="p-field-radiobutton">
            <p-radioButton
              label="Interessent"
              name="type"
              [(ngModel)]="values.status"
              value="INTERESTED"
              #status="ngModel"
              required
            ></p-radioButton>
          </div>
          <div class="p-field-radiobutton">
            <p-radioButton
              label="Stammkunde"
              name="type"
              [(ngModel)]="values.status"
              value="REGULAR"
              #status="ngModel"
              required
            ></p-radioButton>
          </div>
          <div class="p-field-radiobutton">
            <p-radioButton
              label="Neukunde"
              name="type"
              [(ngModel)]="values.status"
              value="NEW"
              #status="ngModel"
              required
            ></p-radioButton>
          </div>
          <div class="p-field-radiobutton">
            <p-radioButton
              label="Storno"
              name="type"
              [(ngModel)]="values.status"
              value="STORNO"
              #status="ngModel"
              required
            ></p-radioButton>
          </div>
          <div class="p-field-radiobutton">
            <p-radioButton
              label="KI"
              name="type"
              [(ngModel)]="values.status"
              value="KI"
              #status="ngModel"
              required
            ></p-radioButton>
          </div>
        </div>
        <div
          *ngIf="status.invalid && (status.dirty || status.touched)"
          class="p-invalid"
        >
          <div *ngIf="status.errors?.required">
            <small>Bitte wählen Sie einen Status</small>
          </div>
        </div>
      </div>
    </div>

    <div class="p-fluid p-formgrid p-grid without-border">
      <div class="p-field p-col-12">
        <label for="patients">Patient *</label>
        <p-multiSelect
          (onChange)="loadPatientsAppointments()"
          inputId="patients"
          [options]="patientOptions"
          optionLabel="full_name"
          [filter]="false"
          [showHeader]="false"
          name="patients"
          [(ngModel)]="values.patients"
          [required]="true"
          #patients="ngModel"
          [class.p-invalid]="
            patients.invalid && (patients.dirty || patients.touched)
          "
        ></p-multiSelect>
        <div
          *ngIf="patients.invalid && (patients.dirty || patients.touched)"
          class="p-invalid"
        >
          <div *ngIf="patients.errors?.required">
            <small>Bitte wählen Sie einen Patienten aus</small>
          </div>
        </div>
      </div>
    </div>

    <div
      class="p-fluid p-formgrid p-grid without-border"
      *ngIf="values.status === 'STORNO' || values.status === 'KI'"
    >
      <div class="p-field p-col-12">
        <label for="storno_reason_id">Grund</label>
        <p-dropdown
          placeholder="Bitte auswählen"
          inputId="storno_reason_id"
          name="storno_reason_id"
          [(ngModel)]="values.storno_reason_id"
          [options]="reasonOptions"
          appendTo="body"
          optionValue="id"
          optionLabel="reason"
          [required]="true"
          #reason="ngModel"
          [class.p-invalid]="reason.invalid && (reason.dirty || reason.touched)"
        ></p-dropdown>
      </div>
    </div>

    <div class="p-fluid p-formgrid p-grid without-border">
      <div class="p-field p-col-12">
        <label for="comment">Bemerkung</label>
        <textarea
          id="comment"
          name="comment"
          [(ngModel)]="values.comment"
          [rows]="3"
          pInputTextarea
        ></textarea>
      </div>
    </div>
  </div>
  <div class="dialog-form-footer">
    <i></i>
    <button
      pButton
      label="Status ändern"
      type="submit"
      (click)="save()"
      [disabled]="submitted"
      [icon]="submitted ? 'pi pi-spin pi-spinner' : 'pi pi-check'"
    ></button>
  </div>
</form>
