import { HttpErrorResponse } from '@angular/common/http'
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core'
import { StatusCodes } from 'http-status-codes'
import {
  DialogService,
  DynamicDialogConfig,
  DynamicDialogRef,
} from 'primeng/dynamicdialog'
import { EventBusService, GlobalEvent } from 'src/app/services/eventbus.service'
import { ToastService } from 'src/app/services/toast.service'
import { DocumentService } from '../../../services/document.service'
import { NgForm } from '@angular/forms'
import { Subscription } from 'rxjs'
import { ConfirmationService } from 'primeng/api'
import { AccountingReturnService } from '../../../services/accounting-return.service'
import { SearchResultModel } from '../../../models/search/search-result.model'
import { SearchService } from '../../../services/search.service'
import { HelperService } from '../../../services/helper.service'
import * as currency from 'currency.js'
import { AccountingReturnHistoryModel } from '../../../models/accounting/accounting-return-history.model'
import { AuthModel } from '../../../models/user/auth.model'
import { AuthService } from '../../../services/auth.service'
import { PatientModel } from '../../../models/customer-patient/patient.model'
import { ceil, floor } from 'lodash-es'
import { InvoiceService } from '../../../services/invoice.service'
import { PatientService } from '../../../services/patient.service'
import { InvoiceDialogComponent } from './invoice-dialog.component'
import { InvoiceNewDialogComponent } from '../invoice-new-dialog/invoice-new-dialog.component'

@Component({
  selector: 'app-invoice-dialog',
  templateUrl: './invoice-km-dialog.component.html',
})
export class InvoiceKmDialogComponent implements OnInit, OnDestroy {
  @ViewChild('form', { static: true }) form!: NgForm

  private formSubscription: Subscription | null | undefined = null
  private isDirty = false
  private selectedFile: any = null
  public patients: SearchResultModel[] = []

  public patient: PatientModel = new PatientModel()

  public clickedOnPreview = false
  public isEdit = false
  public type = ''
  public isFrozen = false
  public withPositions = true
  public data: any = {}

  public selectAll = null

  public currentUser: AuthModel | null = new AuthModel()
  public editHistory: AccountingReturnHistoryModel | null = null
  public historyComment = ''

  public statusOptions = [
    { label: 'ERSTELLT', value: 'CREATED', backgroundColor: '#3296dc' },
    { label: 'IN BEARBEITUNG', value: 'EDITED', backgroundColor: '#dbaa25' },
    { label: 'ERLEDIGT', value: 'FINISHED', backgroundColor: '#84bc3c' },
  ]

  public items = [
    {
      label: 'Zum versand',
      icon: 'pi pi-send',
      command: () => {
        this.storeInvoice()
      },
    },
  ]

  public confirmed = {
    case_number: false,
    performance_month: false,
    performance_year: false,
    invoice_number: false,
    invoice_date_string: false,
    total_value: false,
    invoice_value: false,
  }
  public receiverOptions: any[] = []
  public ccOptions: any[] = []

  private currencyOptions = {
    // Cent
    decimal: ',',
    // Tausender
    separator: '.',
    symbol: '€',
    pattern: `# !`,
  }

  public subtotal = ''
  public incorrectInsurerNumber = false
  public values = {
    status: 'DRAFT',
    id: '',
    sent_type: '',
    receiver: '',
    cc: [],
    vat: 19,
    total_price_euro_formatted: '',
    subtotal_price_euro_formatted: '',
    vat_price_euro_formatted: '',
    positions: [
      {
        amount: '',
        text: '',
        unit_price: null,
        unit_price_euro: null,
        unit_price_euro_formatted: null,
        total_price: null as any,
        total_price_euro_formatted: null as any,
        total_price_euro: null as any,
      },
    ] as any,
  }

  public submitted = false
  public submittedDelete = false

  public monthOptions: any = []
  public isParsing = false

  public reasonOptions = []

  constructor(
    private ref: DynamicDialogRef,
    private patientService: PatientService,
    private searchService: SearchService,
    private dialogService: DialogService,
    public config: DynamicDialogConfig,
    public documentService: DocumentService,
    private accountingService: AccountingReturnService,
    public invoiceService: InvoiceService,
    private eventbus: EventBusService,
    private toastService: ToastService,
    private authService: AuthService,
    private helperService: HelperService,
    private confirmationService: ConfirmationService
  ) {}

  ngOnInit(): void {
    if (this.config.data.invoice) {
      this.isFrozen = this.config.data.invoice.status !== 'DRAFT'

      this.patient = this.config.data.invoice.invoiceable

      this.loadReceivers()

      this.isEdit = true

      this.values = this.config.data.invoice

      const invoiceType = this.patient.invoice_types_string

      if (invoiceType.includes('E-Mail')) {
        this.values.sent_type = 'email'
      } else if (invoiceType.includes('Post')) {
        this.values.sent_type = 'post'
      }
    } else if (this.config.data.patient) {
      this.type = this.config.data.type
      this.patient = this.config.data.patient
      this.withPositions = this.config.data.with_positions

      this.isEdit = true
    } else {
      this.isEdit = false
    }

    this.helperService.dependencies$.subscribe((data: any) => {
      this.monthOptions = data.months
      this.reasonOptions = data.accounting_return_reasons
    })

    this.formSubscription = this.form.valueChanges?.subscribe(() => {
      if (!this.form.pristine) {
        this.isDirty = true
      }
    })

    this.currentUser = this.authService.getUser()

    this.calculate()
  }

  public selectAllToggle(): void {
    this.config.data.patient.persplan_budgets.forEach((budget: any) => {
      // Einträge, die explizit auf "Nicht berechnet" gestellt wurden, sollen ignoriert werden.
      if (budget.caregiver_appointment_trip.selected_for_invoice !== false) {
        budget.caregiver_appointment_trip.selected_for_invoice = this.selectAll
      }
    })
  }

  public getComments(comments: any[]): string {
    const comment: string[] = []

    comments.forEach((c: any) => {
      comment.push(c.comment)
    })

    return comment.join('\n\n')
  }

  public getAppointmentTypes(): { types: string; comment: string } {
    return {
      types: this.patient.invoice_types_string || 'Keine Angabe',
      comment: this.patient.invoice_type_tooltip || '',
    }
  }

  ngOnDestroy(): void {
    this.formSubscription?.unsubscribe()
  }

  public confirmSendLetter(event: any): void {
    // this.confirmationService.confirm({
    //   target: event.target,
    //   message: 'Mit Bearbeitungsgebühr?',
    //   accept: () => {
    //     this.openSendLetterDialog()
    //   },
    //   reject: () => {
    //     this.openSendLetterDialog(true)
    //   },
    // })
  }

  public addPosition(): void {
    this.values.positions.push({
      amount: '',
      text: '',
      unit_price: null,
      unit_price_euro: null,
      unit_price_euro_formatted: null,
      total_price: 0,
      total_price_euro: 0.0,
      total_price_euro_formatted: '0,00 €',
    })
  }

  public patientSelected(event: any): void {
    this.patient = event

    this.loadReceivers()

    const invoiceType = this.patient.invoice_types_string

    if (invoiceType.includes('E-Mail')) {
      this.values.sent_type = 'email'
    } else if (invoiceType.includes('Post')) {
      this.values.sent_type = 'post'
    }
  }

  public loadReceivers(): void {
    this.patientService
      .getReceivers(this.patient.id)
      .subscribe((response: any) => {
        this.buildReceiverOptions(response)
        this.buildCCOptions(response)
      })
  }

  /**
   * Kalkuliert den Preis für ein Item neu.
   */
  calculateItem(position: any) {
    position.unit_price_euro_formatted = currency(
      position.unit_price_euro,
      this.currencyOptions
    ).format()
    position.total_price_euro_formatted = currency(
      position.unit_price_euro,
      this.currencyOptions
    )
      .multiply(position.amount)
      .format()

    this.calculate()
  }

  /**
   * Stößt die Kalkulation für die Summen an.
   */
  calculate() {
    this.calculateSubtotal()
    this.calculateTax()
    this.calculateTotal()
  }

  /**
   * Kalkulation für die Zwischensumme.
   */
  calculateSubtotal() {
    this.values.subtotal_price_euro_formatted = ''

    this.values.positions.map((item: any) => {
      this.values.subtotal_price_euro_formatted = currency(
        this.values.subtotal_price_euro_formatted,
        this.currencyOptions
      )
        .add(item.total_price_euro_formatted)
        .format()
    })
  }

  /**
   * Kalkulation für die Umsatzsteuer, nur wenn keine Provisionsrechnung.
   */
  calculateTax() {
    let tax = currency(this.values.subtotal_price_euro_formatted, {
      ...this.currencyOptions,
      precision: 4,
    }).multiply(`0.${this.values.vat}`).value

    // Nachkommastellen hochrunden
    tax = tax > 0 ? ceil(tax, 2) : floor(tax, 2)

    this.values.vat_price_euro_formatted = currency(
      tax,
      this.currencyOptions
    ).format()
  }

  /**
   * Kalkulation für die gesammte Summe.
   */
  calculateTotal() {
    this.values.total_price_euro_formatted = currency(
      this.values.subtotal_price_euro_formatted,
      this.currencyOptions
    )
      .add(this.values.vat_price_euro_formatted)
      .format()
  }

  removePosition(position: any): void {
    this.values.positions = this.values.positions.filter(
      (item: any) => item != position
    )

    this.calculate()
  }

  public searchPatients(event: any): void {
    this.searchService
      .findPatients(event.query.trim())
      .subscribe((results: SearchResultModel[]) => {
        this.patients = results
      })
  }

  public store(): void {
    if (!this.form.form.valid) {
      this.submitted = false
      this.form.form.markAllAsTouched()
      return
    }

    this.submitted = true

    this.invoiceService.updateKmInvoice(this.config.data).subscribe(
      (invoice: any) => {
        // this.values = invoice

        this.submitted = false
        this.eventbus.emit(GlobalEvent.InvoiceChanged)
        // this.ref.close()
        this.toastService.success(
          'Entwurf gespeichert',
          'Der Entwurf wurde erfolgreich gespeichert'
        )
      },
      (error: HttpErrorResponse) => {
        if (error.status === StatusCodes.UNPROCESSABLE_ENTITY) {
          this.toastService.error('Bitte füllen Sie alle Pflichtfelder aus')
        } else {
          this.toastService.error(
            'Etwas ist schief gelaufen...',
            'Bitte wenden Sie sich an den Support'
          )
        }
        this.submitted = false
      }
    )
  }

  public storeInvoice(): void {
    // TODO: prüfen ob mindestens 1 Auswahl angehakt ist

    this.submitted = true

    this.invoiceService.storeKmInvoice(this.config.data).subscribe(
      (invoice: any) => {
        this.values = invoice

        this.submitted = false
        this.eventbus.emit(GlobalEvent.InvoiceChanged)
        this.ref.close()
        this.toastService.success(
          'Rechnung erstellt',
          'Die Rechnung wurde erfolgreich zum Versand erstellt'
        )

        this.dialogService.open(InvoiceNewDialogComponent, {
          header: 'Rechnung',
          width: '96%',
          height: '90%',
          styleClass: 'dialog-container',
          data: {
            invoice,
          },
        })
      },
      (error: HttpErrorResponse) => {
        if (error.status === StatusCodes.UNPROCESSABLE_ENTITY) {
          this.toastService.error('Bitte füllen Sie alle Pflichtfelder aus')
        } else {
          this.toastService.error(
            'Etwas ist schief gelaufen...',
            'Bitte wenden Sie sich an den Support'
          )
        }
        this.submitted = false
      }
    )
  }

  public buildReceiverOptions(response: any): void {
    this.receiverOptions = []
    this.values.receiver = ''

    this.receiverOptions.push({
      value: '',
      label: 'Bitte auswählen',
      email: '',
      disabled: true,
    })

    this.receiverOptions.push({
      value: `patient_${response.first_patient.id}_`,
      email: response.first_patient.email || 'Keine E-Mail',
      label: `${response.first_patient.full_name} - Patient`,
      disabled:
        this.values.sent_type === 'email' && !response.first_patient.email,
    })

    if (response.second_patient) {
      this.receiverOptions.push({
        value: `patient_${response.second_patient.id}_`,
        email: response.second_patient.email || 'Keine E-Mail',
        label: `${response.second_patient.full_name} - Patient`,
        disabled:
          this.values.sent_type === 'email' && !response.second_patient.email,
      })
    }

    for (const contactPerson of response.contact_persons) {
      if (contactPerson.email_private) {
        this.receiverOptions.push({
          value: `ap_${contactPerson.id}_private`,
          email: `Private E-Mail: ${contactPerson.email_private}`,
          label: `${contactPerson.full_name} - AP`,
          disabled: false,
        })

        // Bei Postversand soll der AP soll nur einmal in der Liste angezeigt
        // werden, selbst wenn er noch eine Geschäftliche E-Mail Adresse hat.
        if (this.values.sent_type === 'post') {
          continue
        }
      }

      if (contactPerson.email_work) {
        this.receiverOptions.push({
          value: `ap_${contactPerson.id}_work`,
          email: `Geschäftliche E-Mail: ${contactPerson.email_work}`,
          label: `${contactPerson.full_name} - AP`,
          disabled: false,
        })
      }

      // Wenn gar keine E-Mail hinterlegt ist.
      if (!contactPerson.email_private && !contactPerson.email_work) {
        this.receiverOptions.push({
          value: `ap_${contactPerson.id}_`,
          email: 'Keine E-Mail',
          label: `${contactPerson.full_name} - AP`,
          disabled: true,
        })
      }
    }
  }

  public buildCCOptions(response: any): void {
    this.ccOptions = []

    this.ccOptions.push({
      value: response.first_patient.email,
      email: response.first_patient.email || 'Keine E-Mail',
      label: `${response.first_patient.full_name} - Patient`,
      disabled: !response.first_patient.email,
    })

    if (response.second_patient) {
      this.ccOptions.push({
        value: response.second_patient.email,
        email: response.second_patient.email || 'Keine E-Mail',
        label: `${response.second_patient.full_name} - Patient`,
        disabled: !response.second_patient.email,
      })
    }

    for (const contactPerson of response.contact_persons) {
      if (contactPerson.email_private) {
        this.ccOptions.push({
          value: contactPerson.email_private,
          email: `Private E-Mail: ${contactPerson.email_private}`,
          label: `${contactPerson.full_name} - AP - Private E-Mail`,
          disabled: false,
        })
      }

      if (contactPerson.email_work) {
        this.ccOptions.push({
          value: contactPerson.email_work,
          email: `Geschäftliche E-Mail: ${contactPerson.email_work}`,
          label: `${contactPerson.full_name} - AP - Geschäftliche E-Mail`,
          disabled: false,
        })
      }

      // Wenn gar keine E-Mail hinterlegt ist.
      if (!contactPerson.email_private && !contactPerson.email_work) {
        this.ccOptions.push({
          value: null,
          email: 'Keine E-Mail',
          label: `${contactPerson.full_name} - AP - Keine E-Mail`,
          disabled: true,
        })
      }
    }
  }

  public close(): void {
    if (this.historyComment) {
      alert('Bitte Historyeintrag speichern oder entfernen')
      return
    }

    this.ref.close()
  }

  public remove(event: any): void {
    // this.confirmationService.confirm({
    //   target: event.target ?? undefined,
    //   message: 'Rückläufer wirklich löschen?',
    //   accept: () => {
    //     this.submittedDelete = true
    //
    //     this.accountingService.remove(this.values.id).subscribe(
    //       () => {
    //         this.submittedDelete = false
    //         this.eventbus.emit(GlobalEvent.AccountingReturnChanged)
    //         this.ref.close()
    //         this.toastService.success(
    //           'Rückläufer gelöscht',
    //           'Der Rückläufer wurde erfolgreich gelöscht'
    //         )
    //       },
    //       () => {
    //         this.submittedDelete = false
    //         this.toastService.error(
    //           'Löschen fehlgeschlagen',
    //           'Der Rückläufer konnte nicht gelöscht werden'
    //         )
    //       }
    //     )
    //   },
    //   reject: () => {},
    // })
  }
}
