<div class="content">
  <header class="content-header">
    <div class="content-header-menu-tabs" [class.disabled]="!caregiver.id">
      <a
        routerLink="/caregivers/{{ caregiverId }}"
        routerLinkActive="active"
        [routerLinkActiveOptions]="{ exact: true }"
        >Stammdaten</a
      >

      <a
        routerLink="/caregivers/{{ caregiverId }}/contract-data"
        routerLinkActive="active"
        [routerLinkActiveOptions]="{ exact: true }"
        >Vertragsdaten</a
      >

      <a
        routerLink="/caregivers/{{ caregiverId }}/qualifications"
        routerLinkActive="active"
        [routerLinkActiveOptions]="{ exact: true }"
        >Qualifikationen</a
      >
    </div>

    <div class="setting-menu-container" #settingContainer>
      <button
        pButton
        label="Einstellungen"
        type="button"
        class="p-button-outlined"
        icon="pi pi-chevron-down"
        iconPos="right"
        [disabled]="!caregiver.id"
        (click)="menu.toggle($event)"
      ></button>
      <p-menu #menu [popup]="true" [model]="settingMenuItems"></p-menu>
    </div>
  </header>
</div>

<router-outlet></router-outlet>
