import { Component, OnInit } from '@angular/core'
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog'
import { EventBusService, GlobalEvent } from 'src/app/services/eventbus.service'
import { ToastService } from 'src/app/services/toast.service'
import { NoticeService } from '../../../services/notice.service'
import { ConfirmationService } from 'primeng/api'

@Component({
  selector: 'app-notice-dialog',
  templateUrl: './notice-dialog.component.html',
})
export class NoticeDialogComponent implements OnInit {
  notice = ''
  submitted = false
  submittedDelete = false

  constructor(
    private ref: DynamicDialogRef,
    private config: DynamicDialogConfig,
    private noticeService: NoticeService,
    private eventbus: EventBusService,
    private toastService: ToastService,
    private confirmationService: ConfirmationService
  ) {}

  public ngOnInit(): void {
    this.notice = this.config.data.notice
  }

  /**
   * Speichert eine Notiz.
   */
  public save(): void {
    this.submitted = true

    this.noticeService
      .updateNotice(
        this.config.data.type_id,
        this.config.data.type,
        this.notice
      )
      .subscribe(
        () => {
          this.submitted = false
          this.eventbus.emit(GlobalEvent.NoticeChanged)
          this.ref.close()
          this.toastService.success(
            'Notiz bearbeitet',
            'Die Notiz wurde erfolgreich bearbeitet'
          )
        },
        () => {
          this.toastService.error(
            'Etwas ist schiefgelaufen...',
            'Bitte wenden Sie sich an den Support'
          )

          this.submitted = false
        }
      )
  }

  public remove(event: any): void {
    this.confirmationService.confirm({
      target: event.target ?? undefined,
      message: 'Notiz wirklich löschen?',
      accept: () => {
        this.noticeService
          .updateNotice(this.config.data.type_id, this.config.data.type, null)
          .subscribe(
            () => {
              this.submitted = false
              this.eventbus.emit(GlobalEvent.NoticeChanged)
              this.ref.close()
              this.toastService.success(
                'Notiz entfernt',
                'Die Notiz wurde erfolgreich entfernt'
              )
            },
            () => {
              this.toastService.error(
                'Etwas ist schiefgelaufen...',
                'Bitte wenden Sie sich an den Support'
              )

              this.submitted = false
            }
          )
      },
      reject: () => {},
    })
  }
}
