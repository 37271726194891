<app-loader [loading]="loading"></app-loader>

<div class="content" *ngIf="!loading">
  <header class="content-header">
    <span>Alle abgeschlossenen Beratungseinsätze</span>

    <div
      class="table-search-container"
      [class.searchfield-active]="globalFilterValue != ''"
      *ngIf="consultings.length > 0"
    >
      <div class="p-input-icon-left">
        <i class="pi pi-search"></i>
        <input
          pInputText
          (input)="filterTableGlobal($event)"
          placeholder="Suche..."
          [(ngModel)]="globalFilterValue"
        />
      </div>
    </div>
  </header>
</div>

<div class="content" style="margin-bottom: 0" *ngIf="!loading">
  <div class="content-body" *ngIf="consultings.length == 0">
    <span class="content-no-message"
      >Keine abgeschlossenen Beratungseinsätze vorhanden</span
    >
  </div>

  <div
    class="content-body content-body-full-height-with-tabs without-padding"
    *ngIf="consultings.length > 0"
  >
    <p-table
      #dt
      [value]="consultings"
      [globalFilterFields]="[
        'patient.id',
        'patient.first_name',
        'patient.last_name',
        'patient.full_name',
        'invoice.invoice_number',
      ]"
      [(first)]="firstPage"
      [rowHover]="true"
      [showCurrentPageReport]="true"
      currentPageReportTemplate="{totalRecords} Einträge"
      [rows]="50"
      [paginator]="true"
      scrollHeight="100%"
      [scrollable]="true"
    >
      <ng-template pTemplate="header">
        <tr class="table-headline-highlight">
          <th style="min-width: 500px" pSortableColumn="status">
            Status <p-sortIcon field="status"></p-sortIcon>
          </th>
          <th pSortableColumn="patient.last_name">
            Patient <p-sortIcon field="patient.last_name"></p-sortIcon>
          </th>
          <th pSortableColumn="patient.degree_of_care">
            PG <p-sortIcon field="patient.degree_of_care"></p-sortIcon>
          </th>
          <th style="min-width: 200px" pSortableColumn="patient.city">
            Wohnort <p-sortIcon field="patient.city"></p-sortIcon>
          </th>
          <th pSortableColumn="date">
            am <p-sortIcon field="date"></p-sortIcon>
          </th>
          <th pSortableColumn="sent_type">
            Übergabe per <p-sortIcon field="sent_type"></p-sortIcon>
          </th>
          <th style="max-width: 100px"></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-consulting>
        <tr>
          <td style="min-width: 500px">
            <span class="p-tag p-tag-rounded p-tag-{{ consulting.status }}">{{
              consulting.status_value
            }}</span>

            <span
              (click)="openMediaFromUuid(consulting.media[0].uuid)"
              style="margin: 0 0 0 5px"
              *ngIf="consulting.media[0]"
              class="p-tag p-tag-rounded p-tag-outline p-tag-clickable"
              ><i class="pi pi-file-pdf"></i>Nachweis</span
            >

            <span
              (click)="openInvoiceDialog(consulting.invoice)"
              style="margin: 0 0 0 5px"
              *ngIf="consulting.invoice && !consulting.invoice.is_storno"
              class="p-tag p-tag-rounded p-tag-outline p-tag-clickable"
              ><i class="pi pi-euro"></i>Rechnung:
              {{ consulting.invoice.status_text }}</span
            >

            <span
              (click)="openInvoiceDialog(consulting.invoice)"
              style="margin: 0 0 0 5px"
              [pTooltip]="consulting.invoice.storno_history?.manual_description"
              *ngIf="consulting.invoice && consulting.invoice.is_storno"
              class="p-tag p-tag-rounded p-tag-outline p-tag-clickable"
              ><i class="pi pi-euro"></i>Rechnung: Storno</span
            >
          </td>
          <td>
            <a routerLink="/patients/{{ consulting.patient_id }}"
              >{{ consulting.patient.last_name }},
              {{ consulting.patient.first_name }}</a
            >
          </td>
          <td>
            {{ consulting.patient.degree_of_care }}
          </td>
          <td style="min-width: 200px">
            <div class="text-center-container">
              <i
                style="font-size: 14px"
                class="pi pi-info-circle color-gray"
                [pTooltip]="consulting.patient.full_address"
              ></i>
              {{ consulting.patient.city }}
            </div>
          </td>
          <td>
            {{ consulting.date_string }}
          </td>
          <td>
            {{ consulting.sent_type_string }}
          </td>
          <td class="table-action" style="max-width: 100px">
            <!--            <i-->
            <!--              *ngIf="consulting.status !== 'DELETED'"-->
            <!--              pTooltip="Bearbeiten"-->
            <!--              (click)="openConsultingEditDialog(consulting)"-->
            <!--              tooltipPosition="left"-->
            <!--              class="pi pi-pencil with-margin-right"-->
            <!--            ></i>-->

            <!--            <i-->
            <!--              *ngIf="consulting.status !== 'DELETED'"-->
            <!--              (click)="openConsultingConfirmDialog(consulting)"-->
            <!--              pTooltip="Bestätigen"-->
            <!--              tooltipPosition="left"-->
            <!--              class="pi pi-check"-->
            <!--            ></i>-->
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
