import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs'
import { environment } from '../../environments/environment'

@Injectable({
  providedIn: 'root',
})
export class CaregiverAnalysesService {
  constructor(private http: HttpClient) {}

  // TODO: Model erstellen
  public load(id: string | null, selectedYear: string): Observable<any> {
    return this.http.get<any>(`${environment.api}/caregiver-analyses/${id}`, {
      params: {
        year: selectedYear,
      },
    })
  }

  // TODO: model erstellen
  public changeDriveTimeManually(values: any): Observable<any> {
    return this.http.put<any>(
      `${environment.api}/caregiver-analyses/${values.id}/drive-time-manually`,
      values
    )
  }

  public releaseAccumulatedToUser(
    id: any,
    month: any,
    year: any
  ): Observable<any> {
    return this.http.put<any>(
      `${environment.api}/caregiver-analyses/${id}/release-accumulated-to-user`,
      {
        month,
        year,
      }
    )
  }

  public releaseAccumulatedForAll(
    caregiverIds: any,
    month: any,
    year: any
  ): Observable<any> {
    return this.http.put<any>(
      `${environment.api}/caregiver-analyses/release-accumulated-for-all`,
      {
        ids: JSON.stringify(caregiverIds),
        month,
        year,
      }
    )
  }

  public unreleaseAccumulatedForAll(
    caregiverIds: any,
    month: any,
    year: any
  ): Observable<any> {
    return this.http.put<any>(
      `${environment.api}/caregiver-analyses/unrelease-accumulated-for-all`,
      {
        ids: JSON.stringify(caregiverIds),
        month,
        year,
      }
    )
  }

  public unreleaseAccumulatedFromUser(
    id: any,
    month: any,
    year: any
  ): Observable<any> {
    return this.http.put<any>(
      `${environment.api}/caregiver-analyses/${id}/unrelease-accumulated-from-user`,
      {
        month,
        year,
      }
    )
  }

  // TODO: model erstellen
  public changeTimeCorrection(values: any): Observable<any> {
    return this.http.put<any>(
      `${environment.api}/caregiver-analyses/${values.id}/correction`,
      values
    )
  }

  // TODO: model erstellen
  public changePersplanTimeCorrection(values: any): Observable<any> {
    return this.http.put<any>(
      `${environment.api}/caregiver-analyses/persplan-correction`,
      values
    )
  }

  public exportUrl(caregiverId: string | null = null, year: string): string {
    return `${environment.api}/caregiver-analyses/${caregiverId}/export?year=${year}`
  }
}
