import { Component, OnInit } from '@angular/core'
import { HistoryService } from '../../../services/history.service'
import { DynamicDialogConfig } from 'primeng/dynamicdialog'

@Component({
  selector: 'app-diff-view-history-dialog',
  templateUrl: './diff-view-history-dialog.component.html',
  styleUrls: ['./diff-view-history-dialog.component.scss'],
})
export class DiffViewHistoryDialogComponent implements OnInit {
  public data: any
  public keys: string[] = []

  constructor(
    private service: HistoryService,
    public config: DynamicDialogConfig
  ) {}

  ngOnInit(): void {
    const { id, type, data } = this.config.data

    this.service.getDiffHistory(id, type, data).subscribe((data: []) => {
      this.data = data
      this.keys = Object.keys(data)
    })
  }
}
