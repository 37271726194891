import { AuthGuard } from '../guards/auth.guard'
import { UserCaregiverPhoneCallsComponent } from '../views/user-caregiver/phone-calls/user-caregiver-phone-calls.component'
import { UserCaregiverProofsComponent } from '../views/user-caregiver/proofs/user-caregiver-proofs.component'
import { UserCaregiverAnalysesComponent } from '../views/user-caregiver/analyses/user-caregiver-analyses.component'
import { UserCaregiverCalendarComponent } from '../views/user-caregiver/calendar/user-caregiver-calendar.component'
import { UserCaregiverOverviewListComponent } from '../views/user-caregiver/overview-list/user-caregiver-overview-list.component'
import { UserCaregiverDataComponent } from '../views/user-caregiver/data/user-caregiver-data.component'
import { UserCaregiverDataMasterDataComponent } from '../views/user-caregiver/data/master-data/user-caregiver-data-master-data.component'
import { UserCaregiverDataContractDataComponent } from '../views/user-caregiver/data/contract-data/user-caregiver-data-contract-data.component'
import { UserCaregiverDataQualificationsComponent } from '../views/user-caregiver/data/qualifications/user-caregiver-data-qualifications.component'
import { UserCaregiverDocumentsComponent } from '../views/user-caregiver/documents/user-caregiver-documents.component'
import { UserCaregiverOfferComponent } from '../views/user-caregiver/offer/user-caregiver-offer.component'
import {
  UserCaregiverRecommendationsComponent
} from "../views/user-caregiver/recommendations/user-caregiver-recommendations.component";
import {
  UserCaregiverHospitationComponent
} from "../views/user-caregiver/hospitation/user-caregiver-hospitation.component";

export const userCaregiverRoutes = [
  {
    path: 'telefonate',
    canActivate: [AuthGuard],
    component: UserCaregiverPhoneCallsComponent,
  },
  {
    path: 'neuer-kunde',
    canActivate: [AuthGuard],
    component: UserCaregiverOfferComponent,
  },
  {
    path: 'leistungsnachweise',
    canActivate: [AuthGuard],
    component: UserCaregiverProofsComponent,
  },
  {
    path: 'dokumente',
    canActivate: [AuthGuard],
    component: UserCaregiverDocumentsComponent,
  },
  {
    path: 'empfehlungen',
    canActivate: [AuthGuard],
    component: UserCaregiverRecommendationsComponent,
  },
  {
    path: 'hospitation',
    canActivate: [AuthGuard],
    component: UserCaregiverHospitationComponent,
  },
  {
    path: 'daten',
    canActivate: [AuthGuard],
    component: UserCaregiverDataComponent,
    children: [
      {
        path: '',
        component: UserCaregiverDataMasterDataComponent,
      },
      {
        path: 'vertragsdaten',
        component: UserCaregiverDataContractDataComponent,
      },
      {
        path: 'qualifikationen',
        component: UserCaregiverDataQualificationsComponent,
      },
    ],
  },
  {
    path: 'auswertungen',
    canActivate: [AuthGuard],
    component: UserCaregiverAnalysesComponent,
  },
  {
    path: 'kalender',
    canActivate: [AuthGuard],
    component: UserCaregiverCalendarComponent,
  },
  {
    path: 'dienstplan',
    canActivate: [AuthGuard],
    component: UserCaregiverOverviewListComponent,
  },
]
