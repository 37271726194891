import { HttpErrorResponse } from '@angular/common/http'
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core'
import { StatusCodes } from 'http-status-codes'
import {
  DialogService,
  DynamicDialogConfig,
  DynamicDialogRef,
} from 'primeng/dynamicdialog'
import { EventBusService, GlobalEvent } from 'src/app/services/eventbus.service'
import { ToastService } from 'src/app/services/toast.service'
import { NgForm } from '@angular/forms'
import { Subscription } from 'rxjs'
import { HelperService } from '../../../services/helper.service'
import { AuthService } from '../../../services/auth.service'
import { AuthModel } from '../../../models/user/auth.model'
import { DocumentService } from '../../../services/document.service'
import { TodoModel } from '../../../models/todo/todo.model'
import { TodoCommentModel } from '../../../models/todo/todo-comment.model'
import { UserSystemService } from '../../../services/user-system.service'
import { UserCaregiverService } from '../../../services/user-caregiver.service'

@Component({
  selector: 'app-appointment-change-feedback-dialog',
  templateUrl: './appointment-change-feedback-dialog.component.html',
})
export class AppointmentChangeFeedbackDialogComponent implements OnInit, OnDestroy {
  @ViewChild('form', { static: true }) form!: NgForm

  private formSubscription: Subscription | null | undefined = null
  private isDirty = false

  public loading = false
  public editAssignedMode = false
  public currentUser: AuthModel | null = new AuthModel()
  public data: any = {}
  public values = {
    comment: '',
    type: '',
  }
  public todo: TodoModel = new TodoModel()
  public editComment: TodoCommentModel | null = null
  public userSystemOptions: any[] = []

  public submitted = false
  public submittedAssigned = false
  public submittedDelete = false

  public statusOptions = [
    { label: 'OFFEN', value: 'OPEN', backgroundColor: '#3296dc' },
    { label: 'ERLEDIGT', value: 'FINISHED', backgroundColor: '#84bc3c' },
  ]

  constructor(
    private ref: DynamicDialogRef,
    public authService: AuthService,
    private config: DynamicDialogConfig,
    private helperService: HelperService,
    private dialogService: DialogService,
    private userCaregiverService: UserCaregiverService,
    public documentService: DocumentService,
    private userSystemService: UserSystemService,
    private eventbus: EventBusService,
    private toastService: ToastService
  ) {}

  ngOnInit(): void {
    this.data = this.config.data

    this.values.type = this.data.type

    if (this.data.type === 'time') {
      this.values.comment = this.data.data.caregiver_appointment_change.time_feedback
    } else {
      this.values.comment = this.data.data.caregiver_appointment_change.drive_time_feedback
    }

    this.formSubscription = this.form.valueChanges?.subscribe(() => {
      if (!this.form.pristine) {
        this.isDirty = true
      }
    })
  }

  ngOnDestroy(): void {
    this.formSubscription?.unsubscribe()
  }

  public save(): void {
    if (!this.form.form.valid) {
      this.submitted = false
      this.form.form.markAllAsTouched()
      return
    }

    this.submitted = true

    this.userCaregiverService
      .storeChangeFeedback(this.data.data.id, this.values)
      .subscribe(
        () => {
          this.submitted = false
          this.eventbus.emit(GlobalEvent.CaregiverOverviewChanged)
          this.toastService.success('Kommentar gespeichert')
          this.ref.close()
        },
        (error: HttpErrorResponse) => {
          if (error.status === StatusCodes.UNPROCESSABLE_ENTITY) {
            this.toastService.error('Bitte füllen Sie alle Pflichtfelder aus')
          } else {
            this.toastService.error(
              'Etwas ist schief gelaufen...',
              'Bitte wenden Sie sich an den Support'
            )
          }
          this.submitted = false
        }
      )
  }
}
