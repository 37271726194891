export class PatientTimeDaysModel {
  id = 0
  checked = false
  day = ''
  start_first = ''
  start_second = ''
  end_first = ''
  end_second = ''
  has_second_block = false
  patient_time_id = 0
  created_at: Date | null = null
  updated_at: Date | null = null
}
