<div class="content">
  <header class="content-header">
    <div class="content-header-menu-tabs">
      <a
        routerLink="/accounting/returns"
        routerLinkActive="active"
        [routerLinkActiveOptions]="{ exact: true }"
      >
        Erstellt
      </a>

      <a
        routerLink="/accounting/returns/edited"
        routerLinkActive="active"
        [routerLinkActiveOptions]="{ exact: true }"
      >
        In Bearbeitung
      </a>

      <a
        routerLink="/accounting/returns/finished"
        routerLinkActive="active"
        [routerLinkActiveOptions]="{ exact: true }"
      >
        Erledigt
      </a>
    </div>

    <div class="content-header-buttons">
      <button
        pButton
        label="Neuer Rückläufer"
        type="button"
        class="p-button-outlined"
        icon="pi pi-plus"
        (click)="openAddAccountingReturnDialog()"
      ></button>

      <div class="table-search-container">
        <div class="p-input-icon-left">
          <i class="pi pi-search"></i>
          <input
            pInputText
            (input)="loading = true; filterTableGlobal()"
            placeholder="Globale Suche..."
            [(ngModel)]="globalFilterValue"
          />
        </div>
      </div>
    </div>
  </header>
</div>

<app-loader *ngIf="globalFilterValue" [loading]="loading"></app-loader>

<div class="content" *ngIf="!loading && globalFilterValue">
  <div class="content-body" *ngIf="results.length == 0">
    <span class="content-no-message">Keine Rückläufer gefunden</span>
  </div>

  <div class="content-body without-padding" *ngIf="results.length > 0">
    <p-table [value]="results" [rowHover]="true">
      <ng-template pTemplate="header">
        <tr class="table-headline-highlight">
          <th style="width: 160px" pSortableColumn="status">
            Status <p-sortIcon field="status"></p-sortIcon>
          </th>
          <th style="width: 300px" pSortableColumn="patient_id">
            Patient <p-sortIcon field="patient_id"></p-sortIcon>
          </th>
          <th pSortableColumn="case_number">
            Fallnummer <p-sortIcon field="case_number"></p-sortIcon>
          </th>
          <th style="width: 90px" pSortableColumn="correction_reason">
            Grund <p-sortIcon field="correction_reason"></p-sortIcon>
          </th>
          <th pSortableColumn="performance_date_sortable">
            Leistungsmonat
            <p-sortIcon field="performance_date_sortable"></p-sortIcon>
          </th>
          <th pSortableColumn="invoice_number">
            Re-Nummer <p-sortIcon field="invoice_number"></p-sortIcon>
          </th>
          <th pSortableColumn="invoice_date">
            Re-Datum <p-sortIcon field="invoice_date"></p-sortIcon>
          </th>
          <th pSortableColumn="total_value_as_float">
            Gesamtwert <p-sortIcon field="total_value_as_float"></p-sortIcon>
          </th>
          <th style="max-width: 100px" pSortableColumn="patient.first_sepa.id">
            SEPA <p-sortIcon field="patient.first_sepa.id"></p-sortIcon>
          </th>
          <th style="width: 180px" pSortableColumn="created_at">
            Erstellt am <p-sortIcon field="created_at"></p-sortIcon>
          </th>
          <th></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-item>
        <tr class="clickable">
          <td (click)="openAddAccountingReturnDialog(item)">
            <span class="p-tag p-tag-rounded p-tag-{{ item.status }}">{{
              item.status_text
            }}</span>
          </td>
          <td>
            <a routerLink="/patients/{{ item.patient_id }}"
              >{{ item.patient.first_name }} {{ item.patient.last_name }}</a
            >
          </td>
          <td (click)="openAddAccountingReturnDialog(item)">
            {{ item.case_number }}
          </td>
          <td
            tooltipStyleClass="tooltip-wider"
            [escape]="false"
            [pTooltip]="correctionReasons[item.correction_reason]"
            (click)="openAddAccountingReturnDialog(item)"
          >
            {{ item.correction_reason }}
          </td>
          <td (click)="openAddAccountingReturnDialog(item)">
            {{ item.performance_month }} {{ item.performance_year }}
          </td>
          <td (click)="openAddAccountingReturnDialog(item)">
            {{ item.invoice_number }}
          </td>
          <td (click)="openAddAccountingReturnDialog(item)">
            {{ item.invoice_date_string }}
          </td>
          <td (click)="openAddAccountingReturnDialog(item)">
            {{ item.total_value_formatted }}
          </td>
          <td style="max-width: 100px">
            <a
              *ngIf="item.patient.first_sepa"
              target="_blank"
              [href]="
                patientService.getDocumentDownloadLink(
                  item.patient.first_sepa.uuid
                )
              "
              >SEPA
              <i
                class="pi pi-arrow-up-right color-gray"
                style="font-size: 12px"
              ></i
            ></a>
          </td>
          <td (click)="openAddAccountingReturnDialog(item)">
            {{ item.created_at | date: "dd.MM.y, H:mm" }} Uhr
          </td>
          <td class="table-action" style="width: 100px">
            <i
              (click)="openAddAccountingReturnDialog(item)"
              class="pi pi-pencil with-margin-right"
              pTooltip="Bearbeiten"
            ></i>
            <a
              *ngIf="item.media[0]"
              [href]="
                documentService.getDocumentDownloadLink(item.media[0].uuid)
              "
              target="_blank"
            >
              <i
                tooltipPosition="left"
                pTooltip="Herunterladen"
                class="pi pi-download"
              ></i>
            </a>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>

<ng-container *ngIf="!globalFilterValue">
  <router-outlet></router-outlet>
</ng-container>
