import { Component, OnInit } from '@angular/core'
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog'
import { EventBusService, GlobalEvent } from 'src/app/services/eventbus.service'
import { ToastService } from 'src/app/services/toast.service'
import { NoticeService } from '../../../services/notice.service'
import { ConfirmationService } from 'primeng/api'
import { ProofsService } from '../../../services/proofs.service'
import { SearchResultModel } from '../../../models/search/search-result.model'
import { SearchService } from '../../../services/search.service'
import { PatientModel } from '../../../models/customer-patient/patient.model'
import { StatusCodes } from 'http-status-codes'
import { HttpErrorResponse } from '@angular/common/http'

@Component({
  selector: 'app-select-patient-for-proof-dialog',
  templateUrl: './select-patient-for-proof-dialog.component.html',
})
export class SelectPatientForProofDialogComponent implements OnInit {
  submitted = false

  public loading = false

  public patients: SearchResultModel[] = []
  public patient: PatientModel = new PatientModel()

  public budget = ''
  public budgetOptions: any = []

  constructor(
    private ref: DynamicDialogRef,
    private toastService: ToastService,
    public config: DynamicDialogConfig,
    private noticeService: NoticeService,
    private searchService: SearchService,
    private eventbus: EventBusService,
    private proofService: ProofsService
  ) {}

  public ngOnInit(): void {}

  public searchPatients(event: any): void {
    this.searchService
      .findPatients(event.query.trim())
      .subscribe((results: SearchResultModel[]) => {
        this.patients = results
      })
  }

  private loadBudgets(): void {
    this.proofService
      .loadBudgetsForPatient(
        this.patient.id,
        this.config.data.month,
        this.config.data.year
      )
      .subscribe((response: any) => {
        this.budgetOptions = response
      })
  }

  public patientSelected(event: any): void {
    this.patient = event

    this.loadBudgets()
  }

  public save(): void {
    this.submitted = true

    this.proofService
      .changeIssueUploadedItem(
        this.config.data.item.id,
        this.patient.id,
        this.budget,
        this.config.data.month,
        this.config.data.year,
        this.config.data.cover_key
      )
      .subscribe(
        (response: any) => {
          this.submitted = false
          this.ref.close(response)
        },
        (error: HttpErrorResponse) => {
          if (error.status === StatusCodes.CONFLICT) {
            this.toastService.error(
              'Der Patient hat bereits einen LNW für das Budget'
            )
          } else {
            this.toastService.error(
              'Etwas ist schief gelaufen...',
              'Bitte wenden Sie sich an den Support'
            )
          }

          this.submitted = false
        }
      )
  }
}
