import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core'
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog'
import { EventBusService, GlobalEvent } from 'src/app/services/eventbus.service'
import { ToastService } from 'src/app/services/toast.service'
import { NgForm } from '@angular/forms'
import { Subscription } from 'rxjs'
import { PatientService } from '../../../services/patient.service'

@Component({
  selector: 'app-confirm-budget-dialog',
  templateUrl: './confirm-budget-dialog.component.html',
})
export class ConfirmBudgetDialogComponent implements OnInit, OnDestroy {
  @ViewChild('form', { static: true }) form!: NgForm

  private formSubscription: Subscription | null | undefined = null
  private isDirty = false

  submitted = false
  submittedDelete = false

  public patientOptions: any[] = []
  public values = {
    pkv: '',
    gkv: '',
    selbstzahler: '',
    kostentraeger: '',
    sgb_v: '',
    uv: '',
    budget_37: {
      choosen: 'Nein',
      budget: '0.00',
    },
    budget_39: {
      choosen: 'Nein',
      budget: '0.00',
    },
    budget_42: {
      choosen: 'Nein',
      budget: '0.00',
    },
    budget_45a: {
      choosen: 'Nein',
      budget: '0.00',
    },
    budget_45b: {
      choosen: 'Nein',
      budget_old: '0.00',
    },
  }

  constructor(
    private ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private patientService: PatientService,
    private eventbus: EventBusService,
    private toastService: ToastService
  ) {}

  public ngOnInit(): void {
    this.loadBudgetCalculations()

    this.formSubscription = this.form.valueChanges?.subscribe(() => {
      if (!this.form.pristine) {
        this.isDirty = true
      }
    })
  }

  ngOnDestroy(): void {
    this.formSubscription?.unsubscribe()
  }

  private loadBudgetCalculations(): void {
    this.patientService
      .loadBudgetCalculations(this.config.data.type_id)
      .subscribe((data: any) => {
        this.values.sgb_v = data.insured_via.includes('SGB V') ? 'Ja' : 'Nein'
        this.values.pkv = data.insured_via.includes('PKV') ? 'Ja' : 'Nein'
        this.values.gkv = data.insured_via.includes('GKV') ? 'Ja' : 'Nein'
        this.values.uv = data.insured_via.includes('UV') ? 'Ja' : 'Nein'
        this.values.kostentraeger = data.insured_via.includes('Kostenträger')
          ? 'Ja'
          : 'Nein'
        this.values.selbstzahler = data.insured_via.includes('Selbstzahler')
          ? 'Ja'
          : 'Nein'

        if (
          data.budget_calculations.budget_37.value.includes('€') &&
          data.request_37 === 'Ja'
        ) {
          this.values.budget_37.choosen = 'Ja'
          this.values.budget_37.budget = this.normalizeCurrency(
            data.budget_calculations.budget_37.value
          )
        }

        if (
          data.budget_calculations.budget_39.value.includes('€') &&
          data.request_39_vpf === 'Ja'
        ) {
          this.values.budget_39.choosen = 'Ja'
          this.values.budget_39.budget = this.normalizeCurrency(
            data.budget_calculations.budget_39.value
          )
        }

        if (
          data.budget_calculations.budget_42.value.includes('€') &&
          data.request_42_kzpf === 'Ja'
        ) {
          this.values.budget_42.choosen = 'Ja'
          this.values.budget_42.budget = this.normalizeCurrency(
            data.budget_calculations.budget_42.value
          )
        }

        if (
          data.budget_calculations.budget_45a.value.includes('€') &&
          data.request_45a === 'Ja'
        ) {
          this.values.budget_45a.choosen = 'Ja'
          this.values.budget_45a.budget = this.normalizeCurrency(
            data.budget_calculations.budget_45a.value
          )
        }

        if (
          data.budget_calculations.budget_45b_current.value.includes('€') &&
          data.request_45b === 'Ja'
        ) {
          this.values.budget_45b.choosen = 'Ja'

          if (data.budget_calculations.budget_45b_old.value.includes('€')) {
            this.values.budget_45b.budget_old = this.normalizeCurrency(
              data.budget_calculations.budget_45b_old.value
            )
          }
        }
      })
  }

  private normalizeCurrency(value: string): string {
    return value
      .replace('€', '')
      .replace('(', '')
      .replace(')', '')
      .replace('.', '')
      .replace(',', '.')
      .trim()
  }

  public save(): void {
    if (!this.form.form.valid) {
      this.submitted = false
      this.form.form.markAllAsTouched()
      return
    }

    this.submitted = true

    this.patientService
      .confirmBudgets(
        this.config.data.type_id,
        this.values,
        this.config.data.year
      )
      .subscribe(
        () => {
          this.submitted = false
          this.eventbus.emit(GlobalEvent.BudgetChanged)
          this.ref.close()
          this.toastService.success(
            'Budgets bestätigt',
            'Die Budgets wurden erfolgreich übernommen'
          )
        },
        () => {
          this.toastService.error(
            'Etwas ist schiefgelaufen...',
            'Bitte wenden Sie sich an den Support'
          )

          this.submitted = false
        }
      )
  }
}
