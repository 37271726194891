import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core'
import { CaregiverService } from '../../../services/caregivers.service'
import { CaregiverFormModel } from '../../../models/caregiver/caregiver-form.model'
import { ActivatedRoute, ParamMap, Router } from '@angular/router'
import { ToastService } from 'src/app/services/toast.service'
import { DirtyComponent } from 'src/app/guards/dirty-check.guard'
import { SearchService } from 'src/app/services/search.service'
import { HttpErrorResponse } from '@angular/common/http'
import { StatusCodes } from 'http-status-codes'
import { NgForm } from '@angular/forms'
import { ConfirmationService, TreeNode } from 'primeng/api'
import { Subscription } from 'rxjs'
import { CaregiverDependenciesModel } from '../../../models/caregiver/caregiver-dependencies.model'
import { TitleService } from 'src/app/services/title.service'
import { ZipcodeSearchResultModel } from '../../../models/search/zipcode-search-result.model'
import { HelperService } from '../../../services/helper.service'
import { AuthService } from '../../../services/auth.service'

@Component({
  selector: 'app-caregivers-form',
  templateUrl: './caregivers-form.component.html',
  styleUrls: ['./caregivers-form.component.scss'],
})
export class CaregiversFormComponent
  implements OnInit, OnDestroy, DirtyComponent {
  @ViewChild('form', { static: true }) form!: NgForm

  private formSubscription: Subscription | null | undefined = null
  public isDirty = false
  public isEditMode = false
  public caregiverId: string | null = null
  public submitted = false
  public loading = true
  public isEmployed = false
  public cityLoading = false
  public savedEmployedTemporary = false
  public savedEmployedUntil: null | string = null
  public formOfAddress: any = []
  public plannerUsers: any = []

  public data: CaregiverFormModel = new CaregiverFormModel()
  public dependencies: CaregiverDependenciesModel = new CaregiverDependenciesModel()

  constructor(
    private caregiverService: CaregiverService,
    private searchService: SearchService,
    private router: Router,
    private helperService: HelperService,
    private route: ActivatedRoute,
    private toastService: ToastService,
    private confirmationService: ConfirmationService,
    private titleService: TitleService,
    public authService: AuthService
  ) {}

  ngOnInit(): void {
    this.loadDependencies()

    this.formSubscription = this.form.valueChanges?.subscribe((value: any) => {
      if (!this.form.pristine) {
        this.isDirty = true
      }
    })

    this.helperService.dependencies$.subscribe((data: any) => {
      this.formOfAddress = data.form_of_address

      for (const planner of data.planner_users) {
        this.plannerUsers.push({
          id: planner.id,
          full_name: planner.user_system.full_name,
        })
      }
    })
  }

  ngOnDestroy(): void {
    this.formSubscription?.unsubscribe()
  }

  canDeactivate(): boolean {
    return this.isDirty
  }

  /**
   * Lädt den Namen der Stadt / des Orts anhand der eingegebenen PLZ.
   */
  public loadCity(): void {
    this.cityLoading = true
    this.searchService.findCity(this.data.zipcode).subscribe(
      (result: ZipcodeSearchResultModel) => {
        if (result) {
          this.data.city = result.city
          this.data.state = result.state
          this.data.county = result.county
        }

        this.cityLoading = false
      },
      () => {
        this.data.city = ''
        this.cityLoading = false
      }
    )
  }

  /**
   * Lädt alle Abhängigkeiten (Erfahrungen, Autos, etc) aus der Datenbank.
   */
  public loadDependencies(): void {
    this.caregiverService
      .dependencies()
      .subscribe((dependencies: CaregiverDependenciesModel) => {
        this.dependencies = dependencies

        this.loadDataForEditMode()
      })
  }

  /**
   * Löscht eine BK. Nur für den Super Admin möglich.
   */
  public delete(event: Event): void {
    this.confirmationService.confirm({
      target: event.target ?? undefined,
      message:
        'Betreuungskraft wirklich löschen? Dies kann nicht mehr rückgängig gemacht werden!',
      accept: () => {
        if (!window.confirm('Sind Sie sich sicher? Dies kann nicht mehr rückgängig gemacht werden!')) {
          return
        }

        this.caregiverService.delete(this.caregiverId).subscribe(
          () => {
            this.toastService.success(
              'Betreuungskraft gelöscht',
              'Die Betreuungskraft wurde erfolgreich gelöscht'
            )

            this.router.navigateByUrl(`/caregivers`)
          },
          () => {
            this.toastService.error(
              'Löschen fehlgeschlagen',
              'Die Betreuungskraft konnte nicht gelöscht werden'
            )
          }
        )
      },
      reject: () => {},
    })
  }

  /**
   * Aktiviert eine BK.
   */
  public activate(event: Event): void {
    this.confirmationService.confirm({
      target: event.target ?? undefined,
      message: 'Betreuungskraft wirklich aktivieren?',
      accept: () => {
        this.caregiverService.activate(this.caregiverId).subscribe(
          () => {
            this.toastService.success(
              'Betreuungskraft aktiviert',
              'Die Betreuungskraft wurde erfolgreich aktiviert'
            )

            this.router.navigateByUrl(`/caregivers/${this.caregiverId}`)
          },
          () => {
            this.toastService.error(
              'Aktivieren fehlgeschlagen',
              'Die Betreuungskraft konnte nicht aktiviert werden'
            )
          }
        )
      },
      reject: () => {},
    })
  }

  /**
   * Erstellt bzw. updated die Daten.
   */
  public save(): void {
    if (!this.form.form.valid) {
      this.submitted = false
      this.form.form.markAllAsTouched()
      this.toastService.error('Bitte füllen Sie alle Pflichtfelder aus')

      // Durch das timeout scrollt er auch bei div.p-invalid hoch.
      setTimeout(() => {
        const firstElementWithError = document.querySelector(
          'textarea.ng-invalid, input.ng-invalid, select.ng-invalid, div.p-invalid'
        )
        if (firstElementWithError) {
          firstElementWithError.scrollIntoView({ behavior: 'smooth' })
        }
      })

      return
    }

    this.submitted = true

    // Vor dem speichern müssen wir die Rekursiven Elemten
    // vom Tree entfernen, sonst bekommen wir einen Fehler.
    // Warum auch immer ich das in dem Projekt machen muss,
    // finde auch nicht wirklich was bei Google oder bei
    // AgendaLaw hat es eigentlich auch funktioniert!
    this.data.city_ids.forEach((cityNode: TreeNode) => {
      delete cityNode.parent

      if (cityNode.children) {
        delete cityNode.children
      }
    })

    const subscription = this.isEditMode
      ? this.caregiverService.update(this.data)
      : this.caregiverService.store(this.data)

    subscription.subscribe(
      (data: CaregiverFormModel) => {
        this.isDirty = false
        this.router.navigate(['/caregivers', data.id])
      },
      (error: HttpErrorResponse) => {
        if (error.status === StatusCodes.UNPROCESSABLE_ENTITY) {
          this.toastService.error('Bitte füllen Sie alle Pflichtfelder aus')
        } else {
          this.toastService.error(
            'Etwas ist schief gelaufen...',
            'Bitte wenden Sie sich an den Support'
          )
        }

        this.submitted = false
      }
    )
  }

  /**
   * Lädt die Betreuungskraft Daten.
   */
  private loadCaregiver(): void {
    this.caregiverService
      .loadForEditMode(this.caregiverId)
      .subscribe((data: CaregiverFormModel) => {
        this.data = data

        this.data.experience_ids = this.data.experiences.map(
          (experience) => experience.id
        )

        this.data.activity_ids = this.data.activities.map(
          (activity) => activity.id
        )

        this.savedEmployedTemporary = data.employed_temporary
        this.savedEmployedUntil = data.employed_until_date_string
        this.isEmployed = data.current_status?.status === 'EMPLOYMENT'

        this.loading = false
      })
  }

  /**
   * Lädt die Daten falls wir im bearbeiten Modus sind.
   */
  private loadDataForEditMode(): void {
    this.loading = true

    this.route.paramMap.subscribe((params: ParamMap) => {
      this.caregiverId = params.get('id')
      this.isEditMode = params.has('id')

      this.titleService.setTitle(
        this.isEditMode ? 'Betreuungskraft bearbeiten' : 'Neue Betreuungskraft'
      )

      if (this.isEditMode) {
        this.loadCaregiver()
      } else {
        this.loading = false
      }
    })
  }
}
