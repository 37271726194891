<form #form="ngForm" class="dialog-form">
  <div class="dialog-form-body">
    <!--    <div class="p-fluid p-formgrid p-grid">-->
    <!--      <div class="p-field p-col-6">-->
    <!--        <label>PKV</label>-->
    <!--        <div class="p-formgroup-inline">-->
    <!--          <div class="p-field-radiobutton">-->
    <!--            <p-radioButton-->
    <!--              label="Ja"-->
    <!--              name="pkv"-->
    <!--              [(ngModel)]="values.pkv"-->
    <!--              value="Ja"-->
    <!--            ></p-radioButton>-->
    <!--          </div>-->
    <!--          <div class="p-field-radiobutton">-->
    <!--            <p-radioButton-->
    <!--              label="Nein"-->
    <!--              name="pkv"-->
    <!--              [(ngModel)]="values.pkv"-->
    <!--              value="Nein"-->
    <!--            ></p-radioButton>-->
    <!--          </div>-->
    <!--        </div>-->
    <!--      </div>-->

    <!--      <div class="p-field p-col-6">-->
    <!--        <label>GKV</label>-->
    <!--        <div class="p-formgroup-inline">-->
    <!--          <div class="p-field-radiobutton">-->
    <!--            <p-radioButton-->
    <!--              label="Ja"-->
    <!--              name="gkv"-->
    <!--              [(ngModel)]="values.gkv"-->
    <!--              value="Ja"-->
    <!--            ></p-radioButton>-->
    <!--          </div>-->
    <!--          <div class="p-field-radiobutton">-->
    <!--            <p-radioButton-->
    <!--              label="Nein"-->
    <!--              name="gkv"-->
    <!--              [(ngModel)]="values.gkv"-->
    <!--              value="Nein"-->
    <!--            ></p-radioButton>-->
    <!--          </div>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </div>-->

    <!--    <div class="p-fluid p-formgrid p-grid">-->
    <!--      <div class="p-field p-col-6">-->
    <!--        <label>Selbstzahler</label>-->
    <!--        <div class="p-formgroup-inline">-->
    <!--          <div class="p-field-radiobutton">-->
    <!--            <p-radioButton-->
    <!--              label="Ja"-->
    <!--              name="selbstzahler"-->
    <!--              [(ngModel)]="values.selbstzahler"-->
    <!--              value="Ja"-->
    <!--            ></p-radioButton>-->
    <!--          </div>-->
    <!--          <div class="p-field-radiobutton">-->
    <!--            <p-radioButton-->
    <!--              label="Nein"-->
    <!--              name="selbstzahler"-->
    <!--              [(ngModel)]="values.selbstzahler"-->
    <!--              value="Nein"-->
    <!--            ></p-radioButton>-->
    <!--          </div>-->
    <!--        </div>-->
    <!--      </div>-->

    <!--      <div class="p-field p-col-6">-->
    <!--        <label>SGB V</label>-->
    <!--        <div class="p-formgroup-inline">-->
    <!--          <div class="p-field-radiobutton">-->
    <!--            <p-radioButton-->
    <!--              label="Ja"-->
    <!--              name="sgb_v"-->
    <!--              [(ngModel)]="values.sgb_v"-->
    <!--              value="Ja"-->
    <!--            ></p-radioButton>-->
    <!--          </div>-->
    <!--          <div class="p-field-radiobutton">-->
    <!--            <p-radioButton-->
    <!--              label="Nein"-->
    <!--              name="sgb_v"-->
    <!--              [(ngModel)]="values.sgb_v"-->
    <!--              value="Nein"-->
    <!--            ></p-radioButton>-->
    <!--          </div>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </div>-->

    <!--    <div class="p-fluid p-formgrid p-grid">-->
    <!--      <div class="p-field p-col-6">-->
    <!--        <label>Unfallkasse</label>-->
    <!--        <div class="p-formgroup-inline">-->
    <!--          <div class="p-field-radiobutton">-->
    <!--            <p-radioButton-->
    <!--              label="Ja"-->
    <!--              name="uv"-->
    <!--              [(ngModel)]="values.uv"-->
    <!--              value="Ja"-->
    <!--            ></p-radioButton>-->
    <!--          </div>-->
    <!--          <div class="p-field-radiobutton">-->
    <!--            <p-radioButton-->
    <!--              label="Nein"-->
    <!--              name="uv"-->
    <!--              [(ngModel)]="values.uv"-->
    <!--              value="Nein"-->
    <!--            ></p-radioButton>-->
    <!--          </div>-->
    <!--        </div>-->
    <!--      </div>-->

    <!--      <div class="p-field p-col-6">-->
    <!--        <label>Kostenträger</label>-->
    <!--        <div class="p-formgroup-inline">-->
    <!--          <div class="p-field-radiobutton">-->
    <!--            <p-radioButton-->
    <!--              label="Ja"-->
    <!--              name="kostentraeger"-->
    <!--              [(ngModel)]="values.kostentraeger"-->
    <!--              value="Ja"-->
    <!--            ></p-radioButton>-->
    <!--          </div>-->
    <!--          <div class="p-field-radiobutton">-->
    <!--            <p-radioButton-->
    <!--              label="Nein"-->
    <!--              name="kostentraeger"-->
    <!--              [(ngModel)]="values.kostentraeger"-->
    <!--              value="Nein"-->
    <!--            ></p-radioButton>-->
    <!--          </div>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </div>-->

    <div class="p-fluid p-formgrid p-grid">
      <div class="p-field p-col-6">
        <label>§37</label>
        <div class="p-formgroup-inline">
          <div class="p-field-radiobutton">
            <p-radioButton
              label="Ja"
              name="budget_37"
              [(ngModel)]="values.budget_37.choosen"
              value="Ja"
            ></p-radioButton>
          </div>
          <div class="p-field-radiobutton">
            <p-radioButton
              label="Nein"
              name="budget_37"
              [(ngModel)]="values.budget_37.choosen"
              value="Nein"
            ></p-radioButton>
          </div>
        </div>
      </div>
      <div class="p-field p-col-6" *ngIf="values.budget_37.choosen === 'Ja'">
        <label for="budget_37_value">Budget</label>
        <p-inputNumber
          mode="currency"
          currency="EUR"
          locale="de-DE"
          id="budget_37_value"
          name="budget_37_value"
          [(ngModel)]="values.budget_37.budget"
          [required]="true"
          #budget_37="ngModel"
          [class.p-invalid]="
            budget_37.invalid && (budget_37.dirty || budget_37.touched)
          "
        ></p-inputNumber>
      </div>
    </div>

    <div class="p-fluid p-formgrid p-grid">
      <div class="p-field p-col-6">
        <label>§39</label>
        <div class="p-formgroup-inline">
          <div class="p-field-radiobutton">
            <p-radioButton
              label="Ja"
              name="budget_39"
              [(ngModel)]="values.budget_39.choosen"
              value="Ja"
            ></p-radioButton>
          </div>
          <div class="p-field-radiobutton">
            <p-radioButton
              label="Nein"
              name="budget_39"
              [(ngModel)]="values.budget_39.choosen"
              value="Nein"
            ></p-radioButton>
          </div>
        </div>
      </div>
      <div class="p-field p-col-6" *ngIf="values.budget_39.choosen === 'Ja'">
        <label for="budget_39_value">Budget</label>
        <p-inputNumber
          mode="currency"
          currency="EUR"
          locale="de-DE"
          id="budget_39_value"
          name="budget_39_value"
          [(ngModel)]="values.budget_39.budget"
          [required]="true"
          #budget_39="ngModel"
          [class.p-invalid]="
            budget_39.invalid && (budget_39.dirty || budget_39.touched)
          "
        ></p-inputNumber>
      </div>
    </div>

    <div class="p-fluid p-formgrid p-grid">
      <div class="p-field p-col-6">
        <label>§42</label>
        <div class="p-formgroup-inline">
          <div class="p-field-radiobutton">
            <p-radioButton
              label="Ja"
              name="budget_42"
              [(ngModel)]="values.budget_42.choosen"
              value="Ja"
            ></p-radioButton>
          </div>
          <div class="p-field-radiobutton">
            <p-radioButton
              label="Nein"
              name="budget_42"
              [(ngModel)]="values.budget_42.choosen"
              value="Nein"
            ></p-radioButton>
          </div>
        </div>
      </div>
      <div class="p-field p-col-6" *ngIf="values.budget_42.choosen === 'Ja'">
        <label for="budget_42_value">Budget</label>
        <p-inputNumber
          mode="currency"
          currency="EUR"
          locale="de-DE"
          id="budget_42_value"
          name="budget_42_value"
          [(ngModel)]="values.budget_42.budget"
          [required]="true"
          #budget_42="ngModel"
          [class.p-invalid]="
            budget_42.invalid && (budget_42.dirty || budget_42.touched)
          "
        ></p-inputNumber>
      </div>
    </div>

    <div class="p-fluid p-formgrid p-grid">
      <div class="p-field p-col-6">
        <label>§45a</label>
        <div class="p-formgroup-inline">
          <div class="p-field-radiobutton">
            <p-radioButton
              label="Ja"
              name="budget_45a"
              [(ngModel)]="values.budget_45a.choosen"
              value="Ja"
            ></p-radioButton>
          </div>
          <div class="p-field-radiobutton">
            <p-radioButton
              label="Nein"
              name="budget_45a"
              [(ngModel)]="values.budget_45a.choosen"
              value="Nein"
            ></p-radioButton>
          </div>
        </div>
      </div>
      <div class="p-field p-col-6" *ngIf="values.budget_45a.choosen === 'Ja'">
        <label for="budget_45a_value">Budget</label>
        <p-inputNumber
          mode="currency"
          currency="EUR"
          locale="de-DE"
          id="budget_45a_value"
          name="budget_45a_value"
          [(ngModel)]="values.budget_45a.budget"
          [required]="true"
          #budget_45a="ngModel"
          [class.p-invalid]="
            budget_45a.invalid && (budget_45a.dirty || budget_45a.touched)
          "
        ></p-inputNumber>
      </div>
    </div>

    <div class="p-fluid p-formgrid p-grid">
      <div class="p-field p-col-6">
        <label>§45b Alt</label>
        <div class="p-formgroup-inline">
          <div class="p-field-radiobutton">
            <p-radioButton
              label="Ja"
              name="budget_45b"
              [(ngModel)]="values.budget_45b.choosen"
              value="Ja"
            ></p-radioButton>
          </div>
          <div class="p-field-radiobutton">
            <p-radioButton
              label="Nein"
              name="budget_45b"
              [(ngModel)]="values.budget_45b.choosen"
              value="Nein"
            ></p-radioButton>
          </div>
        </div>
      </div>
      <div class="p-field p-col-6" *ngIf="values.budget_45b.choosen === 'Ja'">
        <label for="budget_45b_value_old">Budget</label>
        <p-inputNumber
          mode="currency"
          currency="EUR"
          locale="de-DE"
          id="budget_45b_value_old"
          name="budget_45b_value_old"
          [(ngModel)]="values.budget_45b.budget_old"
          [required]="true"
          #budget_45b_old="ngModel"
          [class.p-invalid]="
            budget_45b_old.invalid &&
            (budget_45b_old.dirty || budget_45b_old.touched)
          "
        ></p-inputNumber>
      </div>
    </div>

    <div class="p-fluid p-formgrid p-grid">
      <div class="p-field p-col-6">
        <label>§45b Laufend</label>
        <div class="p-formgroup-inline">
          <div class="p-field-radiobutton">
            <p-radioButton
              label="Ja"
              name="budget_45b"
              [(ngModel)]="values.budget_45b.choosen"
              value="Ja"
            ></p-radioButton>
          </div>
          <div class="p-field-radiobutton">
            <p-radioButton
              label="Nein"
              name="budget_45b"
              [(ngModel)]="values.budget_45b.choosen"
              value="Nein"
            ></p-radioButton>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="dialog-form-footer">
    <i></i>
    <button
      pButton
      [label]="'Budgets für ' + config.data.year + ' bestätigen'"
      type="submit"
      (click)="save()"
      [disabled]="submitted"
      [icon]="submitted ? 'pi pi-spin pi-spinner' : 'pi pi-check'"
    ></button>
  </div>
</form>
