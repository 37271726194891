import { AuthGuard } from '../guards/auth.guard'
import { OfferComponent } from '../views/offer/offer.component'
import { DirtyCheckGuard } from '../guards/dirty-check.guard'

export const offerRoutes = [
  {
    path: 'offer',
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: OfferComponent,
        canDeactivate: [DirtyCheckGuard],
      },
      {
        path: ':id',
        component: OfferComponent,
        canDeactivate: [DirtyCheckGuard],
      },
    ],
  },
]
