<div class="content-actions">
  <h2>
    <i class="tio">users_switch</i>
    Bewerber
  </h2>
</div>

<header class="content-header-tabs">
  <a
    routerLink="/applicants"
    routerLinkActive="active"
    [routerLinkActiveOptions]="{ exact: true }"
  >
    Bewerber
  </a>
  <a
    routerLink="/applicants/deactivated"
    routerLinkActive="active"
    [routerLinkActiveOptions]="{ exact: true }"
  >
    KI
  </a>
</header>

<router-outlet></router-outlet>
