<form #form="ngForm" class="dialog-form">
  <div class="dialog-form-body" *ngIf="!loading">
    <div class="dialog-detail-information-body">
      <div class="dialog-detail-information">
        <div class="dialog-detail-key"><span>Tag</span></div>
        <div class="dialog-detail-value">
          <div>{{ data.date }} ({{ data.day }})</div>
        </div>
      </div>
      <div class="dialog-detail-information">
        <div class="dialog-detail-key"><span>Einsatz</span></div>
        <div class="dialog-detail-value">
          <div>
            {{ data.data.real_from_h }} - {{ data.data.real_to_h }} ({{
              data.data.real_hours_as_float
            }})
          </div>
        </div>
      </div>
      <div class="dialog-detail-information">
        <div class="dialog-detail-key"><span>Patient</span></div>
        <div class="dialog-detail-value">
          <div>
            {{ data.data.patient.first_name }} {{ data.data.patient.last_name }}
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    class="dialog-form-footer dialog-form-footer-textarea"
    *ngIf="!loading"
  >
    <textarea
      *ngIf="!editComment"
      id="comment"
      name="comment"
      [readOnly]="data.readonly"
      placeholder="Kommentar..."
      [(ngModel)]="values.comment"
      [rows]="2"
      [autofocus]="true"
      [required]="true"
      pInputTextarea
    ></textarea>

    <!--    <textarea-->
    <!--      *ngIf="editComment"-->
    <!--      id="comment_edit"-->
    <!--      name="comment_edit"-->
    <!--      [(ngModel)]="values.comment"-->
    <!--      [rows]="2"-->
    <!--      [required]="true"-->
    <!--      pInputTextarea-->
    <!--      #comment_edit="ngModel"-->
    <!--      [class.p-invalid]="-->
    <!--        comment_edit.invalid && (comment_edit.dirty || comment_edit.touched)-->
    <!--      "-->
    <!--    ></textarea>-->

    <div class="dialog-form-footer-actions" *ngIf="!data.readonly">
      <i></i>
      <!--      <button-->
      <!--        *ngIf="editComment"-->
      <!--        pButton-->
      <!--        label="Abbrechen"-->
      <!--        class="p-button-text button-without-padding"-->
      <!--        type="button"-->
      <!--        (click)="cancelEditMode()"-->
      <!--        icon="pi pi-times"-->
      <!--      ></button>-->

      <button
        *ngIf="!editComment"
        (click)="save()"
        pButton
        label="Kommentar speichern"
        type="button"
        [disabled]="submitted || !values.comment"
        [icon]="submitted ? 'pi pi-spin pi-spinner' : 'pi pi-envelope'"
      ></button>

      <!--      <button-->
      <!--        *ngIf="editComment"-->
      <!--        (click)="update()"-->
      <!--        pButton-->
      <!--        label="Kommentar bearbeiten"-->
      <!--        type="button"-->
      <!--        [disabled]="submitted"-->
      <!--        [icon]="submitted ? 'pi pi-spin pi-spinner' : 'pi pi-check'"-->
      <!--      ></button>-->
    </div>
  </div>
</form>
