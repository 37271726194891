<app-loader [loading]="loading"></app-loader>

<div class="content-actions">
  <h2>
    <i class="tio">calendar_month</i>
    Kalender
  </h2>

  <button
    pButton
    (click)="openDateDialog()"
    label="Abwesenheitswunsch"
    type="button"
    icon="pi pi-plus"
    iconPos="left"
  ></button>
</div>

<div class="content" *ngIf="!loading">
  <header class="content-header">
    <div class="content-header-buttons">
      <span>
        <i (click)="goToPreviousYear()" class="pi pi-chevron-left"></i>
        <p-dropdown
          inputId="year"
          name="year"
          (onChange)="loadData(false)"
          [options]="yearOptions"
          [(ngModel)]="year"
        ></p-dropdown>

        <i
          (click)="goToNextYear()"
          class="pi pi-chevron-right"
          style="margin: 0 10px 0 -10px"
        ></i>
      </span>
    </div>
  </header>

  <div
    class="content-body without-padding scrollable-horizontal"
    [class.content-loading]="contentLoading"
  >
    <div class="performance-months-container">
      <div class="performance-days"><strong></strong></div>
      <div class="performance-months-headlines">
        <div><strong>Jan</strong></div>
        <div><strong>Feb</strong></div>
        <div><strong>Mrz</strong></div>
        <div><strong>Apr</strong></div>
        <div><strong>Mai</strong></div>
        <div><strong>Jun</strong></div>
        <div><strong>Jul</strong></div>
        <div><strong>Aug</strong></div>
        <div><strong>Sep</strong></div>
        <div><strong>Okt</strong></div>
        <div><strong>Nov</strong></div>
        <div><strong>Dez</strong></div>
      </div>
    </div>
    <div class="performance-lists">
      <div class="performance-list" *ngFor="let day of calendarDays">
        <div
          class="performance-day"
          [class.background-main1]="currentHover === day"
          [class.color-white]="currentHover === day"
        >
          {{ day }}
        </div>
        <div class="performance-months">
          <div
            class="performance-month"
            (mouseleave)="setHover()"
            (mouseenter)="setHover(day)"
            *ngFor="let month of calendarData[day]; let i = index"
          >
            <!-- Wenn nichts an diesem Tag ansteht, zeigen wir nur den Namen des Tages an -->
            <div
              *ngIf="month.data.length === 0"
              [class.background-hightlight-gray]="month.is_weekend"
            >
              <i class="calendar-week-number" *ngIf="month.week_year">{{
                month.week_year
              }}</i>

              <span>{{ month.day }}</span>
            </div>

            <!-- Ansonsten zeigen wir hier alles mögliche an -->
            <div
              [class.background-hightlight-yellow]="
                item.type === 'TYPE_HOLIDAY'
              "
              [class.background-blue]="item.type === 'TYPE_APPOINTMENT'"
              [class.background-hightlight-blue]="item.type === 'TYPE_VACATION'"
              [class.background-hightlight-gray]="item.type === 'TYPE_INTERN'"
              [class.background-hightlight-alert]="item.type === 'TYPE_ILL'"
              [class.background-hightlight-main1]="
                item.type === 'TYPE_FREE_TIME_REQUEST' ||
                item.type === 'TYPE_TRAINING' ||
                item.type === 'TYPE_OTHER'
              "
              *ngFor="let item of month.data"
              [pTooltip]="item.tooltip || persplanData[month.date]"
              [escape]="false"
              tooltipPosition="bottom"
              [tooltipStyleClass]="
                i < 7 ? 'tooltip-wider-right' : 'tooltip-wider-left'
              "
              (mouseenter)="getPersplanForCaregiver(item.type, month.date)"
            >
              <i
                class="calendar-week-number"
                [class.color-white]="item.type === 'TYPE_APPOINTMENT'"
                *ngIf="month.week_year"
              >
                {{ month.week_year }}
              </i>
              <span *ngIf="item.type !== 'TYPE_APPOINTMENT'">
                {{ month.day }} <span>{{ item.value }}</span>
              </span>
              <span
                class="color-white"
                *ngIf="item.type === 'TYPE_APPOINTMENT'"
                >{{ month.day }}</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
