import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, ParamMap, Router } from '@angular/router'
import { HistoryService } from '../../../../../services/history.service'
import { LatestChangeModel } from '../../../../../models/history/latest-change.model'
import { DialogService } from 'primeng/dynamicdialog'
import { PhoneCallService } from '../../../../../services/phone-call.service'
import { RolePermissionService } from '../../../../../services/role-permission.service'
import { RoleModel } from '../../../../../models/permission/role.model'

@Component({
  selector: 'app-roles-detail-general',
  templateUrl: './roles-detail-general.component.html',
})
export class RolesDetailGeneralComponent implements OnInit {
  public loading = true
  public role: RoleModel = new RoleModel()
  public latestChanges: LatestChangeModel = new LatestChangeModel()

  constructor(
    private rolePermissionService: RolePermissionService,
    private route: ActivatedRoute,
    private dialogService: DialogService,
    public phoneCallService: PhoneCallService,
    private router: Router,
    private historyService: HistoryService
  ) {}

  ngOnInit(): void {
    this.loadRole()
  }

  public getLatestChange(key: string, value: any): void {
    if (this.latestChanges[key]) {
      return
    }

    const id = this.role.id ? this.role.id.toString() : ''
    value = value ? value.toString() : ''

    this.historyService
      .getLatestChange(id, 'role', key, value)
      .subscribe((date: string) => {
        this.latestChanges[key] = date
      })
  }

  public loadRole(): void {
    this.loading = true

    this.route.paramMap.subscribe((params: ParamMap) => {
      this.rolePermissionService
        .role(params.get('id'))
        .subscribe((data: RoleModel) => {
          this.role = data

          this.loading = false
        })
    })
  }
}
