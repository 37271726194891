<form #form="ngForm" class="dialog-form">
  <div class="dialog-sidebar space-between" *ngIf="data.isEdit">
    <div class="p-fluid p-formgrid p-grid without-border">
      <div class="p-field p-col-12">
        <div class="dialog-text-link">
          <span (click)="openAddDocumentDialog()">Dokument hochladen</span>
        </div>
      </div>
    </div>
    <div class="p-fluid p-formgrid p-grid without-border">
      <div class="p-field p-col-12">
        <label class="highlight-label">Hochgeladene Dokumente</label>
        <div class="dialog-no-message" *ngIf="values.documents.length === 0">
          Keine Dokumente hochgeladen
        </div>
        <div class="document-list" *ngFor="let document of values.documents">
          <span
            >{{ document.name }}
            <a
              [href]="documentService.getDocumentDownloadLink(document.uuid)"
              target="_blank"
              >(ansehen)</a
            ></span
          >
        </div>
      </div>
    </div>
  </div>

  <div class="dialog-form-body">
    <div class="p-fluid p-formgrid p-grid without-border">
      <div class="p-field p-col-6">
        <label for="date">Schaden am *</label>
        <p-calendar
          [firstDayOfWeek]="1"
          [showWeek]="true"
          inputId="date"
          name="date"
          dateFormat="dd.mm.yy"
          appendTo="body"
          [(ngModel)]="values.car_damage.date"
          dataType="string"
          [showIcon]="true"
          [required]="true"
          #date="ngModel"
          [showButtonBar]="true"
          [class.p-invalid]="date.invalid && (date.dirty || date.touched)"
        ></p-calendar>
      </div>

      <div class="p-field p-col-6">
        <label for="mileage">Kilometerstand</label>
        <p-inputNumber
          inputId="mileage"
          name="mileage"
          locale="de-DE"
          [(ngModel)]="values.car_damage.mileage"
        ></p-inputNumber>
      </div>
    </div>

    <div class="p-fluid p-formgrid p-grid without-border">
      <div class="p-field p-col-12">
        <label for="guilt">Schuld am Schaden *</label>
        <p-dropdown
          name="guilt"
          inputId="guilt"
          placeholder="Bitte auswählen"
          [(ngModel)]="values.car_damage.guilt"
          [options]="guiltOptions"
          #guilt="ngModel"
          [required]="true"
          [class.p-invalid]="guilt.invalid && (guilt.dirty || guilt.touched)"
        ></p-dropdown>
      </div>
    </div>

    <div class="p-fluid p-formgrid p-grid without-border">
      <div class="p-field p-col-12">
        <label for="caregivers"
          >Beteiligte Betreuungskraft
          {{ values.car_damage.guilt === "CAREGIVER" ? "*" : "" }}</label
        >

        <p-autoComplete
          field="full_name"
          inputId="caregiver"
          name="caregiver"
          appendTo="body"
          [forceSelection]="true"
          [(ngModel)]="values.car_damage.caregiver"
          [suggestions]="caregivers"
          (completeMethod)="searchCaregivers($event)"
          [required]="values.car_damage.guilt === 'CAREGIVER'"
          #caregiver="ngModel"
          [class.p-invalid]="
            caregiver.invalid && (caregiver.dirty || caregiver.touched)
          "
        >
          <ng-template let-caregiver pTemplate="item">
            <div>{{ caregiver.full_name }} ({{ caregiver.status_string }})</div>
          </ng-template>
        </p-autoComplete>
      </div>
    </div>

    <div class="p-fluid p-formgrid p-grid without-border">
      <div class="p-field p-col-12">
        <label for="comment">Bemerkung</label>
        <textarea
          id="comment"
          name="comment"
          [(ngModel)]="values.car_damage.comment"
          [rows]="4"
          pInputTextarea
        ></textarea>
      </div>
    </div>

    <div class="p-fluid p-formgrid p-grid without-border">
      <div class="p-field p-col-12">
        <label for="tracked_by">Aufgenommen von *</label>
        <p-dropdown
          [filter]="true"
          scrollHeight="360px"
          inputId="tracked_by"
          name="tracked_by"
          [(ngModel)]="values.car_damage.tracked_by_id"
          appendTo="body"
          placeholder="Bitte auswählen"
          [resetFilterOnHide]="true"
          [options]="userSystemOptions"
          optionLabel="full_name"
          optionValue="id"
          [required]="true"
          #tracked_by="ngModel"
          [class.p-invalid]="
            tracked_by.invalid && (tracked_by.dirty || tracked_by.touched)
          "
        ></p-dropdown>
      </div>
    </div>
  </div>

  <div class="dialog-form-footer">
    <i *ngIf="!data.isEdit"></i>
    <i
      class="pi pi-trash"
      *ngIf="data.isEdit"
      [class.pi-trash]="!submittedDelete"
      [class.pi-spin]="submittedDelete"
      [class.pi-spinner]="submittedDelete"
      tooltipPosition="left"
      tooltipZIndex="20000"
      [pTooltip]="submittedDelete ? 'Wird gelöscht' : 'Schaden löschen'"
      (click)="remove($event)"
    ></i>

    <button
      (click)="save()"
      pButton
      [label]="data.isEdit ? 'Speichern' : 'Erstellen'"
      type="button"
      [disabled]="submitted"
      [icon]="submitted ? 'pi pi-spin pi-spinner' : 'pi pi-check'"
    ></button>
  </div>
</form>

<p-confirmPopup></p-confirmPopup>
