import { CarsDetailModel } from '../car/cars-detail.model'
import { SelectItem } from 'primeng/api'
import { ZipcodeSearchResultModel } from '../search/zipcode-search-result.model'

export class CaregiverDependenciesModel {
  job_scopes: SelectItem[] = []
  job_days: SelectItem[] = []
  salaries: SelectItem[] = []
  vacation_days: SelectItem[] = []
  glove_sizes: SelectItem[] = []
  clothes_sizes: SelectItem[] = []
  times: SelectItem[] = []
  experiences: ExperienceModel[] = []
  activities: ActivityModel[] = []
  counties: CountyModel[] = []
  cars: CarsDetailModel[] = []
}

export class ExperienceModel {
  id: number = 0
  name = ''
}

export class ActivityModel {
  id: number = 0
  name = ''
}

export class CountyModel {
  id: number = 0
  county = ''
  label = ''
  children: ZipcodeSearchResultModel[] = []
  zipcodes: ZipcodeSearchResultModel[] = []
}
