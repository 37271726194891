import { Component, Input, OnInit } from '@angular/core'
import { HistoryService } from '../../../services/history.service'
import { DynamicDialogConfig } from 'primeng/dynamicdialog'
import { DiffViewModel } from '../../../models/history/diff-view.model'

@Component({
  selector: 'app-diff-view-dialog',
  templateUrl: './diff-view-dialog.component.html',
  styleUrls: ['./diff-view-dialog.component.scss'],
})
export class DiffViewDialogComponent implements OnInit {
  public loading = true

  public data: DiffViewModel = {
    content: [],
    content_before: [],
  }

  constructor(
    private service: HistoryService,
    public config: DynamicDialogConfig
  ) {}

  ngOnInit(): void {
    this.service
      .getDiff(this.config.data.id)
      .subscribe((data: DiffViewModel) => {
        this.data = data

        this.loading = false
      })
  }
}
