import { Component, OnInit, ViewChild } from '@angular/core'
import {
  DialogService,
  DynamicDialogConfig,
  DynamicDialogRef,
} from 'primeng/dynamicdialog'
import { EventBusService, GlobalEvent } from 'src/app/services/eventbus.service'
import { ToastService } from 'src/app/services/toast.service'
import * as dayjs from 'dayjs'
import { SearchResultModel } from '../../../models/search/search-result.model'
import { NgForm } from '@angular/forms'
import { CaregiverService } from '../../../services/caregivers.service'
import { HttpErrorResponse } from '@angular/common/http'
import { StatusCodes } from 'http-status-codes'
import { ConsultingService } from '../../../services/consulting.service'
import { InvoiceDialogComponent } from '../invoice-dialog/invoice-dialog.component'
import { DocumentService } from '../../../services/document.service'
import { InvoiceNewDialogComponent } from '../invoice-new-dialog/invoice-new-dialog.component'

@Component({
  selector: 'app-consulting-confirm-dialog',
  templateUrl: './consulting-confirm-dialog.component.html',
})
export class ConsultingConfirmDialogComponent implements OnInit {
  @ViewChild('form', { static: true }) form!: NgForm

  public patients: SearchResultModel[] = []
  public receiverOptions: any[] = []
  public ccOptions: any[] = []
  public clickedOnPreview = false
  private selectedFile: any = null

  public isEdit = false
  public hasInvoice = false

  values = {
    comment: '',
    home_visit_time: '',
    home_visit_date_string: '',
    selected_file: '',
  }

  public tempFileUrl = ''
  public fileUploading = false

  submitted = false
  submittedDelete = false

  constructor(
    private ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private dialogService: DialogService,
    private caregiverService: CaregiverService,
    private documentService: DocumentService,
    private consultingService: ConsultingService,
    private eventbus: EventBusService,
    private toastService: ToastService
  ) {}

  public setSelectedFile(event: any): any {
    this.fileUploading = true

    this.selectedFile = event.target.files[0]

    const formData = new FormData()
    formData.append('file', this.selectedFile)

    this.documentService.uploadTemp(formData).subscribe((file: any) => {
      this.tempFileUrl = file.name

      this.fileUploading = false
    })
  }

  public openTempMedia(): void {
    this.clickedOnPreview = true

    window.open(this.documentService.getTempDownloadLink(this.tempFileUrl))
  }

  public ngOnInit(): void {
    const consulting = this.config.data.consulting

    this.isEdit = consulting.media[0]
    this.hasInvoice = consulting.invoice !== null

    this.values.comment = consulting.comment
    this.values.home_visit_date_string = dayjs(consulting.date).format(
      'DD.MM.YYYY'
    )
    this.values.home_visit_time = dayjs(consulting.date).format('HH:mm')
  }

  public upload(): void {
    if (!this.form.form.valid) {
      this.submitted = false
      this.form.form.markAllAsTouched()
      return
    }

    this.submitted = true

    const formData = new FormData()
    formData.append('file', this.selectedFile)
    formData.append(
      'home_visit_date_string',
      this.values.home_visit_date_string
    )
    formData.append('home_visit_time', this.values.home_visit_time)
    formData.append('comment', this.values.comment || '')

    this.consultingService
      .uploadConfirm(this.config.data.consulting.id, formData)
      .subscribe(
        (invoice: any) => {
          this.submitted = false
          this.eventbus.emit(GlobalEvent.ConsultingChanged)
          this.ref.close()
          this.toastService.success(
            'Beratungseinsatz bestätigt',
            'Der Termin wurde erfolgreich bestätigt'
          )

          if (invoice) {
            this.openInvoiceDialog(invoice)
          }
        },
        (error: HttpErrorResponse) => {
          if (error.status === StatusCodes.UNPROCESSABLE_ENTITY) {
            this.toastService.error('Bitte füllen Sie alle Pflichtfelder aus')
          } else {
            this.toastService.error(
              'Etwas ist schief gelaufen...',
              'Bitte wenden Sie sich an den Support'
            )
          }
          this.submitted = false
        }
      )
  }

  private openInvoiceDialog(invoice: any): void {
    this.dialogService.open(InvoiceNewDialogComponent, {
      header: 'Rechnung',
      width: '96%',
      height: '90%',
      styleClass: 'dialog-container',
      data: {
        invoice,
      },
    })
  }
}
