<form #form="ngForm" class="dialog-form">
  <div class="dialog-form-body">
    <div
      class="p-field"
      *ngIf="
        sepaDocuments.sepa_first_patient_uuid ||
        sepaDocuments.sepa_second_patient_uuid
      "
    >
      <label>Link zur SEPA</label>
      <a
        [href]="
          patientService.getDocumentDownloadLink(
            sepaDocuments.sepa_first_patient_uuid
          )
        "
        target="_blank"
        *ngIf="sepaDocuments.sepa_first_patient_uuid"
        >Dokument anzeigen (Patient 1)</a
      >
      <br />
      <a
        [href]="
          patientService.getDocumentDownloadLink(
            sepaDocuments.sepa_second_patient_uuid
          )
        "
        target="_blank"
        *ngIf="sepaDocuments.sepa_second_patient_uuid"
        >Dokument anzeigen (Patient 2)</a
      >
    </div>

    <div class="p-field">
      <label for="notice">Notiz Buchhaltung</label>
      <textarea
        id="notice"
        name="notice"
        [rows]="15"
        [(ngModel)]="notice_accounting"
        pInputTextarea
      ></textarea>
    </div>
  </div>
  <div class="dialog-form-footer">
    <i *ngIf="!notice_accounting"></i>
    <i
      class="pi pi-trash"
      *ngIf="notice_accounting"
      [class.pi-trash]="!submittedDelete"
      [class.pi-spin]="submittedDelete"
      [class.pi-spinner]="submittedDelete"
      tooltipPosition="left"
      tooltipZIndex="20000"
      [pTooltip]="submittedDelete ? 'Wird gelöscht' : 'Notiz löschen'"
      (click)="remove($event)"
    ></i>
    <button
      pButton
      label="Speichern"
      type="submit"
      (click)="save()"
      [disabled]="submitted"
      [icon]="submitted ? 'pi pi-spin pi-spinner' : 'pi pi-check'"
    ></button>
  </div>
</form>

<p-confirmPopup></p-confirmPopup>
