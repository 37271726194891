<div class="content">
  <header class="content-header">
    <div class="content-header-menu-tabs">
      <a
        routerLink="/caregivers/{{ caregiverId }}/overview"
        routerLinkActive="active"
        [routerLinkActiveOptions]="{ exact: true }"
        >Dienstplan</a
      >

      <a
        routerLink="/caregivers/{{ caregiverId }}/overview/calendar"
        routerLinkActive="active"
        [routerLinkActiveOptions]="{ exact: true }"
        >Kalender</a
      >

      <a
        routerLink="/caregivers/{{ caregiverId }}/overview/analyses"
        routerLinkActive="active"
        [routerLinkActiveOptions]="{ exact: true }"
        >Auswertung</a
      >

      <a
        routerLink="/caregivers/{{ caregiverId }}/overview/recommendations"
        routerLinkActive="active"
        [routerLinkActiveOptions]="{ exact: true }"
        >Empfehlungen</a
      >

      <a
        routerLink="/caregivers/{{ caregiverId }}/overview/dates"
        routerLinkActive="active"
        [routerLinkActiveOptions]="{ exact: true }"
        >Abwesenheitsmanagement</a
      >

      <a
        routerLink="/caregivers/{{ caregiverId }}/overview/proofs"
        routerLinkActive="active"
        [routerLinkActiveOptions]="{ exact: true }"
        >Leistungsnachweise</a
      >
    </div>
  </header>
</div>

<router-outlet></router-outlet>
