<div class="content">
  <header class="content-header">
    <div class="content-header-menu-tabs">
      <a
        routerLink="/patients/{{ patientId }}/accounting"
        routerLinkActive="active"
        [routerLinkActiveOptions]="{ exact: true }"
        >Einsätze</a
      >

      <a
        routerLink="/patients/{{ patientId }}/accounting/consultings"
        routerLinkActive="active"
        [routerLinkActiveOptions]="{ exact: true }"
        >Beratungseinsätze</a
      >

      <a
        routerLink="/patients/{{ patientId }}/accounting/returns"
        routerLinkActive="active"
        [routerLinkActiveOptions]="{ exact: true }"
        >Rückläufer</a
      >

      <a
        routerLink="/patients/{{ patientId }}/accounting/km"
        routerLinkActive="active"
        [routerLinkActiveOptions]="{ exact: true }"
        >KM-Fahrt</a
      >
    </div>
  </header>
</div>

<router-outlet></router-outlet>
