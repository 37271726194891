import { Component, OnDestroy, OnInit } from '@angular/core'
import { CaregiverService } from '../../../../../services/caregivers.service'
import { ActivatedRoute, ParamMap, Router } from '@angular/router'
import { HistoryService } from '../../../../../services/history.service'
import { LatestChangeModel } from '../../../../../models/history/latest-change.model'
import { CaregiverDetailGeneralModel } from '../../../../../models/caregiver/caregiver-detail-general.model'
import { DiffViewDialogComponent } from '../../../../../components/dialogs/diff-view-dialog/diff-view-dialog.component'
import { PhoneCallsListModel } from '../../../../../models/phone-call/phone-calls-list.model'
import { PhoneCallEditDialogComponent } from '../../../../../components/dialogs/phone-call-edit-dialog/phone-call-edit-dialog.component'
import { DialogService } from 'primeng/dynamicdialog'
import { HistoryListModel } from '../../../../../models/history/history-list.model'
import { HistoryManualDialogComponent } from '../../../../../components/dialogs/history-manual-dialog/history-manual-dialog.component'
import {
  EventBusService,
  GlobalEvent,
} from '../../../../../services/eventbus.service'
import { Subscription } from 'rxjs'
import { PhoneCallService } from '../../../../../services/phone-call.service'
import { TodoModel } from '../../../../../models/todo/todo.model'
import { ContactPersonModel } from '../../../../../models/contact-person/contact-person.model'
import { ContactPersonDialogComponent } from '../../../../../components/dialogs/contact-person-dialog/contact-person-dialog.component'
import { DiffViewHistoryDialogComponent } from '../../../../../components/dialogs/diff-view-history-dialog/diff-view-history-dialog.component'
import { ChangeStatusCaregiverDialogComponent } from '../../../../../components/dialogs/change-status-caregiver-dialog/change-status-caregiver-dialog.component'
import { CaregiverStatusModel } from '../../../../../models/caregiver/caregiver-status.model'
import { ShowTodoDialogComponent } from '../../../../../components/dialogs/show-todo-dialog/show-todo-dialog.component'
import { VetoModel } from '../../../../../models/veto/veto.model'
import { Clipboard } from '@angular/cdk/clipboard'
import {
  HospitationFeedbackDialogComponent
} from "../../../../../components/dialogs/hospitation-feedback-dialog/hospitation-feedback-dialog.component";
import {AuthService} from "../../../../../services/auth.service";

@Component({
  selector: 'app-caregivers-detail-master-data',
  templateUrl: './caregivers-detail-master-data.component.html',
})
export class CaregiversDetailMasterDataComponent implements OnInit, OnDestroy {
  public loading = true
  public caregiver: CaregiverDetailGeneralModel = new CaregiverDetailGeneralModel()
  public latestChanges: LatestChangeModel = new LatestChangeModel()
  public searchQuery = ''

  public caregiverId: string | null = null

  public addressCopied = false
  public vetoOpened = false
  public openedContactPersons: { [index: number]: boolean } = {}

  private routeSubscription: any = null
  private eventBusSubscription: Subscription = new Subscription()

  public openedDetails = {
    status: false,
  }

  constructor(
    public authService: AuthService,
    private caregiverService: CaregiverService,
    public phoneCallService: PhoneCallService,
    private route: ActivatedRoute,
    private clipboard: Clipboard,
    private eventbus: EventBusService,
    private router: Router,
    private historyService: HistoryService,
    private dialogService: DialogService
  ) {}

  ngOnInit(): void {
    this.routeSubscription = this.route.paramMap.subscribe(
      (params: ParamMap) => {
        this.caregiverId = params.get('id')

        this.loadCaregiver()
      }
    )

    this.listenForEventbus()
  }

  ngOnDestroy(): void {
    this.eventBusSubscription.unsubscribe()
    this.routeSubscription && this.routeSubscription.unsubscribe()
  }

  public getLatestChange(
    id: number | string | null | undefined,
    type: 'caregiver' | 'contact_person',
    data: { [key: string]: any }
  ): void {
    const keys = Object.keys(data)

    for (let key of keys) {
      data[key] = data[key] != null ? encodeURIComponent(data[key]) : ''
    }

    this.historyService
      .getLatestChangeMultiple(id || '', type, data)
      .subscribe((date: string) => {
        for (let key of keys) {
          this.latestChanges[key] = date
        }
      })
  }

  public openDiffViewHistory(
    id: number | string | null | undefined,
    type: 'caregiver' | 'contact_person',
    data: { [key: string]: any }
  ): void {
    const keys = Object.keys(data)

    for (let key of keys) {
      data[key] = data[key] != null ? encodeURIComponent(data[key]) : ''
    }

    this.dialogService.open(DiffViewHistoryDialogComponent, {
      data: {
        id,
        type,
        data,
      },
      header: 'Änderungen ansehen',
      styleClass: 'dialog-diff-view',
      dismissableMask: true,
    })
  }

  public copyAddressToClipboard(): void {
    this.clipboard.copy(this.caregiver.caregiver.full_address)

    this.addressCopied = true

    setTimeout(() => {
      this.addressCopied = false
    }, 2000)
  }

  public openHospitationFeedbackDialog(status: CaregiverStatusModel): void {
    this.dialogService.open(HospitationFeedbackDialogComponent, {
      data: {
        is_female: this.caregiver.caregiver.is_female,
        current_status: status,
        is_frozen: true,
      },
      header: 'Feedback',
      styleClass: 'dialog-container',
      dismissableMask: false,
      width: '520px',
    })
  }

  public openChangeStatusDialog(caregiverStatus: CaregiverStatusModel): void {
    this.dialogService.open(ChangeStatusCaregiverDialogComponent, {
      header: 'Status ändern',
      width: '760px',
      styleClass: 'dialog-container',
      data: {
        caregiver_status: { ...caregiverStatus },
        isEdit: true,
        caregiver: { ...this.caregiver.caregiver },
      },
    })
  }

  public openContactPersonDialog(
    contactPerson: ContactPersonModel | null = null
  ): void {
    this.dialogService.open(ContactPersonDialogComponent, {
      header: contactPerson
        ? 'Ansprechpartner bearbeiten'
        : 'Neuer Ansprechpartner',
      width: '1000px',
      styleClass: 'dialog-container',
      data: {
        type: 'caregiver',
        edit: contactPerson !== null,
        type_id: this.caregiver.caregiver.id,
        contactPerson: { ...contactPerson },
      },
    })
  }

  public loadCaregiver(withLoading: boolean = true): void {
    if (withLoading) {
      this.loading = true
    }

    this.caregiverService
      .load(this.caregiverId)
      .subscribe((data: CaregiverDetailGeneralModel) => {
        this.caregiver = data

        // Die Veto-Einträge werden nach Nachnamen des Patienten sortiert.
        this.caregiver.caregiver.vetos = data.caregiver.vetos.sort(
          (a: VetoModel, b: VetoModel) => {
            return a.patient.last_name.localeCompare(b.patient.last_name)
          }
        )

        if (withLoading) {
          this.loading = false
        }
      })
  }

  public searchedForPatientInVeto(name: string): boolean {
    if (!this.searchQuery) {
      return true
    }

    return name.toLowerCase().includes(this.searchQuery.toLocaleLowerCase())
  }

  public openShowTodoDialog(todo: TodoModel): void {
    this.dialogService.open(ShowTodoDialogComponent, {
      header: 'Todo ansehen',
      width: '820px',
      styleClass: 'dialog-container',
      data: {
        isEdit: true,
        todo,
        todo_id: todo.id,
        user_type_name: this.caregiver.caregiver.full_name,
      },
    })
  }

  private listenForEventbus(): void {
    this.eventBusSubscription = this.eventbus.subject.subscribe(
      (event: GlobalEvent) => {
        switch (event) {
          case GlobalEvent.PhoneCallListReload:
          case GlobalEvent.CaregiverDetailReload:
          case GlobalEvent.HistoryListReload:
          case GlobalEvent.CaregiverStatusChanged:
          case GlobalEvent.ContactPersonChanged:
          case GlobalEvent.TodoChanged:
            this.loadCaregiver(false)
        }
      }
    )
  }

  public openManualHistoryDialog(
    edit: boolean,
    history?: HistoryListModel
  ): void {
    this.dialogService.open(HistoryManualDialogComponent, {
      data: {
        edit,
        history: history || new HistoryListModel(),
        type: 'caregiver',
        type_id: this.caregiver.caregiver.id,
      },
      header: history ? 'Eintrag bearbeiten' : 'Neuer Eintrag',
      styleClass: 'dialog-container',
      dismissableMask: false,
      width: '520px',
    })
  }

  public openDiffView(id: number): void {
    this.dialogService.open(DiffViewDialogComponent, {
      data: {
        id,
      },
      header: 'Änderungen ansehen',
      styleClass: 'dialog-diff-view',
      dismissableMask: true,
    })
  }

  public openPhonecallEditModal(phonecall: PhoneCallsListModel): void {
    this.dialogService.open(PhoneCallEditDialogComponent, {
      header: 'Telefonat bearbeiten',
      width: '540px',
      styleClass: 'dialog-container',
      dismissableMask: true,
      data: phonecall,
    })
  }
}
