import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { environment } from '../../environments/environment'
import { Observable } from 'rxjs'
import { ContactPersonModel } from '../models/contact-person/contact-person.model'

@Injectable({
  providedIn: 'root',
})
export class ContactPersonService {
  constructor(private http: HttpClient) {}

  public delete(id: number | null): Observable<[]> {
    return this.http.delete<[]>(`${environment.api}/contact-persons/${id}`)
  }

  public store(data: ContactPersonModel): Observable<ContactPersonModel> {
    return this.http.post<ContactPersonModel>(
      `${environment.api}/contact-persons`,
      {
        ...data,
      }
    )
  }

  public update(data: ContactPersonModel): Observable<ContactPersonModel> {
    return this.http.put<ContactPersonModel>(
      `${environment.api}/contact-persons/${data.id}`,
      { ...data }
    )
  }
}
