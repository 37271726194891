<app-loader [loading]="loading"></app-loader>

<div class="content-actions">
  <h2>
    <i class="tio">heart_outlined</i>
    Leistungsnachweise
  </h2>
</div>

<div class="content new-content-container" *ngIf="!loading">
  <header class="content-header" *ngIf="keys(proofs).length > 0">
    <span> Alle generierten Leistungsnachweise </span>
  </header>

  <div class="content-body auto-height" *ngIf="keys(proofs).length === 0">
    <span class="content-no-message">Keine Einträge vorhanden</span>
  </div>

  <ng-container *ngFor="let date of keys(proofs)">
    <div class="detail-information-header is-accordion">
      <span
        class="accordion-clickable-area"
        (click)="openedAccordions[date] = !openedAccordions[date]"
      >
        <i
          [class.pi-chevron-down]="openedAccordions[date]"
          [class.pi-chevron-up]="!openedAccordions[date]"
          class="pi"
        ></i>
        {{ date }}
      </span>

      <span
        pTooltip="Alle herunterladen"
        tooltipPosition="left"
        (click)="openZipDownload(date)"
        ><i class="pi pi-download"></i
      ></span>
    </div>

    <div
      class="content-body without-padding auto-height"
      *ngIf="openedAccordions[date]"
    >
      <p-table
        [value]="proofs[date]"
        [autoLayout]="true"
        responsiveLayout="stack"
        [rowHover]="true"
      >
        <ng-template pTemplate="header">
          <tr>
            <th style="min-width: 240px">Patient</th>
            <th>Dokument</th>
            <th>Erstellt am</th>
            <th>Erstellt von</th>
            <th style="width: 100px"></th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-proof>
          <tr>
            <td>
              <span class="p-column-title">Patient</span>
              {{ proof.patient.full_name }}
            </td>
            <td>
              <span class="p-column-title">Dokument</span>
              {{ proof.media.name }}
            </td>
            <td>
              <span class="p-column-title">Erstellt am</span>
              {{ proof.media.created_at | date: "dd.MM.y, H:mm" }} Uhr
            </td>
            <td>
              <span class="p-column-title">Erstellt von</span>
              {{ proof.media.custom_properties.created_from_name || "-" }}
            </td>
            <td class="table-action" style="width: 100px">
              <a
                [href]="
                  userCaregiverService.getDownloadProofDocumentLink(
                    proof.media.uuid
                  )
                "
                target="_blank"
              >
                <i
                  tooltipPosition="left"
                  pTooltip="Herunterladen"
                  class="pi pi-download"
                ></i>
              </a>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </ng-container>
</div>
