<form #form="ngForm" class="dialog-form">
  <div class="dialog-loader white-background" *ngIf="isParsing">
    <i class="loading-logo"></i>
    {{
      isUploading ? "DOKUMENT WIRD HOCHGELADEN" : "DOKUMENT WIRD VERARBEITET"
    }}

    <span *ngIf="isProcessing" class="color-gray">
      {{ processedPages }} VON {{ proofUploaded.amount_pages }} SEITEN
    </span>

    <div class="dialog-progress-bar-container" *ngIf="isProcessing">
      <p-progressBar [value]="processedPagesInPercent"></p-progressBar>
    </div>
  </div>

  <div class="dialog-form-body">
    <div class="p-fluid p-formgrid p-grid" *ngIf="!isParsed">
      <div class="p-field p-col-12" style="margin: 0">
        <label for="file">Datei auswählen</label>
        <input
          class="file-input-special"
          (change)="setSelectedFile($event)"
          id="file"
          name="file"
          type="file"
          pInputText
        />
      </div>
    </div>

    <div class="p-fluid p-formgrid p-grid" *ngIf="isParsed">
      <div class="p-field p-col-12" *ngIf="data.has_cover">
        <label>Kennzeichen</label>
        <strong>{{ data.cover_key }}</strong>
      </div>
      <div class="p-field p-col-12" *ngIf="!data.has_cover">
        <label
          style="display: flex; align-items: center; gap: 10px"
          for="cover_key"
          class="color-alert"
          ><i class="pi pi-exclamation-triangle"></i> Kennzeichen *</label
        >
        <input
          id="cover_key"
          name="cover_key"
          type="text"
          placeholder="Bitte eintragen"
          pInputText
          (keyup)="data.cover_key = data.cover_key.toUpperCase()"
          [(ngModel)]="data.cover_key"
          [required]="true"
          #cover_key="ngModel"
          [class.p-invalid]="
            cover_key.invalid && (cover_key.dirty || cover_key.touched)
          "
        />
      </div>
    </div>
  </div>

  <div
    class="content-body dialog-max-height without-padding"
    style="margin-bottom: 20px"
    *ngIf="isParsed && data.issues.length > 0"
  >
    <strong style="padding: 0 0 5px 20px" class="color-gray">FEHLERHAFT</strong>
    <p-table
      scrollHeight="200px"
      [value]="data.issues"
      [rowHover]="true"
      [autoLayout]="true"
      [scrollable]="true"
      [globalFilterFields]="[
        'name',
      ]"
    >
      <ng-template pTemplate="header">
        <tr>
          <th style="min-width: 300px">Grund</th>
          <th style="max-width: 100px">Seite</th>
          <th style="max-width: 100px">Scan</th>
          <th style="max-width: 50px"></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-item>
        <tr>
          <td
            *ngIf="!item.edited_at"
            style="min-width: 300px"
            class="color-alert"
          >
            <div style="display: flex; flex-direction: column">
              {{ item.message }}
              <span *ngIf="item.already_created_invoice">
                {{ item.patient_name }} {{ item.month }} {{ item.year }}
                {{ item.budget_type }}
              </span>
            </div>
          </td>
          <td
            *ngIf="item.edited_at"
            style="min-width: 300px"
            class="color-orange"
          >
            <div style="display: flex; align-items: center; gap: 10px">
              {{ item.patient_name }} ({{ item.budget_type }})
              <i
                style="font-size: 12px"
                class="pi pi-info-circle"
                [pTooltip]="item.message"
              ></i>
            </div>
          </td>

          <td style="max-width: 100px">Seite {{ item.page }}</td>
          <td style="max-width: 100px">
            <a
              [href]="proofService.getImageDownloadLink(item.image_name)"
              target="_blank"
              >ansehen</a
            >
          </td>

          <td style="max-width: 50px">
            <i
              *ngIf="item.changeable"
              pTooltip="Bearbeiten"
              class="pi pi-pencil"
              (click)="openSelectPatientForProofDialog(item)"
              style="display: block"
            ></i>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>

  <div class="content-body dialog-max-height without-padding" *ngIf="isParsed">
    <div
      style="
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 20px 5px 20px;
      "
    >
      <strong class="color-gray">GELESENE DATEN</strong>

      <div class="content-header-buttons">
        <div class="p-input-icon-left">
          <i class="pi pi-search"></i>
          <input
            pInputText
            (input)="filterTableGlobal($event)"
            placeholder="Suche..."
            [value]="globalFilterValue"
          />
        </div>
      </div>
    </div>

    <p-table
      #dt
      [value]="data.files"
      [rowHover]="true"
      [scrollable]="true"
      scrollHeight="320px"
      [globalFilterFields]="[
        'patient_id',
        'patient_name',
        'budget',
      ]"
    >
      <ng-template pTemplate="header">
        <tr>
          <th pSortableColumn="patient_name">
            Patient <p-sortIcon field="patient_name"></p-sortIcon>
          </th>
          <th>Zeitraum</th>
          <th style="min-width: 200px" pSortableColumn="budget">
            Budget <p-sortIcon field="budget"></p-sortIcon>
          </th>
          <th style="max-width: 100px" pSortableColumn="page">
            Seite <p-sortIcon field="page"></p-sortIcon>
          </th>
          <th style="max-width: 100px">Scan</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-item let-rowIndex="rowIndex">
        <tr>
          <td>
            <a target="_blank" routerLink="/patients/{{ item.patient_id }}">
              {{ item.patient_name }}
            </a>
          </td>
          <td>{{ item.month }} {{ item.year }}</td>
          <td style="min-width: 200px">
            <div style="display: flex; align-items: center; gap: 5px">
              <span [class.color-alert]="item.budget === 'Blanko'"
                >{{ item.budget }}
                {{ item.beihilfe == "JA" ? " (Beihilfe)" : "" }}</span
              >

              <i
                pTooltip="Bearbeiten"
                class="pi pi-pencil"
                style="display: block"
                (click)="openChangePatientBudgetProofDialog(item)"
              ></i>
            </div>
          </td>
          <td style="max-width: 100px">Seite {{ item.page }}</td>
          <td style="max-width: 100px">
            <a
              [href]="proofService.getImageDownloadLink(item.image_name)"
              target="_blank"
              >ansehen</a
            >
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>

  <div class="dialog-form-footer" *ngIf="isParsed">
    <i></i>

    <button
      pButton
      label="Speichern"
      type="submit"
      (click)="save()"
      [disabled]="submitted"
      [icon]="submitted ? 'pi pi-spin pi-spinner' : 'pi pi-check'"
    ></button>
  </div>
</form>
