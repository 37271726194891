<form #form="ngForm" class="dialog-form">
  <div class="dialog-loader white-background" *ngIf="loading">
    <i class="loading-logo"></i>
  </div>

  <div class="dialog-form-body">
    <div class="p-fluid p-formgrid p-grid without-border">
      <div class="p-field p-col-4">
        <label>Monat</label>
        <strong>{{ config.data.month }}</strong>
      </div>

      <div class="p-field p-col-4">
        <label>Jahr</label>
        <strong>{{ config.data.year }}</strong>
      </div>

      <div class="p-field p-col-4">
        <label>Kennzeichen</label>
        <strong>{{ config.data.cover_key }}</strong>
      </div>

      <div class="p-field p-col-12">
        <label for="patient">Patient </label>
        <p-autoComplete
          field="full_name"
          inputId="patient"
          name="patient"
          scrollHeight="350px"
          appendTo="body"
          [forceSelection]="true"
          [(ngModel)]="patient"
          [suggestions]="patients"
          (completeMethod)="searchPatients($event)"
          (onSelect)="patientSelected($event)"
          [required]="true"
          [autofocus]="true"
          #patientRef="ngModel"
          [class.p-invalid]="
            patientRef.invalid && (patientRef.dirty || patientRef.touched)
          "
        >
          <ng-template let-patient pTemplate="item">
            <div class="search-result-data">
              <span class="search-result-name">
                <b>{{ patient.full_name }}</b>
                <!--                <span class="search-result-id">#{{ patient.id }}</span>-->
                <i
                  *ngIf="patient.status"
                  class="p-tag p-tag-rounded p-tag-{{ patient.status }}"
                  >{{ patient.status_string }}</i
                >
              </span>
            </div>
          </ng-template>
        </p-autoComplete>
      </div>

      <div class="p-field p-col-12" *ngIf="patient && patient.id">
        <label for="budget">Budget</label>
        <p-dropdown
          appendTo="body"
          scrollHeight="200px"
          placeholder="Bitte wählen"
          inputId="budget"
          name="budget"
          [options]="budgetOptions"
          [(ngModel)]="budget"
        ></p-dropdown>
      </div>
    </div>
  </div>

  <div class="dialog-form-footer">
    <i></i>

    <button
      pButton
      label="Speichern"
      type="submit"
      (click)="save()"
      [disabled]="submitted || !budget || !patient"
      [icon]="submitted ? 'pi pi-spin pi-spinner' : 'pi pi-check'"
    ></button>
  </div>
</form>
<p-confirmPopup></p-confirmPopup>
