import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core'
import {
  DialogService,
  DynamicDialogConfig,
  DynamicDialogRef,
} from 'primeng/dynamicdialog'
import { EventBusService, GlobalEvent } from 'src/app/services/eventbus.service'
import { ToastService } from 'src/app/services/toast.service'
import { NgForm } from '@angular/forms'
import { ProofsService } from '../../../services/proofs.service'
import Viewer from 'viewerjs'
import { DocumentService } from '../../../services/document.service'
import { ReleaseAppointmentAdminDialogComponent } from '../release-appointment-admin-dialog/release-appointment-admin-dialog.component'
import { FinishProofReworkDialogComponent } from '../finish-proof-rework-dialog/finish-proof-rework-dialog.component'
import { AppointmentFeedbackDialogComponent } from '../appointment-feedback-dialog/appointment-feedback-dialog.component'
import * as dayjs from 'dayjs'
import * as customParseFormat from 'dayjs/plugin/customParseFormat'
import * as utc from 'dayjs/plugin/utc'
import { UploadSingleProofDialogComponent } from '../upload-single-proof-dialog/upload-single-proof-dialog.component'
import { SelectItem } from 'primeng/api'
import { PatientService } from '../../../services/patient.service'
import { HelperService } from '../../../services/helper.service'
import { InvoiceService } from '../../../services/invoice.service'
import { CaregiverPlanningDialogComponent } from '../caregiver-planning-dialog/caregiver-planning-dialog.component'

import interact from 'interactjs'
// @ts-ignore
import mergeImages from 'merge-images'
import { DomSanitizer } from '@angular/platform-browser'
import { PlanningService } from '../../../services/planning.service'
import { ConfirmWithTextDialogComponent } from '../confirm-with-text-dialog/confirm-with-text-dialog.component'

@Component({
  selector: 'app-proof-check-dialog',
  templateUrl: './proof-check-dialog.component.html',
})
export class ProofCheckDialogComponent implements OnInit {
  @ViewChild('form', { static: true }) form!: NgForm

  submitted = false
  public activeTab = 'CHECK'
  public loading = true

  public showSidebar = false
  public dataForCheckStatusIsValid = false
  public dataForBudgetStatusIsValid = false
  public hasOpenedReworks = false
  public hasBudgetIssueRework = false
  public data: any = {}

  public imgSrc = ''
  public imgSrcLnw = ''
  public mailPreviewSrc = ''
  public pdfPreviewSrc = ''
  public pdfPreviewSrcSafe: any = ''

  public isBeihilfe = false

  public clickedOnPreviewLnw = false
  public clickedOnPreviewMail = false
  public clickedOnPreviewPdf = false

  public lnwBase64 = null
  public mediaGenerated = false
  public generatedMediaLink = ''

  public viewerLoading = true
  public rotating = false

  public currentPatient: any = null
  public selectedPatientAddress = ''
  public selectedCostUnitAddress = ''
  public selectedReceiverAddress = ''
  public selectedAddress: any = {}
  public selectedUnitCostReceiver: any = null
  public selectedReceiver: any = {}
  private viewer: any = null

  public previousInvoice: any = null
  public currentDate = ''

  public selectedGlobalTab: any = {
    type: 'PROOF',
    index: 0,
  }

  public type: any = []
  public comment = ''

  public isPkv = false
  public isSelbstzahler = false
  public hasMedia = false

  public typeOptions = [
    { label: 'Sonstiges', value: 'OTHER' },
    { label: 'Fehlerhafter Leistungsnachweis', value: 'PROOF_ISSUE' },
    { label: 'Fehlerhaftes Budget', value: 'BUDGET_ISSUE' },
    { label: 'Fehlerhafte Daten Kunde', value: 'CUSTOMER_DATA_ISSUE' },
    { label: 'Änderung Termin', value: 'APPOINTMENT_CHANGE' },
  ]

  public stampSaveSubmitted = false
  public withDateStamp = false
  private positions = {
    stamp: {
      x: 0,
      y: 0,
    },
    date: {
      x: 0,
      y: 0,
    },
  }
  public costUnitHasNoContactPersons = false
  public contactPersonsForInvoice = ''
  public receiversData: any = {}
  public patientReceiverOptions: any[] = []
  public costUnitReceiverOptions: any[] = []
  public costUnitEmailReceiverOptions: any[] = []
  public addressReceiverOptions: any[] = []
  public ccOptions: any[] = []
  public formOfAddress: any = []

  public invoiceValues = {
    invoice_receiver: '',
    individual_email: '',
    individual_address: '',
    individual_form_of_address: '',
    individual_first_name: '',
    individual_last_name: '',
    individual_street_house_number: '',
    individual_zipcode: '',
    individual_city: '',
    accounting_type: '',
    patient_receiver: '',
    cost_unit_receiver: '',
    cost_unit_email_receiver: '',
    accounting_receiver: '',
    payment_type: '',
    send_type: '',
    receiver: null as any,
    cc_receivers: [],
  }

  public buttonItems = [
    {
      label: 'Rechnung versenden',
      icon: 'pi pi-send',
      command: () => {
        this.sendInvoice()
      },
    },
  ]

  constructor(
    private ref: DynamicDialogRef,
    public proofService: ProofsService,
    private invoiceService: InvoiceService,
    public config: DynamicDialogConfig,
    private sanitizer: DomSanitizer,
    private dialogService: DialogService,
    public documentService: DocumentService,
    private patientService: PatientService,
    private eventbus: EventBusService,
    private helperService: HelperService,
    private toastService: ToastService,
    private planningService: PlanningService
  ) {
    dayjs.locale('de')
    dayjs.extend(customParseFormat)
    dayjs.extend(utc)
  }

  public ngOnInit(): void {
    this.currentDate = dayjs.utc().format('DD.MM.YYYY')

    this.data = this.config.data

    this.isBeihilfe = this.data.key.includes('Beihilfe')

    // Wenn wir keinen Eintrag in der Datenbank haben (weil kein LNW hochgeladen wurde)
    // müssen wir einen leeren Eintrag erstellen.
    if (!this.data.proof_status) {
      this.initProofStatus()
    } else {
      this.loadReceivers()

      this.changeTab(this.data.proof_status.status)
    }

    this.hasMedia = this.data.proofs.length > 0
    this.isPkv = this.data.persplan_budgets.persplan_function.startsWith('PV')
    this.isSelbstzahler = this.data.persplan_budgets.persplan_function.startsWith(
      'Selbstzahler'
    )

    if (this.hasMedia) {
      this.imgSrc = this.documentService.getProofImageDocumentDownloadLink(
        this.data.proofs[0].uuid
      )

      if (this.activeTab !== 'INVOICE') {
        setTimeout(() => {
          this.initViewer()
        }, 500)
      }
    }

    if (this.data.proof_status && this.data.proof_status.proof_with_stamp) {
      this.imgSrcLnw = this.documentService.getProofImageDocumentDownloadLink(
        this.data.proof_status.proof_with_stamp.uuid
      )
    }

    this.helperService.dependencies$.subscribe((data: any) => {
      this.formOfAddress = data.form_of_address_with_company
    })
  }

  private initProofStatus(): void {
    this.proofService.initProofStatus(this.data).subscribe((response: any) => {
      this.data.proof_status = response

      this.loadReceivers()
    })
  }

  public mergeImages(): void {
    this.stampSaveSubmitted = true

    // @ts-ignore
    const lnwImage = document.getElementById('image-lnw-for-stamp')
    const canvas = document.createElement('canvas')

    const stampImage = document.getElementById('image-stamp')

    // @ts-ignore
    const widthLnw = lnwImage.width
    // @ts-ignore
    const heightLnw = lnwImage.height

    // @ts-ignore
    const naturalWidthLnw = lnwImage.naturalWidth
    // @ts-ignore
    const naturalHeightLnw = lnwImage.naturalHeight

    const realX = (this.positions.stamp.x * naturalWidthLnw) / widthLnw
    const realY = (this.positions.stamp.y * naturalHeightLnw) / heightLnw

    // @ts-ignore
    const widthStamp = stampImage.width
    // @ts-ignore
    const heightStamp = stampImage.height

    // @ts-ignore
    canvas.width = (widthStamp * naturalWidthLnw) / widthLnw
    // @ts-ignore
    canvas.height = (heightStamp * naturalHeightLnw) / heightLnw

    const ctx = canvas.getContext('2d')

    // @ts-ignore
    ctx.drawImage(stampImage, 0, 0, canvas.width, canvas.height)

    const stampDataUrl = canvas.toDataURL()

    mergeImages(
      [
        // LNW
        {
          src: this.lnwBase64,
        },

        // Stempel
        {
          x: realX,
          y: realY,
          src: stampDataUrl,
        },
      ],
      {
        format: 'image/jpeg',
      }
    ).then((b64: any) => {
      const formData = new FormData()
      formData.append('base64', b64)

      this.documentService
        .saveProofFromBase64(this.data.proof_status.id, formData)
        .subscribe(
          (response: any) => {
            this.data.proof_status.proof_with_stamp = response.media

            this.clickedOnPreviewLnw = false
            this.imgSrcLnw = this.documentService.getProofImageDocumentDownloadLink(
              this.data.proof_status.proof_with_stamp.uuid
            )

            this.stampSaveSubmitted = false

            this.getPreviewLink()
          },
          () => {
            this.toastService.error(
              'Etwas ist schiefgelaufen...',
              'Bitte wenden Sie sich an den Support'
            )

            this.stampSaveSubmitted = false
          }
        )
    })
  }

  public isMailPreviewDisabled(): boolean {
    // Falls keine E-Mail eingetragen ist für Individuell.
    if (
      this.invoiceValues.invoice_receiver === 'other' &&
      !this.invoiceValues.individual_email
    ) {
      return true
    }

    // Falls keine E-Mail bei der Pflegekasse hinterlegt ist.
    if (
      this.invoiceValues.invoice_receiver === 'care_insurance' &&
      !this.data.patient_care_insurance.email
    ) {
      return true
    }

    // Falls keine E-Mail beim direkten Kostenträger hinterlegt ist.
    if (
      this.invoiceValues.invoice_receiver === 'cost_unit' &&
      this.costUnitHasNoContactPersons &&
      !this.data.patient_cost_unit.email
    ) {
      return true
    }

    // Falls kein Empfänger bei Kostenträger mit AP ausgewählt ist.
    if (
      this.invoiceValues.invoice_receiver === 'cost_unit' &&
      !this.costUnitHasNoContactPersons &&
      !this.invoiceValues.cost_unit_email_receiver
    ) {
      return true
    }

    // Falls kein Empfänger bei Leistungsnehmer ausgewählt ist.
    if (
      this.invoiceValues.invoice_receiver === 'patient' &&
      !this.invoiceValues.receiver
    ) {
      return true
    }

    return false
  }

  public getPreviewLink(): void {
    const otherValues = this.buildOtherValues()

    const urlPdf = this.proofService.getPreviewLink(
      'pdf',
      this.data.proof_status.id,
      this.invoiceValues,
      otherValues
    )

    const urlMail = this.proofService.getPreviewLink(
      'mail',
      this.data.proof_status.id,
      this.invoiceValues,
      otherValues
    )

    this.pdfPreviewSrc = urlPdf
    this.pdfPreviewSrcSafe = this.sanitizer.bypassSecurityTrustResourceUrl(
      urlPdf
    )
    this.mailPreviewSrc = urlMail
  }

  /**
   * Zusätzliche Felder für die Requests (PDF Preview, Mail Preview und Versand).
   */
  private buildOtherValues() {
    const appointmentIds = this.data.persplan_budgets.appointments.map(
      (appointment: any) => {
        return appointment.id
      }
    )

    return {
      costs_in_euro: this.data.costs_in_euro,
      hours: this.data.hours,
      proof_media_id:
        this.data.proofs.length > 0 ? this.data.proofs[0].id : null,
      media_id_for_stamp: this.data.proof_status.proof_with_stamp
        ? this.data.proof_status.proof_with_stamp.id
        : null,
      from_date: this.data.from_date,
      to_date: this.data.to_date,
      proof_status_id: this.data.proof_status.id,
      appointment_ids: JSON.stringify(appointmentIds),
    }
  }

  private addInteractEvent(type: string): void {
    this.positions[type] = { x: 0, y: 0 }

    interact('.draggable')
      .draggable({
        modifiers: [
          interact.modifiers.restrictRect({
            restriction: 'parent',
            endOnly: true,
          }),
        ],
        listeners: {
          // tslint:disable-next-line:typedef
          move: (event) => {
            this.positions[type].x += event.dx
            this.positions[type].y += event.dy

            event.target.style.transform = `translate(${this.positions[type].x}px, ${this.positions[type].y}px)`
          },
        },
      })
      .resizable({
        edges: { top: false, left: false, bottom: true, right: true },
        listeners: {
          move: (event: any) => {
            let { x, y } = event.target.dataset

            x = (parseFloat(x) || 0) + event.deltaRect.left
            y = (parseFloat(y) || 0) + event.deltaRect.top

            Object.assign(event.target.style, {
              width: `${event.rect.width}px`,
              height: `${event.rect.height}px`,
              // transform: `translate(${x}px, ${y}px)`
            })

            Object.assign(event.target.dataset, { x, y })
          },
        },
        modifiers: [
          interact.modifiers.aspectRatio({
            // make sure the width is always double the height
            ratio: 'preserve',
            // also restrict the size by nesting another modifier
            modifiers: [interact.modifiers.restrictSize({ max: 'parent' })],
          }),
        ],
      })
  }

  public toggleWithDateStamp(): void {
    this.withDateStamp = !this.withDateStamp

    this.positions.stamp = { x: 0, y: 0 }
  }

  private loadReceivers(): void {
    this.checkForOpenedReworks()
    this.checkForBudgetIssueRework()
    this.dataChangedForCheckedStatus()
    this.dataChangedForBudgetStatus()

    this.patientService
      .getReceivers(this.data.patient_id)
      .subscribe((response: any) => {
        this.receiversData = response

        this.setInvoiceFields()

        this.buildReceiverOptions()
        this.buildAddressReceiverOptions()
        this.buildCCOptions()
        this.buildCostUnitReceiverOptions()

        // Wir setzen die Daten nochmal rein, weil diese unter umständen resetten wurden vorher.
        this.setInvoiceFields()

        this.currentPatient =
          this.receiversData.first_patient.id == this.data.patient_id
            ? this.receiversData.first_patient
            : this.receiversData.second_patient

        this.loading = false
      })
  }

  public openCaregiverPlanningDialog(appointment: any): void {
    const ref = this.dialogService.open(CaregiverPlanningDialogComponent, {
      header: 'Einsatz bearbeiten',
      width: '520px',
      styleClass: 'dialog-container',
      data: {
        year: this.data.year,
        month: this.data.month,
        type: 'EDIT',
        id: appointment.id,
        caregiverId: appointment.caregiver_id,
      },
    })

    ref.onClose.subscribe((response: any) => {
      if (response) {
        this.loadData(appointment)
      }
    })
  }

  private loadData(appointment: any): void {
    this.proofService
      .loadSpecific(this.data.proof_status.id)
      .subscribe((response: any) => {
        this.data.persplan_budgets = response.persplan_budgets
        this.data.budgets = response.budgets
        this.data.costs_in_euro = response.costs_in_euro
        this.data.costs_in_euro_no_format = response.costs_in_euro_no_format

        // Es soll zusätzlich eine Nachbearbeitung erstellt werden.
        const comment = `Einsatz vom ${appointment.date}, ${appointment.from_h} - ${appointment.to_h} für ${appointment.caregiver_name} wurde geändert. Bitte überprüfen.`
        this.proofService
          .addProofRework(
            this.data.proof_status.id,
            ['APPOINTMENT_CHANGE'],
            comment
          )
          .subscribe((r: any) => {
            this.data.proof_status.reworkings.push(r)

            this.checkForOpenedReworks()
            this.checkForBudgetIssueRework()

            this.eventbus.emit(GlobalEvent.ProofsChanged)
            // this.toastService.success('Erfolgreich')
          })
      })
  }

  public costUnitReceiverSelected(): void {
    this.costUnitEmailReceiverOptions = []
    this.selectedUnitCostReceiver = null

    if (!this.invoiceValues.cost_unit_receiver) {
      return
    }

    const [type, id] = this.invoiceValues.cost_unit_receiver.split('_')

    const costUnit = this.data.patient_cost_unit

    if (type === 'multi') {
      this.selectedCostUnitAddress = `<strong>${costUnit.name}</strong><br>${costUnit.street_house_number}<br>${costUnit.zipcode} ${costUnit.city}`

      const isDisabled =
        this.invoiceValues.send_type === 'email' && !costUnit.email

      // Wenn der Multi keine E-Mail hat, soll der erste Eintrag nicht bereits ausgewählt werden.
      if (isDisabled) {
        this.costUnitEmailReceiverOptions.push({
          value: '',
          label: 'Bitte auswählen',
          email: '',
          disabled: true,
        })
      }

      this.costUnitEmailReceiverOptions.push({
        value: `multi_${costUnit.id}_`,
        email: costUnit.email || 'Keine E-Mail',
        label: `${costUnit.full_name}`,
        disabled: isDisabled,
      })
    } else {
      const contactPerson = costUnit.contact_persons.find((person: any) => {
        return person.id == id
      })

      this.selectedUnitCostReceiver = contactPerson

      if (contactPerson.email_private) {
        this.costUnitEmailReceiverOptions.push({
          value: `ap_${contactPerson.id}_private`,
          email: `Private E-Mail: ${contactPerson.email_private}`,
          label: `${contactPerson.full_name} - AP`,
          disabled: false,
        })
      }

      if (contactPerson.email_work) {
        this.costUnitEmailReceiverOptions.push({
          value: `ap_${contactPerson.id}_work`,
          email: `Geschäftliche E-Mail: ${contactPerson.email_work}`,
          label: `${contactPerson.full_name} - AP`,
          disabled: false,
        })
      }

      // Wenn gar keine E-Mail hinterlegt ist.
      if (!contactPerson.email_private && !contactPerson.email_work) {
        this.costUnitEmailReceiverOptions.push({
          value: '',
          label: 'Bitte auswählen',
          email: '',
          disabled: true,
        })

        this.costUnitEmailReceiverOptions.push({
          value: `ap_${contactPerson.id}_`,
          email: 'Keine E-Mail',
          label: `${contactPerson.full_name} - AP`,
          disabled: this.invoiceValues.send_type === 'email',
        })
      }

      this.selectedCostUnitAddress = `${costUnit.name}<br><strong>${contactPerson.form_of_address} ${contactPerson.first_name} ${contactPerson.last_name}</strong><br>${contactPerson.street_house_number}<br>${contactPerson.zipcode} ${contactPerson.city}`
    }

    this.getPreviewLink()
  }

  public patientReceiverSelected(): void {
    this.selectedReceiver = null
    this.selectedPatientAddress = ''

    const [type, id] = this.invoiceValues.patient_receiver.split('_')

    if (type === 'patient') {
      const patient =
        this.receiversData.first_patient.id == id
          ? this.receiversData.first_patient
          : this.receiversData.second_patient

      this.selectedReceiver = patient
      this.selectedPatientAddress = `${patient.form_of_address}<br><strong>${patient.first_name} ${patient.last_name}</strong><br>${patient.street_house_number}<br>${patient.zipcode} ${patient.city}`
    } else {
      const contactPerson = this.receiversData.contact_persons.find(
        (person: any) => {
          return person.id == id
        }
      )

      this.selectedReceiver = contactPerson
      this.selectedPatientAddress = `${contactPerson.form_of_address}<br><strong>${contactPerson.first_name} ${contactPerson.last_name}</strong><br>${contactPerson.street_house_number}<br>${contactPerson.zipcode} ${contactPerson.city}`
    }

    this.getPreviewLink()
  }

  public addressReceiverSelected(): void {
    this.selectedAddress = null
    this.selectedReceiverAddress = ''

    const [type, id] = this.invoiceValues.receiver.split('_')

    if (type === 'patient') {
      const patient =
        this.receiversData.first_patient.id == id
          ? this.receiversData.first_patient
          : this.receiversData.second_patient

      this.selectedAddress = patient
      this.selectedReceiverAddress = `${patient.form_of_address}<br><strong>${patient.first_name} ${patient.last_name}</strong><br>${patient.street_house_number}<br>${patient.zipcode} ${patient.city}`
    } else {
      const contactPerson = this.receiversData.contact_persons.find(
        (person: any) => {
          return person.id == id
        }
      )

      this.selectedAddress = contactPerson
      this.selectedReceiverAddress = `${contactPerson.form_of_address}<br><strong>${contactPerson.first_name} ${contactPerson.last_name}</strong><br>${contactPerson.street_house_number}<br>${contactPerson.zipcode} ${contactPerson.city}`
    }

    this.getPreviewLink()
  }

  public buildAddressReceiverOptions(): void {
    this.clickedOnPreviewMail = false
    this.clickedOnPreviewPdf = false

    this.costUnitReceiverSelected()

    this.addressReceiverOptions = []
    this.invoiceValues.receiver = null
    this.selectedReceiverAddress = ''

    this.addressReceiverOptions.push({
      value: null,
      label: 'Bitte auswählen',
      email: '',
      disabled: true,
    })

    this.addressReceiverOptions.push({
      value: `patient_${this.receiversData.first_patient.id}_`,
      email: this.receiversData.first_patient.email || 'Keine E-Mail',
      label: `${this.receiversData.first_patient.full_name} - Patient`,
      disabled:
        this.invoiceValues.send_type === 'email' &&
        !this.receiversData.first_patient.email,
    })

    if (this.receiversData.second_patient) {
      this.addressReceiverOptions.push({
        value: `patient_${this.receiversData.second_patient.id}_`,
        email: this.receiversData.second_patient.email || 'Keine E-Mail',
        label: `${this.receiversData.second_patient.full_name} - Patient`,
        disabled:
          this.invoiceValues.send_type === 'email' &&
          !this.receiversData.second_patient.email,
      })
    }

    for (const contactPerson of this.receiversData.contact_persons) {
      if (contactPerson.email_private) {
        this.addressReceiverOptions.push({
          value: `ap_${contactPerson.id}_private`,
          email: `Private E-Mail: ${contactPerson.email_private}`,
          label: `${contactPerson.full_name} - AP`,
          disabled: false,
        })

        // Bei Postversand soll der AP soll nur einmal in der Liste angezeigt
        // werden, selbst wenn er noch eine Geschäftliche E-Mail Adresse hat.
        if (this.invoiceValues.send_type === 'post') {
          continue
        }
      }

      if (contactPerson.email_work) {
        this.addressReceiverOptions.push({
          value: `ap_${contactPerson.id}_work`,
          email: `Geschäftliche E-Mail: ${contactPerson.email_work}`,
          label: `${contactPerson.full_name} - AP`,
          disabled: false,
        })
      }

      // Wenn gar keine E-Mail hinterlegt ist.
      if (!contactPerson.email_private && !contactPerson.email_work) {
        this.addressReceiverOptions.push({
          value: `ap_${contactPerson.id}_`,
          email: 'Keine E-Mail',
          label: `${contactPerson.full_name} - AP`,
          disabled: this.invoiceValues.send_type === 'email',
        })
      }
    }

    this.getPreviewLink()
  }

  public buildCostUnitReceiverOptions(): void {
    this.costUnitReceiverOptions = []
    this.costUnitHasNoContactPersons = false

    const costUnit = this.data.patient_cost_unit

    // Nur wenn ein Kostenträger hinterlegt ist.
    if (costUnit.id) {
      // Wenn kein AP beim Kostenträger hinterlegt ist,
      // wird einfach nur der Kostenträger selbst angezeigt.
      if (costUnit.contact_persons.length === 0) {
        this.costUnitHasNoContactPersons = true

        // Falls keine Ansprechpartner vorhanden sind, soll der Multiplikator ausgewählt sein.
        this.invoiceValues.cost_unit_receiver = `multi_${costUnit.id}_`

        this.selectedCostUnitAddress = `<strong>${costUnit.name}</strong><br>${costUnit.street_house_number}<br>${costUnit.zipcode} ${costUnit.city}`
      } else {
        this.costUnitReceiverOptions.push({
          value: null,
          label: 'Bitte auswählen',
          email: '',
          disabled: true,
        })

        this.costUnitReceiverOptions.push({
          value: `multi_${costUnit.id}_`,
          label: costUnit.name,
        })

        for (let contactPerson of costUnit.contact_persons) {
          this.costUnitReceiverOptions.push({
            value: `ap_${contactPerson.id}_`,
            label: contactPerson.full_name + ' - AP',
          })
        }
      }
    }
  }

  public buildReceiverOptions(): void {
    this.patientReceiverOptions = []

    // Der aktuelle Patient wird als Standard ausgewählt
    this.invoiceValues.patient_receiver = `patient_${this.data.patient_id}_`
    this.invoiceValues.accounting_receiver = `patient_${this.data.patient_id}_`

    this.patientReceiverSelected()

    this.patientReceiverOptions.push({
      value: `patient_${this.receiversData.first_patient.id}_`,
      email: this.receiversData.first_patient.email || 'Keine E-Mail',
      label: `${this.receiversData.first_patient.full_name} - Patient`,
      // disabled: this.values.type === 'email' && !this.receiversData.first_patient.email,
    })

    if (this.receiversData.second_patient) {
      this.patientReceiverOptions.push({
        value: `patient_${this.receiversData.second_patient.id}_`,
        email: this.receiversData.second_patient.email || 'Keine E-Mail',
        label: `${this.receiversData.second_patient.full_name} - Patient`,
        // disabled: this.values.type === 'email' && !receiversData.second_patient.email,
      })
    }

    const contactPersonsForInvoice = []

    for (let contactPerson of this.receiversData.customer.contact_persons) {
      // Ich erstelle mir außerdem eine Liste von allen Ansprechpartnern, die eine Rechnung haben wollen.
      if (contactPerson.is_for_invoices) {
        contactPersonsForInvoice.push(
          `${contactPerson.first_name} ${contactPerson.last_name}`
        )
      }

      if (contactPerson.email_private) {
        this.patientReceiverOptions.push({
          value: `ap_${contactPerson.id}_private`,
          email: `Private E-Mail: ${contactPerson.email_private}`,
          label: `${contactPerson.full_name} - AP`,
          disabled: false,
        })

        // continue
      }

      if (contactPerson.email_work) {
        this.patientReceiverOptions.push({
          value: `ap_${contactPerson.id}_work`,
          email: `Geschäftliche E-Mail: ${contactPerson.email_work}`,
          label: `${contactPerson.full_name} - AP`,
          disabled: false,
        })
      }

      // Wenn gar keine E-Mail hinterlegt ist.
      if (!contactPerson.email_private && !contactPerson.email_work) {
        this.patientReceiverOptions.push({
          value: `ap_${contactPerson.id}_`,
          email: 'Keine E-Mail',
          label: `${contactPerson.full_name} - AP`,
          // disabled: this.values.type === 'email',
        })
      }
    }

    this.contactPersonsForInvoice = contactPersonsForInvoice.join(', ')
  }

  public buildCCOptions(): void {
    this.ccOptions = []

    this.ccOptions.push({
      value: this.receiversData.first_patient.email,
      email: this.receiversData.first_patient.email || 'Keine E-Mail',
      label: `${this.receiversData.first_patient.full_name} - Patient`,
      disabled: !this.receiversData.first_patient.email,
    })

    if (this.receiversData.second_patient) {
      this.ccOptions.push({
        value: this.receiversData.second_patient.email,
        email: this.receiversData.second_patient.email || 'Keine E-Mail',
        label: `${this.receiversData.second_patient.full_name} - Patient`,
        disabled: !this.receiversData.second_patient.email,
      })
    }

    for (const contactPerson of this.receiversData.contact_persons) {
      if (contactPerson.email_private) {
        this.ccOptions.push({
          value: contactPerson.email_private,
          email: `Private E-Mail: ${contactPerson.email_private}`,
          label: `${contactPerson.full_name} - AP - Private E-Mail`,
          disabled: false,
        })
      }

      if (contactPerson.email_work) {
        this.ccOptions.push({
          value: contactPerson.email_work,
          email: `Geschäftliche E-Mail: ${contactPerson.email_work}`,
          label: `${contactPerson.full_name} - AP - Geschäftliche E-Mail`,
          disabled: false,
        })
      }

      // Wenn gar keine E-Mail hinterlegt ist.
      if (!contactPerson.email_private && !contactPerson.email_work) {
        this.ccOptions.push({
          value: null,
          email: 'Keine E-Mail',
          label: `${contactPerson.full_name} - AP - Keine E-Mail`,
          disabled: true,
        })
      }
    }
  }

  public stornoInvoice(): void {
    const ref = this.dialogService.open(ConfirmWithTextDialogComponent, {
      header: 'Entwurf wirklich stornieren?',
      width: '450px',
      styleClass: 'dialog-container',
      data: {
        isRequired: true,
      },
    })

    ref.onClose.subscribe((values: any) => {
      if (values) {
        this.submitted = true

        this.invoiceService
          .stornoProofStatus(this.data.proof_status.id, values.storno_comment)
          .subscribe(
            (response: any) => {
              this.eventbus.emit(GlobalEvent.InvoiceChanged)
              this.eventbus.emit(GlobalEvent.ProofsChanged)

              this.ref.close()

              this.toastService.success(
                'Entwurf storniert',
                'Die Rechnung wurde erfolgreich storniert'
              )
            },
            () => {
              this.toastService.error(
                'Etwas ist schiefgelaufen...',
                'Bitte wenden Sie sich an den Support'
              )
            }
          )
      }
    })
  }

  public invoiceReceiverChanged(): void {
    this.clickedOnPreviewMail = false
    this.clickedOnPreviewPdf = false

    if (this.invoiceValues.invoice_receiver !== 'patient') {
      if (this.invoiceValues.accounting_type === 'SEPA') {
        this.invoiceValues.accounting_type = ''
      }
    }

    if (this.invoiceValues.invoice_receiver === 'patient') {
      this.invoiceValues.patient_receiver = `patient_${this.data.patient_id}_`

      if (this.data.patient_first_sepa) {
        this.invoiceValues.accounting_type = 'SEPA'
      } else {
        this.invoiceValues.accounting_type = 'patient'
      }
    }

    if (this.invoiceValues.invoice_receiver === 'cost_unit') {
      this.invoiceValues.accounting_type = 'cost_unit'

      this.buildCostUnitReceiverOptions()
    }

    if (this.invoiceValues.invoice_receiver === 'care_insurance') {
      this.invoiceValues.send_type = 'no_sending'
      this.invoiceValues.accounting_type = 'accounting_center'
    }

    this.getPreviewLink()
  }

  /**
   * Prüft, ob alle Pflichtfelder im Tab "Budget" ausgewählt sind.
   */
  public dataChangedForBudgetStatus(): void {
    const minusBudget = this.data.budgets.find((budget: any) => {
      return budget.is_negative
    })

    if (
      (!minusBudget ||
        this.data.proof_status.has_confirm_minus_budget_checked) &&
      this.data.proof_status.has_budget_checked
    ) {
      this.dataForBudgetStatusIsValid = true
    } else {
      this.dataForBudgetStatusIsValid = false
    }
  }

  public activateSidebar(): void {
    this.showSidebar = true
  }

  /**
   * Übernimmt die Auswahl aus den gespeicherten Einstellungen.
   */
  public invoiceSettingUsed(): void {
    if (!this.data.invoice_setting) {
      return
    }

    this.invoiceValues.invoice_receiver = this.data.invoice_setting.invoice_receiver
    this.invoiceValues.accounting_type = this.data.invoice_setting.accounting_type
    this.invoiceValues.patient_receiver = this.data.invoice_setting.patient_receiver
    this.invoiceValues.cost_unit_receiver = this.data.invoice_setting.cost_unit_receiver
    this.invoiceValues.cost_unit_email_receiver = this.data.invoice_setting.cost_unit_email_receiver
    this.invoiceValues.accounting_receiver = this.data.invoice_setting.accounting_receiver
    this.invoiceValues.send_type = this.data.invoice_setting.send_type
    this.invoiceValues.cc_receivers = this.data.invoice_setting.cc_receivers
    this.invoiceValues.receiver = this.data.invoice_setting.receiver

    if (this.invoiceValues.cost_unit_receiver) {
      this.costUnitReceiverSelected()
    }

    this.getPreviewLink()
  }

  private setInvoiceFields(): void {
    this.invoiceValues.invoice_receiver = this.data.proof_status.invoice_receiver
    this.invoiceValues.accounting_type = this.data.proof_status.accounting_type
    this.invoiceValues.patient_receiver = this.data.proof_status.patient_receiver
    this.invoiceValues.cost_unit_receiver = this.data.proof_status.cost_unit_receiver
    this.invoiceValues.cost_unit_email_receiver = this.data.proof_status.cost_unit_email_receiver
    this.invoiceValues.accounting_receiver = this.data.proof_status.accounting_receiver
    this.invoiceValues.send_type = this.data.proof_status.send_type
    this.invoiceValues.cc_receivers = this.data.proof_status.cc_receivers
    this.invoiceValues.individual_email = this.data.proof_status.individual_email
    this.invoiceValues.individual_address = this.data.proof_status.individual_address
    this.invoiceValues.receiver = this.data.proof_status.receiver
    this.invoiceValues.individual_form_of_address = this.data.proof_status.individual_form_of_address
    this.invoiceValues.individual_first_name = this.data.proof_status.individual_first_name
    this.invoiceValues.individual_last_name = this.data.proof_status.individual_last_name
    this.invoiceValues.individual_street_house_number = this.data.proof_status.individual_street_house_number
    this.invoiceValues.individual_zipcode = this.data.proof_status.individual_zipcode
    this.invoiceValues.individual_city = this.data.proof_status.individual_city

    this.previousInvoice = this.data.proof_status.previous_invoice

    if (this.invoiceValues.cost_unit_receiver) {
      this.costUnitReceiverSelected()
    }

    this.getPreviewLink()
  }

  public checkForOpenedReworks(): void {
    const hasOpenedRework = this.data.proof_status.reworkings.find(
      (rework: any) => {
        return rework.status === 'OPENED'
      }
    )

    if (hasOpenedRework) {
      this.hasOpenedReworks = true
    } else {
      this.hasOpenedReworks = false
    }
  }

  public checkForBudgetIssueRework(): void {
    const hasBudgetIssueRework = this.data.proof_status.reworkings.find(
      (rework: any) => {
        return rework.type === 'BUDGET_ISSUE'
      }
    )

    if (hasBudgetIssueRework) {
      this.hasBudgetIssueRework = true
    } else {
      this.hasBudgetIssueRework = false
    }
  }

  /**
   * Prüft, ob alle Pflichtfelder im Tab "Prüfung" ausgewählt sind.
   */
  public dataChangedForCheckedStatus(): void {
    const uncheckedAppointment = this.data.persplan_budgets.appointments.find(
      (appointment: any) => {
        return !appointment.checked_proof
      }
    )

    const unreleasedAppointment = this.data.persplan_budgets.appointments.find(
      (appointment: any) => {
        return !appointment.released_id
      }
    )

    if (
      !unreleasedAppointment &&
      !uncheckedAppointment &&
      this.data.proof_status.has_signature &&
      this.data.proof_status.has_hand_sign &&
      this.data.proof_status.has_sum_hours
    ) {
      this.dataForCheckStatusIsValid = true
    } else {
      this.dataForCheckStatusIsValid = false
    }
  }

  public saveCheckedStatus(): void {
    this.submitted = true

    this.proofService
      .saveCheckedStatus(this.data, this.dataForCheckStatusIsValid)
      .subscribe(
        (response: any) => {
          this.data.proof_status = response

          if (this.dataForCheckStatusIsValid) {
            this.changeTab('BUDGET')
          }

          this.submitted = false
          this.eventbus.emit(GlobalEvent.ProofsChanged)
          this.toastService.success('Prüfung wurden gespeichert')
        },
        () => {
          this.toastService.error(
            'Etwas ist schiefgelaufen...',
            'Bitte wenden Sie sich an den Support'
          )

          this.submitted = false
        }
      )
  }

  public removeProof(): void {
    const confirm = window.confirm('Soll der LNW wirklich gelöscht werden?')

    if (confirm) {
      const toDeleteId = this.data.proofs[this.selectedGlobalTab.index].id

      this.documentService.remove(toDeleteId).subscribe(
        () => {
          this.data.proofs = this.data.proofs.filter((proof: any) => {
            return proof.id !== toDeleteId
          })

          this.selectedGlobalTab.index = 0

          this.imgSrc = this.documentService.getProofImageDocumentDownloadLink(
            this.data.proofs[0].uuid
          )

          if (this.viewer) {
            this.viewer.destroy()
          }

          setTimeout(() => {
            this.initViewer()
          }, 500)

          this.eventbus.emit(GlobalEvent.ProofsChanged)
          this.toastService.success(
            'LNW gelöscht',
            'Das Dokument wurde erfolgreich gelöscht'
          )
        },
        () => {
          this.toastService.error(
            'Löschen fehlgeschlagen',
            'Das Dokument konnte nicht gelöscht werden'
          )
        }
      )
    }
  }

  public openUploadSingleProofDialog(): void {
    const ref = this.dialogService.open(UploadSingleProofDialogComponent, {
      header: 'Leistungsnachweis ersetzen',
      width: '520px',
      styleClass: 'dialog-container',
      data: {
        ...this.data,
      },
    })

    ref.onClose.subscribe((data: any) => {
      if (data) {
        this.data.proofs[0] = data.media

        this.hasMedia = true

        this.imgSrc = this.documentService.getProofImageDocumentDownloadLink(
          data.media.uuid
        )

        this.getPreviewLink()

        if (this.viewer) {
          this.viewer.destroy()
        }

        setTimeout(() => {
          this.initViewer()
        }, 500)
      }
    })
  }

  public addProofRework(): void {
    if (!this.type.length || !this.comment) {
      alert('Bitte Typ und Kommentar eintragen')
      return
    }

    this.submitted = true

    this.proofService
      .addProofRework(this.data.proof_status.id, this.type, this.comment)
      .subscribe(
        (response: any) => {
          this.data.proof_status.reworkings.push(response)

          this.checkForOpenedReworks()
          this.checkForBudgetIssueRework()

          this.submitted = false
          this.comment = ''
          this.type = []

          this.eventbus.emit(GlobalEvent.ProofsChanged)
          // this.toastService.success('Erfolgreich')
        },
        () => {
          this.toastService.error(
            'Etwas ist schiefgelaufen...',
            'Bitte wenden Sie sich an den Support'
          )

          this.submitted = false
        }
      )
  }

  public openFeedbackDialog(data: any): void {
    this.dialogService.open(AppointmentFeedbackDialogComponent, {
      header: 'Feedback',
      width: '520px',
      styleClass: 'dialog-container',
      data: {
        ...data,
        readonly: true,
        fromProof: true,
      },
      dismissableMask: true,
    })
  }

  public saveBudgetStatus(): void {
    this.submitted = true

    this.proofService
      .saveBudgetStatus(this.data, this.dataForBudgetStatusIsValid)
      .subscribe(
        (response: any) => {
          this.data.proof_status = response

          if (this.dataForBudgetStatusIsValid) {
            this.changeTab('INVOICE')
          }

          this.submitted = false
          this.eventbus.emit(GlobalEvent.ProofsChanged)
          this.toastService.success('Budget wurde gespeichert')
        },
        () => {
          this.toastService.error(
            'Etwas ist schiefgelaufen...',
            'Bitte wenden Sie sich an den Support'
          )

          this.submitted = false
        }
      )
  }

  public sendInvoice(): void {
    if (this.data.proofs.length > 1) {
      alert('Es ist mehr als 1 LNW vorhanden')
      return
    }

    if (!this.invoiceValues.invoice_receiver) {
      alert('Bitte Rechnungsempfänger wählen')
      return
    }

    if (!this.invoiceValues.accounting_type) {
      alert('Bitte Abrechnungsstelle wählen')
      return
    }

    if (!this.invoiceValues.send_type) {
      alert('Bitte Versand wählen')
      return
    }

    if (!this.clickedOnPreviewPdf) {
      alert('Bitte PDF Vorschau ansehen')
      return
    }

    if (
      this.invoiceValues.send_type === 'email' &&
      !this.clickedOnPreviewMail
    ) {
      alert('Bitte Mail Vorschau ansehen')
      return
    }

    if (
      this.invoiceValues.invoice_receiver === 'patient' &&
      this.invoiceValues.send_type === 'post' &&
      !this.invoiceValues.receiver
    ) {
      alert('Bitte Empfänger wählen')
      return
    }

    // Wenn Individuell ausgewählt wurde, müssen alle Felder ausgefüllt sein.
    if (this.invoiceValues.invoice_receiver === 'other') {
      if (
        !this.invoiceValues.individual_zipcode ||
        !this.invoiceValues.individual_city ||
        !this.invoiceValues.individual_last_name ||
        !this.invoiceValues.individual_first_name ||
        !this.invoiceValues.individual_street_house_number ||
        !this.invoiceValues.individual_form_of_address
      ) {
        alert('Bitte alle individuellen Daten eintragen')
        return
      }

      if (
        this.invoiceValues.send_type === 'email' &&
        !this.invoiceValues.individual_email
      ) {
        alert('Bitte individuelle E-Mail eintragen')
        return
      }
    }

    // Prüfen, ob alle Daten bei der Pflegekasse für den Brief hinterlegt sind.
    if (this.invoiceValues.invoice_receiver === 'care_insurance') {
      const careInsurance = this.data.patient_care_insurance

      if (
        !careInsurance.name ||
        !careInsurance.street_house_number ||
        !careInsurance.zipcode ||
        !careInsurance.city
      ) {
        alert('Die Adresse bei der Pflegekasse ist unvollständig')
        return
      }
    }

    this.submitted = true

    const otherValues = this.buildOtherValues()

    const newInvoiceValues = {
      ...this.invoiceValues,
      ...otherValues,
    }

    alert('Bitte auch KM-Rechnungen überprüfen')

    this.invoiceService
      .createOpenedAppointmentInvoice(this.data, newInvoiceValues)
      .subscribe(
        (response: any) => {
          this.eventbus.emit(GlobalEvent.ProofsChanged)

          if (this.invoiceValues.send_type === 'email') {
            this.toastService.success('Rechnung wurde versendet')
            this.ref.close()
          } else {
            this.mediaGenerated = true
            this.generatedMediaLink = this.documentService.getDocumentDownloadLink(
              response.uuid
            )

            this.toastService.success('Rechnung wurde erstellt')
          }

          this.submitted = false
        },
        () => {
          this.toastService.error(
            'Etwas ist schiefgelaufen...',
            'Bitte wenden Sie sich an den Support'
          )

          this.submitted = false
        }
      )
  }

  public saveInvoiceStatus(): void {
    this.submitted = true

    this.proofService
      .saveInvoiceStatus(this.data, this.invoiceValues)
      .subscribe(
        (response: any) => {
          this.data.proof_status = response

          this.submitted = false
          this.eventbus.emit(GlobalEvent.ProofsChanged)
          this.toastService.success('Rechnung wurde gespeichert')
        },
        () => {
          this.toastService.error(
            'Etwas ist schiefgelaufen...',
            'Bitte wenden Sie sich an den Support'
          )

          this.submitted = false
        }
      )
  }

  public changeTab(type: string): void {
    this.activeTab = type

    if (type === 'INVOICE') {
      this.changeGlobalTab('INVOICE')
    }
  }

  public changeGlobalTab(type: string, index = 0): void {
    this.viewerLoading = false
    this.selectedGlobalTab.type = type
    this.selectedGlobalTab.index = index

    if (type === 'PROOF' && this.hasMedia) {
      this.viewerLoading = true

      this.imgSrc = this.documentService.getProofImageDocumentDownloadLink(
        this.data.proofs[index].uuid
      )

      if (this.viewer) {
        this.viewer.destroy()
      }

      setTimeout(() => {
        this.initViewer()
      }, 500)
    }

    if (type === 'STAMP') {
      this.documentService
        .getBase64FromImage(this.data.proofs[0].uuid)
        .subscribe((response: any) => {
          this.lnwBase64 = response.value

          this.addInteractEvent('stamp')
        })
    }
  }

  public openReleaseAppointmentAdminDialog(appointment: any): void {
    const ref = this.dialogService.open(
      ReleaseAppointmentAdminDialogComponent,
      {
        header: 'Einsatz freigeben',
        width: '420px',
        styleClass: 'dialog-container',
        data: {
          ...appointment,
        },
      }
    )

    ref.onClose.subscribe((response: any) => {
      if (response) {
        appointment.released_id = response.id
        appointment.from_admin = true

        this.dataChangedForCheckedStatus()
      }
    })
  }

  public openRemoveAdminAppointmentReleaseConfirm(appointment: any): void {
    if (confirm('Die Freigabe wirklich zurückziehen?')) {
      this.planningService
        .removeAdminAppointmentRelease(appointment.released_id)
        .subscribe(
          () => {
            this.eventbus.emit(GlobalEvent.ProofsChanged)
            this.toastService.success('Freigabe zurückgezogen')
            appointment.released_id = null
            appointment.from_admin = false
          },
          () => {
            this.toastService.error(
              'Etwas ist schiefgelaufen...',
              'Bitte wenden Sie sich an den Support'
            )
          }
        )
    }
  }

  public openFinishReworkDialog(rework: any): void {
    const ref = this.dialogService.open(FinishProofReworkDialogComponent, {
      header: 'Nachbearbeitung erledigen',
      width: '420px',
      styleClass: 'dialog-container',
      data: {
        ...rework,
      },
    })

    ref.onClose.subscribe((response: any) => {
      if (response) {
        this.data.proof_status.reworkings = this.data.proof_status.reworkings.filter(
          (reworking: any) => {
            return reworking.id !== response.id
          }
        )

        this.data.proof_status.reworkings.push(response)

        this.checkForOpenedReworks()
        this.checkForBudgetIssueRework()
      }
    })
  }

  private initViewer(): void {
    const element = document.getElementById('image')

    if (element) {
      // @ts-ignore
      this.viewer = new Viewer(element, {
        inline: true,
        transition: true,
        navbar: false,
        title: false,
        scalable: false,
        tooltip: false,
        fullscreen: false,
        backdrop: false,
        button: false,
        toolbar: {
          zoomIn: true,
          zoomOut: true,
          oneToOne: false,
          reset: false,
          prev: false,
          play: {
            show: false,
            size: 'large',
          },
          next: false,
          rotateLeft: true,
          rotateRight: false,
          flipHorizontal: false,
          flipVertical: false,
        },
        rotate: (event: Viewer.RotateEvent) => {
          this.rotating = true

          this.documentService
            .rotateImage(
              this.data.proofs[this.selectedGlobalTab.index].id,
              event.detail.degree
            )
            .subscribe(() => {
              this.rotating = false
            })
        },
        viewed: () => {
          this.viewerLoading = false
          // @ts-ignore
          // if (element.height > element.width) {
          // this.viewer.rotate(90)
          // }
        },
      })

      this.viewerLoading = false
      this.viewer.show()
    } else {
      setTimeout(() => {
        this.initViewer()
      }, 1000)
    }
  }
}
