import { Component, OnDestroy, OnInit } from '@angular/core'
import { ActivatedRoute, ParamMap } from '@angular/router'
import { DialogService } from 'primeng/dynamicdialog'
import {
  EventBusService,
  GlobalEvent,
} from '../../../../services/eventbus.service'
import { Subscription } from 'rxjs'
import { PatientService } from '../../../../services/patient.service'
import { CustomerDetailGeneralModel } from '../../../../models/customer-patient/customer-detail-general.model'
import { ConsultingConfirmDialogComponent } from '../../../../components/dialogs/consulting-confirm-dialog/consulting-confirm-dialog.component'
import { ConsultingDialogComponent } from '../../../../components/dialogs/consulting-dialog/consulting-dialog.component'
import { ConsultingService } from '../../../../services/consulting.service'
import { InvoiceDialogComponent } from '../../../../components/dialogs/invoice-dialog/invoice-dialog.component'
import { InvoiceViewDialogComponent } from '../../../../components/dialogs/invoice-view-dialog/invoice-view-dialog.component'
import { InvoiceNewDialogComponent } from '../../../../components/dialogs/invoice-new-dialog/invoice-new-dialog.component'
import {AuthService} from "../../../../services/auth.service";

@Component({
  selector: 'app-patients-detail-consulting',
  templateUrl: './patients-detail-consulting.component.html',
  providers: [DialogService],
})
export class PatientsDetailConsultingComponent implements OnInit, OnDestroy {
  public loading = true

  public patients: CustomerDetailGeneralModel = new CustomerDetailGeneralModel()
  private patientId: string | null = null
  public selectedPatient: 'first_patient' | 'second_patient' = 'first_patient'

  private eventBusSubscription: Subscription = new Subscription()

  constructor(
    public authService: AuthService,
    public patientService: PatientService,
    private consultingService: ConsultingService,
    private route: ActivatedRoute,
    private eventbus: EventBusService,
    private dialogService: DialogService
  ) {}

  ngOnInit(): void {
    this.load()
    this.listenForEventbus()
  }

  ngOnDestroy(): void {
    this.eventBusSubscription.unsubscribe()
  }

  private listenForEventbus(): void {
    this.eventBusSubscription = this.eventbus.subject.subscribe(
      (event: GlobalEvent) => {
        switch (event) {
          case GlobalEvent.ConsultingChanged:
          case GlobalEvent.InvoiceChanged:
            this.load(false)
        }
      }
    )
  }

  public openMediaFromUuid(uuid: string): void {
    window.open(this.consultingService.getDocumentDownloadLink(uuid))
  }

  public openInvoiceDialog(invoice: any): void {
    if (invoice.status !== 'READY') {
      this.dialogService.open(InvoiceViewDialogComponent, {
        header: 'Rechnung',
        width: '96%',
        height: '90%',
        styleClass: 'dialog-container',
        data: {
          invoice,
        },
      })
    } else {
      this.dialogService.open(InvoiceNewDialogComponent, {
        header: 'Rechnung',
        width: '96%',
        height: '90%',
        styleClass: 'dialog-container',
        data: {
          invoice,
        },
      })
    }
  }

  public openConsultingConfirmDialog(consulting: any): void {
    this.dialogService.open(ConsultingConfirmDialogComponent, {
      header: 'Beratungseinsatz bestätigen',
      width: '490px',
      styleClass: 'dialog-container',
      data: {
        consulting: { ...consulting },
      },
    })
  }

  public openConsultingDialog(): void {
    this.dialogService.open(ConsultingDialogComponent, {
      header: 'Neuer Beratungseinsatz',
      width: '650px',
      styleClass: 'dialog-container',
      data: {
        recommendation: this.patients.customer.recommendation,
        recommendation_id: this.patients.customer.recommendation_id,
        recommendation_type: this.patients.customer.recommendation_type,
        isNew: true,
        patient: { ...this.patients[this.selectedPatient] },
      },
    })
  }

  public openConsultingEditDialog(consulting: any): void {
    this.dialogService.open(ConsultingDialogComponent, {
      header: 'Beratungseinsatz bearbeiten',
      width: '650px',
      styleClass: 'dialog-container',
      data: {
        isNew: false,
        consulting: { ...consulting },
        patient: { ...this.patients[this.selectedPatient] },
      },
    })
  }

  public changePatient(type: 'first_patient' | 'second_patient'): void {
    this.selectedPatient = type
  }

  public load(withLoading: boolean = true): void {
    if (withLoading) {
      this.loading = true
    }

    if (this.route.parent) {
      this.patientId = this.route.parent.snapshot.params.id

      this.consultingService.load(this.patientId).subscribe((data: any) => {
        this.patients = data

        if (withLoading) {
          this.loading = false
        }
      })
    }
  }
}
