import { Component, ElementRef, OnInit, ViewChild } from '@angular/core'
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog'
import { PatientService } from '../../../services/patient.service'
import { NgForm } from '@angular/forms'
import { UserCaregiverService } from '../../../services/user-caregiver.service'

@Component({
  selector: 'app-anonymous-appointments-dialog',
  templateUrl: './anonymous-appointments-dialog.component.html',
})
export class AnonymousAppointmentsDialogComponent implements OnInit {
  @ViewChild('form', { static: true }) form!: NgForm
  @ViewChild('dt') table: ElementRef | any = null

  public loading = true
  public globalFilterValue = ''

  // TODO: Model erstellen
  public data = []

  constructor(
    public userCaregiverService: UserCaregiverService,
    private ref: DynamicDialogRef,
    private patientService: PatientService,
    private config: DynamicDialogConfig
  ) {}

  public ngOnInit(): void {
    this.userCaregiverService
      .loadAnonymousAppointments(this.config.data.persplanId)
      .subscribe((data: any) => {
        this.data = data

        this.loading = false
      })
  }
}
