import { Component, OnInit } from '@angular/core'
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog'
import { ToastService } from 'src/app/services/toast.service'
import { UserCaregiverService } from '../../../services/user-caregiver.service'
import {
  EventBusService,
  GlobalEvent,
} from '../../../services/eventbus.service'

@Component({
  selector: 'app-approve-overview-dialog',
  templateUrl: './caregiver-approve-overview-dialog.component.html',
})
export class CaregiverApproveOverviewDialogComponent implements OnInit {
  comment = ''
  submitted = false
  submittedDelete = false

  constructor(
    private userCaregiverService: UserCaregiverService,
    private ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private eventbus: EventBusService,
    private toastService: ToastService
  ) {}

  public ngOnInit(): void {}

  public save(): void {
    this.submitted = true

    this.userCaregiverService
      .approveOverview(
        this.comment,
        this.config.data.month,
        this.config.data.year
      )
      .subscribe(
        () => {
          this.submitted = false
          this.eventbus.emit(GlobalEvent.CaregiverOverviewChanged)
          this.ref.close()
          this.toastService.success('Dienstplan freigegeben')
        },
        () => {
          this.toastService.error(
            'Etwas ist schiefgelaufen...',
            'Bitte wenden Sie sich an den Support'
          )

          this.submitted = false
        }
      )
  }
}
