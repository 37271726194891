import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, ParamMap } from '@angular/router'
import { TitleService } from 'src/app/services/title.service'
import { MultiplierService } from '../../../services/multiplier.service'
import { MultiplierDetailModel } from '../../../models/multiplier/multiplier-detail.model'
import { NoticeDialogComponent } from '../../../components/dialogs/notice-dialog/notice-dialog.component'
import { DialogService } from 'primeng/dynamicdialog'
import {
  EventBusService,
  GlobalEvent,
} from '../../../services/eventbus.service'
import { Subscription } from 'rxjs'

@Component({
  selector: 'app-multipliers-detail',
  templateUrl: './multipliers-detail.component.html',
  styleUrls: ['./multipliers-detail.component.scss'],
})
export class MultipliersDetailComponent implements OnInit {
  public multiplierDetail: MultiplierDetailModel = new MultiplierDetailModel()
  private eventBusSubscription: Subscription = new Subscription()

  constructor(
    private route: ActivatedRoute,
    private dialogService: DialogService,
    private eventbus: EventBusService,
    private multiplierService: MultiplierService,
    private titleService: TitleService
  ) {}

  ngOnInit(): void {
    this.loadMultiplierDetails()
    this.listenForEventbus()
  }

  ngOnDestroy(): void {
    this.eventBusSubscription.unsubscribe()
  }

  public loadMultiplierDetails(): void {
    this.route.paramMap.subscribe((params: ParamMap) => {
      this.multiplierService
        .details(params.get('id'))
        .subscribe((data: MultiplierDetailModel) => {
          this.multiplierDetail = data
          this.titleService.setTitle(`${data.name}`)
        })
    })
  }

  public openNoticeDialog(): void {
    this.dialogService.open(NoticeDialogComponent, {
      header: 'Notiz bearbeiten',
      width: '450px',
      styleClass: 'dialog-container',
      data: {
        type: 'multiplier',
        type_id: this.multiplierDetail.id,
        notice: this.multiplierDetail.notice,
      },
    })
  }

  private listenForEventbus(): void {
    this.eventBusSubscription = this.eventbus.subject.subscribe((event) => {
      switch (event) {
        case GlobalEvent.NoticeChanged:
        case GlobalEvent.MultiplierStatusChanged:
          this.loadMultiplierDetails()
          break
      }
    })
  }
}
