import { Component, ElementRef, OnInit, ViewChild } from '@angular/core'
import { Router } from '@angular/router'
import { TitleService } from 'src/app/services/title.service'
import { PatientService } from '../../../services/patient.service'
import { FilterService, MenuItem } from 'primeng/api'

@Component({
  selector: 'app-patients-inactive',
  templateUrl: './patients-inactive.component.html',
})
export class PatientsInactiveComponent implements OnInit {
  @ViewChild('dt') table: ElementRef | any = null

  public globalFilterValue = ''
  public loading = false
  public firstPage = 0

  // TODO: model erstellen
  public patients: any[] = []

  public selectedPatient: any
  public contextItems: MenuItem[] = [
    {
      label: 'Im neuen Tab öffnen',
      icon: 'pi pi-external-link',
      command: () =>
        window.open(`/patients/${this.selectedPatient.id}`, '_blank'),
    },
  ]

  constructor(
    private filterService: FilterService,
    private patientService: PatientService,
    private router: Router,
    private titleService: TitleService
  ) {}

  ngOnInit(): void {
    this.loadUsers()
    this.titleService.setTitle('Inaktive Kunden')
  }

  public filterTableGlobal(event: any): void {
    this.table && this.table.filterGlobal(event.target.value.trim(), 'contains')
  }

  public navigateToDetail(id: number): void {
    this.router.navigate(['/patients', id])
  }

  private loadUsers(): void {
    this.loading = true

    this.patientService.inactive().subscribe((data: any[]) => {
      this.patients = data

      this.loading = false

      setTimeout(() => {
        if (this.table) {
          const totalItems = this.table._totalRecords

          this.globalFilterValue = this.table.filters.global?.value ?? ''

          if (this.table._first > totalItems || this.table._rows > totalItems) {
            this.firstPage = 0
          }
        }
      })
    })
  }
}
