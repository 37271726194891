import { AuthGuard } from '../guards/auth.guard'
import { AccountingReturnsComponent } from '../views/accounting/returns/accounting-returns.component'
import { AccountingReturnsCreatedComponent } from '../views/accounting/returns/created/accounting-returns-created.component'
import { AccountingReturnsEditedComponent } from '../views/accounting/returns/edited/accounting-returns-edited.component'
import { AccountingReturnsFinishedComponent } from '../views/accounting/returns/finished/accounting-returns-finished.component'
import { InvoicesComponent } from '../views/invoices/invoices.component'
import { InvoicesKmComponent } from '../views/invoices/km/invoices-km.component'
import { InvoicesKmDraftsComponent } from '../views/invoices/km/drafts/invoices-km-drafts.component'
import { InvoicesKmOpenedComponent } from '../views/invoices/km/opened/invoices-km-opened.component'
import { InvoicesKmFinishedComponent } from '../views/invoices/km/finished/invoices-km-finished.component'
import { InvoicesKmReadyComponent } from '../views/invoices/km/ready/invoices-km-ready.component'
import { InvoicesKmStornoComponent } from '../views/invoices/km/storno/invoices-km-storno.component'
import { InvoicesKmCreditComponent } from '../views/invoices/km/credit/invoices-km-credit.component'
import { InvoicesConsultingsComponent } from '../views/invoices/consultings/invoices-consultings.component'
import { InvoicesConsultingsOpenedComponent } from '../views/invoices/consultings/opened/invoices-consultings-opened.component'
import { InvoicesConsultingsFinishedComponent } from '../views/invoices/consultings/finished/invoices-consultings-finished.component'
import { InvoicesConsultingsCreditComponent } from '../views/invoices/consultings/credit/invoices-consultings-credit.component'
import { InvoicesConsultingsStornoComponent } from '../views/invoices/consultings/storno/invoices-consultings-storno.component'
import { InvoicesConsultingsReadyComponent } from '../views/invoices/consultings/ready/invoices-consultings-ready.component'
import { InvoicesAppointmentsComponent } from '../views/invoices/appointments/invoices-appointments.component'
import { InvoicesAppointmentsDraftsComponent } from '../views/invoices/appointments/drafts/invoices-appointments-drafts.component'
import { InvoicesAppointmentsOpenedComponent } from '../views/invoices/appointments/opened/invoices-appointments-opened.component'
import { InvoicesAppointmentsFinishedComponent } from '../views/invoices/appointments/finished/invoices-appointments-finished.component'
import { InvoicesAppointmentsCreditComponent } from '../views/invoices/appointments/credit/invoices-appointments-credit.component'
import { InvoicesAppointmentsStornoComponent } from '../views/invoices/appointments/storno/invoices-appointments-storno.component'

export const invoicesRoutes = [
  {
    path: 'invoices',
    component: InvoicesComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        redirectTo: 'appointments',
        pathMatch: 'full',
      },
      {
        path: 'km',
        component: InvoicesKmComponent,
        children: [
          {
            path: '',
            component: InvoicesKmDraftsComponent,
          },
          {
            path: 'opened',
            component: InvoicesKmOpenedComponent,
          },
          {
            path: 'finished',
            component: InvoicesKmFinishedComponent,
          },
          {
            path: 'ready',
            component: InvoicesKmReadyComponent,
          },
          {
            path: 'storno',
            component: InvoicesKmStornoComponent,
          },
          {
            path: 'credits',
            component: InvoicesKmCreditComponent,
          },
        ],
      },
      {
        path: 'appointments',
        component: InvoicesAppointmentsComponent,
        children: [
          {
            path: '',
            component: InvoicesAppointmentsDraftsComponent,
          },
          {
            path: 'opened',
            component: InvoicesAppointmentsOpenedComponent,
          },
          {
            path: 'finished',
            component: InvoicesAppointmentsFinishedComponent,
          },
          {
            path: 'credits',
            component: InvoicesAppointmentsCreditComponent,
          },
          {
            path: 'storno',
            component: InvoicesAppointmentsStornoComponent,
          },
        ],
      },
      {
        path: 'consultings',
        component: InvoicesConsultingsComponent,
        children: [
          {
            path: '',
            component: InvoicesConsultingsReadyComponent,
          },
          {
            path: 'opened',
            component: InvoicesConsultingsOpenedComponent,
          },
          {
            path: 'finished',
            component: InvoicesConsultingsFinishedComponent,
          },
          {
            path: 'storno',
            component: InvoicesConsultingsStornoComponent,
          },
          {
            path: 'credits',
            component: InvoicesConsultingsCreditComponent,
          },
        ],
      },
      {
        path: 'returns',
        component: AccountingReturnsComponent,
        children: [
          {
            path: '',
            component: AccountingReturnsCreatedComponent,
          },
          {
            path: 'edited',
            component: AccountingReturnsEditedComponent,
          },
          {
            path: 'finished',
            component: AccountingReturnsFinishedComponent,
          },
        ],
      },
    ],
  },
]
