<div class="content">
  <header class="content-header">
    <div class="content-header-menu-tabs">
      <a
        routerLink="/invoices/km"
        routerLinkActive="active"
        [routerLinkActiveOptions]="{ exact: true }"
      >
        Entwürfe
      </a>

      <a
        routerLink="/invoices/km/ready"
        routerLinkActive="active"
        [routerLinkActiveOptions]="{ exact: true }"
      >
        Zum Versand
      </a>

      <a
        routerLink="/invoices/km/opened"
        routerLinkActive="active"
        [routerLinkActiveOptions]="{ exact: true }"
      >
        Offen
      </a>

      <a
        routerLink="/invoices/km/finished"
        routerLinkActive="active"
        [routerLinkActiveOptions]="{ exact: true }"
      >
        Abgeschlossen
      </a>

      <a
        routerLink="/invoices/km/credits"
        routerLinkActive="active"
        [routerLinkActiveOptions]="{ exact: true }"
      >
        Gutschriften
      </a>

      <a
        routerLink="/invoices/km/storno"
        routerLinkActive="active"
        [routerLinkActiveOptions]="{ exact: true }"
      >
        Nicht fakturiert
      </a>
    </div>
  </header>
</div>

<router-outlet></router-outlet>
