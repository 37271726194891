<form #form="ngForm" class="dialog-form">
  <div class="dialog-loader white-background" *ngIf="isParsing">
    <i class="loading-logo"></i>
    DOKUMENT WIRD HOCHGELADEN
  </div>

  <div class="dialog-form-body">
    <div class="p-fluid p-formgrid p-grid" *ngIf="!isParsed">
      <div class="p-field p-col-12" *ngIf="!hasMedia">
        <label for="cover_key">Kennzeichen *</label>
        <input
          (keyup)="coverKey = coverKey.toUpperCase()"
          id="cover_key"
          name="cover_key"
          type="text"
          placeholder="Bitte eintragen"
          pInputText
          [(ngModel)]="coverKey"
          [required]="true"
        />
      </div>

      <div
        class="p-field p-col-12"
        style="margin: 0"
        *ngIf="hasMedia || coverKey"
      >
        <label for="file">Datei auswählen</label>
        <input
          (change)="setSelectedFile($event)"
          id="file"
          class="file-input-special"
          name="file"
          type="file"
          pInputText
        />
      </div>
    </div>
  </div>

  <div class="dialog-form-footer" *ngIf="isParsed">
    <i></i>
  </div>
</form>
