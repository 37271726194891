import { Component, ElementRef, OnInit, ViewChild } from '@angular/core'
import { UserCaregiverService } from '../../../../services/user-caregiver.service'
import { CaregiverDetailGeneralDataModel } from '../../../../models/caregiver/caregiver-detail-general.model'

@Component({
  selector: 'app-user-caregiver-data-contract-data',
  templateUrl: './user-caregiver-data-contract-data.component.html',
})
export class UserCaregiverDataContractDataComponent implements OnInit {
  @ViewChild('op') overlayPanel: ElementRef | any = null

  public loading = false

  public data: CaregiverDetailGeneralDataModel = new CaregiverDetailGeneralDataModel()
  public openedContactPersons: { [index: number]: boolean } = {}
  public currentOverlayValue = ''

  constructor(public userCaregiverService: UserCaregiverService) {}

  ngOnInit(): void {
    this.loadData()
  }

  public toggleOverlay(event: any, value: any): void {
    this.currentOverlayValue = value

    this.overlayPanel.toggle(event)
  }

  public loadData(withLoading: boolean = true): void {
    if (withLoading) {
      this.loading = true
    }

    this.userCaregiverService
      .loadData()
      .subscribe((data: CaregiverDetailGeneralDataModel) => {
        this.data = data

        if (withLoading) {
          this.loading = false
        }
      })
  }
}
