export class PatientPauseModel {
  id: number | null = null
  active = true
  created_from: number | null = null
  patient_id: number | null = null
  pause_from: Date | null = null
  pause_to: Date | null = null
  comment = ''
  pause_from_string = ''
  pause_to_string = ''
  created_at: Date | null = null
  updated_at: Date | null = null
}
