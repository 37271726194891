import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { environment } from '../../environments/environment'
import { Observable } from 'rxjs'
import { HistoryListModel } from '../models/history/history-list.model'
import { MultiplierListModel } from '../models/multiplier/multiplier-list.model'
import { MultiplierFormModel } from '../models/multiplier/multiplier-form.model'
import { MultiplierDependenciesModel } from '../models/multiplier/multiplier-dependencies.model'
import { MultiplierDetailModel } from '../models/multiplier/multiplier-detail.model'
import { MultiplierDetailGeneralModel } from '../models/multiplier/multiplier-detail-general.model'

@Injectable({
  providedIn: 'root',
})
export class MultiplierService {
  constructor(private http: HttpClient) {}

  public list(): Observable<MultiplierListModel[]> {
    return this.http.get<MultiplierListModel[]>(
      `${environment.api}/multipliers`
    )
  }

  public load(id: string | null): Observable<MultiplierDetailGeneralModel> {
    return this.http.get<MultiplierDetailGeneralModel>(
      `${environment.api}/multipliers/${id}`
    )
  }

  public loadForEditMode(id: string | null): Observable<MultiplierFormModel> {
    return this.http.get<MultiplierFormModel>(
      `${environment.api}/multipliers/${id}/edit`
    )
  }

  public history(id: string | null): Observable<HistoryListModel[]> {
    return this.http.get<HistoryListModel[]>(
      `${environment.api}/multipliers/${id}/history`
    )
  }

  public details(id: string | null): Observable<MultiplierDetailModel> {
    return this.http.get<MultiplierDetailModel>(
      `${environment.api}/multipliers/${id}/details`
    )
  }

  public update(data: MultiplierFormModel): Observable<MultiplierFormModel> {
    return this.http.put<MultiplierFormModel>(
      `${environment.api}/multipliers/${data.id}`,
      data
    )
  }

  public store(data: MultiplierFormModel): Observable<MultiplierFormModel> {
    return this.http.post<MultiplierFormModel>(
      `${environment.api}/multipliers`,
      data
    )
  }

  public dependencies(): Observable<MultiplierDependenciesModel> {
    return this.http.get<MultiplierDependenciesModel>(
      `${environment.api}/multipliers/dependencies`
    )
  }

  public deactivate(id: string | null): Observable<MultiplierDetailModel> {
    return this.http.put<MultiplierDetailModel>(
      `${environment.api}/multipliers/${id}/deactivate`,
      {}
    )
  }

  public activate(id: string | null): Observable<MultiplierDetailModel> {
    return this.http.put<MultiplierDetailModel>(
      `${environment.api}/multipliers/${id}/activate`,
      {}
    )
  }
}
