import { Component, OnDestroy, OnInit } from '@angular/core'
import { ActivatedRoute, ParamMap } from '@angular/router'
import { TitleService } from 'src/app/services/title.service'
import { DialogService } from 'primeng/dynamicdialog'
import { EventBusService } from '../../../../services/eventbus.service'
import { Subscription } from 'rxjs'
import { PatientService } from '../../../../services/patient.service'
import { CustomerDetailModel } from '../../../../models/customer-patient/customer-detail.model'
import { environment } from '../../../../../environments/environment'
import { UserMultiplierService } from '../../../../services/user-multiplier.service'

@Component({
  selector: 'app-user-multiplier-patients-detail',
  templateUrl: './user-multiplier-patients-detail.component.html',
  styleUrls: ['./user-multiplier-patients-detail.component.scss'],
})
export class UserMultiplierPatientsDetailComponent
  implements OnInit, OnDestroy {
  public customerDetail: CustomerDetailModel = new CustomerDetailModel()
  private eventBusSubscription: Subscription = new Subscription()

  constructor(
    private route: ActivatedRoute,
    private dialogService: DialogService,
    private eventbus: EventBusService,
    private userMultiplierService: UserMultiplierService,
    private titleService: TitleService
  ) {}

  ngOnInit(): void {
    this.loadPatientDetails()
    this.listenForEventbus()
  }

  ngOnDestroy(): void {
    this.eventBusSubscription.unsubscribe()
  }

  public loadPatientDetails(): void {
    this.route.paramMap.subscribe((params: ParamMap) => {
      this.userMultiplierService
        .loadPatientDetails(params.get('id'))
        .subscribe((data: CustomerDetailModel) => {
          this.customerDetail = data
          this.titleService.setTitle(
            `${data.first_patient.form_of_address} ${data.first_patient.first_name} ${data.first_patient.last_name}`
          )
        })
    })
  }

  private listenForEventbus(): void {
    // this.eventBusSubscription = this.eventbus.subject.subscribe((event) => {
    //   switch (event) {
    //     case GlobalEvent.NoticeChanged:
    //       this.loadPatientDetails()
    //       break
    //   }
    // })
  }
}
