<form #form="ngForm" class="dialog-form">
  <div class="dialog-loader white-background" *ngIf="loading">
    <i class="loading-logo"></i>
  </div>

  <div class="dialog-form-body">
    <div class="p-field">
      <label for="budget">Budget</label>
      <p-dropdown
        appendTo="body"
        scrollHeight="200px"
        placeholder="Bitte wählen"
        inputId="budget"
        name="budget"
        [options]="budgetOptions"
        [(ngModel)]="budget"
      ></p-dropdown>
    </div>
  </div>
  <div class="dialog-form-footer">
    <i></i>

    <button
      pButton
      label="Speichern"
      type="submit"
      (click)="save()"
      [disabled]="submitted"
      [icon]="submitted ? 'pi pi-spin pi-spinner' : 'pi pi-check'"
    ></button>
  </div>
</form>
<p-confirmPopup></p-confirmPopup>
