import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core'
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog'
import { EventBusService, GlobalEvent } from 'src/app/services/eventbus.service'
import { ToastService } from 'src/app/services/toast.service'
import { CustomerDetailGeneralModel } from '../../../models/customer-patient/customer-detail-general.model'
import { NgForm } from '@angular/forms'
import { Subscription } from 'rxjs'
import { PatientService } from '../../../services/patient.service'
import { PatientPauseModel } from '../../../models/customer-patient/patient-pause.model'
import {PlanningService} from "../../../services/planning.service";

@Component({
  selector: 'app-pause-dialog',
  templateUrl: './pause-dialog.component.html',
})
export class PauseDialogComponent implements OnInit, OnDestroy {
  @ViewChild('form', { static: true }) form!: NgForm

  public isEdit = false
  public customer: CustomerDetailGeneralModel = new CustomerDetailGeneralModel()
  public currentPause: PatientPauseModel = new PatientPauseModel()
  private formSubscription: Subscription | null | undefined = null
  private isDirty = false

  submitted = false
  submittedDelete = false
  public appointments: any[] = []

  public patientOptions: any[] = []
  public values = {
    patients: [] as any,
    pause_from_string: '',
    pause_to_string: '',
    status: '',
    comment: '',
  }

  constructor(
    private ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private patientService: PatientService,
    private planningService: PlanningService,
    private eventbus: EventBusService,
    private toastService: ToastService
  ) {}

  public ngOnInit(): void {
    this.customer = this.config.data.customer

    if (this.config.data.currentPause) {
      this.currentPause = this.config.data.currentPause
      this.isEdit = true

      this.values.pause_to_string = this.currentPause.pause_to_string
      this.values.pause_from_string = this.currentPause.pause_from_string
      this.values.comment = this.currentPause.comment
      this.values.status = 'PAUSED'
    }

    this.formSubscription = this.form.valueChanges?.subscribe(() => {
      if (!this.form.pristine) {
        this.isDirty = true
      }
    })

    this.values.patients.push({
      id: this.customer.first_patient.id,
      full_name: this.customer.first_patient.full_name,
    })

    this.patientOptions.push({
      id: this.customer.first_patient.id,
      full_name: this.customer.first_patient.full_name,
    })

    if (this.customer.second_patient) {
      this.patientOptions.push({
        id: this.customer.second_patient.id,
        full_name: this.customer.second_patient.full_name,
      })
    }
  }

  ngOnDestroy(): void {
    this.formSubscription?.unsubscribe()
  }

  public loadPatientsAppointments(): void {
    this.appointments = []

    const patientIds = this.values.patients.map((patient: any) => patient.id)

    this.planningService
      .loadPatientsAppointmentsSinceDate(
        patientIds,
        this.values.pause_from_string,
        this.values.pause_to_string
      )
      .subscribe((response: any[]) => {
        this.appointments = response
      })
  }

  public save(): void {
    if (!this.form.form.valid) {
      this.submitted = false
      this.form.form.markAllAsTouched()
      return
    }

    this.submitted = true

    const subscription =
      this.values.status === 'PAUSED'
        ? this.patientService.pause(this.values)
        : this.patientService.reactivate(this.values)

    subscription.subscribe(
      () => {
        this.submitted = false
        this.eventbus.emit(GlobalEvent.PatientStatusChanged)
        this.ref.close()
        this.toastService.success(
          'Status geändert',
          'Der Status wurde erfolgreich geändert'
        )
      },
      () => {
        this.toastService.error(
          'Etwas ist schiefgelaufen...',
          'Bitte wenden Sie sich an den Support'
        )

        this.submitted = false
      }
    )
  }

  public saveEdit(): void {
    if (!this.form.form.valid) {
      this.submitted = false
      this.form.form.markAllAsTouched()
      return
    }

    this.submitted = true

    const subscription =
      this.values.status === 'PAUSED'
        ? this.patientService.pauseEdit(this.currentPause, this.values)
        : this.patientService.reactivateEdit(this.currentPause, this.values)

    subscription.subscribe(
      () => {
        this.submitted = false
        this.eventbus.emit(GlobalEvent.PatientStatusChanged)
        this.ref.close()
        this.toastService.success(
          'Status geändert',
          'Der Status wurde erfolgreich geändert'
        )
      },
      () => {
        this.toastService.error(
          'Etwas ist schiefgelaufen...',
          'Bitte wenden Sie sich an den Support'
        )

        this.submitted = false
      }
    )
  }
}
