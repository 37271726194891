<div *ngIf="caregiverDetails.notice" class="notice-wrap">
  <i class="pi pi-paperclip notice-wrap-drag-handle notice-icon"></i>
  <div
    [class.show-more]="showMoreNotice"
    (click)="openNoticeDialog()"
    class="notice-text"
  >
    {{ caregiverDetails.notice }}
  </div>
  <span
    (click)="showMoreNotice = !showMoreNotice"
    [pTooltip]="showMoreNotice ? 'Weniger anzeigen' : 'Mehr anzeigen'"
    tooltipPosition="bottom"
    class="notice-action"
    ><i
      class="pi"
      [class.pi-chevron-down]="!showMoreNotice"
      [class.pi-chevron-up]="showMoreNotice"
    ></i
  ></span>
</div>

<div class="content-actions">
  <h2 *ngIf="caregiverDetails.id">
    {{ caregiverDetails.form_of_address }} {{ caregiverDetails.first_name }}
    {{ caregiverDetails.last_name }}

    <i
      *ngIf="caregiverDetails.user && caregiverDetails.user.deleted_at"
      class="p-tag p-tag-danger p-tag-rounded"
      ><i class="pi pi-user"></i>Account inaktiv</i
    >

    <i
      *ngIf="caregiverDetails.user && !caregiverDetails.user.deleted_at"
      class="p-tag p-tag-rounded"
      ><i class="pi pi-user"></i>Account aktiv</i
    >

    <i
      class="p-tag p-tag-rounded p-tag-{{
        caregiverDetails.current_status.status
      }}"
      >{{ caregiverDetails.current_status.status_text }}</i
    >
  </h2>

  <a
    routerLink="/caregivers/{{ caregiverDetails.id }}/edit"
    *ngIf="caregiverDetails.id"
  >
    <button
      pButton
      label="Bearbeiten"
      class="p-button-text"
      type="button"
      icon="pi pi-pencil"
      iconPos="right"
    ></button>
  </a>
</div>

<header class="content-header-tabs" [class.disabled]="!caregiverDetails.id">
  <div class="arrow-navigation">
    <i
      class="pi pi-chevron-left"
      [pTooltip]="getNextCaregiverName('previous') + '\n(ALT + LINKS)'"
      tooltipPosition="left"
      (click)="goToNextCaregiver('previous')"
    ></i>
    <i
      class="pi pi-chevron-right"
      [pTooltip]="getNextCaregiverName('next') + '\n(ALT + RECHTS)'"
      tooltipPosition="left"
      (click)="goToNextCaregiver('next')"
    ></i>

    <p-dropdown
      [style]="{ width: '250px' }"
      scrollHeight="500px"
      [filter]="true"
      inputId="caregivers"
      name="caregivers"
      placeholder="Bitte auswählen"
      [resetFilterOnHide]="true"
      [options]="
        caregiverDetails.deleted_at ? caregivers_deactivated : caregivers_active
      "
      [(ngModel)]="currentCaregiverId"
      optionValue="id"
      optionLabel="last_first_name"
      (onChange)="navigateToCaregiver($event)"
    ></p-dropdown>
  </div>

  <a
    routerLink="/caregivers/{{ caregiverDetails.id }}"
    [class.active]="defaultTabIsActive"
    >Allgemeines</a
  >
  <a
    routerLink="/caregivers/{{ caregiverDetails.id }}/overview"
    routerLinkActive="active"
  >
    Leistungsbereitschaft
  </a>
  <a
    routerLink="/caregivers/{{ caregiverDetails.id }}/planning"
    routerLinkActive="active"
  >
    Planung
  </a>
  <a
    routerLink="/caregivers/{{ caregiverDetails.id }}/documents"
    routerLinkActive="active"
    [routerLinkActiveOptions]="{ exact: true }"
  >
    Dokumente
  </a>
  <a
    *ngIf="isEmployeed"
    routerLink="/caregivers/{{ caregiverDetails.id }}/veto"
    routerLinkActive="active"
    [routerLinkActiveOptions]="{ exact: true }"
    >Veto</a
  >
  <a
    routerLink="/caregivers/{{ caregiverDetails.id }}/history"
    routerLinkActive="active"
    [routerLinkActiveOptions]="{ exact: true }"
    >Historie</a
  >
</header>

<router-outlet></router-outlet>
