<form #form="ngForm" class="dialog-form">
  <div
    class="dialog-sidebar secondary-dialog-sidebar without-padding"
    *ngIf="fromHandover"
  >
    <span class="content-sub-headline" style="padding: 20px 20px 0 20px"
      >Patienten</span
    >

    <div class="sidebar-inline-calendar" style="flex-direction: column">
      <div
        style="justify-content: space-between; flex-direction: row"
        [class.background-hightlight-main2]="patient.appointment_transfer.type"
        class="dialog-phone-numbers clickable"
        (click)="selectPatient(patient.id)"
        [class.background-hightlight-gray]="selectedPatientId === patient.id"
        *ngFor="let patient of fromHandoverPatients"
      >
        <div style="display: flex; flex-direction: column">
          <strong>{{ patient.full_name }}</strong>
          <small *ngIf="patient.appointment_transfer.type"
            >Per: {{ patient.appointment_transfer.name }}</small
          >

          <button
            pButton
            *ngIf="patient.appointment_transfer.uuid"
            label="Download"
            (click)="openMediaFromUuid(patient.appointment_transfer.uuid)"
            [class.p-button-outlined]="
              !downloadClicked.includes(patient.appointment_transfer.uuid)
            "
            class="p-button-small"
            type="submit"
          ></button>
        </div>

        <!--        <i class="pi pi-check-circle" *ngIf="patient.appointment_transfer.type"></i>-->
      </div>
    </div>
  </div>

  <div class="dialog-sidebar white-sidebar without-padding">
    <div class="dialog-sidebar-tabs">
      <span
        [class.active]="activeTab === 'MAIL_TEXT'"
        (click)="changeTab('MAIL_TEXT')"
      >
        <i class="pi pi-pencil"></i>
        Inhalt</span
      >
      <span
        [class.active]="activeTab === 'PHONE_DATA'"
        (click)="changeTab('PHONE_DATA')"
      >
        <i class="pi pi-phone"></i>
        Telefondaten</span
      >
    </div>

    <!-- Telefondaten -->
    <div
      class="sidebar-inline-calendar"
      style="flex-direction: column"
      *ngIf="activeTab === 'PHONE_DATA'"
    >
      <div class="dialog-phone-numbers" *ngFor="let receiver of phoneReceivers">
        <strong>{{ receiver.name }}</strong>
        <ng-container *ngFor="let phone of receiver.phones">
          <span *ngIf="phone.phone"
            >{{ phone.name }}:
            <a (click)="createCalling(phone)" href="tel:{{ phone.phone }}">{{
              phone.phone
            }}</a>
            <i
              class="pi pi-info-circle"
              [pTooltip]="phone.info"
              *ngIf="phone.info"
            ></i
          ></span>
        </ng-container>
      </div>
    </div>

    <!-- Inhalt -->
    <div
      class="sidebar-inline-calendar"
      style="flex-direction: column"
      *ngIf="activeTab === 'MAIL_TEXT'"
    >
      <div class="p-field" style="padding: 20px 20px 0 20px">
        <span style="margin: 0 0 5px 0; display: flex; font-size: 14px"
          >Anfangstext</span
        >
        <ngx-jodit [(value)]="values.text_start_appointment_anschreiben" [options]="options"></ngx-jodit>
      </div>
      <div class="p-field" style="padding: 0 20px">
        <span style="margin: 0 0 5px 0; display: flex; font-size: 14px"
          >Schlusstext</span
        >
        <ngx-jodit [(value)]="values.text_end_appointment_anschreiben" [options]="options"></ngx-jodit>
      </div>
    </div>
  </div>

  <div
    *ngIf="appointmentsHandoverViaPhone"
    class="dialog-form-body-banner phone-highlight-banner"
  >
    Termine wurden Telefonisch übergeben
  </div>

  <div class="dialog-form-body">
    <div class="detail-information-banner" *ngIf="appointmentIsLocked()">
      <span>Terminversand ist nicht freigegeben!</span>
    </div>

    <!-- Typ -->
    <div class="p-fluid p-formgrid p-grid without-border">
      <div class="p-field p-col-4">
        <label>Versand per *</label>
        <div class="p-formgroup-inline">
          <div class="p-field-radiobutton">
            <p-radioButton
              (onClick)="buildReceiverOptions()"
              label="E-Mail"
              name="type"
              [(ngModel)]="values.type"
              value="email"
            ></p-radioButton>
          </div>
          <div class="p-field-radiobutton">
            <p-radioButton
              (onClick)="buildReceiverOptions()"
              label="Post"
              name="type"
              [(ngModel)]="values.type"
              value="post"
            ></p-radioButton>
          </div>
          <div class="p-field-radiobutton">
            <p-radioButton
              (onClick)="buildReceiverOptions()"
              label="Telefon"
              name="type"
              [(ngModel)]="values.type"
              value="phone"
            ></p-radioButton>
          </div>
        </div>
      </div>

      <div class="p-field p-col-3">
        <label>Terminversand per</label>
        <strong *ngIf="values.patient_id"
          >{{ getAppointmentTypes().types }}
          <i
            class="pi pi-info-circle"
            *ngIf="getAppointmentTypes().comment"
            [pTooltip]="getAppointmentTypes().comment"
          ></i
        ></strong>
      </div>

      <div class="p-field p-col-3">
        <label for="month">Monat *</label>
        <p-dropdown
          *ngIf="!fromHandover"
          scrollHeight="500px"
          inputId="month"
          name="month"
          (onChange)="loadBudgetData()"
          [options]="monthOptions"
          [(ngModel)]="values.month"
        ></p-dropdown>
        <strong *ngIf="fromHandover">{{ values.month }}</strong>
      </div>

      <div class="p-field p-col-2">
        <label for="year">Jahr *</label>
        <p-dropdown
          *ngIf="!fromHandover"
          [disabled]="fromHandover"
          inputId="year"
          name="year"
          (onChange)="loadBudgetData()"
          [options]="yearOptions"
          [(ngModel)]="values.year"
        ></p-dropdown>
        <strong *ngIf="fromHandover">{{ values.year }}</strong>
      </div>
    </div>

    <div class="p-fluid p-formgrid p-grid without-border">
      <!-- Patient -->
      <div class="p-field p-col-3">
        <label
          for="patient"
          style="display: flex; justify-content: space-between"
          >Patient *
          <a
            class="color-gray"
            target="_blank"
            href="/patients/{{ values.patient_id }}"
            >Patient öffnen
            <i class="pi pi-arrow-up-right color-gray" style="font-size: 11px"></i>
          </a>
        </label>
        <p-dropdown
          inputId="patient"
          name="patient"
          placeholder="Bitte auswählen"
          (onChange)="loadBudgetData()"
          [options]="patientOptions"
          [(ngModel)]="values.patient_id"
          optionLabel="full_name"
          [disabled]="fromHandover"
          optionValue="id"
          [required]="true"
          #patient="ngModel"
          [class.p-invalid]="
            patient.invalid && (patient.dirty || patient.touched)
          "
        ></p-dropdown>
      </div>

      <!-- Empfänger -->
      <div class="p-field p-col-4" *ngIf="values.type !== 'phone'">
        <label for="receiver">Empfänger *</label>
        <p-dropdown
          inputId="receiver"
          name="receiver"
          [options]="receiverOptions"
          [(ngModel)]="values.receiver"
          [required]="true"
          scrollHeight="500px"
          appendTo="body"
          #receiver="ngModel"
          [class.p-invalid]="
            receiver.invalid && (receiver.dirty || receiver.touched)
          "
        >
          <ng-template let-receiver pTemplate="item">
            <div>{{ receiver.label }}</div>
            <span *ngIf="values.type === 'email'">{{ receiver.email }}</span>
          </ng-template>
        </p-dropdown>
      </div>

      <!-- CC an -->
      <div class="p-field p-col-5" *ngIf="values.type === 'email'">
        <label for="cc">CC an</label>
        <p-multiSelect
          inputId="cc"
          placeholder="Optional"
          [options]="ccOptions"
          [filter]="false"
          [showHeader]="false"
          scrollHeight="500px"
          appendTo="body"
          name="cc"
          [(ngModel)]="values.cc"
        ></p-multiSelect>
      </div>
    </div>

    <div class="p-fluid p-formgrid p-grid without-border">
      <div class="p-field p-col-12">
        <label for="subject">Betreff *</label>
        <input
          id="subject"
          name="subject"
          type="text"
          [(ngModel)]="values.subject"
          pInputText
          #subject="ngModel"
          required
        />
        <div
          *ngIf="subject.invalid && (subject.dirty || subject.touched)"
          class="p-invalid"
        >
          <div *ngIf="subject.errors?.required">
            <small>Bitte geben Sie einen Betreff ein</small>
          </div>
        </div>
      </div>
    </div>

    <div class="p-field">
      <label for="text">Inhalt *</label>

      <ngx-jodit [(value)]="values.text" [options]="optionsText"></ngx-jodit>
    </div>
  </div>

  <div
    class="content-body dialog-max-height without-padding"
    style="box-shadow: none"
  >
    <div class="p-field" style="padding: 0 20px">
      <label>Wichtige Ereignisse</label>
      <span *ngIf="histories.length === 0" class="dialog-no-message color-gray">Keine wichtigen Ereignisse vorhanden</span>
    </div>


    <p-table *ngIf="histories.length > 0" [value]="histories" [rowHover]="true">
      <ng-template pTemplate="header">
        <tr>
          <th style="min-width: 150px" pSortableColumn="type_text">
            Typ <p-sortIcon field="type_text"></p-sortIcon>
          </th>
          <th style="width: 180px" pSortableColumn="created_at">
            am <p-sortIcon field="created_at"></p-sortIcon>
          </th>
          <th style="width: 180px" pSortableColumn="created_from_name">
            von <p-sortIcon field="created_from_name"></p-sortIcon>
          </th>
          <th style="width: 50px"></th>
          <th>Informationen</th>
          <th></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-history>
        <tr [class.background-hightlight-yellow]="history.important">
          <td>
            <strong>
              {{ history.type_text }}
            </strong>
          </td>

          <td>{{ history.occurred_at_string }}</td>

          <td>{{ history.created_from_name || "??" }}</td>

          <td style="width: 50px">
            <i
              tooltipPosition="left"
              [pTooltip]="history.phone_call.comment"
              class="pi pi-info-circle"
              *ngIf="history.phone_call?.comment"
            ></i>
          </td>
          <td>
            <span
              class="history-information"
              *ngIf="history.type === 'PHONE_CALL'"
            >
              <i><strong>Dauer:</strong> {{ history.phone_call.duration }}</i>
            </span>

            <span
              class="history-information"
              *ngIf="
                history.type === 'CONTACT_PERSON_CREATED' ||
                history.type === 'CONTACT_PERSON_UPDATED' ||
                history.type === 'PATIENT_CHANGED' ||
                history.type === 'CONTACT_PERSON_DEACTIVATED'
              "
            >
              <i><strong>Name:</strong> {{ history.manual_description }}</i>
            </span>

            <span
              class="history-information"
              *ngIf="
                  history.type === 'MANUAL' ||
                  history.type === 'STATUS_CHANGED' ||
                  history.type === 'CUSTOMER_SERVICE_CHANGED' ||
                  history.type === 'TODO_CREATED' ||
                  history.type === 'TODO_FINISHED' ||
                  history.type === 'TODO_DELETED' ||
                  history.type === 'TODO_UPDATED' ||
                  history.type === 'VETO_CREATED' ||
                  history.type === 'VETO_DELETED' ||
                  history.type === 'VETO_UPDATED' ||
                  history.type === 'DOCUMENT_CHANGED' ||
                  history.type === 'DOCUMENT_REMOVED' ||
                  history.type === 'DOCUMENT_ARCHIVED' ||
                  history.type === 'DOCUMENT_UNARCHIVED' ||
                  history.type === 'BUDGET_CHANGED' ||
                  history.type === 'BUDGET_REMOVED_FROM_LIST' ||
                  history.type === 'PAUSED' ||
                  history.type === 'PAUSE_REACTIVATED' ||
                  history.type === 'INVOICE_OPENED' ||
                  history.type === 'INVOICE_STORNO' ||
                  history.type === 'OFFER_SENT' ||
                  history.type === 'APPOINTMENTS_SENT' ||
                  history.type === 'CONSULTING_CREATED' ||
                  history.type === 'CONSULTING_UPDATED' ||
                  history.type === 'CONSULTING_DELETED' ||
                  history.type === 'CONSULTING_CONFIRMED' ||
                  history.type === 'DESIRED_DATE_CREATED' ||
                  history.type === 'DESIRED_DATE_UPDATED' ||
                  history.type === 'DESIRED_DATE_DELETED' ||
                  history.type === 'CANCELLED_DATE_CREATED' ||
                  history.type === 'CANCELLED_DATE_UPDATED' ||
                  history.type === 'CANCELLED_DATE_DELETED' ||
                  history.type === 'CREATED' ||
                  history.type === 'SYSTEM_MESSAGE_SENT' ||
                  history.type === 'ACCOUNTING_RETURN_CREATED' ||
                  history.type === 'ACCOUNTING_RETURN_DELETED' ||
                  history.type === 'LETTER_SENT'
              "
            >
              <i
              ><b>{{ history.manual_title }}</b></i
              >
              <p class="history-text" [innerHTML]="history.manual_description" *ngIf="history.manual_description">
              </p>
              <p
                class="history-text"
                *ngIf="history.appointment_html_table"
                [innerHTML]="history.appointment_html_table"
              ></p>
            </span>
          </td>
          <td class="table-action"></td>
        </tr>
      </ng-template>
    </p-table>
  </div>


  <div class="dialog-form-footer" *ngIf="!appointmentIsLocked()">
    <i *ngIf="values.type === 'post'"></i>
    <i *ngIf="values.type === 'phone'"></i>
    <a
      *ngIf="values.type === 'email'"
      target="_blank"
      (click)="clickedOnPreview = true"
      [href]="sendAppointmentsService.getAppointmentsMailPreviewLink(values)"
    >
      <button
        pButton
        label="Mail Vorschau"
        type="button"
        icon="pi pi-eye"
        iconPos="left"
        class="p-button p-button-info"
      ></button>
    </a>

    <a *ngIf="documentLink" target="_blank" [href]="documentLink">
      <button
        pButton
        label="Dokument herunterladen"
        type="button"
        icon="pi pi-download"
        iconPos="left"
        class="p-button p-button-info"
      ></button>
    </a>

    <button
      pButton
      [label]="values.type === 'email' ? 'Versenden' : 'Erstellen'"
      type="submit"
      (click)="save()"
      *ngIf="!documentLink && values.type !== 'phone'"
      [pTooltip]="
        !clickedOnPreview && values.type === 'email'
          ? 'Bitte Vorschau ansehen'
          : ''
      "
      [disabled]="submitted || (!clickedOnPreview && values.type === 'email')"
      [icon]="submitted ? 'pi pi-spin pi-spinner' : 'pi pi-send'"
    ></button>

    <!--    <button-->
    <!--      pButton-->
    <!--      [label]="values.type === 'email' ? 'Versenden und weiter' : 'Erstellen und weiter'"-->
    <!--      type="submit"-->
    <!--      (click)="save()"-->
    <!--      *ngIf="!documentLink && values.type !== 'phone' && fromHandover"-->
    <!--      [pTooltip]="-->
    <!--        !clickedOnPreview && values.type === 'email'-->
    <!--          ? 'Bitte Vorschau ansehen'-->
    <!--          : ''-->
    <!--      "-->
    <!--      [disabled]="submitted || (!clickedOnPreview && values.type === 'email')"-->
    <!--      [icon]="submitted ? 'pi pi-spin pi-spinner' : 'pi pi-send'"-->
    <!--    ></button>-->
  </div>
</form>
