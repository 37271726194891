import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core'
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog'
import { EventBusService, GlobalEvent } from 'src/app/services/eventbus.service'
import { ToastService } from 'src/app/services/toast.service'
import { CustomerService } from '../../../services/customer.service'
import { CustomerDetailGeneralModel } from '../../../models/customer-patient/customer-detail-general.model'
import { NgForm } from '@angular/forms'
import { Subscription } from 'rxjs'

@Component({
  selector: 'app-qualification-dialog',
  templateUrl: './qualification-dialog.component.html',
})
export class QualificationDialogComponent implements OnInit, OnDestroy {
  submitted = false
  @ViewChild('form', { static: true }) form!: NgForm

  private formSubscription: Subscription | null | undefined = null
  private isDirty = false

  private selectedFiles: any = {
    first_patient: {
      dlv: null,
      sepa: null,
      einzelfallentscheidung: null,
      kostentraeger: null,
      uv: null,
      abtretung: null,
      entbindung: null,
      vollmacht: null,
    },
    second_patient: {
      dlv: null,
      sepa: null,
      einzelfallentscheidung: null,
      kostentraeger: null,
      uv: null,
      abtretung: null,
      entbindung: null,
      vollmacht: null,
    },
  }

  public data: CustomerDetailGeneralModel = new CustomerDetailGeneralModel()
  public fileNames = {
    first_patient_dlv: '',
    first_patient_sepa: '',
    first_patient_uv: '',
    first_patient_vollmacht: '',
    first_patient_kostentraeger: '',
    first_patient_entbindung: '',
    first_patient_abtretung: '',
    first_patient_einzelfallentscheidung: '',
    second_patient_dlv: '',
    second_patient_sepa: '',
    second_patient_uv: '',
    second_patient_vollmacht: '',
    second_patient_kostentraeger: '',
    second_patient_entbindung: '',
    second_patient_einzelfallentscheidung: '',
    second_patient_abtretung: '',
  }

  public values = {
    first_patient: {
      selected_dlv: '',
      selected_sepa: '',
      selected_uv: '',
      no_need_uv: false,
      selected_einzelfallentscheidung: '',
      no_need_einzelfallentscheidung: false,
      selected_kostentraeger: '',
      no_need_kostentraeger: false,
      selected_abtretung: '',
      no_need_abtretung: false,
      selected_entbindung: '',
      selected_vollmacht: '',
    },
    second_patient: {
      selected_dlv: '',
      selected_sepa: '',
      selected_uv: '',
      no_need_uv: false,
      selected_einzelfallentscheidung: '',
      no_need_einzelfallentscheidung: false,
      selected_kostentraeger: '',
      no_need_kostentraeger: false,
      selected_abtretung: '',
      no_need_abtretung: false,
      selected_entbindung: '',
      selected_vollmacht: '',
    },
  }

  constructor(
    private ref: DynamicDialogRef,
    private config: DynamicDialogConfig,
    private customerService: CustomerService,
    private eventbus: EventBusService,
    private toastService: ToastService
  ) {}

  public ngOnInit(): void {
    this.data = this.config.data
    this.setFileNames()

    this.formSubscription = this.form.valueChanges?.subscribe(() => {
      if (!this.form.pristine) {
        this.isDirty = true
      }
    })
  }

  ngOnDestroy(): void {
    this.formSubscription?.unsubscribe()
  }

  public setSelectedFile(
    event: any,
    type: 'first_patient' | 'second_patient',
    document: string
  ): any {
    this.selectedFiles[type][document] = event.target.files[0]
  }

  public upload(): void {
    if (!this.form.form.valid) {
      this.submitted = false
      this.form.form.markAllAsTouched()
      return
    }

    this.submitted = true

    const formData = new FormData()
    formData.append('first_patient_dlv', this.selectedFiles.first_patient.dlv)
    formData.append('first_patient_sepa', this.selectedFiles.first_patient.sepa)
    formData.append('first_patient_uv', this.selectedFiles.first_patient.uv)
    formData.append(
      'first_patient_vollmacht',
      this.selectedFiles.first_patient.vollmacht
    )
    formData.append(
      'first_patient_entbindung',
      this.selectedFiles.first_patient.entbindung
    )
    formData.append(
      'first_patient_kostentraeger',
      this.selectedFiles.first_patient.kostentraeger
    )
    formData.append(
      'first_patient_abtretung',
      this.selectedFiles.first_patient.abtretung
    )
    formData.append(
      'first_patient_einzelfallentscheidung',
      this.selectedFiles.first_patient.einzelfallentscheidung
    )

    formData.append('second_patient_dlv', this.selectedFiles.second_patient.dlv)
    formData.append(
      'second_patient_sepa',
      this.selectedFiles.second_patient.sepa
    )
    formData.append('second_patient_uv', this.selectedFiles.second_patient.uv)
    formData.append(
      'second_patient_vollmacht',
      this.selectedFiles.second_patient.vollmacht
    )
    formData.append(
      'second_patient_entbindung',
      this.selectedFiles.second_patient.entbindung
    )
    formData.append(
      'second_patient_kostentraeger',
      this.selectedFiles.second_patient.kostentraeger
    )
    formData.append(
      'second_patient_abtretung',
      this.selectedFiles.second_patient.abtretung
    )
    formData.append(
      'second_patient_einzelfallentscheidung',
      this.selectedFiles.second_patient.einzelfallentscheidung
    )

    formData.append('file_names', JSON.stringify(this.fileNames))
    formData.append('data', JSON.stringify(this.data))

    const subscription = this.customerService.finishQualification(
      this.data.customer.id,
      formData
    )

    subscription.subscribe(
      () => {
        this.isDirty = false
        this.submitted = false
        this.eventbus.emit(GlobalEvent.QualificationFinished)
        // this.ref.close()
        this.toastService.success(
          'Qualifizierung beendet',
          'Die Qualifizierung wurde erfolgreich beendet'
        )

        setTimeout(() => {
          location.reload()
        }, 0)
      },
      () => {
        this.toastService.error(
          'Etwas ist schief gelaufen...',
          'Bitte wenden Sie sich an den Support'
        )

        this.submitted = false
      }
    )
  }

  private setFileNames(): void {
    const firstPatient = `${this.data.first_patient.first_name} ${this.data.first_patient.last_name} mit Unterschrift`
    const secondPatient = this.data.second_patient
      ? `${this.data.second_patient.first_name} ${this.data.second_patient.last_name} mit Unterschrift`
      : null

    this.fileNames.first_patient_dlv = `DLV ${firstPatient}`
    this.fileNames.first_patient_sepa = `SEPA ${firstPatient}`
    this.fileNames.first_patient_vollmacht = `Vollmacht ${firstPatient}`
    this.fileNames.first_patient_entbindung = `Entbindung ${firstPatient}`
    this.fileNames.first_patient_abtretung = `Abtretung ${firstPatient}`
    this.fileNames.first_patient_kostentraeger = `Kostenträger ${firstPatient}`
    this.fileNames.first_patient_einzelfallentscheidung = `Einzelfall ${firstPatient}`
    this.fileNames.first_patient_uv = `UV ${firstPatient}`

    if (secondPatient) {
      this.fileNames.second_patient_dlv = `DLV ${secondPatient}`
      this.fileNames.second_patient_sepa = `SEPA ${secondPatient}`
      this.fileNames.second_patient_vollmacht = `Vollmacht ${secondPatient}`
      this.fileNames.second_patient_entbindung = `Entbindung ${secondPatient}`
      this.fileNames.second_patient_abtretung = `Abtretung ${secondPatient}`
      this.fileNames.second_patient_kostentraeger = `Kostenträger ${secondPatient}`
      this.fileNames.second_patient_einzelfallentscheidung = `Einzelfall ${secondPatient}`
      this.fileNames.second_patient_uv = `UV ${secondPatient}`
    }
  }
}
