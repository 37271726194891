import { Component, ElementRef, OnInit, ViewChild } from '@angular/core'
import { CaregiverListActivatedModel } from '../../../../models/caregiver/caregiver-list-activated.model'
import { CaregiverService } from '../../../../services/caregivers.service'
import { PhoneCallService } from '../../../../services/phone-call.service'
import { AuthService } from '../../../../services/auth.service'

@Component({
  selector: 'app-caregivers-list-active',
  templateUrl: './caregivers-list-active.component.html',
  styleUrls: ['./caregivers-list-active.component.scss'],
})
export class CaregiversListActiveComponent implements OnInit {
  @ViewChild('dt') table: ElementRef | any = null

  public isSuperAdmin = false

  public globalFilterValue = ''
  public firstPage = 0

  public loading = true
  public caregivers: CaregiverListActivatedModel[] = []

  constructor(
    public phoneCallService: PhoneCallService,
    public authService: AuthService,
    private caregiverService: CaregiverService
  ) {}

  ngOnInit(): void {
    this.isSuperAdmin = this.authService.isSuperAdmin()

    this.loadCaregivers()
  }

  public filterTableGlobal(event: any): void {
    this.table && this.table.filterGlobal(event.target.value.trim(), 'contains')
  }

  public loginUsingId(id: number): void {
    if (window.confirm('Wirklich als Benutzer einloggen?')) {
      this.authService.loginUsingId(id).subscribe(() => {
        this.authService.setImpersonation()

        window.location.href = '/'
      })
    }
  }

  public loadCaregivers(): void {
    this.loading = true

    this.caregiverService
      .list()
      .subscribe((data: CaregiverListActivatedModel[]) => {
        this.caregivers = data

        this.loading = false

        setTimeout(() => {
          if (this.table) {
            const totalItems = this.table._totalRecords

            this.globalFilterValue = this.table.filters.global?.value ?? ''

            if (
              this.table._first > totalItems ||
              this.table._rows > totalItems
            ) {
              this.firstPage = 0
            }
          }
        })
      })
  }
}
