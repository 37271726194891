import { DocumentModel } from '../document/document.model'
import { ContactPersonModel } from '../contact-person/contact-person.model'
import { AppointmentLockModel } from '../appointments/appointment-lock.model'

export class CustomerDetailDocumentsModel {
  first_patient: PatientDetailDocumentsModel = new PatientDetailDocumentsModel()
  second_patient?: PatientDetailDocumentsModel = new PatientDetailDocumentsModel()
  contact_persons: ContactPersonModel[] = []
}

export class PatientDetailDocumentsModel {
  patient_type = ''
  full_name = ''
  id = 0
  status = ''
  status_string = ''
  is_paused = false
  initial_documents_generated = true
  types: PatientDocumentTypesModel = new PatientDocumentTypesModel()
  list: DocumentModel[] = []
  appointment_lock: AppointmentLockModel | null = null
}

export class PatientDocumentTypesModel {
  misc = new DocumentModel()
  willkommensschreiben = new DocumentModel()
}
