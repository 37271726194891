import { Component, OnDestroy, OnInit } from '@angular/core'
import { ActivatedRoute, ParamMap, Router } from '@angular/router'
import { ToastService } from '../../../../services/toast.service'
import { DialogService } from 'primeng/dynamicdialog'
import {
  EventBusService,
  GlobalEvent,
} from '../../../../services/eventbus.service'
import { Subscription } from 'rxjs'
import { AddDocumentDialogComponent } from '../../../../components/dialogs/add-document-dialog/add-document-dialog.component'
import { DocumentModel } from '../../../../models/document/document.model'
import { MediaModel } from '../../../../models/document/media.model'
import { CarService } from '../../../../services/car.service'
import { CarDetailDocumentsModel } from '../../../../models/car/car-detail-documents.model'

@Component({
  selector: 'app-cars-detail-documents',
  templateUrl: './cars-detail-documents.component.html',
})
export class CarsDetailDocumentsComponent implements OnInit, OnDestroy {
  public loading = true

  private routeSubscription: any = null
  private eventBusSubscription: Subscription = new Subscription()

  public documents: CarDetailDocumentsModel = new CarDetailDocumentsModel()
  public documentTypes: DocumentModel[] = []

  public onlyArchived = false
  private carId: string | null = null

  constructor(
    public carService: CarService,
    private toastService: ToastService,
    private eventbus: EventBusService,
    private dialogService: DialogService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    if (this.route.parent) {
      this.routeSubscription = this.route.parent.paramMap.subscribe(
        (params: ParamMap) => {
          this.carId = params.get('id')

          this.loadDocuments()
        }
      )
    }

    this.listenForEventbus()
  }

  ngOnDestroy(): void {
    this.eventBusSubscription.unsubscribe()
    this.routeSubscription && this.routeSubscription.unsubscribe()
  }

  public openAddDocumentDialog(document = null, documentType = ''): void {
    this.dialogService.open(AddDocumentDialogComponent, {
      data: {
        type_id: this.carId,
        type: 'car',
        document_type: documentType,
        document: document,
      },
      header: document ? 'Dokument bearbeiten' : 'Dokument erstellen',
      styleClass: 'dialog-container',
      dismissableMask: false,
      width: '450px',
    })
  }

  public openDownloadTab(document: MediaModel): void {
    window.open(this.carService.getDocumentDownloadLink(document.uuid))
  }

  private listenForEventbus(): void {
    this.eventBusSubscription = this.eventbus.subject.subscribe(
      (event: GlobalEvent) => {
        switch (event) {
          case GlobalEvent.DocumentListReload:
            this.loadDocuments(false)
        }
      }
    )
  }

  public loadDocuments(withLoading: boolean = true): void {
    if (withLoading) {
      this.loading = true
    }

    this.carService
      .documents(this.carId, this.onlyArchived)
      .subscribe((data: CarDetailDocumentsModel) => {
        this.documents = data
        this.documentTypes = Object.values(data.types)

        if (withLoading) {
          this.loading = false
        }
      })
  }
}
