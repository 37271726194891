import { Component, OnInit } from '@angular/core'
import { TitleService } from 'src/app/services/title.service'

@Component({
  selector: 'app-desired-dates',
  templateUrl: './desired-dates.component.html',
})
export class DesiredDatesComponent implements OnInit {
  constructor(private titleService: TitleService) {}

  ngOnInit(): void {
    this.titleService.setTitle('Wunschtermine')
  }
}
