import { Component, OnDestroy, OnInit } from '@angular/core'
import { ActivatedRoute, ParamMap } from '@angular/router'
import { TitleService } from 'src/app/services/title.service'
import { Subscription } from 'rxjs'
import { RolePermissionService } from '../../../../services/role-permission.service'
import { RoleModel } from '../../../../models/permission/role.model'
import { AuthService } from '../../../../services/auth.service'

@Component({
  selector: 'app-roles-detail',
  templateUrl: './roles-detail.component.html',
  styleUrls: ['./roles-detail.component.scss'],
})
export class RolesDetailComponent implements OnInit, OnDestroy {
  public role: RoleModel = new RoleModel()
  private eventBusSubscription: Subscription = new Subscription()

  constructor(
    private route: ActivatedRoute,
    private rolePermissionService: RolePermissionService,
    private titleService: TitleService,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    this.loadRoleDetails()
  }

  ngOnDestroy(): void {
    this.eventBusSubscription.unsubscribe()
  }

  public loadRoleDetails(): void {
    this.route.paramMap.subscribe((params: ParamMap) => {
      this.rolePermissionService
        .role(params.get('id'))
        .subscribe((data: RoleModel) => {
          this.role = data
          this.titleService.setTitle(this.role.name)
        })
    })
  }
}
