import { Injectable } from '@angular/core'
import Echo from 'laravel-echo'

import { environment } from '../../environments/environment'
import { HttpClient } from '@angular/common/http'

@Injectable({
  providedIn: 'root',
})
export class EchoService {
  constructor(private http: HttpClient) {}

  public init(): void {
    // @ts-ignore
    window.Echo = new Echo({
      broadcaster: 'reverb',
      key: environment.reverb_key,
      wsHost: environment.reverb_host,
      // Lokal ist der WebSocket-Server nicht über einen extra Pfad erreichbar.
      // Musste für die Produktivumgebungen aber so eingestellt werden.
      wsPath: environment.production ? '/ws' : '',
      wsPort: 6001,
      wssPort: 443,
      forceTLS: environment.production,
      enabledTransports: ['ws', 'wss'],

      // Damit die Cookies mitgeschickt werden. Musste für Angular etwas angepasst werden.
      // https://laravel.com/docs/11.x/sanctum#authorizing-private-broadcast-channels
      authorizer: (channel: any, options: any) => {
        return {
          authorize: (socketId: any, callback: any) => {
            this.http
              .post(`${environment.api}/broadcasting/auth`, {
                socket_id: socketId,
                channel_name: channel.name,
              })
              .toPromise()
              .then((response: any) => {
                callback(false, response)
              })
              .catch((error: any) => {
                callback(true, error)
              })
          },
        }
      },
    })
  }
}
