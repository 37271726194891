import { Component, ElementRef, OnInit, ViewChild } from '@angular/core'
import { DialogService } from 'primeng/dynamicdialog'
import { EventBusService } from 'src/app/services/eventbus.service'
import { TitleService } from 'src/app/services/title.service'
import { PhoneCallsListItemModel } from '../../../models/phone-call/phone-calls-list.model'
import { AuthService } from '../../../services/auth.service'
import { UserCaregiverService } from '../../../services/user-caregiver.service'

@Component({
  selector: 'app-user-caregiver-analyses',
  templateUrl: './user-caregiver-analyses.component.html',
  styleUrls: ['./user-caregiver-analyses.component.scss'],
})
export class UserCaregiverAnalysesComponent implements OnInit {
  @ViewChild('dt') table: ElementRef | any = null

  public globalFilterValue = ''
  public analyses: any[] = []
  public loading = false
  public contentLoading = true

  public caregiver: any
  public times: any
  public illQuota: any
  public months: any
  public timeKeys: any

  public year = ''

  public yearOptions = [
    { label: '2023', value: '2023' },
    { label: '2024', value: '2024' },
  ]

  constructor(
    public userCaregiverService: UserCaregiverService,
    private authService: AuthService,
    private dialogService: DialogService,
    private eventbus: EventBusService,
    private titleService: TitleService
  ) {}

  ngOnInit(): void {
    const date = new Date()
    this.year = date.getFullYear().toString()

    document.body.classList.remove('mobile-nav-visible')

    this.titleService.setTitle(`Auswertungen`)
    this.loadList()
  }

  public loadList(withLoading: boolean = true): void {
    this.contentLoading = true

    if (withLoading) {
      this.loading = true
    }

    this.userCaregiverService.loadAnalyses(this.year).subscribe((data: any) => {
      this.caregiver = data.caregiver
      this.times = data.times
      // this.jobScopes = data.job_scopes
      // this.jobDays = data.job_days
      this.illQuota = data.ill_quota
      this.months = data.months
      this.timeKeys = Object.keys(data.times)

      if (withLoading) {
        this.loading = false
      }

      this.contentLoading = false
    })
  }
}
