<form #form="ngForm" class="dialog-form">
  <div class="dialog-form-body">
    <!--    <div-->
    <!--      class="p-field"-->
    <!--      *ngIf="todo.assignment_type === 'CAREGIVER' && !data.isEdit"-->
    <!--    >-->
    <!--      <label for="caregiver"-->
    <!--        >Betreuungskraft *-->
    <!--        <i-->
    <!--          class="pi pi-info-circle"-->
    <!--          pTooltip="Suche nach Persplan ID oder Name"-->
    <!--        ></i-->
    <!--      ></label>-->
    <!--      <p-autoComplete-->
    <!--        field="full_name"-->
    <!--        inputId="caregiver"-->
    <!--        name="caregiver"-->
    <!--        appendTo="body"-->
    <!--        [forceSelection]="true"-->
    <!--        [(ngModel)]="todo.caregiver"-->
    <!--        [suggestions]="caregivers"-->
    <!--        (completeMethod)="searchCaregivers($event)"-->
    <!--        [required]="true"-->
    <!--        #caregiver="ngModel"-->
    <!--        [class.p-invalid]="-->
    <!--          caregiver.invalid && (caregiver.dirty || caregiver.touched)-->
    <!--        "-->
    <!--      >-->
    <!--        <ng-template let-caregiver pTemplate="item">-->
    <!--          <div>{{ caregiver.full_name }}</div>-->
    <!--        </ng-template>-->
    <!--      </p-autoComplete>-->
    <!--      <div-->
    <!--        *ngIf="caregiver.invalid && (caregiver.dirty || caregiver.touched)"-->
    <!--        class="p-invalid"-->
    <!--      >-->
    <!--        <div *ngIf="caregiver.errors?.required">-->
    <!--          <small>Bitte wählen Sie eine Betreuungskraft aus</small>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </div>-->

    <!--    <div-->
    <!--      class="p-field"-->
    <!--      *ngIf="todo.assignment_type === 'CAREGIVER' && data.isEdit"-->
    <!--    >-->
    <!--      <label>Betreuungskraft</label>-->
    <!--      <span>{{ data.user_type_name }}</span>-->
    <!--    </div>-->

    <div class="p-field" *ngIf="data.type === 'caregiver' && !data.isEdit">
      <label for="patient">Patient * </label>
      <p-autoComplete
        field="full_name"
        inputId="patient"
        name="patient"
        appendTo="body"
        [forceSelection]="true"
        [(ngModel)]="veto.patient"
        [suggestions]="patients"
        (completeMethod)="searchPatients($event)"
        [required]="true"
        #patient="ngModel"
        [class.p-invalid]="
          patient.invalid && (patient.dirty || patient.touched)
        "
      >
        <ng-template let-patient pTemplate="item">
          <div class="search-result-data">
            <span class="search-result-name">
              <b>{{ patient.full_name }}</b>
              <span class="search-result-id">#{{ patient.id }}</span>
              <i
                *ngIf="patient.status"
                class="p-tag p-tag-rounded p-tag-{{ patient.status }}"
                >{{ patient.status_string }}</i
              >
            </span>
            <span class="search-result-address">
              {{ patient.zipcode }} {{ patient.city }}
            </span>
          </div>
        </ng-template>
      </p-autoComplete>
      <div
        *ngIf="patient.invalid && (patient.dirty || patient.touched)"
        class="p-invalid"
      >
        <div *ngIf="patient.errors?.required">
          <small>Bitte wählen Sie einen Patienten aus</small>
        </div>
      </div>
    </div>

    <div class="p-field" *ngIf="data.type === 'caregiver' && data.isEdit">
      <label>Patient</label>
      <span>{{ veto.patient.first_name }} {{ veto.patient.last_name }}</span>
    </div>

    <div class="p-field" *ngIf="data.type === 'patient' && !data.isEdit">
      <label for="caregiver">Betreuungskraft * </label>
      <p-autoComplete
        field="full_name"
        inputId="caregiver"
        name="caregiver"
        appendTo="body"
        [forceSelection]="true"
        [(ngModel)]="veto.caregiver"
        [suggestions]="caregivers"
        (completeMethod)="searchCaregivers($event)"
        [required]="true"
        #caregiver="ngModel"
        [class.p-invalid]="
          caregiver.invalid && (caregiver.dirty || caregiver.touched)
        "
      >
        <ng-template let-caregiver pTemplate="item">
          <div>{{ caregiver.full_name }}</div>
        </ng-template>
      </p-autoComplete>
      <div
        *ngIf="caregiver.invalid && (caregiver.dirty || caregiver.touched)"
        class="p-invalid"
      >
        <div *ngIf="caregiver.errors?.required">
          <small>Bitte wählen Sie eine Betreuungskraft aus</small>
        </div>
      </div>
    </div>

    <div class="p-field" *ngIf="data.type === 'patient' && data.isEdit">
      <label>Betreuungskraft</label>
      <span
        >{{ veto.caregiver.first_name }} {{ veto.caregiver.last_name }}</span
      >
    </div>

    <div class="p-field">
      <label for="comment">Bemerkung</label>
      <textarea
        id="comment"
        name="comment"
        [(ngModel)]="veto.comment"
        [rows]="3"
        pInputTextarea
      ></textarea>
    </div>
  </div>
  <div class="dialog-form-footer">
    <i *ngIf="!data.isEdit"></i>
    <i
      class="pi pi-trash"
      *ngIf="data.isEdit"
      [class.pi-trash]="!submittedDelete"
      [class.pi-spin]="submittedDelete"
      [class.pi-spinner]="submittedDelete"
      tooltipPosition="left"
      tooltipZIndex="20000"
      [pTooltip]="submittedDelete ? 'Wird gelöscht' : 'Veto löschen'"
      (click)="remove($event)"
    ></i>

    <button
      pButton
      [label]="data.isEdit ? 'Speichern' : 'Erstellen'"
      type="submit"
      (click)="save()"
      [disabled]="submitted"
      [icon]="submitted ? 'pi pi-spin pi-spinner' : 'pi pi-check'"
    ></button>
  </div>
</form>

<p-confirmPopup></p-confirmPopup>
