<app-loader [loading]="loading"></app-loader>

<div class="content" *ngIf="!loading">
  <header class="content-header">
    <span>Letzte Terminabsagen</span>

    <div class="content-header-buttons">
      <p-checkbox
        label="Termine im aktuellen Monat"
        [binary]="true"
        (onChange)="loadData(false)"
        [(ngModel)]="filters.current_month"
        name="current_month"
      ></p-checkbox>

      <p-checkbox
        label="Termine im Folgemonat"
        (onChange)="loadData(false)"
        [(ngModel)]="filters.next_month"
        [binary]="true"
        name="next-month"
      ></p-checkbox>

      <div
        class="table-search-container"
        [class.searchfield-active]="globalFilterValue != ''"
      >
        <div class="p-input-icon-left">
          <i class="pi pi-search"></i>
          <input
            pInputText
            (input)="filterTableGlobal($event)"
            placeholder="Suche..."
            [(ngModel)]="globalFilterValue"
          />
        </div>
      </div>
    </div>
  </header>
</div>

<div class="content" style="margin-bottom: 0" *ngIf="!loading">
  <div
    class="content-body"
    [class.content-loading]="contentLoading"
    *ngIf="cancelledDateAppointments.length == 0"
  >
    <span class="content-no-message">Keine Absagen vorhanden</span>
  </div>

  <div
    class="content-body content-body-full-height-with-tabs without-padding"
    [class.content-loading]="contentLoading"
    *ngIf="cancelledDateAppointments.length > 0"
  >
    <p-table
      #dt
      [value]="cancelledDateAppointments"
      [globalFilterFields]="[
        'cancelled_date.patient.full_name',
        'caregiver.full_name',
        'cancelled_date.reason_name',
      ]"
      [autoLayout]="true"
      [(first)]="firstPage"
      [rowHover]="true"
      [showCurrentPageReport]="true"
      currentPageReportTemplate="{totalRecords} Terminabsagen"
      [rows]="50"
      [paginator]="true"
      scrollHeight="100%"
      [scrollable]="true"
    >
      <ng-template pTemplate="header">
        <tr class="table-headline-highlight">
          <th
            style="min-width: 160px"
            pSortableColumn="cancelled_date.patient.last_name"
          >
            Patient
            <p-sortIcon field="cancelled_date.patient.last_name"></p-sortIcon>
          </th>
          <th
            style="max-width: 200px"
            pSortableColumn="cancelled_date.patient.city"
          >
            Wohnort
            <p-sortIcon field="cancelled_date.patient.city"></p-sortIcon>
          </th>
          <th style="max-width: 200px" pSortableColumn="date_with_time">
            Termin
            <p-sortIcon field="date_with_time"></p-sortIcon>
          </th>
          <th style="min-width: 160px" pSortableColumn="caregiver.last_name">
            Betreuungskraft
            <p-sortIcon field="caregiver.last_name"></p-sortIcon>
          </th>
          <th
            style="max-width: 300px"
            pSortableColumn="cancelled_date.storno_reason_name"
          >
            Grund
            <p-sortIcon field="cancelled_date.storno_reason_name"></p-sortIcon>
          </th>
          <th style="max-width: 120px">Bemerkung</th>
          <th style="max-width: 160px" pSortableColumn="created_at">
            Abgesagt am <p-sortIcon field="created_at"></p-sortIcon>
          </th>
          <th style="max-width: 50px">von</th>
          <th style="max-width: 50px"></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-date>
        <tr class="clickable">
          <td
            style="min-width: 160px"
            (click)="openAddCancelledDateDialog(date)"
          >
            <a routerLink="/patients/{{ date.cancelled_date.patient_id }}"
              >{{ date.cancelled_date.patient.first_name }}
              {{ date.cancelled_date.patient.last_name }}</a
            >
          </td>

          <td
            style="max-width: 200px"
            (click)="openAddCancelledDateDialog(date)"
          >
            <div
              class="text-center-container"
              [pTooltip]="date.cancelled_date.patient.full_address"
            >
              <i
                style="font-size: 13px"
                class="pi pi-info-circle color-gray"
              ></i>
              {{ date.cancelled_date.patient.city }}
            </div>
          </td>

          <td
            style="max-width: 200px"
            (click)="openAddCancelledDateDialog(date)"
          >
            {{ date.date_with_time | date: "dd.MM.y, H:mm" }} - {{ date.to }}
          </td>

          <td
            style="min-width: 160px"
            (click)="openAddCancelledDateDialog(date)"
          >
            <a routerLink="/caregivers/{{ date.caregiver_id }}"
              >{{ date.caregiver.first_name }} {{ date.caregiver.last_name }}</a
            >
          </td>

          <td
            style="max-width: 300px"
            (click)="openAddCancelledDateDialog(date)"
          >
            {{ date.cancelled_date.storno_reason_name }}
          </td>
          <td
            style="max-width: 120px"
            (click)="openAddCancelledDateDialog(date)"
          >
            <i
              style="font-size: 15px"
              *ngIf="date.cancelled_date.comment"
              [pTooltip]="date.cancelled_date.comment"
              class="pi pi-info-circle color-gray"
            ></i>
          </td>
          <td
            style="max-width: 160px"
            (click)="openAddCancelledDateDialog(date)"
          >
            {{ date.created_at | date: "dd.MM.y, H:mm" }}
          </td>
          <td
            style="max-width: 50px"
            (click)="openAddCancelledDateDialog(date)"
            [pTooltip]="date.cancelled_date.user.user_system.full_name"
            tooltipPosition="left"
          >
            {{ date.cancelled_date.user.user_system.first_name[0]
            }}{{ date.cancelled_date.user.user_system.last_name[0] }}
          </td>

          <td class="table-action" style="max-width: 50px">
            <i
              (click)="openAddCancelledDateDialog(date)"
              class="pi pi-pencil"
            ></i>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
