import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs'
import { environment } from '../../environments/environment'
import { AccountingReturnModel } from '../models/accounting/accounting-return.model'
import { AccountingReturnHistoryModel } from '../models/accounting/accounting-return-history.model'

@Injectable({
  providedIn: 'root',
})
export class HandoverService {
  constructor(private http: HttpClient) {}

  public load(month: string, year: string): Observable<any> {
    return this.http.get<any>(`${environment.api}/handover`, {
      params: {
        month,
        year,
      },
    })
  }

  public loadPatients(month: string, year: string, patientIds: any): Observable<any> {
    return this.http.get<any>(`${environment.api}/handover/patients`, {
      params: {
        month,
        year,
        patientIds: JSON.stringify(patientIds),
      },
    })
  }
}
