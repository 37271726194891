<app-loader [loading]="loading"></app-loader>

<p-overlayPanel
  styleClass="dark-overlay"
  #op
  [showTransitionOptions]="'0ms'"
  [hideTransitionOptions]="'0ms'"
>
  <ng-template pTemplate>
    <div style="white-space: pre-wrap" [innerHTML]="currentOverlayValue"></div>
  </ng-template>
</p-overlayPanel>

<div class="content" *ngIf="!loading">
  <div class="content-body without-padding p-flex-row">
    <div class="detail-information-column border-right">
      <div class="detail-information-container">
        <div class="detail-information-header">
          <span><i class="pi pi-bars"></i> Daten</span>
        </div>
        <div class="detail-information-body">
          <!--          <p-overlayPanel-->
          <!--            #op-->
          <!--            styleClass="with-border"-->
          <!--            [showCloseIcon]="true"-->
          <!--            [style]="{-->
          <!--              maxWidth: '800px',-->
          <!--              width: 'max-content',-->
          <!--              maxHeight: '420px',-->
          <!--              overflowY: 'auto'-->
          <!--            }"-->
          <!--          >-->
          <!--            <ng-template pTemplate>-->
          <!--              <div [innerHTML]="currentBudgetTable"></div>-->
          <!--            </ng-template>-->
          <!--          </p-overlayPanel>-->

          <div class="detail-information" *ngIf="data.current_employment">
            <div class="detail-key">
              <span>Angestellt seit</span>
            </div>
            <div class="detail-value p-jc-between p-align-center">
              <div>
                <strong>{{
                  data.current_employment.employed_since_date_string
                }}</strong>
                <!--                <strong-->
                <!--                  *ngIf="-->
                <!--                    caregiver.caregiver.current_employment.employed_until_date-->
                <!--                  "-->
                <!--                >-->
                <!--                  - -->
                <!--                  {{-->
                <!--                    caregiver.caregiver.current_employment.employed_until_date-->
                <!--                      | date: "dd.MM.y"-->
                <!--                  }}</strong-->
                <!--                >-->

                <!--                <strong-->
                <!--                  *ngIf="-->
                <!--                    caregiver.caregiver.current_employment.employed_temporary-->
                <!--                  "-->
                <!--                >-->
                <!--                  (Befristet)</strong-->
                <!--                >-->
              </div>
            </div>
          </div>

          <div class="detail-information">
            <div class="detail-key">
              <span>Stellenumfang</span>
            </div>
            <div class="detail-value p-jc-between p-align-center">
              <strong *ngIf="data.current_job_scope"
                >{{ data.current_job_scope.job_scope }} Std</strong
              >
              <strong *ngIf="!data.current_job_scope">-</strong>
            </div>
          </div>

          <div class="detail-information">
            <div class="detail-key">
              <span>Arbeitszeit laut Vertrag</span>
            </div>
            <div class="detail-value p-jc-between p-align-center">
              <strong *ngIf="data.current_job_days"
                >{{ data.current_job_days.job_days }} Tage</strong
              >
              <strong *ngIf="!data.current_job_days">-</strong>
            </div>
          </div>

          <div class="detail-information">
            <div class="detail-key">
              <span>Tätigkeiten</span>
            </div>
            <div class="detail-value">
              <strong>{{ data.activities_string || "-" }}</strong>
            </div>
          </div>

          <div class="detail-information">
            <div class="detail-key">
              <span>Bezirke</span>
            </div>
            <div class="detail-value">
              <strong>{{ data.cities_string || "-" }}</strong>
            </div>
          </div>

          <div class="detail-information">
            <div class="detail-key">
              <span>Position</span>
            </div>
            <div class="detail-value">
              <strong>{{ data.job_position || "-" }}</strong>
            </div>
          </div>

          <div class="detail-information">
            <div class="detail-key">
              <span>Urlaub</span>
            </div>
            <div class="detail-value">
              <strong *ngIf="data.vacation_days"
                >{{ data.vacation_days }} Tage</strong
              >
              <strong *ngIf="!data.vacation_days">-</strong>
            </div>
          </div>

          <div class="detail-information">
            <div class="detail-key">
              <span>Gehalt</span>
            </div>
            <div class="detail-value">
              <strong *ngIf="!data.salary">-</strong>
              <i
                style="cursor: pointer"
                class="pi pi-info-circle"
                (click)="toggleOverlay($event, data.salary)"
                *ngIf="data.salary"
              ></i>
            </div>
          </div>

          <div class="detail-information">
            <div class="detail-key">
              <span>Steuer-ID</span>
            </div>
            <div class="detail-value">
              <strong *ngIf="data.tax_id">{{ data.tax_id }}</strong>
              <strong *ngIf="!data.tax_id">-</strong>
            </div>
          </div>

          <div class="detail-information">
            <div class="detail-key">
              <span>Rentenversicherungsnummer</span>
            </div>
            <div class="detail-value">
              <strong *ngIf="data.pension_insurance_number">{{
                data.pension_insurance_number
              }}</strong>
              <strong *ngIf="!data.pension_insurance_number">-</strong>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="detail-information-column">
      <div
        class="detail-information-container detail-information-current-time-table"
        *ngIf="data.current_status.status === 'EMPLOYMENT'"
      >
        <div class="detail-information-header">
          <span><i class="pi pi-clock"></i> Arbeitszeiten </span>
        </div>
        <div class="detail-information-body without-padding">
          <p-table [value]="[data.current_time]" [rowHover]="true">
            <ng-template pTemplate="header">
              <tr>
                <th>Montag</th>
                <th>Dienstag</th>
                <th>Mittwoch</th>
                <th>Donnerstag</th>
                <th>Freitag</th>
                <th>Samstag</th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-time>
              <tr>
                <td>
                  <span class="p-column-title">MONTAG<br /></span>
                  <span *ngIf="!time.days_with_key.monday.checked">-</span>
                  <span *ngIf="time.days_with_key.monday.checked">
                    {{ time.days_with_key.monday.start_first || "??" }} -
                    {{ time.days_with_key.monday.end_first || "??" }}
                  </span>
                  <span *ngIf="time.days_with_key.monday.has_second_block">
                    <br />
                    {{ time.days_with_key.monday.start_second || "??" }} -
                    {{ time.days_with_key.monday.end_second || "??" }}
                  </span>
                </td>

                <td>
                  <span class="p-column-title">DIENSTAG<br /></span>

                  <span *ngIf="!time.days_with_key.tuesday.checked">-</span>
                  <span *ngIf="time.days_with_key.tuesday.checked">
                    {{ time.days_with_key.tuesday.start_first || "??" }} -
                    {{ time.days_with_key.tuesday.end_first || "??" }}
                  </span>
                  <span *ngIf="time.days_with_key.tuesday.has_second_block">
                    <br />
                    {{ time.days_with_key.tuesday.start_second || "??" }} -
                    {{ time.days_with_key.tuesday.end_second || "??" }}
                  </span>
                </td>

                <td>
                  <span class="p-column-title">MITTWOCH<br /></span>

                  <span *ngIf="!time.days_with_key.wednesday.checked">-</span>
                  <span *ngIf="time.days_with_key.wednesday.checked">
                    {{ time.days_with_key.wednesday.start_first || "??" }} -
                    {{ time.days_with_key.wednesday.end_first || "??" }}
                  </span>
                  <span *ngIf="time.days_with_key.wednesday.has_second_block">
                    <br />
                    {{ time.days_with_key.wednesday.start_second || "??" }} -
                    {{ time.days_with_key.wednesday.end_second || "??" }}
                  </span>
                </td>

                <td>
                  <span class="p-column-title">DONNERSTAG<br /></span>

                  <span *ngIf="!time.days_with_key.thursday.checked">-</span>
                  <span *ngIf="time.days_with_key.thursday.checked">
                    {{ time.days_with_key.thursday.start_first || "??" }} -
                    {{ time.days_with_key.thursday.end_first || "??" }}
                  </span>
                  <span *ngIf="time.days_with_key.thursday.has_second_block">
                    <br />
                    {{ time.days_with_key.thursday.start_second || "??" }} -
                    {{ time.days_with_key.thursday.end_second || "??" }}
                  </span>
                </td>

                <td>
                  <span class="p-column-title">FREITAG<br /></span>

                  <span *ngIf="!time.days_with_key.friday.checked">-</span>
                  <span *ngIf="time.days_with_key.friday.checked">
                    {{ time.days_with_key.friday.start_first || "??" }} -
                    {{ time.days_with_key.friday.end_first || "??" }}
                  </span>
                  <span *ngIf="time.days_with_key.friday.has_second_block">
                    <br />
                    {{ time.days_with_key.friday.start_second || "??" }} -
                    {{ time.days_with_key.friday.end_second || "??" }}
                  </span>
                </td>

                <td>
                  <span class="p-column-title">SAMSTAG<br /></span>

                  <span *ngIf="!time.days_with_key.saturday.checked">-</span>
                  <span *ngIf="time.days_with_key.saturday.checked">
                    {{ time.days_with_key.saturday.start_first || "??" }} -
                    {{ time.days_with_key.saturday.end_first || "??" }}
                  </span>
                  <span *ngIf="time.days_with_key.saturday.has_second_block">
                    <br />
                    {{ time.days_with_key.saturday.start_second || "??" }} -
                    {{ time.days_with_key.saturday.end_second || "??" }}
                  </span>
                </td>
              </tr>
            </ng-template>
          </p-table>
        </div>
      </div>

      <div class="detail-information-container">
        <div class="detail-information-header">
          <span><i class="pi pi-car"></i>PKW</span>
        </div>
        <div class="detail-information-body" *ngIf="data.cars.length === 0">
          <span class="content-no-message">Kein PKW vorhanden</span>
        </div>
        <div
          class="detail-information-body without-padding"
          *ngIf="data.cars.length > 0"
        >
          <p-table [value]="data.cars" [rowHover]="true">
            <ng-template pTemplate="header">
              <tr>
                <th>Name</th>
                <th style="width: 120px">seit</th>
                <th style="width: 120px">bis</th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-car>
              <tr>
                <td>
                  {{ car.car.full_name }}
                </td>
                <td>{{ car.since_string }}</td>
                <td *ngIf="car.until">{{ car.until_string }}</td>
                <td *ngIf="!car.until">-</td>
              </tr>
            </ng-template>
          </p-table>
        </div>
      </div>
    </div>
  </div>
</div>
