<app-loader [loading]="loading"></app-loader>

<div class="content has-min-width" *ngIf="!loading">
  <header class="content-header">
    <span>
      <!--      <i (click)="goToPreviousYear()" class="pi pi-chevron-left"></i>-->

      <p-dropdown
        inputId="year"
        name="year"
        (onChange)="loadAnalyses(false)"
        [options]="yearOptions"
        [(ngModel)]="year"
      ></p-dropdown>

      <!--      <i-->
      <!--        (click)="goToNextYear()"-->
      <!--        class="pi pi-chevron-right"-->
      <!--        style="margin: 0 0 0 10px"-->
      <!--      ></i>-->

      <!--      <span style="margin: 0 20px">test: 123</span>-->
    </span>

    <!--    <a-->
    <!--      [href]="caregiverAnalysesService.exportUrl(caregiverId, year)"-->
    <!--      target="_blank"-->
    <!--    >-->
    <!--      <button-->
    <!--        pButton-->
    <!--        label="Export"-->
    <!--        type="button"-->
    <!--        class="p-button-outlined"-->
    <!--        icon="pi pi-download"-->
    <!--        iconPos="left"-->
    <!--      ></button>-->
    <!--    </a>-->
  </header>
  <div
    class="content-body without-padding"
    [class.content-loading]="contentLoading"
  >
    <div class="time-month">
      <div class="time-category"></div>

      <!--      TODO: loop -->
      <div class="time-months">
        <div
          [class.hover-highlight]="
            currentHover === 0 || currentHoverForHeader === 0
          "
          (mouseleave)="setHover()"
          (mouseenter)="setHover(0)"
        >
          <strong>Jan</strong>
        </div>
        <div
          [class.hover-highlight]="
            currentHover === 1 || currentHoverForHeader === 1
          "
          (mouseleave)="setHover()"
          (mouseenter)="setHover(1)"
        >
          <strong>Feb</strong>
        </div>
        <div
          [class.hover-highlight]="
            currentHover === 2 || currentHoverForHeader === 2
          "
          (mouseleave)="setHover()"
          (mouseenter)="setHover(2)"
        >
          <strong>Mrz</strong>
        </div>
        <div
          [class.hover-highlight]="
            currentHover === 3 || currentHoverForHeader === 3
          "
          (mouseleave)="setHover()"
          (mouseenter)="setHover(3)"
        >
          <strong>Apr</strong>
        </div>
        <div
          [class.hover-highlight]="
            currentHover === 4 || currentHoverForHeader === 4
          "
          (mouseleave)="setHover()"
          (mouseenter)="setHover(4)"
        >
          <strong>Mai</strong>
        </div>
        <div
          [class.hover-highlight]="
            currentHover === 5 || currentHoverForHeader === 5
          "
          (mouseleave)="setHover()"
          (mouseenter)="setHover(5)"
        >
          <strong>Jun</strong>
        </div>
        <div
          [class.hover-highlight]="
            currentHover === 6 || currentHoverForHeader === 6
          "
          (mouseleave)="setHover()"
          (mouseenter)="setHover(6)"
        >
          <strong>Jul</strong>
        </div>
        <div
          [class.hover-highlight]="
            currentHover === 7 || currentHoverForHeader === 7
          "
          (mouseleave)="setHover()"
          (mouseenter)="setHover(7)"
        >
          <strong>Aug</strong>
        </div>
        <div
          [class.hover-highlight]="
            currentHover === 8 || currentHoverForHeader === 8
          "
          (mouseleave)="setHover()"
          (mouseenter)="setHover(8)"
        >
          <strong>Sep</strong>
        </div>
        <div
          [class.hover-highlight]="
            currentHover === 9 || currentHoverForHeader === 9
          "
          (mouseleave)="setHover()"
          (mouseenter)="setHover(9)"
        >
          <strong>Okt</strong>
        </div>
        <div
          [class.hover-highlight]="
            currentHover === 10 || currentHoverForHeader === 10
          "
          (mouseleave)="setHover()"
          (mouseenter)="setHover(10)"
        >
          <strong>Nov</strong>
        </div>
        <div
          [class.hover-highlight]="
            currentHover === 11 || currentHoverForHeader === 11
          "
          (mouseleave)="setHover()"
          (mouseenter)="setHover(11)"
        >
          <strong>Dez</strong>
        </div>
      </div>
    </div>
    <div class="time-month">
      <div class="time-category"></div>

      <!--      TODO: loop -->
      <div class="time-months time-types">
        <div
          [class.hover-highlight]="
            currentHover === 0 || currentHoverForHeader === 0
          "
          (mouseleave)="setHover()"
          (mouseenter)="setHover(0)"
        >
          <span>Beginn</span>
          <span>Ende</span>
        </div>
        <div
          [class.hover-highlight]="
            currentHover === 1 || currentHoverForHeader === 1
          "
          (mouseleave)="setHover()"
          (mouseenter)="setHover(1)"
        >
          <span>Beginn</span>
          <span>Ende</span>
        </div>
        <div
          [class.hover-highlight]="
            currentHover === 2 || currentHoverForHeader === 2
          "
          (mouseleave)="setHover()"
          (mouseenter)="setHover(2)"
        >
          <span>Beginn</span>
          <span>Ende</span>
        </div>
        <div
          [class.hover-highlight]="
            currentHover === 3 || currentHoverForHeader === 3
          "
          (mouseleave)="setHover()"
          (mouseenter)="setHover(3)"
        >
          <span>Beginn</span>
          <span>Ende</span>
        </div>
        <div
          [class.hover-highlight]="
            currentHover === 4 || currentHoverForHeader === 4
          "
          (mouseleave)="setHover()"
          (mouseenter)="setHover(4)"
        >
          <span>Beginn</span>
          <span>Ende</span>
        </div>
        <div
          [class.hover-highlight]="
            currentHover === 5 || currentHoverForHeader === 5
          "
          (mouseleave)="setHover()"
          (mouseenter)="setHover(5)"
        >
          <span>Beginn</span>
          <span>Ende</span>
        </div>
        <div
          [class.hover-highlight]="
            currentHover === 6 || currentHoverForHeader === 6
          "
          (mouseleave)="setHover()"
          (mouseenter)="setHover(6)"
        >
          <span>Beginn</span>
          <span>Ende</span>
        </div>
        <div
          [class.hover-highlight]="
            currentHover === 7 || currentHoverForHeader === 7
          "
          (mouseleave)="setHover()"
          (mouseenter)="setHover(7)"
        >
          <span>Beginn</span>
          <span>Ende</span>
        </div>
        <div
          [class.hover-highlight]="
            currentHover === 8 || currentHoverForHeader === 8
          "
          (mouseleave)="setHover()"
          (mouseenter)="setHover(8)"
        >
          <span>Beginn</span>
          <span>Ende</span>
        </div>
        <div
          [class.hover-highlight]="
            currentHover === 9 || currentHoverForHeader === 9
          "
          (mouseleave)="setHover()"
          (mouseenter)="setHover(9)"
        >
          <span>Beginn</span>
          <span>Ende</span>
        </div>
        <div
          [class.hover-highlight]="
            currentHover === 10 || currentHoverForHeader === 10
          "
          (mouseleave)="setHover()"
          (mouseenter)="setHover(10)"
        >
          <span>Beginn</span>
          <span>Ende</span>
        </div>
        <div
          [class.hover-highlight]="
            currentHover === 11 || currentHoverForHeader === 11
          "
          (mouseleave)="setHover()"
          (mouseenter)="setHover(11)"
        >
          <span>Beginn</span>
          <span>Ende</span>
        </div>
      </div>
    </div>

    <div class="time-lists">
      <div
        class="time-list"
        [class.divider]="data[key].divide"
        *ngFor="let key of keys"
      >
        <div class="time-category">
          {{ data[key].name }}

          <span pTooltip="Mittelwert" *ngIf="key === 'ILL_DAYS'">{{
            totalIlls.days
          }}</span>
          <span pTooltip="Mittelwert" *ngIf="key === 'ILL_QUOTA'">{{
            totalIlls.quota
          }}</span>
        </div>
        <div class="times-container">
          <div
            class="times"
            (mouseleave)="setHoverForHeader()"
            (mouseenter)="setHoverForHeader(i)"
            [class.hover-highlight]="currentHover === i"
            *ngFor="let month of data[key].months; let i = index"
          >
            <aside class="times-values-container">
              <span class="times-values" *ngIf="!data[key].single">
                <span
                  >{{ month.SOLL }}
                  <i
                    class="pi pi-info-circle small-info"
                    tooltipStyleClass="tooltip-wider"
                    [pTooltip]="month.SOLL_TOOLTIP"
                    *ngIf="month.SOLL_TOOLTIP"
                  ></i>
                </span>
              </span>
              <span class="times-values" *ngIf="data[key].single">
                <span>-</span>
              </span>

              <span class="times-values">
                <span
                  >{{ month.IST }}
                  <i
                    class="pi pi-info-circle small-info"
                    tooltipStyleClass="tooltip-wider"
                    [pTooltip]="month.IST_TOOLTIP"
                    *ngIf="month.IST_TOOLTIP"
                  ></i>
                </span>
              </span>
            </aside>

            <div class="times-values-full" *ngIf="month.COMPARE">
              <span>{{ month.COMPARE }}%</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
