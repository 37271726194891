import { Component, OnDestroy, OnInit } from '@angular/core'
import { CaregiverService } from '../../../../services/caregivers.service'
import { ActivatedRoute, ParamMap } from '@angular/router'
import { DialogService } from 'primeng/dynamicdialog'
import { NoticeDialogComponent } from '../../../../components/dialogs/notice-dialog/notice-dialog.component'
import { MenuItem } from 'primeng/api'
import { CaregiverDetailModel } from '../../../../models/caregiver/caregiver-detail.model'
import { ChangeStatusCaregiverDialogComponent } from '../../../../components/dialogs/change-status-caregiver-dialog/change-status-caregiver-dialog.component'
import { AuthService } from '../../../../services/auth.service'
import { SendMessageToCaregiverDialogComponent } from '../../../../components/dialogs/send-message-to-caregiver-dialog/send-message-to-caregiver-dialog.component'
import { CaregiverApprovePlanningDialogComponent } from '../../../../components/dialogs/caregiver-approve-planning-dialog/caregiver-approve-planning-dialog.component'
import { AccountCaregiveDialogComponent } from '../../../../components/dialogs/account-caregiver-dialog/account-caregiver-dialog.component'

@Component({
  selector: 'app-caregivers-detail-general',
  templateUrl: './caregivers-detail-general.component.html',
})
export class CaregiversDetailGeneralComponent implements OnInit, OnDestroy {
  public caregiver = new CaregiverDetailModel()
  public caregiverId: string | null = null
  private routeSubscription: any = null

  public settingMenuItems: MenuItem[] = []

  constructor(
    private authService: AuthService,
    private route: ActivatedRoute,
    private dialogService: DialogService,
    private caregiverService: CaregiverService
  ) {}

  ngOnInit(): void {
    if (this.route.parent) {
      this.routeSubscription = this.route.parent.paramMap.subscribe(
        (params: ParamMap) => {
          this.caregiverId = params.get('id')

          this.caregiverService
            .loadForSimpleMode(this.caregiverId)
            .subscribe((data: CaregiverDetailModel) => {
              this.caregiver = data

              this.setMenuItems()
            })
        }
      )
    }
  }

  ngOnDestroy(): void {
    this.routeSubscription && this.routeSubscription.unsubscribe()
  }

  public openChangeStatusDialog(): void {
    this.dialogService.open(ChangeStatusCaregiverDialogComponent, {
      header: 'Status ändern',
      width: '760px',
      styleClass: 'dialog-container',
      data: {
        isEdit: false,
        caregiver: { ...this.caregiver },
      },
    })
  }

  public openSendMessageToCaregiver(): void {
    this.dialogService.open(SendMessageToCaregiverDialogComponent, {
      header: 'E-Mail senden',
      width: '620px',
      styleClass: 'dialog-container',
      data: {
        isEdit: false,
        caregiver: { ...this.caregiver },
      },
    })
  }

  public openApprovePlanningDialog(): void {
    this.dialogService.open(CaregiverApprovePlanningDialogComponent, {
      header: 'Planung abschließen',
      width: '390px',
      styleClass: 'dialog-container',
      data: {
        caregiver: { ...this.caregiver },
      },
    })
  }

  public openAccountDialog(): void {
    this.dialogService.open(AccountCaregiveDialogComponent, {
      header: 'Account',
      width: '760px',
      styleClass: 'dialog-container',
      data: {
        caregiver: { ...this.caregiver },
      },
    })
  }

  public openNoticeDialog(): void {
    this.dialogService.open(NoticeDialogComponent, {
      header: 'Notiz bearbeiten',
      width: '450px',
      styleClass: 'dialog-container',
      data: {
        type: 'caregiver',
        type_id: this.caregiverId,
        notice: this.caregiver.notice,
      },
    })
  }

  private setMenuItems(): void {
    const isEmployeed = this.caregiver.current_status.status === 'EMPLOYMENT'

    this.settingMenuItems = [
      {
        label: 'Notiz',
        icon: 'pi pi-align-left',
        command: () => this.openNoticeDialog(),
      },
      {
        label: 'Nachricht senden',
        icon: 'pi pi-send',
        command: () => this.openSendMessageToCaregiver(),
      },
    ]

    if (this.authService.can('Betreuungskraft.Betreuungskraft Status ändern')) {
      this.settingMenuItems.push({
        label: 'Status ändern',
        icon: 'pi pi-question-circle',
        command: () => this.openChangeStatusDialog(),
      })
    }

    if (isEmployeed) {
      this.settingMenuItems.push({
        label: 'Planung abschließen',
        icon: 'pi pi-clock',
        command: () => this.openApprovePlanningDialog(),
      })
    }

    if (this.authService.can('Betreuungskraft.Account verwalten')) {
      this.settingMenuItems.push({
        separator: true,
      })

      this.settingMenuItems.push({
        label: 'Account',
        icon: 'pi pi-user',
        command: () => this.openAccountDialog(),
      })
    }

    if (this.caregiver.user_id && this.authService.isSuperAdmin()) {
      this.settingMenuItems.push({
        label: 'Als Benutzer einloggen',
        icon: 'pi pi-lock',
        command: () => this.loginUsingId(),
      })
    }
  }

  public loginUsingId(): void {
    this.authService.loginUsingId(this.caregiver.user_id).subscribe(() => {
      this.authService.setImpersonation()

      window.location.href = '/'
    })
  }
}
