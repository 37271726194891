<form #form="ngForm" class="dialog-form">
  <div class="dialog-sidebar space-between">
    <div class="p-fluid p-formgrid p-grid without-border">
      <div class="p-field p-col-12">
        <div class="dialog-text-link">
          <span (click)="openAddDocumentDialog()">Dokument hochladen</span>
        </div>
      </div>
    </div>
    <div class="p-fluid p-formgrid p-grid without-border">
      <div class="p-field p-col-12">
        <label class="highlight-label">Hochgeladene Dokumente</label>
        <div
          class="dialog-no-message"
          *ngIf="todo.media.length === 0"
        >
          Keine Dokumente hochgeladen
        </div>
        <div
          class="document-list"
          *ngFor="let document of todo.media"
        >
          <span
          >{{ document.name }}
            <a
              [href]="documentService.getDocumentDownloadLink(document.uuid)"
              target="_blank"
            >(ansehen)</a
            ></span
          >
        </div>
      </div>
    </div>
  </div>

  <div class="dialog-form-body">
    <div class="p-field" *ngIf="!data.isEdit && !data.isPreSelected">
      <div class="p-formgroup-inline">
        <div class="p-field-radiobutton">
          <p-radioButton
            (onClick)="resetOptions()"
            label="Allgemein"
            name="type"
            [(ngModel)]="todo.assignment_type"
            value="GENERAL"
          ></p-radioButton>
        </div>
        <div class="p-field-radiobutton">
          <p-radioButton
            (onClick)="resetOptions()"
            label="Betreuungskraft"
            name="type"
            [(ngModel)]="todo.assignment_type"
            value="CAREGIVER"
          ></p-radioButton>
        </div>
        <div class="p-field-radiobutton">
          <p-radioButton
            (onClick)="resetOptions()"
            label="Patient"
            name="type"
            [(ngModel)]="todo.assignment_type"
            value="PATIENT"
          ></p-radioButton>
        </div>
        <div class="p-field-radiobutton">
          <p-radioButton
            (onClick)="resetOptions()"
            label="PKW"
            name="type"
            [(ngModel)]="todo.assignment_type"
            value="CAR"
          ></p-radioButton>
        </div>
      </div>
    </div>

    <div class="p-field">
      <label for="title">
        Titel *
      </label>
      <input
        id="title"
        name="title"
        type="text"
        [(ngModel)]="todo.title"
        pInputText
        #title="ngModel"
        [required]="true"
        [class.p-invalid]="title.invalid && (title.dirty || title.touched)"
      />
    </div>

    <div class="p-field">
      <label for="category">Kategorie</label>
      <p-dropdown
        [showClear]="true"
        appendTo="body"
        scrollHeight="500px"
        placeholder="Optional"
        inputId="category"
        name="category"
        optionLabel="category"
        optionValue="id"
        [options]="todoCategories"
        [(ngModel)]="todo.category_id"
      ></p-dropdown>
    </div>

    <div class="p-field">
      <label for="comment">Bemerkung</label>
      <textarea
        id="comment"
        name="comment"
        [(ngModel)]="todo.comment"
        [rows]="10"
        pInputTextarea
      ></textarea>
    </div>

    <div
      class="p-field"
      *ngIf="
        todo.assignment_type === 'CAREGIVER' &&
        !data.isEdit &&
        !data.isPreSelected
      "
    >
      <label for="caregiver">Betreuungskraft * </label>
      <p-autoComplete
        field="full_name"
        inputId="caregiver"
        name="caregiver"
        appendTo="body"
        [forceSelection]="true"
        [(ngModel)]="todo.caregiver"
        [suggestions]="caregivers"
        (completeMethod)="searchCaregivers($event)"
        [required]="true"
        #caregiver="ngModel"
        [class.p-invalid]="
          caregiver.invalid && (caregiver.dirty || caregiver.touched)
        "
      >
        <ng-template let-caregiver pTemplate="item">
          <div>{{ caregiver.full_name }} ({{ caregiver.status_string }})</div>
        </ng-template>
      </p-autoComplete>
      <div
        *ngIf="caregiver.invalid && (caregiver.dirty || caregiver.touched)"
        class="p-invalid"
      >
        <div *ngIf="caregiver.errors?.required">
          <small>Bitte wählen Sie eine Betreuungskraft aus</small>
        </div>
      </div>
    </div>

    <div
      class="p-field"
      *ngIf="
        todo.assignment_type === 'CAREGIVER' &&
        (data.isEdit ||
          (data.isPreSelected && data.selectedType === 'CAREGIVER'))
      "
    >
      <label>Betreuungskraft</label>
      <span>{{ data.user_type_name }}</span>
    </div>

    <div
      class="p-field"
      *ngIf="
        todo.assignment_type === 'CAR' && !data.isEdit && !data.isPreSelected
      "
    >
      <label for="caregiver"
        >PKW *
        <i
          class="pi pi-info-circle"
          pTooltip="Suche nach Hersteller, Model oder Kennzeichen"
          tooltipStyleClass="tooltip-wider"
        ></i
      ></label>
      <p-autoComplete
        field="full_name"
        inputId="car"
        name="car"
        appendTo="body"
        [forceSelection]="true"
        [(ngModel)]="todo.car"
        [suggestions]="cars"
        (completeMethod)="searchCars($event)"
        [required]="true"
        #car="ngModel"
        [class.p-invalid]="car.invalid && (car.dirty || car.touched)"
      >
        <ng-template let-car pTemplate="item">
          <div>{{ car.full_name }}</div>
        </ng-template>
      </p-autoComplete>
<!--      <div *ngIf="car.invalid && (car.dirty || car.touched)" class="p-invalid">-->
<!--        <div *ngIf="car.errors?.required">-->
<!--          <small>Bitte wählen Sie ein PKW aus</small>-->
<!--        </div>-->
<!--      </div>-->
    </div>

    <div
      class="p-field"
      *ngIf="
        todo.assignment_type === 'PATIENT' &&
        !data.isEdit &&
        !data.isPreSelected
      "
    >
      <label for="patient">Patient * </label>
      <p-autoComplete
        field="full_name"
        inputId="patient"
        name="patient"
        appendTo="body"
        [forceSelection]="true"
        [(ngModel)]="todo.patient"
        [suggestions]="patients"
        (completeMethod)="searchPatients($event)"
        [required]="true"
        #patient="ngModel"
        [class.p-invalid]="
          patient.invalid && (patient.dirty || patient.touched)
        "
      >
        <ng-template let-patient pTemplate="item">
          <div class="search-result-data">
            <span class="search-result-name">
              <b>{{ patient.full_name }}</b>
              <span class="search-result-id">#{{ patient.id }}</span>
              <i
                *ngIf="patient.status"
                class="p-tag p-tag-rounded p-tag-{{ patient.status }}"
                >{{ patient.status_string }}</i
              >
            </span>
            <span class="search-result-address">
              {{ patient.zipcode }} {{ patient.city }}
            </span>
          </div>
        </ng-template>
      </p-autoComplete>
      <div
        *ngIf="patient.invalid && (patient.dirty || patient.touched)"
        class="p-invalid"
      >
        <div *ngIf="patient.errors?.required">
          <small>Bitte wählen Sie einen Patienten aus</small>
        </div>
      </div>
    </div>

    <div
      class="p-field"
      *ngIf="
        todo.assignment_type === 'PATIENT' &&
        (data.isEdit || (data.isPreSelected && data.selectedType === 'PATIENT'))
      "
    >
      <label>Patient</label>
      <span>{{ data.user_type_name }}</span>
    </div>

    <div class="p-fluid p-formgrid p-grid without-border" style="padding: 0">
      <div class="p-field p-col-8">
        <label for="assignments">Zuweisung an</label>
        <p-multiSelect
          selectedItemsLabel="{0} Benutzer ausgewählt"
          inputId="assignments"
          [options]="userSystemOptions"
          optionLabel="full_name"
          name="assignments"
          [filter]="true"
          [(ngModel)]="todo.user_systems"
          appendTo="body"
        ></p-multiSelect>
      </div>

      <div class="p-field p-col-4">
        <label for="priority">Priorität *</label>
        <p-dropdown
          inputId="priority"
          name="priority"
          [options]="priorityOptions"
          [(ngModel)]="todo.priority"
          appendTo="body"
        ></p-dropdown>
      </div>
    </div>

    <div class="p-field">
      <div class="p-formgroup-inline">
        <div class="p-field-radiobutton">
          <p-radioButton
            label="Zu erledigen bis"
            name="for_when_type"
            [(ngModel)]="todo.for_when_type"
            value="FINISH_UNTIL"
            #for_when_type="ngModel"
            required
          ></p-radioButton>
          <i
            class="pi pi-info-circle"
            pTooltip="Wird immer im Dashboard angezeigt"
          ></i>
        </div>
        <div class="p-field-radiobutton">
          <p-radioButton
            label="Zu erledigen am"
            name="for_when_type"
            [(ngModel)]="todo.for_when_type"
            value="FINISH_AT"
            #for_when_type="ngModel"
            required
          ></p-radioButton>
          <i
            class="pi pi-info-circle"
            pTooltip="Wird ab dem Zeitpunkt im Dashboard angezeigt"
          ></i>
        </div>
      </div>
    </div>

    <div class="p-field">
      <p-calendar
        [firstDayOfWeek]="1"
        [showWeek]="true"
        [showWeek]="true"
        inputId="for_when"
        name="for_when"
        [showButtonBar]="true"
        [(ngModel)]="todo.for_when_string"
        dateFormat="dd.mm.yy"
        appendTo="body"
        dataType="string"
        [showIcon]="true"
        [required]="true"
        #for_when="ngModel"
        [class.p-invalid]="
          for_when.invalid && (for_when.dirty || for_when.touched)
        "
      ></p-calendar>
      <div
        *ngIf="for_when.invalid && (for_when.dirty || for_when.touched)"
        class="p-invalid"
      >
        <div *ngIf="for_when.errors?.required">
          <small>Bitte wählen Sie ein Datum</small>
        </div>
      </div>
    </div>

    <div class="p-field" *ngIf="data.isEdit">
      <label>Erstellt von</label>
      <span
        >{{ todo.user?.user_system?.full_name || "??" }} am
        {{ todo.created_at | date: "dd.MM.y, H:mm" }} Uhr</span
      >
    </div>
  </div>
  <div class="dialog-form-footer">
    <i *ngIf="!data.isEdit"></i>

    <div *ngIf="data.isEdit">
      <i
        class="pi pi-trash with-margin-right"
        [class.pi-trash]="!submittedDelete"
        [class.pi-spin]="submittedDelete"
        [class.pi-spinner]="submittedDelete"
        tooltipPosition="left"
        tooltipZIndex="20000"
        [pTooltip]="submittedDelete ? 'Wird gelöscht' : 'Todo löschen'"
        (click)="remove($event)"
      ></i>

      <i
        class="pi pi-eye"
        (click)="openShowTodoDialog()"
        pTooltip="Todo ansehen"
      ></i>
    </div>

    <button
      pButton
      [label]="data.isEdit ? 'Speichern' : 'Erstellen'"
      type="submit"
      (click)="save()"
      [disabled]="submitted"
      [icon]="submitted ? 'pi pi-spin pi-spinner' : 'pi pi-check'"
    ></button>
  </div>
</form>

<p-confirmPopup></p-confirmPopup>
