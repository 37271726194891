<div class="diff-header" *ngIf="keys.length === 0">
  <div>Keine Änderung</div>
</div>

<div *ngFor="let key of keys">
  <div class="background-hightlight-hover diff-header">
    <div>{{ key }}</div>
  </div>
  <div
    class="diff-line diff-line-full"
    *ngFor="let item of data[key]; let i = index"
  >
    <div>
      <b>{{ item.value }}</b>

      <!-- Beim letzten History Eintrag wird zusätzlich noch der Wert angezeigt, der bei der Erstellung eingetragen war -->
      <span *ngIf="i === data[key].length - 1">
        <br />
        Anfänglicher Wert: {{ item.value_before || "-" }}</span
      >
      <br />
      {{ item.created_from }} am {{ item.created_at }}
    </div>
  </div>
</div>
