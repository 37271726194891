<div class="content">
  <header class="content-header">
    <div class="content-header-menu-tabs">
      <a
        routerLink="/invoices/consultings"
        routerLinkActive="active"
        [routerLinkActiveOptions]="{ exact: true }"
      >
        Zum Versand
      </a>

      <a
        routerLink="/invoices/consultings/opened"
        routerLinkActive="active"
        [routerLinkActiveOptions]="{ exact: true }"
      >
        Offen
      </a>

      <a
        routerLink="/invoices/consultings/finished"
        routerLinkActive="active"
        [routerLinkActiveOptions]="{ exact: true }"
      >
        Abgeschlossen
      </a>

      <a
        routerLink="/invoices/consultings/credits"
        routerLinkActive="active"
        [routerLinkActiveOptions]="{ exact: true }"
      >
        Gutschriften
      </a>

      <a
        routerLink="/invoices/consultings/storno"
        routerLinkActive="active"
        [routerLinkActiveOptions]="{ exact: true }"
      >
        Nicht fakturiert
      </a>
    </div>
  </header>
</div>

<router-outlet></router-outlet>
