import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core'
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog'
import { EventBusService, GlobalEvent } from 'src/app/services/eventbus.service'
import { ToastService } from 'src/app/services/toast.service'
import { NgForm } from '@angular/forms'
import { Subscription } from 'rxjs'
import { PatientService } from '../../../services/patient.service'
import { UserSystemService } from '../../../services/user-system.service'
import { SearchService } from '../../../services/search.service'
import { SearchResultModel } from '../../../models/search/search-result.model'
import { VetoService } from '../../../services/veto.service'
import { VetoModel } from '../../../models/veto/veto.model'
import { ConfirmationService } from 'primeng/api'

@Component({
  selector: 'app-add-veto-dialog',
  templateUrl: './add-veto-dialog.component.html',
})
export class AddVetoDialogComponent implements OnInit, OnDestroy {
  @ViewChild('form', { static: true }) form!: NgForm

  private formSubscription: Subscription | null | undefined = null
  private isDirty = false

  submitted = false
  submittedDelete = false

  public userSystemOptions: any[] = []
  public caregivers: SearchResultModel[] = []
  public patients: SearchResultModel[] = []

  public veto = new VetoModel()

  public data: any

  constructor(
    private ref: DynamicDialogRef,
    private config: DynamicDialogConfig,
    private searchService: SearchService,
    private vetoService: VetoService,
    private userSystemService: UserSystemService,
    private patientService: PatientService,
    private eventbus: EventBusService,
    private toastService: ToastService,
    private confirmationService: ConfirmationService
  ) {}

  public ngOnInit(): void {
    this.data = this.config.data

    this.formSubscription = this.form.valueChanges?.subscribe(() => {
      if (!this.form.pristine) {
        this.isDirty = true
      }
    })

    if (this.data.isEdit) {
      this.veto = this.data.veto
    } else {
      this.veto.caregiver_id = this.data.caregiver_id || null
      this.veto.patient_id = this.data.patient_id || null
    }
  }

  ngOnDestroy(): void {
    this.formSubscription?.unsubscribe()
  }

  public searchCaregivers(event: any): void {
    this.searchService
      .findCaregivers(event.query.trim())
      .subscribe((results: SearchResultModel[]) => {
        this.caregivers = results
      })
  }

  public searchPatients(event: any): void {
    this.searchService
      .findPatients(event.query.trim())
      .subscribe((results: SearchResultModel[]) => {
        this.patients = results
      })
  }

  public save(): void {
    if (!this.form.form.valid) {
      this.submitted = false
      this.form.form.markAllAsTouched()
      return
    }

    this.submitted = true

    const subscription = this.data.isEdit
      ? this.vetoService.update(this.veto)
      : this.vetoService.store(this.veto)

    subscription.subscribe(
      () => {
        this.submitted = false
        this.eventbus.emit(GlobalEvent.VetoChanged)
        this.ref.close()
        this.toastService.success(
          'Veto gespeichert',
          'Das Veto wurde erfolgreich gespeichert'
        )
      },
      () => {
        this.toastService.error(
          'Etwas ist schiefgelaufen...',
          'Bitte wenden Sie sich an den Support'
        )

        this.submitted = false
      }
    )
  }

  public remove(event: any): void {
    this.confirmationService.confirm({
      target: event.target ?? undefined,
      message: 'Veto wirklich löschen?',
      accept: () => {
        this.submittedDelete = true

        this.vetoService.delete(this.veto.id || null).subscribe(
          () => {
            this.submittedDelete = false
            this.eventbus.emit(GlobalEvent.VetoChanged)
            this.ref.close()
            this.toastService.success(
              'Veto gelöscht',
              'Das Veto wurde erfolgreich gelöscht'
            )
          },
          () => {
            this.submittedDelete = false
            this.toastService.error(
              'Löschen fehlgeschlagen',
              'Das Todo konnte nicht gelöscht werden'
            )
          }
        )
      },
      reject: () => {},
    })
  }
}
