import { Component, ElementRef, OnInit, ViewChild } from '@angular/core'
import { CaregiverListActivatedModel } from '../../../../models/caregiver/caregiver-list-activated.model'
import { CaregiverService } from '../../../../services/caregivers.service'
import { PhoneCallService } from '../../../../services/phone-call.service'
import { CaregiverStatusModel } from '../../../../models/caregiver/caregiver-status.model'
import { AuthService } from '../../../../services/auth.service'
import { HospitationFeedbackDialogComponent } from '../../../../components/dialogs/hospitation-feedback-dialog/hospitation-feedback-dialog.component'
import { DialogService } from 'primeng/dynamicdialog'

@Component({
  selector: 'app-caregivers-list-applicants-applicants',
  templateUrl: './caregivers-list-applicants-applicants.component.html',
  styleUrls: ['./caregivers-list-applicants-applicants.component.scss'],
})
export class CaregiversListApplicantsApplicantsComponent implements OnInit {
  @ViewChild('dt') table: ElementRef | any = null

  public globalFilterValue = ''
  public loading = true
  public firstPage = 0
  public caregivers: CaregiverListActivatedModel[] = []

  constructor(
    private dialogService: DialogService,
    public authService: AuthService,
    public phoneCallService: PhoneCallService,
    private caregiverService: CaregiverService
  ) {}

  ngOnInit(): void {
    this.loadCaregivers()
  }

  public filterTableGlobal(event: any): void {
    this.table && this.table.filterGlobal(event.target.value.trim(), 'contains')
  }

  public confirmHospitation(caregiverStatus: CaregiverStatusModel): void {
    if (!window.confirm('Hospitation wirklich bestätigen?')) {
      return
    }

    this.caregiverService
      .confirmHospitation(caregiverStatus.id)
      .subscribe(() => {
        this.loadCaregivers(false)
      })
  }

  public openHospitationFeedbackDialog(caregiver: any): void {
    this.dialogService.open(HospitationFeedbackDialogComponent, {
      data: {
        is_female: caregiver.is_female,
        current_status: caregiver.current_status,
        is_frozen: true,
      },
      header: 'Feedback',
      styleClass: 'dialog-container',
      dismissableMask: false,
      width: '520px',
    })
  }

  public loadCaregivers(withLoading: boolean = true): void {
    if (withLoading) {
      this.loading = true
    }

    this.caregiverService
      .listApplicants()
      .subscribe((data: CaregiverListActivatedModel[]) => {
        this.caregivers = data

        this.loading = false

        setTimeout(() => {
          if (this.table) {
            const totalItems = this.table._totalRecords

            this.globalFilterValue = this.table.filters.global?.value ?? ''

            if (
              this.table._first > totalItems ||
              this.table._rows > totalItems
            ) {
              this.firstPage = 0
            }
          }
        })
      })
  }
}
