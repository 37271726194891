<section class="auth-section" *ngIf="!hasToken">
  <i class="blob-1"></i>
  <i class="blob-2"></i>

  <h2>Passwort zurücksetzen</h2>

  <div class="login-body">
    <p>
      Bitte tragen Sie Ihre E-Mail ein. Wir senden Ihnen einen Link zum
      Zurücksetzen Ihres Passworts zu.
    </p>

    <form (ngSubmit)="form.form.valid && reset()" #form="ngForm">
      <div class="p-field">
        <label for="email">E-Mail</label>
        <input
          type="email"
          id="email"
          pInputText
          [(ngModel)]="data.email"
          name="email"
          required
        />
      </div>

      <small class="color-main1 auth-message" *ngIf="isSuccess"
      >Ein Link zum zurücksetzen des Passworts wurde versendet</small
      >
      <small class="color-alert auth-message" *ngIf="errorMessage">{{
          errorMessage
        }}</small>

      <div class="login-footer">
        <a routerLink="/login" tabindex="-1">zurück zum Login</a>

        <button
          pButton
          label="E-Mail anfordern"
          type="submit"
          iconPos="right"
          [icon]="submitted ? 'pi pi-spin pi-spinner' : 'pi pi-send'"
        ></button>
      </div>
    </form>
  </div>
</section>

<section class="auth-section" *ngIf="hasToken">
  <i class="blob-1"></i>
  <i class="blob-2"></i>

  <h2>Neues Passwort vergeben</h2>

  <div class="login-body">
    <form (ngSubmit)="form.form.valid && confirm()" #form="ngForm">
      <div class="p-field">
        <label for="password">Passwort</label>
        <input
          type="password"
          id="password"
          pInputText
          [(ngModel)]="data.password"
          name="password"
          required
        />
      </div>

      <div class="p-field">
        <label for="password_confirmation">Passwort bestätigen</label>
        <input
          type="password"
          id="password_confirmation"
          pInputText
          [(ngModel)]="data.password_confirmation"
          name="password_confirmation"
          required
        />
      </div>

      <small class="color-alert auth-message" *ngIf="errorMessage">{{
          errorMessage
        }}</small>

      <div class="login-footer">
        <i></i>
        <button
          pButton
          label="Speichern und einloggen"
          type="submit"
          iconPos="right"
          [icon]="submitted ? 'pi pi-spin pi-spinner' : 'pi pi-key'"
        ></button>
      </div>
    </form>
  </div>
</section>
