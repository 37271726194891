<div class="content-actions">
  <h2>
    <i class="tio">send</i>
    Terminübergabe
  </h2>
</div>

<app-loader [loading]="loading"></app-loader>

<div class="content" *ngIf="!loading">
  <header class="content-header">
    <span>
      <i (click)="goToPreviousMonth()" class="pi pi-chevron-left"></i>

      <p-dropdown
        inputId="month"
        name="month"
        scrollHeight="500px"
        (onChange)="loadCaregivers(false)"
        [options]="monthOptions"
        [(ngModel)]="month"
        [style]="{ width: '150px', zIndex: '10' }"
      ></p-dropdown>

      <p-dropdown
        inputId="year"
        name="year"
        (onChange)="loadCaregivers(false)"
        [options]="yearOptions"
        [(ngModel)]="year"
        [style]="{ margin: '0 0 0 -1px' }"
      ></p-dropdown>

      <i
        (click)="goToNextMonth()"
        class="pi pi-chevron-right"
        style="margin: 0 10px 0 10px"
      ></i>
    </span>

    <div
      class="table-search-container"
      [class.searchfield-active]="globalFilterValue != ''"
    >
      <div class="p-input-icon-left">
        <i class="pi pi-search"></i>
        <input
          pInputText
          (input)="filterTableGlobal($event)"
          placeholder="Suche..."
          [(ngModel)]="globalFilterValue"
        />
      </div>
    </div>
  </header>
</div>

<div class="content" style="margin: 0" *ngIf="!loading">
  <div
    class="content-body"
    [class.content-loading]="contentLoading"
    *ngIf="caregivers.length == 0"
  >
    <span class="content-no-message"
      >Keine freigegebenen Betreuungskräfte vorhanden</span
    >
  </div>

  <div
    class="content-body without-padding"
    [class.content-loading]="contentLoading"
    *ngIf="caregivers.length > 0"
  >
    <p-table
      #dt
      [value]="caregivers"
      [globalFilterFields]="['id', 'first_name', 'last_name', 'full_name']"
      [rowHover]="true"
    >
      <ng-template pTemplate="header">
        <tr class="table-headline-highlight">
          <th pSortableColumn="last_name">
            Betreuungskraft <p-sortIcon field="last_name"></p-sortIcon>
          </th>
          <th>
            <div class="text-center-container">
              <i class="pi pi-envelope" style="font-size: 14px"></i>
              Termine übergeben
            </div>
          </th>
          <th style="width: 520px">
            <div class="text-center-container">
              <i class="pi pi-info-circle" style="font-size: 14px"></i>
              Bemerkung
            </div>
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-caregiver>
        <tr class="clickable">
          <td (click)="openDialog(caregiver)">
            <a class="color-main1" routerLink="/caregivers/{{ caregiver.id }}"
              >{{ caregiver.last_name }}, {{ caregiver.first_name }}</a
            >
          </td>
          <td (click)="openDialog(caregiver)">
            {{ caregiver.patients.with_transfer }} /
            {{ caregiver.patients.all.length }}
          </td>
          <td (click)="openDialog(caregiver)">
            {{ caregiver.approved_planning_comment }}
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
