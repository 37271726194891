import { HttpErrorResponse } from '@angular/common/http'
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core'
import { StatusCodes } from 'http-status-codes'
import {
  DialogService,
  DynamicDialogConfig,
  DynamicDialogRef,
} from 'primeng/dynamicdialog'
import { EventBusService, GlobalEvent } from 'src/app/services/eventbus.service'
import { ToastService } from 'src/app/services/toast.service'
import { DocumentService } from '../../../services/document.service'
import { NgForm } from '@angular/forms'
import { Subscription } from 'rxjs'
import { ConfirmationService, MenuItem } from 'primeng/api'
import { AccountingReturnService } from '../../../services/accounting-return.service'
import { SearchResultModel } from '../../../models/search/search-result.model'
import { SearchService } from '../../../services/search.service'
import { HelperService } from '../../../services/helper.service'
import * as currency from 'currency.js'
import { AccountingReturnHistoryModel } from '../../../models/accounting/accounting-return-history.model'
import { AuthModel } from '../../../models/user/auth.model'
import { AuthService } from '../../../services/auth.service'
import { PatientModel } from '../../../models/customer-patient/patient.model'
import { ceil, floor } from 'lodash-es'
import { InvoiceService } from '../../../services/invoice.service'
import { PatientService } from '../../../services/patient.service'
import { MediaModel } from '../../../models/document/media.model'
import { ConfirmWithTextDialogComponent } from '../confirm-with-text-dialog/confirm-with-text-dialog.component'

@Component({
  selector: 'app-invoice-dialog',
  templateUrl: './invoice-dialog.component.html',
})
export class InvoiceDialogComponent implements OnInit, OnDestroy {
  @ViewChild('form', { static: true }) form!: NgForm

  private formSubscription: Subscription | null | undefined = null
  private isDirty = false
  private selectedFile: any = null
  public patients: SearchResultModel[] = []

  public patient: PatientModel = new PatientModel()

  public clickedOnPreview = false
  public clickedOnPreviewMail = false
  public isEdit = false
  public type = ''
  public isFrozen = false
  public withPositions = true
  public data: any = {}

  public currentUser: AuthModel | null = new AuthModel()
  public editHistory: AccountingReturnHistoryModel | null = null
  public historyComment = ''

  public statusOptions = [
    { label: 'ERSTELLT', value: 'CREATED', backgroundColor: '#3296dc' },
    { label: 'IN BEARBEITUNG', value: 'EDITED', backgroundColor: '#dbaa25' },
    { label: 'ERLEDIGT', value: 'FINISHED', backgroundColor: '#84bc3c' },
  ]

  public items: MenuItem[] = [
    {
      label: 'Rechnung versenden',
      icon: 'pi pi-file-pdf',
      command: () => {
        this.createInvoice()
      },
    },
  ]

  public confirmed = {
    case_number: false,
    performance_month: false,
    performance_year: false,
    invoice_number: false,
    invoice_date_string: false,
    total_value: false,
    invoice_value: false,
  }
  public receiverOptions: any[] = []
  public ccOptions: any[] = []

  private currencyOptions = {
    // Cent
    decimal: ',',
    // Tausender
    separator: '.',
    symbol: '€',
    pattern: `# !`,
  }

  public invoiceType = ''

  public subtotal = ''
  public incorrectInsurerNumber = false

  public partialPaymentEditId: any = null
  public partialPayment = ''

  // TODO: Invoice Model erstellen
  public values = {
    status: 'DRAFT',
    invoiceable: {} as any,
    type: '',
    id: null as any,
    invoice_number: '',
    invoice_number_prefix: '',
    invoice_number_complete: '',
    address: '',
    sent_type: 'post',
    sent_for: 'PATIENT',
    multiplier_sent_for: null as any,
    sent_for_id: null as any,
    sent_type_text: '',
    sent_for_text: '',
    individual_email: '',
    is_storno: false,
    is_credit: false,
    receiver: null as any,
    receiver_nachweis: null as any,
    cc: [] as any,
    cc_nachweis: [] as any,
    part_payments: [] as any,
    part_payments_sum: '',
    part_payments_sum_euro_formatted: '',
    vat: 19,
    media: [] as MediaModel[],
    opened_at: null as any,
    finished_at: null as any,
    total_price_euro_formatted: '',
    subtotal_price_euro_formatted: '',
    vat_price_euro_formatted: '',
    trips: [] as any,
    send_be_to_customer: false,
    positions: [
      {
        amount: '',
        text: '',
        unit_price: null,
        unit_price_euro: null,
        unit_price_euro_formatted: null,
        total_price: null as any,
        total_price_euro_formatted: null as any,
        total_price_euro: null as any,
      },
    ] as any,
  }

  public submitted = false
  public submittedDelete = false

  public monthOptions: any = []
  public isParsing = false

  public loading = true
  public multipliers: SearchResultModel[] = []

  public reasonOptions = []

  constructor(
    private ref: DynamicDialogRef,
    private patientService: PatientService,
    private searchService: SearchService,
    private dialogService: DialogService,
    private config: DynamicDialogConfig,
    public documentService: DocumentService,
    private accountingService: AccountingReturnService,
    public invoiceService: InvoiceService,
    private eventbus: EventBusService,
    private toastService: ToastService,
    private authService: AuthService,
    private helperService: HelperService,
    private confirmationService: ConfirmationService
  ) {}

  ngOnInit(): void {
    const invoice = this.config.data.invoice

    if (invoice) {
      this.invoiceType = invoice.type

      if (this.invoiceType === 'KM') {
        this.patient = invoice.invoiceable
      } else if (this.invoiceType === 'CONSULTING') {
        this.patient = invoice.invoiceable.patient
      }

      this.loadReceivers()
    } else if (this.config.data.patient) {
      this.type = this.config.data.type
      this.patient = this.config.data.patient
      this.withPositions = this.config.data.with_positions

      this.isEdit = true
    } else {
      this.isEdit = false
    }

    this.helperService.dependencies$.subscribe((data: any) => {
      this.monthOptions = data.months
      this.reasonOptions = data.accounting_return_reasons
    })

    this.formSubscription = this.form.valueChanges?.subscribe(() => {
      if (!this.form.pristine) {
        this.isDirty = true
      }
    })

    this.currentUser = this.authService.getUser()

    // this.calculate()
  }

  public getAppointmentTypes(): { types: string; comment: string } {
    return {
      types: this.patient.invoice_types_string || 'Keine Angabe',
      comment: this.patient.invoice_type_tooltip || '',
    }
  }

  ngOnDestroy(): void {
    this.formSubscription?.unsubscribe()
  }

  public confirmSendLetter(event: any): void {
    // this.confirmationService.confirm({
    //   target: event.target,
    //   message: 'Mit Bearbeitungsgebühr?',
    //   accept: () => {
    //     this.openSendLetterDialog()
    //   },
    //   reject: () => {
    //     this.openSendLetterDialog(true)
    //   },
    // })
  }

  public addPosition(): void {
    this.values.positions.push({
      amount: '',
      text: '',
      unit_price: null,
      unit_price_euro: null,
      unit_price_euro_formatted: null,
      total_price: 0,
      total_price_euro: 0.0,
      total_price_euro_formatted: '0,00 €',
    })
  }

  public patientSelected(event: any): void {
    this.patient = event

    this.loadReceivers()

    const invoiceType = this.patient.invoice_types_string

    if (!this.values.sent_type) {
      if (invoiceType.includes('E-Mail')) {
        this.values.sent_type = 'email'
      } else if (invoiceType.includes('Post')) {
        this.values.sent_type = 'post'
      }
    }
  }

  public loadReceivers(): void {
    this.patientService
      .getReceivers(this.patient.id)
      .subscribe((response: any) => {
        this.buildReceiverOptions(response)
        this.buildCCOptions(response)

        this.isEdit = true

        this.values = this.config.data.invoice

        // Wenn kein "Versand an" ausgewählt ist.
        if (!this.values.sent_for) {
          if (
            this.invoiceType === 'CONSULTING' &&
            (this.patient.is_gkv_only || this.patient.is_gkv_selbstzahler_only)
          ) {
            this.values.sent_for = 'INSURER'
          } else {
            this.values.sent_for = 'PATIENT'
          }
        }

        this.checkForFrozenStatus()

        const invoiceType = this.patient.invoice_types_string

        if (!this.values.sent_type) {
          if (invoiceType.includes('E-Mail')) {
            this.values.sent_type = 'email'
          } else if (invoiceType.includes('Post')) {
            this.values.sent_type = 'post'
          }
        }

        this.loading = false
      })
  }

  public searchMultipliers(event: any): void {
    this.searchService
      .findMultipliers(event.query.trim())
      .subscribe((results: SearchResultModel[]) => {
        this.multipliers = results
      })
  }

  /**
   * Kalkuliert den Preis für ein Item neu.
   */
  calculateItem(position: any) {
    position.unit_price_euro_formatted = currency(
      position.unit_price_euro,
      this.currencyOptions
    ).format()
    position.total_price_euro_formatted = currency(
      position.unit_price_euro,
      this.currencyOptions
    )
      .multiply(position.amount)
      .format()

    this.calculate()
  }

  /**
   * Stößt die Kalkulation für die Summen an.
   */
  calculate() {
    this.calculateSubtotal()
    this.calculateTax()
    this.calculateTotal()
  }

  /**
   * Kalkulation für die Zwischensumme.
   */
  calculateSubtotal() {
    this.values.subtotal_price_euro_formatted = ''

    this.values.positions.map((item: any) => {
      this.values.subtotal_price_euro_formatted = currency(
        this.values.subtotal_price_euro_formatted,
        this.currencyOptions
      )
        .add(item.total_price_euro_formatted)
        .format()
    })
  }

  /**
   * Kalkulation für die Umsatzsteuer, nur wenn keine Provisionsrechnung.
   */
  calculateTax() {
    let tax = currency(this.values.subtotal_price_euro_formatted, {
      ...this.currencyOptions,
      precision: 4,
    }).multiply(`0.${this.values.vat}`).value

    // Nachkommastellen hochrunden
    tax = tax > 0 ? ceil(tax, 2) : floor(tax, 2)

    this.values.vat_price_euro_formatted = currency(
      tax,
      this.currencyOptions
    ).format()
  }

  /**
   * Kalkulation für die gesammte Summe.
   */
  calculateTotal() {
    this.values.total_price_euro_formatted = currency(
      this.values.subtotal_price_euro_formatted,
      this.currencyOptions
    )
      .add(this.values.vat_price_euro_formatted)
      .format()
  }

  removePosition(position: any): void {
    this.values.positions = this.values.positions.filter(
      (item: any) => item != position
    )

    this.calculate()
  }

  public searchPatients(event: any): void {
    this.searchService
      .findPatients(event.query.trim())
      .subscribe((results: SearchResultModel[]) => {
        this.patients = results
      })
  }

  public stornoInvoice(): void {
    const ref = this.dialogService.open(ConfirmWithTextDialogComponent, {
      header: 'Rechnung wirklich stornieren?',
      width: '450px',
      styleClass: 'dialog-container',
      data: {
        isRequired: true,
      },
    })

    ref.onClose.subscribe((values: any) => {
      if (values) {
        this.submittedDelete = true
        this.submitted = true

        this.invoiceService
          .stornoInvoice(this.values.id, values.storno_comment)
          .subscribe(
            (response: any) => {
              this.eventbus.emit(GlobalEvent.InvoiceChanged)
              this.ref.close()

              this.toastService.success(
                'Rechnung storniert',
                'Die Rechnung wurde erfolgreich storniert'
              )
            },
            () => {
              this.toastService.error(
                'Etwas ist schiefgelaufen...',
                'Bitte wenden Sie sich an den Support'
              )

              this.submittedDelete = false
            }
          )
      }
    })
  }

  public resetReadyInvoice(): void {
    if (window.confirm('Rechnung wieder zum Entwurf zurücksetzen?')) {
      this.submitted = true

      this.invoiceService.resetReadyInvoice(this.values.id).subscribe(
        () => {
          this.submitted = false
          this.eventbus.emit(GlobalEvent.InvoiceChanged)
          this.ref.close()
          this.toastService.success(
            'Rechnung zurückgesetzt',
            'Die Rechnung wurde erfolgreich zurückgesetzt'
          )
        },
        (error: HttpErrorResponse) => {
          if (error.status === StatusCodes.UNPROCESSABLE_ENTITY) {
            this.toastService.error('Bitte füllen Sie alle Pflichtfelder aus')
          } else {
            this.toastService.error(
              'Etwas ist schief gelaufen...',
              'Bitte wenden Sie sich an den Support'
            )
          }
          this.submitted = false
        }
      )
    }
  }

  public deleteReadyInvoice(): void {
    if (window.confirm('Entwurf komplett löschen?')) {
      this.submitted = true

      this.invoiceService.deleteReadyInvoice(this.values.id).subscribe(
        () => {
          this.submitted = false
          this.eventbus.emit(GlobalEvent.InvoiceChanged)
          this.ref.close()
          this.toastService.success(
            'Rechnung gelöscht',
            'Die Rechnung wurde erfolgreich gelöscht'
          )
        },
        (error: HttpErrorResponse) => {
          if (error.status === StatusCodes.UNPROCESSABLE_ENTITY) {
            this.toastService.error('Bitte füllen Sie alle Pflichtfelder aus')
          } else {
            this.toastService.error(
              'Etwas ist schief gelaufen...',
              'Bitte wenden Sie sich an den Support'
            )
          }
          this.submitted = false
        }
      )
    }
  }

  public editPayment(payment: any): void {
    this.partialPaymentEditId = payment.id
    this.partialPayment = payment.payment_euro
  }

  public addPayment(): void {
    if (!this.partialPayment) {
      return
    }

    this.submitted = true

    // Formatiert die Zahl in einen €-String
    const partialPaymentFormatted = currency(
      this.partialPayment,
      this.currencyOptions
    ).format()

    const subscription = this.partialPaymentEditId
      ? this.invoiceService.updatePayment(
          this.partialPaymentEditId,
          partialPaymentFormatted
        )
      : this.invoiceService.addPayment(this.values.id, partialPaymentFormatted)

    subscription.subscribe(
      (invoice: any) => {
        this.values.part_payments = invoice.part_payments
        this.values.part_payments_sum = invoice.part_payments_sum
        this.values.part_payments_sum_euro_formatted =
          invoice.part_payments_sum_euro_formatted

        this.partialPayment = ''
        this.partialPaymentEditId = null

        this.submitted = false
        this.eventbus.emit(GlobalEvent.InvoiceChanged)
        // this.ref.close()
        this.toastService.success('Teilzahlung hinzugefügt')
      },
      (error: HttpErrorResponse) => {
        if (error.status === StatusCodes.UNPROCESSABLE_ENTITY) {
          this.toastService.error('Bitte füllen Sie alle Pflichtfelder aus')
        } else {
          this.toastService.error(
            'Etwas ist schief gelaufen...',
            'Bitte wenden Sie sich an den Support'
          )
        }
        this.submitted = false
      }
    )
  }

  public getPdfPreviewLink(): string {
    return this.invoiceService.getPdfPreviewLink({
      id: this.values.id,
      sent_for: this.values.sent_for,
      sent_for_id: this.values.multiplier_sent_for?.id,
      address: this.values.address,
      receiver: this.values.receiver,
    })
  }

  public getMailPreviewLink(): string {
    return this.invoiceService.getMailPreviewLink({
      id: this.values.id,
      sent_for: this.values.sent_for,
      sent_for_id: this.values.multiplier_sent_for?.id,
      address: this.values.address,
      receiver: this.values.receiver,
    })
  }

  public markInvoiceAsCompleted(): void {
    if (!this.form.form.valid) {
      this.submitted = false
      this.form.form.markAllAsTouched()
      return
    }

    if (!window.confirm('Rechnung wirklich abschließen?')) {
      return
    }

    this.submitted = true

    this.invoiceService.markInvoiceAsCompleted(this.values.id).subscribe(
      (invoice: any) => {
        this.values = invoice

        this.submitted = false
        this.eventbus.emit(GlobalEvent.InvoiceChanged)
        // this.ref.close()
        this.toastService.success(
          'Rechnung abgeschlossen',
          'Die Rechnung wurde erfolgreich abgeschlossen'
        )
      },
      (error: HttpErrorResponse) => {
        if (error.status === StatusCodes.UNPROCESSABLE_ENTITY) {
          this.toastService.error('Bitte füllen Sie alle Pflichtfelder aus')
        } else {
          this.toastService.error(
            'Etwas ist schief gelaufen...',
            'Bitte wenden Sie sich an den Support'
          )
        }
        this.submitted = false
      }
    )
  }

  public store(): void {
    if (!this.form.form.valid) {
      this.submitted = false
      this.form.form.markAllAsTouched()
      return
    }

    this.submitted = true

    const subscription = this.isEdit
      ? this.invoiceService.update(this.values.id, this.values)
      : this.invoiceService.update(this.values.id, this.values)

    subscription.subscribe(
      (invoice: any) => {
        this.values = invoice

        this.submitted = false
        this.eventbus.emit(GlobalEvent.InvoiceChanged)
        // this.ref.close()
        this.toastService.success(
          'Rechnung gespeichert',
          'Die Rechnung wurde erfolgreich gespeichert'
        )
      },
      (error: HttpErrorResponse) => {
        if (error.status === StatusCodes.UNPROCESSABLE_ENTITY) {
          this.toastService.error('Bitte füllen Sie alle Pflichtfelder aus')
        } else {
          this.toastService.error(
            'Etwas ist schief gelaufen...',
            'Bitte wenden Sie sich an den Support'
          )
        }
        this.submitted = false
      }
    )
  }

  public createInvoice(): void {
    if (!this.form.form.valid) {
      this.submitted = false
      this.form.form.markAllAsTouched()
      return
    }

    if (
      !this.clickedOnPreview ||
      (this.values.sent_type === 'email' && !this.clickedOnPreviewMail)
    ) {
      alert('Bitte PDF und Mail Vorschau ansehen')

      return
    }

    this.submitted = true

    this.invoiceService.createInvoice(this.values.id, this.values).subscribe(
      (invoice: any) => {
        // TODO: was mit invoice.uuid machen
        this.values = invoice.invoice

        this.checkForFrozenStatus()

        this.submitted = false
        this.eventbus.emit(GlobalEvent.InvoiceChanged)
        // this.ref.close()
        this.toastService.success(
          'Rechnung erstellt',
          'Die Rechnung wurde erfolgreich erstellt'
        )
      },
      (error: HttpErrorResponse) => {
        if (error.status === StatusCodes.UNPROCESSABLE_ENTITY) {
          this.toastService.error('Bitte füllen Sie alle Pflichtfelder aus')
        } else {
          this.toastService.error(
            'Etwas ist schief gelaufen...',
            'Bitte wenden Sie sich an den Support'
          )
        }
        this.submitted = false
      }
    )
  }

  public findReceiver(receiver: string): string {
    const found = this.receiverOptions.find((r: any) => r.value === receiver)

    if (found) {
      return found.label
    }

    return 'Unbekannt'
  }

  public findCCReceivers(receivers: []): string {
    const data: string[] = []

    receivers.forEach((r: any) => {
      const found = this.ccOptions.find((c: any) => c.value === r)

      if (found) {
        data.push(found.label)
      }
    })

    return data.join(', ')
  }

  public buildReceiverOptions(response: any): void {
    this.receiverOptions = []
    this.values.receiver = null

    this.receiverOptions.push({
      value: null,
      label: 'Bitte auswählen',
      email: '',
      disabled: true,
    })

    this.receiverOptions.push({
      value: `patient_${response.first_patient.id}_`,
      email: response.first_patient.email || 'Keine E-Mail',
      label: `${response.first_patient.full_name} - Patient`,
      disabled:
        this.values.sent_type === 'email' && !response.first_patient.email,
    })

    if (response.second_patient) {
      this.receiverOptions.push({
        value: `patient_${response.second_patient.id}_`,
        email: response.second_patient.email || 'Keine E-Mail',
        label: `${response.second_patient.full_name} - Patient`,
        disabled:
          this.values.sent_type === 'email' && !response.second_patient.email,
      })
    }

    for (const contactPerson of response.contact_persons) {
      if (contactPerson.email_private) {
        this.receiverOptions.push({
          value: `ap_${contactPerson.id}_private`,
          email: `Private E-Mail: ${contactPerson.email_private}`,
          label: `${contactPerson.full_name} - AP`,
          disabled: false,
        })

        // Bei Postversand soll der AP soll nur einmal in der Liste angezeigt
        // werden, selbst wenn er noch eine Geschäftliche E-Mail Adresse hat.
        if (this.values.sent_type === 'post') {
          continue
        }
      }

      if (contactPerson.email_work) {
        this.receiverOptions.push({
          value: `ap_${contactPerson.id}_work`,
          email: `Geschäftliche E-Mail: ${contactPerson.email_work}`,
          label: `${contactPerson.full_name} - AP`,
          disabled: false,
        })
      }

      // Wenn gar keine E-Mail hinterlegt ist.
      if (!contactPerson.email_private && !contactPerson.email_work) {
        this.receiverOptions.push({
          value: `ap_${contactPerson.id}_`,
          email: 'Keine E-Mail',
          label: `${contactPerson.full_name} - AP`,
          disabled: this.values.sent_type === 'email',
        })
      }
    }
  }

  public buildCCOptions(response: any): void {
    this.ccOptions = []

    this.ccOptions.push({
      value: response.first_patient.email,
      email: response.first_patient.email || 'Keine E-Mail',
      label: `${response.first_patient.full_name} - Patient`,
      disabled: !response.first_patient.email,
    })

    if (response.second_patient) {
      this.ccOptions.push({
        value: response.second_patient.email,
        email: response.second_patient.email || 'Keine E-Mail',
        label: `${response.second_patient.full_name} - Patient`,
        disabled: !response.second_patient.email,
      })
    }

    for (const contactPerson of response.contact_persons) {
      if (contactPerson.email_private) {
        this.ccOptions.push({
          value: contactPerson.email_private,
          email: `Private E-Mail: ${contactPerson.email_private}`,
          label: `${contactPerson.full_name} - AP - Private E-Mail`,
          disabled: false,
        })
      }

      if (contactPerson.email_work) {
        this.ccOptions.push({
          value: contactPerson.email_work,
          email: `Geschäftliche E-Mail: ${contactPerson.email_work}`,
          label: `${contactPerson.full_name} - AP - Geschäftliche E-Mail`,
          disabled: false,
        })
      }

      // Wenn gar keine E-Mail hinterlegt ist.
      if (!contactPerson.email_private && !contactPerson.email_work) {
        this.ccOptions.push({
          value: null,
          email: 'Keine E-Mail',
          label: `${contactPerson.full_name} - AP - Keine E-Mail`,
          disabled: true,
        })
      }
    }
  }

  public close(): void {
    if (this.historyComment) {
      alert('Bitte Historyeintrag speichern oder entfernen')
      return
    }

    this.ref.close()
  }

  public remove(event: any): void {
    // this.confirmationService.confirm({
    //   target: event.target ?? undefined,
    //   message: 'Rückläufer wirklich löschen?',
    //   accept: () => {
    //     this.submittedDelete = true
    //
    //     this.accountingService.remove(this.values.id).subscribe(
    //       () => {
    //         this.submittedDelete = false
    //         this.eventbus.emit(GlobalEvent.AccountingReturnChanged)
    //         this.ref.close()
    //         this.toastService.success(
    //           'Rückläufer gelöscht',
    //           'Der Rückläufer wurde erfolgreich gelöscht'
    //         )
    //       },
    //       () => {
    //         this.submittedDelete = false
    //         this.toastService.error(
    //           'Löschen fehlgeschlagen',
    //           'Der Rückläufer konnte nicht gelöscht werden'
    //         )
    //       }
    //     )
    //   },
    //   reject: () => {},
    // })
  }

  private checkForFrozenStatus() {
    this.isFrozen = ['OPENED', 'FINISHED'].includes(this.values.status)
  }
}
