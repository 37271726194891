import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core'
import { CaregiverService } from '../../../../services/caregivers.service'
import { ActivatedRoute, ParamMap } from '@angular/router'
import { DialogService } from 'primeng/dynamicdialog'
import {
  EventBusService,
  GlobalEvent,
} from '../../../../services/eventbus.service'
import { Subscription } from 'rxjs'
import { AddVetoDialogComponent } from '../../../../components/dialogs/add-veto-dialog/add-veto-dialog.component'
import { VetoModel } from '../../../../models/veto/veto.model'

@Component({
  selector: 'app-caregivers-detail-veto',
  templateUrl: './caregivers-detail-veto.component.html',
  providers: [DialogService],
})
export class CaregiversDetailVetoComponent implements OnInit, OnDestroy {
  public loading = true
  @ViewChild('dt') table: ElementRef | any = null

  public vetos: VetoModel[] = []
  public caregiverId: string | null = null
  public globalFilterValue = ''

  private routeSubscription: any = null
  private eventBusSubscription: Subscription = new Subscription()

  constructor(
    public caregiverService: CaregiverService,
    private route: ActivatedRoute,
    private eventbus: EventBusService,
    private dialogService: DialogService
  ) {}

  ngOnInit(): void {
    this.loadVetos()
    this.listenForEventbus()
  }

  ngOnDestroy(): void {
    this.eventBusSubscription.unsubscribe()
    this.routeSubscription && this.routeSubscription.unsubscribe()
  }

  private listenForEventbus(): void {
    this.eventBusSubscription = this.eventbus.subject.subscribe(
      (event: GlobalEvent) => {
        switch (event) {
          case GlobalEvent.VetoChanged:
            this.loadVetos(false)
        }
      }
    )
  }

  public filterTableGlobal(event: any): void {
    this.table && this.table.filterGlobal(event.target.value.trim(), 'contains')
  }

  public openVetoDialog(veto: VetoModel | null = null): void {
    this.dialogService.open(AddVetoDialogComponent, {
      header: veto ? 'Veto bearbeiten' : 'Neues Veto',
      width: '450px',
      styleClass: 'dialog-container',
      data: {
        isEdit: veto !== null,
        veto: { ...veto },
        type: 'caregiver',
        caregiver_id: this.caregiverId,
      },
    })
  }

  public loadVetos(withLoading: boolean = true): void {
    if (withLoading) {
      this.loading = true
    }

    if (this.route.parent) {
      this.routeSubscription = this.route.parent.paramMap.subscribe(
        (params: ParamMap) => {
          this.caregiverId = params.get('id')

          this.caregiverService
            .vetos(this.caregiverId)
            .subscribe((data: VetoModel[]) => {
              this.vetos = data

              if (withLoading) {
                this.loading = false
              }
            })
        }
      )
    }
  }
}
