import { Component, OnDestroy, OnInit } from '@angular/core'
import { ActivatedRoute, ParamMap, Router } from '@angular/router'
import { HistoryService } from '../../../../services/history.service'
import { LatestChangeModel } from '../../../../models/history/latest-change.model'
import { MultiplierService } from '../../../../services/multiplier.service'
import { MultiplierDetailGeneralModel } from '../../../../models/multiplier/multiplier-detail-general.model'
import { NoticeDialogComponent } from '../../../../components/dialogs/notice-dialog/notice-dialog.component'
import { DialogService } from 'primeng/dynamicdialog'
import { ContactPersonDialogComponent } from '../../../../components/dialogs/contact-person-dialog/contact-person-dialog.component'
import { ContactPersonModel } from '../../../../models/contact-person/contact-person.model'
import {
  EventBusService,
  GlobalEvent,
} from '../../../../services/eventbus.service'
import { Subscription } from 'rxjs'
import { MenuItem } from 'primeng/api'
import { PhoneCallService } from '../../../../services/phone-call.service'
import { AccountMultiplierDialogComponent } from '../../../../components/dialogs/account-multiplier-dialog/account-multiplier-dialog.component'
import { AuthService } from '../../../../services/auth.service'

@Component({
  selector: 'app-multipliers-detail-general',
  templateUrl: './multipliers-detail-general.component.html',
})
export class MultipliersDetailGeneralComponent implements OnInit, OnDestroy {
  public loading = true
  public multiplier: MultiplierDetailGeneralModel = new MultiplierDetailGeneralModel()
  public latestChanges: LatestChangeModel = new LatestChangeModel()
  private eventBusSubscription: Subscription = new Subscription()

  public openedContactPersons: { [index: number]: boolean } = {}
  public settingMenuItems: MenuItem[] = []

  constructor(
    private multiplierService: MultiplierService,
    private route: ActivatedRoute,
    public phoneCallService: PhoneCallService,
    private router: Router,
    public authService: AuthService,
    private eventbus: EventBusService,
    private dialogService: DialogService,
    private historyService: HistoryService
  ) {}

  ngOnInit(): void {
    this.loadMultiplier()
    this.listenForEventbus()
  }

  public loginUsingId(): void {
    this.authService.loginUsingId(this.multiplier.user_id).subscribe(() => {
      this.authService.setImpersonation()

      window.location.href = '/'
    })
  }

  public openAccountDialog(): void {
    this.dialogService.open(AccountMultiplierDialogComponent, {
      header: 'Account',
      width: '760px',
      styleClass: 'dialog-container',
      data: {
        multiplier: { ...this.multiplier },
      },
    })
  }

  ngOnDestroy(): void {
    this.eventBusSubscription.unsubscribe()
  }

  public getLatestChange(key: string, value: any): void {
    if (this.latestChanges[key]) {
      return
    }

    const id = this.multiplier.id ? this.multiplier.id.toString() : ''
    value = value ? value.toString() : ''

    this.historyService
      .getLatestChange(id, 'multiplier', key, value)
      .subscribe((date: string) => {
        this.latestChanges[key] = date
      })
  }

  public loadMultiplier(): void {
    this.loading = true

    this.route.paramMap.subscribe((params: ParamMap) => {
      this.multiplierService
        .load(params.get('id'))
        .subscribe((data: MultiplierDetailGeneralModel) => {
          this.multiplier = data

          this.setSettingOptions()

          this.loading = false
        })
    })
  }

  public openNoticeDialog(): void {
    this.dialogService.open(NoticeDialogComponent, {
      header: 'Notiz bearbeiten',
      width: '450px',
      styleClass: 'dialog-container',
      data: {
        type: 'multiplier',
        type_id: this.multiplier.id,
        notice: this.multiplier.notice,
      },
    })
  }

  public openContactPersonDialog(
    contactPerson: ContactPersonModel | null = null
  ): void {
    this.dialogService.open(ContactPersonDialogComponent, {
      header: contactPerson
        ? 'Ansprechpartner bearbeiten'
        : 'Neuer Ansprechpartner',
      width: '1000px',
      styleClass: 'dialog-container',
      data: {
        type: 'multiplier',
        edit: contactPerson !== null,
        type_id: this.multiplier.id,
        contactPerson: { ...contactPerson },
      },
    })
  }

  private listenForEventbus(): void {
    this.eventBusSubscription = this.eventbus.subject.subscribe((event) => {
      switch (event) {
        case GlobalEvent.ContactPersonChanged:
        case GlobalEvent.MultiplierStatusChanged:
          this.loadMultiplier()
          break
      }
    })
  }

  private setSettingOptions(): void {
    this.settingMenuItems = []

    this.settingMenuItems.push({
      label: 'Notiz',
      icon: 'pi pi-align-left',
      command: () => this.openNoticeDialog(),
    })

    if (this.authService.can('Multiplikator.Multiplikator Account verwalten')) {
      this.settingMenuItems.push({
        separator: true,
      })

      this.settingMenuItems.push({
        label: 'Account',
        icon: 'pi pi-user',
        command: () => this.openAccountDialog(),
      })
    }

    if (this.multiplier.user_id && this.authService.isSuperAdmin()) {
      this.settingMenuItems.push({
        label: 'Als Benutzer einloggen',
        icon: 'pi pi-lock',
        command: () => this.loginUsingId(),
      })
    }
  }
}
