<app-loader [loading]="loading"></app-loader>

<div class="content" *ngIf="!loading">
  <header class="content-header">
    <span>
      <i class="pi pi-euro"></i>
      Alle Faktoren
    </span>

    <button
      pButton
      (click)="openCarCostDialog()"
      label="Neuer Faktor"
      type="button"
      class="p-button-outlined"
      icon="pi pi-plus"
      iconPos="left"
    ></button>
  </header>
</div>

<div class="content" style="margin: 0" *ngIf="!loading">
  <div class="content-body auto-height" *ngIf="costs.length === 0">
    <span class="content-no-message">Keine Faktoren vorhanden</span>
  </div>
  <div
    class="content-body without-padding auto-height"
    *ngIf="costs.length > 0"
  >
    <p-table #dt [value]="costs" [rowHover]="true">
      <ng-template pTemplate="header">
        <tr class="table-headline-highlight">
          <th style="width: 200px" pSortableColumn="name">
            Name <p-sortIcon field="name"></p-sortIcon>
          </th>
          <th style="width: 200px" pSortableColumn="cost">
            Kosten <p-sortIcon field="cost"></p-sortIcon>
          </th>
          <th style="width: 200px" pSortableColumn="from">
            von <p-sortIcon field="from"></p-sortIcon>
          </th>
          <th style="width: 200px" pSortableColumn="to">
            bis <p-sortIcon field="to"></p-sortIcon>
          </th>
          <th>Bemerkung</th>
          <th style="width: 100px"></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-cost>
        <tr>
          <td>
            {{ cost.name }}
          </td>
          <td>
            {{ cost.cost_formatted }}
          </td>
          <td>
            {{ cost.from_string || "-" }}
          </td>
          <td>
            {{ cost.to_string || "-" }}
          </td>
          <td>
            {{ cost.comment || "-" }}
          </td>
          <td class="table-action" style="width: 100px">
            <i
              (click)="openCarCostDialog(cost)"
              pTooltip="Bearbeiten"
              tooltipPosition="left"
              class="pi pi-pencil with-margin-right"
            ></i>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
