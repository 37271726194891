import { HttpErrorResponse } from '@angular/common/http'
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core'
import { StatusCodes } from 'http-status-codes'
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog'
import { EventBusService, GlobalEvent } from 'src/app/services/eventbus.service'
import { ToastService } from 'src/app/services/toast.service'
import { NgForm } from '@angular/forms'
import { Subscription } from 'rxjs'
import * as dayjs from 'dayjs'
import { CarsDetailModel } from '../../../models/car/cars-detail.model'
import { CaregiverDependenciesModel } from '../../../models/caregiver/caregiver-dependencies.model'
import { CaregiverService } from '../../../services/caregivers.service'
import { CarService } from '../../../services/car.service'
import { CaregiverCarModel } from '../../../models/car/caregiver-car.model'
import { ConfirmationService } from 'primeng/api'
import { CaregiverDetailModel } from '../../../models/caregiver/caregiver-detail.model'
import { HelperService } from '../../../services/helper.service'
import { UserSystemSimpleModel } from '../../../models/user/user-system.model'
import { UserSystemService } from '../../../services/user-system.service'

@Component({
  selector: 'app-add-caregiver-car-dialog',
  templateUrl: './add-caregiver-car-dialog.component.html',
})
export class AddCaregiverCarDialogComponent implements OnInit, OnDestroy {
  @ViewChild('form', { static: true }) form!: NgForm

  private formSubscription: Subscription | null | undefined = null
  private isDirty = false

  cars: CarsDetailModel[] = []
  caregivers: CaregiverDetailModel[] = []
  public userSystemOptions: any[] = []

  public hasCurrentCar = false
  public data: any = {}
  public values: CaregiverCarModel = new CaregiverCarModel()

  public submitted = false
  public submittedDelete = false

  constructor(
    private ref: DynamicDialogRef,
    private helperService: HelperService,
    private userSystemService: UserSystemService,
    private caregiverService: CaregiverService,
    private carService: CarService,
    private config: DynamicDialogConfig,
    private eventbus: EventBusService,
    private toastService: ToastService,
    private confirmationService: ConfirmationService
  ) {}

  ngOnInit(): void {
    this.data = this.config.data

    if (this.data.from_type === 'caregiver') {
      this.loadCars()
    } else {
      this.loadCaregivers()
    }

    this.loadAllUserSystem()

    this.formSubscription = this.form.valueChanges?.subscribe(() => {
      if (!this.form.pristine) {
        this.isDirty = true
      }
    })

    if (this.data.isEdit) {
      this.values = this.data.car

      this.values.since = dayjs(this.data.car.since).format('DD.MM.YYYY')
      if (this.data.car.until) {
        this.values.until = dayjs(this.data.car.until).format('DD.MM.YYYY')
      }
    } else {
      if (this.data.from_type === 'caregiver') {
        this.values.caregiver_id = this.data.caregiver.id
        this.hasCurrentCar = this.data.caregiver.current_car_without_until_date
      } else {
        this.values.car_id = this.data.car.id
      }
    }
  }

  ngOnDestroy(): void {
    this.formSubscription?.unsubscribe()
  }

  private loadCars(): void {
    this.caregiverService
      .dependencies()
      .subscribe((dependencies: CaregiverDependenciesModel) => {
        this.cars = dependencies.cars
      })
  }

  private loadAllUserSystem(): void {
    this.userSystemService
      .listActiveSimple()
      .subscribe((data: UserSystemSimpleModel[]) => {
        for (let userSystem of data) {
          this.userSystemOptions.push({
            id: userSystem.id,
            full_name: userSystem.full_name.trim(),
          })
        }
      })
  }

  private loadCaregivers(): void {
    this.helperService.dependencies$.subscribe((data: any) => {
      this.caregivers.push(...data['caregivers_active'])
      this.caregivers.push(...data['caregivers_deactivated'])
    })
  }

  public store(): void {
    if (!this.form.form.valid) {
      this.submitted = false
      this.form.form.markAllAsTouched()
      return
    }

    this.submitted = true

    const subscription = this.data.isEdit
      ? this.carService.updateForCaregiver(this.values)
      : this.carService.storeForCaregiver(this.values, this.data.from_type)

    subscription.subscribe(
      () => {
        this.submitted = false
        this.eventbus.emit(GlobalEvent.CaregiverDetailReload)
        this.eventbus.emit(GlobalEvent.CarDetailReload)
        this.ref.close()
        this.toastService.success(
          'PKW gespeichert',
          'PKW wurde erfolgreich gespeichert'
        )
      },
      (error: HttpErrorResponse) => {
        if (error.status === StatusCodes.UNPROCESSABLE_ENTITY) {
          this.toastService.error('Bitte füllen Sie alle Pflichtfelder aus')
        } else {
          this.toastService.error(
            'Etwas ist schief gelaufen...',
            'Bitte wenden Sie sich an den Support'
          )
        }
        this.submitted = false
      }
    )
  }

  public remove(event: any): void {
    this.confirmationService.confirm({
      target: event.target ?? undefined,
      message: 'PKW wirklich löschen?',
      accept: () => {
        this.submittedDelete = true

        this.carService.deleteForCaregiver(this.data.car.id).subscribe(
          () => {
            this.submittedDelete = false
            this.eventbus.emit(GlobalEvent.CaregiverDetailReload)
            this.eventbus.emit(GlobalEvent.CarDetailReload)
            this.ref.close()
            this.toastService.success(
              'PKW gelöscht',
              'PKW wurde erfolgreich gelöscht'
            )
          },
          () => {
            this.submittedDelete = false
            this.toastService.error(
              'Löschen fehlgeschlagen',
              'Der Eintrag konnte nicht gelöscht werden'
            )
          }
        )
      },
      reject: () => {},
    })
  }
}
