<form #form="ngForm" class="dialog-form">
  <div class="dialog-sidebar" *ngIf="values.sending_type === 'DLV'">
    <div class="p-field">
      <label for="text">Schlusstext</label>
      <textarea
        id="text"
        name="text"
        [disabled]="!values.receiver"
        [(ngModel)]="values.text_end_dlv_anschreiben"
        placeholder="Bitte Empfänger auswählen"
        pInputTextarea
        [rows]="8"
      ></textarea>
    </div>
  </div>

  <div class="dialog-form-body">
    <!--    <div class="detail-information-banner" *ngIf="!hasPersplanId()">-->
    <!--      <span>Bitte zuerst eine Persplan ID hinterlegen!</span>-->
    <!--    </div>-->

    <!-- Angebot oder DLV -->
    <div class="p-field">
      <label for="text">Versand *</label>
      <div class="p-formgroup-inline">
        <div class="p-field-radiobutton">
          <p-radioButton
            label="Nur Angebot"
            name="sending_type"
            [(ngModel)]="values.sending_type"
            value="OFFER"
            [required]="true"
            #sending_type="ngModel"
          ></p-radioButton>
        </div>
        <div class="p-field-radiobutton">
          <p-radioButton
            label="Angebot mit Verträgen"
            name="sending_type"
            [(ngModel)]="values.sending_type"
            value="DLV"
            [required]="true"
            #sending_type="ngModel"
          ></p-radioButton>
        </div>
      </div>
      <div
        *ngIf="
          sending_type.invalid && (sending_type.dirty || sending_type.touched)
        "
        class="p-invalid"
      >
        <div *ngIf="sending_type.errors?.required">
          <small>Bitte wählen Sie einen Versand</small>
        </div>
      </div>
    </div>

    <!-- Typ -->
    <div class="p-field">
      <label>Versand per *</label>
      <div class="p-formgroup-inline">
        <div class="p-field-radiobutton">
          <p-radioButton
            (onClick)="buildReceiverOptions()"
            label="E-Mail"
            name="type"
            [(ngModel)]="values.type"
            value="email"
          ></p-radioButton>
        </div>
        <div class="p-field-radiobutton">
          <p-radioButton
            (onClick)="buildReceiverOptions()"
            label="Post"
            name="type"
            [(ngModel)]="values.type"
            value="post"
          ></p-radioButton>
        </div>
      </div>
    </div>

    <!-- Patient -->
    <div class="p-field">
      <label for="patient">Patient *</label>
      <p-dropdown
        inputId="patient"
        name="patient"
        placeholder="Bitte auswählen"
        [options]="patientOptions"
        [(ngModel)]="values.patient_id"
        optionLabel="full_name"
        optionValue="id"
        [required]="true"
        #patient="ngModel"
        [class.p-invalid]="
          patient.invalid && (patient.dirty || patient.touched)
        "
      ></p-dropdown>
    </div>

    <!-- Empfänger -->
    <div class="p-field">
      <label for="receiver">Empfänger *</label>
      <p-dropdown
        inputId="receiver"
        name="receiver"
        (onChange)="receiverChanged($event)"
        [options]="receiverOptions"
        [(ngModel)]="values.receiver"
        appendTo="body"
        scrollHeight="400px"
        [required]="true"
        #receiver="ngModel"
        [class.p-invalid]="
          receiver.invalid && (receiver.dirty || receiver.touched)
        "
      >
        <ng-template let-receiver pTemplate="item">
          <div>{{ receiver.label }}</div>
          <span *ngIf="values.type === 'email'">{{ receiver.email }}</span>
        </ng-template>
      </p-dropdown>
    </div>

    <!-- CC an -->
    <div class="p-field" *ngIf="values.type === 'email'">
      <label for="cc">CC an</label>
      <p-multiSelect
        inputId="cc"
        placeholder="Optional"
        [options]="ccOptions"
        [filter]="false"
        [showHeader]="false"
        name="cc"
        appendTo="body"
        scrollHeight="400px"
        [(ngModel)]="values.cc"
      ></p-multiSelect>
    </div>

    <!-- Dokumenten Liste -->
    <div class="p-field">
      <label *ngIf="values.type === 'email'"
        >Dokumente die erstellt und versendet werden:</label
      >
      <label *ngIf="values.type === 'post'"
        >Dokumente die erstellt werden:</label
      >

      <!-- Meldung, wenn noch kien Typ für den Versand ausgewählt wurde -->
      <div class="dialog-list-container no-select" *ngIf="!values.sending_type">
        Bitte wählen Sie zuerst einen Versand!
      </div>

      <!-- Wenn "Nur Angebot" ausgewählt ist, muss nur das Angebot PDF erstellt werden -->
      <div
        class="dialog-list-container no-select"
        *ngIf="values.sending_type === 'OFFER'"
      >
        <span *ngIf="values.type === 'post'">
          <p-checkbox
            name="document-offer-anschreiben"
            [binary]="true"
            [disabled]="true"
            label="Angebot Anschreiben"
            [(ngModel)]="values.send_documents.offer_anschreiben"
          ></p-checkbox>
          <a
            *ngIf="
              documentUuids.offer_anschreiben_uuid && values.type === 'post'
            "
            target="_blank"
            [href]="
              documentService.getDocumentDownloadLink(
                documentUuids.offer_anschreiben_uuid
              )
            "
          >
            DOWNLOAD
          </a>
        </span>

        <span>
          <p-checkbox
            name="document-offer"
            [binary]="true"
            [disabled]="true"
            label="Angebot"
            [(ngModel)]="values.send_documents.offer"
          ></p-checkbox>
          <a
            *ngIf="documentUuids.offer_uuid && values.type === 'post'"
            target="_blank"
            [href]="
              documentService.getDocumentDownloadLink(documentUuids.offer_uuid)
            "
          >
            DOWNLOAD
          </a>
        </span>
      </div>

      <!-- Wenn "Angebot mit Verträgen" ausgewählt wurde, für GKV Patienten -->
      <div
        class="dialog-list-container no-select"
        *ngIf="isGKV() && values.sending_type === 'DLV'"
      >
        <span>
          <p-checkbox
            name="document-offer"
            [binary]="true"
            label="Angebot"
            [(ngModel)]="values.send_documents.offer"
          ></p-checkbox>
          <a
            *ngIf="documentUuids.offer_uuid && values.type === 'post'"
            target="_blank"
            [href]="
              documentService.getDocumentDownloadLink(documentUuids.offer_uuid)
            "
          >
            DOWNLOAD
          </a>
        </span>

        <span *ngIf="values.type === 'post'">
          <p-checkbox
            name="document-dlv"
            [binary]="true"
            label="DLV Anschreiben"
            [(ngModel)]="values.send_documents.dlv_anschreiben"
          ></p-checkbox>
          <a
            *ngIf="documentUuids.dlv_anschreiben_uuid && values.type === 'post'"
            target="_blank"
            [href]="
              documentService.getDocumentDownloadLink(
                documentUuids.dlv_anschreiben_uuid
              )
            "
          >
            DOWNLOAD
          </a>
        </span>
        <span>
          <p-checkbox
            name="document-dlv"
            [binary]="true"
            label="DLV"
            [(ngModel)]="values.send_documents.dlv"
          ></p-checkbox>
          <a
            *ngIf="documentUuids.dlv_uuid_1 && values.type === 'post'"
            target="_blank"
            [href]="
              documentService.getDocumentDownloadLink(documentUuids.dlv_uuid_1)
            "
          >
            DOWNLOAD 1
          </a>
          <a
            *ngIf="documentUuids.dlv_uuid_2 && values.type === 'post'"
            target="_blank"
            [href]="
              documentService.getDocumentDownloadLink(documentUuids.dlv_uuid_2)
            "
          >
            DOWNLOAD 2
          </a>
        </span>
        <span>
          <p-checkbox
            name="document-kundeninformation"
            [binary]="true"
            label="Kundeninformation"
            [(ngModel)]="values.send_documents.kundeninformation"
          ></p-checkbox>
          <a
            *ngIf="
              documentUuids.kundeninformation_uuid && values.type === 'post'
            "
            target="_blank"
            [href]="
              documentService.getDocumentDownloadLink(
                documentUuids.kundeninformation_uuid
              )
            "
          >
            DOWNLOAD
          </a>
        </span>
        <span>
          <p-checkbox
            name="document-kundenaufnahmebogen"
            [binary]="true"
            label="Kundenaufnahmebogen"
            [(ngModel)]="values.send_documents.kundenaufnahmebogen"
          ></p-checkbox>
          <a
            *ngIf="
              documentUuids.kundenaufnahmebogen_uuid && values.type === 'post'
            "
            target="_blank"
            [href]="
              documentService.getDocumentDownloadLink(
                documentUuids.kundenaufnahmebogen_uuid
              )
            "
          >
            DOWNLOAD
          </a>
        </span>
        <span>
          <p-checkbox
            name="document-entbindung"
            [binary]="true"
            label="Entbindung Schweigepflicht"
            [(ngModel)]="values.send_documents.entbindung"
          ></p-checkbox>
          <a
            *ngIf="documentUuids.entbindung_uuid_1 && values.type === 'post'"
            target="_blank"
            [href]="
              documentService.getDocumentDownloadLink(
                documentUuids.entbindung_uuid_1
              )
            "
          >
            DOWNLOAD 1
          </a>
          <a
            *ngIf="documentUuids.entbindung_uuid_2 && values.type === 'post'"
            target="_blank"
            [href]="
              documentService.getDocumentDownloadLink(
                documentUuids.entbindung_uuid_2
              )
            "
          >
            DOWNLOAD 2
          </a>
        </span>
        <span>
          <p-checkbox
            name="document-abtretung"
            [binary]="true"
            label="Abtretung"
            [(ngModel)]="values.send_documents.abtretung"
          ></p-checkbox>
          <a
            *ngIf="documentUuids.abtretung_uuid_1 && values.type === 'post'"
            target="_blank"
            [href]="
              documentService.getDocumentDownloadLink(
                documentUuids.abtretung_uuid_1
              )
            "
          >
            DOWNLOAD 1
          </a>
          <a
            *ngIf="documentUuids.abtretung_uuid_2 && values.type === 'post'"
            target="_blank"
            [href]="
              documentService.getDocumentDownloadLink(
                documentUuids.abtretung_uuid_2
              )
            "
          >
            DOWNLOAD 2
          </a>
        </span>
        <span>
          <p-checkbox
            name="document-sepa"
            [binary]="true"
            label="SEPA Blanko"
            [(ngModel)]="values.send_documents.sepa"
          ></p-checkbox>
          <a
            *ngIf="documentUuids.sepa_uuid && values.type === 'post'"
            target="_blank"
            [href]="
              documentService.getDocumentDownloadLink(documentUuids.sepa_uuid)
            "
          >
            DOWNLOAD
          </a>
        </span>
      </div>

      <!-- Wenn "Angebot mit Verträgen" ausgewöhlt wurde, für nicht-GKV Patienten -->
      <div
        class="dialog-list-container"
        *ngIf="!isGKV() && values.sending_type === 'DLV'"
      >
        <span>
          <p-checkbox
            name="document-offer"
            [binary]="true"
            label="Angebot"
            [(ngModel)]="values.send_documents.offer"
          ></p-checkbox>
          <a
            *ngIf="documentUuids.offer_uuid && values.type === 'post'"
            target="_blank"
            [href]="
              documentService.getDocumentDownloadLink(documentUuids.offer_uuid)
            "
          >
            DOWNLOAD
          </a>
        </span>

        <span *ngIf="values.type === 'post'">
          <p-checkbox
            name="document-dlv"
            [binary]="true"
            label="DLV Anschreiben"
            [(ngModel)]="values.send_documents.dlv_anschreiben"
          ></p-checkbox>
          <a
            *ngIf="documentUuids.dlv_anschreiben_uuid && values.type === 'post'"
            target="_blank"
            [href]="
              documentService.getDocumentDownloadLink(
                documentUuids.dlv_anschreiben_uuid
              )
            "
          >
            DOWNLOAD
          </a>
        </span>
        <span>
          <p-checkbox
            name="document-dlv"
            [binary]="true"
            label="DLV"
            [(ngModel)]="values.send_documents.dlv"
          ></p-checkbox>
          <a
            *ngIf="documentUuids.dlv_uuid_1 && values.type === 'post'"
            target="_blank"
            [href]="
              documentService.getDocumentDownloadLink(documentUuids.dlv_uuid_1)
            "
          >
            DOWNLOAD 1
          </a>
          <a
            *ngIf="documentUuids.dlv_uuid_2 && values.type === 'post'"
            target="_blank"
            [href]="
              documentService.getDocumentDownloadLink(documentUuids.dlv_uuid_2)
            "
          >
            DOWNLOAD 2
          </a>
        </span>
        <span>
          <p-checkbox
            name="document-kundeninformation"
            [binary]="true"
            label="Kundeninformation"
            [(ngModel)]="values.send_documents.kundeninformation"
          ></p-checkbox>
          <a
            *ngIf="
              documentUuids.kundeninformation_uuid && values.type === 'post'
            "
            target="_blank"
            [href]="
              documentService.getDocumentDownloadLink(
                documentUuids.kundeninformation_uuid
              )
            "
          >
            DOWNLOAD
          </a>
        </span>
        <span>
          <p-checkbox
            name="document-kundenaufnahmebogen"
            [binary]="true"
            label="Kundenaufnahmebogen"
            [(ngModel)]="values.send_documents.kundenaufnahmebogen"
          ></p-checkbox>
          <a
            *ngIf="
              documentUuids.kundenaufnahmebogen_uuid && values.type === 'post'
            "
            target="_blank"
            [href]="
              documentService.getDocumentDownloadLink(
                documentUuids.kundenaufnahmebogen_uuid
              )
            "
          >
            DOWNLOAD
          </a>
        </span>
        <span>
          <p-checkbox
            name="document-sepa"
            [binary]="true"
            label="SEPA Blanko"
            [(ngModel)]="values.send_documents.sepa"
          ></p-checkbox>
          <a
            *ngIf="documentUuids.sepa_uuid && values.type === 'post'"
            target="_blank"
            [href]="
              documentService.getDocumentDownloadLink(documentUuids.sepa_uuid)
            "
          >
            DOWNLOAD
          </a>
        </span>
      </div>
    </div>
  </div>
  <div class="dialog-form-footer" *ngIf="!documentsCreated">
    <a
      *ngIf="values.type === 'email'"
      target="_blank"
      (click)="clickedOnPreview = true"
      [href]="sendOfferService.getOfferMailPreviewLink(values)"
    >
      <button
        pButton
        label="Mail Vorschau"
        type="button"
        icon="pi pi-eye"
        iconPos="left"
        class="p-button p-button-info"
      ></button>
    </a>

    <a
      target="_blank"
      (click)="clickedOnPreviewOffer = true"
      [href]="sendOfferService.getOfferPdfPreviewLink(values)"
    >
      <button
        pButton
        label="Angebot Vorschau"
        type="button"
        icon="pi pi-file"
        iconPos="left"
        class="p-button p-button-info"
      ></button>
    </a>

    <button
      pButton
      [label]="values.type === 'email' ? 'Versenden' : 'Erstellen'"
      type="submit"
      (click)="save()"
      [pTooltip]="
        (!clickedOnPreview && values.type === 'email') || !clickedOnPreviewOffer
          ? 'Bitte Vorschau ansehen'
          : ''
      "
      [disabled]="
        submitted ||
        (!clickedOnPreview && values.type === 'email') ||
        !clickedOnPreviewOffer
      "
      [icon]="submitted ? 'pi pi-spin pi-spinner' : 'pi pi-send'"
    ></button>
  </div>
</form>
