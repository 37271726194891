<app-loader [loading]="loading"></app-loader>

<div class="content" *ngIf="!loading">
  <header class="content-header">
    <span>Alle Rollen</span>

    <div class="content-header-buttons">
      <a routerLink="/roles/new">
        <button
          pButton
          label="Neue Rolle"
          type="button"
          icon="pi pi-plus"
          iconPos="left"
          class="p-button-outlined"
        ></button>
      </a>
    </div>
  </header>
</div>

<div class="content" *ngIf="!loading">
  <div class="content-body without-padding">
    <p-table [value]="roles" [rowHover]="true">
      <ng-template pTemplate="header">
        <tr class="table-headline-highlight">
          <th>Name</th>
          <th style="width: 150px">Anzahl Benutzer</th>
          <th>Bemerkung</th>
          <th style="width: 100px"></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-role>
        <tr>
          <td>
            <a class="color-main1" routerLink="/roles/{{ role.id }}">
              {{ role.name }}
            </a>
          </td>
          <td>{{ role.users_count }}</td>
          <td>{{ role.comment || "-" }}</td>
          <td class="table-action" style="width: 100px">
            <a routerLink="/roles/{{ role.id }}/edit">
              <i class="pi pi-pencil"></i>
            </a>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
