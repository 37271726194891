import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core'
import {
  DialogService,
  DynamicDialogConfig,
  DynamicDialogRef,
} from 'primeng/dynamicdialog'
import { EventBusService, GlobalEvent } from 'src/app/services/eventbus.service'
import { ToastService } from 'src/app/services/toast.service'
import { NgForm } from '@angular/forms'
import { Subscription } from 'rxjs'
import { PatientService } from '../../../services/patient.service'
import { SearchService } from '../../../services/search.service'
import { SearchResultModel } from '../../../models/search/search-result.model'
import { SelectItem } from 'primeng/api'
import * as dayjs from 'dayjs'
import * as currency from 'currency.js'
import { SendLetterService } from '../../../services/send-letter.service'
import { DocumentService } from '../../../services/document.service'
import { AddDocumentDialogComponent } from '../add-document-dialog/add-document-dialog.component'
import { DocumentModel } from '../../../models/document/document.model'
import { MediaModel } from '../../../models/document/media.model'
import { AccountingReturnPositionModel } from '../../../models/accounting/accounting-return-position.model'
import { HelperService } from '../../../services/helper.service'
import { CaregiverDetailModel } from '../../../models/caregiver/caregiver-detail.model'
import { SystemMessageService } from '../../../services/system-message.service'
import { AuthService } from '../../../services/auth.service'

@Component({
  selector: 'app-send-system-message-dialog',
  templateUrl: './send-system-message-dialog.component.html',
})
export class SendSystemMessageDialogComponent implements OnInit, OnDestroy {
  @ViewChild('form', { static: true }) form!: NgForm

  private formSubscription: Subscription | null | undefined = null
  private isDirty = false

  submitted = false

  public options = {
    buttons: ['bold', 'underline', 'italic'],
    toolbarAdaptive: false,
    toolbar: true,
    enter: 'br' as any,
    placeholder: '',
    statusbar: false,
    addNewLine: false,
    language: 'de',
    showBrowserColorPicker: false,
    allowResizeTags: [],
    table: {
      allowCellResize: false
    }
  }

  public caregiverOptions: any[] = []
  public clickedOnPreview = false

  public values: any
  public documents: any[] = []
  public caregivers: SelectItem[] = []
  public patients: SearchResultModel[] = []

  public data: any
  public response: any
  public systemMessages: any = []
  public selectedMessage: any = null

  constructor(
    private dialogService: DialogService,
    public systemMessageService: SystemMessageService,
    private ref: DynamicDialogRef,
    private config: DynamicDialogConfig,
    private searchService: SearchService,
    private patientService: PatientService,
    private eventbus: EventBusService,
    public documentService: DocumentService,
    private authService: AuthService,
    private toastService: ToastService,
    private helperService: HelperService
  ) {}

  public ngOnInit(): void {
    this.data = this.config.data

    this.resetValues()

    this.formSubscription = this.form.valueChanges?.subscribe(() => {
      if (!this.form.pristine) {
        this.isDirty = true
      }
    })

    this.helperService.dependencies$.subscribe((data: any) => {
      this.caregiverOptions = data['caregivers_active'].map(
        (caregiver: CaregiverDetailModel) => {
          const email = caregiver.email || 'KEINE E-MAIL'

          return {
            id: caregiver.id,
            label: `${caregiver.last_first_name} - ${email}`,
          }
        }
      )
    })

    this.loadAll()
  }

  ngOnDestroy(): void {
    this.formSubscription?.unsubscribe()
  }

  public selectMessage(message: any = null): void {
    this.selectedMessage = message
    this.documents = message ? message.media : []
  }

  public save(): void {
    if (!this.form.form.valid) {
      this.submitted = false
      this.form.form.markAllAsTouched()
      return
    }

    this.submitted = true

    this.systemMessageService.store(this.values, this.documents).subscribe(
      (response: any) => {
        this.submitted = false

        this.resetValues()

        this.eventbus.emit(GlobalEvent.SystemMessageChanged)

        this.loadAll()

        this.selectedMessage = response

        this.toastService.success(
          'Mitteilung wurde versendet',
          'Mit Mitteilung wurde erfolgreich versendet'
        )
      },
      () => {
        this.toastService.error(
          'Etwas ist schiefgelaufen...',
          'Bitte wenden Sie sich an den Support'
        )

        this.submitted = false
      }
    )
  }

  public openAddDocumentDialog(): void {
    const user = this.authService.getUser()

    const ref = this.dialogService.open(AddDocumentDialogComponent, {
      data: {
        // TODO: Hier an den aktuellen Benutzer anbinden
        //   und dann im Backend umwandeln für media auf das gerade erstellte Mitteilung
        type_id: user?.user_system?.id,
        type: 'user_system',
        default_document_type: 'misc',
      },
      header: 'Dokument hochladen',
      styleClass: 'dialog-container',
      dismissableMask: false,
      width: '450px',
    })

    ref.onClose.subscribe((document: any) => {
      if (document) {
        const model = new MediaModel()
        model.id = document.media.id
        model.name = document.media.name
        model.uuid = document.media.uuid

        this.documents.push(model)
      }
    })
  }

  public searchPatients(event: any): void {
    this.searchService
      .findPatients(event.query.trim())
      .subscribe((results: SearchResultModel[]) => {
        this.patients = results
      })
  }

  private resetValues(): void {
    this.documents = []
    this.values = {
      subject: '',
      text: 'Hallo $anrede $vorname $nachname,<br><br>TEXT',
      caregivers: [] as any,
      patient: null,
    }
  }

  private loadAll(): void {
    this.systemMessageService.loadAll().subscribe((messages: any[]) => {
      this.systemMessages = messages
    })
  }
}
