<app-loader [loading]="loading"></app-loader>

<div class="content" *ngIf="!loading">
  <header class="content-header without-border">
    <span>
      <i class="pi pi-shield"></i>
      Rolle
    </span>
  </header>
</div>

<div class="content" style="margin: 0" *ngIf="!loading">
  <div class="content-body without-padding p-flex-row">
    <div class="detail-information-column border-right">
      <div class="detail-information-container">
        <div class="detail-information-header">
          <span><i class="pi pi-bars"></i>Daten</span>
        </div>
        <div class="detail-information-body">
          <div class="detail-information">
            <div class="detail-key">
              <span
                tooltipPosition="left"
                (mouseenter)="getLatestChange('name', role.name)"
                [pTooltip]="latestChanges.name"
                >Name</span
              >
            </div>
            <div class="detail-value">
              <strong>{{ role.name }}</strong>
            </div>
          </div>
          <div class="detail-information">
            <div class="detail-key">
              <span
                tooltipPosition="left"
                (mouseenter)="getLatestChange('comment', role.comment)"
                [pTooltip]="latestChanges.comment"
                >Kommentar</span
              >
            </div>
            <div class="detail-value">
              <strong>{{ role.comment || "-" }}</strong>
            </div>
          </div>
          <div class="detail-information">
            <div class="detail-key">
              <span
                tooltipPosition="left"
                (mouseenter)="
                  getLatestChange('permission_text', role.permission_text)
                "
                [pTooltip]="latestChanges.permission_text"
                >Rechte</span
              >
            </div>
            <div class="detail-value">
              <strong style="white-space: pre-wrap">{{
                role.permission_text || "-"
              }}</strong>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="detail-information-column">
      <div class="detail-information-container">
        <div class="detail-information-header">
          <span><i class="pi pi-user"></i>Zugewiesene Benutzer</span>
        </div>
        <div class="detail-information-body without-padding">
          <p-table [value]="role.users" [rowHover]="true">
            <ng-template pTemplate="header">
              <tr>
                <th>Name</th>
                <th></th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-user>
              <tr>
                <td>
                  {{ user.name }}
                </td>
                <td></td>
              </tr>
            </ng-template>
          </p-table>
        </div>
      </div>
    </div>
  </div>
</div>
