import { HttpErrorResponse } from '@angular/common/http'
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core'
import { StatusCodes } from 'http-status-codes'
import {
  DialogService,
  DynamicDialogConfig,
  DynamicDialogRef,
} from 'primeng/dynamicdialog'
import { EventBusService, GlobalEvent } from 'src/app/services/eventbus.service'
import { ToastService } from 'src/app/services/toast.service'
import { DocumentService } from '../../../services/document.service'
import { NgForm } from '@angular/forms'
import { Subscription } from 'rxjs'
import { ConfirmationService } from 'primeng/api'
import { AccountingReturnService } from '../../../services/accounting-return.service'
import { SearchResultModel } from '../../../models/search/search-result.model'
import { SearchService } from '../../../services/search.service'
import { HelperService } from '../../../services/helper.service'
import { MediaModel } from '../../../models/document/media.model'
import { SendLetterDialogComponent } from '../send-letter-dialog/send-letter-dialog.component'
import * as currency from 'currency.js'
import { AccountingReturnHistoryModel } from '../../../models/accounting/accounting-return-history.model'
import { AuthModel } from '../../../models/user/auth.model'
import { AuthService } from '../../../services/auth.service'

@Component({
  selector: 'app-add-accounting-return-dialog',
  templateUrl: './add-accounting-return-dialog.component.html',
})
export class AddAccountingReturnDialogComponent implements OnInit, OnDestroy {
  @ViewChild('form', { static: true }) form!: NgForm

  private formSubscription: Subscription | null | undefined = null
  private isDirty = false
  private selectedFile: any = null
  public patients: SearchResultModel[] = []

  public isEdit = false
  public data: any = {}

  public currentUser: AuthModel | null = new AuthModel()
  public editHistory: AccountingReturnHistoryModel | null = null
  public historyComment = ''

  public statusOptions = [
    { label: 'ERSTELLT', value: 'CREATED', backgroundColor: '#3296dc' },
    { label: 'IN BEARBEITUNG', value: 'EDITED', backgroundColor: '#dbaa25' },
    { label: 'ERLEDIGT', value: 'FINISHED', backgroundColor: '#84bc3c' },
  ]

  public confirmed = {
    case_number: false,
    performance_month: false,
    performance_year: false,
    invoice_number: false,
    invoice_date_string: false,
    total_value: false,
    invoice_value: false,
  }

  public incorrectInsurerNumber = false
  public values = {
    id: '',
    status: '',
    patient: '' as any,
    selected_file: '',
    invoice_date_string: null,
    total_value: 0,
    invoice_value: 0,
    letter_id: null,
    case_number: '',
    invoice_number: '',
    correction_reason: '',
    temp_image_name: '',
    insurer_number: '',
    result: null as any,
    performance_month: null,
    performance_year: null,
    media: [] as MediaModel[],
    user_histories: [] as AccountingReturnHistoryModel[],
    positions: [
      {
        amount: '',
        text: '',
        unit_value: null,
        total_value: null as any,
        correction: null,
      },
    ],
  }

  public submitted = false
  public submittedDelete = false

  public monthOptions: any = []
  public isParsing = false

  public resultOptions = []
  public reasonOptions = []

  constructor(
    private ref: DynamicDialogRef,
    private searchService: SearchService,
    private dialogService: DialogService,
    private config: DynamicDialogConfig,
    public documentService: DocumentService,
    private accountingService: AccountingReturnService,
    private eventbus: EventBusService,
    private toastService: ToastService,
    private authService: AuthService,
    private helperService: HelperService,
    private confirmationService: ConfirmationService
  ) {}

  ngOnInit(): void {
    if (this.config.data.accounting_return) {
      this.isEdit = true

      this.values = this.config.data.accounting_return
    }

    this.helperService.dependencies$.subscribe((data: any) => {
      this.monthOptions = data.months
      this.resultOptions = data.accounting_return_results
      this.reasonOptions = data.accounting_return_reasons
    })

    this.formSubscription = this.form.valueChanges?.subscribe(() => {
      if (!this.form.pristine) {
        this.isDirty = true
      }
    })

    this.currentUser = this.authService.getUser()
  }

  ngOnDestroy(): void {
    this.formSubscription?.unsubscribe()
  }

  public setSelectedFile(event: any): any {
    this.isParsing = true
    this.selectedFile = event.target.files[0]

    const formData = new FormData()
    formData.append('file', this.selectedFile)

    this.accountingService
      .uploadReturn(this.values.patient.id, formData)
      .subscribe(
        (data: any) => {
          this.incorrectInsurerNumber = data.incorrect_insurer_number

          this.values.case_number = data.data.case_number
          this.values.invoice_date_string = data.data.invoice_date
          this.values.invoice_number = data.data.invoice_number
          this.values.correction_reason = data.data.correction_reason || null
          this.values.invoice_value = data.data.invoice_value || 0
          this.values.total_value = data.data.total_value || 0
          this.values.performance_month = data.data.performance_month || null
          this.values.performance_year = data.data.performance_year || null
          this.values.positions = data.data.positions
          this.values.temp_image_name = data.data.temp_image_name
          this.values.insurer_number = data.data.insurer_number

          let totalValueForPosition = null

          // Nur wenn wir einen Wert aus dem Backend bekommen, berechnen wir den Wert.
          if (this.values.invoice_value != 0) {
            totalValueForPosition = currency(this.values.invoice_value)
              .add(this.values.total_value)
              .toString()
          }

          this.values.positions.push({
            amount: '',
            unit_value: null,
            correction: null,
            text: 'Zahlung Pflegekasse',
            total_value: totalValueForPosition,
          })

          this.isParsing = false
        },
        (error: HttpErrorResponse) => {
          if (error.status === StatusCodes.UNPROCESSABLE_ENTITY) {
            this.toastService.error('Das Dokument muss eine PDF sein')
            this.selectedFile = null
            this.values.selected_file = ''
          } else {
            this.toastService.error(
              'Etwas ist schief gelaufen...',
              'Bitte wenden Sie sich an den Support'
            )
          }

          this.isParsing = false
          this.submitted = false
        }
      )
  }

  public openEditMode(history: AccountingReturnHistoryModel): void {
    this.editHistory = history
    this.historyComment = history.comment
  }

  public cancelEditMode(): void {
    this.editHistory = null
    this.historyComment = ''
  }

  public changeStatus(): void {
    this.accountingService
      .changeReturnStatus(this.values.id, this.values.status)
      .subscribe(
        () => {
          this.eventbus.emit(GlobalEvent.AccountingReturnChanged)
          this.toastService.success(
            'Status gespeichert',
            'Der Status wurde erfolgreich geändert'
          )
        },
        (error: HttpErrorResponse) => {
          if (error.status === StatusCodes.UNPROCESSABLE_ENTITY) {
            this.toastService.error('Bitte füllen Sie alle Pflichtfelder aus')
          } else {
            this.toastService.error(
              'Etwas ist schief gelaufen...',
              'Bitte wenden Sie sich an den Support'
            )
          }
        }
      )
  }

  public storeHistoryComment(): void {
    this.submitted = true

    this.accountingService
      .storeHistoryComment(
        this.values.id,
        this.values.result,
        this.historyComment
      )
      .subscribe(
        () => {
          this.toastService.success(
            'Historyeintrag gespeichert',
            'Der Eintrag wurde erfolgreich geändert'
          )

          this.historyComment = ''
          this.loadUserHistories()

          this.submitted = false
        },
        (error: HttpErrorResponse) => {
          if (error.status === StatusCodes.UNPROCESSABLE_ENTITY) {
            this.toastService.error('Bitte füllen Sie alle Pflichtfelder aus')
          } else {
            this.toastService.error(
              'Etwas ist schief gelaufen...',
              'Bitte wenden Sie sich an den Support'
            )
          }

          this.submitted = false
        }
      )
  }

  public updateHistoryComment(): void {
    this.accountingService
      .updateHistoryComment(
        this.editHistory?.id || '',
        this.values.result,
        this.historyComment
      )
      .subscribe(
        () => {
          this.toastService.success(
            'Historyeintrag geändert',
            'Der Eintrag wurde erfolgreich geändert'
          )

          this.historyComment = ''
          this.cancelEditMode()
          this.loadUserHistories()
        },
        (error: HttpErrorResponse) => {
          if (error.status === StatusCodes.UNPROCESSABLE_ENTITY) {
            this.toastService.error('Bitte füllen Sie alle Pflichtfelder aus')
          } else {
            this.toastService.error(
              'Etwas ist schief gelaufen...',
              'Bitte wenden Sie sich an den Support'
            )
          }
        }
      )
  }

  public openMediaFromUuid(uuid: string): void {
    window.open(this.documentService.getDocumentDownloadLink(uuid))
  }

  public openOptaDataImageMedia(name: string): void {
    window.open(
      this.documentService.getTempOptaDataImageDocumentDownloadLink(name)
    )
  }

  public confirmSendLetter(event: any): void {
    this.confirmationService.confirm({
      target: event.target,
      message: 'Mit Bearbeitungsgebühr?',
      accept: () => {
        this.openSendLetterDialog()
      },
      reject: () => {
        this.openSendLetterDialog(true)
      },
    })
  }

  public openSendLetterDialog(withoutFee = false): void {
    const ref = this.dialogService.open(SendLetterDialogComponent, {
      header: 'Neuer Brief für Rückläufer',
      width: '680px',
      styleClass: 'dialog-container',
      data: {
        from_other_dialog: true,
        type: 'rückläufer',
        values: this.values,
        withoutFee,
      },
    })

    ref.onClose.subscribe((letter: any) => {
      if (letter) {
        this.values.result = 'rückläufer'
        this.values.letter_id = letter.id

        const feeText = letter.without_fee
          ? 'Ohne Bearbeitungsgebühr.'
          : 'Mit Bearbeitungsgebühr.'
        this.historyComment = `Versendet per ${letter.type_string}.\n${feeText}`
        this.storeHistoryComment()
      }
    })
  }

  public patientSelected(): void {
    this.selectedFile = null
    this.values.selected_file = ''
    this.values.invoice_date_string = null
    this.values.case_number = ''
    this.values.invoice_number = ''
    this.values.correction_reason = ''
    this.values.performance_month = null
    this.values.performance_year = null
    this.values.total_value = 0
    this.values.result = null
    this.values.invoice_value = 0
    this.values.letter_id = null
    this.values.temp_image_name = ''
    this.values.insurer_number = ''
    this.values.user_histories = []
    this.values.positions = [
      {
        amount: '',
        text: '',
        unit_value: null,
        total_value: 0,
        correction: null,
      },
    ]
  }

  public addPosition(): void {
    this.values.positions.push({
      amount: '',
      text: '',
      unit_value: null,
      total_value: 0,
      correction: null,
    })
  }

  removePosition(position: any): void {
    this.values.positions = this.values.positions.filter(
      (item) => item != position
    )
  }

  public searchPatients(event: any): void {
    this.searchService
      .findPatients(event.query.trim())
      .subscribe((results: SearchResultModel[]) => {
        this.patients = results
      })
  }

  public store(): void {
    if (!this.form.form.valid) {
      this.submitted = false
      this.form.form.markAllAsTouched()
      return
    }

    if (!this.isEdit && Object.values(this.confirmed).includes(false)) {
      alert('Bitte alle Felder bestätigen')
      return
    }

    if (this.historyComment) {
      alert('Bitte Historyeintrag speichern oder entfernen')
      return
    }

    this.submitted = true

    const formData = new FormData()
    formData.append('file', this.selectedFile)
    formData.append('data', JSON.stringify(this.values))

    const subscription = this.isEdit
      ? this.accountingService.updateReturn(this.values.id, formData)
      : this.accountingService.storeReturn(this.values.patient.id, formData)

    subscription.subscribe(
      () => {
        this.submitted = false
        this.eventbus.emit(GlobalEvent.AccountingReturnChanged)
        this.ref.close()
        this.toastService.success(
          'Rückläufer gespeichert',
          'Das Dokument wurde erfolgreich gespeichert'
        )
      },
      (error: HttpErrorResponse) => {
        if (error.status === StatusCodes.UNPROCESSABLE_ENTITY) {
          this.toastService.error('Bitte füllen Sie alle Pflichtfelder aus')
        } else {
          this.toastService.error(
            'Etwas ist schief gelaufen...',
            'Bitte wenden Sie sich an den Support'
          )
        }
        this.submitted = false
      }
    )
  }

  public close(): void {
    if (this.historyComment) {
      alert('Bitte Historyeintrag speichern oder entfernen')
      return
    }

    this.ref.close()
  }

  public loadUserHistories(): void {
    this.accountingService
      .loadUserHistories(this.values.id)
      .subscribe((userHistories: AccountingReturnHistoryModel[]) => {
        this.values.user_histories = userHistories
      })
  }

  public remove(event: any): void {
    this.confirmationService.confirm({
      target: event.target ?? undefined,
      message: 'Rückläufer wirklich löschen?',
      accept: () => {
        this.submittedDelete = true

        this.accountingService.remove(this.values.id).subscribe(
          () => {
            this.submittedDelete = false
            this.eventbus.emit(GlobalEvent.AccountingReturnChanged)
            this.ref.close()
            this.toastService.success(
              'Rückläufer gelöscht',
              'Der Rückläufer wurde erfolgreich gelöscht'
            )
          },
          () => {
            this.submittedDelete = false
            this.toastService.error(
              'Löschen fehlgeschlagen',
              'Der Rückläufer konnte nicht gelöscht werden'
            )
          }
        )
      },
      reject: () => {},
    })
  }
}
