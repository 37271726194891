import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs'
import { environment } from '../../environments/environment'
import { DesiredDatesResponse } from '../models/desired-date/desired-date.model'

@Injectable({
  providedIn: 'root',
})
export class CancelledDateService {
  constructor(private http: HttpClient) {}

  public load(): Observable<any> {
    return this.http.get<any>(`${environment.api}/cancelled-date`)
  }

  public loadLast(filters: any): Observable<any> {
    return this.http.get<any>(`${environment.api}/cancelled-date/last`, {
      params: {
        ...filters,
      },
    })
  }

  public reasons(): Observable<[]> {
    return this.http.get<[]>(`${environment.api}/cancelled-date/reasons`)
  }

  public store(data: {}): Observable<[]> {
    return this.http.post<[]>(`${environment.api}/cancelled-date`, data)
  }

  public update(id: number | string, data: {}): Observable<[]> {
    return this.http.put<[]>(`${environment.api}/cancelled-date/${id}`, data)
  }

  public delete(id: number | string): Observable<[]> {
    return this.http.delete<[]>(`${environment.api}/cancelled-date/${id}`)
  }
}
