import { CaregiverFormModel } from './caregiver-form.model'
import { CarsDetailModel } from '../car/cars-detail.model'
import { HistoryListModel } from '../history/history-list.model'
import { TodoModel } from '../todo/todo.model'
import { VacationIllModel } from '../vacation-ill/vacation-ill.model'
import { VetoModel } from '../veto/veto.model'
import { CaregiverCarModel } from '../car/caregiver-car.model'
import { ContactPersonModel } from '../contact-person/contact-person.model'
import { CaregiverJobScopeModel } from './caregiver-job-scope.model'
import { CaregiverJobDaysModel } from './caregiver-job-days.model'
import { CaregiverTimeModel } from './caregiver-time.model'
import { CaregiverEmployeedModel } from './caregiver-employeed.model'
import {
  CaregiverStatusModel,
  CaregiverStatusModelCaregiver,
} from './caregiver-status.model'
import { UserModel } from '../user/user.model'
import { CaregiverDetailModel } from './caregiver-detail.model'
import {CaregiverHospitationFeedbackModel} from "./caregiver-hospitation-feedback.model";

export class CaregiverDetailGeneralModel extends CaregiverFormModel {
  caregiver: CaregiverDetailGeneralDataModel = new CaregiverDetailGeneralDataModel()
  last_histories: HistoryListModel[] = []
  important_histories: HistoryListModel[] = []
}

export class CaregiverDetailGeneralDataModel extends CaregiverFormModel {
  activities_string = ''
  experiences_string = ''
  counties_string = ''
  cities_string = ''
  full_name = ''
  is_male = false
  is_female = false
  full_address = ''
  planner_id: null | number = null
  first_last_name = ''
  last_first_name = ''
  car: CarsDetailModel | null = null
  notice = ''
  allergy_text = ''
  phobia_text = ''
  works_on_holidays = false
  todos: TodoModel[] = []

  caregivers_with_same_email: CaregiverDetailModel[] = []

  current_vacation: VacationIllModel | null = null
  current_ill: VacationIllModel | null = null
  current_free_time_request: VacationIllModel | null = null
  vetos: VetoModel[] = []
  cars: CaregiverCarModel[] = []
  contact_persons: ContactPersonModel[] = []
  important_contact_person: ContactPersonModel | null = null
  current_job_scope: CaregiverJobScopeModel | null = null
  current_job_days: CaregiverJobDaysModel | null = null
  job_scopes: CaregiverJobScopeModel[] = []
  job_days: CaregiverJobDaysModel[] = []
  current_time: CaregiverTimeModel | null = null
  past_times: CaregiverTimeModel[] = []
  employments: CaregiverEmployeedModel[] = []
  current_employment: CaregiverEmployeedModel = new CaregiverEmployeedModel()
  current_status: CaregiverStatusModel = new CaregiverStatusModel()
  status_values: CaregiverStatusModel[] = []
  last_status_with_hospitation: CaregiverStatusModel | null = null
  last_status_with_hospitation_feedback: CaregiverStatusModel | null = null
  planner_name = ''

  planner: null | UserModel = null
}
