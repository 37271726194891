import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core'
import { CaregiverService } from '../../../../../services/caregivers.service'
import { ActivatedRoute, ParamMap } from '@angular/router'
import { DialogService } from 'primeng/dynamicdialog'
import {
  EventBusService,
  GlobalEvent,
} from '../../../../../services/eventbus.service'
import { Subscription } from 'rxjs'
import { AccountingReturnModel } from '../../../../../models/accounting/accounting-return.model'
import { AddAccountingReturnDialogComponent } from '../../../../../components/dialogs/add-accounting-return-dialog/add-accounting-return-dialog.component'
import { DocumentService } from '../../../../../services/document.service'
import { AccountingReturnService } from '../../../../../services/accounting-return.service'
import { HelperService } from '../../../../../services/helper.service'

@Component({
  selector: 'app-patients-detail-accounting-returns',
  templateUrl: './patients-detail-accounting-returns.component.html',
  providers: [DialogService],
})
export class PatientsDetailAccountingReturnsComponent
  implements OnInit, OnDestroy {
  @ViewChild('dt') table: ElementRef | any = null

  public loading = true
  public data: AccountingReturnModel[] = []
  public patientId: string | null = null
  public globalFilterValue = ''

  public correctionReasons: any = {}

  private eventBusSubscription: Subscription = new Subscription()
  private routeSubscription: any = null

  constructor(
    public caregiverService: CaregiverService,
    public documentService: DocumentService,
    private accountingService: AccountingReturnService,
    private route: ActivatedRoute,
    private eventbus: EventBusService,
    private dialogService: DialogService,
    private helperService: HelperService
  ) {}

  ngOnInit(): void {
    this.loadData()
    this.listenForEventbus()

    this.helperService.dependencies$.subscribe((data: any) => {
      data['accounting_return_reasons'].forEach((reason: any) => {
        this.correctionReasons[reason.value] = reason.label
      })
    })
  }

  ngOnDestroy(): void {
    this.eventBusSubscription.unsubscribe()
    this.routeSubscription && this.routeSubscription.unsubscribe()
  }

  public openAddAccountingReturnDialog(
    accounting_return: AccountingReturnModel | null = null
  ): void {
    this.dialogService.open(AddAccountingReturnDialogComponent, {
      header: accounting_return
        ? 'Rückläufer bearbeiten'
        : 'Rückläufer erstellen',
      width: '820px',
      closeOnEscape: false,
      closable: false,
      styleClass: 'dialog-container dialog-with-own-close',
      data: {
        patient_id: this.patientId,
        accounting_return,
      },
    })
  }

  private listenForEventbus(): void {
    this.eventBusSubscription = this.eventbus.subject.subscribe(
      (event: GlobalEvent) => {
        switch (event) {
          case GlobalEvent.AccountingReturnChanged:
            this.loadData(false)
        }
      }
    )
  }

  public filterTableGlobal(event: any): void {
    this.table && this.table.filterGlobal(event.target.value.trim(), 'contains')
  }

  public loadData(withLoading: boolean = true): void {
    if (withLoading) {
      this.loading = true
    }

    if (this.route.parent?.parent) {
      this.routeSubscription = this.route.parent.parent.paramMap.subscribe(
        (params: ParamMap) => {
          this.patientId = params.get('id')

          this.accountingService
            .loadForPatient(this.patientId || '')
            .subscribe((data: AccountingReturnModel[]) => {
              this.data = data

              if (withLoading) {
                this.loading = false
              }
            })
        }
      )
    }
  }
}
