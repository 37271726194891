<form #form="ngForm" class="dialog-form">
  <div class="dialog-form-body">
    <div class="dialog-detail-information-body">
      <div class="dialog-detail-information">
        <div class="dialog-detail-key"><span>Typ</span></div>
        <div class="dialog-detail-value">
          <div *ngIf="config.data.type === 'DRIVE_TIME'">Fahrzeit</div>
          <div *ngIf="config.data.type === 'PAUSE_TIME'">Pause</div>
          <div *ngIf="config.data.type === 'NO_IDLE'">Plausibel</div>
        </div>
      </div>
      <div class="dialog-detail-information">
        <div class="dialog-detail-key"><span>Tag</span></div>
        <div class="dialog-detail-value">
          <div>{{ config.data.row.date }} ({{ config.data.row.day }})</div>
        </div>
      </div>
      <div class="dialog-detail-information">
        <div class="dialog-detail-key"><span>Einsatz</span></div>
        <div class="dialog-detail-value">
          <div>
            {{ config.data.row.data.real_from_h }} -
            {{ config.data.row.data.real_to_h }} ({{
              config.data.row.data.real_hours_as_float
            }}) bei
            {{ config.data.row.data.patient.first_name }}
            {{ config.data.row.data.patient.last_name }}
          </div>
        </div>
      </div>
      <div class="dialog-detail-information">
        <div class="dialog-detail-key"><span>Nächster Einsatz</span></div>
        <div class="dialog-detail-value">
          <div>
            {{ config.data.next_row.data.real_from_h }} -
            {{ config.data.next_row.data.real_to_h }} ({{
              config.data.next_row.data.real_hours_as_float
            }}) bei
            {{ config.data.next_row.data.patient.first_name }}
            {{ config.data.next_row.data.patient.last_name }}
          </div>
        </div>
      </div>
      <div class="dialog-detail-information">
        <div class="dialog-detail-key">
          <span>Fahrzeit zum nächsten Einsatz</span>
        </div>
        <div class="dialog-detail-value">
          <div>{{ config.data.row.google_drive_time_formatted }}</div>
        </div>
      </div>
    </div>
  </div>
  <div class="dialog-form-body without-padding">
    <div class="dialog-comments">
      <div
        class="dialog-comment dialog-no-comment"
        *ngIf="config.data.comments.length === 0"
      >
        Noch keine Kommentare
      </div>
      <div class="dialog-comment" *ngFor="let comment of config.data.comments">
        <!-- Den Kommentar darf nur der Ersteller bearbeiten bzw. ein Super Admin -->
        <i
          *ngIf="
            currentUser?.id === comment.user_id || authService.isSuperAdmin
          "
          class="pi pi-pencil"
          pTooltip="Bearbeiten"
          (click)="openEditMode(comment)"
        ></i>

        <i
          [escape]="false"
          tooltipStyleClass="tooltip-wider"
          class="pi pi-info-circle"
          [pTooltip]="comment.tooltip_text"
        ></i>

        <strong>{{ comment.comment }}</strong>
        <span
          >{{ comment.created_from.name }} am
          {{ comment.created_at | date: "dd.MM.y, H:mm" }} Uhr</span
        >
      </div>
    </div>
  </div>
  <div class="dialog-form-footer dialog-form-footer-textarea">
    <textarea
      id="comment"
      name="comment"
      [(ngModel)]="comment"
      [rows]="2"
      [required]="true"
      pInputTextarea
      [autofocus]="true"
      placeholder="Kommentar eintragen..."
    ></textarea>

    <div class="dialog-form-footer-actions">
      <i *ngIf="!editComment"></i>
      <button
        *ngIf="editComment"
        pButton
        label="Abbrechen"
        class="p-button-text button-without-padding"
        type="button"
        (click)="cancelEditMode()"
        icon="pi pi-times"
      ></button>

      <button
        *ngIf="editComment"
        (click)="update()"
        pButton
        label="Kommentar bearbeiten"
        type="button"
        [disabled]="submitted"
        [icon]="submitted ? 'pi pi-spin pi-spinner' : 'pi pi-check'"
      ></button>

      <button
        *ngIf="!editComment"
        pButton
        label="Kommentar erstellen"
        type="submit"
        (click)="save()"
        [disabled]="submitted || !comment"
        [icon]="submitted ? 'pi pi-spin pi-spinner' : 'pi pi-check'"
      ></button>
    </div>
  </div>
</form>
<p-confirmPopup></p-confirmPopup>
