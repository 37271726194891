<app-loader [loading]="loading"></app-loader>

<div
  class="content new-content-container"
  style="margin-bottom: 0"
  *ngIf="!loading"
>
  <header class="content-header" *ngIf="data.length > 0">
    <span>Stornierte Rechnungen</span>

    <div
      class="table-search-container"
      [class.searchfield-active]="globalFilterValue != ''"
      *ngIf="data.length > 0"
    >
      <div class="p-input-icon-left">
        <i class="pi pi-search"></i>
        <input
          pInputText
          (input)="filterTableGlobal($event)"
          placeholder="Suche..."
          [(ngModel)]="globalFilterValue"
        />
      </div>
    </div>
  </header>

  <div class="content-body" *ngIf="data.length == 0">
    <span class="content-no-message"
      >Keine stornierten Rechnungen vorhanden</span
    >
  </div>

  <div
    class="content-body content-body-full-height-with-subtabs without-padding"
    *ngIf="data.length > 0"
  >
    <p-table
      #dt
      [value]="data"
      [globalFilterFields]="[
        'invoiceable_id',
        'invoiceable.patient.first_name',
        'invoiceable.patient.last_name',
        'invoice_number_complete'
      ]"
      scrollHeight="100%"
      [showCurrentPageReport]="true"
      currentPageReportTemplate="{totalRecords} Stornos"
      [scrollable]="true"
      [rowHover]="true"
      [paginator]="true"
      [rows]="50"
      [(first)]="firstPage"
      stateStorage="local"
      stateKey="invoices-consultings-storno-3"
    >
      <ng-template pTemplate="header">
        <tr class="table-headline-highlight">
          <th>Status</th>
          <th pSortableColumn="invoice_number">
            Rechnungsnummer
            <p-sortIcon field="invoice_number"></p-sortIcon>
          </th>
          <th pSortableColumn="invoiceable.patient.last_name">
            Patient
            <p-sortIcon field="invoiceable.patient.last_name"></p-sortIcon>
          </th>
          <th pSortableColumn="total_price">
            Gesamtkosten
            <p-sortIcon field="total_price"></p-sortIcon>
          </th>
          <th style="min-width: 400px">Grund</th>
          <th style="min-width: 200px" pSortableColumn="storno_at">
            Storniert am
            <p-sortIcon field="storno_at"></p-sortIcon>
          </th>
          <th style="max-width: 100px"></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-item>
        <tr>
          <td>
            <span class="p-tag p-tag-rounded p-tag-{{ item.status }}">{{
              item.status_text
            }}</span>
          </td>
          <td>
            {{ item.invoice_number_complete }}
          </td>
          <td>
            <a
              routerLink="/patients/{{
                item.invoiceable.patient.id
              }}/consulting"
              >{{ item.invoiceable.patient.last_name }},
              {{ item.invoiceable.patient.first_name }}</a
            >
          </td>
          <td>
            {{ item.total_price_euro_formatted }}
          </td>
          <td style="min-width: 400px">
            {{ item.storno_history?.manual_description }}
          </td>
          <td style="min-width: 200px">
            {{ item.storno_at | date: "dd.MM.y, H:mm" }} Uhr
          </td>
          <td class="table-action" style="max-width: 100px">
            <a
              *ngIf="item.media[0]"
              [href]="
                documentService.getDocumentDownloadLink(item.media[0].uuid)
              "
              target="_blank"
            >
              <i
                tooltipPosition="left"
                pTooltip="Herunterladen"
                class="pi pi-download"
              ></i>
            </a>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
