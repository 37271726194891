import { Component } from '@angular/core'

@Component({
  selector: 'app-no-permission',
  templateUrl: './no-permission.component.html',
  styleUrls: ['./no-permission.component.scss'],
})
export class NoPermissionComponent {
  constructor() {}
}
