import { Component, OnInit } from '@angular/core'
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog'
import { EventBusService, GlobalEvent } from 'src/app/services/eventbus.service'
import { ToastService } from 'src/app/services/toast.service'
import { NoticeService } from '../../../services/notice.service'
import { PatientService } from '../../../services/patient.service'
import { ConfirmationService } from 'primeng/api'

@Component({
  selector: 'app-notice-accounting-dialog',
  templateUrl: './notice-accounting-dialog.component.html',
})
export class NoticeAccountingDialogComponent implements OnInit {
  notice_accounting = ''
  submitted = false
  submittedDelete = false

  public sepaDocuments: {
    sepa_first_patient_uuid: string | null
    sepa_second_patient_uuid: string | null
  } = { sepa_first_patient_uuid: null, sepa_second_patient_uuid: null }

  constructor(
    public patientService: PatientService,
    private ref: DynamicDialogRef,
    private config: DynamicDialogConfig,
    private noticeService: NoticeService,
    private eventbus: EventBusService,
    private toastService: ToastService,
    private confirmationService: ConfirmationService
  ) {}

  public ngOnInit(): void {
    this.notice_accounting = this.config.data.notice_accounting

    this.loadSepaDocuments()
  }

  public save(): void {
    this.submitted = true

    this.noticeService
      .updateNoticeAccounting(
        this.config.data.type_id,
        this.config.data.type,
        this.notice_accounting
      )
      .subscribe(
        () => {
          this.submitted = false
          this.eventbus.emit(GlobalEvent.NoticeChanged)
          this.ref.close()
          this.toastService.success(
            'Notiz bearbeitet',
            'Die Notiz wurde erfolgreich bearbeitet'
          )
        },
        () => {
          this.toastService.error(
            'Etwas ist schiefgelaufen...',
            'Bitte wenden Sie sich an den Support'
          )

          this.submitted = false
        }
      )
  }

  private loadSepaDocuments(): void {
    this.noticeService
      .loadSepaDocuments(this.config.data.type_id)
      .subscribe((data: any) => {
        this.sepaDocuments = data
      })
  }

  public remove(event: any): void {
    this.confirmationService.confirm({
      target: event.target ?? undefined,
      message: 'Notiz wirklich löschen?',
      accept: () => {
        this.noticeService
          .updateNoticeAccounting(
            this.config.data.type_id,
            this.config.data.type,
            null
          )
          .subscribe(
            () => {
              this.submitted = false
              this.eventbus.emit(GlobalEvent.NoticeChanged)
              this.ref.close()
              this.toastService.success(
                'Notiz entfernt',
                'Die Notiz wurde erfolgreich entfernt'
              )
            },
            () => {
              this.toastService.error(
                'Etwas ist schiefgelaufen...',
                'Bitte wenden Sie sich an den Support'
              )

              this.submitted = false
            }
          )
      },
      reject: () => {},
    })
  }
}
