import { Component, OnInit } from '@angular/core'
import { DialogService } from 'primeng/dynamicdialog'
import { debounce } from 'lodash-es'
import { DocumentService } from '../../../services/document.service'
import {
  EventBusService,
  GlobalEvent,
} from '../../../services/eventbus.service'
import { PlansFeedbackDialogComponent } from '../../../components/dialogs/plans-feedback-dialog/plans-feedback-dialog.component'
import { PlanService } from '../../../services/plan.service'

@Component({
  selector: 'app-plans-feedback',
  templateUrl: './plans-feedback.component.html',
})
export class PlansFeedbackComponent implements OnInit {
  public globalFilterValue = ''
  public loading = false
  public correctionReasons: any = {}

  public filters = [
    'FEEDBACK',
    'TIME_CHANGE',
    'TRIP',
    'DRIVE_TIME_FEEDBACK',
    'VACATION_REQUEST',
    'FREE_TIME_REQUEST',
  ]

  public results: any = []

  constructor(
    public documentService: DocumentService,
    private dialogService: DialogService,
    private eventbus: EventBusService,
    private planService: PlanService
  ) {}

  ngOnInit(): void {
    this.initFilters()
    this.filterTableGlobal = debounce(this.filterTableGlobal, 600)
  }

  public openPlansFeedbackDialog(item: any): void {
    let header = ''

    if (item.type === 'FEEDBACK') {
      header = 'Feedback'
    } else if (item.type === 'TIME_CHANGE') {
      header = 'Dauer'
    } else if (item.type === 'DRIVE_TIME_FEEDBACK') {
      header = 'Fahrzeit'
    } else {
      header = 'KM-Fahrt'
    }

    this.dialogService.open(PlansFeedbackDialogComponent, {
      header,
      width: '680px',
      dismissableMask: true,
      styleClass: 'dialog-container',
      data: {
        item,
      },
    })
  }

  public filterTableGlobal(withLoading: boolean = true): void {
    if (withLoading) {
      this.loading = true
    }

    const filters = JSON.parse(
      localStorage.getItem('plans-feedback-filters') || '[]'
    )

    this.planService
      .search(filters, this.globalFilterValue)
      .subscribe((results: any) => {
        this.results = results

        if (withLoading) {
          this.loading = false
        }
      })
  }

  public loadFeedbacks(): void {
    localStorage.setItem('plans-feedback-filters', JSON.stringify(this.filters))

    this.eventbus.emit(GlobalEvent.PlanFeedbackChanged)

    // Wenn ein Suchbegriff eingetragen ist, soll die Suche nochmal neu starten.
    if (this.globalFilterValue) {
      this.filterTableGlobal(false)
    }
  }

  private initFilters(): void {
    // Falls noch nichts im Speicher vorhanden ist, setze den Default wert.
    if (localStorage.getItem('plans-feedback-filters') === null) {
      localStorage.setItem(
        'plans-feedback-filters',
        JSON.stringify(this.filters)
      )
    } else {
      // Falls aber was vorhanden ist, werden die Filter aus dem Speicher genommen.
      this.filters = JSON.parse(
        localStorage.getItem('plans-feedback-filters') || '[]'
      )
    }
  }
}
