import { Component, ElementRef, OnInit, ViewChild } from '@angular/core'
import { TitleService } from 'src/app/services/title.service'
import { PatientService } from '../../../services/patient.service'
import { DialogService } from 'primeng/dynamicdialog'

@Component({
  selector: 'app-patients-qualification-open',
  templateUrl: './patients-qualification-open.component.html',
})
export class PatientsQualificationOpenComponent implements OnInit {
  @ViewChild('dt') table: ElementRef | any = null

  public globalFilterValue = ''
  public firstPage = 0

  public loading = false

  // TODO: model erstellen
  public patients: any[] = []

  constructor(
    private dialogService: DialogService,
    private patientService: PatientService,
    private titleService: TitleService
  ) {}

  ngOnInit(): void {
    this.loadUsers()
    this.titleService.setTitle('Qualifizierung')
  }

  public filterTableGlobal(event: any): void {
    this.table && this.table.filterGlobal(event.target.value.trim(), 'contains')
  }

  // public openSendDlvDialog(patient: any): void {
  //   this.dialogService.open(SendDlvDialogComponent, {
  //     header: 'DLV versenden',
  //     width: '420px',
  //     styleClass: 'dialog-container',
  //     data: {
  //       ...this.customer,
  //       patient_id: patient.id,
  //     },
  //   })
  // }

  private loadUsers(): void {
    this.loading = true

    this.patientService.qualificationOpen().subscribe((data: any[]) => {
      this.patients = data

      this.loading = false

      setTimeout(() => {
        if (this.table) {
          const totalItems = this.table._totalRecords

          this.globalFilterValue = this.table.filters.global?.value ?? ''

          if (this.table._first > totalItems || this.table._rows > totalItems) {
            this.firstPage = 0
          }
        }
      })
    })
  }
}
