import { Injectable } from '@angular/core'
import { Subject } from 'rxjs'

@Injectable({
  providedIn: 'root',
})
export class EventBusService {
  private subject$ = new Subject<GlobalEvent>()
  public subject = this.subject$.asObservable()

  constructor() {}

  public emit(event: GlobalEvent): void {
    this.subject$.next(event)
  }
}

export enum GlobalEvent {
  PhoneCallListReload,
  HistoryListReload,
  ContactPersonChanged,
  DocumentListReload,
  ProofsChanged,
  AccountingReturnChanged,
  InvoiceChanged,
  PlanFeedbackChanged,
  VacationChanged,
  DamageChanged,
  CarCostChanged,
  TrainingChanged,
  CaregiverDateChanged,
  TimesChanged,
  ThcDateChanged,
  CaregiverDetailReload,
  CarDetailReload,
  NoticeChanged,
  CustomerServiceChanged,
  QualificationFinished,
  PatientStatusChanged,
  CaregiverStatusChanged,
  MultiplierStatusChanged,
  BudgetChanged,
  CaregiverTimeChanged,
  TodoChanged,
  VetoChanged,
  ConsultingChanged,
  PlanningChanged,
  BirthdayWished,
  OfferSent,
  InvoiceReminderSent,
  HandoverSent,
  LetterSent,
  DesiredDateChanged,
  SystemMessageChanged,
  CancelledDateChanged,
  CaregiverProofChanged,
  CaregiverOverviewChanged,
}
