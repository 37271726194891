import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { environment } from '../../environments/environment'
import { Observable } from 'rxjs'
import { CarCostModel } from '../models/car/car-cost.model'

@Injectable({
  providedIn: 'root',
})
export class CarCostService {
  constructor(private http: HttpClient) {}

  public load(carCostId: string | number): Observable<CarCostModel> {
    return this.http.get<CarCostModel>(
      `${environment.api}/cars-costs/${carCostId}`
    )
  }

  public store(carId: string, values: any): Observable<[]> {
    return this.http.post<[]>(`${environment.api}/cars-costs/${carId}`, values)
  }

  public update(carCostId: number | string, data: {}): Observable<[]> {
    return this.http.put<[]>(`${environment.api}/cars-costs/${carCostId}`, data)
  }

  public list(carId: string | null): Observable<CarCostModel[]> {
    return this.http.get<CarCostModel[]>(
      `${environment.api}/cars-costs/${carId}/list`
    )
  }

  public delete(carCostId: number | string): Observable<[]> {
    return this.http.delete<[]>(`${environment.api}/cars-costs/${carCostId}`)
  }
}
