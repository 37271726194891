import { Component, ElementRef, OnInit, ViewChild } from '@angular/core'
import { DialogService } from 'primeng/dynamicdialog'
import { EventBusService } from 'src/app/services/eventbus.service'
import { TitleService } from 'src/app/services/title.service'
import { PhoneCallsListItemModel } from '../../../models/phone-call/phone-calls-list.model'
import { AuthService } from '../../../services/auth.service'
import { UserCaregiverService } from '../../../services/user-caregiver.service'
import { LatestChangeModel } from '../../../models/history/latest-change.model'
import * as dayjs from 'dayjs'
import * as customParseFormat from 'dayjs/plugin/customParseFormat'
import * as utc from 'dayjs/plugin/utc'
import { AddUserCaregiverDateDialogComponent } from '../../../components/dialogs/add-user-caregiver-date-dialog/add-user-caregiver-date-dialog.component'

@Component({
  selector: 'app-user-caregiver-calendar',
  templateUrl: './user-caregiver-calendar.component.html',
  styleUrls: ['./user-caregiver-calendar.component.scss'],
})
export class UserCaregiverCalendarComponent implements OnInit {
  @ViewChild('dt') table: ElementRef | any = null

  public globalFilterValue = ''
  public phoneCalls: PhoneCallsListItemModel[] = []
  public loading = false
  public contentLoading = true
  public currentHover = ''

  // TODO: Models erstellen
  public calendarData: any
  public calendarDays: string[] = []
  public persplanData = new LatestChangeModel()

  public year = ''
  private selectedYear = 0

  public yearOptions = [
    { label: '2021', value: '2021' },
    { label: '2022', value: '2022' },
    { label: '2023', value: '2023' },
    { label: '2024', value: '2024' },
  ]

  constructor(
    public userCaregiverService: UserCaregiverService,
    private authService: AuthService,
    private dialogService: DialogService,
    private eventbus: EventBusService,
    private titleService: TitleService
  ) {
    dayjs.locale('de')
    dayjs.extend(customParseFormat)
    dayjs.extend(utc)
  }

  ngOnInit(): void {
    document.body.classList.remove('mobile-nav-visible')

    this.titleService.setTitle(`Kalender`)

    const date = new Date()
    this.selectedYear = date.getFullYear()
    this.year = this.selectedYear.toString()

    this.loadData()
  }

  public openDateDialog(): void {
    this.dialogService.open(AddUserCaregiverDateDialogComponent, {
      header: 'Neuer Abwesenheitswunsch',
      width: '450px',
      styleClass: 'dialog-container',
      data: {},
    })
  }

  /**
   * Springt zum letzten Jahr.
   */
  public goToPreviousYear(): void {
    const newYear = this.selectedYear - 1

    const hasYearOption = this.yearOptions.find(
      (data: any) => data.value == newYear
    )

    if (hasYearOption) {
      this.selectedYear--
      this.year = this.selectedYear.toString()

      this.loadData(false)
    }
  }

  /**
   * Springt zum nächsten Jahr.
   */
  public goToNextYear(): void {
    const newYear = this.selectedYear + 1

    const hasYearOption = this.yearOptions.find(
      (data: any) => data.value == newYear
    )

    if (hasYearOption) {
      this.selectedYear++
      this.year = this.selectedYear.toString()

      this.loadData(false)
    }
  }

  // TODO: Eigene Route für die BK Basteln
  public getPersplanForCaregiver(type: string, date: string): void {
    // Wir können nur eine Request machen, wenn es sich um einen Termin handelt.
    if (this.persplanData[date] || type !== 'TYPE_APPOINTMENT') {
      return
    }

    this.userCaregiverService
      .getPersplan(date)
      .subscribe((response: string) => {
        this.persplanData[date] = response
      })
  }

  public loadData(withLoading: boolean = true): void {
    this.contentLoading = true

    if (withLoading) {
      this.loading = true
    }

    this.userCaregiverService
      .loadCalender(this.year)
      .subscribe((calendar: any) => {
        this.calendarDays = Object.keys(calendar)
        this.calendarData = calendar

        this.contentLoading = false

        if (withLoading) {
          this.loading = false
        }
      })
  }

  public setHover(day: string = ''): void {
    this.currentHover = day
  }
}
