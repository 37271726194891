<form #form="ngForm" class="dialog-form">
  <div class="dialog-form-body">
    <div class="p-field" *ngIf="customer.first_patient">
      <label>Patient 1</label>
      <a
        target="_blank"
        [href]="
          documentService.printAddressLink(
            'patient_' + customer.first_patient.id
          )
        "
        >{{ customer.first_patient.full_name }} <i class="pi pi-download"></i
      ></a>
    </div>

    <div class="p-field" *ngIf="customer.second_patient">
      <label>Patient 2</label>
      <a
        target="_blank"
        [href]="
          documentService.printAddressLink(
            'patient_' + customer.second_patient.id
          )
        "
        >{{ customer.second_patient.full_name }} <i class="pi pi-download"></i
      ></a>
    </div>

    <div
      class="p-field"
      *ngFor="let contactPersons of customer.customer.contact_persons"
    >
      <label>Ansprechpartner</label>
      <a
        target="_blank"
        [href]="documentService.printAddressLink('ap_' + contactPersons.id)"
        >{{ contactPersons.full_name }} <i class="pi pi-download"></i
      ></a>
    </div>
  </div>
</form>
