import { HistoryListModel } from '../history/history-list.model'
import { ContactPersonModel } from '../contact-person/contact-person.model'
import { AppointmentLockModel } from '../appointments/appointment-lock.model'

export class PatientDetailHistoryModel {
  first_patient: PatientDetailDocumentsModel = new PatientDetailDocumentsModel()
  second_patient: PatientDetailDocumentsModel = new PatientDetailDocumentsModel()
  contact_persons: ContactPersonModel[] = []
}

export class PatientDetailDocumentsModel {
  patient_type = ''
  full_name = ''
  id = 0
  status = ''
  is_paused = false
  status_string = ''
  initial_documents_generated = true
  histories: HistoryListModel[] = []
  appointment_lock: AppointmentLockModel | null = null
}
