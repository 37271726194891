import { Injectable } from '@angular/core'
import { HttpClient, HttpParams } from '@angular/common/http'
import { Observable } from 'rxjs'
import { environment } from '../../environments/environment'

@Injectable({
  providedIn: 'root',
})
export class SystemMessageService {
  constructor(private http: HttpClient) {}

  public store(data: any, documents: any[] = []): Observable<[]> {
    return this.http.post<[]>(`${environment.api}/system-messages/store`, {
      ...data,
      documents,
    })
  }

  public loadAll(): Observable<any[]> {
    return this.http.get<any[]>(`${environment.api}/system-messages/load-all`)
  }

  public getMailPreviewLink(values: any): string {
    const params = new HttpParams({ fromObject: values }).toString()

    return `${environment.api}/system-messages/preview?${params}`
  }
}
