import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core'
import { ActivatedRoute, ParamMap } from '@angular/router'
import { CaregiverOverviewService } from '../../../../../services/caregiver-overview.service'
import { CapacityService } from '../../../../../services/capacity.service'
import * as dayjs from 'dayjs'
import * as customParseFormat from 'dayjs/plugin/customParseFormat'
import * as utc from 'dayjs/plugin/utc'
import { HelperService } from '../../../../../services/helper.service'
import { ToastService } from '../../../../../services/toast.service'
import { DocumentService } from '../../../../../services/document.service'
import { AuthService } from '../../../../../services/auth.service'
import { DialogService } from 'primeng/dynamicdialog'
import { AddCaregiverOverviewCheckCommentDialogComponent } from '../../../../../components/dialogs/add-caregiver-overview-check-comment-dialog/add-caregiver-overview-check-comment-dialog.component'
import {
  EventBusService,
  GlobalEvent,
} from '../../../../../services/eventbus.service'
import { Subject, Subscription } from 'rxjs'
import { AppointmentFeedbackDialogComponent } from '../../../../../components/dialogs/appointment-feedback-dialog/appointment-feedback-dialog.component'
import { AppointmentTripsDialogComponent } from '../../../../../components/dialogs/appointment-trips-dialog/appointment-trips-dialog.component'
import { AppointmentChangeFeedbackDialogComponent } from '../../../../../components/dialogs/appointment-change-feedback-dialog/appointment-change-feedback-dialog.component'
import { switchMap } from 'rxjs/operators'

@Component({
  selector: 'app-caregivers-detail-list',
  templateUrl: './caregivers-detail-list.component.html',
  styleUrls: ['caregivers-detail-list.component.scss'],
})
export class CaregiversDetailListComponent implements OnInit, OnDestroy {
  @ViewChild('dt') table: ElementRef | any = null

  private eventBusSubscription: Subscription = new Subscription()

  public loading = true
  public contentLoading = true
  public pdfIsGenerating = false
  public pdfIllIsGenerating = false
  public checkIsActivated = false
  public caregiverId: string | null = null
  public currentDay = dayjs().format('DD.MM.YYYY')
  public globalFilterValue = ''

  // TODO: Models erstellen
  public listData: any

  public hoursGoogle = 0
  public hoursGoogleFull = 0
  public hoursGoogleForHome = 0
  public driveMeters = 0
  public driveMetersFull = 0
  public driveMetersForHome = 0
  public hoursTotal = 0
  public totalPlannings = 0
  public year = ''
  public month = ''
  public currentHover = ''
  public activeRow = ''

  public selectedYear = 0
  public selectedMonth = 0

  private loadTrigger$ = new Subject<void>()
  private subscription: Subscription = new Subscription()

  private routeSubscription: any = null

  public monthOptions: any = []
  public yearOptions = [
    { label: '2021', value: '2021' },
    { label: '2022', value: '2022' },
    { label: '2023', value: '2023' },
    { label: '2024', value: '2024' },
  ]

  constructor(
    private route: ActivatedRoute,
    private toastService: ToastService,
    public authService: AuthService,
    private eventbus: EventBusService,
    private dialogService: DialogService,
    private helperService: HelperService,
    private documentService: DocumentService,
    private caregiverOverviewService: CaregiverOverviewService
  ) {
    dayjs.locale('de')
    dayjs.extend(customParseFormat)
    dayjs.extend(utc)
  }

  ngOnInit(): void {
    this.activateDataLoading()

    const date = new Date()
    this.selectedYear = date.getFullYear()
    this.selectedMonth = date.getMonth()

    this.helperService.dependencies$.subscribe((data: any) => {
      this.monthOptions = data.months
      this.month = this.monthOptions[this.selectedMonth].label
      this.year = this.selectedYear.toString()
    })

    this.loadData()

    this.listenForEventbus()
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe()
    this.eventBusSubscription.unsubscribe()
    this.routeSubscription && this.routeSubscription.unsubscribe()
  }

  public setActiveRow(type: string): void {
    this.activeRow = type
  }

  public setHover(type: string): void {
    this.currentHover = type
  }

  private listenForEventbus(): void {
    this.eventBusSubscription = this.eventbus.subject.subscribe(
      (event: GlobalEvent) => {
        switch (event) {
          case GlobalEvent.CaregiverOverviewChanged:
            this.loadData(false)
        }
      }
    )
  }

  public filterTableGlobal(event: any): void {
    this.table && this.table.filterGlobal(event.target.value.trim(), 'contains')
  }

  /**
   * Springt zum letzten Monat.
   */
  public goToPreviousMonth(): void {
    if (this.selectedMonth === 0) {
      const lastYear = this.selectedYear - 1

      const hasYearOption = this.yearOptions.find(
        (data: any) => data.value == lastYear
      )

      if (hasYearOption) {
        this.selectedYear = lastYear
        this.selectedMonth = 11
      }
    } else {
      this.selectedMonth--
    }

    this.year = this.selectedYear.toString()
    this.month = this.monthOptions[this.selectedMonth].label

    this.loadData(false)
  }

  /**
   * Springt zum nächsten Monat.
   */
  public goToNextMonth(): void {
    if (this.selectedMonth === 11) {
      const nextYear = this.selectedYear + 1

      const hasYearOption = this.yearOptions.find(
        (data: any) => data.value == nextYear
      )

      if (hasYearOption) {
        this.selectedYear = nextYear
        this.selectedMonth = 0
      }
    } else {
      this.selectedMonth++
    }

    this.year = this.selectedYear.toString()
    this.month = this.monthOptions[this.selectedMonth].label

    this.loadData(false)
  }

  public openCheckCommentDialog(
    data: any,
    nextRowData: any,
    comments: any,
    type: string
  ): void {
    this.dialogService.open(AddCaregiverOverviewCheckCommentDialogComponent, {
      header: 'Kommentar hinzufügen',
      width: '720px',
      styleClass: 'dialog-container',
      data: {
        caregiver_id: this.caregiverId,
        row: data,
        next_row: nextRowData,
        comments,
        type,
      },
    })
  }

  public openFeedbackDialog(data: any): void {
    this.dialogService.open(AppointmentFeedbackDialogComponent, {
      header: 'Feedback',
      width: '520px',
      styleClass: 'dialog-container',
      data: {
        ...data,
        readonly: true,
      },
      dismissableMask: true,
    })
  }

  public openChangeFeedbackDialog(
    data: any,
    type: 'time' | 'drive_time'
  ): void {
    this.dialogService.open(AppointmentChangeFeedbackDialogComponent, {
      header: 'Kommentar',
      width: '520px',
      styleClass: 'dialog-container',
      data: {
        ...data,
        type,
        readonly: true,
      },
      dismissableMask: true,
    })
  }

  public openTripsDialog(data: any): void {
    this.dialogService.open(AppointmentTripsDialogComponent, {
      header: 'Zusätzliche Fahrten',
      width: '520px',
      styleClass: 'dialog-container',
      data: {
        ...data,
        readonly: true,
      },
      dismissableMask: true,
    })
  }

  public generateDriveTimeDocument(withIll = false): void {
    if (withIll) {
      this.pdfIllIsGenerating = true
    } else {
      this.pdfIsGenerating = true
    }

    this.caregiverOverviewService
      .generateDriveTimeDocument(
        this.caregiverId,
        this.month,
        this.year,
        withIll
      )
      .subscribe(
        (uuid: string) => {
          window.open(
            this.documentService.getDocumentDownloadLink(uuid),
            '_blank'
          )
          this.pdfIsGenerating = false
          this.pdfIllIsGenerating = false
        },
        () => {
          this.toastService.error(
            'Etwas ist schiefgelaufen...',
            'Bitte wenden Sie sich an den Support'
          )

          this.pdfIsGenerating = false
          this.pdfIllIsGenerating = false
        }
      )
  }

  public toggleCheckMode(): void {
    this.checkIsActivated = !this.checkIsActivated
  }

  public loadData(withLoading: boolean = true): void {
    const foundMonth = this.monthOptions.findIndex((month: any) => {
      return month.label === this.month
    })

    if (foundMonth != null) {
      this.selectedMonth = foundMonth
    }

    this.selectedYear = +this.year

    this.routeSubscription && this.routeSubscription.unsubscribe()

    if (withLoading) {
      this.loading = true
    }

    if (this.route.parent?.parent) {
      this.routeSubscription = this.route.parent.parent.paramMap.subscribe(
        (params: ParamMap) => {
          this.contentLoading = true

          this.caregiverId = params.get('id')

          this.loadTrigger$.next()
        }
      )
    }
  }

  private activateDataLoading(): void {
    this.subscription.add(
      this.loadTrigger$
        .pipe(
          switchMap(() =>
            this.caregiverOverviewService.list(
              this.caregiverId,
              this.month,
              this.year
            )
          )
        )
        .subscribe((list: any) => {
          this.listData = list.list_data
          this.hoursTotal = list.hours_total
          this.totalPlannings = list.total_plannings
          this.hoursGoogle = list.hours_google
          this.hoursGoogleForHome = list.hours_google_for_home
          this.hoursGoogleFull = list.hours_google_full
          this.driveMeters = list.drive_meters
          this.driveMetersForHome = list.drive_meters_for_home
          this.driveMetersFull = list.drive_meters_full

          this.contentLoading = false
          this.loading = false
        })
    )
  }
}
