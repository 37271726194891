import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { environment } from '../../environments/environment'
import { Observable } from 'rxjs'
import { PatientModel } from '../models/customer-patient/patient.model'
import { CustomerDetailModel } from '../models/customer-patient/customer-detail.model'
import { CustomerDetailGeneralModel } from '../models/customer-patient/customer-detail-general.model'
import { PatientDetailHistoryModel } from '../models/customer-patient/patient-detail-history.model'

@Injectable({
  providedIn: 'root',
})
export class UserMultiplierService {
  constructor(private http: HttpClient) {}

  public loadPatients(): Observable<PatientModel[]> {
    return this.http.get<PatientModel[]>(
      `${environment.api}/user-multiplier/patients`
    )
  }

  public getPersplanMonth(
    patientId: string,
    budgetMonth: string,
    budgetYear: string,
    withFunction: boolean
  ): Observable<string> {
    return this.http.get(
      `${environment.api}/user-multiplier/patients/${patientId}/budget-month`,
      {
        responseType: 'text',
        params: {
          budgetMonth,
          budgetYear,
          withFunction: withFunction.toString(),
        },
      }
    )
  }

  public history(id: string | null): Observable<PatientDetailHistoryModel> {
    return this.http.get<PatientDetailHistoryModel>(
      `${environment.api}/user-multiplier/patients/${id}/history`
    )
  }

  public loadPatient(
    id: string | null
  ): Observable<CustomerDetailGeneralModel> {
    return this.http.get<CustomerDetailGeneralModel>(
      `${environment.api}/user-multiplier/patients/${id}`
    )
  }

  public loadPatientDetails(
    id: string | null
  ): Observable<CustomerDetailModel> {
    return this.http.get<CustomerDetailModel>(
      `${environment.api}/user-multiplier/patients/${id}/details`
    )
  }
}
