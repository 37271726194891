import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core'
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog'
import { EventBusService, GlobalEvent } from 'src/app/services/eventbus.service'
import { ToastService } from 'src/app/services/toast.service'
import { SendOfferService } from '../../../services/send-offer.service'
import { Subscription } from 'rxjs'
import { NgForm } from '@angular/forms'
import { DocumentUuidModel } from '../../../models/document/document-uuid.model'
import { DocumentService } from '../../../services/document.service'
import { PatientModel } from '../../../models/customer-patient/patient.model'

@Component({
  selector: 'app-send-offer-dialog',
  templateUrl: './send-dlv-dialog.component.html',
})
export class SendDlvDialogComponent implements OnInit, OnDestroy {
  @ViewChild('form', { static: true }) form!: NgForm

  private formSubscription: Subscription | null | undefined = null
  private isDirty = false

  public values = {
    sending_type: '',
    type: 'email',

    text_end_dlv_anschreiben: '',

    receiver: '',
    cc: [],
    patient_id: '',
    send_documents: {
      offer: true,
      offer_anschreiben: true,
      dlv: true,
      dlv_anschreiben: true,
      kundenaufnahmebogen: true,
      kundeninformation: true,
      entbindung: true,
      abtretung: true,
      sepa: true,
    },
  }

  public clickedOnPreview = false
  public clickedOnPreviewOffer = false
  public data: any = {}
  public receiverOptions: any[] = []
  public ccOptions: any[] = []
  public patientOptions: any[] = []
  public documentUuids = new DocumentUuidModel()

  documentsCreated = false
  submitted = false
  submittedDelete = false

  constructor(
    private ref: DynamicDialogRef,
    private config: DynamicDialogConfig,
    public documentService: DocumentService,
    public sendOfferService: SendOfferService,
    private eventbus: EventBusService,
    private toastService: ToastService
  ) {}

  public ngOnInit(): void {
    this.data = this.config.data

    this.values.patient_id = this.data.patient_id

    this.formSubscription = this.form.valueChanges?.subscribe(() => {
      if (!this.form.pristine) {
        this.isDirty = true
      }
    })

    this.buildReceiverOptions()
    this.buildCCOptions()
    this.buildPatientOptions()
  }

  ngOnDestroy(): void {
    this.formSubscription?.unsubscribe()
  }

  // Wenn Empfänger ausgewählt wird, muss der Endtext noch angepasst werden.
  public receiverChanged(event: any): void {
    if (this.values.receiver.includes('ap')) {
      const patient = this.patientOptions.find(
        (patient: any) => patient.id === this.values.patient_id
      )

      this.values.text_end_dlv_anschreiben = `Um die oben genannten Unterlagen nochmal zu besprechen, aber auch Sie und den Haushalt von ${patient.full_name} kennenzulernen, werden wir Sie zeitnah telefonisch kontaktieren und einen persönlichen Besuch vereinbaren. Für diesen umfangreichen Beratungs- und Einrichtungstermin berechnen wir pauschal 2 Stunden.`
    } else {
      this.values.text_end_dlv_anschreiben =
        'Um die oben genannten Unterlagen nochmal zu besprechen, aber auch Sie und Ihren Haushalt kennenzulernen, werden wir Sie zeitnah telefonisch kontaktieren und einen persönlichen Besuch vereinbaren. Für diesen umfangreichen Beratungs- und Einrichtungstermin berechnen wir pauschal 2 Stunden.'
    }
  }

  public save(): void {
    if (!this.form.form.valid) {
      this.submitted = false
      this.form.form.markAllAsTouched()
      return
    }

    this.submitted = true

    this.sendOfferService.send(this.values).subscribe(
      (documentUuids: DocumentUuidModel) => {
        this.documentUuids = documentUuids

        this.documentsCreated = true
        this.submitted = false
        this.eventbus.emit(GlobalEvent.OfferSent)

        if (this.values.type === 'email') {
          this.ref.close()

          this.toastService.success(
            'Angebot versendet',
            'Das Angebot wurde erfolgreich versendet'
          )
        } else {
          this.toastService.success(
            'Angebot erstellt',
            'Das Angebot wurde erfolgreich erstellt'
          )
        }
      },
      () => {
        this.toastService.error(
          'Etwas ist schiefgelaufen...',
          'Bitte wenden Sie sich an den Support'
        )

        this.submitted = false
      }
    )
  }

  public isGKV(): boolean {
    if (this.data.first_patient.id === this.values.patient_id) {
      return this.data.first_patient.insured_via.includes('GKV')
    }

    if (
      this.data.second_patient &&
      this.data.second_patient.id === this.values.patient_id
    ) {
      return this.data.second_patient.insured_via.includes('GKV')
    }

    return false
  }

  public hasPersplanId(): boolean {
    if (this.data.first_patient.id === this.values.patient_id) {
      return this.data.first_patient.persplan_id
    }

    if (
      this.data.second_patient &&
      this.data.second_patient.id === this.values.patient_id
    ) {
      return this.data.second_patient.persplan_id
    }

    return false
  }

  private buildPatientOptions(): void {
    this.patientOptions.push({
      id: this.data.first_patient.id,
      full_name: this.data.first_patient.full_name,
    })

    if (this.data.second_patient) {
      this.patientOptions.push({
        id: this.data.second_patient.id,
        full_name: this.data.second_patient.full_name,
      })
    }
  }

  public buildReceiverOptions(): void {
    this.documentUuids = new DocumentUuidModel()

    this.receiverOptions = []
    this.values.receiver = ''

    this.receiverOptions.push({
      value: '',
      label: 'Bitte auswählen',
      email: '',
      disabled: true,
    })

    this.receiverOptions.push({
      value: `patient_${this.data.first_patient.id}_`,
      email: this.data.first_patient.email || 'Keine E-Mail',
      label: `${this.data.first_patient.full_name} - Patient`,
      disabled: this.values.type === 'email' && !this.data.first_patient.email,
    })

    if (this.data.second_patient) {
      this.receiverOptions.push({
        value: `patient_${this.data.second_patient.id}_`,
        email: this.data.second_patient.email || 'Keine E-Mail',
        label: `${this.data.second_patient.full_name} - Patient`,
        disabled:
          this.values.type === 'email' && !this.data.second_patient.email,
      })
    }

    // Der AP kann 2 verschiedene Mails haben.
    for (let contactPerson of this.data.customer.contact_persons) {
      if (contactPerson.email_private) {
        this.receiverOptions.push({
          value: `ap_${contactPerson.id}_private`,
          email: `Private E-Mail: ${contactPerson.email_private}`,
          label: `${contactPerson.full_name} - AP`,
          disabled: false,
        })

        // Bei Postversand soll der AP soll nur einmal in der Liste angezeigt
        // werden, selbst wenn er noch eine Geschäftliche E-Mail Adresse hat.
        if (this.values.type === 'post') {
          continue
        }
      }

      if (contactPerson.email_work) {
        this.receiverOptions.push({
          value: `ap_${contactPerson.id}_work`,
          email: `Geschäftliche E-Mail: ${contactPerson.email_work}`,
          label: `${contactPerson.full_name} - AP`,
          disabled: false,
        })
      }

      // Wenn gar keine E-Mail hinterlegt ist.
      if (!contactPerson.email_private && !contactPerson.email_work) {
        this.receiverOptions.push({
          value: `ap_${contactPerson.id}_`,
          email: 'Keine E-Mail',
          label: `${contactPerson.full_name} - AP`,
          disabled: this.values.type === 'email',
        })
      }
    }
  }

  public buildCCOptions(): void {
    this.ccOptions = []

    this.ccOptions.push({
      value: this.data.first_patient.email,
      email: this.data.first_patient.email || 'Keine E-Mail',
      label: `${this.data.first_patient.full_name} - Patient`,
      disabled: !this.data.first_patient.email,
    })

    if (this.data.second_patient) {
      this.ccOptions.push({
        value: this.data.second_patient.email,
        email: this.data.second_patient.email || 'Keine E-Mail',
        label: `${this.data.second_patient.full_name} - Patient`,
        disabled: !this.data.second_patient.email,
      })
    }

    // Der AP kann 2 verschiedene Mails haben.
    for (let contactPerson of this.data.customer.contact_persons) {
      if (contactPerson.email_private) {
        this.ccOptions.push({
          value: contactPerson.email_private,
          email: `Private E-Mail: ${contactPerson.email_private}`,
          label: `${contactPerson.full_name} - AP - Private E-Mail`,
          disabled: false,
        })
      }

      if (contactPerson.email_work) {
        this.ccOptions.push({
          value: contactPerson.email_work,
          email: `Geschäftliche E-Mail: ${contactPerson.email_work}`,
          label: `${contactPerson.full_name} - AP - Geschäftliche E-Mail`,
          disabled: false,
        })
      }

      // Wenn gar keine E-Mail hinterlegt ist.
      if (!contactPerson.email_private && !contactPerson.email_work) {
        this.ccOptions.push({
          value: null,
          email: 'Keine E-Mail',
          label: `${contactPerson.full_name} - AP - Keine E-Mail`,
          disabled: true,
        })
      }
    }
  }
}
