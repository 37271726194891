import { Component, ElementRef, OnInit, ViewChild } from '@angular/core'
import { TitleService } from 'src/app/services/title.service'
import { MultiplierService } from '../../../services/multiplier.service'
import { MultiplierListModel } from '../../../models/multiplier/multiplier-list.model'
import { PhoneCallService } from '../../../services/phone-call.service'
import { AuthService } from '../../../services/auth.service'

@Component({
  selector: 'app-multipliers-list',
  templateUrl: './multipliers-list.component.html',
})
export class MultipliersListComponent implements OnInit {
  @ViewChild('dt') table: ElementRef | any = null

  public isSuperAdmin = false
  public firstPage = 0

  public loading = false
  public multipliers: MultiplierListModel[] = []
  public globalFilterValue = ''

  constructor(
    public phoneCallService: PhoneCallService,
    private multiplierService: MultiplierService,
    private authService: AuthService,
    private titleService: TitleService
  ) {}

  ngOnInit(): void {
    this.isSuperAdmin = this.authService.isSuperAdmin()

    this.loadMultipliers()
    this.titleService.setTitle('Multiplikatoren')
  }

  public filterTableGlobal(event: any): void {
    this.table && this.table.filterGlobal(event.target.value.trim(), 'contains')
  }

  public loginUsingId(id: number): void {
    if (!id) {
      alert('Noch kein Account aktiv')
      return
    }

    if (window.confirm('Wirklich als Benutzer einloggen?')) {
      this.authService.setImpersonation()

      this.authService.loginUsingId(id).subscribe(() => {
        window.location.href = '/'
      })
    }
  }

  private loadMultipliers(): void {
    this.loading = true

    this.multiplierService.list().subscribe((data: MultiplierListModel[]) => {
      this.multipliers = data

      this.loading = false

      setTimeout(() => {
        if (this.table) {
          const totalItems = this.table._totalRecords

          this.globalFilterValue = this.table.filters.global?.value ?? ''

          if (this.table._first > totalItems || this.table._rows > totalItems) {
            this.firstPage = 0
          }
        }
      })
    })
  }
}
