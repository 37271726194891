import { Component, OnDestroy, OnInit } from '@angular/core'
import { CaregiverService } from '../../../../../services/caregivers.service'
import { ActivatedRoute, ParamMap } from '@angular/router'
import { DialogService } from 'primeng/dynamicdialog'
import {
  EventBusService,
  GlobalEvent,
} from '../../../../../services/eventbus.service'
import { Subscription } from 'rxjs'
import {
  CaregiverProofGroupedModel,
  CaregiverProofModel,
} from '../../../../../models/caregiver/caregiver-proof.model'
import { AddCaregiverProofDialogComponent } from '../../../../../components/dialogs/add-caregiver-proof-dialog/add-caregiver-proof-dialog.component'
import { MediaModel } from '../../../../../models/document/media.model'
import { DocumentService } from '../../../../../services/document.service'

@Component({
  selector: 'app-caregivers-detail-proofs',
  templateUrl: './caregivers-detail-proofs.component.html',
  providers: [DialogService],
})
export class CaregiversDetailProofsComponent implements OnInit, OnDestroy {
  public loading = true
  public proofs: CaregiverProofGroupedModel = new CaregiverProofGroupedModel()
  public keys = Object.keys
  public caregiverId: string | null = null
  public openedAccordions: any = {}

  private eventBusSubscription: Subscription = new Subscription()
  private routeSubscription: any = null

  constructor(
    public caregiverService: CaregiverService,
    private route: ActivatedRoute,
    private eventbus: EventBusService,
    private documentService: DocumentService,
    private dialogService: DialogService
  ) {}

  ngOnInit(): void {
    this.loadProofs()
    this.listenForEventbus()
  }

  ngOnDestroy(): void {
    this.eventBusSubscription.unsubscribe()
    this.routeSubscription && this.routeSubscription.unsubscribe()
  }

  private listenForEventbus(): void {
    this.eventBusSubscription = this.eventbus.subject.subscribe(
      (event: GlobalEvent) => {
        switch (event) {
          case GlobalEvent.CaregiverProofChanged:
            this.loadProofs(false)
        }
      }
    )
  }

  public openZipDownload(key: string): void {
    const [month, year] = key.split(' ')

    window.open(
      this.documentService.getZipOfProofsLink(this.caregiverId, month, year)
    )
  }

  public openDownloadTab(document: MediaModel): void {
    window.open(this.caregiverService.getDocumentDownloadLink(document.uuid))
  }

  public openProofDialog(): void {
    this.dialogService.open(AddCaregiverProofDialogComponent, {
      header: 'Neue Leistungsnachweise',
      width: '450px',
      styleClass: 'dialog-container',
      data: {
        caregiver_id: this.caregiverId,
      },
    })
  }

  public loadProofs(withLoading: boolean = true): void {
    if (withLoading) {
      this.loading = true
    }

    if (this.route.parent?.parent) {
      this.routeSubscription = this.route.parent.parent.paramMap.subscribe(
        (params: ParamMap) => {
          this.caregiverId = params.get('id')

          this.caregiverService
            .proofs(this.caregiverId)
            .subscribe((data: CaregiverProofGroupedModel) => {
              this.proofs = data

              if (withLoading) {
                this.loading = false
              }
            })
        }
      )
    }
  }
}
