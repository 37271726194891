import { UserSystemModel } from './user-system.model'
import { CaregiverDetailModel } from '../caregiver/caregiver-detail.model'
import {MultiplierDetailModel} from "../multiplier/multiplier-detail.model";

export class AuthModel {
  id: null | number = null
  name = ''
  email = ''
  super_admin = false
  type = ''
  initial_password_changed = false
  is_user_system = false
  is_caregiver = false
  is_multiplier = false
  user_system: UserSystemModel = new UserSystemModel()
  caregiver: CaregiverDetailModel = new CaregiverDetailModel()
  multiplier: MultiplierDetailModel = new MultiplierDetailModel()
}

export class AuthSettingModel {
  id: null | number = null
  name = ''
  email = ''
  old_password = ''
  password = ''
  password_confirmation = ''
  is_user_system = false
  is_caregiver = false
  is_multiplier = false
  user_system: UserSystemModel = new UserSystemModel()
  caregiver: CaregiverDetailModel = new CaregiverDetailModel()
}
