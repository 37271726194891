import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { environment } from '../../environments/environment'
import { Observable } from 'rxjs'
import { CaregiverTimeDetailResponseModel } from '../models/caregiver/caregiver-time-detail-response.model'

@Injectable({
  providedIn: 'root',
})
export class CaregiverTimeService {
  constructor(private http: HttpClient) {}

  public store(
    caregiverId: string | null,
    worksOnHolidays: boolean,
    data: any
  ): Observable<any> {
    return this.http.post<any>(`${environment.api}/times/${caregiverId}`, {
      ...data,
      worksOnHolidays,
    })
  }

  public load(
    caregiverId: string | null
  ): Observable<CaregiverTimeDetailResponseModel> {
    return this.http.get<CaregiverTimeDetailResponseModel>(
      `${environment.api}/times/${caregiverId}`
    )
  }
}
