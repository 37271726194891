import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core'
import {
  DialogService,
  DynamicDialogConfig,
  DynamicDialogRef,
} from 'primeng/dynamicdialog'
import { EventBusService, GlobalEvent } from 'src/app/services/eventbus.service'
import { ToastService } from 'src/app/services/toast.service'
import { NgForm } from '@angular/forms'
import { Subscription } from 'rxjs'
import { PatientService } from '../../../services/patient.service'
import { SearchService } from '../../../services/search.service'
import { SearchResultModel } from '../../../models/search/search-result.model'
import { SelectItem } from 'primeng/api'
import * as dayjs from 'dayjs'
import * as currency from 'currency.js'
import { SendLetterService } from '../../../services/send-letter.service'
import { DocumentService } from '../../../services/document.service'
import { AddDocumentDialogComponent } from '../add-document-dialog/add-document-dialog.component'
import { DocumentModel } from '../../../models/document/document.model'
import { MediaModel } from '../../../models/document/media.model'
import { AccountingReturnPositionModel } from '../../../models/accounting/accounting-return-position.model'

@Component({
  selector: 'app-send-letter-dialog',
  templateUrl: './send-letter-dialog.component.html',
})
export class SendLetterDialogComponent implements OnInit, OnDestroy {
  @ViewChild('form', { static: true }) form!: NgForm

  private formSubscription: Subscription | null | undefined = null
  private isDirty = false

  submitted = false
  submittedDelete = false

  public optionsJodit = {
    toolbar: false,
    statusbar: false,
    addNewLine: false,
    enter: 'br' as any,
    language: 'de',
    showBrowserColorPicker: false,
    allowResizeTags: [],
    table: {
      allowCellResize: false
    }
  }

  public fromOtherDialog = false

  public documentLink = ''
  public clickedOnPreview = false
  public receiverOptions: any[] = []
  public documentOptions: any[] = []
  public ccOptions: any[] = []
  public options: SelectItem[] = []

  public savedLetterId = 0
  public savedLetter: any = null

  public values = {
    letter_type: '',
    type: 'email',
    without_fee: null as any,
    receiver: '',
    patient: '' as any,
    cc: [],
    documents: [] as any,
    payment_for: '',
    subject: 'Abrechnungsmitteilung für den Monat: ',
    case_number: '',
    text: '',
    from_other_dialog_type: '',
    from_other_dialog_media_uuid: '',
    from_other_dialog_temp_image_name: '',
  }
  public htmlTables = {
    mahnung_word: '',
    mahnung_email: '',
    ruecklaeufer_word: '',
    ruecklaeufer_email: '',
  }

  public caregivers: SearchResultModel[] = []
  public patients: SearchResultModel[] = []
  public data: any
  public response: any

  constructor(
    private dialogService: DialogService,
    public documentService: DocumentService,
    private ref: DynamicDialogRef,
    private config: DynamicDialogConfig,
    private searchService: SearchService,
    private patientService: PatientService,
    public sendLetterService: SendLetterService,
    private eventbus: EventBusService,
    private toastService: ToastService
  ) {}

  public ngOnInit(): void {
    this.data = this.config.data

    this.formSubscription = this.form.valueChanges?.subscribe(() => {
      if (!this.form.pristine) {
        this.isDirty = true
      }
    })

    this.values.payment_for = dayjs().add(14, 'days').format('DD.MM.YYYY')

    this.loadHtmlTables()
    this.buildOptions()
  }

  ngOnDestroy(): void {
    this.formSubscription?.unsubscribe()
  }

  public searchPatients(event: any): void {
    this.searchService
      .findPatients(event.query.trim())
      .subscribe((results: SearchResultModel[]) => {
        this.patients = results
      })
  }

  public getReceivers(): void {
    this.setText()
    this.replacePlaceholders()
    this.values.receiver = ''
    this.values.documents = []
    this.values.cc = []
    this.receiverOptions = []
    this.ccOptions = []

    if (!this.values.patient) {
      return
    }

    this.patientService
      .getReceivers(this.values.patient.id)
      .subscribe((response: any) => {
        this.response = response

        this.buildReceiverOptions(response)
        this.buildCCOptions(response)
      })

    this.getDocuments()
  }

  public removeDocument(document: any): void {
    this.values.documents = this.values.documents.filter((doc: any) => {
      return doc.uuid !== document.uuid
    })
  }

  public openAddDocumentDialog(): void {
    const ref = this.dialogService.open(AddDocumentDialogComponent, {
      data: {
        type_id: this.values.patient.id,
        patients: this.response,
        contact_persons: this.response.contact_persons,
        type: 'patients',
      },
      header: 'Dokument erstellen',
      styleClass: 'dialog-container',
      dismissableMask: false,
      width: '450px',
    })

    ref.onClose.subscribe((document: any) => {
      if (document) {
        // Es kann passieren, dass mehrere Dokumente generiert werden (z.B. 2x DLV für Intern und Kunde).
        if (Array.isArray(document.media)) {
          for (const media of document.media) {
            this.values.documents.push({
              name: media.name,
              uuid: media.uuid,
            })
          }
        } else {
          this.values.documents.push({
            name: document.media.name,
            uuid: document.media.uuid,
          })
        }
      }
    })
  }

  private getDocuments(): void {
    this.patientService
      .documents(this.values.patient.id)
      .subscribe((response: any) => {
        this.documentOptions = Object.values(response.first_patient.types)

        // Wir Filtern uns hier nur die Hochgeladenen Dokumente raus.
        this.documentOptions.forEach((document: DocumentModel) => {
          document.documents = document.documents.filter((doc: MediaModel) => {
            return doc.custom_properties.upload
          })
        })
      })
  }

  public buildReceiverOptions(response: any): void {
    this.receiverOptions.push({
      value: `patient_${response.first_patient.id}_`,
      email: response.first_patient.email || 'Keine E-Mail',
      label: `${response.first_patient.full_name} - Patient`,
      disabled: this.values.type === 'email' && !response.first_patient.email,
    })

    if (response.second_patient) {
      this.receiverOptions.push({
        value: `patient_${response.second_patient.id}_`,
        email: response.second_patient.email || 'Keine E-Mail',
        label: `${response.second_patient.full_name} - Patient`,
        disabled:
          this.values.type === 'email' && !response.second_patient.email,
      })
    }

    for (const contactPerson of response.contact_persons) {
      if (contactPerson.email_private) {
        this.receiverOptions.push({
          value: `ap_${contactPerson.id}_private`,
          email: `Private E-Mail: ${contactPerson.email_private}`,
          label: `${contactPerson.full_name} - AP`,
          disabled: false,
        })

        // Bei Postversand soll der AP soll nur einmal in der Liste angezeigt
        // werden, selbst wenn er noch eine Geschäftliche E-Mail Adresse hat.
        if (this.values.type === 'post') {
          continue
        }
      }

      if (contactPerson.email_work) {
        this.receiverOptions.push({
          value: `ap_${contactPerson.id}_work`,
          email: `Geschäftliche E-Mail: ${contactPerson.email_work}`,
          label: `${contactPerson.full_name} - AP`,
          disabled: false,
        })
      }

      // Wenn gar keine E-Mail hinterlegt ist.
      if (!contactPerson.email_private && !contactPerson.email_work) {
        this.receiverOptions.push({
          value: `ap_${contactPerson.id}_`,
          email: 'Keine E-Mail',
          label: `${contactPerson.full_name} - AP`,
          disabled: this.values.type === 'email',
        })
      }
    }
  }

  public buildCCOptions(response: any): void {
    this.ccOptions.push({
      value: response.first_patient.email,
      email: response.first_patient.email || 'Keine E-Mail',
      label: `${response.first_patient.full_name} - Patient`,
      disabled: !response.first_patient.email,
    })

    if (response.second_patient) {
      this.ccOptions.push({
        value: response.second_patient.email,
        email: response.second_patient.email || 'Keine E-Mail',
        label: `${response.second_patient.full_name} - Patient`,
        disabled: !response.second_patient.email,
      })
    }

    for (const contactPerson of response.contact_persons) {
      if (contactPerson.email_private) {
        this.ccOptions.push({
          value: contactPerson.email_private,
          email: `Private E-Mail: ${contactPerson.email_private}`,
          label: `${contactPerson.full_name} - AP - Private E-Mail`,
          disabled: false,
        })
      }

      if (contactPerson.email_work) {
        this.ccOptions.push({
          value: contactPerson.email_work,
          email: `Geschäftliche E-Mail: ${contactPerson.email_work}`,
          label: `${contactPerson.full_name} - AP - Geschäftliche E-Mail`,
          disabled: false,
        })
      }

      // Wenn gar keine E-Mail hinterlegt ist.
      if (!contactPerson.email_private && !contactPerson.email_work) {
        this.ccOptions.push({
          value: null,
          email: 'Keine E-Mail',
          label: `${contactPerson.full_name} - AP - Keine E-Mail`,
          disabled: true,
        })
      }
    }
  }

  public documentSelected(event: any): void {
    this.values.documents.push({
      name: event.value.name,
      uuid: event.value.uuid,
    })
  }

  public setText(): void {
    if (this.values.letter_type === 'mahnung') {
      this.values.documents = []

      this.values.text =
        this.values.type === 'email'
          ? this.htmlTables.mahnung_email
          : this.htmlTables.mahnung_word
    }

    if (this.values.letter_type === 'rückläufer') {
      this.values.documents = []

      this.values.text =
        this.values.type === 'email'
          ? this.htmlTables.ruecklaeufer_email
          : this.htmlTables.ruecklaeufer_word
    }

    if (this.values.letter_type === 'opta_data') {
      this.setDefaultDocumentsForOptaData()
      this.values.text = ''
    }
  }

  private setDefaultDocumentsForOptaData(): void {
    for (const documentType of this.documentOptions) {
      for (const document of documentType.documents) {
        // Wir brauchen nur ein paar Dokumente.
        if (
          !['Abretung', 'DLV', 'Leistungsnachweis'].includes(documentType.name)
        ) {
          continue
        }

        // Prüfen ob das Dokument hochgeladen wurde, und nicht generiert.
        if (document.custom_properties.upload) {
          this.values.documents.push({
            name: document.name,
            uuid: document.uuid,
          })
        }
      }
    }
  }

  private loadHtmlTables(): void {
    this.sendLetterService.getHtmlTables().subscribe((tables: any) => {
      this.htmlTables = tables

      // Sobald der Briefdialog von einem anderen Dialog aus geöffnet wurde,
      // tragen wir die übergebenen Werte in die passenden Felder.
      if (this.data.from_other_dialog) {
        this.fromOtherDialog = this.data.from_other_dialog
        this.values.patient = this.data.values.patient
        this.values.letter_type = this.data.type
        this.values.case_number = this.data.values.case_number
        this.values.subject += `${this.data.values.performance_month} ${this.data.values.performance_year}`

        this.values.from_other_dialog_type = this.data.type
        this.values.from_other_dialog_temp_image_name = this.data.values.temp_image_name
        this.values.from_other_dialog_media_uuid = this.data.values.media.length
          ? this.data.values.media[0].uuid
          : ''

        this.setText()
        this.getReceivers()
      }
    })
  }

  private replacePlaceholders(): void {
    if (this.data.from_other_dialog) {
      // Wir ersetzen nun die Platzhalter mit den richtigen Werten.
      // Die Platzhalter stehen in den Blade Templates.
      this.values.text = this.values.text
        .replace('???A', this.data.values.invoice_number)
        // Wir entfernen noch das Minus im Betrag für den Platzhalter.
        .replace(
          '???C',
          this.data.values.total_value.replace('-', '').replace('.', ',')
        )

      // Wir laufen die Positionen der Rückmeldung durch und nehmen uns den
      // Wert von "Zahlung Pflegekasse" und ersetzen diesen in den Platzhalter.
      this.data.values.positions.forEach(
        (position: AccountingReturnPositionModel) => {
          if (position.text === 'Zahlung Pflegekasse') {
            const value = position.total_value?.toString() || ''

            this.values.text = this.values.text.replace(
              '???B',
              value.replace('.', ',')
            )
          }
        }
      )

      // Die Summe wird noch berechnet und für den Platzhalter ersetzt.
      const totalValue = this.data.values.total_value.replace('-', '')
      // TODO: Die Bearbeitungsgebühr in eine Config verschieben.
      let processingFee = 18.0

      if (this.data.withoutFee) {
        this.values.without_fee = true

        processingFee = 0
      }

      // Bearbeitungsgebühr.
      this.values.text = this.values.text.replace(
        '???E',
        currency(processingFee).toString().replace('.', ',')
      )

      // Berechnung.
      this.values.text = this.values.text.replace(
        '???D',
        currency(totalValue).add(processingFee).toString().replace('.', ',')
      )
    }
  }

  public downloadAndClose(): void {
    this.eventbus.emit(GlobalEvent.LetterSent)
    this.ref.close(this.savedLetter)
  }

  public save(): void {
    if (!this.form.form.valid) {
      this.submitted = false
      this.form.form.markAllAsTouched()
      return
    }

    this.submitted = true

    this.sendLetterService.send(this.values).subscribe(
      (response: any) => {
        this.submitted = false
        this.savedLetterId = response.letter_id
        this.savedLetter = response.letter

        // Wenn es per POST verschickt wird, bekommen wir die UUID vom Dokument.
        // Wir zeigen dem Benutzer einen Button an damit er das Dokument herunterladen kann.
        if (response.uuid) {
          this.documentLink = this.documentService.getDocumentDownloadLink(
            response.uuid
          )
        } else {
          this.eventbus.emit(GlobalEvent.LetterSent)
          this.ref.close(this.savedLetter)
        }

        if (this.values.type === 'email') {
          this.toastService.success(
            'Brief versendet',
            'Der Brief wurde erfolgreich versendet'
          )
        } else {
          this.toastService.success(
            'Brief erstellt',
            'Der Brief wurde erfolgreich erstellt'
          )
        }
      },
      () => {
        this.toastService.error(
          'Etwas ist schiefgelaufen...',
          'Bitte wenden Sie sich an den Support'
        )

        this.submitted = false
      }
    )
  }

  private buildOptions(): void {
    this.options.push({
      label: 'Mahnung',
      value: 'mahnung',
    })

    this.options.push({
      label: 'Rückläufer',
      value: 'rückläufer',
    })

    this.options.push({
      label: 'OptaData',
      value: 'opta_data',
    })
  }
}
