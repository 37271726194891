export class CaregiverTimeErrorsModel {
  monday_start_first = false
  monday_end_first = false
  monday_start_second = false
  monday_end_second = false
  tuesday_start_first = false
  tuesday_end_first = false
  tuesday_start_second = false
  tuesday_end_second = false
  wednesday_start_first = false
  wednesday_end_first = false
  wednesday_start_second = false
  wednesday_end_second = false
  thursday_start_first = false
  thursday_end_first = false
  thursday_start_second = false
  thursday_end_second = false
  friday_start_first = false
  friday_end_first = false
  friday_start_second = false
  friday_end_second = false
  saturday_start_first = false
  saturday_end_first = false
  saturday_start_second = false
  saturday_end_second = false
}
