import { Component, OnInit, ViewChild } from '@angular/core'
import { ActivatedRoute, ParamMap, Router } from '@angular/router'

import { OfferService } from '../../../services/offer.service'
import { OfferDependenciesModel } from '../../../models/offer/offer-dependencies.model'
import { TitleService } from 'src/app/services/title.service'
import { SearchService } from '../../../services/search.service'
import { BudgetService } from '../../../services/budget.service'
import * as dayjs from 'dayjs'
import * as customParseFormat from 'dayjs/plugin/customParseFormat'
import * as utc from 'dayjs/plugin/utc'
import { HttpErrorResponse } from '@angular/common/http'
import { StatusCodes } from 'http-status-codes'
import { ToastService } from '../../../services/toast.service'
import { Subscription } from 'rxjs'
import { NgForm } from '@angular/forms'
import { DirtyComponent } from '../../../guards/dirty-check.guard'
import { OfferFormModel } from '../../../models/offer/offer-form.model'
import { PatientModel } from '../../../models/customer-patient/patient.model'
import { MultiplierFormModel } from '../../../models/multiplier/multiplier-form.model'
import { DialogService } from 'primeng/dynamicdialog'
import { AuthModel } from '../../../models/user/auth.model'
import { AuthService } from '../../../services/auth.service'
import { ZipcodeSearchResultModel } from '../../../models/search/zipcode-search-result.model'
import { HelperService } from '../../../services/helper.service'
import { CaregiverTimeErrorsModel } from '../../../models/caregiver/caregiver-time-errors.model'
import { SelectItem } from 'primeng/api'
import { SearchResultModel } from '../../../models/search/search-result.model'

@Component({
  selector: 'app-user-caregiver-offer',
  templateUrl: './user-caregiver-offer.component.html',
  styleUrls: ['./user-caregiver-offer.component.scss'],
})
export class UserCaregiverOfferComponent implements OnInit, DirtyComponent {
  @ViewChild('form', { static: true }) form!: NgForm

  public isEditMode = false
  public submitted = false
  public loading = true
  public cityLoading = false
  public customerId: string | null = null
  public formOfAddress: any = []
  public attentionFrom = []
  public times: SelectItem[] = []

  private formSubscription: Subscription | null | undefined = null
  public isDirty = false
  public user: AuthModel | null = new AuthModel()
  public pflegekassen: MultiplierFormModel[] = []

  public caregivers: SearchResultModel[] = []
  public multipliers: SearchResultModel[] = []

  public data: OfferFormModel = new OfferFormModel()
  public dependencies = new OfferDependenciesModel()
  public errors: any = new CaregiverTimeErrorsModel()

  constructor(
    private router: Router,
    private searchService: SearchService,
    private route: ActivatedRoute,
    private authService: AuthService,
    private helperService: HelperService,
    private toastService: ToastService,
    private dialogService: DialogService,
    private budgetService: BudgetService,
    private service: OfferService,
    private titleService: TitleService
  ) {
    dayjs.locale('de')
    dayjs.extend(customParseFormat)
    dayjs.extend(utc)
  }

  ngOnInit(): void {
    document.body.classList.remove('mobile-nav-visible')

    this.user = this.authService.getUser()

    this.loadDependencies()

    this.titleService.setTitle(
      this.isEditMode ? 'Kunde bearbeiten' : 'Kunde erfassen'
    )

    this.formSubscription = this.form.valueChanges?.subscribe(() => {
      // sonst wird isDirty durch das initiale rendern der form gesetzt
      if (!this.form.pristine) {
        this.isDirty = true
      }
    })

    this.helperService.dependencies$.subscribe((data: any) => {
      this.formOfAddress = data.form_of_address
    })

    this.data.offer_type = 'OFFER'
  }

  canDeactivate(): boolean {
    return this.isDirty
  }

  public selectPersonType(type: string): void {
    this.data.patient_type = type

    // Wenn beim bearbeiten B oder C angehakt wird, muss
    // der Default für den zweiten Patienten gefüllt werden.
    // Denn dieser ist NULL wenn er aus der Datenbank
    // kommt und A vorher ausgewählt war.
    if (type !== 'A' && !this.data.second_patient) {
      this.data.second_patient = new PatientModel()
    }
  }

  public searchPflegekassen(event: any): void {
    this.searchService
      .findCareInsurance(event.query.trim())
      .subscribe((results: MultiplierFormModel[]) => {
        this.pflegekassen = results
      })
  }

  /**
   * Prüft ob GKV oder PKV ausgewählt ist.
   */
  public isGkvOrPkvSelected(type: 'first_patient' | 'second_patient'): boolean {
    return (
      this.data[type].insured_via.includes('GKV') ||
      this.data[type].insured_via.includes('PKV')
    )
  }

  /**
   * Prüfung ob nur "Selbstzahler" angehakt wurde.
   */
  public onlySelbstzahlerSelected(
    type: 'first_patient' | 'second_patient'
  ): boolean {
    const selected = this.data[type].insured_via

    if (!selected.length || selected.length > 1) {
      return false
    }

    return selected[0] === 'Selbstzahler'
  }

  public loadCity(type: 'first_patient' | 'contact_person'): void {
    this.cityLoading = true

    this.searchService.findCity(this.data[type].zipcode).subscribe(
      (result: ZipcodeSearchResultModel) => {
        if (result) {
          this.data[type].city = result.city
          this.data[type].county = result.county
          this.data[type].state = result.state
        }

        this.cityLoading = false
      },
      () => {
        this.data[type].city = ''
        this.cityLoading = false
      }
    )
  }

  public calculateBudget(type: 'first_patient' | 'second_patient'): void {
    // Wenn "Beihilfe" angehakt ist, soll auch "Selbstzahler" angehakt werden.
    if (
      this.data[type].insured_via.includes('Beihilfe') &&
      !this.data[type].insured_via.includes('Selbstzahler')
    ) {
      this.data[type].insured_via.push('Selbstzahler')
    }

    // Wenn PKV ausgewählt wird, soll GKV abgewählt werden, und umgekehrt.
    if (this.data[type].insured_via.includes('PKV')) {
      this.data[type].insured_via = this.data[type].insured_via.filter(
        (via: any) => {
          return via !== 'GKV'
        }
      )
    } else if (this.data[type].insured_via.includes('GKV')) {
      this.data[type].insured_via = this.data[type].insured_via.filter(
        (via: any) => {
          return via !== 'PKV'
        }
      )
    }

    // Wenn Pflegegrad I ausgewählt wird, müssen bestimmte Auswahlen entfernt werden.
    if (
      this.data[type].degree_of_care === 'I' &&
      (!this.data[type].has_degree_of_care_old ||
        this.data[type].degree_of_care_old === 'I')
    ) {
      this.data[type].request_45a = ''
      this.data[type].request_42_kzpf = ''
      this.data[type].request_39_vpf = ''
      this.data[type].request_37 = ''
    }

    // Falls GKV oder PKV nicht mehr ausgewählt sind,
    // muss Pflegeperson Auswahl entfernen werden.
    if (
      !this.data[type].insured_via.includes('PKV') &&
      !this.data[type].insured_via.includes('GKV')
    ) {
      this.data[type].nursing_person = ''
      this.data[type].request_45a = ''
      this.data[type].request_42_kzpf = ''
      this.data[type].request_39_vpf = ''
      this.data[type].request_37 = ''
    }

    // Wenn §39 auf Nein gesetzt wird, soll auch die Auswahl für §42 entfernt werden.
    if (this.data[type].request_39_vpf !== 'Ja') {
      this.data[type].request_42_kzpf = ''
    }

    // Wenn Pflegeperson abgewählt wird, muss §39 ebenfalls entfernt werden.
    if (this.data[type].nursing_person !== 'Ja') {
      this.data[type].request_39_vpf = ''
    }

    this.data[
      type
    ].budget_calculations.budget_45b_old = this.budgetService.get45bOld(
      this.data[type].request_45b,
      this.data[type].degree_of_care_since_string,
      this.data[type].degree_of_care,
      this.dependencies
    )

    this.data[
      type
    ].budget_calculations.budget_45b_current = this.budgetService.get45bCurrent(
      this.data[type].request_45b,
      this.data[type].degree_of_care_since_string,
      this.data[type].degree_of_care,
      this.dependencies
    )

    this.data[type].budget_calculations.budget_45a = this.budgetService.get45a(
      this.data[type].degree_of_care,
      this.data[type].request_45a,
      this.dependencies,
      this.isGkvOrPkvSelected('first_patient')
    )

    this.data[type].budget_calculations.budget_37 = this.budgetService.get37(
      this.data[type].degree_of_care,
      this.data[type].nursing_person,
      this.data[type].request_37,
      this.data[type].request_45a,
      this.dependencies
    )

    this.data[type].budget_calculations.budget_39 = this.budgetService.get39(
      this.data[type].degree_of_care,
      this.data[type].degree_of_care_since_string,
      this.data[type].nursing_person,
      this.data[type].request_39_vpf,
      this.dependencies
    )

    this.data[type].budget_calculations.budget_42 = this.budgetService.get42(
      this.data[type].degree_of_care,
      this.data[type].degree_of_care_since_string,
      this.data[type].nursing_person,
      this.data[type].request_42_kzpf,
      this.data[type].request_39_vpf,
      this.dependencies
    )

    // Prüfung für §39, §42 und §45b ob alter Pflegegrad angehakt ist.
    // Der alte PG überschreibt im Prinzip den aktuellen.
    if (
      this.data[type].has_degree_of_care_old &&
      this.data[type].degree_of_care_old &&
      this.data[type].degree_of_care_old_since_string
    ) {
      this.data[
        type
      ].budget_calculations.budget_45b_old = this.budgetService.get45bOld(
        this.data[type].request_45b,
        this.data[type].degree_of_care_old_since_string,
        this.data[type].degree_of_care_old,
        this.dependencies
      )

      this.data[
        type
      ].budget_calculations.budget_45b_current = this.budgetService.get45bCurrent(
        this.data[type].request_45b,
        this.data[type].degree_of_care_old_since_string,
        this.data[type].degree_of_care_old,
        this.dependencies
      )

      // Für §39 und §42 muss geprüft werden ob dieser mindestens PG II ist.
      if (this.data[type].degree_of_care_old !== 'I') {
        this.data[
          type
        ].budget_calculations.budget_39 = this.budgetService.get39(
          this.data[type].degree_of_care_old,
          this.data[type].degree_of_care_old_since_string,
          this.data[type].nursing_person,
          this.data[type].request_39_vpf,
          this.dependencies
        )

        this.data[
          type
        ].budget_calculations.budget_42 = this.budgetService.get42(
          this.data[type].degree_of_care_old,
          this.data[type].degree_of_care_old_since_string,
          this.data[type].nursing_person,
          this.data[type].request_42_kzpf,
          this.data[type].request_39_vpf,
          this.dependencies
        )
      }
    }
  }

  /**
   * Lädt die Daten falls wir im bearbeiten Modus sind.
   */
  private loadDataForEditMode(): void {
    this.loading = true

    this.route.paramMap.subscribe((params: ParamMap) => {
      this.customerId = params.get('id')
      this.isEditMode = params.has('id')

      if (this.isEditMode) {
        this.loadCustomer()
      } else {
        this.loading = false
      }
    })
  }

  private loadCustomer(): void {
    this.service
      .loadForEditMode(this.customerId)
      .subscribe((data: OfferFormModel) => {
        this.data = data

        // Falls eine Empfehlung vorhanden ist, müssen
        // die Frontenddaten korrekt gesetzt werden.
        if (data.recommendation) {
          this.data.recommendation_id = data.recommendation

          if (data.recommendation_type?.includes('Multiplier')) {
            this.data.recommendation_type = 'MULTIPLIER'
          }

          if (data.recommendation_type?.includes('Caregiver')) {
            this.data.recommendation_type = 'CAREGIVER'
          }
        }

        this.loading = false
      })
  }

  /**
   * Lädt alle Abhängigkeiten (Krankheiten, Leistungen, etc) aus der Datenbank.
   */
  public loadDependencies(): void {
    this.service
      .dependencies()
      .subscribe((dependencies: OfferDependenciesModel) => {
        this.dependencies = dependencies

        this.loadDataForEditMode()
      })
  }

  /**
   * Erstellt bzw. updated die Daten.
   */
  public save(): void {
    if (!this.form.form.valid) {
      this.submitted = false
      this.form.form.markAllAsTouched()
      this.toastService.error('Bitte füllen Sie alle Pflichtfelder aus')

      // Durch das timeout scrollt er auch bei div.p-invalid hoch.
      setTimeout(() => {
        const firstElementWithError = document.querySelector(
          'textarea.ng-invalid, input.ng-invalid, select.ng-invalid, div.p-invalid, p-dropdown.ng-invalid'
        )
        if (firstElementWithError) {
          firstElementWithError.scrollIntoView({ behavior: 'smooth' })
        }
      })

      return
    }

    this.submitted = true

    const subscription = this.isEditMode
      ? this.service.update(this.data)
      : this.service.storeFromCaregiver(this.data)

    subscription.subscribe(
      (firstPatient: PatientModel) => {
        this.isDirty = false

        this.toastService.success(
          'Neuer Kunde erstellt',
          'Der neue Kunde wurde erfolgreich erstellt'
        )

        this.router.navigate(['/dienstplan'])
      },
      (error: HttpErrorResponse) => {
        if (error.status === StatusCodes.UNPROCESSABLE_ENTITY) {
          this.toastService.error('Bitte füllen Sie alle Pflichtfelder aus')
        } else {
          this.toastService.error(
            'Etwas ist schief gelaufen...',
            'Bitte wenden Sie sich an den Support'
          )
        }

        this.submitted = false
      }
    )
  }
}
