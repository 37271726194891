<app-loader [loading]="loading"></app-loader>

<div class="content" *ngIf="!loading">
  <header class="content-header">
    <div class="content-header-patients-tabs">
      <span
        (click)="changePatient('first_patient')"
        [class.active]="selectedPatient === 'first_patient'"
      >
        <i
          pTooltip="Terminversand gesperrt"
          class="pi pi-lock color-alert"
          *ngIf="patients.first_patient.appointment_lock"
        ></i>
        <i class="pi pi-user"></i>
        {{ patients.first_patient.full_name }}

        <i
          *ngIf="patients.first_patient.status"
          class="p-tag p-tag-rounded p-tag-{{ patients.first_patient.status }}"
          >{{ patients.first_patient.status_string }}</i
        >

        <i
          *ngIf="patients.first_patient.is_paused"
          class="p-tag p-tag-rounded p-tag-PAUSED"
          >Pausiert</i
        >
      </span>
      <span
        (click)="changePatient('second_patient')"
        [class.active]="selectedPatient === 'second_patient'"
        *ngIf="patients.second_patient"
      >
        <i
          pTooltip="Terminversand gesperrt"
          class="pi pi-lock color-alert"
          *ngIf="patients.second_patient.appointment_lock"
        ></i>
        <i class="pi pi-users"></i>
        {{ patients.second_patient.full_name }}

        <i
          *ngIf="patients.second_patient.status"
          class="p-tag p-tag-rounded p-tag-{{ patients.second_patient.status }}"
          >{{ patients.second_patient.status_string }}</i
        >

        <i
          *ngIf="patients.second_patient.is_paused"
          class="p-tag p-tag-rounded p-tag-PAUSED"
          >Pausiert</i
        >
      </span>
    </div>
    <button
      pButton
      (click)="openVetoDialog()"
      label="Neuer Eintrag"
      type="button"
      class="p-button-outlined"
      icon="pi pi-plus"
      iconPos="left"
    ></button>
  </header>
</div>

<div class="content" style="margin: 0" *ngIf="!loading">
  <div
    class="content-body auto-height"
    *ngIf="patients[selectedPatient].vetos.length === 0"
  >
    <span class="content-no-message">Keine Veto Einträge vorhanden</span>
  </div>
  <div
    class="content-body without-padding auto-height"
    *ngIf="patients[selectedPatient].vetos.length > 0"
  >
    <p-table #dt [value]="patients[selectedPatient].vetos" [rowHover]="true">
      <ng-template pTemplate="header">
        <tr class="table-headline-highlight">
          <th style="min-width: 200px" pSortableColumn="caregiver_id">
            Betreuungskraft <p-sortIcon field="caregiver_id"></p-sortIcon>
          </th>
          <th style="width: 200px" pSortableColumn="user.first_name">
            Erstellt von
            <p-sortIcon field="user.first_name"></p-sortIcon>
          </th>
          <th style="width: 200px" pSortableColumn="created_at">
            Erstellt am <p-sortIcon field="created_at"></p-sortIcon>
          </th>
          <th>Kommentar</th>
          <th style="width: 50px"></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-veto>
        <tr>
          <td style="min-width: 200px">
            <a routerLink="/caregivers/{{ veto.caregiver_id }}">
              {{ veto.caregiver.first_name }} {{ veto.caregiver.last_name }}
            </a>
          </td>
          <td>{{ veto.user.first_name }} {{ veto.user.last_name }}</td>
          <td>{{ veto.created_at | date: "dd.MM.y" }}</td>
          <td>
            <p class="history-text">{{ veto.comment }}</p>
          </td>
          <td style="width: 50px" class="table-action">
            <i
              pTooltip="Bearbeiten"
              tooltipPosition="left"
              (click)="openVetoDialog(veto)"
              class="pi pi-pencil"
            ></i>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
