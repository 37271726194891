<span
  *ngIf="user?.is_user_system"
  (click)="toggleNav()"
  class="nav-switch"
  [pTooltip]="navHidden ? 'Ausklappen' : 'Einklappen'"
  ><i
    class="pi"
    [class.pi-chevron-left]="!navHidden"
    [class.pi-chevron-right]="navHidden"
  ></i
></span>

<!-- Betreuungskraft -->
<nav *ngIf="user?.is_caregiver">
  <i class="pi pi-times" (click)="toggleMobileNav()"></i>

  <div class="nav-items">
    <a
      routerLink="/neuer-kunde"
      routerLinkActive="active"
      [routerLinkActiveOptions]="{ exact: true }"
    >
      <i class="tio">file_add_outlined</i>
      <span>Neuer Kunde</span>
    </a>

    <a
      routerLink="/dienstplan"
      routerLinkActive="active"
      [routerLinkActiveOptions]="{ exact: true }"
    >
      <i class="tio">time</i>
      <span>Dienstplan</span>
    </a>
    <a routerLink="/daten" routerLinkActive="active">
      <i class="tio">explore_outlined</i>
      <span>Meine Daten</span>
    </a>
    <a
      routerLink="/kalender"
      routerLinkActive="active"
      [routerLinkActiveOptions]="{ exact: true }"
    >
      <i class="tio">calendar_month</i>
      <span>Kalender</span>
    </a>
    <a
      routerLink="/auswertungen"
      routerLinkActive="active"
      [routerLinkActiveOptions]="{ exact: true }"
    >
      <i class="tio">chart_donut_2</i>
      <span>Auswertungen</span>
    </a>
    <a
      routerLink="/leistungsnachweise"
      routerLinkActive="active"
      [routerLinkActiveOptions]="{ exact: true }"
    >
      <i class="tio">heart_outlined</i>
      <span>Leistungsnachweise</span>
    </a>
    <a
      routerLink="/dokumente"
      routerLinkActive="active"
      [routerLinkActiveOptions]="{ exact: true }"
    >
      <i class="tio">document_text_outlined</i>
      <span>Dokumente</span>
    </a>
    <a
      routerLink="/empfehlungen"
      routerLinkActive="active"
      [routerLinkActiveOptions]="{ exact: true }"
    >
      <i class="tio">group_equal</i>
      <span>Empfehlungen</span>
    </a>
    <a
      *ngIf="user?.caregiver?.is_mentor"
      routerLink="/hospitation"
      routerLinkActive="active"
      [routerLinkActiveOptions]="{ exact: true }"
    >
      <i class="tio">visible_outlined</i>
      <span>Hospitation</span>
    </a>
  </div>
</nav>

<!-- Multiplikator -->
<nav *ngIf="user?.is_multiplier">
  <i class="pi pi-times" (click)="toggleMobileNav()"></i>

  <div class="nav-items">
    <a
      routerLink="/neu"
      routerLinkActive="active"
      [routerLinkActiveOptions]="{ exact: true }"
    >
      <i class="tio">file_add_outlined</i>
      <span>Neuer Kunde</span>
    </a>

    <a routerLink="/patienten" routerLinkActive="active">
      <i class="tio">group_equal</i>
      <span>Patienten</span>
    </a>
  </div>
</nav>

<!-- Systembenutzer -->
<nav *ngIf="user?.is_user_system">
  <div class="nav-items">
    <a
      [pTooltip]="navHidden ? 'Übersicht' : ''"
      routerLink="/"
      routerLinkActive="active"
      [routerLinkActiveOptions]="{ exact: true }"
    >
      <i class="tio">explore_outlined</i>
      <span>Übersicht</span>
    </a>
    <a
      *ngIf="authService.can('Telefonate.Liste: Telefonate')"
      [pTooltip]="navHidden ? 'Telefonate' : ''"
      routerLink="/phone-calls"
      routerLinkActive="active"
    >
      <i class="tio">call_talking</i>
      <span>Telefonate</span>
    </a>
    <a
      [pTooltip]="navHidden ? 'Todos' : ''"
      routerLink="/todos"
      routerLinkActive="active"
    >
      <i class="tio">checkmark_circle_outlined</i>
      <span>
        Todos

        <p-badge
          *ngIf="amounts.todos"
          [value]="amounts.todos.toString()"
        ></p-badge>

        <p-badge
          severity="info"
          *ngIf="amounts.new_todos"
          [value]="amounts.new_todos.toString()"
        ></p-badge>
      </span>
    </a>

    <span>Vertrieb</span>
    <a
      *ngIf="authService.can('Kunde.Erfassung Neukunde')"
      [pTooltip]="navHidden ? 'Erfassung Neukunde' : ''"
      routerLink="/offer"
      routerLinkActive="active"
      [routerLinkActiveOptions]="{ exact: true }"
    >
      <i class="tio">file_add_outlined</i>
      <span>Erfassung Neukunde</span>
    </a>
    <a
      *ngIf="authService.can('Kunde.Liste: Qualifizierung')"
      [pTooltip]="navHidden ? 'Qualifizierung' : ''"
      routerLink="/patients"
      routerLinkActive="active"
      [routerLinkActiveOptions]="{ exact: true }"
    >
      <i class="tio">help_outlined</i>
      <span>Qualifizierung</span>
    </a>
    <a
      *ngIf="authService.can('Kunde.Liste: Neukunden')"
      [pTooltip]="navHidden ? 'Neukunden' : ''"
      routerLink="/patients/new"
      routerLinkActive="active"
      [routerLinkActiveOptions]="{ exact: true }"
    >
      <i class="tio">group_equal</i>
      <span>Neukunden</span>
    </a>
    <a
      *ngIf="authService.can('Kunde.Kalender einsehen')"
      [pTooltip]="navHidden ? 'Kalender' : ''"
      routerLink="/calendar"
      routerLinkActive="active"
    >
      <i class="tio">calendar_month</i>
      <span>Kalender</span>
    </a>

    <span>Kundenbetreuung</span>
    <a
      *ngIf="authService.can('Kunde.Liste: Storno')"
      [pTooltip]="navHidden ? 'Storno' : ''"
      routerLink="/patients/inactive"
      routerLinkActive="active"
      [routerLinkActiveOptions]="{ exact: true }"
    >
      <i class="tio">clear</i>
      <span>Storno</span>
    </a>
    <a
      *ngIf="authService.can('Kunde.Liste: Pausiert')"
      [pTooltip]="navHidden ? 'Pausiert' : ''"
      routerLink="/patients/paused"
      routerLinkActive="active"
      [routerLinkActiveOptions]="{ exact: true }"
    >
      <i class="tio">pause_circle_outlined</i>
      <span>Pausiert</span>
    </a>
    <a
      *ngIf="authService.can('Kunde.Liste: Stammkunden')"
      [pTooltip]="navHidden ? 'Stammkunden' : ''"
      routerLink="/patients/regular"
      routerLinkActive="active"
      [routerLinkActiveOptions]="{ exact: true }"
    >
      <i class="tio">group_equal</i>
      <span>Stammkunden</span>
    </a>
    <a
      *ngIf="authService.can('Kunde.Liste: Wunschtermine')"
      [pTooltip]="navHidden ? 'Wunschtermine' : ''"
      routerLink="/desired-dates"
      routerLinkActive="active"
    >
      <i class="tio">appointment</i>
      <span>Wunschtermine</span>
    </a>
    <a
      *ngIf="authService.can('Kunde.Liste: Terminabsagen')"
      [pTooltip]="navHidden ? 'Terminabsagen' : ''"
      routerLink="/cancelled-dates"
      routerLinkActive="active"
    >
      <i class="tio">appointment_cancelled</i>
      <span>Terminabsagen</span>
    </a>
    <a
      *ngIf="authService.can('Kunde.Liste: Beratungseinsätze')"
      [pTooltip]="navHidden ? 'Beratungseinsätze' : ''"
      routerLink="/consultings"
      routerLinkActive="active"
    >
      <i class="tio">event</i>
      <span>Beratungseinsätze</span>
    </a>
    <a
      *ngIf="authService.can('Kunde.Liste: Terminübergabe')"
      [pTooltip]="navHidden ? 'Übergabe' : ''"
      routerLink="/handover"
      routerLinkActive="active"
    >
      <i class="tio">send</i>
      <span>Terminübergabe</span>
    </a>

    <span> Außendienst </span>

    <a
      *ngIf="authService.can('Betreuungskraft.Liste: Betreuungskräfte')"
      [pTooltip]="navHidden ? 'Betreuungskräfte' : ''"
      routerLink="/caregivers"
      routerLinkActive="active"
    >
      <i class="tio">heart_outlined</i>
      <span>Betreuungskräfte</span>
    </a>

    <a
      *ngIf="authService.can('Betreuungskraft.Liste: Bewerber')"
      [pTooltip]="navHidden ? 'Bewerber' : ''"
      routerLink="/applicants"
      routerLinkActive="active"
    >
      <i class="tio">users_switch</i>
      <span>
        Bewerber

        <p-badge
          *ngIf="amounts.updated_applicants"
          [value]="amounts.updated_applicants.toString()"
        ></p-badge>
      </span>
    </a>

    <a
      *ngIf="authService.can('Betreuungskraft.Liste: Freie Kapazität')"
      [pTooltip]="navHidden ? 'Freie Kapazität' : ''"
      routerLink="/capacity"
      routerLinkActive="active"
    >
      <i class="tio">remaining_time</i>
      <span>Freie Kapazität</span>
    </a>

    <a
      *ngIf="
        authService.can('Betreuungskraft.Liste: Planung') ||
        authService.can('Betreuungskraft.Globale Urlaubsplanung einsehen')
      "
      [pTooltip]="navHidden ? 'Planung' : ''"
      [routerLink]="
        authService.can('Betreuungskraft.Liste: Planung')
          ? '/plannings'
          : '/plannings/vacation'
      "
      routerLinkActive="active"
    >
      <i class="tio">time</i>
      <span>
        Planung
        <p-badge
          *ngIf="
            amounts.plannings &&
            authService.can('Betreuungskraft.Liste: Planung')
          "
          [value]="amounts.plannings.toString()"
        ></p-badge>
      </span>
    </a>

    <a
      *ngIf="
        authService.can('Betreuungskraft.Dienstplan Freigaben verwalten') ||
        authService.can('Betreuungskraft.Feedback verwalten')
      "
      [pTooltip]="navHidden ? 'Dienstpläne' : ''"
      [routerLink]="
        authService.can('Betreuungskraft.Feedback verwalten')
          ? '/plans'
          : '/plans/approvals'
      "
      routerLinkActive="active"
    >
      <i class="tio">calendar_note</i>
      <span>Dienstpläne</span>
    </a>

    <a
      *ngIf="authService.can('Betreuungskraft.Liste: Leistungsnachweise')"
      [pTooltip]="navHidden ? 'Leistungsnachweise' : ''"
      routerLink="/proofs"
      routerLinkActive="active"
    >
      <i class="tio">file_text_outlined</i>
      <span>Leistungsnachweise</span>
    </a>

    <span>Verwaltung</span>
    <a
      *ngIf="
        authService.can('Auswertungen.Globale Auswertungen einsehen') ||
        authService.can('Auswertungen.Globale Budgets einsehen')
      "
      [pTooltip]="navHidden ? 'Auswertungen' : ''"
      [routerLink]="
        authService.can('Auswertungen.Globale Auswertungen einsehen')
          ? '/analyses'
          : '/analyses/budgets'
      "
      routerLinkActive="active"
    >
      <i class="tio">chart_donut_2</i>
      <span>Auswertungen</span>
    </a>
    <a
      *ngIf="authService.can('Abrechnung.Rechnungen verwalten')"
      [pTooltip]="navHidden ? 'Rechnungen' : ''"
      routerLink="/invoices"
      routerLinkActive="active"
    >
      <i class="tio">euro_outlined</i>
      <span>Rechnungen</span>
    </a>
    <a
      *ngIf="authService.can('Abrechnung.Rückläufer verwalten')"
      [pTooltip]="navHidden ? 'Rückläufer' : ''"
      routerLink="/accounting/returns"
      routerLinkActive="active"
    >
      <i class="tio">backspace_outlined</i>
      <span>Rückläufer</span>
    </a>
    <a
      *ngIf="authService.can('Verwaltung.Liste: Globale Dokumente')"
      [pTooltip]="navHidden ? 'Dokumente' : ''"
      routerLink="/documents"
      routerLinkActive="active"
    >
      <i class="tio">document_text_outlined</i>
      <span>Dokumente</span>
    </a>
    <a
      *ngIf="authService.can('Multiplikator.Liste: Multiplikatoren')"
      [pTooltip]="navHidden ? 'Multiplikatoren' : ''"
      routerLink="/multipliers"
      routerLinkActive="active"
    >
      <i class="tio">quarantine_outlined</i>
      <span>Multiplikatoren</span>
    </a>
    <a
      *ngIf="authService.can('PKW.Liste: PKW')"
      [pTooltip]="navHidden ? 'PKW' : ''"
      routerLink="/cars"
      routerLinkActive="active"
    >
      <i class="tio">car</i>
      <span>PKW</span>
    </a>
    <a
      *ngIf="authService.canAny('Verwaltung.Systembenutzer verwalten')"
      [pTooltip]="navHidden ? 'Verwaltung' : ''"
      routerLink="/administration"
      routerLinkActive="active"
    >
      <i class="tio">settings_outlined</i>
      <span>Verwaltung</span>
    </a>
  </div>
</nav>
