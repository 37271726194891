<form #form="ngForm" class="dialog-form">
  <div class="dialog-form-body">
    <div class="p-fluid p-formgrid p-grid without-border">
      <div class="p-field p-col-12">
        <div class="p-field-checkbox">
          <p-checkbox
            name="hospitation_canceled"
            [binary]="true"
            [disabled]="isFrozen"
            [(ngModel)]="values.hospitation_canceled"
            label="Hospitation abgebrochen"
          ></p-checkbox>
        </div>
      </div>

      <div class="p-field p-col-12" *ngIf="values.hospitation_canceled">
        <label for="hospitation_canceled_reason">Grund für Abbruch</label>
        <strong *ngIf="isFrozen">{{ values.hospitation_canceled_reason }}</strong>

        <textarea
          *ngIf="!isFrozen"
          [(ngModel)]="values.hospitation_canceled_reason"
          id="hospitation_canceled_reason"
          name="hospitation_canceled_reason"
          [rows]="2"
          pInputTextarea
        ></textarea>
      </div>

      <div class="p-field p-col-6">
        <label for="family_status">Familienstand</label>
        <strong *ngIf="isFrozen">{{ values.family_status }}</strong>

        <p-dropdown
          *ngIf="!isFrozen"
          [(ngModel)]="values.family_status"
          inputId="family_status"
          name="family_status"
          [options]="familyStatus"
          placeholder="Bitte auswählen"
        ></p-dropdown>
      </div>

      <div class="p-field p-col-6">
        <label for="participated_in_assignments">Einsätze mitgemacht</label>
        <strong *ngIf="isFrozen">{{ values.participated_in_assignments }}</strong>

        <p-inputNumber
          *ngIf="!isFrozen"
          [(ngModel)]="values.participated_in_assignments"
          inputId="participated_in_assignments"
          name="participated_in_assignments"
          [useGrouping]="false"
          placeholder="Bitte eintragen"
        ></p-inputNumber>
      </div>

      <div class="p-field p-col-12">
        <label for="hobbies">Hobbys</label>
        <strong *ngIf="isFrozen">{{ values.hobbies }}</strong>

        <input
          *ngIf="!isFrozen"
          [(ngModel)]="values.hobbies"
          id="hobbies"
          name="hobbies"
          type="text"
          pInputText
        />
      </div>

      <div class="p-field p-col-12">
        <label for="commitments">Verpflichtungen</label>
        <strong *ngIf="isFrozen">{{ values.commitments }}</strong>

        <input
          *ngIf="!isFrozen"
          [(ngModel)]="values.commitments"
          id="commitments"
          name="commitments"
          type="text"
          pInputText
        />
      </div>

      <div class="p-field p-col-12">
        <label for="impairments">Beeinträchtigungen</label>
        <strong *ngIf="isFrozen">{{ values.impairments }}</strong>

        <input
          *ngIf="!isFrozen"
          [(ngModel)]="values.impairments"
          id="impairments"
          name="impairments"
          type="text"
          pInputText
        />
      </div>
    </div>

    <div class="p-fluid p-formgrid p-grid without-border">
      <div class="p-field p-col-12">
        <label style="display: flex; align-items: center; justify-content: space-between">Pünktlichkeit<strong>Note: {{ values.on_time != -100 ? values.on_time.toString().replace('-', '') : 'Keine Angabe' }}</strong></label>
        <div style="margin: 10px 0">
          <p-slider [animate]="true" [disabled]="isFrozen" name="on_time" [(ngModel)]="values.on_time" [min]="-6" [max]="-1"></p-slider>
        </div>
        <strong *ngIf="isFrozen">{{ values.on_time_comment }}</strong>

        <input
          *ngIf="!isFrozen"
          [(ngModel)]="values.on_time_comment"
          placeholder="Bemerkung"
          id="on_time_comment"
          name="on_time_comment"
          type="text"
          pInputText
        />
      </div>

      <div class="p-field p-col-12">
        <label style="display: flex; align-items: center; justify-content: space-between">Erscheinungsbild<strong>Note: {{ values.appearance != -100 ? values.appearance.toString().replace('-', '') : 'Keine Angabe' }}</strong></label>
        <div style="margin: 10px 0">
          <p-slider [animate]="true" [disabled]="isFrozen" name="appearance" [(ngModel)]="values.appearance" [min]="-6" [max]="-1"></p-slider>
        </div>
        <strong *ngIf="isFrozen">{{ values.appearance_comment }}</strong>

        <input
          *ngIf="!isFrozen"
          [(ngModel)]="values.appearance_comment"
          placeholder="Bemerkung"
          id="appearance_comment"
          name="appearance_comment"
          type="text"
          pInputText
        />
      </div>

      <div class="p-field p-col-12">
        <label style="display: flex; align-items: center; justify-content: space-between">Kommunikation mit Kunde<strong>Note: {{ values.communication_with_customer != -100 ? values.communication_with_customer.toString().replace('-', '') : 'Keine Angabe' }}</strong></label>
        <div style="margin: 10px 0">
          <p-slider [animate]="true" [disabled]="isFrozen" name="communication_with_customer" [(ngModel)]="values.communication_with_customer" [min]="-6" [max]="-1"></p-slider>
        </div>
        <strong *ngIf="isFrozen">{{ values.communication_with_customer_comment }}</strong>

        <input
          *ngIf="!isFrozen"
          [(ngModel)]="values.communication_with_customer_comment"
          placeholder="Bemerkung"
          id="communication_with_customer_comment"
          name="communication_with_customer_comment"
          type="text"
          pInputText
        />
      </div>

      <div class="p-field p-col-12">
        <label style="display: flex; align-items: center; justify-content: space-between">Kommunikation mit mir<strong>Note: {{values.communication_with_me != -100 ? values.communication_with_me.toString().replace('-', '') : 'Keine Angabe' }}</strong></label>
        <div style="margin: 10px 0">
          <p-slider [animate]="true" [disabled]="isFrozen" name="communication_with_me" [(ngModel)]="values.communication_with_me" [min]="-6" [max]="-1"></p-slider>
        </div>
        <strong *ngIf="isFrozen">{{ values.communication_with_me_comment }}</strong>

        <input
          *ngIf="!isFrozen"
          [(ngModel)]="values.communication_with_me_comment"
          placeholder="Bemerkung"
          id="communication_with_me_comment"
          name="communication_with_me_comment"
          type="text"
          pInputText
        />
      </div>

      <div class="p-field p-col-12">
        <label style="display: flex; align-items: center; justify-content: space-between">Fachkenntnisse<strong>Note: {{ values.expertise != -100 ? values.expertise.toString().replace('-', '') : 'Keine Angabe' }}</strong></label>
        <div style="margin: 10px 0">
          <p-slider [animate]="true" [disabled]="isFrozen" name="expertise" [(ngModel)]="values.expertise" [min]="-6" [max]="-1"></p-slider>
        </div>
        <strong *ngIf="isFrozen">{{ values.expertise_comment }}</strong>

        <input
          *ngIf="!isFrozen"
          [(ngModel)]="values.expertise_comment"
          placeholder="Bemerkung"
          id="expertise_comment"
          name="expertise_comment"
          type="text"
          pInputText
        />
      </div>

      <div class="p-field p-col-12">
        <label style="display: flex; align-items: center; justify-content: space-between">Auffassungsgabe<strong>Note: {{ values.perceptiveness != -100 ? values.perceptiveness.toString().replace('-', '') : 'Keine Angabe' }}</strong></label>
        <div style="margin: 10px 0">
          <p-slider [animate]="true" [disabled]="isFrozen" name="perceptiveness" [(ngModel)]="values.perceptiveness" [min]="-6" [max]="-1"></p-slider>
        </div>
        <strong *ngIf="isFrozen">{{ values.perceptiveness_comment }}</strong>

        <input
          *ngIf="!isFrozen"
          [(ngModel)]="values.perceptiveness_comment"
          placeholder="Bemerkung"
          id="perceptiveness_comment"
          name="perceptiveness_comment"
          type="text"
          pInputText
        />
      </div>

      <div class="p-field p-col-12">
        <label style="display: flex; align-items: center; justify-content: space-between">Arbeitsweise<strong>Note: {{ values.working_method != -100 ? values.working_method.toString().replace('-', '') : 'Keine Angabe' }}</strong></label>
        <div style="margin: 10px 0">
          <p-slider [animate]="true" [disabled]="isFrozen" name="working_method" [(ngModel)]="values.working_method" [min]="-6" [max]="-1"></p-slider>
        </div>
        <strong *ngIf="isFrozen">{{ values.working_method_comment }}</strong>
        <input
          *ngIf="!isFrozen"
          [(ngModel)]="values.working_method_comment"
          placeholder="Bemerkung"
          id="working_method_comment"
          name="working_method_comment"
          type="text"
          pInputText
        />
      </div>

      <div class="p-field p-col-12">
        <label style="display: flex; align-items: center; justify-content: space-between">Arbeitstempo<strong>Note: {{ values.working_speed != -100 ? values.working_speed.toString().replace('-', '') : 'Keine Angabe' }}</strong></label>
        <div style="margin: 10px 0">
          <p-slider [animate]="true" [disabled]="isFrozen" name="working_speed" [(ngModel)]="values.working_speed" [min]="-6" [max]="-1"></p-slider>
        </div>
        <strong *ngIf="isFrozen">{{ values.working_speed_comment }}</strong>
        <input
          *ngIf="!isFrozen"
          [(ngModel)]="values.working_speed_comment"
          placeholder="Bemerkung"
          id="working_speed_comment"
          name="working_speed_comment"
          type="text"
          pInputText
        />
      </div>

      <div class="p-field p-col-12">
        <label style="display: flex; align-items: center; justify-content: space-between">PKW Fahrt<strong>Note: {{ values.car_ride != -100 ? values.car_ride.toString().replace('-', '') : 'Keine Angabe' }}</strong></label>
        <div style="margin: 10px 0">
          <p-slider [animate]="true" [disabled]="isFrozen" name="car_ride" [(ngModel)]="values.car_ride" [min]="-6" [max]="-1"></p-slider>
        </div>
        <strong *ngIf="isFrozen">{{ values.car_ride_comment }}</strong>
        <input
          *ngIf="!isFrozen"
          [(ngModel)]="values.car_ride_comment"
          placeholder="Bemerkung"
          id="car_ride_comment"
          name="car_ride_comment"
          type="text"
          pInputText
        />
      </div>

      <div class="p-field p-col-12">
        <label style="display: flex; align-items: center; justify-content: space-between"><strong>Gesamteindruck</strong><strong>Note: {{ values.overall_impression != -100 ? values.overall_impression.toString().replace('-', '') : 'Keine Angabe' }}</strong></label>
        <div style="margin: 10px 0">
          <p-slider [animate]="true" [disabled]="isFrozen" name="overall_impression" [(ngModel)]="values.overall_impression" [min]="-6" [max]="-1"></p-slider>
        </div>
        <strong *ngIf="isFrozen">{{ values.overall_impression_comment }}</strong>
        <input
          *ngIf="!isFrozen"
          [(ngModel)]="values.overall_impression_comment"
          placeholder="Bemerkung"
          id="overall_impression_comment"
          name="overall_impression_comment"
          type="text"
          pInputText
        />
      </div>

      <div class="p-field p-col-12">
        <label for="recommendation"><strong>Ich empfehle eine Einstellung</strong></label>
        <strong *ngIf="isFrozen">{{ values.recommendation || 'Keine Angabe' }}</strong>

        <div class="p-formgroup-inline" *ngIf="!isFrozen">
          <div class="p-field-radiobutton">
            <p-radioButton
              [(ngModel)]="values.recommendation"
              label="Nein"
              name="recommendation"
              value="NEIN"
            ></p-radioButton>
          </div>
          <div class="p-field-radiobutton">
            <p-radioButton
              [(ngModel)]="values.recommendation"
              label="Ja"
              name="recommendation"
              value="JA"
            ></p-radioButton>
          </div>
        </div>
      </div>

      <div class="p-field p-col-12">
        <label for="feedback">Feedback {{ data.is_female ? 'der Bewerberin' : 'des Bewerbers' }}</label>
        <strong *ngIf="isFrozen">{{ values.feedback }}</strong>
        <textarea
          *ngIf="!isFrozen"
          [(ngModel)]="values.feedback"
          id="feedback"
          name="feedback"
          [rows]="3"
          pInputTextarea
        ></textarea>

        <small *ngIf="!isFrozen" class="color-gray">Wie fühlen Sie sich? Haben Sie ein Gefühl für das Unternehmen entwickeln können? Haben Sie aktuell eine Vorstellung über Ihre zukünftige Tätigkeit? Was hat Ihnen besonders gut gefallen? Haben Sie noch Fragen? Was könnten wir im Bewerbungsverfahren verändern?</small>
      </div>
    </div>
  </div>
  <div class="dialog-form-footer" *ngIf="!isFrozen">
    <i></i>

    <button
      pButton
      label="Feedback speichern"
      type="submit"
      (click)="save()"
      [disabled]="submitted"
      [icon]="submitted ? 'pi pi-spin pi-spinner' : 'pi pi-check'"
    ></button>
  </div>
</form>
<p-confirmPopup></p-confirmPopup>
