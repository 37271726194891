import { Component, OnDestroy, OnInit } from '@angular/core'
import { ActivatedRoute, ParamMap, Router } from '@angular/router'
import { DialogService } from 'primeng/dynamicdialog'
import { DiffViewDialogComponent } from '../../../../../components/dialogs/diff-view-dialog/diff-view-dialog.component'
import { HistoryListModel } from '../../../../../models/history/history-list.model'
import { UserSystemService } from '../../../../../services/user-system.service'
import { HistoryManualDialogComponent } from '../../../../../components/dialogs/history-manual-dialog/history-manual-dialog.component'
import {
  EventBusService,
  GlobalEvent,
} from '../../../../../services/eventbus.service'
import { Subscription } from 'rxjs'

@Component({
  selector: 'app-users-detail-history',
  templateUrl: './users-detail-history.component.html',
  providers: [DialogService],
})
export class UsersDetailHistoryComponent implements OnInit, OnDestroy {
  public loading = true
  public histories: HistoryListModel[] = []
  public userId: string | null = null
  public onlyImportant = false

  private eventBusSubscription: Subscription = new Subscription()

  constructor(
    private userSystemService: UserSystemService,
    private route: ActivatedRoute,
    private eventbus: EventBusService,
    private dialogService: DialogService
  ) {}

  ngOnInit(): void {
    this.loadHistory()
    this.listenForEventbus()
  }

  ngOnDestroy(): void {
    this.eventBusSubscription.unsubscribe()
  }

  public openDiffView(id: number): void {
    this.dialogService.open(DiffViewDialogComponent, {
      data: {
        id,
      },
      header: 'Änderungen ansehen',
      styleClass: 'dialog-diff-view',
      dismissableMask: true,
    })
  }

  public openManualHistoryDialog(
    edit: boolean,
    history?: HistoryListModel
  ): void {
    this.dialogService.open(HistoryManualDialogComponent, {
      data: {
        edit,
        history: history || new HistoryListModel(),
        type: 'user_system',
        type_id: this.userId,
        without_documents: true,
      },
      header: history ? 'Eintrag bearbeiten' : 'Neuer Eintrag',
      styleClass: 'dialog-container',
      dismissableMask: false,
      width: '520px',
    })
  }

  /**
   * Hier reagieren wir auf Events im EventBusService, wie z.B. das Event,
   * dass die PhoneCall-Liste die Daten neu laden soll (wenn z.B. ein Telefonat
   * gelöscht oder bearbeitet wurde).
   */
  private listenForEventbus(): void {
    this.eventBusSubscription = this.eventbus.subject.subscribe(
      (event: GlobalEvent) => {
        switch (event) {
          case GlobalEvent.PhoneCallListReload:
          case GlobalEvent.HistoryListReload:
            this.loadHistory(false)
        }
      }
    )
  }

  public loadHistory(withLoading: boolean = true): void {
    if (withLoading) {
      this.loading = true
    }

    if (this.route.parent) {
      this.route.parent.paramMap.subscribe((params: ParamMap) => {
        this.userId = params.get('id')

        this.userSystemService
          .history(this.userId)
          .subscribe((data: HistoryListModel[]) => {
            this.histories = data

            if (withLoading) {
              this.loading = false
            }
          })
      })
    }
  }
}
