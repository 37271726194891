<app-loader [loading]="loading"></app-loader>

<div class="content" *ngIf="!loading">
  <!--  <header class="content-header without-border">-->
  <!--    <span>-->
  <!--      <i class="pi pi-user"></i>-->
  <!--      Betreuungskraft-->
  <!--    </span>-->
  <!--  </header>-->
  <div class="content-body without-padding p-flex-row">
    <div class="detail-information-column border-right">
      <div class="detail-information-container">
        <div class="detail-information-header">
          <span><i class="pi pi-bars"></i> Daten</span>
        </div>
        <div class="detail-information-body">
          <!--          <div class="detail-information">-->
          <!--            <div class="detail-key clickable">-->
          <!--              <span-->
          <!--                tooltipPosition="left"-->
          <!--                (mouseenter)="-->
          <!--                  getLatestChange(caregiver.caregiver.id, 'caregiver', {-->
          <!--                    employed_as: caregiver.caregiver.employed_as-->
          <!--                  })-->
          <!--                "-->
          <!--                (click)="-->
          <!--                  openDiffViewHistory(caregiver.caregiver.id, 'caregiver', {-->
          <!--                    employed_as: caregiver.caregiver.employed_as-->
          <!--                  })-->
          <!--                "-->
          <!--                [pTooltip]="latestChanges.employed_as"-->
          <!--                >Angestellt als</span-->
          <!--              >-->
          <!--            </div>-->
          <!--            <div class="detail-value">-->
          <!--              <strong>{{ caregiver.caregiver.employed_as || "-" }}</strong>-->
          <!--            </div>-->
          <!--          </div>-->

          <p-overlayPanel
            #op
            styleClass="with-border"
            [showCloseIcon]="true"
            [style]="{
              maxWidth: '800px',
              width: 'max-content',
              maxHeight: '420px',
              overflowY: 'auto'
            }"
          >
            <ng-template pTemplate>
              <div [innerHTML]="currentBudgetTable"></div>
            </ng-template>
          </p-overlayPanel>

          <div
            class="detail-information"
            *ngIf="caregiver.caregiver.current_status.status !== 'EMPLOYMENT'"
          >
            <div class="detail-key">
              <span>Beginn möglich am</span>
            </div>
            <div class="detail-value p-jc-between p-align-center">
              <div>
                <strong>
                  {{ caregiver.caregiver.employment_possible_at_string }}
                </strong>
              </div>
            </div>
          </div>

          <div
            class="detail-information"
            *ngIf="caregiver.caregiver.current_employment"
          >
            <div class="detail-key">
              <span>Angestellt seit</span>
            </div>
            <div class="detail-value p-jc-between p-align-center">
              <div>
                <strong>{{
                  caregiver.caregiver.current_employment.employed_since_date
                    | date: "dd.MM.y"
                }}</strong>
                <strong
                  *ngIf="
                    caregiver.caregiver.current_employment.employed_until_date
                  "
                >
                  -
                  {{
                    caregiver.caregiver.current_employment.employed_until_date
                      | date: "dd.MM.y"
                  }}</strong
                >

                <strong
                  *ngIf="
                    caregiver.caregiver.current_employment.employed_temporary
                  "
                >
                  (Befristet)</strong
                >
              </div>

              <div class="detail-value-actions">
                <i
                  *ngIf="
                    caregiver.caregiver.current_status.status === 'EMPLOYMENT'
                  "
                  class="pi pi-plus color-gray"
                  (click)="openAddCaregiverEmployeedDialog()"
                  pTooltip="Neu"
                ></i>
                <i
                  class="pi color-gray"
                  [class.pi-chevron-down]="!openedDetails.employments"
                  [class.pi-chevron-up]="openedDetails.employments"
                  [pTooltip]="
                    openedDetails.employments ? 'Schließen' : 'Anzeigen'
                  "
                  (click)="
                    openedDetails.employments = !openedDetails.employments
                  "
                ></i>
              </div>
            </div>
          </div>

          <div
            class="detail-information without-padding"
            *ngIf="openedDetails.employments"
          >
            <p-table
              styleClass="highlight-header"
              [value]="caregiver.caregiver.employments"
              [rowHover]="true"
            >
              <ng-template pTemplate="header">
                <tr>
                  <th style="width: 50px"></th>
                  <th>seit</th>
                  <th>bis</th>
                  <th style="width: 400px">Kommentar</th>
                  <th style="width: 50px"></th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-employment>
                <tr>
                  <td>
                    <i
                      *ngIf="employment.employed_temporary"
                      class="pi pi-clock"
                      pTooltip="Befristet"
                    ></i>
                  </td>
                  <td>
                    {{ employment.employed_since_date | date: "dd.MM.y" }}
                  </td>
                  <td>
                    {{ employment.employed_until_date | date: "dd.MM.y" }}
                  </td>

                  <td>{{ employment.comment }}</td>
                  <td>
                    <i
                      class="pi pi-pencil show-always"
                      (click)="openAddCaregiverEmployeedDialog(employment)"
                    ></i>
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </div>

          <div class="detail-information">
            <div class="detail-key">
              <span>Stellenumfang</span>
            </div>
            <div class="detail-value p-jc-between p-align-center">
              <strong *ngIf="caregiver.caregiver.current_job_scope"
                >{{
                  caregiver.caregiver.current_job_scope.job_scope
                }}
                Std</strong
              >
              <strong *ngIf="!caregiver.caregiver.current_job_scope">-</strong>

              <div class="detail-value-actions">
                <i
                  *ngIf="
                    caregiver.caregiver.current_status.status === 'EMPLOYMENT'
                  "
                  class="pi pi-plus color-gray"
                  (click)="openAddCaregiverJobScopeDialog()"
                  pTooltip="Ändern"
                ></i>
                <i
                  class="pi color-gray"
                  [class.pi-chevron-down]="!openedDetails.job_scope"
                  [class.pi-chevron-up]="openedDetails.job_scope"
                  [pTooltip]="
                    openedDetails.job_scope ? 'Schließen' : 'Anzeigen'
                  "
                  (click)="openedDetails.job_scope = !openedDetails.job_scope"
                ></i>
              </div>
            </div>
          </div>

          <div
            class="detail-information without-padding"
            *ngIf="openedDetails.job_scope"
          >
            <p-table
              styleClass="highlight-header"
              [value]="caregiver.caregiver.job_scopes"
              [rowHover]="true"
            >
              <ng-template pTemplate="header">
                <tr>
                  <th>Stunden</th>
                  <th>seit</th>
                  <th style="width: 400px">Kommentar</th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-jobScope>
                <tr>
                  <td>{{ jobScope.job_scope }} Std</td>
                  <td>{{ jobScope.since | date: "dd.MM.y" }}</td>
                  <td>{{ jobScope.comment }}</td>
                </tr>
              </ng-template>
            </p-table>
          </div>

          <div class="detail-information">
            <div class="detail-key">
              <span>Arbeitszeit laut Vertrag</span>
            </div>
            <div class="detail-value p-jc-between p-align-center">
              <strong *ngIf="caregiver.caregiver.current_job_days"
                >{{
                  caregiver.caregiver.current_job_days.job_days
                }}
                Tage</strong
              >
              <strong *ngIf="!caregiver.caregiver.current_job_days">-</strong>

              <div class="detail-value-actions">
                <i
                  *ngIf="
                    caregiver.caregiver.current_status.status === 'EMPLOYMENT'
                  "
                  class="pi pi-plus color-gray"
                  (click)="openAddCaregiverJobDaysDialog()"
                  pTooltip="Ändern"
                ></i>
                <i
                  class="pi color-gray"
                  [class.pi-chevron-down]="!openedDetails.job_days"
                  [class.pi-chevron-up]="openedDetails.job_days"
                  [pTooltip]="openedDetails.job_days ? 'Schließen' : 'Anzeigen'"
                  (click)="openedDetails.job_days = !openedDetails.job_days"
                ></i>
              </div>
            </div>
          </div>

          <div
            class="detail-information without-padding"
            *ngIf="openedDetails.job_days"
          >
            <p-table
              styleClass="highlight-header"
              [value]="caregiver.caregiver.job_days"
              [rowHover]="true"
            >
              <ng-template pTemplate="header">
                <tr>
                  <th>Tage</th>
                  <th>seit</th>
                  <th style="width: 400px">Kommentar</th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-jobDays>
                <tr>
                  <td>{{ jobDays.job_days }} Tage</td>
                  <td>{{ jobDays.since | date: "dd.MM.y" }}</td>
                  <td>{{ jobDays.comment }}</td>
                </tr>
              </ng-template>
            </p-table>
          </div>

          <div class="detail-information">
            <div class="detail-key">
              <span>Stunden pro Monat</span>
            </div>
            <div class="detail-value">
              <i
                (click)="
                  openBudgetOverlay(
                    $event,
                    persplanData['last' + caregiver.caregiver.id]
                  )
                "
                class="p-tag p-tag-rounded p-tag-outline p-tag-clickable"
                pTooltip="Stunden ansehen"
                (mouseenter)="getPersplanBudget(caregiver.caregiver.id, 'last')"
                style="margin: 0 10px 0 0"
                >Letzter:
                {{ caregiver.caregiver.persplan_hours_real_for_last_month }}</i
              >
              <i
                (click)="
                  openBudgetOverlay(
                    $event,
                    persplanData['current' + caregiver.caregiver.id]
                  )
                "
                class="p-tag p-tag-rounded p-tag-outline p-tag-clickable"
                pTooltip="Stunden ansehen"
                (mouseenter)="
                  getPersplanBudget(caregiver.caregiver.id, 'current')
                "
                style="margin: 0 10px 0 0"
                >Aktueller:
                {{
                  caregiver.caregiver.persplan_hours_real_for_current_month
                }}</i
              >
              <i
                (click)="
                  openBudgetOverlay(
                    $event,
                    persplanData['next' + caregiver.caregiver.id]
                  )
                "
                class="p-tag p-tag-rounded p-tag-outline p-tag-clickable"
                pTooltip="Stunden ansehen"
                (mouseenter)="getPersplanBudget(caregiver.caregiver.id, 'next')"
                >Nächster:
                {{ caregiver.caregiver.persplan_hours_real_for_next_month }}</i
              >
            </div>
          </div>

          <div class="detail-information">
            <div class="detail-key clickable">
              <span
                tooltipPosition="left"
                (mouseenter)="
                  getLatestChange(caregiver.caregiver.id, 'caregiver', {
                    activities_string: caregiver.caregiver.activities_string
                  })
                "
                (click)="
                  openDiffViewHistory(caregiver.caregiver.id, 'caregiver', {
                    activities_string: caregiver.caregiver.activities_string
                  })
                "
                [pTooltip]="latestChanges.activities_string"
                >Tätigkeiten</span
              >
            </div>
            <div class="detail-value">
              <strong>{{
                caregiver.caregiver.activities_string || "-"
              }}</strong>
            </div>
          </div>

          <div class="detail-information">
            <div class="detail-key clickable">
              <span
                tooltipPosition="left"
                (mouseenter)="
                  getLatestChange(caregiver.caregiver.id, 'caregiver', {
                    cities_string: caregiver.caregiver.cities_string
                  })
                "
                (click)="
                  openDiffViewHistory(caregiver.caregiver.id, 'caregiver', {
                    cities_string: caregiver.caregiver.cities_string
                  })
                "
                [pTooltip]="latestChanges.cities_string"
                >Bezirke</span
              >
            </div>
            <div class="detail-value">
              <strong>{{ caregiver.caregiver.cities_string || "-" }}</strong>
            </div>
          </div>

          <div class="detail-information">
            <div class="detail-key clickable">
              <span
                tooltipPosition="left"
                (mouseenter)="
                  getLatestChange(caregiver.caregiver.id, 'caregiver', {
                    job_position: caregiver.caregiver.job_position
                  })
                "
                (click)="
                  openDiffViewHistory(caregiver.caregiver.id, 'caregiver', {
                    job_position: caregiver.caregiver.job_position
                  })
                "
                [pTooltip]="latestChanges.job_position"
                >Position</span
              >
            </div>
            <div class="detail-value">
              <strong>{{ caregiver.caregiver.job_position || "-" }}</strong>
            </div>
          </div>

          <div class="detail-information">
            <div class="detail-key clickable">
              <span
                tooltipPosition="left"
                (mouseenter)="
                  getLatestChange(caregiver.caregiver.id, 'caregiver', {
                    vacation_days: caregiver.caregiver.vacation_days
                  })
                "
                (click)="
                  openDiffViewHistory(caregiver.caregiver.id, 'caregiver', {
                    vacation_days: caregiver.caregiver.vacation_days
                  })
                "
                [pTooltip]="latestChanges.vacation_days"
                >Urlaub</span
              >
            </div>
            <div class="detail-value">
              <strong *ngIf="caregiver.caregiver.vacation_days"
                >{{ caregiver.caregiver.vacation_days }} Tage</strong
              >
              <strong *ngIf="!caregiver.caregiver.vacation_days">-</strong>
            </div>
          </div>

          <div class="detail-information">
            <div class="detail-key clickable">
              <span
                tooltipPosition="left"
                (mouseenter)="
                  getLatestChange(caregiver.caregiver.id, 'caregiver', {
                    salary: caregiver.caregiver.salary
                  })
                "
                (click)="
                  openDiffViewHistory(caregiver.caregiver.id, 'caregiver', {
                    salary: caregiver.caregiver.salary
                  })
                "
                [pTooltip]="latestChanges.salary"
                >Gehalt</span
              >
            </div>
            <div class="detail-value">
              <strong *ngIf="!caregiver.caregiver.salary">-</strong>
              <i
                class="pi pi-info-circle"
                [pTooltip]="caregiver.caregiver.salary || ''"
                *ngIf="caregiver.caregiver.salary"
              ></i>
            </div>
          </div>

          <div class="detail-information">
            <div class="detail-key clickable">
              <span
                tooltipPosition="left"
                (mouseenter)="
                  getLatestChange(caregiver.caregiver.id, 'caregiver', {
                    tax_id: caregiver.caregiver.tax_id
                  })
                "
                (click)="
                  openDiffViewHistory(caregiver.caregiver.id, 'caregiver', {
                    tax_id: caregiver.caregiver.tax_id
                  })
                "
                [pTooltip]="latestChanges.tax_id"
                >Steuer-ID</span
              >
            </div>
            <div class="detail-value">
              <strong *ngIf="caregiver.caregiver.tax_id">{{
                caregiver.caregiver.tax_id
              }}</strong>
              <strong *ngIf="!caregiver.caregiver.tax_id">-</strong>
            </div>
          </div>

          <div class="detail-information">
            <div class="detail-key clickable">
              <span
                tooltipPosition="left"
                (mouseenter)="
                  getLatestChange(caregiver.caregiver.id, 'caregiver', {
                    pension_insurance_number:
                      caregiver.caregiver.pension_insurance_number
                  })
                "
                (click)="
                  openDiffViewHistory(caregiver.caregiver.id, 'caregiver', {
                    pension_insurance_number:
                      caregiver.caregiver.pension_insurance_number
                  })
                "
                [pTooltip]="latestChanges.pension_insurance_number"
                >Rentenversicherungsnummer</span
              >
            </div>
            <div class="detail-value">
              <strong *ngIf="caregiver.caregiver.pension_insurance_number">{{
                caregiver.caregiver.pension_insurance_number
              }}</strong>
              <strong *ngIf="!caregiver.caregiver.pension_insurance_number"
                >-</strong
              >
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="detail-information-column">
      <!--      <div class="detail-information-container">-->
      <!--        <div class="detail-information-header">Details</div>-->
      <!--        <div class="detail-information-body">-->
      <!--          <div class="detail-information">-->
      <!--            <div class="detail-key clickable">-->
      <!--              <span-->
      <!--                tooltipPosition="left"-->
      <!--                (mouseenter)="-->
      <!--                  getLatestChange(caregiver.caregiver.id, 'caregiver', {-->
      <!--                    experiences_string: caregiver.caregiver.experiences_string-->
      <!--                  })-->
      <!--                "-->
      <!--                (click)="-->
      <!--                  openDiffViewHistory(caregiver.caregiver.id, 'caregiver', {-->
      <!--                    experiences_string: caregiver.caregiver.experiences_string-->
      <!--                  })-->
      <!--                "-->
      <!--                [pTooltip]="latestChanges.experiences_string"-->
      <!--                >Erfahrungen</span-->
      <!--              >-->
      <!--            </div>-->
      <!--            <div class="detail-value">-->
      <!--              <strong>{{-->
      <!--                caregiver.caregiver.experiences_string || "-"-->
      <!--              }}</strong>-->
      <!--            </div>-->
      <!--          </div>-->
      <!--          <div class="detail-information">-->
      <!--            <div class="detail-key clickable">-->
      <!--              <span-->
      <!--                tooltipPosition="left"-->
      <!--                (mouseenter)="-->
      <!--                  getLatestChange(caregiver.caregiver.id, 'caregiver', {-->
      <!--                    experience_info: caregiver.caregiver.experience_info-->
      <!--                  })-->
      <!--                "-->
      <!--                (click)="-->
      <!--                  openDiffViewHistory(caregiver.caregiver.id, 'caregiver', {-->
      <!--                    experience_info: caregiver.caregiver.experience_info-->
      <!--                  })-->
      <!--                "-->
      <!--                [pTooltip]="latestChanges.experience_info"-->
      <!--                >Zusatzinfo</span-->
      <!--              >-->
      <!--            </div>-->
      <!--            <div class="detail-value">-->
      <!--              <strong>{{ caregiver.caregiver.experience_info || "-" }}</strong>-->
      <!--            </div>-->
      <!--          </div>-->
      <!--          <div class="detail-information">-->
      <!--            <div class="detail-key clickable">-->
      <!--              <span-->
      <!--                tooltipPosition="left"-->
      <!--                (mouseenter)="-->
      <!--                  getLatestChange(caregiver.caregiver.id, 'caregiver', {-->
      <!--                    education_as: caregiver.caregiver.education_as-->
      <!--                  })-->
      <!--                "-->
      <!--                (click)="-->
      <!--                  openDiffViewHistory(caregiver.caregiver.id, 'caregiver', {-->
      <!--                    education_as: caregiver.caregiver.education_as-->
      <!--                  })-->
      <!--                "-->
      <!--                [pTooltip]="latestChanges.education_as"-->
      <!--                >Ausbildung als</span-->
      <!--              >-->
      <!--            </div>-->
      <!--            <div class="detail-value">-->
      <!--              <strong>{{ caregiver.caregiver.education_as || "-" }}</strong>-->
      <!--            </div>-->
      <!--          </div>-->
      <!--          <div class="detail-information">-->
      <!--            <div class="detail-key clickable">-->
      <!--              <span-->
      <!--                tooltipPosition="left"-->
      <!--                (mouseenter)="-->
      <!--                  getLatestChange(caregiver.caregiver.id, 'caregiver', {-->
      <!--                    education_dementia: caregiver.caregiver.education_dementia-->
      <!--                  })-->
      <!--                "-->
      <!--                (click)="-->
      <!--                  openDiffViewHistory(caregiver.caregiver.id, 'caregiver', {-->
      <!--                    education_dementia: caregiver.caregiver.education_dementia-->
      <!--                  })-->
      <!--                "-->
      <!--                [pTooltip]="latestChanges.education_dementia"-->
      <!--                >DemenzBK</span-->
      <!--              >-->
      <!--            </div>-->
      <!--            <div class="detail-value">-->
      <!--              <strong>{{-->
      <!--                caregiver.caregiver.education_dementia ? "Ja" : "Nein"-->
      <!--              }}</strong>-->
      <!--            </div>-->
      <!--          </div>-->
      <!--          <div class="detail-information">-->
      <!--            <div class="detail-key clickable">-->
      <!--              <span-->
      <!--                tooltipPosition="left"-->
      <!--                (mouseenter)="-->
      <!--                  getLatestChange(caregiver.caregiver.id, 'caregiver', {-->
      <!--                    education_assistant: caregiver.caregiver.education_assistant-->
      <!--                  })-->
      <!--                "-->
      <!--                (click)="-->
      <!--                  openDiffViewHistory(caregiver.caregiver.id, 'caregiver', {-->
      <!--                    education_assistant: caregiver.caregiver.education_assistant-->
      <!--                  })-->
      <!--                "-->
      <!--                [pTooltip]="latestChanges.education_assistant"-->
      <!--                >Pflegehilfskraft</span-->
      <!--              >-->
      <!--            </div>-->
      <!--            <div class="detail-value">-->
      <!--              <strong>{{-->
      <!--                caregiver.caregiver.education_assistant ? "Ja" : "Nein"-->
      <!--              }}</strong>-->
      <!--            </div>-->
      <!--          </div>-->
      <!--          <div class="detail-information">-->
      <!--            <div class="detail-key clickable">-->
      <!--              <span-->
      <!--                tooltipPosition="left"-->
      <!--                (mouseenter)="-->
      <!--                  getLatestChange(caregiver.caregiver.id, 'caregiver', {-->
      <!--                    initial_training: caregiver.caregiver.initial_training,-->
      <!--                    last_training_date_string:-->
      <!--                      caregiver.caregiver.last_training_date_string-->
      <!--                  })-->
      <!--                "-->
      <!--                (click)="-->
      <!--                  openDiffViewHistory(caregiver.caregiver.id, 'caregiver', {-->
      <!--                    initial_training: caregiver.caregiver.initial_training,-->
      <!--                    last_training_date_string:-->
      <!--                      caregiver.caregiver.last_training_date_string-->
      <!--                  })-->
      <!--                "-->
      <!--                [pTooltip]="-->
      <!--                  latestChanges.last_training_date_string ||-->
      <!--                  latestChanges.initial_training-->
      <!--                "-->
      <!--                >Erstschulung vollständig?</span-->
      <!--              >-->
      <!--            </div>-->
      <!--            <div class="detail-value">-->
      <!--              <strong-->
      <!--                >{{ caregiver.caregiver.initial_training }}-->
      <!--                {{-->
      <!--                  caregiver.caregiver.last_training_date_string-->
      <!--                    ? "(" + caregiver.caregiver.last_training_date_string + ")"-->
      <!--                    : ""-->
      <!--                }}</strong-->
      <!--              >-->
      <!--            </div>-->
      <!--          </div>-->
      <!--          <div class="detail-information">-->
      <!--            <div class="detail-key clickable">-->
      <!--              <span-->
      <!--                tooltipPosition="left"-->
      <!--                (mouseenter)="-->
      <!--                  getLatestChange(caregiver.caregiver.id, 'caregiver', {-->
      <!--                    first_aid: caregiver.caregiver.first_aid,-->
      <!--                    first_aid_date_string:-->
      <!--                      caregiver.caregiver.first_aid_date_string-->
      <!--                  })-->
      <!--                "-->
      <!--                (click)="-->
      <!--                  openDiffViewHistory(caregiver.caregiver.id, 'caregiver', {-->
      <!--                    first_aid: caregiver.caregiver.first_aid,-->
      <!--                    first_aid_date_string:-->
      <!--                      caregiver.caregiver.first_aid_date_string-->
      <!--                  })-->
      <!--                "-->
      <!--                [pTooltip]="-->
      <!--                  latestChanges.first_aid_date_string || latestChanges.first_aid-->
      <!--                "-->
      <!--                >Erste Hilfe-Kurs?</span-->
      <!--              >-->
      <!--            </div>-->
      <!--            <div class="detail-value">-->
      <!--              <strong-->
      <!--                >{{ caregiver.caregiver.first_aid }}-->
      <!--                {{-->
      <!--                  caregiver.caregiver.first_aid_date_string-->
      <!--                    ? "(" + caregiver.caregiver.first_aid_date_string + ")"-->
      <!--                    : ""-->
      <!--                }}</strong-->
      <!--              >-->
      <!--            </div>-->
      <!--          </div>-->
      <!--          <div class="detail-information">-->
      <!--            <div class="detail-key clickable">-->
      <!--              <span-->
      <!--                tooltipPosition="left"-->
      <!--                (mouseenter)="-->
      <!--                  getLatestChange(caregiver.caregiver.id, 'caregiver', {-->
      <!--                    training_administration_date_string:-->
      <!--                      caregiver.caregiver.training_administration_date_string,-->
      <!--                    training_administration:-->
      <!--                      caregiver.caregiver.training_administration,-->
      <!--                    training_administration_from:-->
      <!--                      caregiver.caregiver.training_administration_from-->
      <!--                  })-->
      <!--                "-->
      <!--                (click)="-->
      <!--                  openDiffViewHistory(caregiver.caregiver.id, 'caregiver', {-->
      <!--                    training_administration_date_string:-->
      <!--                      caregiver.caregiver.training_administration_date_string,-->
      <!--                    training_administration:-->
      <!--                      caregiver.caregiver.training_administration,-->
      <!--                    training_administration_from:-->
      <!--                      caregiver.caregiver.training_administration_from-->
      <!--                  })-->
      <!--                "-->
      <!--                [pTooltip]="-->
      <!--                  latestChanges.training_administration_date_string ||-->
      <!--                  latestChanges.training_administration ||-->
      <!--                  latestChanges.training_administration_from-->
      <!--                "-->
      <!--                >Einarbeitung Administration?</span-->
      <!--              >-->
      <!--            </div>-->
      <!--            <div class="detail-value">-->
      <!--              <strong-->
      <!--                >{{ caregiver.caregiver.training_administration }}-->
      <!--                {{-->
      <!--                  caregiver.caregiver.training_administration_date_string-->
      <!--                    ? "(" +-->
      <!--                      caregiver.caregiver.training_administration_date_string +-->
      <!--                      " von " +-->
      <!--                      (caregiver.caregiver.training_administration_from ||-->
      <!--                        "??") +-->
      <!--                      ")"-->
      <!--                    : ""-->
      <!--                }}</strong-->
      <!--              >-->
      <!--            </div>-->
      <!--          </div>-->
      <!--          <div class="detail-information">-->
      <!--            <div class="detail-key clickable">-->
      <!--              <span-->
      <!--                tooltipPosition="left"-->
      <!--                (mouseenter)="-->
      <!--                  getLatestChange(caregiver.caregiver.id, 'caregiver', {-->
      <!--                    hospitation_dates: caregiver.caregiver.hospitation_dates,-->
      <!--                    hospitation: caregiver.caregiver.hospitation,-->
      <!--                    hospitation_from: caregiver.caregiver.hospitation_from-->
      <!--                  })-->
      <!--                "-->
      <!--                (click)="-->
      <!--                  openDiffViewHistory(caregiver.caregiver.id, 'caregiver', {-->
      <!--                    hospitation_dates: caregiver.caregiver.hospitation_dates,-->
      <!--                    hospitation: caregiver.caregiver.hospitation,-->
      <!--                    hospitation_from: caregiver.caregiver.hospitation_from-->
      <!--                  })-->
      <!--                "-->
      <!--                [pTooltip]="-->
      <!--                  latestChanges.hospitation_dates ||-->
      <!--                  latestChanges.hospitation_from ||-->
      <!--                  latestChanges.hospitation-->
      <!--                "-->
      <!--                >Hospitation?</span-->
      <!--              >-->
      <!--            </div>-->
      <!--            <div class="detail-value">-->
      <!--              <strong-->
      <!--                >{{ caregiver.caregiver.hospitation }}-->
      <!--                {{-->
      <!--                  caregiver.caregiver.hospitation_dates-->
      <!--                    ? "(" +-->
      <!--                      caregiver.caregiver.hospitation_dates +-->
      <!--                      " von " +-->
      <!--                      (caregiver.caregiver.hospitation_from || "??") +-->
      <!--                      ")"-->
      <!--                    : ""-->
      <!--                }}</strong-->
      <!--              >-->
      <!--            </div>-->
      <!--          </div>-->
      <!--          <div class="detail-information">-->
      <!--            <div class="detail-key clickable">-->
      <!--              <span-->
      <!--                tooltipPosition="left"-->
      <!--                (mouseenter)="-->
      <!--                  getLatestChange(caregiver.caregiver.id, 'caregiver', {-->
      <!--                    hospitation_feedback:-->
      <!--                      caregiver.caregiver.hospitation_feedback-->
      <!--                  })-->
      <!--                "-->
      <!--                (click)="-->
      <!--                  openDiffViewHistory(caregiver.caregiver.id, 'caregiver', {-->
      <!--                    hospitation_feedback:-->
      <!--                      caregiver.caregiver.hospitation_feedback-->
      <!--                  })-->
      <!--                "-->
      <!--                [pTooltip]="latestChanges.hospitation_feedback"-->
      <!--                >Hospitation Feedback</span-->
      <!--              >-->
      <!--            </div>-->
      <!--            <div class="detail-value">-->
      <!--              <strong-->
      <!--                >{{ caregiver.caregiver.hospitation_feedback || "-" }}-->
      <!--              </strong>-->
      <!--            </div>-->
      <!--          </div>-->
      <!--        </div>-->
      <!--      </div>-->

      <div
        class="detail-information-container detail-information-current-time-table"
        *ngIf="caregiver.caregiver.current_status.status === 'EMPLOYMENT'"
      >
        <div class="detail-information-header">
          <span><i class="pi pi-clock"></i> Arbeitszeiten </span>
          <i
            class="pi pi-plus"
            (click)="openTimesDialog()"
            tooltipPosition="left"
            pTooltip="Neue Arbeitszeiten"
          ></i>
        </div>
        <div class="detail-information-body without-padding">
          <p-table
            [value]="[caregiver.caregiver.current_time]"
            [rowHover]="true"
          >
            <ng-template pTemplate="header">
              <tr>
                <th>Montag</th>
                <th>Dienstag</th>
                <th>Mittwoch</th>
                <th>Donnerstag</th>
                <th>Freitag</th>
                <th>Samstag</th>
                <th style="width: 70px"></th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-time>
              <tr>
                <td>
                  <span *ngIf="!time.days_with_key.monday.checked">-</span>
                  <span *ngIf="time.days_with_key.monday.checked">
                    {{ time.days_with_key.monday.start_first || "??" }} -
                    {{ time.days_with_key.monday.end_first || "??" }}
                  </span>
                  <span *ngIf="time.days_with_key.monday.has_second_block">
                    <br />
                    {{ time.days_with_key.monday.start_second || "??" }} -
                    {{ time.days_with_key.monday.end_second || "??" }}
                  </span>
                </td>

                <td>
                  <span *ngIf="!time.days_with_key.tuesday.checked">-</span>
                  <span *ngIf="time.days_with_key.tuesday.checked">
                    {{ time.days_with_key.tuesday.start_first || "??" }} -
                    {{ time.days_with_key.tuesday.end_first || "??" }}
                  </span>
                  <span *ngIf="time.days_with_key.tuesday.has_second_block">
                    <br />
                    {{ time.days_with_key.tuesday.start_second || "??" }} -
                    {{ time.days_with_key.tuesday.end_second || "??" }}
                  </span>
                </td>

                <td>
                  <span *ngIf="!time.days_with_key.wednesday.checked">-</span>
                  <span *ngIf="time.days_with_key.wednesday.checked">
                    {{ time.days_with_key.wednesday.start_first || "??" }} -
                    {{ time.days_with_key.wednesday.end_first || "??" }}
                  </span>
                  <span *ngIf="time.days_with_key.wednesday.has_second_block">
                    <br />
                    {{ time.days_with_key.wednesday.start_second || "??" }} -
                    {{ time.days_with_key.wednesday.end_second || "??" }}
                  </span>
                </td>

                <td>
                  <span *ngIf="!time.days_with_key.thursday.checked">-</span>
                  <span *ngIf="time.days_with_key.thursday.checked">
                    {{ time.days_with_key.thursday.start_first || "??" }} -
                    {{ time.days_with_key.thursday.end_first || "??" }}
                  </span>
                  <span *ngIf="time.days_with_key.thursday.has_second_block">
                    <br />
                    {{ time.days_with_key.thursday.start_second || "??" }} -
                    {{ time.days_with_key.thursday.end_second || "??" }}
                  </span>
                </td>

                <td>
                  <span *ngIf="!time.days_with_key.friday.checked">-</span>
                  <span *ngIf="time.days_with_key.friday.checked">
                    {{ time.days_with_key.friday.start_first || "??" }} -
                    {{ time.days_with_key.friday.end_first || "??" }}
                  </span>
                  <span *ngIf="time.days_with_key.friday.has_second_block">
                    <br />
                    {{ time.days_with_key.friday.start_second || "??" }} -
                    {{ time.days_with_key.friday.end_second || "??" }}
                  </span>
                </td>

                <td>
                  <span *ngIf="!time.days_with_key.saturday.checked">-</span>
                  <span *ngIf="time.days_with_key.saturday.checked">
                    {{ time.days_with_key.saturday.start_first || "??" }} -
                    {{ time.days_with_key.saturday.end_first || "??" }}
                  </span>
                  <span *ngIf="time.days_with_key.saturday.has_second_block">
                    <br />
                    {{ time.days_with_key.saturday.start_second || "??" }} -
                    {{ time.days_with_key.saturday.end_second || "??" }}
                  </span>
                </td>

                <td>
                  <div style="display: flex; align-items: center; gap: 5px">
                    <i
                      *ngIf="caregiver.caregiver.works_on_holidays"
                      class="pi pi-sun color-blue"
                      tooltipPosition="left"
                      pTooltip="An Feiertagen"
                    ></i>
                    <i
                      style="font-size: 14px"
                      *ngIf="time.comment"
                      [pTooltip]="time.comment || ''"
                      tooltipPosition="left"
                      class="pi pi-info-circle"
                    ></i>
                  </div>
                </td>
              </tr>
            </ng-template>
          </p-table>
        </div>
        <div class="detail-information-body without-padding">
          <div
            *ngIf="caregiver.caregiver.current_status.status === 'EMPLOYMENT'"
            class="detail-information-subpanel"
            (click)="openedDetails.past_times = !openedDetails.past_times"
          >
            Vergangene Arbeitszeiten

            <i
              class="pi"
              [class.pi-chevron-down]="openedDetails.past_times"
              [class.pi-chevron-up]="!openedDetails.past_times"
            ></i>
          </div>
        </div>
        <div
          class="detail-information-body"
          *ngIf="
            caregiver.caregiver.past_times.length === 0 &&
            openedDetails.past_times
          "
        >
          <span class="content-no-message">Keine Arbeitszeiten vorhanden</span>
        </div>
        <div
          class="detail-information-body without-padding"
          *ngIf="
            openedDetails.past_times &&
            caregiver.caregiver.past_times.length > 0
          "
        >
          <p-table [value]="caregiver.caregiver.past_times" [rowHover]="true">
            <ng-template pTemplate="header">
              <tr>
                <th>Montag</th>
                <th>Dienstag</th>
                <th>Mittwoch</th>
                <th>Donnerstag</th>
                <th>Freitag</th>
                <th>Samstag</th>
                <th>Bis</th>
                <th style="width: 30px"></th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-time>
              <tr>
                <td>
                  <span *ngIf="!time.days_with_key.monday.checked">-</span>
                  <span *ngIf="time.days_with_key.monday.checked">
                    {{ time.days_with_key.monday.start_first || "??" }} -
                    {{ time.days_with_key.monday.end_first || "??" }}
                  </span>
                  <span *ngIf="time.days_with_key.monday.has_second_block">
                    <br />
                    {{ time.days_with_key.monday.start_second || "??" }} -
                    {{ time.days_with_key.monday.end_second || "??" }}
                  </span>
                </td>

                <td>
                  <span *ngIf="!time.days_with_key.tuesday.checked">-</span>
                  <span *ngIf="time.days_with_key.tuesday.checked">
                    {{ time.days_with_key.tuesday.start_first || "??" }} -
                    {{ time.days_with_key.tuesday.end_first || "??" }}
                  </span>
                  <span *ngIf="time.days_with_key.tuesday.has_second_block">
                    <br />
                    {{ time.days_with_key.tuesday.start_second || "??" }} -
                    {{ time.days_with_key.tuesday.end_second || "??" }}
                  </span>
                </td>

                <td>
                  <span *ngIf="!time.days_with_key.wednesday.checked">-</span>
                  <span *ngIf="time.days_with_key.wednesday.checked">
                    {{ time.days_with_key.wednesday.start_first || "??" }} -
                    {{ time.days_with_key.wednesday.end_first || "??" }}
                  </span>
                  <span *ngIf="time.days_with_key.wednesday.has_second_block">
                    <br />
                    {{ time.days_with_key.wednesday.start_second || "??" }} -
                    {{ time.days_with_key.wednesday.end_second || "??" }}
                  </span>
                </td>

                <td>
                  <span *ngIf="!time.days_with_key.thursday.checked">-</span>
                  <span *ngIf="time.days_with_key.thursday.checked">
                    {{ time.days_with_key.thursday.start_first || "??" }} -
                    {{ time.days_with_key.thursday.end_first || "??" }}
                  </span>
                  <span *ngIf="time.days_with_key.thursday.has_second_block">
                    <br />
                    {{ time.days_with_key.thursday.start_second || "??" }} -
                    {{ time.days_with_key.thursday.end_second || "??" }}
                  </span>
                </td>

                <td>
                  <span *ngIf="!time.days_with_key.friday.checked">-</span>
                  <span *ngIf="time.days_with_key.friday.checked">
                    {{ time.days_with_key.friday.start_first || "??" }} -
                    {{ time.days_with_key.friday.end_first || "??" }}
                  </span>
                  <span *ngIf="time.days_with_key.friday.has_second_block">
                    <br />
                    {{ time.days_with_key.friday.start_second || "??" }} -
                    {{ time.days_with_key.friday.end_second || "??" }}
                  </span>
                </td>

                <td>
                  <span *ngIf="!time.days_with_key.saturday.checked">-</span>
                  <span *ngIf="time.days_with_key.saturday.checked">
                    {{ time.days_with_key.saturday.start_first || "??" }} -
                    {{ time.days_with_key.saturday.end_first || "??" }}
                  </span>
                  <span *ngIf="time.days_with_key.saturday.has_second_block">
                    <br />
                    {{ time.days_with_key.saturday.start_second || "??" }} -
                    {{ time.days_with_key.saturday.end_second || "??" }}
                  </span>
                </td>

                <td>{{ time.until | date: "dd.MM.y" }}</td>

                <td>
                  <i
                    style="font-size: 14px"
                    *ngIf="time.comment"
                    [pTooltip]="time.comment || ''"
                    tooltipPosition="left"
                    class="pi pi-info-circle"
                  ></i>
                </td>
              </tr>
            </ng-template>
          </p-table>
        </div>
      </div>

      <div class="detail-information-container">
        <div class="detail-information-header">
          <span><i class="pi pi-car"></i>PKW</span>
          <i
            class="pi pi-plus"
            tooltipPosition="left"
            (click)="openAddCaregiverCarDialog()"
            pTooltip="Neuer PKW"
          ></i>
        </div>
        <div
          class="detail-information-body"
          *ngIf="caregiver.caregiver.cars.length === 0"
        >
          <span class="content-no-message">Kein PKW vorhanden</span>
        </div>
        <div
          class="detail-information-body without-padding"
          *ngIf="caregiver.caregiver.cars.length > 0"
        >
          <p-table [value]="caregiver.caregiver.cars" [rowHover]="true">
            <ng-template pTemplate="header">
              <tr>
                <th>Name</th>
                <th style="width: 120px">seit</th>
                <th style="width: 120px">bis</th>
                <th style="width: 90px">KM</th>
                <th style="width: 100px">Kommentar</th>
                <th style="width: 50px"></th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-car>
              <tr>
                <td>
                  <a routerLink="/cars/{{ car.car_id }}">{{
                    car.car.full_name
                  }}</a>
                </td>
                <td>{{ car.since | date: "dd.MM.y" }}</td>
                <td *ngIf="car.until">{{ car.until | date: "dd.MM.y" }}</td>
                <td *ngIf="!car.until">-</td>
                <td>{{ car.mileage || "-" }}</td>
                <td>
                  <i
                    *ngIf="car.comment"
                    class="pi pi-info-circle"
                    [pTooltip]="car.comment"
                  ></i>
                </td>
                <td>
                  <i
                    class="pi pi-pencil"
                    (click)="openAddCaregiverCarDialog(car)"
                  ></i>
                </td>
              </tr>
            </ng-template>
          </p-table>
        </div>
      </div>
    </div>
  </div>
</div>
