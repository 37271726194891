import { TodoModel } from '../todo/todo.model'
import { CaregiverDetailModel } from './caregiver-detail.model'
import { CaregiverFormModel } from './caregiver-form.model'
import { UserModel } from '../user/user.model'
import {CaregiverHospitationFeedbackModel} from "./caregiver-hospitation-feedback.model";

export class CaregiverStatusModel {
  id = 0
  caregiver_id = 0
  status = 'APPLICANT'
  status_text = ''
  hospitation_caregiver_id: number | null = null
  hospitation_coordination_type = ''
  hospitation_feedback = ''
  canceled_from_type = ''
  hospitation_confirmed = false
  hospitation_coordination_at: Date | null = null
  hospitation_coordination_at_string = ''
  hospitation_from: Date | null = null
  hospitation_from_string = ''
  hospitation_to: Date | null = null
  hospitation_to_string = ''
  hospitation_coordination_user_id: number | null = null
  canceled_from_user_id: number | null = null
  edit_from_user_id: number | null = null
  canceled_reason = ''
  edit_at_string = ''
  canceled_type = ''
  canceled_at: Date | null = null
  edit_at: Date | null = null
  edit_info = ''
  canceled_at_string = ''
  job_interview_at: Date | null | string = null
  job_interview_at_string = ''
  job_interview_with = ''
  inactive_reason = ''
  inactive_since: Date | null = null
  inactive_since_string = ''
  employed_until: Date | null = null
  employed_until_string = ''
  with_todo = true
  with_email = false
  todo: TodoModel | null = null
  deleted_at: Date | null = null
  created_at: Date | null = null
  updated_at: Date | null = null

  caregiver_hospitation_feedback?: CaregiverHospitationFeedbackModel = new CaregiverHospitationFeedbackModel()
  hospitation_with_caregiver?: CaregiverStatusModelCaregiver = new CaregiverStatusModelCaregiver()
  coordination_from_user?: UserModel = new UserModel()
  caregiver?: CaregiverStatusModelCaregiver = new CaregiverStatusModelCaregiver()
}

export class CaregiverStatusModelCaregiver {
  id = 0
  first_name = ''
  last_name = ''
  form_of_address = ''
  full_name = ''
}
