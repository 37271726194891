import { AuthGuard } from '../guards/auth.guard'
import { CarsListComponent } from '../views/cars/list/cars-list.component'
import { CarsListActiveComponent } from '../views/cars/list/active/cars-list-active.component'
import { CarsListDeactivatedComponent } from '../views/cars/list/deactivated/cars-list-deactivated.component'
import { CarsFormComponent } from '../views/cars/form/cars-form.component'
import { DirtyCheckGuard } from '../guards/dirty-check.guard'
import { CarsDetailComponent } from '../views/cars/detail/cars-detail.component'
import { CarsDetailGeneralComponent } from '../views/cars/detail/general/cars-detail-general.component'
import { CarsDetailDamageComponent } from '../views/cars/detail/damage/cars-detail-damage.component'
import { CarsDetailDocumentsComponent } from '../views/cars/detail/documents/cars-detail-documents.component'
import { CarsDetailHistoryComponent } from '../views/cars/detail/history/cars-detail-history.component'
import { SuperAdminGuard } from '../guards/super-admin.guard'
import { CarsDetailCostsComponent } from '../views/cars/detail/costs/cars-detail-costs.component'

export const carsRoutes = [
  {
    path: 'cars',
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: CarsListComponent,
        children: [
          {
            path: '',
            component: CarsListActiveComponent,
          },
          {
            path: 'deactivated',
            component: CarsListDeactivatedComponent,
          },
        ],
      },
      {
        path: 'new',
        component: CarsFormComponent,
        canDeactivate: [DirtyCheckGuard],
      },
      {
        path: ':id',
        component: CarsDetailComponent,
        children: [
          {
            path: '',
            component: CarsDetailGeneralComponent,
          },
          {
            path: 'damage',
            component: CarsDetailDamageComponent,
          },
          {
            path: 'costs',
            component: CarsDetailCostsComponent,
          },
          {
            path: 'documents',
            component: CarsDetailDocumentsComponent,
          },
          {
            path: 'history',
            component: CarsDetailHistoryComponent,
          },
        ],
      },
      {
        path: ':id/edit',
        component: CarsFormComponent,
        canDeactivate: [DirtyCheckGuard],
      },
    ],
  },
]
