export class CapacityResponseModel {
  Vollzeit: CapacityModel[] = []
  Teilzeit: CapacityModel[] = []
  Halbtag: CapacityModel[] = []
  Minijob: CapacityModel[] = []
}

export class CapacityModel {
  caregiver_id = 0
  caregiver_name = ''
  about_and_times_table = ''
  overtime = ''
  accumulated_single = ''
  job_scope = ''
  data = new CapacityDataModel()
}

export class CapacityDataModel {
  monday = new CapacityDataDayModel()
  tuesday = new CapacityDataDayModel()
  wednesday = new CapacityDataDayModel()
  thursday = new CapacityDataDayModel()
  friday = new CapacityDataDayModel()
  saturday = new CapacityDataDayModel()
  sunday = new CapacityDataDayModel()
}

export class CapacityDataDayModel {
  free_time_request = []
  training = []
  other = []
  vacation = []
  intern = []
  ill = []
  holiday = []
  capacities: string[] = []
  planed = false
}
