import { AuthGuard } from '../guards/auth.guard'
import { PlanningsComponent } from '../views/plannings/plannings.component'
import { VacationPlanningsComponent } from '../views/plannings/vacation-plannings/vacation-plannings.component'
import { ChangeRequiredPlanningsComponent } from '../views/plannings/change-required-plannings/change-required-plannings.component'

export const planningsRoutes = [
  {
    path: 'plannings',
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: PlanningsComponent,
        children: [
          {
            path: '',
            pathMatch: 'full',
            redirectTo: 'change-required',
          },
          {
            path: 'vacation',
            component: VacationPlanningsComponent,
          },
          {
            path: 'change-required',
            component: ChangeRequiredPlanningsComponent,
          },
        ],
      },
    ],
  },
]
