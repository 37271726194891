<div class="content-actions">
  <h2>
    <i class="tio">checkmark_circle_outlined</i>
    Todos
  </h2>
</div>

<header class="content-header-tabs">
  <a
    routerLink="/todos"
    routerLinkActive="active"
    [routerLinkActiveOptions]="{ exact: true }"
  >
    Offene
  </a>
  <a
    routerLink="/todos/finished"
    routerLinkActive="active"
    [routerLinkActiveOptions]="{ exact: true }"
  >
    Erledigte
  </a>
  <a
    routerLink="/todos/own"
    routerLinkActive="active"
    [routerLinkActiveOptions]="{ exact: true }"
  >
    Meine erstellten
  </a>
</header>

<router-outlet></router-outlet>
