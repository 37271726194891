<form #form="ngForm" class="dialog-form">
  <div class="dialog-sidebar space-between" *ngIf="withDocuments">
    <div class="p-fluid p-formgrid p-grid without-border">
      <div class="p-field p-col-12">
        <div class="dialog-text-link">
          <span (click)="openAddDocumentDialog()">Dokument hochladen</span>
        </div>
      </div>
    </div>
    <div class="p-fluid p-formgrid p-grid without-border">
      <div class="p-field p-col-12">
        <label class="highlight-label">Hochgeladene Dokumente</label>
        <div
          class="dialog-no-message"
          *ngIf="data.history.documents.length === 0"
        >
          Keine Dokumente hochgeladen
        </div>
        <div
          class="document-list"
          *ngFor="let document of data.history.documents"
        >
          <span
            >{{ document.name }}
            <a
              [href]="documentService.getDocumentDownloadLink(document.uuid)"
              target="_blank"
              >(ansehen)</a
            ></span
          >
        </div>
      </div>
    </div>
  </div>

  <div class="dialog-form-body">
    <div
      class="p-field"
      *ngIf="
        !data.edit &&
        data.type === 'patient' &&
        !data.is_birthday_wish &&
        patientOptions.length > 1
      "
    >
      <label for="patient">Patient *</label>
      <p-dropdown
        inputId="patient"
        name="patient"
        placeholder="Bitte auswählen"
        [options]="patientOptions"
        [(ngModel)]="data.type_id"
        optionLabel="full_name"
        optionValue="id"
        [required]="true"
        #patient="ngModel"
        [class.p-invalid]="
          patient.invalid && (patient.dirty || patient.touched)
        "
      ></p-dropdown>
    </div>

    <div class="p-field">
      <label for="manual_type">Typ *</label>
      <p-dropdown
        inputId="manual_type"
        name="manual_type"
        placeholder="Bitte auswählen"
        [options]="historyTypeOptions"
        optionLabel="label"
        scrollHeight="400px"
        optionValue="value"
        [(ngModel)]="data.history.manual_type"
        [required]="true"
        #manual_type="ngModel"
        [class.p-invalid]="
          manual_type.invalid && (manual_type.dirty || manual_type.touched)
        "
      ></p-dropdown>
      <div
        *ngIf="
          manual_type.invalid && (manual_type.dirty || manual_type.touched)
        "
        class="p-invalid"
      >
        <div *ngIf="manual_type.errors?.required">
          <small>Bitte wählen Sie einen Typ</small>
        </div>
      </div>
    </div>
    <div *ngIf="data.history.manual_type == 'misc'" class="p-field">
      <label for="manual_title">Titel *</label>
      <input
        id="manual_title"
        name="manual_title"
        type="text"
        [(ngModel)]="data.history.manual_title"
        pInputText
        #manual_title="ngModel"
        [required]="data.history.manual_type == 'misc'"
      />
      <div
        *ngIf="
          manual_title.invalid && (manual_title.dirty || manual_title.touched)
        "
        class="p-invalid"
      >
        <div *ngIf="manual_title.errors?.required">
          <small>Bitte geben Sie einen Titel ein</small>
        </div>
      </div>
    </div>

    <div
      class="p-field"
      *ngIf="data.history.manual_type == 'appointment_transfer'"
    >
      <label for="appointment_transfer">Terminübergabe für *</label>
      <div class="p-formgroup-inline">
        <div class="p-field-radiobutton">
          <p-radioButton
            label="Aktuellen Monat"
            name="appointment_transfer"
            [(ngModel)]="data.history.appointment_transfer"
            value="CURRENT_MONTH"
            #appointment_transfer="ngModel"
            required
          ></p-radioButton>
        </div>
        <div class="p-field-radiobutton">
          <p-radioButton
            label="Nächsten Monat"
            name="appointment_transfer"
            [(ngModel)]="data.history.appointment_transfer"
            value="NEXT_MONTH"
            #appointment_transfer="ngModel"
            required
          ></p-radioButton>
        </div>
      </div>
      <div
        *ngIf="
          appointment_transfer.invalid &&
          (appointment_transfer.dirty || appointment_transfer.touched)
        "
        class="p-invalid"
      >
        <div *ngIf="appointment_transfer.errors?.required">
          <small>Bitte wählen Sie etwas aus</small>
        </div>
      </div>
    </div>

    <div class="p-field">
      <label for="manual_description">Beschreibung *</label>
      <textarea
        id="manual_description"
        name="manual_description"
        type="text"
        [rows]="12"
        [(ngModel)]="data.history.manual_description"
        pInputTextarea
        required
        #manual_description="ngModel"
        [class.p-invalid]="
          manual_description.invalid &&
          (manual_description.dirty || manual_description.touched)
        "
      ></textarea>
      <div
        *ngIf="
          manual_description.invalid &&
          (manual_description.dirty || manual_description.touched)
        "
        class="p-invalid"
      >
        <div *ngIf="manual_description.errors?.required">
          <small>Bitte geben Sie eine Beschreibung ein</small>
        </div>
      </div>
    </div>
    <div class="p-field">
      <label for="occurred_at">Datum / Uhrzeit *</label>
      <div class="p-field-checkbox checkbox-with-calendar">
        <p-calendar
          inputId="occurred_at"
          name="occurred_at"
          [(ngModel)]="data.history.occurred_at_string"
          [showTime]="true"
          [showButtonBar]="true"
          hourFormat="24"
          dateFormat="dd.mm.yy,"
          appendTo="body"
          dataType="string"
          [showIcon]="true"
          [required]="true"
          [firstDayOfWeek]="1"
          [showWeek]="true"
          #occurred_at="ngModel"
          [class.p-invalid]="
            occurred_at.invalid && (occurred_at.dirty || occurred_at.touched)
          "
        ></p-calendar>

        <p-checkbox
          name="important"
          [binary]="true"
          [(ngModel)]="data.history.important"
          label="Wichtiges Ereignis"
        ></p-checkbox>

        <i></i>
      </div>
      <div
        *ngIf="
          occurred_at.invalid && (occurred_at.dirty || occurred_at.touched)
        "
        class="p-invalid"
      >
        <div *ngIf="occurred_at.errors?.required">
          <small>Bitte wählen Sie ein Datum</small>
        </div>
      </div>
    </div>

    <!-- Freigabe Multiplikator -->
    <div
      class="p-field-checkbox"
      style="margin-top: 50px"
      *ngIf="hasRecommendation"
    >
      <p-checkbox
        name="available_for_recommendation"
        [binary]="true"
        [(ngModel)]="data.history.available_for_recommendation"
        label="Freigeben für den Multiplikator"
      ></p-checkbox>
    </div>

    <!-- TO-DO Zeug -->
    <div
      class="p-field-checkbox"
      style="margin-top: 50px"
      *ngIf="todo.is_possible"
    >
      <p-checkbox
        name="todo"
        (onChange)="setTodoTitle()"
        [binary]="true"
        [(ngModel)]="todo.create"
        label="Todo erstellen"
      ></p-checkbox>
    </div>
    <div class="p-fluid p-formgrid p-grid without-border" *ngIf="todo.create">
      <div class="p-field p-col-12">
        <label for="title">
          Titel *
        </label>
        <input
          id="title"
          name="title"
          type="text"
          [(ngModel)]="todo.title"
          pInputText
          #title="ngModel"
          [required]="true"
          [class.p-invalid]="title.invalid && (title.dirty || title.touched)"
        />
      </div>

      <div class="p-field p-col-12">
        <label for="category">Kategorie</label>
        <p-dropdown
          [showClear]="true"
          appendTo="body"
          scrollHeight="500px"
          placeholder="Optional"
          inputId="category"
          name="category"
          optionLabel="category"
          optionValue="id"
          [options]="todoCategories"
          [(ngModel)]="todo.category_id"
        ></p-dropdown>
      </div>

      <div class="p-field p-col-8">
        <label for="assignments">Zuweisung an *</label>
        <p-multiSelect
          selectedItemsLabel="{0} Benutzer ausgewählt"
          inputId="assignments"
          [options]="userSystemOptions"
          optionLabel="full_name"
          name="assignments"
          [filter]="true"
          [(ngModel)]="todo.user_systems"
          appendTo="body"
          [required]="true"
        ></p-multiSelect>
      </div>

      <div class="p-field p-col-4">
        <label for="priority">Priorität *</label>
        <p-dropdown
          inputId="priority"
          name="priority"
          [options]="priorityOptions"
          [(ngModel)]="todo.priority"
          appendTo="body"
        ></p-dropdown>
      </div>
    </div>

    <div class="p-field" style="margin-top: 15px" *ngIf="todo.create">
      <div class="p-formgroup-inline">
        <div class="p-field-radiobutton">
          <p-radioButton
            label="Zu erledigen bis"
            name="for_when_type"
            [(ngModel)]="todo.for_when_type"
            value="FINISH_UNTIL"
            required
          ></p-radioButton>
          <i
            class="pi pi-info-circle"
            pTooltip="Wird immer im Dashboard angezeigt"
          ></i>
        </div>
        <div class="p-field-radiobutton">
          <p-radioButton
            label="Zu erledigen am"
            name="for_when_type"
            [(ngModel)]="todo.for_when_type"
            value="FINISH_AT"
            required
          ></p-radioButton>
          <i
            class="pi pi-info-circle"
            pTooltip="Wird ab dem Zeitpunkt im Dashboard angezeigt"
          ></i>
        </div>
      </div>
    </div>

    <div class="p-field" *ngIf="todo.create">
      <p-calendar
        [firstDayOfWeek]="1"
        [showWeek]="true"
        inputId="for_when"
        name="for_when"
        [showButtonBar]="true"
        [(ngModel)]="todo.for_when_string"
        dateFormat="dd.mm.yy"
        appendTo="body"
        dataType="string"
        [showIcon]="true"
        [required]="true"
        #for_when="ngModel"
        [class.p-invalid]="
          for_when.invalid && (for_when.dirty || for_when.touched)
        "
      ></p-calendar>
      <div
        *ngIf="for_when.invalid && (for_when.dirty || for_when.touched)"
        class="p-invalid"
      >
        <div *ngIf="for_when.errors?.required">
          <small>Bitte wählen Sie ein Datum</small>
        </div>
      </div>
    </div>
  </div>
  <div class="dialog-form-footer">
    <i></i>

    <button
      (click)="save()"
      pButton
      label="Speichern"
      type="button"
      [pTooltip]="canEdit ? '' : 'Nur der Ersteller darf die History bearbeiten'"
      [disabled]="submitted || !canEdit"
      [icon]="submitted ? 'pi pi-spin pi-spinner' : 'pi pi-check'"
    ></button>
  </div>
</form>
