<form #form="ngForm" class="dialog-form">
  <div class="dialog-form-body">
    <div class="p-fluid p-formgrid p-grid">
      <div class="p-field p-col-12">
        <label for="drive_time">Korrektur *</label>
        <p-inputNumber
          mode="decimal"
          locale="de-DE"
          [minFractionDigits]="2"
          [maxFractionDigits]="2"
          inputId="drive_time"
          name="drive_time"
          suffix=" Std"
          [(ngModel)]="data.real_value"
          [required]="true"
          #drive_time="ngModel"
          [class.p-invalid]="
            drive_time.invalid && (drive_time.dirty || drive_time.touched)
          "
        ></p-inputNumber>
        <div
          *ngIf="drive_time.invalid && (drive_time.dirty || drive_time.touched)"
          class="p-invalid"
        >
          <div *ngIf="drive_time.errors?.required">
            <small>Bitte geben Sie einen Wert ein</small>
          </div>
        </div>
      </div>
    </div>

    <div class="p-fluid p-formgrid p-grid">
      <div class="p-field p-col-12">
        <label for="change_reason">Bemerkung *</label>
        <textarea
          id="change_reason"
          name="change_reason"
          [rows]="3"
          pInputTextarea
          [(ngModel)]="data.change_reason"
          [required]="true"
          #change_reason="ngModel"
          [class.p-invalid]="
            change_reason.invalid &&
            (change_reason.dirty || change_reason.touched)
          "
        ></textarea>
      </div>
    </div>
  </div>

  <div class="dialog-form-footer">
    <i></i>
    <button
      pButton
      label="Zeiten ändern"
      type="submit"
      (click)="save()"
      [disabled]="submitted"
      [icon]="submitted ? 'pi pi-spin pi-spinner' : 'pi pi-check'"
    ></button>
  </div>
</form>
