<form #form="ngForm" class="dialog-form">
  <div class="dialog-loader" *ngIf="loading">
    <i class="loading-logo"></i>
  </div>

  <div class="dialog-form-body" *ngIf="!loading && data.length === 0">
    <span class="content-no-message">Keine Termine vorhanden</span>
  </div>

  <div
    class="content-body dialog-max-height without-padding"
    *ngIf="!loading && data.length > 0"
  >
    <p-table
      #dt
      [value]="data"
      [rowHover]="true"
      [autoLayout]="true"
      [globalFilterFields]="[
        'name',
      ]"
    >
      <ng-template pTemplate="header">
        <tr>
          <th style="min-width: 150px">Datum</th>
          <th>Uhrzeit</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-item>
        <tr>
          <td>{{ item.date }} ({{ item.day }})</td>
          <td>{{ item.from }} - {{ item.to }}</td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</form>
