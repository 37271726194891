import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core'
import { ActivatedRoute, ParamMap, Router } from '@angular/router'
import { DialogService } from 'primeng/dynamicdialog'
import { DiffViewDialogComponent } from '../../../../components/dialogs/diff-view-dialog/diff-view-dialog.component'
import { HistoryListModel } from '../../../../models/history/history-list.model'
import { HistoryManualDialogComponent } from '../../../../components/dialogs/history-manual-dialog/history-manual-dialog.component'
import {
  EventBusService,
  GlobalEvent,
} from '../../../../services/eventbus.service'
import { Subscription } from 'rxjs'
import { PatientService } from '../../../../services/patient.service'
import { PhoneCallsListModel } from '../../../../models/phone-call/phone-calls-list.model'
import { PhoneCallEditDialogComponent } from '../../../../components/dialogs/phone-call-edit-dialog/phone-call-edit-dialog.component'
import { TodoModel } from '../../../../models/todo/todo.model'
import { OfferService } from '../../../../services/offer.service'
import { SendAppointmentsService } from '../../../../services/send-appointments.service'
import { SendOfferService } from '../../../../services/send-offer.service'
import { PatientDetailHistoryModel } from '../../../../models/customer-patient/patient-detail-history.model'
import { SendLetterService } from '../../../../services/send-letter.service'
import { ShowTodoDialogComponent } from '../../../../components/dialogs/show-todo-dialog/show-todo-dialog.component'

@Component({
  selector: 'app-patients-detail-history',
  templateUrl: './patients-detail-history.component.html',
  providers: [DialogService],
})
export class PatientsDetailHistoryComponent implements OnInit, OnDestroy {
  @ViewChild('dt') table: ElementRef | any = null

  public loading = true
  public patientId: string | null = null
  public selectedPatient: 'first_patient' | 'second_patient' = 'first_patient'

  public patients: PatientDetailHistoryModel = new PatientDetailHistoryModel()
  public histories: HistoryListModel[] = []
  public globalFilterValue = ''

  public filterTypes: any = []
  public selectedFilterTypes: any = []
  public typeIsHidden: any = {}

  public onlyImportant = false
  public onlyPlanning = false

  private eventBusSubscription: Subscription = new Subscription()

  constructor(
    private patientService: PatientService,
    private offerService: OfferService,
    private sendOfferService: SendOfferService,
    private sendAppointmentsService: SendAppointmentsService,
    private sendLetterService: SendLetterService,
    private route: ActivatedRoute,
    private eventbus: EventBusService,
    private dialogService: DialogService
  ) {}

  ngOnInit(): void {
    this.setFilterValues()
    this.loadHistory()
    this.listenForEventbus()
  }

  ngOnDestroy(): void {
    this.eventBusSubscription.unsubscribe()
  }

  private setFilterValues(): void {
    this.filterTypes.push({
      value: 'PATIENT_CHANGES',
      name: 'Patientenänderung',
    })

    this.filterTypes.push({
      value: 'APPOINTMENT',
      name: 'Termine',
    })

    this.filterTypes.push({
      value: 'PHONE',
      name: 'Telefonat',
    })

    this.filterTypes.push({
      value: 'TODO',
      name: 'Todo',
    })

    this.filterTypes.push({
      value: 'VETO',
      name: 'Veto',
    })

    this.filterTypes.push({
      value: 'BUDGET',
      name: 'Budget',
    })

    this.filterTypes.push({
      value: 'CONTACT_PERSON',
      name: 'Ansprechpartner',
    })

    this.filterTypes.push({
      value: 'FEEDBACK',
      name: 'Feedback BK',
    })

    this.filterTypes.push({
      value: 'DOCUMENT',
      name: 'Dokumente',
    })

    this.filterTypes.push({
      value: 'MANUAL',
      name: 'Manuelle Einträge',
    })

    this.filterTypes.push({
      value: 'NOTICE',
      name: 'Notizen',
    })

    this.filterTypes.push({
      value: 'OFFER_LETTER',
      name: 'Angebot / Briefe',
    })

    this.filterTypes.push({
      value: 'ACCOUNTING',
      name: 'Buchhaltung',
    })

    // Alphabetisch sortieren.
    this.filterTypes.sort((a: any, b: any) => a.name.localeCompare(b.name))

    // Standardmäßig werden alle Filtermöglichkeiten ausgewählt.
    this.selectedFilterTypes = this.filterTypes.map((type: any) => type.value)

    this.initFilters()

    this.setTypeIsHidden()
  }

  private initFilters(): void {
    // Falls noch nichts im Speicher vorhanden ist, setze den Default wert.
    if (localStorage.getItem('patient-history-filters') === null) {
      localStorage.setItem(
        'patient-history-filters',
        JSON.stringify(this.selectedFilterTypes)
      )
    } else {
      // Falls aber was vorhanden ist, werden die Filter aus dem Speicher genommen.
      this.selectedFilterTypes = JSON.parse(
        localStorage.getItem('patient-history-filters') || '[]'
      )
    }
  }

  public setTypeIsHidden(): void {
    localStorage.setItem(
      'patient-history-filters',
      JSON.stringify(this.selectedFilterTypes)
    )

    this.typeIsHidden = {
      HOUSEHOLD_CHANGED: !this.selectedFilterTypes.includes('PATIENT_CHANGES'),
      QM_CHANGED: !this.selectedFilterTypes.includes('PATIENT_CHANGES'),
      PATIENT_CHANGED: !this.selectedFilterTypes.includes('PATIENT_CHANGES'),
      CUSTOMER_CHANGED: !this.selectedFilterTypes.includes('PATIENT_CHANGES'),
      CHANGED: !this.selectedFilterTypes.includes('PATIENT_CHANGES'),
      CREATED: !this.selectedFilterTypes.includes('PATIENT_CHANGES'),
      STATUS_CHANGED: !this.selectedFilterTypes.includes('PATIENT_CHANGES'),
      CUSTOMER_SERVICE_CHANGED: !this.selectedFilterTypes.includes(
        'PATIENT_CHANGES'
      ),
      PAUSED: !this.selectedFilterTypes.includes('PATIENT_CHANGES'),
      PAUSE_REACTIVATED: !this.selectedFilterTypes.includes('PATIENT_CHANGES'),
      TODO_CREATED: !this.selectedFilterTypes.includes('TODO'),
      TODO_DELETED: !this.selectedFilterTypes.includes('TODO'),
      TODO_FINISHED: !this.selectedFilterTypes.includes('TODO'),
      TODO_UPDATED: !this.selectedFilterTypes.includes('TODO'),
      VETO_CREATED: !this.selectedFilterTypes.includes('VETO'),
      VETO_DELETED: !this.selectedFilterTypes.includes('VETO'),
      VETO_UPDATED: !this.selectedFilterTypes.includes('VETO'),
      BUDGET_CHANGED: !this.selectedFilterTypes.includes('BUDGET'),
      BUDGET_REMOVED_FROM_LIST: !this.selectedFilterTypes.includes('BUDGET'),
      BUDGET_CONFIRMED: !this.selectedFilterTypes.includes('BUDGET'),
      CONTACT_PERSON_CREATED: !this.selectedFilterTypes.includes(
        'CONTACT_PERSON'
      ),
      CONTACT_PERSON_UPDATED: !this.selectedFilterTypes.includes(
        'CONTACT_PERSON'
      ),
      CONTACT_PERSON_DEACTIVATED: !this.selectedFilterTypes.includes(
        'CONTACT_PERSON'
      ),
      DOCUMENT_CHANGED: !this.selectedFilterTypes.includes('DOCUMENT'),
      DOCUMENT_REMOVED: !this.selectedFilterTypes.includes('DOCUMENT'),
      DOCUMENT_ARCHIVED: !this.selectedFilterTypes.includes('DOCUMENT'),
      DOCUMENT_UNARCHIVED: !this.selectedFilterTypes.includes('DOCUMENT'),
      APPOINTMENTS_SENT: !this.selectedFilterTypes.includes('APPOINTMENT'),
      DESIRED_DATE_CREATED: !this.selectedFilterTypes.includes('APPOINTMENT'),
      DESIRED_DATE_UPDATED: !this.selectedFilterTypes.includes('APPOINTMENT'),
      DESIRED_DATE_DELETED: !this.selectedFilterTypes.includes('APPOINTMENT'),
      CANCELLED_DATE_CREATED: !this.selectedFilterTypes.includes('APPOINTMENT'),
      CANCELLED_DATE_UPDATED: !this.selectedFilterTypes.includes('APPOINTMENT'),
      CANCELLED_DATE_DELETED: !this.selectedFilterTypes.includes('APPOINTMENT'),
      PHONE_CALL: !this.selectedFilterTypes.includes('PHONE'),
      MANUAL: !this.selectedFilterTypes.includes('MANUAL'),
      NOTICE_CHANGED: !this.selectedFilterTypes.includes('NOTICE'),
      NOTICE_ACCOUNTING_CHANGED: !this.selectedFilterTypes.includes('NOTICE'),
      OFFER_SENT: !this.selectedFilterTypes.includes('OFFER_LETTER'),
      LETTER_SENT: !this.selectedFilterTypes.includes('OFFER_LETTER'),
      APPOINTMENT_FEEDBACK_FINISHED_WITH_HISTORY: !this.selectedFilterTypes.includes(
        'FEEDBACK'
      ),
      ACCOUNTING_RETURN_CREATED: !this.selectedFilterTypes.includes(
        'ACCOUNTING'
      ),
      ACCOUNTING_RETURN_DELETED: !this.selectedFilterTypes.includes(
        'ACCOUNTING'
      ),
    }
  }

  public filterTableGlobal(event: any): void {
    this.table && this.table.filterGlobal(event.target.value.trim(), 'contains')
  }

  public openOfferMail(offerId: string): void {
    window.open(this.sendOfferService.getMailLink(offerId))
  }

  public openAppointmentsMail(appointmentId: string): void {
    window.open(this.sendAppointmentsService.getMailLink(appointmentId))
  }

  public openLetterMail(letterId: string): void {
    window.open(this.sendLetterService.getMailLink(letterId))
  }

  public changePatient(type: 'first_patient' | 'second_patient'): void {
    this.selectedPatient = type
  }

  public openDiffView(id: number): void {
    this.dialogService.open(DiffViewDialogComponent, {
      data: {
        id,
      },
      header: 'Änderungen ansehen',
      styleClass: 'dialog-diff-view',
      dismissableMask: true,
    })
  }

  public openShowTodoDialog(todo: TodoModel): void {
    this.dialogService.open(ShowTodoDialogComponent, {
      header: 'Todo ansehen',
      width: '820px',
      styleClass: 'dialog-container',
      data: {
        isEdit: true,
        todo,
        todo_id: todo.id,
        user_type_name: todo?.patient?.full_name,
      },
    })
  }

  public openManualHistoryDialog(
    edit: boolean,
    history?: HistoryListModel
  ): void {
    this.dialogService.open(HistoryManualDialogComponent, {
      data: {
        edit,
        history: history || new HistoryListModel(),
        type: 'patients',
        type_id: this.patients[this.selectedPatient]?.id,
        patients: this.patients,
      },
      header: history ? 'Eintrag bearbeiten' : 'Neuer Eintrag',
      styleClass: 'dialog-container',
      dismissableMask: false,
      width: '520px',
    })
  }

  private listenForEventbus(): void {
    this.eventBusSubscription = this.eventbus.subject.subscribe(
      (event: GlobalEvent) => {
        switch (event) {
          case GlobalEvent.PhoneCallListReload:
          case GlobalEvent.HistoryListReload:
          case GlobalEvent.TodoChanged:
            this.loadHistory(false)
        }
      }
    )
  }

  /**
   * Öffnet das Modal zum Bearbeiten eines Phonecalls.
   */
  public openPhonecallEditModal(phonecall: PhoneCallsListModel): void {
    this.dialogService.open(PhoneCallEditDialogComponent, {
      header: 'Telefonat bearbeiten',
      width: '540px',
      styleClass: 'dialog-container',
      dismissableMask: false,
      data: phonecall,
    })
  }

  public loadHistory(withLoading: boolean = true): void {
    if (withLoading) {
      this.loading = true
    }

    if (this.route.parent) {
      // this.route.parent.paramMap.subscribe((params: ParamMap) => {
      this.patientId = this.route.parent.snapshot.params.id

      const subscription = this.onlyPlanning
        ? this.patientService.historyPlanning(this.patientId)
        : this.patientService.history(this.patientId)

      subscription.subscribe((data: PatientDetailHistoryModel) => {
        this.patients = data

        if (withLoading) {
          this.loading = false
        }
      })
    }
  }
}
