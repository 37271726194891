import { Component, OnInit } from '@angular/core'
import { DialogService } from 'primeng/dynamicdialog'
import { AddAccountingReturnDialogComponent } from '../../../components/dialogs/add-accounting-return-dialog/add-accounting-return-dialog.component'
import { AccountingReturnService } from '../../../services/accounting-return.service'
import { AccountingReturnModel } from '../../../models/accounting/accounting-return.model'
import { debounce } from 'lodash-es'
import { HelperService } from '../../../services/helper.service'
import { DocumentService } from '../../../services/document.service'
import { PatientService } from '../../../services/patient.service'
import { MenuItem } from 'primeng/api'
import { InvoiceDialogComponent } from '../../../components/dialogs/invoice-dialog/invoice-dialog.component'

@Component({
  selector: 'app-invoices-km',
  templateUrl: './invoices-km.component.html',
})
export class InvoicesKmComponent implements OnInit {
  public globalFilterValue = ''
  public loading = false
  public correctionReasons: any = {}

  public results: AccountingReturnModel[] = []

  constructor(
    public documentService: DocumentService,
    private dialogService: DialogService,
    public patientService: PatientService,
    private helperService: HelperService,
    private accountingService: AccountingReturnService
  ) {}

  ngOnInit(): void {
    this.filterTableGlobal = debounce(this.filterTableGlobal, 600)

    this.helperService.dependencies$.subscribe((data: any) => {
      data['accounting_return_reasons'].forEach((reason: any) => {
        this.correctionReasons[reason.value] = reason.label
      })
    })
  }

  public filterTableGlobal(): void {
    this.loading = true

    this.accountingService
      .search(this.globalFilterValue)
      .subscribe((results: AccountingReturnModel[]) => {
        this.results = results

        this.loading = false
      })
  }
}
