<div class="content-actions">
  <h2>
    <i class="tio">appointment</i>
    Wunschtermine
  </h2>
</div>

<header class="content-header-tabs">
  <a
    routerLink="/desired-dates"
    routerLinkActive="active"
    [routerLinkActiveOptions]="{ exact: true }"
  >
    Anstehend
  </a>
  <a
    routerLink="/desired-dates/finished"
    routerLinkActive="active"
    [routerLinkActiveOptions]="{ exact: true }"
  >
    Abgeschlossen
  </a>
</header>

<router-outlet></router-outlet>
