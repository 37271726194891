import { Component, ElementRef, OnInit, ViewChild } from '@angular/core'
import { DialogService } from 'primeng/dynamicdialog'
import { EventBusService } from 'src/app/services/eventbus.service'
import { TitleService } from 'src/app/services/title.service'
import { PhoneCallsListItemModel } from '../../../models/phone-call/phone-calls-list.model'
import { AuthService } from '../../../services/auth.service'
import { UserCaregiverService } from '../../../services/user-caregiver.service'

@Component({
  selector: 'app-user-caregiver-phone-calls',
  templateUrl: './user-caregiver-phone-calls.component.html',
  styleUrls: ['./user-caregiver-phone-calls.component.scss'],
})
export class UserCaregiverPhoneCallsComponent implements OnInit {
  @ViewChild('dt') table: ElementRef | any = null

  public globalFilterValue = ''
  public phoneCalls: PhoneCallsListItemModel[] = []
  public loading = true

  constructor(
    public userCaregiverService: UserCaregiverService,
    private authService: AuthService,
    private dialogService: DialogService,
    private eventbus: EventBusService,
    private titleService: TitleService
  ) {}

  ngOnInit(): void {
    document.body.classList.remove('mobile-nav-visible')

    this.titleService.setTitle(`Telefonate`)
    this.loadList()
  }

  private loadList(withLoading: boolean = true): void {
    if (withLoading) {
      this.loading = true
    }

    this.userCaregiverService
      .loadPhoneCalls()
      .subscribe((data: PhoneCallsListItemModel[]) => {
        this.phoneCalls = data

        if (withLoading) {
          this.loading = false
        }
      })
  }
}
