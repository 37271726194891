import { CaregiverTimeDaysModel } from './caregiver-time-days.model'
import { CaregiverDetailModel } from './caregiver-detail.model'

export class CaregiverTimeModel {
  id = 0
  comment = ''
  caregiver_id = 0
  since: Date | null = null
  until: Date | null = null
  deleted_at: Date | null = null
  created_at: Date | null = null
  updated_at: Date | null = null
  days_as_html_table = ''

  caregiver: CaregiverDetailModel = new CaregiverDetailModel()
  days: CaregiverTimeDaysModel[] = []
  days_with_key: CaregiverTimeDaysWithKeyModel = new CaregiverTimeDaysWithKeyModel()
}

export class CaregiverTimeDaysWithKeyModel {
  monday: CaregiverTimeDaysModel = new CaregiverTimeDaysModel()
  tuesday: CaregiverTimeDaysModel = new CaregiverTimeDaysModel()
  wednesday: CaregiverTimeDaysModel = new CaregiverTimeDaysModel()
  thursday: CaregiverTimeDaysModel = new CaregiverTimeDaysModel()
  friday: CaregiverTimeDaysModel = new CaregiverTimeDaysModel()
  saturday: CaregiverTimeDaysModel = new CaregiverTimeDaysModel()
}
