import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core'
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog'
import { EventBusService, GlobalEvent } from 'src/app/services/eventbus.service'
import { ToastService } from 'src/app/services/toast.service'
import { NgForm } from '@angular/forms'
import { Subscription } from 'rxjs'
import { CaregiverService } from '../../../services/caregivers.service'
import { DocumentService } from '../../../services/document.service'
import { HelperService } from '../../../services/helper.service'

@Component({
  selector: 'app-add-caregiver-proof-dialog',
  templateUrl: './add-caregiver-proof-dialog.component.html',
})
export class AddCaregiverProofDialogComponent implements OnInit, OnDestroy {
  @ViewChild('form', { static: true }) form!: NgForm

  private formSubscription: Subscription | null | undefined = null
  private isDirty = false

  submitted = false
  submittedDelete = false

  public patientOptions: any[] = []
  public data: any

  public values = {
    month: '',
    year: '',
    patients: [],
  }

  public monthOptions: any = []
  public yearOptions = [
    { label: '2019', value: '2019' },
    { label: '2020', value: '2020' },
    { label: '2021', value: '2021' },
    { label: '2022', value: '2022' },
    { label: '2023', value: '2023' },
    { label: '2024', value: '2024' },
  ]

  constructor(
    private ref: DynamicDialogRef,
    private config: DynamicDialogConfig,
    private caregiverService: CaregiverService,
    private eventbus: EventBusService,
    private documentService: DocumentService,
    private helperService: HelperService,
    private toastService: ToastService
  ) {}

  public ngOnInit(): void {
    this.data = this.config.data

    const date = new Date()
    let currentYear = date.getFullYear()
    let nextMonth = date.getMonth() + 1

    // Wenn der aktuelle Monat einen index von 11 hatte (also bereits Dezember),
    // müssen wir den nächsten Monat auf Januar (also index 0) setzen und das Jahr erhöhen.
    if (nextMonth === 12) {
      nextMonth = 0
      currentYear++
    }

    this.helperService.dependencies$.subscribe((data: any) => {
      this.monthOptions = data.months
      this.values.month = this.monthOptions[nextMonth].label
      this.values.year = currentYear.toString()
    })

    this.formSubscription = this.form.valueChanges?.subscribe(() => {
      if (!this.form.pristine) {
        this.isDirty = true
      }
    })

    this.loadPatients()
  }

  ngOnDestroy(): void {
    this.formSubscription?.unsubscribe()
  }

  public save(): void {
    if (!this.form.form.valid) {
      this.submitted = false
      this.form.form.markAllAsTouched()
      return
    }

    this.submitted = true

    this.caregiverService
      .storeProofs(this.data.caregiver_id, this.values)
      .subscribe(
        (mediaIds: []) => {
          window.open(
            this.documentService.getZipOfProofsLink(
              this.data.caregiver_id,
              this.values.month,
              this.values.year
            )
          )

          this.submitted = false
          this.eventbus.emit(GlobalEvent.CaregiverProofChanged)
          this.ref.close()
          this.toastService.success(
            'Leistungsnachweise erstellt',
            'Die Leistungsnachweise wurden erfolgreich erstellt'
          )
        },
        () => {
          this.toastService.error(
            'Etwas ist schiefgelaufen...',
            'Bitte wenden Sie sich an den Support'
          )

          this.submitted = false
        }
      )
  }

  public loadPatients(): void {
    this.values.patients = []
    this.patientOptions = []

    this.caregiverService
      .loadPatientsForMonth(this.data.caregiver_id, this.values)
      .subscribe((patients: any) => {
        for (let patient of patients) {
          this.patientOptions.push({
            id: patient.id,
            label: patient.label,
          })
        }

        // Richtige Struktur für PrimeNG zusammenstellen.
        this.values.patients = patients.map((patient: any) => {
          return {
            id: patient.id,
            label: patient.label,
          }
        })
      })
  }
}
