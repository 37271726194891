import { AuthGuard } from '../guards/auth.guard'
import { UserMultiplierPatientsComponent } from '../views/user-multiplier/patients/user-multiplier-patients.component'
import { UserMultiplierPatientsDetailComponent } from '../views/user-multiplier/patients/detail/user-multiplier-patients-detail.component'
import { UserMultiplierPatientsDetailGeneralComponent } from '../views/user-multiplier/patients/detail/general/user-multiplier-patients-detail-general.component'
import { UserMultiplierPatientsDetailHistoryComponent } from '../views/user-multiplier/patients/detail/history/user-multiplier-patients-detail-history.component'
import { UserMultiplierOfferComponent } from '../views/user-multiplier/offer/user-multiplier-offer.component'
import { DirtyCheckGuard } from '../guards/dirty-check.guard'

export const userMultiplierRoutes = [
  {
    path: 'patienten',
    canActivate: [AuthGuard],
    component: UserMultiplierPatientsComponent,
  },
  {
    path: 'neu',
    canActivate: [AuthGuard],
    canDeactivate: [DirtyCheckGuard],
    component: UserMultiplierOfferComponent,
  },
  {
    path: 'neu/:id',
    canActivate: [AuthGuard],
    canDeactivate: [DirtyCheckGuard],
    component: UserMultiplierOfferComponent,
  },
  {
    path: 'patienten/:id',
    canActivate: [AuthGuard],
    component: UserMultiplierPatientsDetailComponent,
    children: [
      {
        path: '',
        component: UserMultiplierPatientsDetailGeneralComponent,
      },
      {
        path: 'historie',
        component: UserMultiplierPatientsDetailHistoryComponent,
      },
    ],
  },
]
