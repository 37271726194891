import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs'
import { environment } from '../../environments/environment'
import {
  DesiredDateModel,
  DesiredDatesResponse,
} from '../models/desired-date/desired-date.model'

@Injectable({
  providedIn: 'root',
})
export class DesiredDateService {
  constructor(private http: HttpClient) {}

  public load(): Observable<DesiredDateModel[]> {
    return this.http.get<DesiredDateModel[]>(`${environment.api}/desired-date`)
  }

  public loadFinished(): Observable<DesiredDateModel[]> {
    return this.http.get<DesiredDateModel[]>(
      `${environment.api}/desired-date/finished`
    )
  }

  public loadForPatient(patientId: any): Observable<any> {
    return this.http.get<any>(`${environment.api}/desired-date/${patientId}`)
  }

  public store(data: {}): Observable<[]> {
    return this.http.post<[]>(`${environment.api}/desired-date`, data)
  }

  public update(id: number | string, data: {}): Observable<[]> {
    return this.http.put<[]>(`${environment.api}/desired-date/${id}`, data)
  }

  public delete(id: number | string): Observable<[]> {
    return this.http.delete<[]>(`${environment.api}/desired-date/${id}`)
  }
}
