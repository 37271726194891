<form #form="ngForm" class="dialog-form">
  <div class="dialog-form-body">
    <div class="p-field" *ngIf="!isEdit">
      <label for="file">Datei auswählen *</label>
      <input
        id="file"
        name="file"
        type="file"
        (change)="setSelectedFile($event)"
        pInputText
        [(ngModel)]="values.selected_file"
        [required]="true"
        #uploadFile="ngModel"
        [class.p-invalid]="
          uploadFile.invalid && (uploadFile.dirty || uploadFile.touched)
        "
      />
    </div>

    <div class="p-field">
      <label for="name">Name *</label>
      <input
        id="name"
        name="name"
        type="text"
        [(ngModel)]="values.name"
        pInputText
        [required]="true"
        #name="ngModel"
        [class.p-invalid]="name.invalid && (name.dirty || name.touched)"
      />
      <div
        *ngIf="name.invalid && (name.dirty || name.touched)"
        class="p-invalid"
      >
        <div *ngIf="name.errors?.required">
          <small>Bitte geben Sie einen Namen ein</small>
        </div>
      </div>
    </div>

    <div class="p-field">
      <label for="comment">Beschreibung</label>
      <textarea
        id="comment"
        name="comment"
        [rows]="2"
        [(ngModel)]="values.comment"
        pInputTextarea
      ></textarea>
    </div>

    <div class="p-field">
      <div class="p-field-checkbox">
        <p-checkbox
          name="approved_for_caregivers_"
          [binary]="true"
          label="Für Betreuungskräfte freigeben"
          [(ngModel)]="values.approved_for_caregivers"
        ></p-checkbox>
      </div>
    </div>
  </div>
  <div class="dialog-form-footer">
    <i *ngIf="!isEdit"></i>

    <div style="display: flex; gap: 20px">
      <i
        class="pi pi-trash"
        *ngIf="
          isEdit && authService.can('Verwaltung.Globale Dokumente löschen')
        "
        [class.pi-trash]="!submittedDelete"
        [class.pi-spin]="submittedDelete"
        [class.pi-spinner]="submittedDelete"
        tooltipPosition="left"
        tooltipZIndex="20000"
        [pTooltip]="submittedDelete ? 'Wird gelöscht' : 'Dokument löschen'"
        (click)="remove($event)"
      ></i>

      <i
        class="pi"
        *ngIf="isEdit && !config.data.document.media[0].is_archived"
        [class.pi-clock]="!submittedDelete"
        [class.pi-spin]="submittedDelete"
        [class.pi-spinner]="submittedDelete"
        tooltipPosition="left"
        tooltipZIndex="20000"
        [pTooltip]="submittedDelete ? 'Wird archiviert' : 'Archivieren'"
        (click)="archive($event)"
      ></i>

      <i
        class="pi"
        *ngIf="isEdit && config.data.document.media[0].is_archived"
        [class.pi-clock]="!submittedDelete"
        [class.pi-spin]="submittedDelete"
        [class.pi-spinner]="submittedDelete"
        tooltipPosition="left"
        tooltipZIndex="20000"
        [pTooltip]="
          submittedDelete ? 'Wird wiederhergestellt' : 'Wiederherstellen'
        "
        (click)="unarchive($event)"
      ></i>
    </div>

    <button
      (click)="upload()"
      pButton
      [label]="isEdit ? 'Speichern' : 'Hochladen'"
      type="button"
      [disabled]="submitted"
      [icon]="submitted ? 'pi pi-spin pi-spinner' : 'pi pi-upload'"
    ></button>
  </div>
</form>

<p-confirmPopup></p-confirmPopup>
