import { Component, OnInit } from '@angular/core'
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog'
import { ToastService } from 'src/app/services/toast.service'
import {
  EventBusService,
  GlobalEvent,
} from '../../../services/eventbus.service'
import {PlanningService} from "../../../services/planning.service";

@Component({
  selector: 'app-release-appointment-admin-dialog',
  templateUrl: './release-appointment-admin-dialog.component.html',
})
export class ReleaseAppointmentAdminDialogComponent implements OnInit {
  comment = ''
  submitted = false
  submittedDelete = false

  constructor(
    private ref: DynamicDialogRef,
    private planningService: PlanningService,
    public config: DynamicDialogConfig,
    private eventbus: EventBusService,
    private toastService: ToastService
  ) {}

  public ngOnInit(): void {
  }

  public save(): void {
    this.submitted = true

    this.planningService
      .releaseAppointmentFromAdmin(this.config.data)
      .subscribe(
        (response: any) => {
          this.submitted = false
          this.eventbus.emit(GlobalEvent.ProofsChanged)
          this.ref.close(response)
          this.toastService.success('Einsatz freigegeben')
        },
        () => {
          this.toastService.error(
            'Etwas ist schiefgelaufen...',
            'Bitte wenden Sie sich an den Support'
          )

          this.submitted = false
        }
      )
  }
}
