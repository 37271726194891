import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs'
import { environment } from '../../environments/environment'
import { GlobalDocumentModel } from '../models/document/global-document.model'

@Injectable({
  providedIn: 'root',
})
export class DocumentService {
  constructor(private http: HttpClient) {}

  public generate(type_id: string, type: string, data: object): Observable<[]> {
    return this.http.post<[]>(`${environment.api}/documents/generate`, {
      type_id,
      type,
      ...data,
    })
  }

  // public rotateImage(uuid: string): Observable<[]> {}

  public upload(form: FormData): Observable<[]> {
    return this.http.post<[]>(`${environment.api}/documents/upload`, form)
  }

  public uploadTemp(form: FormData): Observable<[]> {
    return this.http.post<[]>(`${environment.api}/documents/upload/temp`, form)
  }

  public saveProofFromBase64(
    proofStatusId: any,
    form: FormData
  ): Observable<[]> {
    return this.http.post<[]>(
      `${environment.api}/documents/${proofStatusId}/save-proof-from-base64`,
      form
    )
  }

  public uploadGlobal(form: FormData): Observable<[]> {
    return this.http.post<[]>(
      `${environment.api}/documents/global/upload`,
      form
    )
  }

  public edit(documentId: number | string, data: {}): Observable<[]> {
    return this.http.put<[]>(`${environment.api}/documents/${documentId}`, data)
  }

  public rotateImage(documentId: number | string, degree: any): Observable<[]> {
    return this.http.put<[]>(
      `${environment.api}/documents/${documentId}/rotate-image`,
      {
        degree,
      }
    )
  }

  public editGlobal(documentId: number | string, data: {}): Observable<[]> {
    return this.http.put<[]>(
      `${environment.api}/documents/global/${documentId}`,
      data
    )
  }

  public remove(documentId: number | string): Observable<[]> {
    return this.http.delete<[]>(`${environment.api}/documents/${documentId}`)
  }

  public archive(documentId: number | string): Observable<[]> {
    return this.http.delete<[]>(
      `${environment.api}/documents/${documentId}/archive`
    )
  }

  public unarchive(documentId: number | string): Observable<[]> {
    return this.http.delete<[]>(
      `${environment.api}/documents/${documentId}/unarchive`
    )
  }

  public removeGlobal(documentId: number | string): Observable<[]> {
    return this.http.delete<[]>(
      `${environment.api}/documents/global/${documentId}`
    )
  }

  public archiveGlobal(documentId: number | string): Observable<[]> {
    return this.http.delete<[]>(
      `${environment.api}/documents/global/${documentId}/archive`
    )
  }

  public unarchiveGlobal(documentId: number | string): Observable<[]> {
    return this.http.delete<[]>(
      `${environment.api}/documents/global/${documentId}/unarchive`
    )
  }

  public loadGlobalDocuments(
    onlyArchived: boolean = false
  ): Observable<GlobalDocumentModel[]> {
    return this.http.get<GlobalDocumentModel[]>(
      `${environment.api}/documents/global?only_archived=${onlyArchived}`
    )
  }

  public canUploadCheck(
    type: string,
    id: number | string
  ): Observable<{ can_upload: boolean; can_not_upload_message: '' }> {
    return this.http.get<{ can_upload: boolean; can_not_upload_message: '' }>(
      `${environment.api}/documents/can-upload-check?type=${type}&id=${id}`
    )
  }

  public getPriceChange2022MediaData(mediaId: number): Observable<any> {
    return this.http.get<any>(
      `${environment.api}/documents/price-change-2022-for-media/${mediaId}`
    )
  }

  public getPriceChange2024MediaData(mediaId: number): Observable<any> {
    return this.http.get<any>(
      `${environment.api}/documents/price-change-2024-for-media/${mediaId}`
    )
  }

  public getHospitationBlanko(): string {
    return `${environment.api}/user-caregiver/hospitation-blanko`
  }

  public getDocumentDownloadLink(uuid: string): string {
    return `${environment.api}/documents/${uuid}`
  }

  public getProofImageDocumentDownloadLink(uuid: string): string {
    const now = Date.now()

    // Wir fügen hier einen random string dazu, damit der Browser die Datei nicht cached.
    return `${environment.api}/documents/${uuid}/proof-image?random=${now}`
  }

  public getTempOptaDataImageDocumentDownloadLink(name: string): string {
    return `${environment.api}/documents/temp-opta-data-image?name=${name}`
  }

  public getBase64FromImage(uuid: string): Observable<any> {
    return this.http.get<any>(
      `${environment.api}/documents/${uuid}/base64-string`
    )
  }

  public getTempDownloadLink(name: string): string {
    return `${environment.api}/documents/temp?name=${name}`
  }

  public getZipOfProofsLink(
    id: string | null,
    month: string,
    year: string
  ): string {
    return `${environment.api}/documents/caregivers/${id}/download-zip-of-proofs?month=${month}&year=${year}`
  }

  public printAddressLink(receiver: string): string {
    return `${environment.api}/documents/print-address/${receiver}`
  }
}
