<!--<button class="dialog-close-icon-fake" (click)="close()">-->
<!--  <i class="pi pi-times"></i>-->
<!--</button>-->

<form #form="ngForm" class="dialog-form">
  <div
    class="dialog-sidebar smaller-dialog-sidebar"
    *ngIf="!loading && ['OPENED', 'FINISHED'].includes(values.status)"
  >
    <label
      class="highlight-label"
      *ngIf="values.status === 'OPENED' && !values.is_storno"
      >Teilzahlung ({{ values.part_payments_sum_euro_formatted }} /
      {{ values.total_price_euro_formatted }})</label
    >
    <div
      class="p-fluid p-formgrid p-grid without-border"
      *ngIf="values.status === 'OPENED' && !values.is_storno"
    >
      <div class="p-field p-col-6">
        <div class="p-inputgroup" style="width: 130px">
          <p-inputNumber
            mode="decimal"
            locale="de-DE"
            [minFractionDigits]="2"
            [min]="0"
            [maxFractionDigits]="2"
            inputId="partialPayment"
            name="partialPayment"
            [(ngModel)]="partialPayment"
          ></p-inputNumber>
          <span class="p-inputgroup-addon">€</span>
        </div>
      </div>
      <div class="p-field p-col-6">
        <button
          (click)="addPayment()"
          pButton
          [label]="partialPaymentEditId ? 'Bearbeiten' : 'Eintragen'"
          type="button"
          [disabled]="submitted"
          class="p-button p-button-outlined-light"
          [icon]="submitted ? 'pi pi-spin pi-spinner' : 'pi pi-check'"
        ></button>
      </div>
    </div>

    <div
      class="dialog-sidebar-part-payments-container"
      [class.with-border]="values.status === 'OPENED' && !values.is_storno"
    >
      <div
        class="dialog-no-message dialog-no-comment"
        *ngIf="values.part_payments.length === 0"
      >
        Keine Teilzahlungen vorhanden
      </div>
      <div
        class="dialog-sidebar-part-payment"
        *ngFor="let payment of values.part_payments"
      >
        <i
          (click)="editPayment(payment)"
          *ngIf="values.status === 'OPENED'"
          class="pi pi-pencil"
          pTooltip="Bearbeiten"
        ></i>
        <strong>{{ payment.payment_euro_formatted }}</strong>
        <span
          >{{ payment.user.name }} am
          {{ payment.created_at | date: "dd.MM.y, H:mm" }} Uhr</span
        >
      </div>
    </div>
  </div>

  <div
    class="dialog-form-body-banner"
    *ngIf="values.id && values.status === 'READY' && !loading"
  >
    Entwurfsmodus
  </div>

  <div class="dialog-form-body" *ngIf="!loading">
    <div class="p-fluid p-formgrid p-grid without-border">
      <div class="p-field p-col-12" *ngIf="!isEdit">
        <label for="patient">Patient * </label>
        <p-autoComplete
          field="full_name"
          inputId="patient"
          name="patient"
          scrollHeight="350px"
          appendTo="body"
          [forceSelection]="true"
          [(ngModel)]="patient"
          [suggestions]="patients"
          (completeMethod)="searchPatients($event)"
          (onSelect)="patientSelected($event)"
          [required]="true"
          [autofocus]="true"
          #patientRef="ngModel"
          [class.p-invalid]="
            patientRef.invalid && (patientRef.dirty || patientRef.touched)
          "
        >
          <ng-template let-patient pTemplate="item">
            <div class="search-result-data">
              <span class="search-result-name">
                <b>{{ patient.full_name }}</b>
                <!--                <span class="search-result-id">#{{ patient.id }}</span>-->
                <i
                  *ngIf="patient.status"
                  class="p-tag p-tag-rounded p-tag-{{ patient.status }}"
                  >{{ patient.status_string }}</i
                >
              </span>
              <span class="search-result-address">
                VS-Nummer: {{ patient.insurer_number || "-" }}
              </span>
            </div>
          </ng-template>
        </p-autoComplete>
      </div>

      <div class="p-field p-col-3" *ngIf="isEdit">
        <label>Patient</label>
        <strong>{{ patient.full_name }}</strong>
      </div>

      <div
        class="p-field p-col-6"
        *ngIf="!isFrozen && invoiceType === 'CONSULTING'"
      >
        <label>Versand an *</label>
        <div class="p-formgroup-inline">
          <div class="p-field-radiobutton">
            <p-radioButton
              label="Patient"
              name="sent_for"
              [(ngModel)]="values.sent_for"
              value="PATIENT"
              [required]="true"
              #sent_for="ngModel"
            ></p-radioButton>
          </div>
          <div class="p-field-radiobutton">
            <p-radioButton
              label="Pflegekasse"
              name="sent_for"
              [(ngModel)]="values.sent_for"
              value="INSURER"
              [required]="true"
              #sent_for="ngModel"
            ></p-radioButton>
          </div>
          <div class="p-field-radiobutton">
            <p-radioButton
              label="Multiplikator"
              name="sent_for"
              [(ngModel)]="values.sent_for"
              value="MULTIPLIER"
              [required]="true"
              #sent_for="ngModel"
            ></p-radioButton>
          </div>
          <div class="p-field-radiobutton">
            <p-radioButton
              label="Individuell"
              name="sent_for"
              [(ngModel)]="values.sent_for"
              value="INDIVIDUAL"
              [required]="true"
              #sent_for="ngModel"
            ></p-radioButton>
          </div>
        </div>
      </div>

      <div class="p-field p-col-3" *ngIf="isFrozen">
        <label>Rechnungsnummer</label>
        <strong>{{ values.invoice_number_complete }}</strong>
      </div>

      <div class="p-field p-col-3" *ngIf="isFrozen">
        <label>Versand an</label>
        <strong>
          {{ values.sent_for_text }}
          <i
            class="pi pi-info-circle"
            *ngIf="
              values.sent_for === 'INDIVIDUAL' && values.sent_type === 'email'
            "
            [pTooltip]="values.individual_email"
          ></i>
          <i
            class="pi pi-info-circle"
            *ngIf="
              values.sent_for === 'INDIVIDUAL' && values.sent_type === 'post'
            "
            [pTooltip]="values.address"
          ></i>
        </strong>
      </div>

      <div
        class="p-field p-col-3"
        *ngIf="isFrozen && values.type === 'CONSULTING'"
      >
        <label>BE durch</label>
        <strong>
          {{ values.invoiceable.for_user }}
        </strong>
      </div>
    </div>

    <div class="p-fluid p-formgrid p-grid without-border" *ngIf="patient?.id">
      <!-- Wie soll die Rechnung versendet werden -->
      <div class="p-field p-col-3" *ngIf="!isFrozen">
        <label>Versand per *</label>
        <div class="p-formgroup-inline">
          <div class="p-field-radiobutton">
            <p-radioButton
              (onClick)="loadReceivers()"
              label="E-Mail"
              name="sent_type"
              [(ngModel)]="values.sent_type"
              value="email"
              [required]="true"
              #sending_type="ngModel"
            ></p-radioButton>
          </div>
          <div class="p-field-radiobutton">
            <p-radioButton
              (onClick)="loadReceivers()"
              label="Post"
              name="sent_type"
              [(ngModel)]="values.sent_type"
              value="post"
              [required]="true"
              #sending_type="ngModel"
            ></p-radioButton>
          </div>
        </div>

        <div
          *ngIf="
            sending_type.invalid && (sending_type.dirty || sending_type.touched)
          "
          class="p-invalid"
        >
          <div *ngIf="sending_type.errors?.required">
            <small>Bitte wählen Sie einen Versand</small>
          </div>
        </div>
      </div>

      <!-- Wie wurde die Rechnung versendet -->
      <div class="p-field p-col-3" *ngIf="isFrozen">
        <label>Versand per</label>
        <strong>{{ values.sent_type_text }}</strong>
      </div>

      <!-- Wann und wer hat die Rechnung versendet -->
      <div
        class="p-field p-col-3"
        *ngIf="isFrozen && values.status === 'OPENED'"
      >
        <label>Versand / Ausdruck am</label>
        <strong>{{ values.opened_at | date: "dd.MM.y, H:mm" }} Uhr</strong>
        <!--        TODO: Info icon für history -->
      </div>
      <div
        class="p-field p-col-3"
        *ngIf="isFrozen && values.status === 'FINISHED'"
      >
        <label>Abgeschlossen am</label>
        <strong>{{ values.finished_at | date: "dd.MM.y, H:mm" }} Uhr</strong>
        <!--        TODO: Info icon für history -->
      </div>

      <!-- Der hinterlegte Wunschversand des Patienten -->
      <div class="p-field p-col-3" *ngIf="!isFrozen">
        <label>Rechnungsversand per</label>
        <strong
          >{{ getAppointmentTypes().types }}
          <i
            class="pi pi-info-circle"
            *ngIf="getAppointmentTypes().comment"
            [pTooltip]="getAppointmentTypes().comment"
          ></i
        ></strong>
      </div>

      <!-- An wen wurde die Rechnung versendet -->
      <div
        class="p-field p-col-3"
        *ngIf="isFrozen && values.sent_for == 'PATIENT'"
      >
        <label>Empfänger</label>
        <strong>{{ findReceiver(values.receiver) }}</strong>
      </div>

      <!-- Individuelle Adresse -->
      <div
        class="p-field p-col-5"
        *ngIf="
          !isFrozen &&
          values.sent_for == 'INDIVIDUAL' &&
          values.sent_type == 'post'
        "
      >
        <label for="address">Adresse *</label>
        <textarea
          id="address"
          name="address"
          [rows]="3"
          [(ngModel)]="values.address"
          [required]="true"
          pInputTextarea
          #address="ngModel"
          [class.p-invalid]="
            address.invalid && (address.dirty || address.touched)
          "
        ></textarea>
      </div>

      <!-- Multiplikator -->
      <div
        class="p-field p-col-5"
        *ngIf="!isFrozen && values.sent_for == 'MULTIPLIER'"
      >
        <label for="address"
          >Multiplikator *
          <strong
            *ngIf="
              values.sent_type === 'email' && !values.multiplier_sent_for?.email
            "
            class="color-alert"
            style="margin: 0 0 0 10px"
            >Keine E-Mail</strong
          ></label
        >
        <p-autoComplete
          field="full_name"
          inputId="multiplier"
          name="multiplier"
          appendTo="body"
          [forceSelection]="true"
          [(ngModel)]="values.multiplier_sent_for"
          [suggestions]="multipliers"
          (completeMethod)="searchMultipliers($event)"
          [required]="true"
          #multiplier="ngModel"
          [class.p-invalid]="
            multiplier.invalid && (multiplier.dirty || multiplier.touched)
          "
        >
          <ng-template let-multiplier pTemplate="item">
            <div>{{ multiplier.full_name }}</div>
          </ng-template>
        </p-autoComplete>
      </div>

      <!-- Individuelle E-Mail -->
      <div
        class="p-field p-col-5"
        *ngIf="
          !isFrozen &&
          values.sent_for == 'INDIVIDUAL' &&
          values.sent_type == 'email'
        "
      >
        <div class="p-field">
          <label for="individual-email">E-Mail *</label>
          <input
            name="individual-email"
            id="individual-email"
            [(ngModel)]="values.individual_email"
            pInputText
            [required]="true"
            #individual_email="ngModel"
            [class.p-invalid]="
              individual_email.invalid &&
              (individual_email.dirty || individual_email.touched)
            "
          />
        </div>

        <div class="p-field">
          <label for="address">Adresse *</label>
          <textarea
            id="address"
            name="address"
            [rows]="3"
            [(ngModel)]="values.address"
            [required]="true"
            pInputTextarea
            #address="ngModel"
            [class.p-invalid]="
              address.invalid && (address.dirty || address.touched)
            "
          ></textarea>
        </div>
      </div>

      <!-- Pflegekasse -->
      <div class="p-field p-col-4" *ngIf="values.sent_for == 'INSURER'">
        <label>Empfänger</label>
        <strong *ngIf="values.sent_type !== 'email'">{{
          patient.care_insurance.name || "Keine Pflegekasse"
        }}</strong>
        <strong
          *ngIf="values.sent_type === 'email' && patient.care_insurance.email"
          >{{ patient.care_insurance.name }} ({{
            patient.care_insurance.email
          }})</strong
        >
        <strong
          class="color-alert"
          *ngIf="values.sent_type === 'email' && !patient.care_insurance.email"
          >{{ patient.care_insurance.name }} (KEINE E-MAIL)</strong
        >
      </div>

      <!-- Multiplikator -->
      <div
        class="p-field p-col-4"
        *ngIf="values.sent_for == 'MULTIPLIER' && isFrozen"
      >
        <label>Empfänger</label>
        <strong>{{ values.multiplier_sent_for?.full_name }}</strong>
      </div>

      <!-- An wen soll die Rechnung versendet werden -->
      <div
        class="p-field p-col-3"
        *ngIf="!isFrozen && values.sent_for == 'PATIENT'"
      >
        <label for="receiver">Empfänger *</label>
        <p-dropdown
          inputId="receiver"
          name="receiver"
          [options]="receiverOptions"
          [(ngModel)]="values.receiver"
          [required]="true"
          scrollHeight="500px"
          appendTo="body"
          #receiver="ngModel"
          [class.p-invalid]="
            receiver.invalid && (receiver.dirty || receiver.touched)
          "
        >
          <ng-template let-receiver pTemplate="item">
            <div>{{ receiver.label }}</div>
            <span *ngIf="values.sent_type === 'email'">{{
              receiver.email
            }}</span>
          </ng-template>
        </p-dropdown>
      </div>

      <!-- An wen wurde die Rechnung per Mail per CC versendet -->
      <div
        class="p-field p-col-3"
        *ngIf="
          isFrozen &&
          values.sent_type === 'email' &&
          values.sent_for == 'PATIENT'
        "
      >
        <label>CC an</label>
        <i
          *ngIf="values.cc"
          class="pi pi-info-circle"
          [pTooltip]="findCCReceivers(values.cc)"
        ></i>
        <span *ngIf="!values.cc">-</span>
      </div>

      <!-- An wen soll die Rechnung per Mail per CC versendet werden -->
      <div
        class="p-field p-col-3"
        *ngIf="
          !isFrozen &&
          values.sent_type === 'email' &&
          values.sent_for == 'PATIENT'
        "
      >
        <label for="cc">CC an</label>
        <p-multiSelect
          inputId="cc"
          placeholder="Optional"
          [options]="ccOptions"
          [filter]="false"
          [showHeader]="false"
          scrollHeight="500px"
          appendTo="body"
          name="cc"
          [(ngModel)]="values.cc"
        ></p-multiSelect>
      </div>
    </div>

    <div
      class="p-fluid p-formgrid p-grid without-border"
      *ngIf="
        !isFrozen &&
        values.sent_type === 'email' &&
        invoiceType === 'CONSULTING' &&
        values.sent_for !== 'PATIENT'
      "
    >
      <div class="p-field p-col-4">
        <div class="p-field-checkbox">
          <p-checkbox
            name="send_be_to_customer"
            [binary]="true"
            label="Nachweis an Kunden senden"
            [(ngModel)]="values.send_be_to_customer"
          ></p-checkbox>
        </div>
      </div>

      <div class="p-field p-col-3" *ngIf="values.send_be_to_customer">
        <label for="receiver_nachweis">Nachweis Empfänger *</label>
        <p-dropdown
          inputId="receiver_nachweis"
          name="receiver_nachweis"
          [options]="receiverOptions"
          [(ngModel)]="values.receiver_nachweis"
          [required]="true"
          scrollHeight="500px"
          appendTo="body"
          #receiver_nachweis="ngModel"
          [class.p-invalid]="
            receiver_nachweis.invalid &&
            (receiver_nachweis.dirty || receiver_nachweis.touched)
          "
        >
          <ng-template let-receiver pTemplate="item">
            <div>{{ receiver.label }}</div>
            <span>{{ receiver.email }}</span>
          </ng-template>
        </p-dropdown>
      </div>

      <div class="p-field p-col-3" *ngIf="values.send_be_to_customer">
        <label for="cc_nachweis">Nachweis CC an</label>
        <p-multiSelect
          inputId="cc_nachweis"
          placeholder="Optional"
          [options]="ccOptions"
          [filter]="false"
          [showHeader]="false"
          scrollHeight="500px"
          appendTo="body"
          name="cc"
          [(ngModel)]="values.cc_nachweis"
        ></p-multiSelect>
      </div>
    </div>
  </div>

  <!-- KM Rechnung Tabelle -->
  <div
    class="content-body dialog-max-height without-padding"
    *ngIf="!loading && !values.is_storno && invoiceType === 'KM'"
  >
    <p-table
      #dt
      [value]="values.trips"
      [rowHover]="true"
      [scrollable]="true"
      scrollHeight="320px"
      [autoLayout]="true"
      [globalFilterFields]="[
        'name',
      ]"
    >
      <ng-template pTemplate="header">
        <tr>
          <th>Datum</th>
          <th>Betreuungskraft</th>
          <th>Kilometer</th>
          <th>Summe</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-item>
        <tr [attr.data-id]="item.id">
          <td>
            {{ item.persplan_budget.real_from | date: "dd.MM.y" }} ({{
              item.persplan_budget.weekday[0]
            }}{{ item.persplan_budget.weekday[1] }})
          </td>
          <td>{{ item.persplan_budget.caregiver.full_name }}</td>
          <td>{{ item.mileage }} KM</td>
          <td>
            {{ item.mileage_price_euro_formatted }}
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>

  <div class="content-body dialog-max-height" *ngIf="!loading">
    <div class="invoice-dialog-sum-container">
      <div *ngIf="invoiceType !== 'CONSULTING'">
        <span>Zwischensumme:</span>
        <span
          ><i
            class="pi pi-info-circle"
            pTooltip="Inkl. Bearbeitungsgebühren"
            tooltipPosition="left"
            style="font-size: 14px"
            tooltipStyleClass="tooltip-wider"
          ></i
          >{{ values.subtotal_price_euro_formatted }}</span
        >
      </div>
      <div *ngIf="invoiceType !== 'CONSULTING'">
        <span>Umsatzsteuer:</span>
        <span>{{ values.vat_price_euro_formatted }}</span>
      </div>
      <div class="total-sum-container">
        <span>Gesamt:</span>
        <span>{{ values.total_price_euro_formatted }}</span>
      </div>
    </div>
  </div>

  <!--  TODO: Positions wieder einfügen -->
  <!--  <div-->
  <!--    class="dialog-form-body dialog-reasons-container"-->
  <!--    *ngIf="patient?.id && withPositions"-->
  <!--  >-->
  <!--    <div class="p-fluid p-formgrid p-grid without-border">-->
  <!--      <div class="p-field p-col-2">-->
  <!--        <label><b>Anzahl</b></label>-->
  <!--      </div>-->
  <!--      <div class="p-field p-col-5">-->
  <!--        <label><b>Text</b></label>-->
  <!--      </div>-->
  <!--      <div class="p-field p-col-2">-->
  <!--        <label><b>Einzelpreis</b></label>-->
  <!--      </div>-->
  <!--      <div class="p-field p-col-2">-->
  <!--        <label><b>Gesamtpreis</b></label>-->
  <!--      </div>-->
  <!--    </div>-->

  <!--    <div-->
  <!--      class="p-fluid p-formgrid p-grid without-border"-->
  <!--      *ngFor="let position of values.positions; let i = index"-->
  <!--    >-->
  <!--      <div class="p-field p-col-2">-->
  <!--        <p-inputNumber-->
  <!--          (onBlur)="calculateItem(position)"-->
  <!--          [inputId]="'position-amount-' + i"-->
  <!--          [name]="'position-amount-' + i"-->
  <!--          [(ngModel)]="position.amount"-->
  <!--          [useGrouping]="false"-->
  <!--          [disabled]="isFrozen"-->
  <!--        ></p-inputNumber>-->
  <!--      </div>-->
  <!--      <div class="p-field p-col-5">-->
  <!--        <input-->
  <!--          [id]="'position-text-' + i"-->
  <!--          [name]="'position-text-' + i"-->
  <!--          type="text"-->
  <!--          [(ngModel)]="position.text"-->
  <!--          [disabled]="isFrozen"-->
  <!--          pInputText-->
  <!--        />-->
  <!--      </div>-->
  <!--      <div class="p-field p-col-2">-->
  <!--        <p-inputNumber-->
  <!--          (onBlur)="calculateItem(position)"-->
  <!--          mode="decimal"-->
  <!--          locale="de-DE"-->
  <!--          [disabled]="isFrozen"-->
  <!--          [minFractionDigits]="2"-->
  <!--          [maxFractionDigits]="2"-->
  <!--          [inputId]="'position-unit-price-' + i"-->
  <!--          [name]="'position-unit-price-' + i"-->
  <!--          [(ngModel)]="position.unit_price_euro"-->
  <!--        ></p-inputNumber>-->
  <!--      </div>-->
  <!--      <div class="p-field p-col-2">-->
  <!--        <strong>{{ position.total_price_euro_formatted }}</strong>-->
  <!--      </div>-->
  <!--      <div-->
  <!--        *ngIf="values.positions.length > 1 && !isFrozen"-->
  <!--        class="p-field p-col-1 remove-position-container"-->
  <!--      >-->
  <!--        <i-->
  <!--          class="pi pi-times"-->
  <!--          pTooltip="Entfernen"-->
  <!--          (click)="removePosition(position)"-->
  <!--        ></i>-->
  <!--      </div>-->
  <!--    </div>-->

  <!--    <div class="add-position-container" *ngIf="!isFrozen">-->
  <!--      <i class="pi pi-plus" pTooltip="Hinzufügen" (click)="addPosition()"></i>-->
  <!--    </div>-->

  <!--    <br />-->
  <!--    Zwischensumme: {{ values.subtotal_price_euro_formatted }}-->
  <!--    <br />-->
  <!--    Umsatzsteuer-->
  <!--    <select-->
  <!--      *ngIf="!isFrozen"-->
  <!--      (change)="calculate()"-->
  <!--      [(ngModel)]="values.vat"-->
  <!--      name="vat"-->
  <!--    >-->
  <!--      <option value="0">0%</option>-->
  <!--      <option [value]="19">19%</option>-->
  <!--    </select>-->
  <!--    <span *ngIf="isFrozen">({{ values.vat }}%)</span>:-->
  <!--    {{ values.vat_price_euro_formatted }}-->
  <!--    <br />-->
  <!--    Gesamtsumme: {{ values.total_price_euro_formatted }}-->
  <!--  </div>-->

  <div class="dialog-form-footer" *ngIf="patient?.id && !loading">
    <div class="dialog-footer-action-container">
      <button
        *ngIf="isFrozen && !values.is_storno"
        pButton
        label="Stornieren"
        class="p-button-danger"
        type="button"
        (click)="stornoInvoice()"
      ></button>

      <button
        *ngIf="!isFrozen && invoiceType === 'KM'"
        pButton
        label="Zurücksetzen"
        class="p-button-outlined"
        type="button"
        (click)="resetReadyInvoice()"
      ></button>

      <button
        *ngIf="!isFrozen && invoiceType === 'CONSULTING'"
        pButton
        label="Löschen"
        class="p-button-outlined"
        type="button"
        (click)="deleteReadyInvoice()"
      ></button>

      <a
        *ngIf="values.id && values.status === 'READY'"
        target="_blank"
        (click)="clickedOnPreview = true"
        [href]="getPdfPreviewLink()"
      >
        <button
          pButton
          label="PDF Vorschau"
          type="button"
          icon="pi pi-eye"
          iconPos="left"
          class="p-button p-button-info"
        ></button>
      </a>

      <a
        *ngIf="
          values.media[0] && ['OPENED', 'FINISHED'].includes(values.status)
        "
        target="_blank"
        [href]="documentService.getDocumentDownloadLink(values.media[0].uuid)"
      >
        <button
          pButton
          label="Rechnung herunterladen"
          type="button"
          icon="pi pi-file-pdf"
          iconPos="left"
          class="p-button p-button-info"
        ></button>
      </a>

      <a
        *ngIf="
          values.media[0] &&
          ['OPENED', 'FINISHED'].includes(values.status) &&
          invoiceType === 'CONSULTING'
        "
        target="_blank"
        [href]="
          documentService.getDocumentDownloadLink(
            values.invoiceable.media[0].uuid
          )
        "
      >
        <button
          pButton
          label="Nachweis herunterladen"
          type="button"
          icon="pi pi-file-pdf"
          iconPos="left"
          class="p-button p-button-info"
        ></button>
      </a>

      <a
        *ngIf="values.sent_type === 'email' && values.status === 'READY'"
        target="_blank"
        (click)="clickedOnPreviewMail = true"
        [href]="getMailPreviewLink()"
      >
        <button
          pButton
          label="Mail Vorschau"
          type="button"
          icon="pi pi-eye"
          iconPos="left"
          class="p-button p-button-info"
        ></button>
      </a>
    </div>

    <div class="dialog-footer-action-container">
      <p-splitButton
        *ngIf="values.id && values.status === 'READY'"
        label="Zwischenspeichern"
        [icon]="submitted ? 'pi pi-spin pi-spinner' : 'pi pi-check'"
        (onClick)="store()"
        [model]="items"
        appendTo="body"
        [disabled]="submitted"
      >
      </p-splitButton>

      <button
        *ngIf="values.status === 'OPENED' && !values.is_storno"
        (click)="markInvoiceAsCompleted()"
        pButton
        label="Vollständig bezahlt"
        type="button"
        [disabled]="submitted"
        [icon]="submitted ? 'pi pi-spin pi-spinner' : 'pi pi-check'"
      ></button>
    </div>
  </div>
</form>

<p-confirmPopup></p-confirmPopup>
