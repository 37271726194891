<form #form="ngForm" class="dialog-form">
  <div class="dialog-form-body">
    <div class="p-fluid p-formgrid p-grid without-border">
      <div class="p-field p-col-8">
        <label for="month">Monat *</label>
        <p-dropdown
          scrollHeight="500px"
          inputId="month"
          name="month"
          (onChange)="loadPatients()"
          [options]="monthOptions"
          [(ngModel)]="values.month"
          appendTo="body"
        ></p-dropdown>
      </div>

      <div class="p-field p-col-4">
        <label for="year">Jahr *</label>
        <p-dropdown
          inputId="year"
          name="year"
          (onChange)="loadPatients()"
          [options]="yearOptions"
          [(ngModel)]="values.year"
          appendTo="body"
        ></p-dropdown>
      </div>
    </div>

    <div class="p-field">
      <label for="patients">Patienten *</label>
      <p-multiSelect
        selectedItemsLabel="{0} Patienten ausgewählt"
        inputId="patients"
        [options]="patientOptions"
        optionLabel="label"
        name="patients"
        [filter]="true"
        scrollHeight="400px"
        [(ngModel)]="values.patients"
        appendTo="body"
        [required]="true"
      ></p-multiSelect>
    </div>

    <div class="p-field">
      <small
        >Falls der Patient bereits einen Leistungsnachweis für diesen Monat und
        § hat, wird dieser überschrieben.</small
      >
    </div>
  </div>
  <div class="dialog-form-footer">
    <i></i>

    <button
      pButton
      label="Erstellen"
      type="submit"
      (click)="save()"
      [disabled]="submitted"
      [icon]="submitted ? 'pi pi-spin pi-spinner' : 'pi pi-check'"
    ></button>
  </div>
</form>
