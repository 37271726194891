import { Component, OnInit, ViewChild } from '@angular/core'
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog'
import { EventBusService, GlobalEvent } from 'src/app/services/eventbus.service'
import { ToastService } from 'src/app/services/toast.service'
import { NoticeService } from '../../../services/notice.service'
import { HelperService } from '../../../services/helper.service'
import { CustomerService } from '../../../services/customer.service'
import { QmModel } from '../../../models/customer-patient/qm.model'
import * as dayjs from 'dayjs'
import { SearchResultModel } from '../../../models/search/search-result.model'
import { SearchService } from '../../../services/search.service'
import { NgForm } from '@angular/forms'
import { PatientService } from '../../../services/patient.service'
import { CaregiverService } from '../../../services/caregivers.service'
import { ConfirmationService } from 'primeng/api'
import { CalendarService } from '../../../services/calendar.service'
import { UserSystemService } from '../../../services/user-system.service'

@Component({
  selector: 'app-thc-date-dialog',
  templateUrl: './thc-date-dialog.component.html',
})
export class ThcDateDialogComponent implements OnInit {
  @ViewChild('form', { static: true }) form!: NgForm

  public homeVisitFrom: any[] = []
  public patients: SearchResultModel[] = []
  public receiverOptions: any[] = []
  public ccOptions: any[] = []
  public clickedOnPreview = false
  public appointmentsForUser: any[] = []

  values = {
    comment: '',
    home_visit_from: '',
    home_visit_time: '',
    home_visit_date_string: '',
    patient: null as any,
    receiver: null,
    cc: [],
    send_email: false,
    type: 'patient',
    title: '',
  }

  submitted = false
  submittedDelete = false

  constructor(
    private ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private caregiverService: CaregiverService,
    private userSystemService: UserSystemService,
    private helperService: HelperService,
    public customerService: CustomerService,
    public calendarService: CalendarService,
    private searchService: SearchService,
    private noticeService: NoticeService,
    private patientService: PatientService,
    private eventbus: EventBusService,
    private confirmationService: ConfirmationService,
    private toastService: ToastService
  ) {}

  public ngOnInit(): void {
    if (this.config.data.isNew) {
    } else {
      this.loadData()

      if (this.config.data.type === 'patient') {
        this.values.patient = {
          id: this.config.data.patient_id,
        }

        this.loadReceivers(this.values.patient.id)
      }
    }

    this.helperService.dependencies$.subscribe((data: any) => {
      for (const user of data.thc_users) {
        this.homeVisitFrom.push({
          id: user.id,
          full_name: `${user.user_system.first_name} ${user.user_system.last_name}`,
        })
      }
    })
  }

  private loadQmForPatient(): void {
    this.customerService
      .loadQmForPatient(this.values.patient.id)
      .subscribe((qm: QmModel) => {
        this.values.comment = qm.comment_thc
      })
  }

  private loadReceivers(patientId: string): void {
    this.patientService.getReceivers(patientId).subscribe((response: any) => {
      this.buildReceiverOptions(response)
      this.buildCCOptions(response)
    })
  }

  public loadUserAppointments(): void {
    const found = this.homeVisitFrom.find((item: any) => {
      return item.full_name === this.values.home_visit_from
    })

    if (!found) {
      return
    }

    this.userSystemService
      .loadDatesForDay(found.id, this.values.home_visit_date_string)
      .subscribe((response: string[]) => {
        this.appointmentsForUser = response
      })
  }

  public patientSelected(event: any): void {
    this.loadQmForPatient()
    this.loadReceivers(this.values.patient.id)
  }

  public searchPatients(event: any): void {
    this.searchService
      .findPatients(event.query.trim())
      .subscribe((results: SearchResultModel[]) => {
        this.patients = results
      })
  }

  private loadData(): void {
    if (this.config.data.type === 'patient') {
      this.customerService
        .loadQm(this.config.data.id)
        .subscribe((response: QmModel) => {
          this.values.type = 'patient'
          this.values.home_visit_from = response.home_visit_from
          this.values.comment = response.comment_thc
          this.values.home_visit_date_string = dayjs(
            response.home_visit_date
          ).format('DD.MM.YYYY')
          this.values.home_visit_time = dayjs(response.home_visit_date).format(
            'HH:mm'
          )

          this.loadUserAppointments()
        })
    } else {
      this.calendarService
        .load(this.config.data.id)
        .subscribe((response: any) => {
          this.values.type = 'other'
          this.values.title = response.title
          this.values.home_visit_from = response.for_user
          this.values.comment = response.comment
          this.values.home_visit_date_string = dayjs(response.date_from).format(
            'DD.MM.YYYY'
          )
          this.values.home_visit_time = dayjs(response.date_from).format(
            'HH:mm'
          )

          // TODO: date_to
          this.loadUserAppointments()
        })
    }
  }

  public checkForTimes(event: any): void {
    const value = event.target.value

    if (value.length === 1) {
      this.values.home_visit_time = `0${value}:00`
    } else if (value.length === 2) {
      this.values.home_visit_time = `${value}:00`
    } else if (value.length === 4) {
      this.values.home_visit_time = `${value[0]}${value[1]}:${value[2]}${value[3]}`
    }
  }

  public save(): void {
    if (!this.form.form.valid) {
      this.submitted = false
      this.form.form.markAllAsTouched()
      return
    }

    this.submitted = true
    let subscription = null

    if (this.values.type === 'patient') {
      subscription = this.config.data.isNew
        ? this.customerService.storeThcQm(this.values.patient?.id, this.values)
        : this.customerService.updateThcQm(this.config.data.id, this.values)
    } else {
      subscription = this.config.data.isNew
        ? this.calendarService.store(this.values, 'qm')
        : this.calendarService.update(this.config.data.id, this.values)
    }

    subscription.subscribe(
      (qm: QmModel) => {
        this.submitted = false
        this.ref.close(qm)
        this.eventbus.emit(GlobalEvent.ThcDateChanged)
        this.toastService.success(
          'THC bearbeitet',
          'Der Termin wurde erfolgreich bearbeitet'
        )
      },
      () => {
        this.toastService.error(
          'Etwas ist schiefgelaufen...',
          'Bitte wenden Sie sich an den Support'
        )

        this.submitted = false
      }
    )
  }

  public buildReceiverOptions(response: any): void {
    this.receiverOptions = []

    this.receiverOptions.push({
      value: '',
      label: 'Bitte auswählen',
      disabled: true,
    })

    this.receiverOptions.push({
      value: `patient_${response.first_patient.id}_`,
      email: response.first_patient.email || 'Keine E-Mail',
      label: `${response.first_patient.full_name} - Patient`,
      disabled: !response.first_patient.email,
    })

    if (response.second_patient) {
      this.receiverOptions.push({
        value: `patient_${response.second_patient.id}_`,
        email: response.second_patient.email || 'Keine E-Mail',
        label: `${response.second_patient.full_name} - Patient`,
        disabled: !response.second_patient.email,
      })
    }

    for (const contactPerson of response.contact_persons) {
      if (contactPerson.email_private) {
        this.receiverOptions.push({
          value: `ap_${contactPerson.id}_private`,
          email: `Private E-Mail: ${contactPerson.email_private}`,
          label: `${contactPerson.full_name} - AP`,
          disabled: false,
        })
      }

      if (contactPerson.email_work) {
        this.receiverOptions.push({
          value: `ap_${contactPerson.id}_work`,
          email: `Geschäftliche E-Mail: ${contactPerson.email_work}`,
          label: `${contactPerson.full_name} - AP`,
          disabled: false,
        })
      }

      // Wenn gar keine E-Mail hinterlegt ist.
      if (!contactPerson.email_private && !contactPerson.email_work) {
        this.receiverOptions.push({
          value: `ap_${contactPerson.id}_`,
          email: 'Keine E-Mail',
          label: `${contactPerson.full_name} - AP`,
          disabled: true,
        })
      }
    }
  }

  public buildCCOptions(response: any): void {
    this.ccOptions = []

    this.ccOptions.push({
      value: response.first_patient.email,
      email: response.first_patient.email || 'Keine E-Mail',
      label: `${response.first_patient.full_name} - Patient`,
      disabled: !response.first_patient.email,
    })

    if (response.second_patient) {
      this.ccOptions.push({
        value: response.second_patient.email,
        email: response.second_patient.email || 'Keine E-Mail',
        label: `${response.second_patient.full_name} - Patient`,
        disabled: !response.second_patient.email,
      })
    }

    for (const contactPerson of response.contact_persons) {
      if (contactPerson.email_private) {
        this.ccOptions.push({
          value: contactPerson.email_private,
          email: `Private E-Mail: ${contactPerson.email_private}`,
          label: `${contactPerson.full_name} - AP - Private E-Mail`,
          disabled: false,
        })
      }

      if (contactPerson.email_work) {
        this.ccOptions.push({
          value: contactPerson.email_work,
          email: `Geschäftliche E-Mail: ${contactPerson.email_work}`,
          label: `${contactPerson.full_name} - AP - Geschäftliche E-Mail`,
          disabled: false,
        })
      }

      // Wenn gar keine E-Mail hinterlegt ist.
      if (!contactPerson.email_private && !contactPerson.email_work) {
        this.ccOptions.push({
          value: null,
          email: 'Keine E-Mail',
          label: `${contactPerson.full_name} - AP - Keine E-Mail`,
          disabled: true,
        })
      }
    }
  }

  public remove(event: any): void {
    this.confirmationService.confirm({
      target: event.target ?? undefined,
      message: 'THC wirklich löschen?',
      accept: () => {
        this.submittedDelete = true

        const subscription =
          this.values.type === 'patient'
            ? this.customerService.removeThcQm(this.config.data.id)
            : this.calendarService.remove(this.config.data.id)

        subscription.subscribe(
          () => {
            this.submittedDelete = false
            this.eventbus.emit(GlobalEvent.ThcDateChanged)
            this.ref.close()
            this.toastService.success(
              'THC gelöscht',
              'Der Eintrag wurde erfolgreich gelöscht'
            )
          },
          () => {
            this.submittedDelete = false
            this.toastService.error(
              'Löschen fehlgeschlagen',
              'Der Eintrag konnte nicht gelöscht werden'
            )
          }
        )
      },
      reject: () => {},
    })
  }
}
