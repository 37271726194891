import { Component, ElementRef, OnInit, ViewChild } from '@angular/core'
import { Router } from '@angular/router'
import { TitleService } from 'src/app/services/title.service'
import { DialogService } from 'primeng/dynamicdialog'
import { PhoneCallService } from '../../../services/phone-call.service'
import {
  PhoneCallsListItemModel,
  PhoneCallsListModel,
} from '../../../models/phone-call/phone-calls-list.model'
import { PhoneCallEditDialogComponent } from '../../../components/dialogs/phone-call-edit-dialog/phone-call-edit-dialog.component'
import { Subscription } from 'rxjs'
import {
  EventBusService,
  GlobalEvent,
} from '../../../services/eventbus.service'

@Component({
  selector: 'app-patients-consultings',
  templateUrl: './patients-consultings.component.html',
})
export class PatientsConsultingsComponent implements OnInit {
  @ViewChild('dt') table: ElementRef | any = null

  public globalFilterValue = ''
  public loading = false
  private eventBusSubscription: Subscription = new Subscription()

  public calls: PhoneCallsListItemModel[] = []

  constructor(
    private dialogService: DialogService,
    public phoneCallService: PhoneCallService,
    private router: Router,
    private eventbus: EventBusService,
    private titleService: TitleService
  ) {}

  ngOnInit(): void {
    this.loadCalls()
    this.listenForEventbus()
    this.titleService.setTitle('Neukunden Calls')
  }

  ngOnDestroy(): void {
    this.eventBusSubscription.unsubscribe()
  }

  public filterTableGlobal(event: any): void {
    this.table && this.table.filterGlobal(event.target.value.trim(), 'contains')
  }

  private listenForEventbus(): void {
    this.eventBusSubscription = this.eventbus.subject.subscribe(
      (event: GlobalEvent) => {
        switch (event) {
          case GlobalEvent.PhoneCallListReload:
            this.loadCalls(false)
        }
      }
    )
  }

  public openPhonecallEditModal(phonecall: PhoneCallsListModel): void {
    this.dialogService.open(PhoneCallEditDialogComponent, {
      header: 'Telefonat bearbeiten',
      width: '540px',
      styleClass: 'dialog-container',
      dismissableMask: false,
      data: phonecall,
    })
  }

  private loadCalls(withLoading: boolean = true): void {
    if (withLoading) {
      this.loading = true
    }

    this.phoneCallService
      .consultings()
      .subscribe((data: PhoneCallsListItemModel[]) => {
        this.calls = data

        if (withLoading) {
          this.loading = false
        }

        setTimeout(() => {
          this.table && this.table.filterGlobal('', 'contains')
        })
      })
  }
}
