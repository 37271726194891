<form #form="ngForm" class="dialog-form">
  <div class="dialog-form-body">
    <div class="p-field">
      <label for="customer_service">Kundenbetreuer *</label>
      <p-dropdown
        inputId="customer_service"
        name="customer_service"
        placeholder="Bitte auswählen"
        [options]="customerServiceOptions"
        [(ngModel)]="values.customer_service"
        [required]="true"
        #customer_service="ngModel"
        [class.p-invalid]="
          customer_service.invalid &&
          (customer_service.dirty || customer_service.touched)
        "
      ></p-dropdown>
      <div
        *ngIf="
          customer_service.invalid &&
          (customer_service.dirty || customer_service.touched)
        "
        class="p-invalid"
      >
        <div *ngIf="customer_service.errors?.required">
          <small>Bitte wählen Sie einen Kundenbetreuer</small>
        </div>
      </div>
    </div>

    <div class="p-field">
      <label for="comment">Kommentar</label>
      <textarea
        id="comment"
        name="comment"
        [(ngModel)]="values.comment"
        [rows]="3"
        pInputTextarea
      ></textarea>
    </div>
  </div>
  <div class="dialog-form-footer">
    <i></i>
    <button
      (click)="save()"
      pButton
      label="Speichern"
      type="button"
      [disabled]="submitted"
      [icon]="submitted ? 'pi pi-spin pi-spinner' : 'pi pi-check'"
    ></button>
  </div>
</form>
