import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core'
import { Router } from '@angular/router'
import { DialogService } from 'primeng/dynamicdialog'
import {
  EventBusService,
  GlobalEvent,
} from '../../../services/eventbus.service'
import { Subject, Subscription } from 'rxjs'
import { TodoService } from '../../../services/todo.service'
import { TodoModel } from '../../../models/todo/todo.model'
import { TodoFormDialogComponent } from '../../../components/dialogs/todo-form-dialog/todo-form-dialog.component'
import { ShowTodoDialogComponent } from '../../../components/dialogs/show-todo-dialog/show-todo-dialog.component'
import { switchMap } from 'rxjs/operators'

@Component({
  selector: 'app-open-todos',
  templateUrl: './open-todos.component.html',
})
export class OpenTodosComponent implements OnInit, OnDestroy {
  @ViewChild('dtOpen') table: ElementRef | any = null

  public globalFilterValue = ''
  private eventBusSubscription: Subscription = new Subscription()

  public firstPage = 0

  public loading = false
  public todos: TodoModel[] = []

  public settings = {
    only_assigned: false,
  }

  private loadTrigger$ = new Subject<void>()
  private subscription: Subscription = new Subscription()

  constructor(
    public todoService: TodoService,
    private dialogService: DialogService,
    private router: Router,
    private eventbus: EventBusService
  ) {}

  ngOnInit(): void {
    this.activateDataLoading()

    this.initSettings()
    this.loadTodos()
    this.listenForEventbus()
  }

  ngOnDestroy(): void {
    this.eventBusSubscription.unsubscribe()
    this.subscription.unsubscribe()
  }

  public filterTableGlobal(event: any): void {
    this.table && this.table.filterGlobal(event.target.value.trim(), 'contains')
  }

  private initSettings(): void {
    // Falls noch nichts im Speicher vorhanden ist, setze den Default wert.
    if (localStorage.getItem('todos-settings') === null) {
      localStorage.setItem('todos-settings', JSON.stringify(this.settings))
    } else {
      // Falls aber was vorhanden ist, werden die Settings aus dem Speicher genommen.
      const savedSettings = JSON.parse(
        localStorage.getItem('todos-settings') || '{}'
      )

      // Es muss geprüft werden, ob die Menge der gespeicherten Daten übereinstimmt.
      // Falls nicht, wurde bei der Entwicklung ein neues Setting hinzugefügt
      // und die Daten werden nun zurückgesetzt.
      if (
        Object.keys(savedSettings).length != Object.keys(this.settings).length
      ) {
        localStorage.setItem('todos-settings', JSON.stringify(this.settings))
      } else {
        this.settings = JSON.parse(
          localStorage.getItem('todos-settings') || '{}'
        )
      }
    }
  }

  private listenForEventbus(): void {
    this.eventBusSubscription = this.eventbus.subject.subscribe(
      (event: GlobalEvent) => {
        switch (event) {
          case GlobalEvent.TodoChanged:
            this.loadTodos(false)
        }
      }
    )
  }

  public changedSettings(): void {
    localStorage.setItem('todos-settings', JSON.stringify(this.settings))

    this.loadTodos(false)
  }

  public loadTodos(withLoading: boolean = true): void {
    if (withLoading) {
      this.loading = true
    }

    this.loadTrigger$.next()
  }

  private activateDataLoading(): void {
    this.subscription.add(
      this.loadTrigger$
        .pipe(switchMap(() => this.todoService.loadOpen(this.settings)))
        .subscribe((data: TodoModel[]) => {
          this.todos = data

          this.loading = false

          setTimeout(() => {
            if (this.table) {
              const totalItems = this.table._totalRecords

              this.globalFilterValue = this.table.filters.global?.value ?? ''

              if (
                this.table._first > totalItems ||
                this.table._rows > totalItems
              ) {
                this.firstPage = 0
              }
            }
          })
        })
    )
  }

  public openShowTodoDialog(todo: TodoModel | null = null): void {
    this.dialogService.open(ShowTodoDialogComponent, {
      header: 'Todo ansehen',
      width: '820px',
      styleClass: 'dialog-container',
      data: {
        todo,
        todo_id: todo?.id,
        user_type_name: todo?.caregiver?.full_name || todo?.patient?.full_name,
      },
    })
  }

  public openTodoFormDialog(todo: TodoModel | null = null): void {
    this.dialogService.open(TodoFormDialogComponent, {
      header: todo ? 'Todo bearbeiten' : 'Neues Todo',
      width: '520px',
      styleClass: 'dialog-container',
      data: {
        isEdit: !!todo,
        todo,
        user_type_name: todo?.caregiver?.full_name || todo?.patient?.full_name,
      },
    })
  }
}
