import { AuthGuard } from '../guards/auth.guard'
import { AdministrationComponent } from '../views/administration/administration.component'
import { UsersListComponent } from '../views/administration/users/list/users-list.component'
import { RolesListComponent } from '../views/administration/roles/list/roles-list.component'
import { SuperAdminGuard } from '../guards/super-admin.guard'
import {UsersFormComponent} from "../views/administration/users/form/users-form.component";
import {DirtyCheckGuard} from "../guards/dirty-check.guard";
import {UsersDetailComponent} from "../views/administration/users/detail/users-detail.component";
import {UsersDetailGeneralComponent} from "../views/administration/users/detail/general/users-detail-general.component";
import {UsersDetailHistoryComponent} from "../views/administration/users/detail/history/users-detail-history.component";

export const administrationRoutes = [
  {
    path: 'administration',
    canActivate: [AuthGuard],
    component: AdministrationComponent,
    children: [
      {
        path: '',
        redirectTo: 'users',
        pathMatch: 'full',
      },
      {
        path: 'users',
        component: UsersListComponent,
      },
      {
        path: 'roles',
        component: RolesListComponent,
        canActivate: [SuperAdminGuard],
      },
    ],
  },
  {
    path: 'administration/users',
    canActivate: [AuthGuard],
    children: [
      {
        path: 'new',
        component: UsersFormComponent,
        canDeactivate: [DirtyCheckGuard],
      },
      {
        path: ':id',
        component: UsersDetailComponent,
        children: [
          {
            path: '',
            component: UsersDetailGeneralComponent,
          },
          {
            path: 'history',
            component: UsersDetailHistoryComponent,
          },
        ],
      },
      {
        path: ':id/edit',
        component: UsersFormComponent,
        canDeactivate: [DirtyCheckGuard],
      },
    ],
  },
]
