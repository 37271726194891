import { Injectable } from '@angular/core'
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
} from '@angular/router'
import { Observable, of } from 'rxjs'
import { AuthService } from '../services/auth.service'
import { catchError, map } from 'rxjs/operators'
import { AuthModel } from '../models/user/auth.model'

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {}

  /**
   * Prüfen ob der Benutzer eingeloggt ist, falls nicht, wird
   * ein Backend Request gemacht um zu Prüfen, ob der Login Cookie
   * noch gültig ist (falls er überhaupt vorhanden war).
   *
   * Wenn ja, wird der Benutzer im Frontend als eingeloggt markiert.
   * Ansonsten wird er auf die Loginseite navigiert.
   *
   * Falls der Benutzer sein initiales Passwort noch nicht geändert hat,
   * wird er auf die spezielle Seite weitergeleitet, wo er ein neues
   * Passwort vergeben muss.
   */
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | boolean {
    if (this.authService.isAuthenticated()) {
      return true
    }

    return this.authService.authenticate().pipe(
      map((user: AuthModel) => {
        if (!user.initial_password_changed) {
          this.authService.setIsLoggedIn(false)
          this.authService.setUser(null)

          this.router.navigate(['change-initial-password'])

          return false
        }

        this.authService.setUser(user)
        this.authService.setIsLoggedIn(true)

        return true
      }),
      catchError(() => {
        this.authService.setIsLoggedIn(false)
        this.authService.setUser(null)

        this.router.navigate(['login'], {
          queryParams: { returnUrl: state.url },
        })

        return of(false)
      })
    )
  }
}
