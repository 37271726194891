import { MediaModel } from '../document/media.model'
import { CaregiverDetailModel } from './caregiver-detail.model'
import { PatientDetailModel } from '../customer-patient/patient-detail.model'

export class CaregiverProofGroupedModel {
  [key: string]: CaregiverProofModel[]
}

export class CaregiverProofModel {
  id: number = 0
  patient_id: number | null = null
  patient: PatientDetailModel = new PatientDetailModel()
  caregiver_id: number | null = null
  caregiver: CaregiverDetailModel = new CaregiverDetailModel()
  media_id: number | null = null
  media: MediaModel = new MediaModel()
  deleted_at: Date | null = null
  created_at: Date | null = null
  updated_at: Date | null = null
}
