<app-loader [loading]="loading"></app-loader>

<div class="content-actions">
  <h2>
    <i class="tio">chart_donut_2</i>
    Auswertungen
  </h2>
</div>

<div class="content has-min-width" *ngIf="!loading">
  <header class="content-header">
    <span>
      <p-dropdown
        inputId="year"
        name="year"
        (onChange)="loadList(false)"
        [options]="yearOptions"
        [(ngModel)]="year"
      ></p-dropdown>

      <span style="margin: 0 20px"
        >Beginn:
        {{
          caregiver.current_employment?.employed_since_date | date: "dd.MM.y"
        }}</span
      >
<!--      <span-->
<!--        *ngIf="caregiver.current_employment?.employed_until_date"-->
<!--        style="margin: 0 20px"-->
<!--        >Bis:-->
<!--        {{-->
<!--          caregiver.current_employment?.employed_until_date | date: "dd.MM.y"-->
<!--        }}</span-->
<!--      >-->
      <span style="margin: 0 20px"
        >Urlaub: {{ caregiver.vacation_days }} Tage</span
      >
      <span style="margin: 0 20px"
        >Stellenumfang:
        {{ caregiver.current_job_scope?.job_scope }} Std</span
      >
      <span style="margin: 0 20px"
        >AU Tage {{ year }}: {{ illQuota.days }}
        {{ illQuota === 1 ? "Tag" : "Tage" }}</span
      >
      <span style="margin: 0 20px"
        >AU Quote {{ year }}: {{ illQuota.percentage }} %</span
      >
    </span>
  </header>

  <div
    class="content-body without-padding has-min-width"
    [class.content-loading]="contentLoading"
  >
    <div class="time-month">
      <div class="time-category"></div>

      <div class="time-months">
        <div *ngFor="let month of months">
          <span></span>
          <strong>{{ month }}</strong>
        </div>
      </div>
    </div>

    <div class="time-lists">
      <div
        class="time-list"
        [class.divider]="key === 'ACCUMULATED'"
        *ngFor="let key of timeKeys"
      >
        <div class="time-category">
          <span>{{ times[key].name }}</span>
        </div>
        <div class="times-container">
          <div
            class="times"
            *ngFor="let month of times[key].months; let i = index"
          >
            <span *ngIf="month">{{ month.value }} {{ month.suffix }}</span>
            <span *ngIf="month.length === 0">-</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
