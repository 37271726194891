import { Injectable } from '@angular/core'
import { HttpClient, HttpParams } from '@angular/common/http'
import { environment } from '../../environments/environment'
import { Observable } from 'rxjs'
import { QmModel } from '../models/customer-patient/qm.model'

@Injectable({
  providedIn: 'root',
})
export class ConsultingService {
  constructor(private http: HttpClient) {}

  public load(patientId: string | null): Observable<any> {
    return this.http.get<any>(
      `${environment.api}/consulting/${patientId}/for-patient`
    )
  }

  public loadOpen(filters: any, selectedCities: any): Observable<any> {
    return this.http.get<any>(`${environment.api}/consulting/open`, {
      params: {
        ...filters,
        selectedCities: JSON.stringify(selectedCities),
      }
    })
  }

  public loadFinished(): Observable<any> {
    return this.http.get<any>(`${environment.api}/consulting/finished`)
  }

  public loadCalendarData(mondayDate: string, users: any[]): Observable<any[]> {
    return this.http.get<any[]>(
      `${environment.api}/consulting/calendar-dates`,
      {
        params: {
          monday_date: mondayDate,
          user_ids: JSON.stringify(users),
        },
      }
    )
  }

  public update(id: number, data: any): Observable<any> {
    return this.http.put<any>(`${environment.api}/consulting/${id}`, data)
  }

  public uploadConfirm(id: number, form: FormData): Observable<any> {
    return this.http.post<any>(
      `${environment.api}/consulting/${id}/upload-confirm`,
      form
    )
  }

  public store(patientId: number, data: any): Observable<any> {
    return this.http.post<any>(
      `${environment.api}/consulting/${patientId}`,
      data
    )
  }

  public remove(consultingId: number): Observable<any> {
    return this.http.delete<any>(
      `${environment.api}/consulting/${consultingId}`
    )
  }

  public getMailPreviewLink(values: any): string {
    const params = new HttpParams({ fromObject: values }).toString()

    return `${environment.api}/consulting/mail-preview/?${params}`
  }

  public getPdfPreviewLink(values: any): string {
    const params = new HttpParams({ fromObject: values }).toString()

    return `${environment.api}/consulting/pdf-preview/?${params}`
  }

  public getDocumentDownloadLink(uuid: string): string {
    return `${environment.api}/documents/${uuid}`
  }

  public getCalendarDatesPdfUrl(date: string, users: any[]): string {
    const userIds = JSON.stringify(users)

    return `${environment.api}/consulting/calendar-dates/pdf?date=${date}&user_ids=${userIds}`
  }
}
