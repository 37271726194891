export class MediaModel {
  id: number | null = null
  model_type = ''
  uuid = ''
  collection_name = ''
  name = ''
  file_name = ''
  mime_type = ''
  disk = ''
  conversions_disk = ''
  size: number | null = null
  order_column: number | null = null
  manipulations = []
  custom_properties: any = {}
  responsive_images = []
  is_removed = false
  approved_for_user = false
  created_at: Date | null = null
  updated_at: Date | null = null
}
