import { Component, OnDestroy, OnInit } from '@angular/core'
import { TitleService } from 'src/app/services/title.service'
import { CapacityService } from '../../services/capacity.service'
import {
  CapacityModel,
  CapacityResponseModel,
} from '../../models/capacity/capacity.model'
import * as dayjs from 'dayjs'
import * as customParseFormat from 'dayjs/plugin/customParseFormat'
import * as utc from 'dayjs/plugin/utc'
import { LatestChangeModel } from '../../models/history/latest-change.model'
import { Dayjs } from 'dayjs'
import { Subject, Subscription } from 'rxjs'
import { switchMap } from 'rxjs/operators'
import { TodoModel } from '../../models/todo/todo.model'
import { HelperService } from '../../services/helper.service'

@Component({
  selector: 'app-capacity',
  templateUrl: './capacity.component.html',
  styleUrls: ['./capacity.component.scss'],
})
export class CapacityComponent implements OnInit, OnDestroy {
  public data: CapacityResponseModel = new CapacityResponseModel()
  public capacityKeys: ['Vollzeit', 'Teilzeit', 'Halbtag', 'Minijob'] = [
    'Vollzeit',
    'Teilzeit',
    'Halbtag',
    'Minijob',
  ]

  private loadTrigger$ = new Subject<void>()
  private subscription: Subscription = new Subscription()

  public selectedPlanner: any = null
  public plannerUsers: any[] = []

  public loading = true
  public contentLoading = true

  public persplanData = new LatestChangeModel()
  private startWeek: Dayjs = dayjs()

  public lastUpdate = ''
  public searchQuery = ''
  public currentDay = dayjs().format('DD.MM.YYYY')
  public week = {
    monday: '',
    tuesday: '',
    wednesday: '',
    thursday: '',
    friday: '',
    saturday: '',
    sunday: '',
  }

  constructor(
    private helperService: HelperService,
    public capacityService: CapacityService,
    private titleService: TitleService
  ) {
    dayjs.locale('de')
    dayjs.extend(customParseFormat)
    dayjs.extend(utc)
  }

  ngOnInit(): void {
    this.startWeek = dayjs.utc().startOf('week')
    this.setWeek()

    this.helperService.dependencies$.subscribe((data: any) => {
      for (const planner of data.planner_users) {
        this.plannerUsers.push({
          id: planner.id,
          full_name: `${planner.user_system.first_name} ${planner.user_system.last_name}`,
        })
      }
    })

    this.activateDataLoading()

    this.loadCapacity()
    this.loadLastUpdate()
    this.titleService.setTitle(`Freie Kapazität`)
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe()
  }

  /**
   * Springt zur Woche vom ausgewählten Datum aus dem Datepicker.
   */
  public goToSelectedWeek(event: any): void {
    this.startWeek = dayjs(event).startOf('week')
    this.setWeek()
    this.loadCapacity(false)
  }

  /**
   * Springt zur letzten Woche.
   */
  public goToPreviousWeek(): void {
    this.startWeek = this.startWeek.subtract(1, 'week').startOf('week')
    this.setWeek()
    this.loadCapacity(false)
  }

  /**
   * Springt zur nächsten Woche.
   */
  public goToNextWeek(): void {
    this.startWeek = this.startWeek.add(1, 'week').startOf('week')
    this.setWeek()
    this.loadCapacity(false)
  }

  /**
   * Baut die komplette Woche zusammen.
   */
  private setWeek(): void {
    this.week.monday = this.startWeek.format('DD.MM.YYYY')
    this.week.tuesday = this.startWeek.add(1, 'day').format('DD.MM.YYYY')
    this.week.wednesday = this.startWeek.add(2, 'days').format('DD.MM.YYYY')
    this.week.thursday = this.startWeek.add(3, 'days').format('DD.MM.YYYY')
    this.week.friday = this.startWeek.add(4, 'days').format('DD.MM.YYYY')
    this.week.saturday = this.startWeek.add(5, 'days').format('DD.MM.YYYY')
    this.week.sunday = this.startWeek.add(6, 'days').format('DD.MM.YYYY')
  }

  public searchedForCaregiver(caregiverName: string): boolean {
    if (!this.searchQuery) {
      return true
    }

    return caregiverName.toLowerCase().includes(this.searchQuery.toLowerCase())
  }

  public getPersplanForCaregiver(caregiverId: number, date: string): void {
    if (this.persplanData[caregiverId + date]) {
      return
    }

    this.capacityService
      .getPersplan(caregiverId, date)
      .subscribe((response: string) => {
        this.persplanData[caregiverId + date] = response
      })
  }

  private loadLastUpdate(): void {
    this.capacityService.loadLastUpdate().subscribe((lastUpdate: string) => {
      this.lastUpdate = lastUpdate
    })
  }

  public loadCapacity(withLoading: boolean = true): void {
    this.contentLoading = true

    if (withLoading) {
      this.loading = true
    }

    this.loadTrigger$.next()
  }

  private activateDataLoading(): void {
    this.subscription.add(
      this.loadTrigger$
        .pipe(
          switchMap(() =>
            this.capacityService.load(this.week.monday, this.selectedPlanner)
          )
        )
        .subscribe((data: CapacityResponseModel) => {
          this.data = data

          this.contentLoading = false
          this.loading = false
        })
    )
  }
}
