import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { environment } from '../../environments/environment'
import { BehaviorSubject } from 'rxjs'

@Injectable({
  providedIn: 'root',
})
export class HelperService {
  // TODO: Model erstellen
  private dependencies = new BehaviorSubject<any>({})
  public dependencies$ = this.dependencies.asObservable()

  constructor(private http: HttpClient) {}

  public init(): Promise<any> {
    // TODO: Model erstellen
    return this.http
      .get<any>(`${environment.api}/frontend-dependencies`)
      .toPromise()
      .then((data: any) => {
        this.dependencies.next(data)
      })
  }
}
