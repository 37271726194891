export class QmModel {
  id: number | null = null
  customer_id: number | null = null
  initial_consultation_date: Date | null = null
  initial_consultation_date_string = ''
  initial_consultation_from = ''
  initial_consultation_type = ''
  home_visit_date: Date | null = null
  home_visit_date_string = ''
  home_visit_from = ''
  postal_shipping_date: Date | null = null
  postal_shipping_date_string = ''
  postal_return_date: Date | null = null
  postal_return_date_string = ''
  comment = ''
  comment_thc = ''
  comment_new_customer_consultant = ''
  in_progress = false
  in_progress_from = ''
  in_progress_since: Date | null = null
  created_at: Date | null = null
  updated_at: Date | null = null
}
