import { Component, OnInit, ViewChild } from '@angular/core'
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog'
import { EventBusService, GlobalEvent } from 'src/app/services/eventbus.service'
import { ToastService } from 'src/app/services/toast.service'
import { NoticeService } from '../../../services/notice.service'
import { ConfirmationService } from 'primeng/api'
import { CaregiverOverviewService } from '../../../services/caregiver-overview.service'
import { NgForm } from '@angular/forms'
import { AuthModel } from '../../../models/user/auth.model'
import { AuthService } from '../../../services/auth.service'
import { TodoCommentModel } from '../../../models/todo/todo-comment.model'

@Component({
  selector: 'app-add-caregiver-overview-check-comment-dialog',
  templateUrl: './add-caregiver-overview-check-comment-dialog.component.html',
})
export class AddCaregiverOverviewCheckCommentDialogComponent implements OnInit {
  @ViewChild('form', { static: true }) form!: NgForm

  comment = ''
  submitted = false
  submittedDelete = false

  public currentUser: AuthModel | null = new AuthModel()
  // TODO: Model erstellen
  public editComment: any = null

  constructor(
    private ref: DynamicDialogRef,
    private overviewService: CaregiverOverviewService,
    public config: DynamicDialogConfig,
    private noticeService: NoticeService,
    private eventbus: EventBusService,
    private toastService: ToastService,
    public authService: AuthService,
    private confirmationService: ConfirmationService
  ) {}

  public ngOnInit(): void {
    this.currentUser = this.authService.getUser()
  }

  public save(): void {
    if (!this.form.form.valid) {
      this.submitted = false
      this.form.form.markAllAsTouched()
      return
    }

    this.submitted = true

    this.overviewService
      .storeCheckComment(
        this.config.data.caregiver_id,
        this.config.data,
        this.comment
      )
      .subscribe(
        () => {
          this.submitted = false
          this.eventbus.emit(GlobalEvent.CaregiverOverviewChanged)
          this.ref.close()
          this.toastService.success(
            'Kommentar erstellt',
            'Der Kommentar wurde erfolgreich bearbeitet'
          )
        },
        () => {
          this.toastService.error(
            'Etwas ist schiefgelaufen...',
            'Bitte wenden Sie sich an den Support'
          )

          this.submitted = false
        }
      )
  }

  public update(): void {
    if (!this.form.form.valid) {
      this.submitted = false
      this.form.form.markAllAsTouched()
      return
    }

    this.submitted = true

    this.overviewService
      .updateCheckComment(this.editComment.id, this.comment)
      .subscribe(
        () => {
          this.submitted = false
          this.eventbus.emit(GlobalEvent.CaregiverOverviewChanged)
          this.ref.close()
          this.toastService.success(
            'Kommentar bearbeitet',
            'Der Kommentar wurde erfolgreich bearbeitet'
          )
        },
        () => {
          this.toastService.error(
            'Etwas ist schiefgelaufen...',
            'Bitte wenden Sie sich an den Support'
          )

          this.submitted = false
        }
      )
  }

  public openEditMode(comment: TodoCommentModel): void {
    this.editComment = comment
    this.comment = comment.comment
  }

  public cancelEditMode(): void {
    this.editComment = null
    this.comment = ''
  }
}
