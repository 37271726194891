import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core'
import { EventBusService, GlobalEvent } from 'src/app/services/eventbus.service'
import { ToastService } from 'src/app/services/toast.service'
import { NgForm } from '@angular/forms'
import { Subscription } from 'rxjs'
import { PatientService } from '../../../services/patient.service'
import { SearchResultModel } from '../../../models/search/search-result.model'
import { PlanningService } from '../../../services/planning.service'
import {
  DialogService,
  DynamicDialogConfig,
  DynamicDialogRef,
} from 'primeng/dynamicdialog'
import * as dayjs from 'dayjs'
import * as customParseFormat from 'dayjs/plugin/customParseFormat'
import * as utc from 'dayjs/plugin/utc'
import { CaregiverService } from '../../../services/caregivers.service'
import { DiffViewDialogComponent } from '../diff-view-dialog/diff-view-dialog.component'
import { ConfirmWithTextDialogComponent } from '../confirm-with-text-dialog/confirm-with-text-dialog.component'
import { DesiredDateService } from '../../../services/desired-date.service'
import { DesiredDateModel } from '../../../models/desired-date/desired-date.model'
import { SelectItem } from 'primeng/api'
import { HttpErrorResponse } from '@angular/common/http'
import { StatusCodes } from 'http-status-codes'

@Component({
  selector: 'app-appointment-planning-dialog',
  templateUrl: './appointment-planning-dialog.component.html',
})
export class AppointmentPlanningDialogComponent implements OnInit, OnDestroy {
  @ViewChild('form', { static: true }) form!: NgForm

  private formSubscription: Subscription | null | undefined = null
  private isDirty = false

  submitted = false
  submittedDelete = false
  public hasInvoice = false

  public savedDataBeforeCheck: any = {}
  public budgets: any[] = []
  public weekHoursKostentraeger: any = 0

  public userSystemOptions: any[] = []
  public caregivers: SearchResultModel[] = []
  public patients: SearchResultModel[] = []

  public isEditMode = false
  public checkingBudgets = false
  public activeTab = 'CALENDAR'
  public showPlausibleButton = false
  public errors = {
    holiday: false,
    time_split: false,
    time: false,
    time_quarter: [] as any[],
    plausible: true,
    plausibleMessage: '',

    plausibleData: [] as any,
  }

  public intervalOptions: SelectItem[] = [
    {
      label: 'Täglich',
      value: 'DAILY',
    },
    {
      label: 'Wöchentlich',
      value: 'WEEKLY',
    },
    {
      label: 'Zweiwöchentlich',
      value: 'BIWEEKLY',
    },
    {
      label: 'Monatlich',
      value: 'MONTHLY',
    },
  ]

  public intervalDaysOptions: SelectItem[] = [
    {
      label: 'Mo',
      value: 1,
    },
    {
      label: 'Di',
      value: 2,
    },
    {
      label: 'Mi',
      value: 3,
    },
    {
      label: 'Do',
      value: 4,
    },
    {
      label: 'Fr',
      value: 5,
    },
    {
      label: 'Sa',
      value: 6,
    },
  ]

  public accordions = {}

  public keys = Object.keys

  public nearPatientsLoading = false
  public area = 1
  public nearPatients = []
  public appointmentsForCaregiver: any[] = []

  public lastAppointments: {
    grouped: any
    appointments: any
  } = {
    grouped: {},
    appointments: {},
  }

  public appointmentTask = ''
  public keepAppointmentBefore = false

  public originalResponseAppointment: any = {}

  public wasWithInterval = false
  public checkedIntervalAppointments: any = []
  public currentDay: any = null
  public currentWeek = ''
  public currentWeekIndex = 1
  public checkedTimeData: any = null

  public originalAppointment: any = {}
  public appointment: any = {
    date: '',
    date_to: '',
    comment: '',
    from: '',
    to: '',
    full_day_24h: false,
    budget_type: '',
    caregiver_name: '',
    patient_id: '',
    caregiver_id: '',
    with_interval: false,
    with_beihilfe: false,
    doctor_appointment: false,
    not_changeable: false,
    keep_appointment: false,
    store_desired_date: false,
    not_changeable_reason: '',
    split_budget: false,
    interval_type: 'WEEKLY',
    monthly_type: 'SAME_DAY',
    monthly_week: 1,
    interval_days: [],
  }

  public data: any
  public histories: any[] = []
  public holidays: any = {}
  public desiredDates: DesiredDateModel[] = []
  public desiredDatesCalendar: any = {}

  constructor(
    private ref: DynamicDialogRef,
    private config: DynamicDialogConfig,
    private caregiverService: CaregiverService,
    private dialogService: DialogService,
    private patientService: PatientService,
    private eventbus: EventBusService,
    private planningService: PlanningService,
    private desiredDateService: DesiredDateService,
    private toastService: ToastService
  ) {
    dayjs.locale('de')
    dayjs.extend(customParseFormat)
    dayjs.extend(utc)
  }

  public ngOnInit(): void {
    this.data = this.config.data

    this.isEditMode = this.data.data?.id && this.data.type === 'appointment'

    this.formSubscription = this.form.valueChanges?.subscribe(() => {
      if (!this.form.pristine) {
        this.isDirty = true
      }
    })

    this.appointment.date = this.data.date?.date
    this.checkedTimeData = this.data.date?.checked_time
    this.appointment.patient_id = this.data.patient.id
    this.appointment.budget_type = this.data.budget_type

    this.setCurrentDay()
    this.openNearPatients()

    if (this.data.type === 'appointment') {
      // Falls ein leerer Termin erstellt werden soll (in der Budgetleiste).
      if (!this.data.data) {
        this.appointment.comment = this.data.patient.appointment_comment
      } else if (!this.data.budget_type) {
        // Falls ein leerer Termin erstellt werden soll (in der BK Leiste).
        this.appointment.comment = this.data.patient.appointment_comment
        this.appointment.caregiver_name = this.data.data.caregiver_name
        this.appointment.caregiver_id = this.data.data.caregiver_id

        // Falls nur ein Budget vorhanden ist, wird dieses automatisch gesetzt.
        if (this.data.budgets.length === 1) {
          this.appointment.budget_type = this.data.budgets[0].type
        }
      } else {
        // Oder wenn ein vorhandener Termin bearbeitet werden soll.
        this.appointment.comment = this.data.data.task
        this.appointment.from = this.data.data.real_from_h
        this.appointment.to = this.data.data.real_to_h
        this.appointment.caregiver_name = this.data.data.caregiver.last_first_name
        this.appointment.caregiver_id = this.data.data.caregiver_id
        this.appointment.with_beihilfe = this.data.data.with_beihilfe
        this.wasWithInterval = this.data.data.with_interval
        this.appointment.doctor_appointment = this.data.data.doctor_appointment
        this.appointment.change_required = this.data.data.change_required
        this.appointment.keep_appointment = this.data.data.keep_appointment
        this.keepAppointmentBefore = this.data.data.keep_appointment
        this.appointment.date_to = dayjs(this.data.data.interval_until).format(
          'DD.MM.YYYY'
        )

        this.originalResponseAppointment = this.data.data
        this.hasInvoice = this.data.data.invoice_id !== null

        // Wir prüfen, ob die "to" Uhrzeit auf "24:00" steht.
        // Falls nicht, wurde dieser Termin durch eine splittung erstellt, und
        // nur der zweite Termin aus der splittung hat die "24:00" Uhr.
        this.appointment.full_day_24h = this.data.data.real_to_h === '24:00'

        // Wir speichern uns den originalen Termin ab, weil bei der
        // Stornierung geprüft werden muss, ob es eine Änderung gab.
        this.originalAppointment = JSON.parse(JSON.stringify(this.appointment))
      }
    } else if (this.data.type === 'caregiver') {
      this.appointment.caregiver_name = this.data.data.caregiver_name
      this.appointment.caregiver_id = this.data.data.caregiver_id
      this.appointment.comment = this.data.patient.appointment_comment
    } else if (this.data.type === 'copy') {
      // this.appointment.comment = this.data.data.task
      this.appointment.comment = ''
      this.appointment.from = this.data.data.real_from_h
      this.appointment.to = this.data.data.real_to_h
      this.appointment.caregiver_name = this.data.data.caregiver.last_first_name
      this.appointment.caregiver_id = this.data.data.caregiver_id

      // Wir prüfen, ob die "to" Uhrzeit auf "24:00" steht.
      // Falls nicht, wurde dieser Termin durch eine splittung erstellt, und
      // nur der zweite Termin aus der splittung hat die "24:00" Uhr.
      this.appointment.full_day_24h = this.data.data.real_to_h === '24:00'
    }

    this.loadDesiredDates()
    this.loadLastAppointments()
    this.loadHolidays()
    this.loadHistoriesForAppointment()
    this.loadCaregiverAppointments()
    this.loadPatientBudgets()
  }

  ngOnDestroy(): void {
    this.formSubscription?.unsubscribe()
  }

  public fullDay24hChanged(): void {
    if (this.appointment.full_day_24h) {
      this.appointment.to = '24:00'
    } else {
      this.appointment.to = ''
    }
  }

  public withIntervalToggle(): void {
    if (this.appointment.with_interval) {
      if (!this.appointment.date_to) {
        this.appointment.date_to = dayjs(this.appointment.date, 'DD.MM.YYYY')
          .endOf('month')
          .format('DD.MM.YYYY')
      }

      if (this.appointment.date) {
        if (this.appointment.interval_days.length < 2) {
          const dayIndex = dayjs(this.appointment.date, 'DD.MM.YYYY').format(
            'd'
          )
          this.appointment.interval_days = [+dayIndex]
        }
      }
    }
  }

  public loadPatientBudgets(): void {
    this.planningService
      .loadPatientBudgets(this.appointment.patient_id, this.appointment.date)
      .subscribe((response: any) => {
        this.appointmentTask = response.appointment_comment

        this.budgets = response.budgets
        this.weekHoursKostentraeger = response.week_hours_kostentraeger
      })
  }

  public keepChanged(): void {
    if (this.appointment.keep_appointment) {
      this.appointment.not_changeable = false
    }
  }

  public notChangeableChanged(): void {
    if (this.appointment.not_changeable) {
      this.appointment.keep_appointment = false
    } else {
      this.appointment.store_desired_date = false
    }
  }

  public setRhytmus(): void {
    // Falls das tägliche Intervall ausgewählt wurde,
    // müssen alle Tage automatisch ausgewählt werden.
    if (this.appointment.interval_type === 'DAILY') {
      this.appointment.interval_days = this.intervalDaysOptions.map(
        (option: any) => option.value
      )
    }
  }

  public setCurrentDay(): void {
    if (this.appointment.date) {
      this.currentDay = +dayjs(this.appointment.date, 'DD.MM.YYYY').format('D')
      this.currentWeek = dayjs(this.appointment.date, 'DD.MM.YYYY').format(
        'dddd'
      )

      this.currentWeekIndex = this.currentDay / 7
    }
  }

  public setCheckedTimeData(event: any): void {
    const found = this.data.dates.find((date: any) => {
      return date.date_complete === this.appointment.date
    })

    if (found) {
      this.checkedTimeData = found.checked_time
    }

    this.withIntervalToggle()
    this.loadCaregiverAppointments()
    this.loadLastAppointments()
    this.setCurrentDay()
    this.loadPatientBudgets()
  }

  public checkForTimes(event: any, type: string): void {
    const value = event.target.value

    if (value.length === 1) {
      this.appointment[type] = `0${value}:00`
    } else if (value.length === 2) {
      this.appointment[type] = `${value}:00`
    } else if (value.length === 4) {
      this.appointment[type] = `${value[0]}${value[1]}:${value[2]}${value[3]}`
    }

    if (type === 'from_2') {
      this.appointment.to_1 = this.appointment.from_2
    } else if (type === 'to_1') {
      this.appointment.from_2 = this.appointment.to_1
    }

    const time = this.appointment[type]

    if (time) {
      const minutes = time.split(':')[1]

      // Wir prüfen hier, ob die Minutenanzeige im 15-Minuten-Takt ist.
      if (!['00', '15', '30', '45'].includes(minutes)) {
        this.errors.time_quarter.push(type)
      } else {
        this.errors.time_quarter = this.errors.time_quarter.filter(
          (value: any) => value != type
        )
      }
    }
  }

  public budgetSplit(): void {
    if (this.appointment.split_budget) {
      this.appointment.from_1 = this.appointment.from
      this.appointment.to_2 = this.appointment.to
    }
  }

  public appointmentForDate(date: any): string {
    const appointment = this.lastAppointments.appointments[
      date.day + '.' + (date.month + 1) + '.' + date.year
    ]

    if (appointment) {
      return `${appointment.real_from_h} - ${appointment.real_to_h} (${appointment.caregiver.first_last_name})`
    }

    return ''
  }

  public openNearPatients(): void {
    this.patientService
      .getNearPatients(this.data.patient.customer_id, this.area)
      .subscribe((patients: any) => {
        this.nearPatients = patients
      })
  }

  public changeTab(type: string): void {
    this.activeTab = type
  }

  public save(): void {
    if (this.appointment.split_budget) {
      if (!this.appointment.budget_type_2) {
        alert('Bitte wählen Sie das zweite Budget')
        return
      }
    }

    if (this.appointment.split_budget) {
      if (this.appointment.budget_type_2 == this.appointment.budget_type) {
        alert('Das zweite Budget darf nicht gleich sein')
        return
      }
    }

    if (!this.form.form.valid) {
      this.submitted = false
      this.form.form.markAllAsTouched()
      return
    }

    if (this.errors.time_quarter.length > 0) {
      return
    }

    this.showPlausibleButton = false
    this.errors.time = false
    this.errors.time_split = false
    this.errors.holiday = false
    this.errors.plausible = true
    this.errors.plausibleMessage = ''
    this.errors.plausibleData = []

    // Die Uhrzeiten werden als integer umgewandelt, dadurch
    // lässt es sich leicht nach größer/kleiner überprüfen.
    const integerStart = this.appointment.from
      ? +this.appointment.from.replace(':', '')
      : 0
    const integerEnd = this.appointment.to
      ? +this.appointment.to.replace(':', '')
      : 0

    if (integerStart >= integerEnd) {
      this.errors.time = true
      return
    }

    // Die Uhrzeiten bei der aufteilung müssen auch gecheckt werden.
    if (this.appointment.split_budget) {
      const integerStart1 = this.appointment.from_1
        ? +this.appointment.from_1.replace(':', '')
        : 0
      const integerEnd1 = this.appointment.to_1
        ? +this.appointment.to_1.replace(':', '')
        : 0

      const integerStart2 = this.appointment.from_2
        ? +this.appointment.from_2.replace(':', '')
        : 0
      const integerEnd2 = this.appointment.to_2
        ? +this.appointment.to_2.replace(':', '')
        : 0

      if (
        integerStart1 >= integerEnd1 ||
        integerStart2 >= integerEnd2 ||
        integerStart2 < integerEnd1
      ) {
        this.errors.time = true
        return
      }

      if (integerStart1 < integerStart || integerEnd2 > integerEnd) {
        this.errors.time_split = true
        return
      }

      if (integerEnd1 !== integerStart2) {
        this.errors.time_split = true
        return
      }
    }

    this.submitted = true

    // Wir speichern uns die genauen Daten vorher ab, damit wir prüfen können
    // ob der check für die Plausibilität nochmal gemacht werden
    // muss (weil Daten geändert wurden).
    this.savedDataBeforeCheck = JSON.stringify(this.appointment)

    // Es wird zuerst geprüft, ob das Datum und die Uhrzeit plausibel sind.
    // Das heißt, dass die BK in dem Zeitraum keinen Urlaub, AU, Planung, etc. hat
    // oder ob das Budget des Patienten ausgereizt ist.
    if (!this.appointment.split_budget) {
      // Falls keine Aufteilung vom Budget aktiviert ist, müssen wir den check nur 1x machen.
      this.planningService
        .checkForAppointmentPlausibility(this.appointment, this.data.data?.id)
        .subscribe(
          (response: { plausible: boolean; message: string }[]) => {
            this.errors.plausibleData = response

            // Falls alles OK ist (keine Nachricht vorhanden ist),
            // wird der Termin erstellt/bearbeitet.
            if (response.length === 0) {
              this.saveAppointment()
            } else {
              // Wir prüfen, ob in der gesamten Plausibilität eins dabei ist,
              // das NICHT plausibel ist. Dann dürfen wir nicht speichern.
              const hasNotPlausibleData = response.find(
                (r: any) => !r.plausible
              )

              if (!hasNotPlausibleData) {
                this.showPlausibleButton = true
              }

              this.submitted = false
            }
          },
          () => {
            this.toastService.error(
              'Etwas ist schiefgelaufen...',
              'Bitte wenden Sie sich an den Support'
            )

            this.submitted = false
          }
        )
    } else {
      // Falls die Budgetaufteilung aktiv ist, müssen wir 2x den gleichen request machen.
      // Wir passen die Daten jeweils für den Request an. Das verhindert, dass wir
      // viele checks im Backend machen müssen.
      const newAppointment = { ...this.appointment }

      newAppointment.from = this.appointment.from_1
      newAppointment.to = this.appointment.to_1

      this.planningService
        .checkForAppointmentPlausibility(newAppointment, this.data.id)
        .subscribe(
          (response: { plausible: boolean; message: string }[]) => {
            this.errors.plausibleData = response

            newAppointment.from = this.appointment.from_2
            newAppointment.to = this.appointment.to_2
            newAppointment.budget_type = this.appointment.budget_type_2

            // Nun wird ein zweiter Request gestartet, um das zweite Budget zu prüfen.
            this.planningService
              .checkForAppointmentPlausibility(newAppointment, this.data.id)
              .subscribe(
                (response2: { plausible: boolean; message: string }[]) => {
                  // Die Fehlermeldungen werden dazuaddiert.
                  this.errors.plausibleData.push(...response2)

                  // Falls alles OK ist (keine Nachricht vorhanden ist),
                  // wird der Termin erstellt/bearbeitet.
                  if (response.length === 0) {
                    this.saveAppointment()
                  } else {
                    // Wir prüfen, ob in der gesamten Plausibilität eins dabei ist,
                    // das NICHT plausibel ist. Dann dürfen wir nicht speichern.
                    const hasNotPlausibleData = this.errors.plausibleData.find(
                      (r: any) => !r.plausible
                    )

                    if (!hasNotPlausibleData) {
                      this.showPlausibleButton = true
                    }

                    this.submitted = false
                  }
                },
                () => {
                  this.toastService.error(
                    'Etwas ist schiefgelaufen...',
                    'Bitte wenden Sie sich an den Support'
                  )

                  this.submitted = false
                }
              )
          },
          () => {
            this.toastService.error(
              'Etwas ist schiefgelaufen...',
              'Bitte wenden Sie sich an den Support'
            )

            this.submitted = false
          }
        )
    }
  }

  public formularHasChanged(): boolean {
    return JSON.stringify(this.appointment) !== this.savedDataBeforeCheck
  }

  public checkIntervalAppointments(): void {
    if (!this.form.form.valid) {
      this.submitted = false
      this.form.form.markAllAsTouched()
      return
    }

    if (this.errors.time_quarter.length > 0) {
      return
    }

    this.showPlausibleButton = false
    this.errors.time = false
    this.errors.holiday = false
    this.errors.plausible = true
    this.errors.plausibleMessage = ''
    this.errors.plausibleData = []

    // Die Uhrzeiten werden als integer umgewandelt, dadurch
    // lässt es sich leicht nach größer/kleiner überprüfen.
    const integerStart = this.appointment.from
      ? +this.appointment.from.replace(':', '')
      : 0
    const integerEnd = this.appointment.to
      ? +this.appointment.to.replace(':', '')
      : 0

    if (integerStart >= integerEnd) {
      this.errors.time = true
      return
    }

    this.submitted = true

    // Wir speichern uns die genauen Daten vorher ab, damit wir prüfen können
    // ob der check für die Plausibilität nochmal gemacht werden
    // muss (weil Daten geändert wurden).
    this.savedDataBeforeCheck = JSON.stringify(this.appointment)

    this.planningService.checkIntervalAppointments(this.appointment).subscribe(
      (appointments: any) => {
        this.checkedIntervalAppointments = appointments

        this.setPatientsTooltip()

        this.showPlausibleButton = true

        this.submitted = false
      },
      () => {
        this.toastService.error(
          'Etwas ist schiefgelaufen...',
          'Bitte wenden Sie sich an den Support'
        )

        this.submitted = false
      }
    )
  }

  public sub15Minutes(from: string, to: string): void {
    this.appointment[from] = dayjs('2000-01-01 ' + this.appointment[from])
      .subtract(15, 'minutes')
      .format('HH:mm')
    this.appointment[to] = dayjs('2000-01-01 ' + this.appointment[to])
      .subtract(15, 'minutes')
      .format('HH:mm')
  }

  public add15Minutes(from: string, to: string): void {
    // Falls keine "bis" Uhrzeit eingetragen ist (durch erstellung eines neuen Einsatzes),
    // wird die "von" Uhrzeit genommen und 45 min drauf addiert.
    if (!this.appointment[to]) {
      this.appointment[to] = dayjs('2000-01-01 ' + this.appointment[from])
        .add(45, 'minutes')
        .format('HH:mm')
    } else {
      this.appointment[from] = dayjs('2000-01-01 ' + this.appointment[from])
        .add(15, 'minutes')
        .format('HH:mm')
      this.appointment[to] = dayjs('2000-01-01 ' + this.appointment[to])
        .add(15, 'minutes')
        .format('HH:mm')
    }
  }

  public saveAppointment(): void {
    // Falls Daten im Formular geändert wurden, müssen wir "normal" speichern
    // damit der Check für die Plausibilität wieder ausgeführt wird.
    if (this.formularHasChanged()) {
      this.save()
      return
    }

    this.submitted = true

    const subscription = this.isEditMode
      ? this.planningService.changeAppointment(
          this.data.data.id,
          this.appointment
        )
      : this.planningService.addAppointment(this.appointment)

    subscription.subscribe(
      (response: any) => {
        this.submitted = false
        this.eventbus.emit(GlobalEvent.PlanningChanged)
        this.ref.close(response)

        const { caregiver_name, date, from, to } = this.appointment
        const message = `${caregiver_name} am ${date} ${from} - ${to}`

        if (this.isEditMode) {
          this.toastService.success('Einsatz verschoben', message)
        } else {
          this.toastService.success('Einsatz erstellt', message)
        }
      },
      (error: HttpErrorResponse) => {
        if (error.status === StatusCodes.EXPECTATION_FAILED) {
          this.toastService.error(
            'Es wurde bereits eine Rechnung für diesen Einsatz gestellt'
          )
        } else {
          this.toastService.error(
            'Etwas ist schief gelaufen...',
            'Bitte wenden Sie sich an den Support'
          )
        }
        this.submitted = false
      }
    )
  }

  public appointmentSelected(item: any): void {
    if (item.is_selected) {
      item.is_wish = false
    }

    this.checkBudgetsForIntervalAppointments()
  }

  private checkBudgetsForIntervalAppointments(): void {
    this.checkingBudgets = true

    this.planningService
      .checkBudgetsForIntervalAppointments(
        this.appointment,
        this.checkedIntervalAppointments
      )
      .subscribe(
        (data: any) => {
          this.checkedIntervalAppointments = data

          this.setPatientsTooltip()

          this.checkingBudgets = false
        },
        (error) => {
          this.checkingBudgets = false
        }
      )
  }

  public toggleWish(item: any): void {
    item.is_wish = !item.is_wish

    if (item.is_wish) {
      item.is_selected = false
    }

    this.checkBudgetsForIntervalAppointments()
  }

  public saveIntervalAppointments(): void {
    // Falls Daten im Formular geändert wurden, müssen wir "normal" speichern
    // damit der Check für die Plausibilität wieder ausgeführt wird.
    if (this.formularHasChanged()) {
      this.checkIntervalAppointments()
      return
    }

    // Es muss mindestens ein Termin angehakt sein.
    const hasCheckedAppointment = this.checkedIntervalAppointments.find(
      (appointment: any) => {
        return appointment.is_selected
      }
    )

    // Oder ein Wunschtermin ausgewählt sein.
    const hasWishAppointment = this.checkedIntervalAppointments.find(
      (appointment: any) => {
        return appointment.is_wish
      }
    )

    if (!hasCheckedAppointment && !hasWishAppointment) {
      alert('Bitte wählen Sie mindestens einen Termin oder Wunschtermin aus')
      return
    }

    this.submitted = true

    this.planningService
      .addIntervalAppointments(
        this.appointment,
        this.checkedIntervalAppointments
      )
      .subscribe(
        (response: any) => {
          this.submitted = false

          // Falls der nachträgliche Check neue Plausibilitätsfehler gefunden hat.
          if (response.errors && response.errors.length) {
            this.toastService.error(
              'Neue Fehler gefunden',
              'Bitte prüfen Sie nochmal alle Serientermine'
            )

            this.checkedIntervalAppointments = response.errors

            this.setPatientsTooltip()
          } else {
            this.toastService.success('Termine erstellt', '')
            this.eventbus.emit(GlobalEvent.PlanningChanged)
            this.ref.close(response.ids)
          }
        },
        () => {
          this.toastService.error(
            'Etwas ist schiefgelaufen...',
            'Bitte wenden Sie sich an den Support'
          )

          this.submitted = false
        }
      )
  }

  private setPatientsTooltip(): void {
    this.checkedIntervalAppointments.forEach((data: any) => {
      let tooltip = ''

      tooltip += '<div style="display: flex; flex-direction: column">'

      data.patients.forEach((patient: any, index: number) => {
        tooltip += '<small style="display: flex; flex-direction: column;">'

        if (index === 0) {
          tooltip += '<span>Von Zuhause</span>'
        }

        tooltip +=
          '<small class="drive-time-info-in-list color-white">' +
          '<i style="font-size: 12px;" class="pi pi-car color-gray"></i>' +
          patient.from_patient_duration_seconds_rounded / 60 +
          'min' +
          '</small>'

        tooltip +=
          patient.patient.first_name +
          ' ' +
          patient.patient.last_name +
          ' ' +
          patient.real_from_h +
          ' - ' +
          patient.real_to_h

        tooltip += '</small>'
      })

      tooltip += '</div>'

      data.tooltip_patients = tooltip
    })
  }

  public loadCaregiverAppointments(): void {
    if (this.appointment.caregiver_id && this.appointment.date) {
      this.caregiverService
        .loadPatientsForDay(
          this.appointment.caregiver_id,
          this.appointment.date
        )
        .subscribe((response: string[]) => {
          this.appointmentsForCaregiver = response
        })
    }
  }

  public loadLastAppointments(): void {
    if (this.appointment.date) {
      this.planningService
        .loadLastAppointmentsForPatient(
          this.appointment.patient_id,
          this.appointment.date
        )
        .subscribe((response: any) => {
          this.lastAppointments = response
        })
    }
  }

  public loadDesiredDates(): void {
    this.desiredDateService
      .loadForPatient(this.appointment.patient_id)
      .subscribe((response: any) => {
        this.desiredDatesCalendar = response.formatted
        this.desiredDates = response.normal
      })
  }

  public loadHistoriesForAppointment(): void {
    // History nur laden, wenn es ein vorhander Termin ist.
    if (this.data?.data?.id) {
      this.planningService
        .loadAppointmentHistories(this.data.data.id)
        .subscribe((response: any[]) => {
          this.histories = response
        })
    }
  }

  public loadHolidays(): void {
    this.planningService.loadAllHolidays().subscribe((response: any[]) => {
      this.holidays = response
    })
  }

  public openDiffView(id: number): void {
    this.dialogService.open(DiffViewDialogComponent, {
      data: {
        id,
      },
      header: 'Änderungen ansehen',
      styleClass: 'dialog-diff-view',
      dismissableMask: true,
    })
  }

  public getSelectedDay(type: 'date' | 'date_to'): string {
    if (this.appointment.date) {
      return dayjs(this.appointment[type], 'DD.MM.YYYY').format('dd')
    }

    return ''
  }

  public getCaregiverTimeTable(): string {
    const caregiver = this.data.caregivers.find(
      (c: any) => c.caregiver_id === this.appointment.caregiver_id
    )

    if (caregiver) {
      return caregiver.current_time.days_as_html_table
    }

    return ''
  }

  public remove(): void {
    // Stornierung lässt sich nicht öffnen, falls Änderungen im Einsatz gemacht wurden.
    // Weil wir beim Stornieren auf die richtigen Uhrzeiten angewiesen sind für die
    // Berechnungen der maximalen Werte (Fahrzeiten und anpassung der Uhrzeiten).
    if (
      this.originalAppointment.from !== this.appointment.from ||
      this.originalAppointment.to !== this.appointment.to ||
      this.originalAppointment.date !== this.appointment.date ||
      this.originalAppointment.budget_type !== this.appointment.budget_type
    ) {
      alert(
        'Änderungen bei Datum, Uhrzeit oder Budget erkannt. Bitte vorher speichern.'
      )

      return
    }

    const ref = this.dialogService.open(ConfirmWithTextDialogComponent, {
      header: 'Einsatz stornieren',
      width: '450px',
      styleClass: 'dialog-container',
      data: {
        appointment_complete: this.originalResponseAppointment,
        wasWithInterval: this.wasWithInterval,
        appointment: this.appointment,
      },
    })

    ref.onClose.subscribe((values: any) => {
      if (values) {
        this.submitted = true
        this.submittedDelete = true

        this.planningService
          .deleteAppointment(this.data.data.id, values)
          .subscribe(
            (response: any) => {
              this.eventbus.emit(GlobalEvent.PlanningChanged)
              this.ref.close(response)

              this.toastService.success(
                'Einsatz storniert',
                'Der Einsatz wurde erfolgreich storniert'
              )
            },
            () => {
              this.toastService.error(
                'Etwas ist schiefgelaufen...',
                'Bitte wenden Sie sich an den Support'
              )

              this.submitted = false
              this.submittedDelete = false
            }
          )
      }
    })
  }
}
