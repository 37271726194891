import { Component, ElementRef, OnInit, ViewChild } from '@angular/core'
import { TitleService } from 'src/app/services/title.service'
import { PatientService } from '../../../services/patient.service'
import { LatestChangeModel } from '../../../models/history/latest-change.model'
import * as dayjs from 'dayjs'

@Component({
  selector: 'app-patients-new',
  templateUrl: './patients-new.component.html',
})
export class PatientsNewComponent implements OnInit {
  @ViewChild('dt') table: ElementRef | any = null
  @ViewChild('opBudgets') overlayPanelBudgets: ElementRef | any = null

  public currentBudgetTable = ''
  public selectedPatientId: any = null

  public persplanData = new LatestChangeModel()
  private persplanDates: any = {}
  public filterValues = {
    selectedTypes: [] as any,
    types: [] as any,

    selectedFilterType: 'NO_FILTER',
    filterTypes: [
      { label: 'Kein Filter', value: 'NO_FILTER' },
      { label: 'Aktueller Monat', value: 'current' },
      { label: 'Nächster Monat', value: 'next' },
    ],
  }

  public globalFilterValue = ''
  public firstPage = 0

  public loading = false

  // TODO: model erstellen
  public patients: any[] = []
  public savedPatients: any[] = []

  constructor(
    private patientService: PatientService,
    private titleService: TitleService
  ) {}

  ngOnInit(): void {
    dayjs.locale('de')

    this.persplanDates = {
      current: {
        month: dayjs().format('MMMM'),
        year: dayjs().format('YYYY'),
      },
      next: {
        month: dayjs().add(1, 'month').format('MMMM'),
        year: dayjs().add(1, 'month').format('YYYY'),
      },
    }

    this.setFilterValues()
    this.loadUsers()
    this.titleService.setTitle('Neukunden')
  }

  public openBudgetOverlay(event: any, text: string, patientId: any): void {
    this.selectedPatientId = patientId

    this.currentBudgetTable = text

    this.overlayPanelBudgets.toggle(event)
  }

  public filterTableGlobal(event: any): void {
    this.table && this.table.filterGlobal(event.target.value.trim(), 'contains')
  }

  public customFilter(): void {
    // Reset der Patientenliste.
    if (this.filterValues.selectedFilterType === 'NO_FILTER') {
      this.patients = this.savedPatients
      return
    }

    this.patients = this.savedPatients.filter((patient: any) => {
      let isVisible = false

      if (this.filterValues.selectedTypes.includes('PAUSED') && !isVisible) {
        isVisible =
          patient[`pause_in_${this.filterValues.selectedFilterType}_month`] !==
          null
      }

      if (
        this.filterValues.selectedTypes.includes('PAUSE_ENDING') &&
        !isVisible &&
        this.filterValues.selectedFilterType === 'current'
      ) {
        isVisible = patient['pause_that_will_end'] !== null
      }

      if (this.filterValues.selectedTypes.includes('NOTHING') && !isVisible) {
        isVisible =
          patient[
            `budget_for_${this.filterValues.selectedFilterType}_month`
          ] === 3
      }

      if (this.filterValues.selectedTypes.includes('SOLL') && !isVisible) {
        isVisible =
          patient[
            `budget_for_${this.filterValues.selectedFilterType}_month`
          ] === 2
      }

      if (
        this.filterValues.selectedTypes.includes('APPOINTMENT') &&
        !isVisible
      ) {
        isVisible =
          patient[
            `budget_for_${this.filterValues.selectedFilterType}_month`
          ] === 1
      }

      return isVisible
    })
  }

  private setFilterValues(): void {
    this.filterValues.selectedTypes = [
      'NOTHING',
      'SOLL',
      'APPOINTMENT',
      'PAUSED',
      'PAUSE_ENDING',
    ]

    this.filterValues.types.push({
      value: 'NOTHING',
      name: 'Keine Persplan Planung',
      class: 'light-symbol-red',
    })

    this.filterValues.types.push({
      value: 'SOLL',
      name: 'SOLL vorhanden',
      class: 'light-symbol-orange',
    })

    this.filterValues.types.push({
      value: 'APPOINTMENT',
      name: 'Termine übergeben',
      class: 'light-symbol-green',
    })

    this.filterValues.types.push({
      value: 'PAUSED',
      name: 'Pausiert',
      class: 'light-symbol-dark',
    })

    this.filterValues.types.push({
      value: 'PAUSE_ENDING',
      name: 'Pause endet',
      class: 'light-symbol-blue',
    })
  }

  public getPersplanBudget(patientId: string, type: 'current' | 'next'): void {
    if (this.persplanData[type + patientId]) {
      return
    }

    this.patientService
      .getPersplanMonth(
        patientId,
        this.persplanDates[type].month,
        this.persplanDates[type].year,
        true
      )
      .subscribe((response: string) => {
        this.persplanData[type + patientId] = response
      })
  }

  private loadUsers(): void {
    this.loading = true

    this.patientService.new().subscribe((data: any[]) => {
      this.patients = data
      this.savedPatients = data

      this.loading = false

      setTimeout(() => {
        if (this.table) {
          const totalItems = this.table._totalRecords

          this.globalFilterValue = this.table.filters.global?.value ?? ''

          if (this.table._first > totalItems || this.table._rows > totalItems) {
            this.firstPage = 0
          }
        }
      })
    })
  }
}
