import { DashboardComponent } from '../views/dashboard/dashboard.component'
import { AuthGuard } from '../guards/auth.guard'
import { LoginComponent } from '../views/auth/login/login.component'
import { LoginRouteGuard } from '../guards/login-route.guard'
import { PhoneCallsComponent } from '../views/phone-calls/phone-calls.component'
import { GlobalDocumentsComponent } from '../views/global-documents/global-documents.component'
import { CapacityComponent } from '../views/capacity/capacity.component'
import { DesiredDatesIncomingComponent } from '../views/desired-dates/incoming/desired-dates-incoming.component'
import { CancelledDatesLastComponent } from '../views/cancelled-dates/last/cancelled-dates-last.component'
import { PasswordResetComponent } from '../views/auth/password-reset/password-reset.component'
import { SuperAdminGuard } from '../guards/super-admin.guard'
import { GlobalAnalysesComponent } from '../views/global-analyses/global-analyses.component'
import { AnalysesCaregiversComponent } from '../views/global-analyses/caregivers/analyses-caregivers.component'
import { AnalysesPlanningsComponent } from '../views/global-analyses/plannings/analyses-plannings.component'
import { UserSettingsComponent } from '../views/user-settings/user-settings.component'
import { DirtyCheckGuard } from '../guards/dirty-check.guard'
import { ChangeInitialPasswordComponent } from '../views/auth/change-initial-password/change-initial-password.component'
import { ThcDatesComponent } from '../views/calendar/thc-dates/thc-dates.component'
import { AnalysesBudgetsComponent } from '../views/global-analyses/budgets/analyses-budgets.component'
import { CalendarComponent } from '../views/calendar/calendar.component'
import { ConsultingDatesComponent } from '../views/calendar/consulting-dates/consulting-dates.component'
import { ConsultingsListComponent } from '../views/consultings-list/consultings-list.component'
import { ConsultingsListOpenComponent } from '../views/consultings-list/open/consultings-list-open.component'
import { ConsultingsListFinishedComponent } from '../views/consultings-list/finished/consultings-list-finished.component'
import { AnalysesProgressComponent } from '../views/global-analyses/progress/analyses-progress.component'
import { AnalysesPhoneCallsComponent } from '../views/global-analyses/phone-calls/analyses-phone-calls.component'
import { CancelledDatesComponent } from '../views/cancelled-dates/cancelled-dates.component'
import { CancelledDatesAllComponent } from '../views/cancelled-dates/all/cancelled-dates-all.component'
import { DesiredDatesComponent } from '../views/desired-dates/desired-dates.component'
import { DesiredDatesFinishedComponent } from '../views/desired-dates/finished/desired-dates-finished.component'
import { HandoverComponent } from '../views/handover/handover.component'
import { ProofsComponent } from '../views/proofs/proofs.component'
import { AnalysesRecommendationsComponent } from '../views/global-analyses/recommendations/analyses-recommendations.component'

export const miscRoutes = [
  {
    path: '',
    pathMatch: 'full',
    component: DashboardComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [LoginRouteGuard],
  },
  {
    path: 'settings',
    component: UserSettingsComponent,
    canActivate: [AuthGuard],
    canDeactivate: [DirtyCheckGuard],
  },
  {
    path: 'password-reset',
    component: PasswordResetComponent,
    canActivate: [LoginRouteGuard],
  },
  {
    path: 'change-initial-password',
    component: ChangeInitialPasswordComponent,
  },
  {
    path: 'phone-calls',
    component: PhoneCallsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'documents',
    component: GlobalDocumentsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'calendar',
    canActivate: [AuthGuard],
    component: CalendarComponent,
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'thc',
      },
      {
        path: 'thc',
        component: ThcDatesComponent,
      },
      {
        path: 'consulting',
        component: ConsultingDatesComponent,
      },
    ],
  },
  {
    path: 'capacity',
    component: CapacityComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'handover',
    component: HandoverComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'proofs',
    component: ProofsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'analyses',
    component: GlobalAnalysesComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: AnalysesCaregiversComponent,
      },
      {
        path: 'plannings',
        component: AnalysesPlanningsComponent,
        canActivate: [SuperAdminGuard],
      },
      {
        path: 'phone-calls',
        component: AnalysesPhoneCallsComponent,
        canActivate: [SuperAdminGuard],
      },
      {
        path: 'progress',
        component: AnalysesProgressComponent,
        canActivate: [SuperAdminGuard],
      },
      {
        path: 'recommendations',
        component: AnalysesRecommendationsComponent,
        canActivate: [SuperAdminGuard],
      },
      {
        path: 'budgets',
        component: AnalysesBudgetsComponent,
      },
    ],
  },
  {
    path: 'consultings',
    component: ConsultingsListComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: ConsultingsListOpenComponent,
      },
      {
        path: 'finished',
        component: ConsultingsListFinishedComponent,
      },
    ],
  },
  {
    path: 'desired-dates',
    component: DesiredDatesComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: DesiredDatesIncomingComponent,
      },
      {
        path: 'finished',
        component: DesiredDatesFinishedComponent,
      },
    ],
  },
  {
    path: 'cancelled-dates',
    component: CancelledDatesComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: CancelledDatesLastComponent,
      },
      {
        path: 'all',
        component: CancelledDatesAllComponent,
      },
    ],
  },
]
