import { UserSystemDetailModel } from '../user/user-system-detail.model'

export class PhoneCallsListModel {
  active: PhoneCallsListItemModel[] = []
  finished: PhoneCallsListItemModel[] = []
}

export class PhoneCallsListItemModel {
  id: number | null = null
  number = ''
  duration = ''
  phone_caller_name: string | null = null
  phone_caller_type: string | null = null
  phone_caller_id: number | null = null
  we_called = false
  created_at: Date | null = null
  user_id: number | null = null
  user_system: UserSystemDetailModel | null = null
}
