import { Component, OnInit } from '@angular/core'
import { UserCaregiverService } from '../../../../services/user-caregiver.service'
import { CaregiverDetailGeneralDataModel } from '../../../../models/caregiver/caregiver-detail-general.model'

@Component({
  selector: 'app-user-caregiver-data-master-data',
  templateUrl: './user-caregiver-data-master-data.component.html',
})
export class UserCaregiverDataMasterDataComponent implements OnInit {
  public loading = false

  public data: CaregiverDetailGeneralDataModel = new CaregiverDetailGeneralDataModel()
  public openedContactPersons: { [index: number]: boolean } = {}

  constructor(public userCaregiverService: UserCaregiverService) {}

  ngOnInit(): void {
    this.loadData()
  }

  public loadData(withLoading: boolean = true): void {
    if (withLoading) {
      this.loading = true
    }

    this.userCaregiverService
      .loadData()
      .subscribe((data: CaregiverDetailGeneralDataModel) => {
        this.data = data

        if (withLoading) {
          this.loading = false
        }
      })
  }
}
