import { SelectItem } from 'primeng/api'

export class OfferDependenciesModel {
  diseases: DiseaseModel[] = []
  services_care: ServiceModel[] = []
  services_housekeeping: ServiceModel[] = []
  tools: ToolModel[] = []
  communication_types: CommunicationTypeModel[] = []
  incontinence: SelectItem[] = []
  //degree_of_care_all: string[] = []
  degree_of_care_all: SelectItem[] = []
  // '45b_old_expiration_month' = 0
  '45b_old_expiration_month': { [key: number]: number }
  '45_old_monthly_budget' = 0
  '45_currently_monthly_budget' = 0
  '37_budgets': { [key: string]: number }
  '39_budget' = 0
  '39_degree_of_care_since_months' = 0
  '42_budget' = 0
  '42_degree_of_care_since_months' = 0
  '45a_budgets': { [key: string]: number }
  '45a_budgets_40%': { [key: string]: number }
  '37_budgets_60%_with_45a': { [key: string]: number }
}

export class DiseaseModel {
  id: number | null = null
  name = ''
  describe_label = false
}

export class ServiceModel {
  id: number | null = null
  name = ''
  type = ''
}

export class ToolModel {
  id: number | null = null
  name = ''
}

export class CommunicationTypeModel {
  id: number | null = null
  name = ''
}
