import { HouseholdModel } from '../customer-patient/household.model'
import { PatientModel } from '../customer-patient/patient.model'
import { ContactPersonModel } from '../contact-person/contact-person.model'
import { QmModel } from '../customer-patient/qm.model'

export class OfferFormModel {
  id: number | null = null
  service_scope = ''
  customer_service = ''
  patient_type = 'A'
  phone_consent = false
  offer_type = 'OFFER'
  created_from_multiplier = false
  created_from_caregiver = false
  consulting_assignment = false
  consulting_date = ''
  consulting_date_string = ''
  consulting_time = ''
  consulting_text = ''
  consulting_from = ''
  start_of_operation = ''
  start_of_operation_date = ''
  start_of_operation_date_string = ''
  summary_customer = ''
  comment_customer = ''
  summary_intern = ''
  summary_from_recommendation = ''
  attention_from_id: number | null = null
  attention_from_string = ''
  attention_from_info = ''
  recommendation_type: string | null = null
  recommendation_id = null
  recommendation: any = {}
  qm: QmModel = new QmModel()
  household: HouseholdModel = new HouseholdModel()
  first_patient: PatientModel = new PatientModel()
  second_patient: PatientModel = new PatientModel()
  contact_person: ContactPersonModel = new ContactPersonModel()
}
