import { Injectable } from '@angular/core'
import { MessageService } from 'primeng/api'

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  constructor(private messageService: MessageService) {}

  public success(
    summary: string,
    detail: string = '',
    sticky: boolean = false,
    closable: boolean = false
  ): void {
    this.messageService.add({
      key: 'success',
      severity: 'success',
      summary,
      detail,
      closable,
      sticky,
    })
  }

  public stickySuccess(summary: string, detail: string = ''): void {
    this.success(summary, detail, true, true)
  }

  public error(
    summary: string,
    detail: string = '',
    sticky: boolean = false,
    closable: boolean = false
  ): void {
    this.messageService.add({
      life: 10000,
      key: 'error',
      severity: 'error',
      detail,
      summary,
      closable,
      sticky,
    })
  }

  public stickyError(summary: string, detail: string = ''): void {
    this.error(summary, detail, true, true)
  }
}
