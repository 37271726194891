import { Component, OnDestroy, OnInit } from '@angular/core'
import { ActivatedRoute, ParamMap } from '@angular/router'

@Component({
  selector: 'app-caregivers-detail-overview',
  templateUrl: './caregivers-detail-overview.component.html',
})
export class CaregiversDetailOverviewComponent implements OnInit, OnDestroy {
  public caregiverId: string | null = null
  private routeSubscription: any = null

  constructor(private route: ActivatedRoute) {}

  ngOnInit(): void {
    if (this.route.parent) {
      this.routeSubscription = this.route.parent.paramMap.subscribe(
        (params: ParamMap) => {
          this.caregiverId = params.get('id')
        }
      )
    }
  }

  ngOnDestroy(): void {
    this.routeSubscription && this.routeSubscription.unsubscribe()
  }
}
