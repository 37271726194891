import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs'
import { environment } from '../../environments/environment'
import {
  CapacityModel,
  CapacityResponseModel,
} from '../models/capacity/capacity.model'

@Injectable({
  providedIn: 'root',
})
export class CapacityService {
  constructor(private http: HttpClient) {}

  public load(
    mondayDate: string,
    selectedPlanner: number
  ): Observable<CapacityResponseModel> {
    return this.http.get<CapacityResponseModel>(`${environment.api}/capacity`, {
      params: {
        selectedPlanner,
        monday_date: mondayDate,
      },
    })
  }

  public loadLastUpdate(): Observable<string> {
    return this.http.get(`${environment.api}/capacity/last-update`, {
      responseType: 'text',
    })
  }

  public getPersplan(caregiverId: number, date: string): Observable<string> {
    return this.http.get(`${environment.api}/capacity/persplan`, {
      responseType: 'text',
      params: {
        caregiver_id: caregiverId.toString(),
        date,
      },
    })
  }
}
