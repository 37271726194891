export class CaregiverJobScopeModel {
  id = 0
  job_scope = 0
  caregiver_id = 0
  since: Date | null = null
  comment = ''
  deleted_at: Date | null = null
  created_at: Date | null = null
  updated_at: Date | null = null
}
