import { Component, OnInit } from '@angular/core'
import { DocumentService } from '../../../services/document.service'
import {
  DialogService,
  DynamicDialogConfig,
  DynamicDialogRef,
} from 'primeng/dynamicdialog'
import { SendAppointmentsDialogComponent } from '../send-appointments-dialog/send-appointments-dialog.component'
import { PatientService } from '../../../services/patient.service'
import { HistoryListModel } from '../../../models/history/history-list.model'

@Component({
  selector: 'app-confirm-important-histories-dialog',
  templateUrl: './confirm-important-histories-dialog.component.html',
})
export class ConfirmImportantHistoriesDialogComponent implements OnInit {
  public histories: HistoryListModel[] = []

  constructor(
    public documentService: DocumentService,
    private ref: DynamicDialogRef,
    private patientService: PatientService,
    private config: DynamicDialogConfig,
    private dialogService: DialogService
  ) {}

  public ngOnInit(): void {
    this.patientService
      .historyImportant(this.config.data.patient_id)
      .subscribe((histories: HistoryListModel[]) => {
        this.histories = histories
      })
  }

  public confirm(): void {
    this.dialogService.open(SendAppointmentsDialogComponent, {
      header: 'Termine versenden',
      width: '900px',
      styleClass: 'dialog-container',
      data: this.config.data,
    })

    // Ein Timeout wurde hinzugefügt, da er ansonsten die Seite komplett neu lädt.
    // Keine Ahnung warum...
    setTimeout(() => {
      this.ref.close()
    })
  }
}
