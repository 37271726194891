<app-loader [loading]="loading"></app-loader>

<div class="content" *ngIf="!loading">
  <header class="content-header">
    <span>
      <i (click)="goToPreviousMonth()" class="pi pi-chevron-left"></i>

      <p-dropdown
        inputId="month"
        name="month"
        scrollHeight="500px"
        (onChange)="loadAnalyses(false)"
        [options]="monthOptions"
        [(ngModel)]="month"
        [style]="{ width: '150px', zIndex: '10' }"
      ></p-dropdown>

      <p-dropdown
        inputId="year"
        name="year"
        (onChange)="loadAnalyses(false)"
        [options]="yearOptions"
        [(ngModel)]="year"
        [style]="{ margin: '0 0 0 -1px' }"
      ></p-dropdown>

      <i
        (click)="goToNextMonth()"
        class="pi pi-chevron-right"
        style="margin: 0 10px 0 10px"
      ></i>

      <p-dropdown
        [style]="{ width: '140px' }"
        (onChange)="loadAnalyses(false)"
        [showClear]="true"
        name="planner_id"
        inputId="planner_id"
        [(ngModel)]="selectedPlanner"
        placeholder="Betreuer"
        [options]="plannerUsers"
        optionValue="id"
        optionLabel="full_name"
        scrollHeight="300px"
      ></p-dropdown>

      <p-checkbox
        label="sortieren"
        (onChange)="loadAnalyses(false)"
        [(ngModel)]="isSorted"
        [binary]="true"
        name="sort"
      ></p-checkbox>
    </span>

    <div>
      <span
        style="margin: 0 20px"
        *ngIf="authService.can('Auswertungen.Anzahl Patienten sichtbar')"
        >Anzahl der Patienten: {{ data.total.AMOUNT_PATIENTS }}</span
      >

      <!--      <div class="content-header-info">-->
      <!--        Aktualisiert am {{ lastUpdate }} Uhr-->
      <!--      </div>-->
    </div>

    <div>
      <a
        target="_blank"
        [href]="
          globalAnalysesService.exportCaregiversUrls(
            month,
            year,
            isSorted,
            selectedPlanner
          )
        "
      >
        <button
          pButton
          label="Export"
          type="button"
          class="p-button-outlined"
          icon="pi pi-download"
          iconPos="left"
        ></button>
      </a>
    </div>
  </header>

  <div
    class="content-body without-padding"
    [class.content-loading]="contentLoading"
  >
    <div class="analyses-container">
      <div class="analyses-informations-container">
        <div class="analyses-informations">
          <div class="analyses-header"></div>
          <div class="analyses-informations-content">
            <div></div>
            <div></div>
            <div
              (mouseleave)="setHover('')"
              (mouseenter)="setHover('VOLUME')"
              [class.highlight]="currentHover === 'VOLUME'"
            ></div>
            <div
              (mouseleave)="setHover('')"
              (mouseenter)="setHover('ILL')"
              [class.highlight]="currentHover === 'ILL'"
            >
              AU Tage
            </div>
            <div
              (mouseleave)="setHover('')"
              (mouseenter)="setHover('ILL_QUOTA')"
              [class.highlight]="currentHover === 'ILL_QUOTA'"
            >
              AU Quote
            </div>
            <div
              (mouseleave)="setHover('')"
              (mouseenter)="setHover('VACATION')"
              [class.highlight]="currentHover === 'VACATION'"
            >
              Urlaubstage
            </div>
            <div
              (mouseleave)="setHover('')"
              (mouseenter)="setHover('OVER_UNDER_TIME_LAST_MONTH')"
              [class.highlight]="currentHover === 'OVER_UNDER_TIME_LAST_MONTH'"
            >
              Überstunden <i class="small-info">letzter Monat</i>
            </div>
            <div
              (mouseleave)="setHover('')"
              (mouseenter)="setHover('OVER_UNDER_TIME')"
              [class.highlight]="currentHover === 'OVER_UNDER_TIME'"
            >
              Überstunden <i class="small-info">aktueller Monat</i>
            </div>
            <div
              (mouseleave)="setHover('')"
              (mouseenter)="setHover('ACCUMULATED')"
              [class.highlight]="currentHover === 'ACCUMULATED'"
            >
              Aufgelaufen
              <i
                pTooltip="Wird Nachts aktualisiert"
                class="pi pi-info-circle small-info"
              ></i>
            </div>
            <div
              class="divider"
              (mouseleave)="setHover('')"
              (mouseenter)="setHover('HW')"
              [class.highlight]="currentHover === 'HW'"
            >
              Reine HW
            </div>
            <div
              (mouseleave)="setHover('')"
              (mouseenter)="setHover('BE')"
              [class.highlight]="currentHover === 'BE'"
            >
              Reine BE
            </div>
            <div
              (mouseleave)="setHover('')"
              (mouseenter)="setHover('HW_BE')"
              [class.highlight]="currentHover === 'HW_BE'"
            >
              HW und BE
            </div>
            <div
              class="divider"
              (mouseleave)="setHover('')"
              (mouseenter)="setHover('BASIS_CAPACITY')"
              [class.highlight]="currentHover === 'BASIS_CAPACITY'"
            >
              Grundkapazität
            </div>
            <div
              (mouseleave)="setHover('')"
              (mouseenter)="setHover('CAPACITY')"
              [class.highlight]="currentHover === 'CAPACITY'"
            >
              Kapazität <i class="small-info">abzgl. AU / U</i>
            </div>
            <div
              (mouseleave)="setHover('')"
              (mouseenter)="setHover('SOLL_PERSPLAN')"
              [class.highlight]="currentHover === 'SOLL_PERSPLAN'"
            >
              Arbeitszeit lt. Schichtplan
              <i
                pTooltip="Grün: IST-Werte vorhanden"
                [escape]="false"
                class="pi pi-info-circle small-info"
              ></i>
            </div>
            <div
              (mouseleave)="setHover('')"
              (mouseenter)="setHover('DRIVE_TIME')"
              [class.highlight]="currentHover === 'DRIVE_TIME'"
            >
              Fahrtzeit lt. Aufzeichnung
            </div>
            <div
              (mouseleave)="setHover('')"
              (mouseenter)="setHover('DRIVE_TIME_GOOGLE')"
              [class.highlight]="currentHover === 'DRIVE_TIME_GOOGLE'"
            >
              Fahrtzeit lt. Google
            </div>
            <div
              (mouseleave)="setHover('')"
              (mouseenter)="setHover('DRIVING_PERCENTAGE')"
              [class.highlight]="currentHover === 'DRIVING_PERCENTAGE'"
            >
              % Fahrtzeit lt. Aufzeichnung
            </div>
            <div
              (mouseleave)="setHover('')"
              (mouseenter)="setHover('DRIVING_PERCENTAGE_GOOGLE')"
              [class.highlight]="currentHover === 'DRIVING_PERCENTAGE_GOOGLE'"
            >
              % Fahrtzeit lt. Google
            </div>
            <div
              (mouseleave)="setHover('')"
              (mouseenter)="setHover('FREE_CAPACITY')"
              [class.highlight]="currentHover === 'FREE_CAPACITY'"
            >
              Freie Kapazität
            </div>
            <div
              (mouseleave)="setHover('')"
              (mouseenter)="setHover('UTILISATION')"
              [class.highlight]="currentHover === 'UTILISATION'"
            >
              Auslastung
            </div>
          </div>
        </div>
        <div class="analyses-item">
          <div class="analyses-header"><span>Total</span></div>
          <div class="analyses-content">
            <div></div>
            <!-- Freischaltung Auswertung -->
            <div>
              <button
                *ngIf="authService.isSuperAdmin() && globalRelease"
                pButton
                (click)="unreleaseAccumulatedForAll()"
                pTooltip="Freigabe für alle zurückziehen"
                label="Freigabe"
                type="button"
              ></button>

              <button
                *ngIf="authService.isSuperAdmin() && !globalRelease"
                pButton
                (click)="releaseAccumulatedForAll()"
                pTooltip="Freigabe für alle erteilen"
                label="Freigabe"
                type="button"
                class="p-button-outlined"
              ></button>
            </div>

            <!-- Umfang -->
            <div
              (mouseleave)="setHover('')"
              (mouseenter)="setHover('VOLUME')"
              [class.highlight]="currentHover === 'VOLUME'"
            >
              {{ data.total.job_scope_formatted }}
            </div>

            <!-- AU Tage -->
            <div
              (mouseleave)="setHover('')"
              (mouseenter)="setHover('ILL')"
              [class.highlight]="currentHover === 'ILL'"
            >
              {{ data.total.ILL }} Tage
            </div>

            <!-- AU Quote -->
            <div
              (mouseleave)="setHover('')"
              (mouseenter)="setHover('ILL_QUOTA')"
              [class.highlight]="currentHover === 'ILL_QUOTA'"
            >
              <!--              {{ data.total.ILL }} Tage-->
            </div>

            <!-- Urlaubstage -->
            <div
              (mouseleave)="setHover('')"
              (mouseenter)="setHover('VACATION')"
              [class.highlight]="currentHover === 'VACATION'"
            >
              {{ data.total.VACATION }} Tage
            </div>

            <!-- Überstunden letzter Monat -->
            <div
              (mouseleave)="setHover('')"
              (mouseenter)="setHover('OVER_UNDER_TIME_LAST_MONTH')"
              [class.highlight]="currentHover === 'OVER_UNDER_TIME_LAST_MONTH'"
            >
              <span *ngIf="!loadingOverTime">
                {{ data.total.OVER_UNDER_TIME_LAST_MONTH }} Std
              </span>

              <span *ngIf="loadingOverTime">??</span>
            </div>

            <!-- Überstunden aktueller Monat -->
            <div
              (mouseleave)="setHover('')"
              (mouseenter)="setHover('OVER_UNDER_TIME')"
              [class.highlight]="currentHover === 'OVER_UNDER_TIME'"
            >
              {{ data.total.OVER_UNDER_TIME }} Std
            </div>

            <!-- Aufgelaufen -->
            <div
              (mouseleave)="setHover('')"
              (mouseenter)="setHover('ACCUMULATED')"
              [class.highlight]="currentHover === 'ACCUMULATED'"
            >
              {{ data.total.ACCUMULATED }} Std
            </div>

            <!-- Reine HW -->
            <div
              class="divider"
              (mouseleave)="setHover('')"
              (mouseenter)="setHover('HW')"
              [class.highlight]="currentHover === 'HW'"
            >
              {{ data.total.PERCENTAGE_HW }} %
            </div>

            <!-- Reine BE -->
            <div
              (mouseleave)="setHover('')"
              (mouseenter)="setHover('BE')"
              [class.highlight]="currentHover === 'BE'"
            >
              {{ data.total.PERCENTAGE_BE }} %
            </div>

            <!-- HW und BE -->
            <div
              (mouseleave)="setHover('')"
              (mouseenter)="setHover('HW_BE')"
              [class.highlight]="currentHover === 'HW_BE'"
            >
              {{ data.total.PERCENTAGE_HW_BE }} %
            </div>

            <!-- Grundkapazität -->
            <div
              class="divider"
              (mouseleave)="setHover('')"
              (mouseenter)="setHover('BASIS_CAPACITY')"
              [class.highlight]="currentHover === 'BASIS_CAPACITY'"
            >
              {{ data.total.BASIS_CAPACITY }} Std
            </div>

            <!-- Kapazität -->
            <div
              (mouseleave)="setHover('')"
              (mouseenter)="setHover('CAPACITY')"
              [class.highlight]="currentHover === 'CAPACITY'"
            >
              {{ data.total.CAPACITY }} Std
            </div>

            <!-- Arbeitszeit laut Schichtplan -->
            <div
              (mouseleave)="setHover('')"
              style="align-items: center"
              (mouseenter)="setHover('SOLL_PERSPLAN')"
              [class.highlight]="currentHover === 'SOLL_PERSPLAN'"
            >
              <i
                [pTooltip]="
                  'Abrechenbare Stunden: ' + data.total.SOLL_PERSPLAN_REAL
                "
                class="pi pi-info-circle small-info"
                style="margin: 0 10px 0 0"
              ></i>
              {{ data.total.SOLL_PERSPLAN }} Std
            </div>

            <!-- Fahrtzeit laut Aufzeichnung -->
            <div
              (mouseleave)="setHover('')"
              (mouseenter)="setHover('DRIVE_TIME')"
              [class.highlight]="currentHover === 'DRIVE_TIME'"
            >
              {{ data.total.DRIVE_TIME }} Std
            </div>

            <!-- Fahrtzeit laut Google -->
            <div
              (mouseleave)="setHover('')"
              (mouseenter)="setHover('DRIVE_TIME_GOOGLE')"
              [class.highlight]="currentHover === 'DRIVE_TIME_GOOGLE'"
            >
              {{ data.total.DRIVE_TIME_GOOGLE }} Std
            </div>

            <!-- % Fahrtzeit laut Aufzeichnung -->
            <div
              style="align-items: center"
              (mouseleave)="setHover('')"
              (mouseenter)="setHover('DRIVING_PERCENTAGE')"
              [class.highlight]="currentHover === 'DRIVING_PERCENTAGE'"
            >
              <i
                pTooltip="Der Durchschnitt. Werte mit 0,00 % werden nicht berücksichtigt."
                [escape]="false"
                style="margin: 0 10px 0 0"
                class="pi pi-info-circle small-info"
              ></i>

              {{ data.total.DRIVING_PERCENTAGE }} %
            </div>

            <!-- % Fahrtzeit laut Google -->
            <div
              style="align-items: center"
              (mouseleave)="setHover('')"
              (mouseenter)="setHover('DRIVING_PERCENTAGE_GOOGLE')"
              [class.highlight]="currentHover === 'DRIVING_PERCENTAGE_GOOGLE'"
            >
              <i
                pTooltip="Der Durchschnitt. Werte mit 0,00 % werden nicht berücksichtigt."
                [escape]="false"
                style="margin: 0 10px 0 0"
                class="pi pi-info-circle small-info"
              ></i>

              {{ data.total.DRIVING_PERCENTAGE_GOOGLE }} %
            </div>

            <!-- Freie Kapazität -->
            <div
              style="align-items: center"
              (mouseleave)="setHover('')"
              (mouseenter)="setHover('FREE_CAPACITY')"
              [class.highlight]="currentHover === 'FREE_CAPACITY'"
            >
              <i
                [pTooltip]="data.total.FREE_CAPACITY_FROM_NOW + ' Std'"
                [escape]="false"
                style="margin: 0 10px 0 0"
                class="pi pi-info-circle small-info"
              ></i>

              {{ data.total.FREE_CAPACITY }} Std
            </div>

            <!-- Auslastung -->
            <div
              style="align-items: center"
              (mouseleave)="setHover('')"
              (mouseenter)="setHover('UTILISATION')"
              [class.highlight]="currentHover === 'UTILISATION'"
            >
              <i
                [pTooltip]="data.total.UTILISATION_REAL + ' %'"
                [escape]="false"
                style="margin: 0 10px 0 0"
                class="pi pi-info-circle small-info"
              ></i>

              {{ data.total.UTILISATION }} %
            </div>
          </div>
        </div>
      </div>

      <div class="analyses-data">
        <div class="analyses-item" *ngFor="let caregiver of data.caregivers">
          <a
            routerLink="/caregivers/{{
              caregiver.caregiver.id
            }}/overview/analyses"
            target="_blank"
            class="analyses-header"
            [pTooltip]="caregiver.caregiver.full_name"
            tooltipPosition="top"
          >
            <span>{{ caregiver.caregiver.last_name }}</span>
            <span> {{ caregiver.caregiver.first_name[0] }}.</span>
          </a>
          <div class="analyses-content">
            <!-- Freigabe Plannung -->
            <div>
              <i
                *ngIf="caregiver.planning_type === 'NO_PLANNINGS'"
                class="light-symbol light-symbol-red"
                pTooltip="Keine Planung vorhanden"
                tooltipPosition="left"
              ></i>

              <i
                *ngIf="caregiver.planning_type === 'HAS_PLANNINGS'"
                class="light-symbol light-symbol-orange"
                pTooltip="Planung gestartet"
                tooltipPosition="left"
              ></i>

              <i
                *ngIf="caregiver.planning_type === 'APPROVED'"
                class="light-symbol light-symbol-green"
                [pTooltip]="
                  caregiver.approved_planning?.comment || 'Abgeschlossen'
                "
                tooltipPosition="left"
              ></i>
            </div>

            <!-- Freischaltung Auswertung -->
            <div>
              <button
                *ngIf="
                  caregiver.accumulated_single?.released &&
                  authService.isSuperAdmin()
                "
                (click)="
                  unreleaseAccumulatedFromUser(
                    caregiver,
                    caregiver.caregiver.id
                  )
                "
                pButton
                pTooltip="Freigabe zurückziehen"
                label="Freigabe"
                type="button"
                icon="pi pi-check"
                iconPos="left"
              ></button>

              <button
                *ngIf="
                  !caregiver.accumulated_single?.released &&
                  authService.isSuperAdmin()
                "
                (click)="
                  releaseAccumulatedToUser(caregiver, caregiver.caregiver.id)
                "
                pButton
                pTooltip="Freigabe erteilen"
                label="Freigabe"
                type="button"
                icon="pi pi-times"
                iconPos="left"
                class="p-button-outlined"
              ></button>
            </div>
            <!-- Umfang -->
            <div
              style="align-items: center"
              (mouseleave)="setHover('')"
              (mouseenter)="setHover('VOLUME')"
              [class.highlight]="currentHover === 'VOLUME'"
            >
              <i
                style="font-size: 13px; margin: 0 10px 0 0"
                class="pi pi-info-circle color-blue"
                *ngFor="let date of caregiver.employed_since_this_month"
                [pTooltip]="'Angestellt seit ' + date"
              ></i>

              <i
                style="font-size: 13px; margin: 0 10px 0 0"
                class="pi pi-info-circle color-alert"
                *ngFor="let date of caregiver.employed_until_this_month"
                [pTooltip]="'Angestellt bis ' + date"
              ></i>

              {{ caregiver.job_scope_formatted }}
            </div>

            <!-- AU Tage -->
            <div
              (mouseleave)="setHover('')"
              (mouseenter)="setHover('ILL')"
              [class.highlight]="currentHover === 'ILL'"
            >
              {{ caregiver.times.ILL.value }} {{ caregiver.times.ILL.suffix }}
            </div>

            <!-- AU Quote -->
            <div
              (mouseleave)="setHover('')"
              (mouseenter)="setHover('ILL_QUOTA')"
              [class.highlight]="currentHover === 'ILL_QUOTA'"
            >
              <span *ngIf="caregiver.ill_quota">
                {{ caregiver.ill_quota.percentage }}
                % / {{ caregiver.ill_quota.days }} Tage
              </span>
              <span *ngIf="!caregiver.ill_quota">-</span>
            </div>

            <!-- Urlaubstage -->
            <div
              (mouseleave)="setHover('')"
              (mouseenter)="setHover('VACATION')"
              [class.highlight]="currentHover === 'VACATION'"
            >
              {{ caregiver.times.VACATION.value }}
              {{ caregiver.times.VACATION.suffix }}
            </div>

            <!-- Überstunden letzter Monat -->
            <div
              (mouseleave)="setHover('')"
              (mouseenter)="setHover('OVER_UNDER_TIME_LAST_MONTH')"
              [class.highlight]="currentHover === 'OVER_UNDER_TIME_LAST_MONTH'"
            >
              {{ caregiver.times.OVER_UNDER_TIME_LAST_MONTH?.value || "-" }}
              {{ caregiver.times.OVER_UNDER_TIME_LAST_MONTH?.suffix || "" }}
            </div>

            <!-- Überstunden aktueller Monat -->
            <div
              (mouseleave)="setHover('')"
              (mouseenter)="setHover('OVER_UNDER_TIME')"
              [class.highlight]="currentHover === 'OVER_UNDER_TIME'"
              [class.alert-highlight]="
                caregiver.times.OVER_UNDER_TIME.real_value < 0
              "
            >
              {{ caregiver.times.OVER_UNDER_TIME.value }}
              {{ caregiver.times.OVER_UNDER_TIME.suffix }}
            </div>

            <!-- Aufgelaufen -->
            <div
              (mouseleave)="setHover('')"
              (mouseenter)="setHover('ACCUMULATED')"
              [class.highlight]="currentHover === 'ACCUMULATED'"
              [class.alert-highlight]="
                caregiver.times.ACCUMULATED.real_value < 0
              "
            >
              {{ caregiver.times.ACCUMULATED.value }}
              {{ caregiver.times.ACCUMULATED.suffix }}
            </div>

            <!-- Reine HW -->
            <div
              class="divider"
              (mouseleave)="setHover('')"
              (mouseenter)="setHover('HW')"
              [class.highlight]="currentHover === 'HW'"
            >
              {{ caregiver.times.HW.value }}
            </div>

            <!-- Reine BE -->
            <div
              (mouseleave)="setHover('')"
              (mouseenter)="setHover('BE')"
              [class.highlight]="currentHover === 'BE'"
            >
              {{ caregiver.times.BE.value }}
            </div>

            <!-- HW und BE -->
            <div
              (mouseleave)="setHover('')"
              (mouseenter)="setHover('HW_BE')"
              [class.highlight]="currentHover === 'HW_BE'"
            >
              {{ caregiver.times.HW_BE.value }}
            </div>

            <!-- Grundkapazität -->
            <div
              class="divider"
              (mouseleave)="setHover('')"
              (mouseenter)="setHover('BASIS_CAPACITY')"
              [class.highlight]="currentHover === 'BASIS_CAPACITY'"
            >
              {{ caregiver.times.BASIS_CAPACITY.value }}
              {{ caregiver.times.BASIS_CAPACITY.suffix }}
            </div>

            <!-- Kapazität -->
            <div
              (mouseleave)="setHover('')"
              (mouseenter)="setHover('CAPACITY')"
              [class.highlight]="currentHover === 'CAPACITY'"
            >
              {{ caregiver.times.CAPACITY.value }}
              {{ caregiver.times.CAPACITY.suffix }}
            </div>

            <!-- Arbeitszeit laut Schichtplan -->
            <div
              style="align-items: center"
              (mouseleave)="setHover('')"
              (mouseenter)="setHover('SOLL_PERSPLAN')"
              [class.highlight]="currentHover === 'SOLL_PERSPLAN'"
            >
              <i
                style="font-size: 12px; margin: 0 3px 0 0"
                class="pi pi-info-circle color-gray"
                pTooltip="Laut Persplan: {{
                  caregiver.times.SOLL_PERSPLAN.value_from_persplan
                }} {{ caregiver.times.SOLL_PERSPLAN.suffix }}"
                *ngIf="
                  caregiver.times.SOLL_PERSPLAN.value_from_persplan &&
                  caregiver.times.SOLL_PERSPLAN.value_from_persplan != '0,00' &&
                  caregiver.times.SOLL_PERSPLAN.value_from_persplan !=
                    caregiver.times.SOLL_PERSPLAN.value
                "
              ></i>

              <span>
                {{ caregiver.times.SOLL_PERSPLAN.value }}
                {{ caregiver.times.SOLL_PERSPLAN.suffix }}
              </span>
            </div>

            <!-- Fahrtzeit lt. Aufzeichnung -->
            <div
              (mouseleave)="setHover('')"
              (mouseenter)="setHover('DRIVE_TIME')"
              [class.highlight]="currentHover === 'DRIVE_TIME'"
              [class.background-hightlight-main2]="
                caregiver.times.DRIVE_TIME.has_data
              "
            >
              {{ caregiver.times.DRIVE_TIME.value }}
              {{ caregiver.times.DRIVE_TIME.suffix }}
            </div>

            <!-- Fahrtzeit lt. Google -->
            <div
              (mouseleave)="setHover('')"
              (mouseenter)="setHover('DRIVE_TIME_GOOGLE')"
              [class.highlight]="currentHover === 'DRIVE_TIME_GOOGLE'"
              [class.background-hightlight-blue]="
                caregiver.times.DRIVE_TIME_GOOGLE.has_data
              "
            >
              {{ caregiver.times.DRIVE_TIME_GOOGLE.value }}
              {{ caregiver.times.DRIVE_TIME_GOOGLE.suffix }}
            </div>

            <!-- % Fahrtzeit lt. Aufzeichnung -->
            <div
              (mouseleave)="setHover('')"
              (mouseenter)="setHover('DRIVING_PERCENTAGE')"
              [class.highlight]="currentHover === 'DRIVING_PERCENTAGE'"
            >
              {{ caregiver.times.DRIVING_PERCENTAGE.value }}
              {{ caregiver.times.DRIVING_PERCENTAGE.suffix }}
            </div>

            <!-- % Fahrtzeit lt. Google -->
            <div
              (mouseleave)="setHover('')"
              (mouseenter)="setHover('DRIVING_PERCENTAGE_GOOGLE')"
              [class.highlight]="currentHover === 'DRIVING_PERCENTAGE_GOOGLE'"
            >
              {{ caregiver.times.DRIVING_PERCENTAGE_GOOGLE.value }}
              {{ caregiver.times.DRIVING_PERCENTAGE_GOOGLE.suffix }}
            </div>

            <!-- Freie Kapazität -->
            <div
              (mouseleave)="setHover('')"
              (mouseenter)="setHover('FREE_CAPACITY')"
              [class.highlight]="currentHover === 'FREE_CAPACITY'"
            >
              {{ caregiver.times.FREE_CAPACITY.value }}
              {{ caregiver.times.FREE_CAPACITY.suffix }}
            </div>

            <!-- Auslastung -->
            <div
              (mouseleave)="setHover('')"
              (mouseenter)="setHover('UTILISATION')"
              [class.highlight]="currentHover === 'UTILISATION'"
            >
              {{ caregiver.times.UTILISATION.value }}
              {{ caregiver.times.UTILISATION.suffix }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
