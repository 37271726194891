import { MediaModel } from './media.model'

export class DocumentModel {
  name = ''
  documents: MediaModel[] = []
}

export class DocumentModelSimple {
  name = ''
  document: MediaModel = new MediaModel()
}
