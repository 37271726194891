import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core'
import * as dayjs from 'dayjs'
import * as customParseFormat from 'dayjs/plugin/customParseFormat'
import * as utc from 'dayjs/plugin/utc'
import { LatestChangeModel } from '../../../models/history/latest-change.model'
import { GlobalAnalysesService } from '../../../services/global-analyses.service'
import { HelperService } from '../../../services/helper.service'
import { AuthService } from '../../../services/auth.service'
import { NoticeDialogComponent } from '../../../components/dialogs/notice-dialog/notice-dialog.component'
import { AverageBudgetHoursPatientDialogComponent } from '../../../components/dialogs/average-budget-hours-patient-dialog/average-budget-hours-patient-dialog.component'
import { DialogService } from 'primeng/dynamicdialog'
import { Subject, Subscription } from 'rxjs'
import { switchMap } from 'rxjs/operators'

@Component({
  selector: 'app-analyses-budgets',
  templateUrl: './analyses-budgets.component.html',
  styleUrls: ['./analyses-budgets.component.scss'],
})
export class AnalysesBudgetsComponent implements OnInit, OnDestroy {
  @ViewChild('dt') table: ElementRef | any = null

  private loadTrigger$ = new Subject<void>()
  private subscription: Subscription = new Subscription()

  // TODO: model erstellen
  public data: any
  public keys: any
  public globalFilterValue = ''
  public onlyMinusBudgets = false

  public currentHover = -1
  public currentHoverForHeader = -1

  public loading = true
  public contentLoading = true

  public persplanData = new LatestChangeModel()

  public searchQuery = ''
  public year = ''
  public month = ''

  private selectedYear = 0
  private selectedMonth = 0

  public monthOptions: any = []
  public yearOptions = [
    { label: '2022', value: '2022' },
    { label: '2023', value: '2023' },
    { label: '2024', value: '2024' },
  ]

  constructor(
    public globalAnalysesService: GlobalAnalysesService,
    public authService: AuthService,
    private dialogService: DialogService,
    private helperService: HelperService
  ) {
    dayjs.locale('de')
    dayjs.extend(customParseFormat)
    dayjs.extend(utc)
  }

  ngOnInit(): void {
    const date = new Date()
    this.selectedYear = date.getFullYear()
    this.selectedMonth = date.getMonth()

    this.helperService.dependencies$.subscribe((data: any) => {
      this.monthOptions = data.months
      this.month = this.monthOptions[this.selectedMonth].label
      this.year = this.selectedYear.toString()
    })

    this.activateDataLoading()

    this.loadAnalyses()
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe()
  }

  public searchedForCaregiver(caregiverName: string): boolean {
    if (!this.searchQuery) {
      return true
    }

    return caregiverName.toLowerCase().includes(this.searchQuery.toLowerCase())
  }

  public setHover(type: number = -1): void {
    this.currentHover = type
  }

  public setHoverForHeader(index: number = -1): void {
    this.currentHoverForHeader = index
  }

  public openAverageBudgetHoursPatientDialog(index: any): void {
    this.dialogService.open(AverageBudgetHoursPatientDialogComponent, {
      header: 'Ø Dauer pro Patient',
      width: '620px',
      styleClass: 'dialog-container',
      data: {
        monthNumber: index + 1,
        year: this.year,
      },
    })
  }

  /**
   * Springt zum letzten Monat.
   */
  public goToPreviousMonth(): void {
    if (this.selectedMonth === 0) {
      const lastYear = this.selectedYear - 1

      const hasYearOption = this.yearOptions.find(
        (data: any) => data.value == lastYear
      )

      if (hasYearOption) {
        this.selectedYear = lastYear
        this.selectedMonth = 11
      }
    } else {
      this.selectedMonth--
    }

    this.year = this.selectedYear.toString()
    this.month = this.monthOptions[this.selectedMonth].label

    this.loadAnalyses(false)
  }

  public filterTableGlobal(event: any): void {
    this.table && this.table.filterGlobal(event.target.value.trim(), 'contains')
  }

  /**
   * Springt zum nächsten Monat.
   */
  public goToNextMonth(): void {
    if (this.selectedMonth === 11) {
      const nextYear = this.selectedYear + 1

      const hasYearOption = this.yearOptions.find(
        (data: any) => data.value == nextYear
      )

      if (hasYearOption) {
        this.selectedYear = nextYear
        this.selectedMonth = 0
      }
    } else {
      this.selectedMonth++
    }

    this.year = this.selectedYear.toString()
    this.month = this.monthOptions[this.selectedMonth].label

    this.loadAnalyses(false)
  }

  public loadAnalyses(withLoading: boolean = true): void {
    const foundMonth = this.monthOptions.findIndex((month: any) => {
      return month.label === this.month
    })

    if (foundMonth != null) {
      this.selectedMonth = foundMonth
    }

    this.selectedYear = +this.year

    this.contentLoading = true

    if (withLoading) {
      this.loading = true
    }

    this.loadTrigger$.next()
  }

  private activateDataLoading(): void {
    this.subscription.add(
      this.loadTrigger$
        .pipe(
          switchMap(() =>
            this.globalAnalysesService.budgets(
              this.month,
              this.year,
              this.onlyMinusBudgets
            )
          )
        )
        .subscribe((data: any) => {
          this.data = data.data

          this.contentLoading = false
          this.loading = false
        })
    )
  }
}
