<app-loader [loading]="loading"></app-loader>

<div
  class="content new-content-container"
  style="margin-bottom: 0"
  *ngIf="!loading"
>
  <header class="content-header" *ngIf="data.length > 0">
    <span>Rechnungsentwürfe</span>

    <div
      class="table-search-container"
      [class.searchfield-active]="globalFilterValue != ''"
    >
      <div class="p-input-icon-left">
        <i class="pi pi-search"></i>
        <input
          pInputText
          (input)="filterTableGlobal($event)"
          placeholder="Suche..."
          [(ngModel)]="globalFilterValue"
        />
      </div>
    </div>
  </header>

  <div class="content-body" *ngIf="data.length == 0">
    <span class="content-no-message">Keine Entwürfe vorhanden</span>
  </div>

  <div
    class="content-body content-body-full-height-with-subtabs without-padding"
    *ngIf="data.length > 0"
  >
    <p-table
      #dt
      [value]="data"
      [globalFilterFields]="[
        'first_name',
        'last_name',
      ]"
      scrollHeight="100%"
      [showCurrentPageReport]="true"
      currentPageReportTemplate="{totalRecords} Entwürfe"
      [scrollable]="true"
      [rowHover]="true"
      [paginator]="true"
      [rows]="50"
      [(first)]="firstPage"
      stateStorage="local"
      stateKey="invoices-km-draft-1"
    >
      <ng-template pTemplate="header">
        <tr class="table-headline-highlight">
          <th style="max-width: 160px" pSortableColumn="status_string">
            Status
            <p-sortIcon field="status_string"></p-sortIcon>
          </th>
          <th pSortableColumn="last_name">
            Patient <p-sortIcon field="last_name"></p-sortIcon>
          </th>
          <th pSortableColumn="amount_mileages">
            Kilometer
            <p-sortIcon field="amount_mileages"></p-sortIcon>
          </th>
          <th pSortableColumn="persplan_budgets_count">
            Anzahl fahrten
            <p-sortIcon field="persplan_budgets_count"></p-sortIcon>
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-item>
        <tr class="clickable">
          <td style="max-width: 160px" (click)="openInvoiceDialog(item)">
            <span class="p-tag p-tag-rounded p-tag-{{ item.status }}">{{
                item.status_string
              }}</span>
          </td>
          <td (click)="openInvoiceDialog(item)">
            <a routerLink="/patients/{{ item.id }}"
              >{{ item.last_name }}, {{ item.first_name }}</a
            >
          </td>
          <td (click)="openInvoiceDialog(item)">
            {{ item.amount_mileages }} KM
          </td>
          <td (click)="openInvoiceDialog(item)">
            {{ item.persplan_budgets_count }}
            {{ item.persplan_budgets_count === 1 ? "Fahrt" : "Fahrten" }}
          </td>
        </tr>
        <!--        <tr class="clickable">-->
        <!--          <td>-->
        <!--            <a routerLink="/patients/{{ item.patient_id }}"-->
        <!--              >{{ item.patient.first_name }} {{ item.patient.last_name }}</a-->
        <!--            >-->
        <!--          </td>-->
        <!--          <td (click)="openInvoiceDialog(item)">-->
        <!--            {{ item.case_number }}-->
        <!--          </td>-->
        <!--          <td-->
        <!--            tooltipStyleClass="tooltip-wider"-->
        <!--            [escape]="false"-->
        <!--            [pTooltip]="correctionReasons[item.correction_reason]"-->
        <!--            (click)="openInvoiceDialog(item)"-->
        <!--          >-->
        <!--            {{ item.correction_reason }}-->
        <!--          </td>-->
        <!--          <td (click)="openInvoiceDialog(item)">-->
        <!--            {{ item.performance_month }} {{ item.performance_year }}-->
        <!--          </td>-->
        <!--          <td (click)="openInvoiceDialog(item)">-->
        <!--            {{ item.invoice_number }}-->
        <!--          </td>-->
        <!--          <td (click)="openInvoiceDialog(item)">-->
        <!--            {{ item.invoice_date_string }}-->
        <!--          </td>-->
        <!--          <td (click)="openInvoiceDialog(item)">-->
        <!--            {{ item.total_value_formatted }}-->
        <!--          </td>-->
        <!--          <td>-->
        <!--            <a-->
        <!--              *ngIf="item.patient.first_sepa"-->
        <!--              target="_blank"-->
        <!--              [href]="-->
        <!--                patientService.getDocumentDownloadLink(-->
        <!--                  item.patient.first_sepa.uuid-->
        <!--                )-->
        <!--              "-->
        <!--              class="p-tag p-tag-rounded p-tag-success"-->
        <!--              >SEPA</a-->
        <!--            >-->
        <!--          </td>-->
        <!--          <td (click)="openInvoiceDialog(item)">-->
        <!--            {{ item.created_at | date: "dd.MM.y, H:mm" }} Uhr-->
        <!--          </td>-->
        <!--          <td class="table-action" style="width: 100px">-->
        <!--            <i-->
        <!--              (click)="openInvoiceDialog(item)"-->
        <!--              class="pi pi-pencil with-margin-right"-->
        <!--              pTooltip="Bearbeiten"-->
        <!--            ></i>-->
        <!--            <a-->
        <!--              *ngIf="item.media[0]"-->
        <!--              [href]="-->
        <!--                documentService.getDocumentDownloadLink(item.media[0].uuid)-->
        <!--              "-->
        <!--              target="_blank"-->
        <!--            >-->
        <!--              <i-->
        <!--                tooltipPosition="left"-->
        <!--                pTooltip="Herunterladen"-->
        <!--                class="pi pi-download"-->
        <!--              ></i>-->
        <!--            </a>-->
        <!--          </td>-->
        <!--        </tr>-->
      </ng-template>
    </p-table>
  </div>
</div>
