import { Component, OnInit } from '@angular/core'
import { TitleService } from 'src/app/services/title.service'
import { AuthService } from '../../services/auth.service'

@Component({
  selector: 'app-global-analyses',
  templateUrl: './global-analyses.component.html',
})
export class GlobalAnalysesComponent implements OnInit {
  constructor(
    private titleService: TitleService,
    public authService: AuthService
  ) {}

  ngOnInit(): void {
    this.titleService.setTitle(`Auswertungen`)
  }
}
