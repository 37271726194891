import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core'
import { Router } from '@angular/router'
import { PhoneCallService } from '../../../services/phone-call.service'
import { HelperService } from '../../../services/helper.service'
import { PlanningService } from '../../../services/planning.service'
import * as dayjs from 'dayjs'
import * as customParseFormat from 'dayjs/plugin/customParseFormat'
import * as utc from 'dayjs/plugin/utc'
import { DialogService } from 'primeng/dynamicdialog'
import {
  EventBusService,
  GlobalEvent,
} from '../../../services/eventbus.service'
import { Subscription } from 'rxjs'
import { GlobalAnalysesService } from '../../../services/global-analyses.service'
import { SelectItem } from 'primeng/api'

@Component({
  selector: 'app-analyses-phone-calls',
  templateUrl: './analyses-phone-calls.component.html',
})
export class AnalysesPhoneCallsComponent implements OnInit, OnDestroy {
  @ViewChild('op') overlayPanel: ElementRef | any = null
  @ViewChild('dt') table: ElementRef | any = null

  public globalFilterValue = ''

  public loading = true
  public contentLoading = true
  private eventBusSubscription: Subscription = new Subscription()

  public data: any[] = []

  public fields = {
    selected: 'LAST_7',
    dates: [],
  }

  public intervalDaysOptions: SelectItem[] = [
    {
      label: 'Heute',
      value: 'TODAY',
    },
    {
      label: 'Gestern',
      value: 'YESTERDAY',
    },
    {
      label: 'Letzte 7 Tage',
      value: 'LAST_7',
    },
    {
      label: 'Letzte 30 Tage',
      value: 'LAST_30',
    },
    {
      label: 'Individuell',
      value: 'INDIVIDUAL',
    },
  ]

  constructor(
    public phoneCallService: PhoneCallService,
    private helperService: HelperService,
    private planningService: PlanningService,
    private globalAnalysesService: GlobalAnalysesService,
    private eventbus: EventBusService,
    private dialogService: DialogService,
    private router: Router
  ) {
    dayjs.locale('de')
    dayjs.extend(customParseFormat)
    dayjs.extend(utc)
  }

  ngOnInit(): void {
    this.loadCalls()
    this.listenForEventbus()
  }

  ngOnDestroy(): void {
    this.eventBusSubscription.unsubscribe()
  }

  public loadCalls(withLoading: boolean = true): void {
    // Die Werte für den Datepicker werden zurückgesetzt, da es
    // ansonsten zu Fehlern kommt, wenn wir wieder das Datum anklicken wollen.
    if (this.fields.selected !== 'INDIVIDUAL') {
      this.fields.dates = []
    }

    this.contentLoading = true

    if (withLoading) {
      this.loading = true
    }

    this.globalAnalysesService
      .phoneCalls(this.fields)
      .subscribe((data: any) => {
        this.data = data

        this.contentLoading = false

        if (withLoading) {
          this.loading = false
        }
      })
  }

  public filterTableGlobal(event: any): void {
    this.table && this.table.filterGlobal(event.target.value.trim(), 'contains')
  }

  private listenForEventbus(): void {
    this.eventBusSubscription = this.eventbus.subject.subscribe(
      (event: GlobalEvent) => {
        switch (event) {
          case GlobalEvent.PlanningChanged:
            this.loadCalls(false)
        }
      }
    )
  }
}
