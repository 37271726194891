import { Component, OnInit, ViewChild } from '@angular/core'
import { ActivatedRoute, ParamMap, Router } from '@angular/router'
import { ToastService } from 'src/app/services/toast.service'
import { DirtyComponent } from 'src/app/guards/dirty-check.guard'
import { HttpErrorResponse } from '@angular/common/http'
import { StatusCodes } from 'http-status-codes'
import { NgForm } from '@angular/forms'
import { ConfirmationService } from 'primeng/api'
import { TitleService } from 'src/app/services/title.service'
import { MultiplierService } from '../../../services/multiplier.service'
import { MultiplierFormModel } from '../../../models/multiplier/multiplier-form.model'
import { SearchService } from '../../../services/search.service'
import { MultiplierDependenciesModel } from '../../../models/multiplier/multiplier-dependencies.model'
import { ZipcodeSearchResultModel } from '../../../models/search/zipcode-search-result.model'

@Component({
  selector: 'app-multipliers-form',
  templateUrl: './multipliers-form.component.html',
  styleUrls: ['./multipliers-form.component.scss'],
})
export class MultipliersFormComponent implements OnInit, DirtyComponent {
  @ViewChild('form', { static: true }) form!: NgForm

  public isDirty = false
  public isEditMode = false
  public multiplierId: string | null = null
  public submitted = false
  public loading = true
  public cityLoading = false

  public dependencies: MultiplierDependenciesModel = new MultiplierDependenciesModel()
  public data: MultiplierFormModel = new MultiplierFormModel()

  constructor(
    private multiplierService: MultiplierService,
    private router: Router,
    private route: ActivatedRoute,
    private searchService: SearchService,
    private toastService: ToastService,
    private confirmationService: ConfirmationService,
    private titleService: TitleService
  ) {}

  ngOnInit(): void {
    this.loadDependencies()

    this.titleService.setTitle(
      this.isEditMode ? 'Multiplikator bearbeiten' : 'Neuer Multiplikator'
    )

    // TODO: formsubscription und unsubscribe?
    this.form.valueChanges?.subscribe(() => {
      // sonst wird isDirty durch das initiale rendern der form gesetzt
      if (!this.form.pristine) {
        this.isDirty = true
      }
    })
  }

  /**
   * Lädt den Namen der Stadt / des Orts anhand der eingegebenen PLZ.
   */
  public loadCity(): void {
    this.cityLoading = true
    this.searchService.findCity(this.data.zipcode).subscribe(
      (result: ZipcodeSearchResultModel) => {
        if (result) {
          this.data.city = result.city
          this.data.state = result.state
          this.data.county = result.county
        }

        this.cityLoading = false
      },
      () => {
        this.data.city = ''
        this.cityLoading = false
      }
    )
  }

  canDeactivate(): boolean {
    return this.isDirty
  }

  public deactivate(event: Event): void {
    this.confirmationService.confirm({
      target: event.target ?? undefined,
      message: 'Multiplikator wirklich deaktivieren?',
      accept: () => {
        this.multiplierService.deactivate(this.multiplierId).subscribe(
          () => {
            this.toastService.success(
              'Multiplikator deaktiviert',
              'Der Multiplikator wurde erfolgreich deaktiviert'
            )

            this.router.navigateByUrl(`/multipliers/${this.multiplierId}`)
          },
          () => {
            this.toastService.error(
              'Deaktivieren fehlgeschlagen',
              'Der Multiplikator konnte nicht deaktiviert werden'
            )
          }
        )
      },
      reject: () => {},
    })
  }

  public activate(event: Event): void {
    this.confirmationService.confirm({
      target: event.target ?? undefined,
      message: 'Multiplikator wirklich aktivieren?',
      accept: () => {
        this.multiplierService.activate(this.multiplierId).subscribe(
          () => {
            this.toastService.success(
              'Multiplikator aktiviert',
              'Der Multiplikator wurde erfolgreich aktiviert'
            )

            this.router.navigateByUrl(`/multipliers/${this.multiplierId}`)
          },
          () => {
            this.toastService.error(
              'Aktivieren fehlgeschlagen',
              'Der Multiplikator konnte nicht aktiviert werden'
            )
          }
        )
      },
      reject: () => {},
    })
  }

  /**
   * Erstellt bzw. updated die Daten.
   */
  public save(): void {
    if (!this.form.form.valid) {
      this.submitted = false
      this.form.form.markAllAsTouched()
      this.toastService.error('Bitte füllen Sie alle Pflichtfelder aus')

      return
    }

    this.submitted = true

    const subscription = this.isEditMode
      ? this.multiplierService.update(this.data)
      : this.multiplierService.store(this.data)

    subscription.subscribe(
      (data: MultiplierFormModel) => {
        this.isDirty = false
        this.router.navigate(['/multipliers', data.id])
      },
      (error: HttpErrorResponse) => {
        if (error.status === StatusCodes.UNPROCESSABLE_ENTITY) {
          this.toastService.error('Bitte füllen Sie alle Pflichtfelder aus')
        } else {
          this.toastService.error(
            'Etwas ist schief gelaufen...',
            'Bitte wenden Sie sich an den Support'
          )
        }

        this.submitted = false
      }
    )
  }

  public loadDependencies(): void {
    this.multiplierService
      .dependencies()
      .subscribe((dependencies: MultiplierDependenciesModel) => {
        this.dependencies = dependencies

        this.loadDataForEditMode()
      })
  }

  /**
   * Lädt die Multiplikator Daten.
   */
  private loadMultiplier(): void {
    this.multiplierService
      .loadForEditMode(this.multiplierId)
      .subscribe((data: MultiplierFormModel) => {
        this.data = data
        this.loading = false
      })
  }

  /**
   * Lädt die Daten falls wir im bearbeiten Modus sind.
   */
  private loadDataForEditMode(): void {
    this.loading = true

    this.route.paramMap.subscribe((params: ParamMap) => {
      this.multiplierId = params.get('id')
      this.isEditMode = params.has('id')

      if (this.isEditMode) {
        this.loadMultiplier()
      } else {
        this.loading = false
      }
    })
  }
}
