export class DocumentUuidModel {
  dlv_anschreiben_uuid: string | null = null
  offer_uuid: string | null = null
  offer_anschreiben_uuid: string | null = null
  dlv_uuid_1: string | null = null
  dlv_uuid_2: string | null = null
  kundenaufnahmebogen_uuid: string | null = null
  kundeninformation_uuid: string | null = null
  entbindung_uuid_1: string | null = null
  entbindung_uuid_2: string | null = null
  abtretung_uuid_1: string | null = null
  abtretung_uuid_2: string | null = null
  sepa_uuid: string | null = null
}
