<app-loader [loading]="loading"></app-loader>

<div class="content new-content-container" *ngIf="!loading">
  <header class="content-header" *ngIf="recommendations.length > 0">
    <span> Empfehlungen </span>

    <div class="content-header-buttons">
      <div
        class="table-search-container"
        [class.searchfield-active]="globalFilterValue != ''"
      >
        <div class="p-input-icon-left">
          <i class="pi pi-search"></i>
          <input
            pInputText
            (input)="filterTableGlobal($event)"
            placeholder="Suche..."
            [(ngModel)]="globalFilterValue"
          />
        </div>
      </div>
    </div>
  </header>
  <div class="content-body auto-height" *ngIf="recommendations.length === 0">
    <span class="content-no-message">Keine Empfehlungen vorhanden</span>
  </div>
  <div
    class="content-body without-padding auto-height"
    *ngIf="recommendations.length > 0"
  >
    <p-table
      #dt
      [value]="recommendations"
      [rowHover]="true"
      [globalFilterFields]="[
        'last_name',
        'first_name',
        'full_name',
      ]"
    >
      <ng-template pTemplate="header">
        <tr class="table-headline-highlight">
          <th style="width: 160px" pSortableColumn="status_string">
            Status
            <p-sortIcon field="status_string"></p-sortIcon>
          </th>
          <th pSortableColumn="last_name">
            Patient <p-sortIcon field="last_name"></p-sortIcon>
          </th>
          <th pSortableColumn="city">
            Wohnort <p-sortIcon field="city"></p-sortIcon>
          </th>
          <th pSortableColumn="customer.created_at">
            Erstellt am
            <p-sortIcon field="customer.created_at"></p-sortIcon>
          </th>
          <th pSortableColumn="caregiver_paid_recommendation.paid_at">
            <div style="display: flex; align-items: center; gap: 5px">
              <i style="font-size: 12px" class="pi pi-euro"></i>
              Bezahlt
              <p-sortIcon
                field="caregiver_paid_recommendation.paid_at"
              ></p-sortIcon>
            </div>
          </th>
          <th></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-patient>
        <tr>
          <td>
            <!-- Wenn Quali noch offen ist, soll der Workflow Status angezeigt werden -->
            <span
              *ngIf="!patient.status && !patient.is_paused"
              class="p-tag p-tag-rounded p-tag-{{ patient.workflow_status }}"
              >{{ patient.workflow_status_string }}</span
            >

            <!-- Falls der Patient pausiert ist -->
            <span
              *ngIf="patient.is_paused"
              class="p-tag p-tag-rounded p-tag-PAUSED"
              >Pausiert</span
            >

            <!-- Ansonsten soll der richtige Status angezeigt werden -->
            <span
              *ngIf="patient.status && !patient.is_paused"
              class="p-tag p-tag-rounded p-tag-{{ patient.status }}"
              >{{ patient.status_string }}
            </span>
          </td>
          <td>
            <a class="color-main1" routerLink="/patients/{{ patient.id }}">
              {{ patient.last_name }}, {{ patient.first_name }}
            </a>
          </td>
          <td>
            <div class="text-center-container">
              <i
                style="font-size: 14px"
                class="pi pi-info-circle color-gray"
                *ngIf="patient.city"
                [pTooltip]="patient.full_address"
              ></i>
              {{ patient.city || "-" }}
            </div>
          </td>
          <td>
            {{ patient.customer.created_at | date: "dd.MM.y, H:mm" }}
          </td>
          <td>
            <span *ngIf="patient.caregiver_paid_recommendation">
              {{
                patient.caregiver_paid_recommendation.paid_at
                  | date: "dd.MM.y, H:mm"
              }}
            </span>
          </td>
          <td class="table-action" style="width: 100px">
            <i
              *ngIf="authService.isSuperAdmin()"
              tooltipPosition="left"
              [pTooltip]="
                patient.caregiver_paid_recommendation
                  ? 'Bezahlung zurückziehen'
                  : 'Als bezahlt markieren'
              "
              (click)="togglePaid(patient)"
              class="pi"
              [class.pi-euro]="!patient.caregiver_paid_recommendation"
              [class.pi-times]="patient.caregiver_paid_recommendation"
            ></i>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
