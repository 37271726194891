<app-loader [loading]="loading"></app-loader>

<div class="content" *ngIf="!loading">
  <header class="content-header">
    <div class="content-header-patients-tabs">
      <span [class.active]="selectedPatient === 'first_patient'">
        <i
          pTooltip="Terminversand gesperrt"
          class="pi pi-lock color-alert"
          *ngIf="patients.first_patient.appointment_lock"
        ></i>
        <i class="pi pi-user"></i>
        {{ patients.first_patient.full_name }}

        <i
          *ngIf="patients.first_patient.status"
          class="p-tag p-tag-rounded p-tag-{{ patients.first_patient.status }}"
          >{{ patients.first_patient.status_string }}</i
        >

        <i
          *ngIf="patients.first_patient.is_paused"
          class="p-tag p-tag-rounded p-tag-PAUSED"
          >Pausiert</i
        >
      </span>
      <a
        href="/patients/{{ patients.second_patient.id }}/planning"
        [class.active]="selectedPatient === 'second_patient'"
        *ngIf="patients.second_patient"
      >
        <i
          pTooltip="Terminversand gesperrt"
          class="pi pi-lock color-alert"
          *ngIf="patients.second_patient.appointment_lock"
        ></i>
        <i class="pi pi-users"></i>
        {{ patients.second_patient.full_name }}

        <i
          *ngIf="patients.second_patient.status"
          class="p-tag p-tag-rounded p-tag-{{ patients.second_patient.status }}"
          >{{ patients.second_patient.status_string }}</i
        >

        <i
          *ngIf="patients.second_patient.is_paused"
          class="p-tag p-tag-rounded p-tag-PAUSED"
          >Pausiert</i
        >
      </a>
    </div>
    <div class="content-header-action-icons">
      <i
        (click)="toggleFullscreen()"
        class="pi pi-arrows-v content-header-action-icon"
        pTooltip="Vollbild"
        tooltipPosition="left"
      ></i>
    </div>
  </header>
</div>

<div class="content" *ngIf="!loading">
  <header class="content-header" style="z-index: 50; position: relative">
    <span>
      <i (click)="goToPreviousMonth()" class="pi pi-chevron-left"></i>

      <p-dropdown
        inputId="month"
        name="month"
        scrollHeight="500px"
        (onChange)="loadPlanning(false)"
        [options]="monthOptions"
        [(ngModel)]="month"
        [style]="{ width: '150px', zIndex: '10' }"
      ></p-dropdown>

      <p-dropdown
        inputId="year"
        name="year"
        (onChange)="loadPlanning(false)"
        [options]="yearOptions"
        [(ngModel)]="year"
        [style]="{ margin: '0 0 0 -1px' }"
      ></p-dropdown>

      <i
        (click)="goToNextMonth()"
        class="pi pi-chevron-right"
        style="margin: 0 10px 0 10px"
      ></i>
    </span>
    <span class="header-small-infos">
      <span
        ><small>Stunden pro Monat</small>
        {{ patients.first_patient.hours_per_month }} Std</span
      >
      <span
        [pTooltip]="
          patients.first_patient.times.days_as_string || 'Keine Angabe'
        "
        ><small>Leistungstage</small>
        {{
          patients.first_patient.times.only_days_as_string || "Keine Angabe"
        }}</span
      >
      <span
        ><small>Intervall</small>
        {{
          patients.first_patient.appointment_interval || "Keine Angabe"
        }}</span
      >
    </span>
    <!--    <button-->
    <!--      pButton-->
    <!--      (click)="openHistorySidebar()"-->
    <!--      label="History ansehen"-->
    <!--      type="button"-->
    <!--      class="p-button-outlined"-->
    <!--      icon="pi pi-history"-->
    <!--      iconPos="left"-->
    <!--    ></button>-->
  </header>

  <div
    class="content-body without-padding"
    style="overflow: hidden"
    *ngIf="data.no_budget || !patients.first_patient.times.days_as_string"
  >
    <span class="content-no-message"
      >Budget Bestätigung oder Leistungstage fehlen</span
    >
  </div>

  <div
    class="content-body without-padding auto-height"
    [class.content-loading]="contentLoading"
    [class.is-past-content-body]="data.is_past"
    *ngIf="!data.no_budget && patients.first_patient.times.days_as_string"
  >
    <div class="planning-lists-dates">
      <div class="planning-list without-hover">
        <div class="name-block"></div>
        <div class="date-container">
          <div
            class="date-block"
            (mouseleave)="setHover('')"
            (mouseenter)="setHover(date.date_complete)"
            [class.highlight]="currentHoverForHeader === date.date_complete"
            [class.background-hightlight-orange]="date.holiday"
            [class.background-hightlight-gray]="date.sunday"
            [class.is-sunday]="date.sunday"
            [class.background-hightlight-blue]="
              date.date_complete === currentDay
            "
            [title]="date.holiday ? date.holiday : ''"
            *ngFor="let date of data.dates"
          >
            <strong>{{ date.day }}</strong>
            <span [class.hidden]="date.sunday">{{ date.date }}</span>
            <div
              *ngIf="!data.is_past"
              class="checked-time"
              [class.checked]="date.checked_time"
            >
              <div *ngIf="date.checked_time">
                <div>
                  {{ date.checked_time.start_first }}<br />
                  {{ date.checked_time.end_first }}
                </div>

                <div *ngIf="date.checked_time.has_second_block">
                  {{ date.checked_time.start_second }}<br />
                  {{ date.checked_time.end_second }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="planning-lists sticky-list">
      <div class="planning-list" *ngFor="let budget of data.patient_budgets">
        <div class="name-block" style="z-index: 10">
          <div>
            <span style="display: flex; justify-content: space-between">
              {{ budget.type_formatted }}
              <i
                *ngIf="budget.type === '39' || budget.type === '42'"
                class="pi pi-times"
                (click)="removeFromBudgetList(budget)"
                style="font-size: 13px; padding: 0"
                pTooltip="Budget entfernen"
              ></i>
            </span>
            <small
              *ngIf="
                budget.type !== 'SELBSTZAHLER' &&
                budget.type !== 'KOSTENTRAEGER'
              "
              >{{ budget.amount_hours_real_formatted }} h •
              {{ budget.amount_appointments }}
              {{ budget.amount_appointments === 1 ? "Einsatz" : "Einsätze" }} •
              {{ budget.budget_end_formatted }} Rest</small
            >
            <small *ngIf="budget.type === 'SELBSTZAHLER'"
              >{{ budget.amount_hours_real_formatted }} h •
              {{ budget.amount_appointments }}
              {{ budget.amount_appointments === 1 ? "Einsatz" : "Einsätze" }} •
              {{
                budget.selbstzahler_budget_type
                  ? budget.selbstzahler_budget_type === "Flexibel"
                    ? "Flexibel"
                    : budget.selbstzahler_budget_max + " Std Max"
                  : "Keine Angabe"
              }}</small
            >
            <small *ngIf="budget.type === 'KOSTENTRAEGER'"
              >{{ budget.amount_hours_real_formatted }} h •
              {{ budget.amount_appointments }}
              {{ budget.amount_appointments === 1 ? "Einsatz" : "Einsätze" }}
              <br />
              {{ budget.kostentraeger_budget || "??" }} Std / Woche, Gültig bis:
              {{
                budget.kostentraeger_valid_type === "Datum"
                  ? budget.kostentraeger_valid_until_date_string || "??"
                  : "Unbegrenzt"
              }}</small
            >
          </div>
        </div>
        <div
          class="date-block without-padding"
          (mouseleave)="setHover(''); setHoverForHeader('')"
          (mouseenter)="setHover(date.date); setHoverForHeader(date.date)"
          [class.highlight]="currentHover === date.date"
          [class.background-hightlight-orange]="date.holiday"
          [class.background-hightlight-gray]="date.sunday"
          [class.is-sunday]="date.sunday"
          [class.background-hightlight-blue]="copyMode"
          *ngFor="let date of budget.dates"
          (onDrop)="onDateDrop($event, date, budget)"
          pDroppable="dd"
        >
          <span
            class="block"
            [title]="date.holiday"
            *ngIf="date.holiday"
            (click)="appointmentClicked(date, null, budget)"
            >F</span
          >

          <span
            class="block"
            (click)="appointmentClicked(date, null, budget)"
            *ngIf="date.appointments.length === 0 && !date.holiday"
          ></span>

          <div
            class="block-multiple-container"
            *ngFor="let appointment of date.appointments"
          >
            <i
              *ngIf="appointment.doctor_appointment"
              pTooltip="Arzttermin"
              class="pi pi-heart-fill transparent doctor-appointment-container"
            ></i>

            <i
              pTooltip="Termin beibehalten"
              *ngIf="appointment.keep_appointment"
              class="pi pi-flag-fill transparent doctor-appointment-container"
            ></i>

            <i
              [pTooltip]="copyMode ? 'Kopie entfernen' : 'Kopieren'"
              (click)="copyAppointmentToggle(appointment)"
              class="date-copy-container"
              ><i
                class="pi"
                [class.pi-sync]="!copyMode"
                [class.pi-times]="copyMode"
              ></i
            ></i>

            <span
              (click)="appointmentClicked(date, appointment, budget)"
              pDraggable="dd"
              (onDragStart)="dragStart(appointment, 'appointment')"
              (onDragEnd)="dragEnd()"
              class="block block-appointment"
              [class.block-appointment-change-required]="
                appointment.change_required
              "
              [class.time-storno]="appointment.has_storno_options"
              [title]="
                appointment.caregiver.first_last_name +
                '\n' +
                (appointment.task || '')
              "
            >
              {{ appointment.real_from_h }}<br />{{
                appointment.real_to_h
              }}</span
            >
          </div>
        </div>
      </div>

      <div class="planning-list" style="width: 100%; background: transparent">
        <div class="name-block simple-name-block">
          <div>
            <small
              >Summe:
              {{ data.total_patient_budgets.total_amount_hours_real_formatted }}
              h und
              {{ data.total_patient_budgets.total_amount_appointments }}
              {{
                data.total_patient_budgets.total_amount_appointments === 1
                  ? "Einsatz"
                  : "Einsätze"
              }}</small
            >
          </div>
        </div>
      </div>
    </div>

    <div class="planning-lists" style="padding: 10px 0" *ngIf="!data.is_past">
      <div class="planning-list" style="width: 100%; background: transparent">
        <div class="name-block simple-name-block">
          <p-autoComplete
            field="full_name"
            inputId="caregivers"
            name="caregivers"
            appendTo="body"
            placeholder="Suche Betreuungskraft"
            [forceSelection]="true"
            [(ngModel)]="selectedCaregiver"
            [suggestions]="searchedCaregivers"
            (completeMethod)="searchCaregivers($event)"
            (onSelect)="searchedCaregiverSelected($event)"
          >
            <ng-template let-caregiver pTemplate="item">
              <div style="display: flex; flex-direction: column">
                <span>{{ caregiver.full_name }}</span>
                <small class="color-alert" *ngIf="caregiver.criterias">{{
                  caregiver.criterias
                }}</small>
              </div>
            </ng-template>
          </p-autoComplete>
        </div>
      </div>
    </div>

    <div class="planning-lists caregivers-planning-list">
      <div class="planning-list" *ngFor="let caregiver of data.caregivers">
        <div
          class="name-block"
          [class.background-hightlight-alert-no-opacity]="
            caregiver.highlight_caregiver
          "
        >
          <div style="width: 100%">
            <div style="display: flex; justify-content: space-between">
              <a
                pDraggable="dd"
                (onDragStart)="dragStart(caregiver, 'caregiver')"
                (onDragEnd)="dragEnd()"
                routerLink="/caregivers/{{ caregiver.caregiver_id }}/overview"
                target="_blank"
              >
                {{ caregiver.caregiver_name }}
              </a>

              <i
                (click)="removeSelectedCaregiver(caregiver)"
                *ngIf="caregiver.searched"
                class="pi pi-times"
              ></i>
            </div>
            <small *ngIf="!data.is_past"
              >{{
                caregiver.analyses_month.times.WORKING_TIME_SUM.months[0].value
              }}
              / {{ caregiver.analyses_month.times.SOLL.months[0].value }} h •
              {{ caregiver.analyses_month.times.ACCUMULATED.months[0].value }} h
              aufgelaufen
            </small>
          </div>
        </div>
        <div
          class="date-block without-padding"
          (mouseleave)="setHoverForHeader('')"
          (mouseenter)="setHoverForHeader(data.date_complete)"
          [class.highlight]="currentHover === data.date_complete"
          [class.background-hightlight-orange]="data.holiday"
          [class.background-hightlight-gray]="data.sunday"
          [class.is-sunday]="data.sunday"
          *ngFor="let data of caregiver.data"
        >
          <span
            class="block"
            pDraggable="dd"
            (onDragStart)="dragStart(caregiver, 'caregiver')"
            (click)="
              appointmentClicked({ date: data.date_complete }, caregiver, null)
            "
            (onDragEnd)="dragEnd()"
            *ngIf="
              !data.planned &&
              !data.vacation &&
              !data.ill &&
              !data.holiday &&
              !data.training &&
              !data.free_time_request &&
              !data.other &&
              !data.intern
            "
          ></span>

          <!-- TODO: klickbar machen mit den richtigen daten -->
          <!-- (click)="
          appointmentClicked({ date: data.date_complete }, data.planned[0], null)
          "
          (onDragStart)="dragStart(caregiver, 'caregiver')"
          (onDragEnd)="dragEnd()" -->
          <span
            class="block background-orange color-white"
            [class.background-main2]="data.planned_current_patient"
            [title]="data.planned_tooltip"
            pDraggable="dd"
            *ngIf="data.planned"
            >P</span
          >

          <span class="block" [title]="data.holiday_day" *ngIf="data.holiday"
            >F</span
          >

          <span
            class="block background-gray color-white not-clickable"
            [title]="data.vacation"
            *ngIf="data.vacation"
            >{{ data.sunday ? "" : "Urlaub" }}</span
          >

          <span
            class="block background-gray color-white not-clickable"
            [title]="data.training"
            *ngIf="data.training"
            >{{ data.sunday ? "" : "Fortbildung" }}</span
          >

          <span
            class="block background-gray color-white not-clickable"
            [title]="data.intern"
            *ngIf="data.intern"
            >{{ data.sunday ? "" : "Intern" }}</span
          >

          <span
            class="block background-gray color-white not-clickable"
            [title]="data.other"
            *ngIf="data.other"
            >{{ data.sunday ? "" : "Sonstiges" }}</span
          >

          <span
            class="block background-gray color-white not-clickable"
            [title]="data.free_time_request"
            *ngIf="data.free_time_request"
            >{{ data.sunday ? "" : "Wunschfrei" }}</span
          >

          <span
            class="block background-alert color-white not-clickable"
            [title]="data.ill"
            *ngIf="data.ill"
            >{{ data.sunday ? "" : "AU" }}</span
          >
        </div>
      </div>
    </div>
  </div>
</div>
