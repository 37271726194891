<app-loader [loading]="loading"></app-loader>

<div class="content" *ngIf="!loading">
  <header class="content-header">
    <span>
      <p-multiSelect
        [style]="{ width: '320px' }"
        selectedItemsLabel="{0} / {{
          caregiverOptions.length
        }} Betreuungskräfte ausgewählt"
        inputId="caregivers"
        [maxSelectedLabels]="1"
        placeholder="Betreuungskräfte auswählen"
        [options]="caregiverOptions"
        optionValue="id"
        optionLabel="last_first_name"
        name="caregivers"
        [filter]="true"
        scrollHeight="540px"
        [(ngModel)]="filters.caregivers"
        appendTo="body"
        (onChange)="loadCaregivers(false)"
      ></p-multiSelect>
    </span>

    <span>
      <span style="margin: 0 20px 0 0">
        <span>{{ filters.start }}</span>

        <p-calendar
          styleClass="datepicker-as-icon"
          [firstDayOfWeek]="1"
          [showWeek]="true"
          inputId="start"
          name="start"
          dateFormat="dd.mm.yy"
          [(ngModel)]="filters.start"
          dataType="string"
          [showIcon]="true"
          [showButtonBar]="true"
          (onSelect)="loadCaregivers(false)"
        ></p-calendar>

        <span style="margin: 0 20px 0 10px">-</span>

        <span>{{ filters.end }}</span>

        <p-calendar
          styleClass="datepicker-as-icon"
          [firstDayOfWeek]="1"
          [showWeek]="true"
          inputId="end"
          name="end"
          dateFormat="dd.mm.yy"
          [(ngModel)]="filters.end"
          dataType="string"
          [showIcon]="true"
          [showButtonBar]="true"
          (onSelect)="loadCaregivers(false)"
        ></p-calendar>
      </span>

      <i
        (click)="goToPreviousMonth()"
        style="margin: 0 10px 0 10px"
        class="pi pi-chevron-left"
      ></i>

      <p-dropdown
        inputId="month"
        name="month"
        scrollHeight="500px"
        (onChange)="monthOrYearChanged()"
        [options]="monthOptions"
        [(ngModel)]="filters.month"
        [style]="{ width: '150px', zIndex: '50' }"
      ></p-dropdown>

      <p-dropdown
        inputId="year"
        name="year"
        (onChange)="monthOrYearChanged()"
        [options]="yearOptions"
        [(ngModel)]="filters.year"
        [style]="{ margin: '0 0 0 -1px' }"
      ></p-dropdown>

      <i
        (click)="goToNextMonth()"
        class="pi pi-chevron-right"
        style="margin: 0 0 0 10px"
      ></i>
    </span>
  </header>

  <div class="content-body" *ngIf="filters.caregivers.length === 0">
    <span class="content-no-message"
      >Bitte wählen Sie Betreuungskräfte aus</span
    >
  </div>

  <div
    *ngIf="filters.caregivers.length > 0"
    class="content-body content-body-full-height without-padding"
    [class.content-loading]="contentLoading"
  >
    <div class="planning-lists-dates">
      <div class="planning-list without-hover">
        <div class="name-block">
          <div class="checks-container">
            <span pTooltip="Fahrzeit">FZ</span>
            <span pTooltip="Pause">PA</span>
            <span pTooltip="Plausibel">PL</span>
          </div>
        </div>
        <div class="date-container">
          <div
            class="date-block"
            (mouseleave)="setHover('')"
            (mouseenter)="setHover(date.date)"
            [class.highlight]="currentHoverForHeader === date.date"
            [class.background-hightlight-orange]="date.holiday"
            [class.background-hightlight-gray]="date.sunday"
            [title]="date.holiday ? date.holiday.name : ''"
            *ngFor="let date of data.dates"
          >
            <strong>{{ date.day }}</strong>
            <span>{{ date.date }}</span>
          </div>
        </div>
      </div>
    </div>

    <p-overlayPanel
      #op
      styleClass="with-border"
      [showCloseIcon]="true"
      [dismissable]="false"
      [style]="{
        maxWidth: '680px',
        width: 'max-content',
        maxHeight: '420px',
        overflowY: 'auto'
      }"
    >
      <ng-template pTemplate>
        <p-table [value]="currentCheckOverlayData.data">
          <ng-template pTemplate="header">
            <tr>
              <th>Tag</th>
              <th>Einsatz</th>
              <th>Nächster Einsatz</th>
              <th style="width: 100px">Fahrzeit</th>
              <th style="width: 50px" pTooltip="Kommentare">
                <i class="pi pi-comment"></i>
              </th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-item>
            <tr
              class="clickable"
              (click)="
                openCheckCommentDialog(
                  item,
                  item.next_row,
                  item.checks[currentCheckOverlayData.comment_type]
                )
              "
            >
              <td>{{ item.date }} ({{ item.day }})</td>
              <td>
                {{ item.data.real_from_h }} - {{ item.data.real_to_h }} ({{
                  item.data.real_hours_as_float
                }}) <br />
                {{ item.data.patient.first_name }}
                {{ item.data.patient.last_name }}
              </td>
              <td>
                {{ item.next_row.data.real_from_h }} -
                {{ item.next_row.data.real_to_h }} ({{
                  item.next_row.data.real_hours_as_float
                }}) <br />
                {{ item.next_row.data.patient.first_name }}
                {{ item.next_row.data.patient.last_name }}
              </td>
              <td>{{ item.google_drive_time_formatted }}</td>
              <td>
                {{ item.checks[currentCheckOverlayData.comment_type].length }}
              </td>
            </tr>
          </ng-template>
        </p-table>
      </ng-template>
    </p-overlayPanel>

    <div class="planning-lists sticky-list" *ngIf="markedCaregivers.length > 0">
      <div class="planning-list" *ngFor="let caregiver of markedCaregivers">
        <div class="name-block">
          <i
            (click)="toggleMarking(caregiver)"
            class="pi pi-star-fill color-main2"
            pTooltip="Entfernen"
          ></i>
          <a
            routerLink="/caregivers/{{ caregiver.caregiver_id }}/overview"
            target="_blank"
          >
            {{ caregiver.caregiver_name }}
          </a>

          <div class="checks-container" *ngIf="!checksLoaded">
            <p-skeleton size="16px" shape="circle"></p-skeleton>
            <p-skeleton size="16px" shape="circle"></p-skeleton>
            <p-skeleton size="16px" shape="circle"></p-skeleton>
          </div>

          <div
            class="checks-container"
            *ngIf="checksLoaded && overviewChecks[caregiver.caregiver_id]"
          >
            <!-- Fahrzeit -->
            <i
              *ngIf="!overviewChecks[caregiver.caregiver_id].drive_time"
              class="pi pi-times-circle color-alert"
              pTooltip="Fahrzeit nicht möglich"
              (click)="
                openCheckOverlay(
                  $event,
                  caregiver.caregiver_id,
                  overviewChecks[caregiver.caregiver_id].drive_time_data,
                  'drive_time_comments',
                  'DRIVE_TIME'
                )
              "
            ></i>
            <i
              *ngIf="overviewChecks[caregiver.caregiver_id].drive_time"
              class="pi pi-check-circle color-main1 not-clickable"
              pTooltip="Fahrzeit möglich"
            ></i>

            <!-- Pause -->
            <i
              *ngIf="!overviewChecks[caregiver.caregiver_id].pause_time"
              class="pi pi-times-circle color-alert"
              pTooltip="Keine Pause möglich"
              (click)="
                openCheckOverlay(
                  $event,
                  caregiver.caregiver_id,
                  overviewChecks[caregiver.caregiver_id].pause_time_data,
                  'pause_time_comments',
                  'PAUSE_TIME'
                )
              "
            ></i>
            <i
              *ngIf="overviewChecks[caregiver.caregiver_id].pause_time"
              class="pi pi-check-circle color-main1 not-clickable"
              pTooltip="Pause möglich"
            ></i>

            <!-- Plausibel -->
            <i
              *ngIf="!overviewChecks[caregiver.caregiver_id].no_idle"
              class="pi pi-times-circle color-alert"
              pTooltip="Leerlauf vorhanden"
              (click)="
                openCheckOverlay(
                  $event,
                  caregiver.caregiver_id,
                  overviewChecks[caregiver.caregiver_id].no_idle_data,
                  'no_idle_comments',
                  'NO_IDLE'
                )
              "
            ></i>
            <i
              *ngIf="overviewChecks[caregiver.caregiver_id].no_idle"
              class="pi pi-check-circle color-main1 not-clickable"
              pTooltip="Kein Leerlauf vorhanden"
            ></i>
          </div>
        </div>
        <div
          class="date-block"
          (mouseleave)="setHoverForHeader('')"
          (mouseenter)="setHoverForHeader(data.date)"
          [class.highlight]="currentHover === data.date"
          [class.background-hightlight-orange]="data.holiday"
          [class.background-hightlight-gray]="data.sunday"
          *ngFor="let data of caregiver.data"
          [class.without-padding]="
            !data.planned && !data.vacation && !data.ill && !data.holiday
          "
        >
          <span
            (click)="
              openDateDialog(
                data.date_full,
                caregiver.caregiver_id,
                caregiver.caregiver_name
              )
            "
            class="block add-block"
            title="Urlaub hinzufügen"
            *ngIf="
              !data.planned && !data.vacation && !data.ill && !data.holiday
            "
          ></span>

          <span class="block" title="Planung vorhanden" *ngIf="data.planned"
            >P</span
          >
          <span class="block" [title]="data.holiday_day" *ngIf="data.holiday"
            >F</span
          >
          <span
            class="block background-blue color-white"
            title="Urlaub"
            *ngIf="data.vacation"
            >U</span
          >
          <span
            class="block background-alert color-white"
            title="AU"
            *ngIf="data.ill"
            >A</span
          >
        </div>
      </div>
    </div>

    <div class="planning-lists">
      <div
        class="planning-list"
        [class.hidden]="caregiver.marked"
        *ngFor="let caregiver of data.caregivers"
      >
        <div class="name-block">
          <i
            (click)="toggleMarking(caregiver)"
            class="pi pi-star color-gray"
            pTooltip="Markieren"
          ></i>
          <a
            routerLink="/caregivers/{{ caregiver.caregiver_id }}/overview"
            target="_blank"
          >
            {{ caregiver.caregiver_name }}
          </a>

          <div class="checks-container" *ngIf="!checksLoaded">
            <p-skeleton size="16px" shape="circle"></p-skeleton>
            <p-skeleton size="16px" shape="circle"></p-skeleton>
            <p-skeleton size="16px" shape="circle"></p-skeleton>
          </div>

          <div
            class="checks-container"
            *ngIf="checksLoaded && overviewChecks[caregiver.caregiver_id]"
          >
            <!-- Fahrzeit -->
            <i
              *ngIf="!overviewChecks[caregiver.caregiver_id].drive_time"
              class="pi pi-times-circle color-alert"
              pTooltip="Fahrzeit nicht möglich"
              (click)="
                openCheckOverlay(
                  $event,
                  caregiver.caregiver_id,
                  overviewChecks[caregiver.caregiver_id].drive_time_data,
                  'drive_time_comments',
                  'DRIVE_TIME'
                )
              "
            ></i>
            <i
              *ngIf="overviewChecks[caregiver.caregiver_id].drive_time"
              class="pi pi-check-circle color-main1 not-clickable"
              pTooltip="Fahrzeit möglich"
            ></i>

            <!-- Pause -->
            <i
              *ngIf="!overviewChecks[caregiver.caregiver_id].pause_time"
              class="pi pi-times-circle color-alert"
              pTooltip="Keine Pause möglich"
              (click)="
                openCheckOverlay(
                  $event,
                  caregiver.caregiver_id,
                  overviewChecks[caregiver.caregiver_id].pause_time_data,
                  'pause_time_comments',
                  'PAUSE_TIME'
                )
              "
            ></i>
            <i
              *ngIf="overviewChecks[caregiver.caregiver_id].pause_time"
              class="pi pi-check-circle color-main1 not-clickable"
              pTooltip="Pause möglich"
            ></i>

            <!-- Plausibel -->
            <i
              *ngIf="!overviewChecks[caregiver.caregiver_id].no_idle"
              class="pi pi-times-circle color-alert"
              pTooltip="Leerlauf vorhanden"
              (click)="
                openCheckOverlay(
                  $event,
                  caregiver.caregiver_id,
                  overviewChecks[caregiver.caregiver_id].no_idle_data,
                  'no_idle_comments',
                  'NO_IDLE'
                )
              "
            ></i>
            <i
              *ngIf="overviewChecks[caregiver.caregiver_id].no_idle"
              class="pi pi-check-circle color-main1 not-clickable"
              pTooltip="Kein Leerlauf vorhanden"
            ></i>
          </div>
        </div>
        <div
          class="date-block"
          (mouseleave)="setHoverForHeader('')"
          (mouseenter)="setHoverForHeader(data.date)"
          [class.highlight]="currentHover === data.date"
          [class.background-hightlight-orange]="data.holiday"
          [class.background-hightlight-gray]="data.sunday"
          *ngFor="let data of caregiver.data"
          [class.without-padding]="
            !data.planned && !data.vacation && !data.ill && !data.holiday
          "
        >
          <span
            (click)="
              openDateDialog(
                data.date_full,
                caregiver.caregiver_id,
                caregiver.caregiver_name
              )
            "
            class="block add-block"
            title="Urlaub hinzufügen"
            *ngIf="
              !data.planned && !data.vacation && !data.ill && !data.holiday
            "
          ></span>

          <span class="block" title="Planung vorhanden" *ngIf="data.planned"
            >P</span
          >
          <span class="block" [title]="data.holiday_day" *ngIf="data.holiday"
            >F</span
          >
          <span
            class="block background-blue color-white"
            title="Urlaub"
            *ngIf="data.vacation"
            >U</span
          >
          <span
            class="block background-alert color-white"
            title="AU"
            *ngIf="data.ill"
            >A</span
          >
        </div>
      </div>
    </div>
  </div>

  <div class="content-legend">
    <span>P = Planung vorhanden</span>
    <span>U = Urlaub</span>
    <span>A = AU</span>
    <span>F = Feiertag</span>
    <span>Klick auf leeres Feld erstellt Urlaub</span>
  </div>
</div>
