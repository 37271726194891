<app-loader [loading]="loading"></app-loader>

<div class="content-actions">
  <h2>
    <i class="tio">visible_outlined</i>
    Hospitation
  </h2>

  <a [href]="documentService.getHospitationBlanko()" target="_blank">
    <button
      pButton
      label="Blanko Vertrag"
      type="button"
      class="p-button-info"
      icon="pi pi-download"
      iconPos="left"
    ></button>
  </a>
</div>

<div class="content new-content-container" *ngIf="!loading">
  <header class="content-header" *ngIf="data.length > 0">
    <span>
      Meine Hospitationen
    </span>
  </header>

  <ng-container>
    <div class="content-body without-padding auto-height"
         [class.content-loading]="contentLoading"
    >
      <span class="content-no-message with-padding" *ngIf="data.length === 0">
        Keine Hospitationen vorhanden
      </span>

      <p-table [sortOrder]="-1" sortField="status.hospitation_from" [style]="{minWidth: '1200px'}" responsiveLayout="scroll" [value]="data" [rowHover]="true" *ngIf="data.length > 0">
        <ng-template pTemplate="header">
          <tr class="table-headline-highlight">
            <th style="max-width: 50px"></th>
            <th style="width: 160px" pSortableColumn="current_status.status_text">
              Status
              <p-sortIcon field="current_status.status_text"></p-sortIcon>
            </th>
            <th pSortableColumn="first_name">
              Name <p-sortIcon field="first_name"></p-sortIcon>
            </th>
            <th pSortableColumn="city">
              Wohnort <p-sortIcon field="city"></p-sortIcon>
            </th>
            <th>
              E-Mail
            </th>
            <th>Telefon</th>
            <th pSortableColumn="status.hospitation_from">
              Hospitation am
              <p-sortIcon field="status.hospitation_from"></p-sortIcon>
            </th>
            <th></th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-caregiver>
          <tr class="clickable">
            <td (click)="openHospitationDialog(caregiver)">
              <div class="text-center-container" *ngIf="caregiver.current_status.caregiver_hospitation_feedback">
                <i pTooltip="Feedback erstellt" class="pi pi-star color-main1"></i>
              </div>
            </td>
            <td (click)="openHospitationDialog(caregiver)" >
              <span
                class="p-tag p-tag-rounded p-tag-{{ caregiver.current_status.status }}"
                >{{ caregiver.current_status.status_text }}
              </span>
            </td>
            <td (click)="openHospitationDialog(caregiver)" >{{ caregiver.full_name }}</td>
            <td (click)="openHospitationDialog(caregiver)" >
              {{ caregiver.city || "-" }}
            </td>
            <td>
              <div *ngIf="caregiver.current_status.status === 'HOSPITATION' || caregiver.current_status.status === 'HOSPITATION_STARTED'">
                <a *ngIf="caregiver.email" href="mailto:{{ caregiver.email }}">
                  {{ caregiver.email }}
                </a>
                <span *ngIf="!caregiver.email">-</span>
              </div>
            </td>
            <td>
              <div *ngIf="caregiver.current_status.status === 'HOSPITATION' || caregiver.current_status.status === 'HOSPITATION_STARTED'" style="display: flex; flex-direction: column">
                <span *ngIf="caregiver.phone_1">
                  <a
                    href="tel:{{ caregiver.phone_1 }}"
                  >{{ caregiver.phone_1 }}</a
                  >
                </span>
                  <span *ngIf="caregiver.phone_2">
                  <a
                    href="tel:{{ caregiver.phone_2 }}"
                  >{{ caregiver.phone_2 }}</a
                  >
                </span>
              </div>
            </td>
            <td (click)="openHospitationDialog(caregiver)" >
              <div *ngIf="caregiver.status.hospitation_to">
                {{ caregiver.status.hospitation_from_string }} - {{ caregiver.status.hospitation_to_string }}
              </div>
              <strong *ngIf="!caregiver.status.hospitation_to">
                OFFEN
              </strong>
            </td>
            <td (click)="openHospitationDialog(caregiver)"  class="table-action">
              <div *ngIf="caregiver.current_status.status === 'HOSPITATION' || caregiver.current_status.status === 'HOSPITATION_STARTED'" style="display: flex; align-items: center; justify-content: flex-end; gap: 5px">
                <i tooltipPosition="left" pTooltip="Dokumente vorhanden" *ngIf="caregiver.application_documents?.length > 0" class="pi pi-file"></i>
                <i
                  pTooltip="Ansehen"
                  tooltipPosition="left"
                  class="pi pi-eye"
                ></i>
              </div>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </ng-container>
</div>
