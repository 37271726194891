import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core'
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog'
import { EventBusService, GlobalEvent } from 'src/app/services/eventbus.service'
import { ToastService } from 'src/app/services/toast.service'
import { NgForm } from '@angular/forms'
import { Subscription } from 'rxjs'
import { CaregiverAnalysesService } from '../../../services/caregiver-analyses.service'

@Component({
  selector: 'app-change-caregiver-persplan-time-correction-dialog',
  templateUrl:
    './change-caregiver-persplan-time-correction-dialog.component.html',
})
export class ChangeCaregiverPersplanTimeCorrectionDialogComponent
  implements OnInit, OnDestroy {
  @ViewChild('form', { static: true }) form!: NgForm

  private formSubscription: Subscription | null | undefined = null
  private isDirty = false

  submitted = false
  submittedDelete = false

  public patientOptions: any[] = []
  public data: any

  constructor(
    private ref: DynamicDialogRef,
    private config: DynamicDialogConfig,
    private caregiverAnalysesService: CaregiverAnalysesService,
    private eventbus: EventBusService,
    private toastService: ToastService
  ) {}

  public ngOnInit(): void {
    this.data = this.config.data

    this.formSubscription = this.form.valueChanges?.subscribe(() => {
      if (!this.form.pristine) {
        this.isDirty = true
      }
    })
  }

  ngOnDestroy(): void {
    this.formSubscription?.unsubscribe()
  }

  public save(): void {
    if (!this.form.form.valid) {
      this.submitted = false
      this.form.form.markAllAsTouched()
      return
    }

    this.submitted = true

    this.caregiverAnalysesService
      .changePersplanTimeCorrection(this.data)
      .subscribe(
        () => {
          this.submitted = false
          this.eventbus.emit(GlobalEvent.CaregiverTimeChanged)
          this.ref.close()
          this.toastService.success(
            'Korrektur erstellt',
            'Die Korrektur wurden erfolgreich erstellt'
          )
        },
        () => {
          this.toastService.error(
            'Etwas ist schiefgelaufen...',
            'Bitte wenden Sie sich an den Support'
          )

          this.submitted = false
        }
      )
  }
}
