<form #form="ngForm" class="dialog-form">
  <div class="dialog-form-body">
    <div class="p-field">
      <label for="finished_comment">Kommentar *</label>
      <textarea
        id="finished_comment"
        name="finished_comment"
        [rows]="4"
        [(ngModel)]="config.data.finished_comment"
        pInputTextarea
        [required]="true"
      ></textarea>
    </div>
  </div>
  <div class="dialog-form-footer">
    <i></i>
    <button
      pButton
      label="Erledigen"
      type="submit"
      (click)="save()"
      [disabled]="submitted"
      [icon]="submitted ? 'pi pi-spin pi-spinner' : 'pi pi-check'"
    ></button>
  </div>
</form>
