<form #form="ngForm" class="dialog-form">
  <div class="dialog-loader" *ngIf="loading">
    <i class="loading-logo"></i>
    WIRD GELADEN
  </div>

  <div class="dialog-form-body" *ngIf="!loading && data.patients.length === 0">
    <span class="content-no-message">Keine Planungen vorhanden</span>
  </div>

  <div
    class="content-body dialog-max-height without-padding"
    *ngIf="!loading && data.patients.length > 0"
  >
    <div
      style="
        padding: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
      "
    >
      <strong>{{ data.month }} {{ data.year }}</strong>

      <div class="table-search-container">
        <div class="p-input-icon-left">
          <i class="pi pi-search"></i>
          <input
            pInputText
            (input)="filterTableGlobal($event)"
            placeholder="Suche..."
            [value]="globalFilterValue"
          />
        </div>
      </div>
    </div>

    <p-table
      #dt
      [value]="data.patients"
      [rowHover]="true"
      [autoLayout]="true"
      [globalFilterFields]="[
        'name',
      ]"
    >
      <ng-template pTemplate="header">
        <tr>
          <th style="min-width: 150px" pSortableColumn="name">
            Patient <p-sortIcon field="name"></p-sortIcon>
          </th>
          <th pSortableColumn="average_hours">
            Ø Dauer <p-sortIcon field="average_hours"></p-sortIcon>
          </th>
          <th pSortableColumn="assignments">
            Einsätze <p-sortIcon field="assignments"></p-sortIcon>
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-patient>
        <tr>
          <td>{{ patient.name }}</td>
          <td>{{ patient.average_hours_formatted }}</td>
          <td>{{ patient.assignments }}</td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</form>
