export class CaregiverHospitationFeedbackModel {
  id?: number | null = null
  caregiver_status_id: number | null = null
  hospitation_canceled: boolean = false
  hospitation_canceled_reason: string | null = null
  family_status: string | null = null
  participated_in_assignments: number | null = null
  hobbies: string | null = null
  commitments: string | null = null
  impairments: string | null = null
  on_time: number = -100
  on_time_comment: string | null = null
  appearance: number = -100
  appearance_comment: string | null = null
  communication_with_customer: number = -100
  communication_with_customer_comment: string | null = null
  communication_with_me: number = -100
  communication_with_me_comment: string | null = null
  expertise: number = -100
  expertise_comment: string | null = null
  perceptiveness: number = -100
  perceptiveness_comment: string | null = null
  working_method: number = -100
  working_method_comment: string | null = null
  working_speed: number = -100
  working_speed_comment: string | null = null
  car_ride: number = -100
  car_ride_comment: string | null = null
  overall_impression: number = -100
  overall_impression_comment: string | null = null
  recommendation: string | null = null
  feedback: string | null = null
  created_at: Date | null = null
  updated_at: Date | null = null
}
