import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core'
import {
  DialogService,
  DynamicDialogConfig,
  DynamicDialogRef,
} from 'primeng/dynamicdialog'
import { EventBusService, GlobalEvent } from 'src/app/services/eventbus.service'
import { ToastService } from 'src/app/services/toast.service'
import { NgForm } from '@angular/forms'
import { ProofsService } from '../../../services/proofs.service'
import { HttpErrorResponse } from '@angular/common/http'
import { StatusCodes } from 'http-status-codes'
import { CaregiverPlanningDialogComponent } from '../caregiver-planning-dialog/caregiver-planning-dialog.component'
import { ChangePatientBudgetProofDialogComponent } from '../change-patient-budget-proof-dialog/change-patient-budget-proof-dialog.component'
import { SelectPatientForProofDialogComponent } from '../select-patient-for-proof-dialog/select-patient-for-proof-dialog.component'

@Component({
  selector: 'app-upload-proofs-dialog',
  templateUrl: './upload-proofs-dialog.component.html',
})
export class UploadProofsDialogComponent implements OnInit, OnDestroy {
  @ViewChild('form', { static: true }) form!: NgForm
  @ViewChild('dt') table: ElementRef | any = null

  submitted = false
  generated = false
  public globalFilterValue = ''

  public onlyBlanko = false
  public loading = false
  public loaded = false
  public data: any = {}

  public proofUploaded: any = {}

  private processInterval: any = null

  public processedPages = 0
  public processedPagesInPercent = 0
  public isParsed = false
  public isParsing = false
  public isUploading = false
  public isProcessing = false

  public editIndex: any = null

  constructor(
    private ref: DynamicDialogRef,
    public proofService: ProofsService,
    private dialogService: DialogService,
    public config: DynamicDialogConfig,
    private eventbus: EventBusService,
    private toastService: ToastService
  ) {}

  public ngOnInit(): void {}

  public ngOnDestroy(): void {
    this.clearProcessInterval()
  }

  public filterTableGlobal(event: any): void {
    this.table && this.table.filterGlobal(event.target.value.trim(), 'contains')
  }

  public setSelectedFile(event: any): any {
    this.isParsed = false
    this.isUploading = true
    this.isProcessing = false
    this.isParsing = true

    this.processedPages = 0
    this.processedPagesInPercent = 0

    const formData = new FormData()
    formData.append('file', event.target.files[0])

    this.proofService.upload(formData).subscribe(
      (response: any) => {
        this.proofUploaded = response

        this.isProcessing = true
        this.isUploading = false

        this.processInterval = setInterval(() => {
          this.loadProcessData(response.id)
        }, 2000)

        this.proofService.processUpload(response).subscribe(
          (data: any) => {
            this.data = data

            this.isParsing = false
            this.isParsed = true
            this.isProcessing = false

            this.clearProcessInterval()
          },
          (error: HttpErrorResponse) => {
            this.toastService.error(
              'Etwas ist schief gelaufen...',
              'Bitte wenden Sie sich an den Support'
            )

            this.clearProcessInterval()

            this.isProcessing = false
            this.isUploading = false
            this.isParsing = false
            this.submitted = false
          }
        )
      },
      (error: HttpErrorResponse) => {
        if (error.status === StatusCodes.UNPROCESSABLE_ENTITY) {
          this.toastService.error('Das Dokument muss eine PDF sein')
        } else if (error.status === StatusCodes.CONFLICT) {
          this.toastService.error('Die Datei wurde bereits hochgeladen')
        } else {
          this.toastService.error(
            'Etwas ist schief gelaufen...',
            'Bitte wenden Sie sich an den Support'
          )
        }

        this.clearProcessInterval()

        this.isProcessing = false
        this.isUploading = false
        this.isParsing = false
        this.submitted = false
      }
    )
  }

  public openSelectPatientForProofDialog(item: any): void {
    const ref = this.dialogService.open(SelectPatientForProofDialogComponent, {
      header: 'Scan bearbeiten',
      width: '360px',
      styleClass: 'dialog-container',
      data: {
        item,
        cover_key: this.data.cover_key,
        year: this.config.data.year,
        month: this.config.data.month,
      },
    })

    ref.onClose.subscribe((response: any) => {
      if (response) {
        item.edited_at = response.edited_at
        item.month = response.month
        item.year = response.year
        item.budget_type = response.budget_type
        item.patient_name = response.patient_name

        this.eventbus.emit(GlobalEvent.ProofsChanged)
      }
    })
  }

  public openChangePatientBudgetProofDialog(item: any): void {
    const ref = this.dialogService.open(
      ChangePatientBudgetProofDialogComponent,
      {
        header: 'Budget bearbeiten',
        width: '320px',
        styleClass: 'dialog-container',
        data: {
          item,
        },
      }
    )

    ref.onClose.subscribe((response: any) => {
      if (response) {
        item.budget = response.budget_type
      }
    })
  }

  private loadProcessData(proofUploadedId: number): void {
    this.proofService
      .loadProofUploaded(proofUploadedId)
      .subscribe((response: any) => {
        this.processedPages = response.processed_pages

        if (this.processedPages) {
          this.processedPagesInPercent =
            (100 * response.processed_pages) / response.amount_pages
        }
      })
  }

  private clearProcessInterval(): void {
    clearInterval(this.processInterval)
    this.processInterval = null
  }

  public save(): void {
    if (!this.form.form.valid) {
      this.submitted = false
      this.form.form.markAllAsTouched()
      return
    }

    this.submitted = true

    this.proofService.saveUpload(this.data).subscribe(
      (response: any) => {
        this.submitted = false
        this.toastService.success('Leistungsnachweise wurden hochgeladen')

        this.eventbus.emit(GlobalEvent.ProofsChanged)
        this.ref.close()
      },
      (error: HttpErrorResponse) => {
        if (error.status === StatusCodes.NOT_FOUND) {
          this.toastService.error('Das Kennzeichen existiert nicht')
        } else {
          this.toastService.error(
            'Etwas ist schief gelaufen...',
            'Bitte wenden Sie sich an den Support'
          )
        }

        this.isParsing = false
        this.submitted = false
      }
    )
  }
}
