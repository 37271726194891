import { HttpErrorResponse } from '@angular/common/http'
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core'
import { NgForm } from '@angular/forms'
import { StatusCodes } from 'http-status-codes'
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog'
import { Subscription } from 'rxjs'
import { EventBusService, GlobalEvent } from 'src/app/services/eventbus.service'
import { ToastService } from 'src/app/services/toast.service'
import { ContactPersonModel } from '../../../models/contact-person/contact-person.model'
import { SearchService } from '../../../services/search.service'
import { ContactPersonService } from '../../../services/contact-person.service'
import { ConfirmationService } from 'primeng/api'
import { ZipcodeSearchResultModel } from '../../../models/search/zipcode-search-result.model'
import { HelperService } from '../../../services/helper.service'

@Component({
  selector: 'app-contact-person-dialog',
  templateUrl: './contact-person-dialog.component.html',
})
export class ContactPersonDialogComponent implements OnInit, OnDestroy {
  @ViewChild('form', { static: true }) form!: NgForm

  public formOfAddress: any = []
  private formSubscription: Subscription | null | undefined = null
  private isDirty = false

  public contactPerson: ContactPersonModel = new ContactPersonModel()

  public data: any = {}
  public submitted = false
  public submittedDelete = false
  public cityLoading = false

  constructor(
    private ref: DynamicDialogRef,
    private searchService: SearchService,
    private helperService: HelperService,
    private config: DynamicDialogConfig,
    private contactPersonService: ContactPersonService,
    private eventbus: EventBusService,
    private toastService: ToastService,
    private confirmationService: ConfirmationService
  ) {}

  ngOnInit(): void {
    this.data = this.config.data

    if (this.data.edit) {
      this.contactPerson = this.data.contactPerson
    }

    this.formSubscription = this.form.valueChanges?.subscribe(() => {
      if (!this.form.pristine) {
        this.isDirty = true
      }
    })

    this.helperService.dependencies$.subscribe((data: any) => {
      this.formOfAddress = data.form_of_address
    })
  }

  ngOnDestroy(): void {
    this.formSubscription?.unsubscribe()
  }

  /**
   * Speichert den Ansprechpartner.
   */
  public save(): void {
    if (!this.form.form.valid) {
      this.submitted = false
      this.form.form.markAllAsTouched()
      return
    }

    this.submitted = true

    const subscription = this.data.edit
      ? this.contactPersonService.update(this.contactPerson)
      : this.contactPersonService.store({
          ...this.contactPerson,
          contact_personable_id: this.data.type_id,
          contact_personable_type: this.data.type,
        })

    subscription.subscribe(
      () => {
        this.submitted = false
        this.eventbus.emit(GlobalEvent.ContactPersonChanged)
        this.ref.close()
        this.toastService.success(
          'Ansprechpartner gespeichert',
          'Der Absprechpartner wurde erfolgreich gespeichert'
        )
      },
      (error: HttpErrorResponse) => {
        if (error.status === StatusCodes.UNPROCESSABLE_ENTITY) {
          this.toastService.error('Bitte füllen Sie alle Pflichtfelder aus')
        } else {
          this.toastService.error(
            'Etwas ist schief gelaufen...',
            'Bitte wenden Sie sich an den Support'
          )
        }
        this.submitted = false
      }
    )
  }

  /**
   * Lädt den Namen der Stadt / des Orts anhand der eingegebenen PLZ.
   */
  public loadCity(): void {
    this.cityLoading = true
    this.searchService.findCity(this.contactPerson.zipcode).subscribe(
      (result: ZipcodeSearchResultModel) => {
        if (result) {
          this.contactPerson.city = result.city
          this.contactPerson.state = result.state
          this.contactPerson.county = result.county
        }

        this.cityLoading = false
      },
      () => {
        this.contactPerson.city = ''
        this.cityLoading = false
      }
    )
  }

  public remove(event: any): void {
    this.confirmationService.confirm({
      target: event.target ?? undefined,
      message: 'AP wirklich löschen?',
      accept: () => {
        this.submittedDelete = true

        this.contactPersonService.delete(this.data.contactPerson.id).subscribe(
          () => {
            this.submittedDelete = false
            this.eventbus.emit(GlobalEvent.ContactPersonChanged)
            this.ref.close()
            this.toastService.success(
              'Ansprechpartner deaktiviert',
              'Der Ansprechpartner wurde erfolgreich deaktiviert'
            )
          },
          () => {
            this.submittedDelete = false
            this.toastService.error(
              'Deaktivierung fehlgeschlagen',
              'Der Ansprechpartner konnte nicht deaktiviert werden'
            )
          }
        )
      },
      reject: () => {},
    })
  }
}
