import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { environment } from '../../environments/environment'
import { Observable } from 'rxjs'
import { PhoneCallOpenModel } from '../models/phone-call/phone-call-event.model'
import { PhoneToastMessageModel } from '../models/phone-call/phone-toast-message.model'
import {
  PhoneCallsListItemModel,
  PhoneCallsListModel,
} from '../models/phone-call/phone-calls-list.model'
import { PhoneCallModel } from '../models/phone-call/phone-call.model'

@Injectable({
  providedIn: 'root',
})
export class PhoneCallService {
  constructor(private http: HttpClient) {}

  public delete(id: number | null): Observable<[]> {
    return this.http.delete<[]>(`${environment.api}/phone-call/${id}`)
  }

  /**
   * Erstellt einen ausgehenden Anruf aus der Software und speichert diesen in der Datenbank ab.
   * Der Timeout ist dafür, da er der Browser sonst den Request abbricht, weil er das Programm für
   * das Telefonat startet. Durch diesen Trick macht er dennoch den Ajax Call.
   */
  public createCalling(number: string, values: any = {}): void {
    setTimeout(() => {
      this.http
        .post<[]>(`${environment.api}/phone-call/create-calling`, {
          number,
          values,
        })
        .subscribe()
    }, 1000)
  }

  public save(data: PhoneToastMessageModel): Observable<[]> {
    return this.http.put<[]>(`${environment.api}/phone-call/${data.id}/save`, {
      ...data,
    })
  }

  public finishFromActive(data: PhoneToastMessageModel): Observable<[]> {
    return this.http.put<[]>(
      `${environment.api}/phone-call/${data.id}/finish-from-active`,
      {
        ...data,
      }
    )
  }

  public update(data: PhoneCallModel, todo: any): Observable<PhoneCallModel> {
    return this.http.put<PhoneCallModel>(
      `${environment.api}/phone-call/${data.id}/update`,
      {
        ...data,
        todo,
      }
    )
  }

  public open(): Observable<PhoneCallOpenModel> {
    return this.http.get<PhoneCallOpenModel>(
      `${environment.api}/phone-call/open`
    )
  }

  public list(): Observable<PhoneCallsListModel> {
    return this.http.get<PhoneCallsListModel>(
      `${environment.api}/phone-call/list`
    )
  }

  public consultings(): Observable<PhoneCallsListItemModel[]> {
    return this.http.get<PhoneCallsListItemModel[]>(
      `${environment.api}/phone-call/consultings`
    )
  }

  public getPersplanMonthForSelectPhone(
    customerId: number | null,
    type: string
  ): Observable<[]> {
    return this.http.get<[]>(
      `${environment.api}/patients/${customerId}/budget-month-select/phone`,
      {
        params: {
          type,
        },
      }
    )
  }
}
