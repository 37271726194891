import { Component, OnInit, ViewChild } from '@angular/core'
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog'
import { EventBusService } from 'src/app/services/eventbus.service'
import { ToastService } from 'src/app/services/toast.service'
import { NgForm } from '@angular/forms'
import { ProofsService } from '../../../services/proofs.service'

@Component({
  selector: 'app-generate-proof-cover-dialog',
  templateUrl: './generate-proof-cover-dialog.component.html',
})
export class GenerateProofCoverDialogComponent implements OnInit {
  @ViewChild('form', { static: true }) form!: NgForm

  submitted = false
  generated = false
  documentLink = ''

  constructor(
    private ref: DynamicDialogRef,
    public proofService: ProofsService,
    public config: DynamicDialogConfig,
    private eventbus: EventBusService,
    private toastService: ToastService
  ) {}

  public ngOnInit(): void {}

  public save(): void {
    this.submitted = true

    this.proofService
      .createCovers(this.config.data.month, this.config.data.year)
      .subscribe(
        (response: any) => {
          this.documentLink = this.proofService.getCoverDownloadLink(response)

          this.generated = true

          this.submitted = false
          this.toastService.success('Deckblätter wurden erstellt')
        },
        () => {
          this.toastService.error(
            'Etwas ist schiefgelaufen...',
            'Bitte wenden Sie sich an den Support'
          )

          this.submitted = false
        }
      )
  }
}
