<form #form="ngForm" class="dialog-form">
  <div class="dialog-form-body">
    <div class="p-field">
      Zeitraum:
      <strong>{{ config.data.month }} {{ config.data.year }}</strong>
    </div>
    <div class="p-field">
      <label for="comment">Kommentar</label>
      <textarea
        id="comment"
        name="comment"
        placeholder="Optional"
        [rows]="3"
        [(ngModel)]="comment"
        pInputTextarea
      ></textarea>
    </div>
  </div>
  <div class="dialog-form-footer">
    <i></i>
    <button
      pButton
      label="Freigeben"
      type="submit"
      (click)="save()"
      [disabled]="submitted"
      [icon]="submitted ? 'pi pi-spin pi-spinner' : 'pi pi-check'"
    ></button>
  </div>
</form>
