<div class="content-actions">
  <h2>
    <i class="tio">explore_outlined</i>
    Meine Daten
  </h2>
</div>

<div class="content">
  <header class="content-header">
    <div class="content-header-menu-tabs">
      <a
        routerLink="/daten"
        routerLinkActive="active"
        [routerLinkActiveOptions]="{ exact: true }"
        >Stammdaten</a
      >

      <a
        routerLink="/daten/vertragsdaten"
        routerLinkActive="active"
        [routerLinkActiveOptions]="{ exact: true }"
        >Vertragsdaten</a
      >

      <a
        routerLink="/daten/qualifikationen"
        routerLinkActive="active"
        [routerLinkActiveOptions]="{ exact: true }"
        >Qualifikationen</a
      >
    </div>
  </header>
</div>

<router-outlet></router-outlet>
