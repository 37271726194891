import { Component, OnInit, ViewChild } from '@angular/core'
import { ActivatedRoute, ParamMap, Router } from '@angular/router'
import { ToastService } from 'src/app/services/toast.service'
import { DirtyComponent } from 'src/app/guards/dirty-check.guard'
import { HttpErrorResponse } from '@angular/common/http'
import { StatusCodes } from 'http-status-codes'
import { NgForm } from '@angular/forms'
import { ConfirmationService } from 'primeng/api'
import { TitleService } from 'src/app/services/title.service'
import { UserSystemService } from '../../services/user-system.service'
import { RolePermissionService } from '../../services/role-permission.service'
import { HelperService } from '../../services/helper.service'
import { AuthService } from '../../services/auth.service'
import { AuthSettingModel } from '../../models/user/auth.model'

@Component({
  selector: 'app-user-settings',
  templateUrl: './user-settings.component.html',
  styleUrls: ['./user-settings.component.scss'],
})
export class UserSettingsComponent implements OnInit, DirtyComponent {
  @ViewChild('form', { static: true }) form!: NgForm
  @ViewChild('formPassword', { static: true }) formPassword!: NgForm

  public isDirty = false
  public submitted = false
  public loading = false
  public formOfAddress: any = []
  public errorMessage = ''

  public data: AuthSettingModel = new AuthSettingModel()

  constructor(
    private service: UserSystemService,
    private router: Router,
    private rolePermissionService: RolePermissionService,
    private route: ActivatedRoute,
    private helperService: HelperService,
    public authService: AuthService,
    private toastService: ToastService,
    private confirmationService: ConfirmationService,
    private titleService: TitleService
  ) {}

  ngOnInit(): void {
    this.setData()

    this.titleService.setTitle('Einstellungen')

    this.form.valueChanges?.subscribe(() => {
      // sonst wird isDirty durch das initiale rendern der form gesetzt
      if (!this.form.pristine) {
        this.isDirty = true
      }
    })

    this.helperService.dependencies$.subscribe((data: any) => {
      this.formOfAddress = data.form_of_address
    })
  }

  canDeactivate(): boolean {
    return this.isDirty
  }

  public save(): void {
    if (!this.form.form.valid) {
      this.submitted = false
      this.form.form.markAllAsTouched()
      this.toastService.error('Bitte füllen Sie alle Pflichtfelder aus')

      return
    }

    this.submitted = true

    this.service.updateOwnData(this.data.user_system).subscribe(
      () => {
        this.isDirty = false
        this.submitted = false

        this.toastService.success('Daten erfolgreich gespeichert')
      },
      (error: HttpErrorResponse) => {
        if (error.status === StatusCodes.UNPROCESSABLE_ENTITY) {
          this.toastService.error('Bitte füllen Sie alle Pflichtfelder aus')
        } else {
          this.toastService.error(
            'Etwas ist schief gelaufen...',
            'Bitte wenden Sie sich an den Support'
          )
        }

        this.submitted = false
      }
    )
  }

  public savePassword(): void {
    if (!this.formPassword.form.valid) {
      this.submitted = false
      this.formPassword.form.markAllAsTouched()
      this.toastService.error('Bitte füllen Sie alle Pflichtfelder aus')

      return
    }

    if (this.data.password !== this.data.password_confirmation) {
      this.submitted = false
      this.formPassword.form.markAllAsTouched()
      this.errorMessage =
        'Das neue Passwort stimmt nicht mit der bestätigung überein'

      return
    }

    this.errorMessage = ''
    this.submitted = true

    this.service.updateOwnPassword(this.data).subscribe(
      () => {
        this.isDirty = false
        this.submitted = false

        this.toastService.success('Passwort erfolgreich gespeichert')

        location.reload()
      },
      (error: HttpErrorResponse) => {
        if (error.status === StatusCodes.UNPROCESSABLE_ENTITY) {
          this.errorMessage =
            'Bitte prüfen Sie ob ihr aktuelles Passwort korrekt ist und ob das neue Passwort die gewünschten Regeln erfüllt'
        } else {
          this.toastService.error(
            'Etwas ist schief gelaufen...',
            'Bitte wenden Sie sich an den Support'
          )
        }

        this.submitted = false
      }
    )
  }

  private setData(): void {
    const user = this.authService.getUser()

    if (user) {
      this.data.caregiver = user.caregiver
      this.data.user_system = user.user_system
      this.data.name = user.name
      this.data.email = user.email
      this.data.is_user_system = user.is_user_system
      this.data.is_caregiver = user.is_caregiver
      this.data.is_multiplier = user.is_multiplier
    }

    this.loading = false
  }
}
