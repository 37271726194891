import { HttpErrorResponse } from '@angular/common/http'
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core'
import { StatusCodes } from 'http-status-codes'
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog'
import { EventBusService, GlobalEvent } from 'src/app/services/eventbus.service'
import { ToastService } from 'src/app/services/toast.service'
import { NgForm } from '@angular/forms'
import { Subscription } from 'rxjs'
import * as dayjs from 'dayjs'
import { CarsDetailModel } from '../../../models/car/cars-detail.model'
import { CaregiverDependenciesModel } from '../../../models/caregiver/caregiver-dependencies.model'
import { CaregiverService } from '../../../services/caregivers.service'
import { CarService } from '../../../services/car.service'
import { CaregiverCarModel } from '../../../models/car/caregiver-car.model'
import { HelperService } from '../../../services/helper.service'

@Component({
  selector: 'app-add-caregiver-job-scope-dialog',
  templateUrl: './add-caregiver-job-scope-dialog.component.html',
})
export class AddCaregiverJobScopeDialogComponent implements OnInit, OnDestroy {
  @ViewChild('form', { static: true }) form!: NgForm

  private formSubscription: Subscription | null | undefined = null
  private isDirty = false

  public data: any = {}
  public dependencies: CaregiverDependenciesModel = new CaregiverDependenciesModel()
  public values = {
    comment: '',
    document_appendix: true,
    month: '',
    job_scope: null,
    salary: null,
    year: '',
  }

  public monthOptions: any = []
  public yearOptions = [
    { label: '2021', value: '2021' },
    { label: '2022', value: '2022' },
    { label: '2023', value: '2023' },
    { label: '2024', value: '2024' },
  ]

  public submitted = false
  public submittedDelete = false

  constructor(
    private ref: DynamicDialogRef,
    private caregiverService: CaregiverService,
    private config: DynamicDialogConfig,
    private eventbus: EventBusService,
    private helperService: HelperService,
    private toastService: ToastService
  ) {}

  ngOnInit(): void {
    this.data = this.config.data

    this.values.salary = this.data.caregiver.salary

    this.formSubscription = this.form.valueChanges?.subscribe(() => {
      if (!this.form.pristine) {
        this.isDirty = true
      }
    })

    const date = new Date()
    let currentYear = date.getFullYear()
    let nextMonth = date.getMonth() + 1

    // Wenn der aktuelle Monat einen index von 11 hatte (also bereits Dezember),
    // müssen wir den nächsten Monat auf Januar (also index 0) setzen und das Jahr erhöhen.
    if (nextMonth === 12) {
      nextMonth = 0
      currentYear++
    }

    this.helperService.dependencies$.subscribe((data: any) => {
      this.monthOptions = data.months
      this.values.month = this.monthOptions[nextMonth].label
      this.values.year = currentYear.toString()
    })

    // if (this.data.isEdit) {
    //   this.values = this.data.car
    //
    //   this.values.since = dayjs(this.data.car.since).format('DD.MM.YYYY')
    //   if (this.data.car.until) {
    //     this.values.until = dayjs(this.data.car.until).format('DD.MM.YYYY')
    //   }
    // }

    this.loadDependencies()
  }

  ngOnDestroy(): void {
    this.formSubscription?.unsubscribe()
  }

  public loadDependencies(): void {
    this.caregiverService
      .dependencies()
      .subscribe((dependencies: CaregiverDependenciesModel) => {
        this.dependencies = dependencies
      })
  }

  public store(): void {
    if (!this.form.form.valid) {
      this.submitted = false
      this.form.form.markAllAsTouched()
      return
    }

    this.submitted = true

    this.caregiverService
      .storeJobScope(this.data.caregiver.id, this.values)
      .subscribe(
        () => {
          this.submitted = false
          this.eventbus.emit(GlobalEvent.CaregiverDetailReload)
          this.ref.close()
          this.toastService.success(
            'Stellenumfang gespeichert',
            'Stellenumfang wurde erfolgreich gespeichert'
          )
        },
        (error: HttpErrorResponse) => {
          if (error.status === StatusCodes.UNPROCESSABLE_ENTITY) {
            this.toastService.error('Bitte füllen Sie alle Pflichtfelder aus')
          } else {
            this.toastService.error(
              'Etwas ist schief gelaufen...',
              'Bitte wenden Sie sich an den Support'
            )
          }
          this.submitted = false
        }
      )
  }

  public remove(): void {
    // this.submittedDelete = true
    //
    // this.carService.deleteForCaregiver(this.data.car.id).subscribe(
    //   () => {
    //     this.submittedDelete = false
    //     this.eventbus.emit(GlobalEvent.CaregiverDetailReload)
    //     this.ref.close()
    //     this.toastService.success(
    //       'PKW gelöscht',
    //       'PKW wurde erfolgreich gelöscht'
    //     )
    //   },
    //   () => {
    //     this.submittedDelete = false
    //     this.toastService.error(
    //       'Löschen fehlgeschlagen',
    //       'Der Eintrag konnte nicht gelöscht werden'
    //     )
    //   }
    // )
  }
}
