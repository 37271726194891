import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core'
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog'
import { EventBusService, GlobalEvent } from 'src/app/services/eventbus.service'
import { ToastService } from 'src/app/services/toast.service'
import { SendOfferService } from '../../../services/send-offer.service'
import { Subscription } from 'rxjs'
import { NgForm } from '@angular/forms'
import { DocumentUuidModel } from '../../../models/document/document-uuid.model'
import { DocumentService } from '../../../services/document.service'
import {ContactPersonModel} from "../../../models/contact-person/contact-person.model";
import {InvoiceService} from "../../../services/invoice.service";

@Component({
  selector: 'app-send-invoice-reminder-dialog',
  templateUrl: './send-invoice-reminder-dialog.component.html',
})
export class SendInvoiceReminderDialogComponent implements OnInit, OnDestroy {
  @ViewChild('form', { static: true }) form!: NgForm

  private formSubscription: Subscription | null | undefined = null
  private isDirty = false

  public values = {
    type: 'email',
    receiver: '',
    cc: [],
  }

  public clickedOnPreview = false
  public clickedOnPreviewOffer = false
  public data: any = {}
  public receiverOptions: any[] = []
  public ccOptions: any[] = []
  public contactPersonsForInvoice = ''

  documentsCreated = false
  submitted = false
  submittedDelete = false
  public documentLink = ''

  constructor(
    private ref: DynamicDialogRef,
    private config: DynamicDialogConfig,
    public documentService: DocumentService,
    public invoiceService: InvoiceService,
    private eventbus: EventBusService,
    private toastService: ToastService
  ) {}

  public ngOnInit(): void {
    this.data = this.config.data

    this.formSubscription = this.form.valueChanges?.subscribe(() => {
      if (!this.form.pristine) {
        this.isDirty = true
      }
    })

    this.buildReceiverOptions()
    this.buildCCOptions()
    this.buildContactPersonsForInvoice()
  }

  ngOnDestroy(): void {
    this.formSubscription?.unsubscribe()
  }

  public downloadAndClose(): void {
    this.ref.close()
  }

  public save(): void {
    if (!this.form.form.valid) {
      this.submitted = false
      this.form.form.markAllAsTouched()
      return
    }

    this.submitted = true

    this.invoiceService.createReminder(this.data.invoice.id, this.values).subscribe(
      (response: any) => {
        this.submitted = false
        this.eventbus.emit(GlobalEvent.InvoiceReminderSent)

        if (this.values.type === 'email') {
          this.ref.close()

          this.toastService.success(
            'Mahnung versendet',
            'Die Mahnung wurde erfolgreich versendet'
          )
        } else {
          this.documentLink = this.documentService.getDocumentDownloadLink(
            response.uuid
          )

          this.toastService.success(
            'Mahnung erstellt',
            'Die Mahnung wurde erfolgreich erstellt'
          )
        }
      },
      () => {
        this.toastService.error(
          'Etwas ist schiefgelaufen...',
          'Bitte wenden Sie sich an den Support'
        )

        this.submitted = false
      }
    )
  }

  public buildReceiverOptions(): void {
    this.receiverOptions = []
    this.values.receiver = ''

    this.receiverOptions.push({
      value: '',
      label: 'Bitte auswählen',
      email: '',
      disabled: true,
    })

    this.receiverOptions.push({
      value: `patient_${this.data.customerDetail.first_patient.id}_`,
      email: this.data.customerDetail.first_patient.email || 'Keine E-Mail',
      label: `${this.data.customerDetail.first_patient.full_name} - Patient`,
      disabled: this.values.type === 'email' && !this.data.customerDetail.first_patient.email,
    })

    if (this.data.customerDetail.second_patient) {
      this.receiverOptions.push({
        value: `patient_${this.data.customerDetail.second_patient.id}_`,
        email: this.data.customerDetail.second_patient.email || 'Keine E-Mail',
        label: `${this.data.customerDetail.second_patient.full_name} - Patient`,
        disabled:
          this.values.type === 'email' && !this.data.customerDetail.second_patient.email,
      })
    }

    // Der AP kann 2 verschiedene Mails haben.
    for (let contactPerson of this.data.customerDetail.customer.contact_persons) {
      if (contactPerson.email_private) {
        this.receiverOptions.push({
          value: `ap_${contactPerson.id}_private`,
          email: `Private E-Mail: ${contactPerson.email_private}`,
          label: `${contactPerson.full_name} - AP`,
          disabled: false,
        })

        // Bei Postversand soll der AP soll nur einmal in der Liste angezeigt
        // werden, selbst wenn er noch eine Geschäftliche E-Mail Adresse hat.
        if (this.values.type === 'post') {
          continue
        }
      }

      if (contactPerson.email_work) {
        this.receiverOptions.push({
          value: `ap_${contactPerson.id}_work`,
          email: `Geschäftliche E-Mail: ${contactPerson.email_work}`,
          label: `${contactPerson.full_name} - AP`,
          disabled: false,
        })
      }

      // Wenn gar keine E-Mail hinterlegt ist.
      if (!contactPerson.email_private && !contactPerson.email_work) {
        this.receiverOptions.push({
          value: `ap_${contactPerson.id}_`,
          email: 'Keine E-Mail',
          label: `${contactPerson.full_name} - AP`,
          disabled: this.values.type === 'email',
        })
      }
    }
  }

  public buildCCOptions(): void {
    this.ccOptions = []

    this.ccOptions.push({
      value: this.data.customerDetail.first_patient.email,
      email: this.data.customerDetail.first_patient.email || 'Keine E-Mail',
      label: `${this.data.customerDetail.first_patient.full_name} - Patient`,
      disabled: !this.data.customerDetail.first_patient.email,
    })

    if (this.data.customerDetail.second_patient) {
      this.ccOptions.push({
        value: this.data.customerDetail.second_patient.email,
        email: this.data.customerDetail.second_patient.email || 'Keine E-Mail',
        label: `${this.data.customerDetail.second_patient.full_name} - Patient`,
        disabled: !this.data.customerDetail.second_patient.email,
      })
    }

    // Der AP kann 2 verschiedene Mails haben.
    for (let contactPerson of this.data.customerDetail.customer.contact_persons) {
      if (contactPerson.email_private) {
        this.ccOptions.push({
          value: contactPerson.email_private,
          email: `Private E-Mail: ${contactPerson.email_private}`,
          label: `${contactPerson.full_name} - AP - Private E-Mail`,
          disabled: false,
        })
      }

      if (contactPerson.email_work) {
        this.ccOptions.push({
          value: contactPerson.email_work,
          email: `Geschäftliche E-Mail: ${contactPerson.email_work}`,
          label: `${contactPerson.full_name} - AP - Geschäftliche E-Mail`,
          disabled: false,
        })
      }

      // Wenn gar keine E-Mail hinterlegt ist.
      if (!contactPerson.email_private && !contactPerson.email_work) {
        this.ccOptions.push({
          value: null,
          email: 'Keine E-Mail',
          label: `${contactPerson.full_name} - AP - Keine E-Mail`,
          disabled: true,
        })
      }
    }
  }

  private buildContactPersonsForInvoice(): void {
    this.contactPersonsForInvoice = this.data.customerDetail.customer.contact_persons
      .filter((contactPerson: ContactPersonModel) => contactPerson.is_for_invoices)
      .map((contactPerson: ContactPersonModel) => `${contactPerson.first_name} ${contactPerson.last_name}`)
      .join(', ')
  }
}
