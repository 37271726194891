import { Component, OnInit } from '@angular/core'
import { AuthService } from '../../../services/auth.service'
import { ActivatedRoute } from '@angular/router'

@Component({
  selector: 'app-password-reset',
  templateUrl: './password-reset.component.html',
})
export class PasswordResetComponent implements OnInit {
  public submitted = false
  public isSuccess = false
  public errorMessage = ''

  public hasToken = false

  public data = {
    email: '',
    token: '',
    password: '',
    password_confirmation: '',
  }

  constructor(
    private authService: AuthService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      this.hasToken = params['token']

      this.data.token = params['token']
      this.data.email = params['email']
    })
  }

  public reset(): void {
    this.submitted = true
    this.isSuccess = false
    this.errorMessage = ''
    this.authService.resetPassword(this.data.email).subscribe(
      () => {
        this.isSuccess = true
        this.submitted = false
      },
      () => {
        this.errorMessage =
          'Etwas ist schief gelaufen. Bitte wenden Sie sich an den Support.'
        this.submitted = false
      }
    )
  }

  public confirm(): void {
    this.isSuccess = false
    this.errorMessage = ''

    if (this.data.password !== this.data.password_confirmation) {
      this.errorMessage = 'Die Passwörter stimmen nicht überein.'
      return
    }

    this.submitted = true

    this.authService.confirmResetPassword(this.data).subscribe(
      () => {
        this.isSuccess = true
        this.submitted = false

        location.reload()
      },
      () => {
        this.errorMessage =
          'Etwas ist schief gelaufen. Bitte wenden Sie sich an den Support.'
        this.submitted = false
      }
    )
  }
}
