import { Component, OnInit } from '@angular/core'
import { TitleService } from 'src/app/services/title.service'

@Component({
  selector: 'app-cancelled-dates',
  templateUrl: './cancelled-dates.component.html',
})
export class CancelledDatesComponent implements OnInit {
  constructor(private titleService: TitleService) {}

  ngOnInit(): void {
    this.titleService.setTitle('Terminabsagen')
  }
}
