import { DocumentModel } from '../document/document.model'

export class CarDetailDocumentsModel {
  types: CarDocumentTypesModel = new CarDocumentTypesModel()
}

export class CarDocumentTypesModel {
  misc = new DocumentModel()
  damages = new DocumentModel()
}
