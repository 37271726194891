<form #form="ngForm" class="dialog-form" #calendarContainer>
  <div class="dialog-form-body" style="padding-bottom: 0">
    <div
      class="p-fluid p-formgrid p-grid without-border"
      *ngIf="data.wasWithInterval"
    >
      <div class="p-field p-col-12">
        <div class="p-field-radiobutton">
          <p-radioButton
            label="Nur diesen Einsatz"
            name="type"
            [(ngModel)]="values.type"
            [value]="1"
            [required]="true"
            (onClick)="typeChanged()"
          ></p-radioButton>
        </div>
      </div>
      <div class="p-field p-col-12" *ngIf="!values.calculate_appointment">
        <div class="p-field-radiobutton">
          <p-radioButton
            label="Diesen und alle folgenden Einsätze"
            name="type"
            [(ngModel)]="values.type"
            [value]="2"
            [required]="true"
            (onClick)="typeChanged()"
          ></p-radioButton>
        </div>
      </div>
      <div class="p-field p-col-12" *ngIf="!values.calculate_appointment">
        <div class="p-field-radiobutton">
          <p-radioButton
            label=""
            name="type"
            [(ngModel)]="values.type"
            [value]="4"
            inputId="date_until"
            [required]="true"
            (onClick)="typeChanged()"
          ></p-radioButton>
          <label for="date_until" style="display: flex; align-items: center"
            >Diesen und alle folgenden Einsätze bis
            {{ values.until_date || " ??" }}
            <p-calendar
              styleClass="datepicker-as-icon"
              [firstDayOfWeek]="1"
              [showWeek]="true"
              inputId="start"
              [appendTo]="calendarContainer"
              name="until_date"
              dateFormat="dd.mm.yy"
              [(ngModel)]="values.until_date"
              dataType="string"
              [required]="values.type === 4"
              [showIcon]="true"
              [showButtonBar]="true"
            ></p-calendar>
          </label>
        </div>
      </div>
      <div class="p-field p-col-12" *ngIf="!values.calculate_appointment">
        <div class="p-field-radiobutton">
          <p-radioButton
            label="Alle Einsätze der Serie, auch die vergangenen"
            name="type"
            [(ngModel)]="values.type"
            [value]="3"
            [required]="true"
            (onClick)="typeChanged()"
          ></p-radioButton>
        </div>
      </div>
    </div>

    <div class="p-fluid p-formgrid p-grid without-border">
      <div class="p-field p-col-12" *ngIf="isAppointment">
        <label for="storno_reason_id">Grund</label>
        <p-dropdown
          placeholder="Bitte auswählen"
          inputId="storno_reason_id"
          name="storno_reason_id"
          [(ngModel)]="values.storno_reason_id"
          [options]="reasonOptions"
          appendTo="body"
          optionValue="id"
          optionLabel="reason"
          [required]="true"
          #reason="ngModel"
          [class.p-invalid]="reason.invalid && (reason.dirty || reason.touched)"
        ></p-dropdown>
      </div>
      <div class="p-field p-col-12">
        <label for="storno_comment">Bemerkung</label>
        <textarea
          id="storno_comment"
          name="storno_comment"
          [rows]="2"
          [(ngModel)]="values.storno_comment"
          pInputTextarea
          [autofocus]="true"
          [required]="isRequired"
          #comment="ngModel"
          [class.p-invalid]="
            comment.invalid && (comment.dirty || comment.touched)
          "
        ></textarea>
      </div>

      <div class="p-field p-col-12" *ngIf="lnwIsAvailable">
        <label for="lnw">Was ist mit LNW? *</label>
        <textarea
          id="lnw"
          name="lnw"
          [(ngModel)]="values.lnw"
          [rows]="2"
          pInputTextarea
          [required]="true"
          #lnw="ngModel"
          [class.p-invalid]="lnw.invalid && (lnw.dirty || lnw.touched)"
        ></textarea>
        <small
          style="display: flex; align-items: center; gap: 5px"
          class="color-blue"
          ><i class="pi pi-info-circle"></i>Es wird eine automatische Todo
          erstellt</small
        >
      </div>
    </div>

    <div
      class="p-fluid p-formgrid p-grid without-border"
      *ngIf="
        errors.time || errors.max_minutes || errors.time_quarter.length > 0
      "
    >
      <div
        class="p-field p-col-12"
        style="display: flex; flex-direction: column"
      >
        <span *ngIf="errors.time_quarter.length > 0" class="color-alert"
          >Uhrzeit muss im 15-Minuten-Takt sein</span
        >

        <span *ngIf="errors.time" class="color-alert"
          >Plausibilität der Uhrzeit stimmt nicht</span
        >

        <span *ngIf="errors.max_minutes" class="color-alert"
          >Uhrzeit darf nicht länger als {{ maxMinutes }} Minuten sein</span
        >
      </div>
    </div>

    <div
      class="p-fluid p-formgrid p-grid without-border"
      *ngIf="isAppointmentInvoice"
    >
      <div class="p-field p-col-12">
        <label>Neuen Entwurf erstellen?</label>
        <div class="p-formgroup-inline">
          <div class="p-field-radiobutton">
            <p-radioButton
              label="Ja"
              name="with_new_draft"
              [(ngModel)]="values.with_new_draft"
              [value]="true"
              required
            ></p-radioButton>
          </div>

          <div class="p-field-radiobutton">
            <p-radioButton
              label="Nein"
              name="with_new_draft"
              [(ngModel)]="values.with_new_draft"
              [value]="false"
              required
            ></p-radioButton>
          </div>
        </div>
      </div>
    </div>

    <div
      class="p-fluid p-formgrid p-grid without-border"
      *ngIf="data.withExtraText"
    >
      <div class="p-field p-col-12">
        <div style="display: flex; align-items: center; gap: 5px">
          <i class="pi pi-info-circle"></i>
          {{ data.withExtraText }}
        </div>
      </div>
    </div>
  </div>

  <div class="dialog-form-body">
    <div
      *ngIf="isAppointment && values.required_storno_options"
      class="p-fluid p-formgrid p-grid without-border"
    >
      <div class="p-field p-col-12">
        <div class="p-field-checkbox">
          <p-checkbox
            name="consider_drive_time"
            [binary]="true"
            [disabled]="values.consider_working_time"
            label="BK war vor Ort"
            [(ngModel)]="values.consider_drive_time"
          ></p-checkbox>
          <i
            class="pi pi-info-circle color-gray"
            style="margin: 0 0 0 5px; font-size: 13px"
            [escape]="false"
            pTooltip="Die Anfahrt zum Patienten wird der Betreuungskraft gutgeschrieben.<br><br>Außer es handelt sich hierbei um die erste Anfahrt des Tages.<br><br>Falls die Betreuungskraft einen AU hat, werden nur die KM gutgeschrieben.<br><br>Bezieht sich nur auf diesen Einsatz."
          ></i>
        </div>
      </div>
    </div>

    <div
      *ngIf="isAppointment && values.required_storno_options && !isIll"
      style="padding: 0"
      class="p-fluid p-formgrid p-grid without-border"
    >
      <div class="p-field p-col-6">
        <div class="p-field-checkbox">
          <p-checkbox
            name="consider_working_time"
            [binary]="true"
            (onChange)="stornoOptionsChanged()"
            label="Arbeitszeit anrechnen"
            [(ngModel)]="values.consider_working_time"
          ></p-checkbox>

          <i
            class="pi pi-info-circle color-gray"
            style="margin: 0 0 0 5px; font-size: 13px"
            [escape]="false"
            pTooltip="Maximal {{
              maxMinutes
            }} Minuten.<br><br>Bezieht sich nur auf diesen Einsatz."
          ></i>
        </div>
      </div>

      <div class="p-field p-col-6" *ngIf="values.consider_working_time">
        <p-inputNumber
          styleClass="input-height-light"
          name="consider_working_time_minutes"
          [(ngModel)]="values.consider_working_time_minutes"
          [showButtons]="true"
          inputId="stacked"
          [min]="15"
          (onBlur)="stornoOptionsChanged()"
          [allowEmpty]="false"
          [step]="15"
          suffix=" Minuten"
        >
        </p-inputNumber>
      </div>
    </div>

    <div
      *ngIf="isAppointment"
      style="padding: 0"
      class="p-fluid p-formgrid p-grid without-border"
    >
      <div class="p-field p-col-6" style="margin-bottom: 0">
        <div class="p-field-checkbox">
          <p-checkbox
            name="calculate_appointment"
            [binary]="true"
            (onChange)="stornoOptionsChanged()"
            label="Einsatz berechnen"
            [(ngModel)]="values.calculate_appointment"
          ></p-checkbox>
          <i
            class="pi pi-info-circle color-gray"
            style="margin: 0 0 0 5px; font-size: 13px"
            [escape]="false"
            pTooltip="Maximal {{
              maxMinutes
            }} Minuten.<br><br>Trotz Stornierung wird dieser Einsatz in Rechnung gestellt.<br><br>Bezieht sich nur auf diesen Einsatz."
          ></i>
        </div>
      </div>

      <div
        class="p-field p-col-6"
        style="margin-bottom: 0"
        *ngIf="values.calculate_appointment"
      >
        <div style="display: flex; align-items: center; gap: 10px">
          <p-calendar
            styleClass="input-height-light"
            (onBlur)="checkForTimes($event, 'from_new')"
            [stepMinute]="15"
            inputId="from_new"
            name="from_new"
            [timeOnly]="true"
            appendTo="body"
            [(ngModel)]="values.from_new"
            dataType="string"
            [required]="true"
            #from="ngModel"
            [showButtonBar]="false"
            [class.p-invalid]="from.invalid && (from.dirty || from.touched)"
          ></p-calendar>

          <span style="font-size: 14px" class="color-gray">bis</span>

          <p-calendar
            styleClass="input-height-light"
            (onBlur)="checkForTimes($event, 'to_new')"
            [stepMinute]="15"
            inputId="to_new"
            name="to_new"
            [timeOnly]="true"
            appendTo="body"
            [(ngModel)]="values.to_new"
            dataType="string"
            [required]="true"
            #to="ngModel"
            [showButtonBar]="false"
            [class.p-invalid]="to.invalid && (to.dirty || to.touched)"
          ></p-calendar>
        </div>
      </div>
    </div>
  </div>
  <div class="dialog-form-footer">
    <button
      pButton
      label="Abbrechen"
      class="p-button-text button-without-padding"
      type="button"
      icon="pi pi-times"
      (click)="abort()"
    ></button>

    <button
      (click)="save()"
      pButton
      label="Bestätigen"
      type="button"
      [disabled]="submitted"
      [icon]="submitted ? 'pi pi-spin pi-spinner' : 'pi pi-check'"
    ></button>
  </div>
</form>
