<app-loader [loading]="loading"></app-loader>

<div class="content" *ngIf="!loading">
  <div class="content-body without-padding p-flex-row">
    <div class="detail-information-column border-right">
      <div class="detail-information-container">
        <div class="detail-information-header">
          <span><i class="pi pi-user"></i>Daten</span>
        </div>
        <!--        <div class="detail-information-body-container">-->
        <div class="detail-information-body">
          <div class="detail-information">
            <div class="detail-key">
              <span>Status</span>
            </div>
            <div class="detail-value p-jc-between p-align-center">
              <strong>{{ data.current_status.status_text }}</strong>
            </div>
          </div>

          <div class="detail-information">
            <div class="detail-key">
              <span>ID</span>
            </div>
            <div class="detail-value">
              <strong>{{ data.id }}</strong>
            </div>
          </div>

          <div class="detail-information">
            <div class="detail-key">
              <span>Name</span>
            </div>
            <div class="detail-value">
              <strong
                >{{ data.full_name }}
                {{ data.birth_name ? "(" + data.birth_name + ")" : "" }}</strong
              >
            </div>
          </div>

          <div class="detail-information">
            <div class="detail-key">
              <span>Geburtsdatum</span>
            </div>
            <div class="detail-value">
              <strong
                >{{ data.birthday_date_string }}
                {{ data.birthplace ? "(" + data.birthplace + ")" : "" }}</strong
              >
            </div>
          </div>

          <div class="detail-information">
            <div class="detail-key">
              <span>Rauchen</span>
            </div>
            <div class="detail-value">
              <strong>{{ data.smoking }}</strong>
            </div>
          </div>

          <div class="detail-information">
            <div class="detail-key">
              <span>Führerschein seit</span>
            </div>
            <div class="detail-value">
              <strong>{{ data.driving_license_since_string || "-" }}</strong>
            </div>
          </div>

          <div class="detail-information">
            <div class="detail-key">
              <span>Allergien</span>
            </div>
            <div class="detail-value">
              <strong>{{ data.allergies || "-" }}</strong>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="detail-information-column">
      <div class="detail-information-container">
        <div class="detail-information-header">
          <span><i class="pi pi-phone"></i> Kontaktdaten</span>
        </div>

        <div class="detail-information-body">
          <div class="detail-information">
            <div class="detail-key" style="align-items: center">
              <span>Notfallkontakt </span>
            </div>
            <div class="detail-value" *ngIf="!data.important_contact_person">
              <strong>-</strong>
            </div>
            <div class="detail-value" *ngIf="data.important_contact_person">
              <strong>{{ data.important_contact_person.full_name }} </strong>
            </div>
          </div>

          <div class="detail-information">
            <div class="detail-key">
              <span>Telefon 1</span>
            </div>
            <div class="detail-value">
              <strong>{{ data.phone_1 || "-" }}</strong>
            </div>
          </div>

          <div class="detail-information">
            <div class="detail-key">
              <span>Telefon 2</span>
            </div>
            <div class="detail-value">
              <strong>{{ data.phone_2 || "-" }}</strong>
            </div>
          </div>

          <div class="detail-information">
            <div class="detail-key">
              <span>Telefon 3</span>
            </div>
            <div class="detail-value">
              <strong>{{ data.phone_3 || "-" }}</strong>
            </div>
          </div>

          <div class="detail-information">
            <div class="detail-key">
              <span>E-Mail</span>
            </div>
            <div class="detail-value">
              <strong>{{ data.email || "-" }}</strong>
            </div>
          </div>

          <div class="detail-information">
            <div class="detail-key">
              <span>Straße / Hausnummer</span>
            </div>
            <div class="detail-value">
              <strong>{{ data.street_house_number || "-" }}</strong>
            </div>
          </div>

          <div class="detail-information">
            <div class="detail-key">
              <span>PLZ / Ort</span>
            </div>
            <div class="detail-value">
              <strong
                >{{ data.zipcode }} {{ data.city }} {{ data.city_part }}</strong
              >
            </div>
          </div>

          <div class="detail-information">
            <div class="detail-key">
              <span>Landkreis / Bundesland</span>
            </div>
            <div class="detail-value">
              <strong>{{ data.county }} {{ data.state }}</strong>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="content" *ngIf="!loading">
  <header class="content-header">
    <span>
      <i class="pi pi-phone"></i>
      Ansprechpartner
    </span>
  </header>
  <div class="content-body" *ngIf="data.contact_persons.length === 0">
    <span class="content-no-message"> Kein Ansprechpartner vorhanden </span>
  </div>

  <div
    class="content-body without-padding p-flex-row p-flex-wrap"
    *ngIf="data.contact_persons.length > 0"
  >
    <div
      *ngFor="let contactPerson of data.contact_persons; let i = index"
      class="detail-information-column contact-person-column border-right"
    >
      <div class="detail-information-container">
        <div class="detail-information-header for-contact-person">
          <span>
            <i class="pi pi-user"></i>
            Ansprechpartner {{ i + 1 }}
          </span>
        </div>
        <div class="detail-information-body">
          <div class="detail-information">
            <div class="detail-key">
              <span>Infos</span>
            </div>
            <div class="detail-value">
              <strong>{{ contactPerson.informations_string || "-" }}</strong>
            </div>
          </div>
          <div class="detail-information">
            <div class="detail-key">
              <span>Name</span>
            </div>
            <div class="detail-value">
              <strong
                >{{ contactPerson.form_of_address }}
                {{ contactPerson.first_name }}
                {{ contactPerson.last_name }}</strong
              >
            </div>
          </div>
          <div class="detail-information">
            <div class="detail-key">
              <span>Telefon 1</span>
            </div>
            <div class="detail-value">
              <strong class="p-d-flex p-align-center">
                {{ contactPerson.phone_1 }}
              </strong>
            </div>
          </div>
          <div class="detail-information">
            <div class="detail-key">
              <span>Telefon 2</span>
            </div>
            <div class="detail-value">
              <strong class="p-d-flex p-align-center">
                {{ contactPerson.phone_2 }}
              </strong>
            </div>
          </div>
          <div class="detail-information" *ngIf="openedContactPersons[i]">
            <div class="detail-key">
              <span>E-Mail Privat</span>
            </div>
            <div class="detail-value">
              <strong class="p-d-flex p-align-center">
                {{ contactPerson.email_private }}
              </strong>
            </div>
          </div>
          <div class="detail-information" *ngIf="openedContactPersons[i]">
            <div class="detail-key">
              <span>E-Mail Geschäftlich</span>
            </div>
            <div class="detail-value">
              <strong class="p-d-flex p-align-center">
                {{ contactPerson.email_work }}
              </strong>
            </div>
          </div>
          <div class="detail-information" *ngIf="openedContactPersons[i]">
            <div class="detail-key">
              <span>PLZ / Ort</span>
            </div>
            <div class="detail-value">
              <strong
                >{{ contactPerson.zipcode }} {{ contactPerson.city }}
                {{ contactPerson.city_part }}</strong
              >
            </div>
          </div>
          <div class="detail-information" *ngIf="openedContactPersons[i]">
            <div class="detail-key">
              <span>Adresse</span>
            </div>
            <div class="detail-value">
              <strong>{{ contactPerson.street_house_number }}</strong>
            </div>
          </div>
        </div>

        <span
          class="show-more-btn"
          (click)="openedContactPersons[i] = !openedContactPersons[i]"
        >
          {{ openedContactPersons[i] ? "Weniger anzeigen" : "Mehr anzeigen" }}
        </span>
      </div>
    </div>
  </div>
</div>
