<!--<button class="dialog-close-icon-fake" (click)="close()">-->
<!--  <i class="pi pi-times"></i>-->
<!--</button>-->

<form #form="ngForm" class="dialog-form">
  <div class="dialog-form-body">
    <div class="p-fluid p-formgrid p-grid without-border">
      <div class="p-field p-col-12" *ngIf="!isEdit">
        <label for="patient">Patient * </label>
        <p-autoComplete
          field="full_name"
          inputId="patient"
          name="patient"
          scrollHeight="350px"
          appendTo="body"
          [forceSelection]="true"
          [(ngModel)]="patient"
          [suggestions]="patients"
          (completeMethod)="searchPatients($event)"
          (onSelect)="patientSelected($event)"
          [required]="true"
          [autofocus]="true"
          #patientRef="ngModel"
          [class.p-invalid]="
            patientRef.invalid && (patientRef.dirty || patientRef.touched)
          "
        >
          <ng-template let-patient pTemplate="item">
            <div class="search-result-data">
              <span class="search-result-name">
                <b>{{ patient.full_name }}</b>
                <!--                <span class="search-result-id">#{{ patient.id }}</span>-->
                <i
                  *ngIf="patient.status"
                  class="p-tag p-tag-rounded p-tag-{{ patient.status }}"
                  >{{ patient.status_string }}</i
                >
              </span>
              <span class="search-result-address">
                VS-Nummer: {{ patient.insurer_number || "-" }}
              </span>
            </div>
          </ng-template>
        </p-autoComplete>
      </div>

      <div class="p-field p-col-6" *ngIf="isEdit">
        <label>Patient</label>
        <strong>{{ patient.full_name }}</strong>
      </div>
    </div>

    <div class="p-fluid p-formgrid p-grid without-border"></div>
  </div>

  <div class="content-body dialog-max-height without-padding">
    <p-table
      #dt
      [value]="config.data.patient.persplan_budgets"
      [rowHover]="true"
      [scrollable]="true"
      scrollHeight="320px"
      [autoLayout]="true"
      [globalFilterFields]="[
        'name',
      ]"
    >
      <ng-template pTemplate="header">
        <tr>
          <th style="min-width: 240px">
            <p-checkbox
              [style]="{ marginRight: '10px' }"
              name="select_all"
              (onChange)="selectAllToggle()"
              label=""
              [binary]="true"
              [trueValue]="true"
              [falseValue]="null"
              [(ngModel)]="selectAll"
            ></p-checkbox
            >Auswahl
          </th>
          <th pSortableColumn="real_from">
            Datum <p-sortIcon field="real_from"></p-sortIcon>
          </th>
          <th pSortableColumn="caregiver.last_name">
            Betreuungskraft
            <p-sortIcon field="caregiver.last_name"></p-sortIcon>
          </th>
          <th
            style="max-width: 140px"
            pSortableColumn="caregiver_appointment_trip.mileage"
          >
            Kilometer
            <p-sortIcon field="caregiver_appointment_trip.mileage"></p-sortIcon>
          </th>
          <th
            style="max-width: 100px"
            pSortableColumn="caregiver_appointment_trip.mileage_price"
          >
            Summe
            <p-sortIcon
              field="caregiver_appointment_trip.mileage_price"
            ></p-sortIcon>
          </th>
          <th style="max-width: 60px"></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-item>
        <tr>
          <td style="min-width: 240px">
            <div style="display: flex; gap: 15px; flex-shrink: 0">
              <span style="display: flex; gap: 3px; align-items: center">
                <p-triStateCheckbox
                  [name]="item.id"
                  [(ngModel)]="
                    item.caregiver_appointment_trip.selected_for_invoice
                  "
                  [label]="
                    item.caregiver_appointment_trip.selected_for_invoice ===
                    true
                      ? 'Wird berechnet'
                      : item.caregiver_appointment_trip.selected_for_invoice ===
                        false
                      ? 'Wird nicht berechnet'
                      : 'Berechnen?'
                  "
                ></p-triStateCheckbox>
              </span>
            </div>
          </td>
          <td>
            {{ item.real_from | date: "dd.MM.y" }} ({{ item.weekday[0]
            }}{{ item.weekday[1] }})
          </td>
          <td>{{ item.caregiver.last_first_name }}</td>
          <td style="max-width: 140px">
            {{ item.caregiver_appointment_trip.mileage }} KM
          </td>
          <td style="max-width: 100px">
            {{ item.caregiver_appointment_trip.mileage_price_euro_formatted }}
          </td>
          <td style="max-width: 60px">
            <i
              *ngIf="item.caregiver_appointment_trip.comments.length > 0"
              class="pi pi-info-circle"
              [pTooltip]="getComments(item.caregiver_appointment_trip.comments)"
            ></i>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>

  <div class="dialog-form-footer" *ngIf="patient?.id">
    <div class="dialog-footer-action-container">
      <i></i>
    </div>

    <div class="dialog-footer-action-container">
      <p-splitButton
        label="Entwurf speichern"
        [icon]="submitted ? 'pi pi-spin pi-spinner' : 'pi pi-check'"
        (onClick)="store()"
        [model]="items"
        appendTo="body"
        [disabled]="submitted"
      >
      </p-splitButton>
    </div>
  </div>
</form>

<p-confirmPopup></p-confirmPopup>
