import { CarsDetailModel } from './cars-detail.model'

export class CarMileageModel {
  id: number | null = null
  car_id: number | null = null
  car_damage_id: number | null = null
  mileage = ''
  comment = ''
  created_at: Date | null = null
  updated_at: Date | null = null
  deleted_at: Date | null = null
  since: Date | string | null = null

  car: CarsDetailModel = new CarsDetailModel()
}
