import { CarsDetailModel } from './cars-detail.model'
import { CaregiverDetailModel } from '../caregiver/caregiver-detail.model'

export class CaregiverCarModel {
  id: number | null = null
  car_id: number | null = null
  caregiver_id: number | null = null
  assignable_id: number | null = null
  assignable_type = 'App\\Models\\Caregiver'
  comment = ''
  mileage = null
  created_at: Date | null = null
  updated_at: Date | null = null
  deleted_at: Date | null = null
  since: Date | string | null = null
  until: Date | string | null = null
  since_string = ''
  until_string = ''

  car: CarsDetailModel = new CarsDetailModel()
  caregiver: CaregiverDetailModel = new CaregiverDetailModel()
  assignable: any
}
