<button class="dialog-close-icon-fake" *ngIf="isEdit" (click)="close()">
  <i class="pi pi-times"></i>
</button>

<form #form="ngForm" class="dialog-form">
  <div class="dialog-loader" *ngIf="isParsing">
    <i class="loading-logo"></i>
    DOKUMENT WIRD VERARBEITET
  </div>

  <div
    class="dialog-sidebar secondary-dialog-sidebar smaller-dialog-sidebar"
    *ngIf="values.patient && isEdit"
  >
    <div class="p-fluid p-formgrid p-grid">
      <div class="p-field p-col-12">
        <label for="result">Ergebnis</label>
        <p-dropdown
          appendTo="body"
          scrollHeight="500px"
          placeholder="Bitte wählen"
          inputId="result"
          name="result"
          [options]="resultOptions"
          [(ngModel)]="values.result"
        ></p-dropdown>
      </div>
    </div>

    <div class="p-fluid p-formgrid p-grid without-border">
      <div class="p-field p-col-12">
        <label for="history">History</label>
        <textarea
          id="history"
          name="history"
          [rows]="2"
          [(ngModel)]="historyComment"
          pInputTextarea
        ></textarea>
      </div>
      <div
        class="p-field p-col-12"
        style="margin-top: -10px"
        *ngIf="!editHistory"
      >
        <button
          style="padding: 2px 0"
          pButton
          label="Eintragen"
          class="p-button-outlined"
          type="button"
          [disabled]="!this.historyComment || submitted"
          (click)="storeHistoryComment()"
        ></button>
      </div>

      <div
        class="p-field p-col-6"
        style="margin-top: -10px"
        *ngIf="editHistory"
      >
        <button
          style="padding: 2px 0"
          pButton
          label="Ändern"
          class="p-button-outlined"
          type="button"
          [disabled]="!this.historyComment"
          (click)="updateHistoryComment()"
        ></button>
      </div>

      <div
        class="p-field p-col-6"
        style="margin-top: -10px"
        *ngIf="editHistory"
      >
        <button
          style="padding: 2px 0"
          pButton
          label="Abbrechen"
          class="p-button-text"
          type="button"
          (click)="cancelEditMode()"
        ></button>
      </div>
    </div>

    <div class="dialog-sidebar-comments-container" *ngIf="isEdit">
      <div
        class="dialog-no-message dialog-no-comment"
        *ngIf="values.user_histories.length === 0"
      >
        Keine History vorhanden
      </div>
      <div
        class="dialog-sidebar-comment"
        *ngFor="let history of values.user_histories"
      >
        <!-- Den Kommentar darf nur der Ersteller bearbeiten -->
        <i
          *ngIf="currentUser?.id === history.user_id"
          class="pi pi-pencil"
          pTooltip="Bearbeiten"
          (click)="openEditMode(history)"
        ></i>
        <strong>{{ history.comment }}</strong>
        <span
          >{{ history.user.name }} am
          {{ history.created_at | date: "dd.MM.y, H:mm" }} Uhr</span
        >
      </div>
    </div>
  </div>

  <div
    class="dialog-sidebar smaller-dialog-sidebar"
    *ngIf="values.patient && !isEdit"
  >
    <div
      class="p-fluid p-formgrid p-grid without-border full-height-file-input"
    >
      <div class="p-field p-col-12">
        <h4>PDF auswählen oder reinziehen</h4>
        <input
          class="big-file-input"
          id="file"
          name="file"
          [disabled]="isParsing"
          type="file"
          (change)="setSelectedFile($event)"
          pInputText
          [(ngModel)]="values.selected_file"
        />
      </div>
    </div>
  </div>

  <div class="dialog-form-body">
    <div class="p-fluid p-formgrid p-grid" *ngIf="isEdit">
      <div class="p-field">
        <div class="selectbutton-small">
          <p-selectButton
            [options]="statusOptions"
            [(ngModel)]="values.status"
            name="status"
            (onChange)="changeStatus()"
          >
            <ng-template let-item>
              <span [style]="{ backgroundColor: item.backgroundColor }">{{
                item.label
              }}</span>
            </ng-template>
          </p-selectButton>
        </div>
      </div>
    </div>
    <div class="p-fluid p-formgrid p-grid without-border">
      <div class="p-field p-col-12" *ngIf="!isEdit">
        <label for="patient">Patient * </label>
        <p-autoComplete
          field="full_name"
          inputId="patient"
          name="patient"
          scrollHeight="350px"
          appendTo="body"
          [forceSelection]="true"
          [(ngModel)]="values.patient"
          [suggestions]="patients"
          (completeMethod)="searchPatients($event)"
          (onSelect)="patientSelected()"
          [required]="true"
          [autofocus]="true"
          #patient="ngModel"
          [class.p-invalid]="
            patient.invalid && (patient.dirty || patient.touched)
          "
        >
          <ng-template let-patient pTemplate="item">
            <div class="search-result-data">
              <span class="search-result-name">
                <b>{{ patient.full_name }}</b>
                <!--                <span class="search-result-id">#{{ patient.id }}</span>-->
                <i
                  *ngIf="patient.status"
                  class="p-tag p-tag-rounded p-tag-{{ patient.status }}"
                  >{{ patient.status_string }}</i
                >
              </span>
              <span class="search-result-address">
                VS-Nummer: {{ patient.insurer_number || "-" }}
              </span>
            </div>
          </ng-template>
        </p-autoComplete>
      </div>

      <div class="p-field p-col-6" *ngIf="isEdit">
        <label>Patient</label>
        <strong>{{ values.patient.full_name }}</strong>
      </div>
    </div>

    <div
      class="p-fluid p-formgrid p-grid without-border"
      *ngIf="values.patient"
    >
      <div class="p-field p-col-5">
        <label>Pflegekasse</label>
        <strong>{{ values.patient.care_insurance.name || "-" }}</strong>
      </div>

      <div class="p-field p-col-2">
        <label>Pflegegrad</label>
        <strong>{{ values.patient.degree_of_care || "-" }}</strong>
      </div>

      <div class="p-field p-col-3">
        <label>VS-Nummer</label>
        <strong>{{ values.patient.insurer_number || "-" }}</strong>
        <strong
          class="color-alert"
          *ngIf="values.patient && !isEdit && incorrectInsurerNumber"
          ><br />{{ values.insurer_number }}
          <i
            class="pi pi-info-circle"
            pTooltip="VS-Nummer aus dem Dokument. Kann unkorrekt sein."
          ></i
        ></strong>
      </div>

      <div class="p-field p-col-2">
        <label>Geburtstag</label>
        <strong>{{ values.patient.birthday_date_string || "-" }}</strong>
      </div>
    </div>

    <div
      class="p-fluid p-formgrid p-grid without-border"
      *ngIf="values.patient"
    >
      <div class="p-field p-col-6">
        <label for="case_number">Fallnummer *</label>

        <div class="p-inputgroup">
          <input
            id="case_number"
            name="case_number"
            type="text"
            [(ngModel)]="values.case_number"
            pInputText
            #case_number="ngModel"
            required
          />
          <span
            *ngIf="!isEdit"
            [class.active]="confirmed.case_number"
            [pTooltip]="
              confirmed.case_number ? 'Wurde überprüft' : 'Bestätigen'
            "
            (click)="confirmed.case_number = !confirmed.case_number"
            class="p-inputgroup-addon inputgroup-addon-clickable"
            ><i class="pi pi-check"></i
          ></span>
        </div>
      </div>

      <div class="p-field p-col-3">
        <label for="month">Leistungsmonat *</label>
        <div class="p-inputgroup">
          <p-dropdown
            appendTo="body"
            scrollHeight="500px"
            placeholder="Bitte wählen"
            inputId="month"
            name="month"
            [options]="monthOptions"
            [(ngModel)]="values.performance_month"
            [required]="true"
            #month="ngModel"
            [class.p-invalid]="month.invalid && (month.dirty || month.touched)"
          ></p-dropdown>
          <span
            *ngIf="!isEdit"
            [class.active]="confirmed.performance_month"
            [pTooltip]="
              confirmed.performance_month ? 'Wurde überprüft' : 'Bestätigen'
            "
            (click)="confirmed.performance_month = !confirmed.performance_month"
            class="p-inputgroup-addon inputgroup-addon-clickable"
            ><i class="pi pi-check"></i
          ></span>
        </div>
      </div>

      <div class="p-field p-col-3">
        <label for="year">Leistungsjahr *</label>
        <div class="p-inputgroup">
          <p-inputNumber
            [min]="1900"
            [max]="2100"
            [useGrouping]="false"
            inputId="year"
            name="year"
            [(ngModel)]="values.performance_year"
            [required]="true"
            #year="ngModel"
            [class.p-invalid]="year.invalid && (year.dirty || year.touched)"
          ></p-inputNumber>
          <span
            *ngIf="!isEdit"
            [class.active]="confirmed.performance_year"
            [pTooltip]="
              confirmed.performance_year ? 'Wurde überprüft' : 'Bestätigen'
            "
            (click)="confirmed.performance_year = !confirmed.performance_year"
            class="p-inputgroup-addon inputgroup-addon-clickable"
            ><i class="pi pi-check"></i
          ></span>
        </div>
      </div>
    </div>
    <div
      class="p-fluid p-formgrid p-grid without-border"
      *ngIf="values.patient"
    >
      <div class="p-field p-col-3">
        <label for="invoice_number">Rechnungsnummer *</label>
        <div class="p-inputgroup">
          <input
            id="invoice_number"
            name="invoice_number"
            type="text"
            [(ngModel)]="values.invoice_number"
            pInputText
            #invoice_number="ngModel"
            [required]="true"
            [class.p-invalid]="
              invoice_number.invalid &&
              (invoice_number.dirty || invoice_number.touched)
            "
          />
          <span
            *ngIf="!isEdit"
            [class.active]="confirmed.invoice_number"
            [pTooltip]="
              confirmed.invoice_number ? 'Wurde überprüft' : 'Bestätigen'
            "
            (click)="confirmed.invoice_number = !confirmed.invoice_number"
            class="p-inputgroup-addon inputgroup-addon-clickable"
            ><i class="pi pi-check"></i
          ></span>
        </div>
      </div>

      <div class="p-field p-col-3">
        <label for="invoice_date">Rechnungsdatum *</label>
        <div class="p-inputgroup">
          <p-calendar
            [firstDayOfWeek]="1"
            [showWeek]="true"
            inputId="invoice_date"
            name="invoice_date"
            [showButtonBar]="false"
            [(ngModel)]="values.invoice_date_string"
            dateFormat="dd.mm.yy"
            appendTo="body"
            [showIcon]="false"
            dataType="string"
            #invoice_date="ngModel"
            [required]="true"
            [class.p-invalid]="
              invoice_date.invalid &&
              (invoice_date.dirty || invoice_date.touched)
            "
          ></p-calendar>
          <span
            *ngIf="!isEdit"
            [class.active]="confirmed.invoice_date_string"
            [pTooltip]="
              confirmed.invoice_date_string ? 'Wurde überprüft' : 'Bestätigen'
            "
            (click)="
              confirmed.invoice_date_string = !confirmed.invoice_date_string
            "
            class="p-inputgroup-addon inputgroup-addon-clickable"
            ><i class="pi pi-check"></i
          ></span>
        </div>
      </div>

      <div class="p-field p-col-3">
        <label for="total_value">Gesamtwert *</label>
        <div class="p-inputgroup">
          <p-inputNumber
            mode="currency"
            currency="EUR"
            locale="de-DE"
            inputId="total_value"
            name="total_value"
            [(ngModel)]="values.total_value"
            [required]="true"
            #total_value="ngModel"
            [class.p-invalid]="
              total_value.invalid && (total_value.dirty || total_value.touched)
            "
          ></p-inputNumber>
          <span
            *ngIf="!isEdit"
            [class.active]="confirmed.total_value"
            [pTooltip]="
              confirmed.total_value ? 'Wurde überprüft' : 'Bestätigen'
            "
            (click)="confirmed.total_value = !confirmed.total_value"
            class="p-inputgroup-addon inputgroup-addon-clickable"
            ><i class="pi pi-check"></i
          ></span>
        </div>
      </div>

      <div class="p-field p-col-3">
        <label for="invoice_value">Rechnungsbetrag *</label>
        <div class="p-inputgroup">
          <p-inputNumber
            mode="currency"
            currency="EUR"
            locale="de-DE"
            inputId="invoice_value"
            name="invoice_value"
            [(ngModel)]="values.invoice_value"
            [required]="true"
            #invoice_value="ngModel"
            [class.p-invalid]="
              invoice_value.invalid &&
              (invoice_value.dirty || invoice_value.touched)
            "
          ></p-inputNumber>
          <span
            *ngIf="!isEdit"
            [class.active]="confirmed.invoice_value"
            [pTooltip]="
              confirmed.invoice_value ? 'Wurde überprüft' : 'Bestätigen'
            "
            (click)="confirmed.invoice_value = !confirmed.invoice_value"
            class="p-inputgroup-addon inputgroup-addon-clickable"
            ><i class="pi pi-check"></i
          ></span>
        </div>
      </div>
    </div>

    <div
      class="p-fluid p-formgrid p-grid without-border"
      *ngIf="values.patient"
    >
      <div class="p-field p-col-12">
        <label for="correction_reason">Korrekturgrund *</label>
        <div class="p-inputgroup">
          <p-dropdown
            appendTo="body"
            scrollHeight="500px"
            placeholder="Bitte auswählen"
            inputId="correction_reason"
            name="correction_reason"
            [options]="reasonOptions"
            [(ngModel)]="values.correction_reason"
            [required]="true"
            #correction_reason="ngModel"
            [class.p-invalid]="
              correction_reason.invalid &&
              (correction_reason.dirty || correction_reason.touched)
            "
          ></p-dropdown>
        </div>
      </div>
    </div>
  </div>

  <div class="dialog-form-body dialog-reasons-container" *ngIf="values.patient">
    <div class="p-fluid p-formgrid p-grid without-border">
      <div class="p-field p-col-2">
        <label><b>Anzahl</b></label>
      </div>
      <div class="p-field p-col-5">
        <label><b>Text</b></label>
      </div>
      <div class="p-field p-col-2">
        <label><b>Einzelpreis</b></label>
      </div>
      <div class="p-field p-col-2">
        <label><b>Gesamtpreis</b></label>
      </div>
    </div>

    <div
      class="p-fluid p-formgrid p-grid without-border"
      *ngFor="let position of values.positions; let i = index"
    >
      <div class="p-field p-col-2">
        <input
          [id]="'position-amount-' + i"
          [name]="'position-amount-' + i"
          type="text"
          [(ngModel)]="position.amount"
          pInputText
        />
      </div>
      <div class="p-field p-col-5">
        <input
          [id]="'position-text-' + i"
          [name]="'position-text-' + i"
          type="text"
          [(ngModel)]="position.text"
          pInputText
        />
      </div>
      <div class="p-field p-col-2">
        <p-inputNumber
          mode="currency"
          currency="EUR"
          locale="de-DE"
          [inputId]="'position-unit-value-' + i"
          [name]="'position-unit-value-' + i"
          [(ngModel)]="position.unit_value"
        ></p-inputNumber>
      </div>
      <div class="p-field p-col-2">
        <p-inputNumber
          mode="currency"
          currency="EUR"
          locale="de-DE"
          [inputId]="'position-total-value-' + i"
          [name]="'position-total-value-' + i"
          [(ngModel)]="position.total_value"
        ></p-inputNumber>
      </div>
      <div
        *ngIf="values.positions.length > 1"
        class="p-field p-col-1 remove-position-container"
      >
        <i
          class="pi pi-times"
          pTooltip="Entfernen"
          (click)="removePosition(position)"
        ></i>
      </div>
    </div>

    <div class="add-position-container">
      <i class="pi pi-plus" pTooltip="Hinzufügen" (click)="addPosition()"></i>
    </div>
  </div>

  <div class="dialog-form-footer" *ngIf="values.patient">
    <div class="dialog-footer-action-container">
      <i
        class="pi pi-trash"
        *ngIf="isEdit"
        [class.pi-trash]="!submittedDelete"
        [class.pi-spin]="submittedDelete"
        [class.pi-spinner]="submittedDelete"
        tooltipPosition="left"
        tooltipZIndex="20000"
        [pTooltip]="submittedDelete ? 'Wird gelöscht' : 'Rückläufer löschen'"
        (click)="remove($event)"
      ></i>

      <button
        *ngIf="values.media[0]"
        pButton
        label="Datei ansehen"
        class="p-button-text button-without-padding"
        type="button"
        icon="pi pi-file"
        (click)="openMediaFromUuid(values.media[0].uuid)"
      ></button>

      <button
        *ngIf="values.temp_image_name"
        pButton
        label="Datei ansehen"
        class="p-button-text button-without-padding"
        type="button"
        icon="pi pi-file"
        (click)="openOptaDataImageMedia(values.temp_image_name)"
      ></button>
    </div>

    <div class="dialog-footer-action-container">
      <button
        *ngIf="isEdit && (!values.result || values.result === 'rückläufer')"
        pButton
        (click)="confirmSendLetter($event)"
        label="Brief erstellen"
        type="button"
        class="p-button-outlined"
        icon="pi pi-send"
      ></button>
      <button
        (click)="store()"
        pButton
        [label]="isEdit ? 'Speichern' : 'Erstellen'"
        type="button"
        [disabled]="submitted"
        [icon]="submitted ? 'pi pi-spin pi-spinner' : 'pi pi-check'"
      ></button>
    </div>
  </div>
</form>

<p-confirmPopup></p-confirmPopup>
