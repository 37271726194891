import { Component, OnInit } from '@angular/core'
import { TitleService } from 'src/app/services/title.service'

@Component({
  selector: 'app-cars-list',
  templateUrl: './cars-list.component.html',
})
export class CarsListComponent implements OnInit {
  constructor(private titleService: TitleService) {}

  ngOnInit(): void {
    this.titleService.setTitle(`Alle PKW`)
  }
}
