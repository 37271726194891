import { Component, OnDestroy, OnInit, Renderer2 } from '@angular/core'
import {
  ActivatedRoute,
  Event,
  NavigationEnd,
  NavigationStart,
  ParamMap,
  Router,
} from '@angular/router'
import { CaregiverService } from '../../../services/caregivers.service'
import { CaregiverDetailModel } from '../../../models/caregiver/caregiver-detail.model'
import { DialogService } from 'primeng/dynamicdialog'
import {
  EventBusService,
  GlobalEvent,
} from '../../../services/eventbus.service'
import { Subscription } from 'rxjs'
import { TitleService } from 'src/app/services/title.service'
import { NoticeDialogComponent } from '../../../components/dialogs/notice-dialog/notice-dialog.component'
import { HelperService } from '../../../services/helper.service'

@Component({
  selector: 'app-caregivers-detail',
  templateUrl: './caregivers-detail.component.html',
  styleUrls: ['./caregivers-detail.component.scss'],
})
export class CaregiversDetailComponent implements OnInit, OnDestroy {
  public caregiverId: string | null = null
  public currentCaregiverId: number | null = null

  public caregiverDetails: CaregiverDetailModel = new CaregiverDetailModel()
  public isEmployeed = true
  private eventBusSubscription: Subscription = new Subscription()

  // TODO: Model erstellen
  public caregivers_active: any[] = []
  public caregivers_deactivated: any[] = []
  public showMoreNotice = false
  public defaultTabIsActive = false

  private keyListener: any = null

  constructor(
    private renderer: Renderer2,
    private route: ActivatedRoute,
    private router: Router,
    private dialogService: DialogService,
    private eventbus: EventBusService,
    private caregiverService: CaregiverService,
    private titleService: TitleService,
    private helperService: HelperService
  ) {}

  ngOnInit(): void {
    this.route.paramMap.subscribe((params: ParamMap) => {
      this.caregiverId = params.get('id')
      this.currentCaregiverId = this.caregiverId ? +this.caregiverId : null

      this.loadCaregiverDetails()
    })

    this.listenForEventbus()

    // Wir müssen den "aktiv" Status für den "Allgemeines" Tab manuell setzen.
    // Wir haben unter "Allgemeines" mehrere Tabs zur Unterteilung.
    // Da die URL aber "/caregivers/ID" ist, würde er den Aktivstatus nicht setzen,
    // da wir ein exact: true für den routerLinkActiveOptions drin haben.
    // Ansonsten würde er ja überall den "Allgemeines" Tab als aktiv anzeigen.
    // Deswegen müssen wir manuell nach den passenden URLs prüfen.
    this.setDefaultTabAsActive(this.router.routerState.snapshot.url)
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.setDefaultTabAsActive(event.url)
      }
    })

    this.helperService.dependencies$.subscribe((data: any) => {
      this.caregivers_active = data['caregivers_active']
      this.caregivers_deactivated = data['caregivers_deactivated']
    })

    this.keyListener = this.renderer.listen('window', 'keydown', (event) => {
      if (event.key === 'ArrowRight' && event.altKey) {
        event.preventDefault()
        this.goToNextCaregiver('next')
      }

      if (event.key === 'ArrowLeft' && event.altKey) {
        event.preventDefault()
        this.goToNextCaregiver('previous')
      }
    })
  }

  ngOnDestroy(): void {
    this.eventBusSubscription.unsubscribe()

    // Unsubscribe vom Key Listener.
    this.keyListener()
  }

  public navigateToCaregiver(event: any): void {
    if (event.value) {
      this.router.navigateByUrl(
        this.getNextCaregiverUrl(this.caregiverDetails.id, event.value)
      )
    }
  }

  /**
   * Suchen uns die nächste BK in der Liste zu der wir springen wollen.
   */
  public goToNextCaregiver(type: 'next' | 'previous'): void {
    if (!this.caregivers_active && !this.caregivers_deactivated) {
      return
    }

    let caregivers = this.caregiverDetails.deleted_at
      ? this.caregivers_deactivated
      : this.caregivers_active

    const currentIndex = caregivers.findIndex(
      (caregiver: any) => caregiver.id === this.caregiverDetails.id
    )

    if (currentIndex !== null) {
      const nextIndex = type === 'next' ? currentIndex + 1 : currentIndex - 1
      let nextCaregiver = null

      if (caregivers[nextIndex]) {
        nextCaregiver = caregivers[nextIndex]
      } else {
        // Falls es den nächsten Index nicht gibt, schauen wir, ob wir den ersten oder letzten Wert holen müssen.
        nextCaregiver =
          type === 'next' ? caregivers[0] : caregivers[caregivers.length - 1]
      }

      this.router.navigateByUrl(
        this.getNextCaregiverUrl(this.caregiverDetails.id, nextCaregiver.id)
      )
    }
  }

  /**
   * Der Name der nächsten BK für den Tooltip.
   */
  public getNextCaregiverName(type: 'next' | 'previous'): string {
    if (!this.caregivers_active && !this.caregivers_deactivated) {
      return ''
    }

    let caregivers = this.caregiverDetails.deleted_at
      ? this.caregivers_deactivated
      : this.caregivers_active

    const currentIndex = caregivers.findIndex(
      (caregiver: any) => caregiver.id === this.caregiverDetails.id
    )

    if (currentIndex !== null) {
      const nextIndex = type === 'next' ? currentIndex + 1 : currentIndex - 1
      let nextCaregiver = null

      if (caregivers[nextIndex]) {
        nextCaregiver = caregivers[nextIndex]
      } else {
        // Falls es den nächsten Index nicht gibt, schauen wir, ob wir den ersten oder letzten Wert holen müssen.
        nextCaregiver =
          type === 'next' ? caregivers[0] : caregivers[caregivers.length - 1]
      }

      return nextCaregiver.full_name
    }

    return ''
  }

  public openNoticeDialog(): void {
    this.dialogService.open(NoticeDialogComponent, {
      header: 'Notiz bearbeiten',
      width: '450px',
      styleClass: 'dialog-container',
      data: {
        type: 'caregiver',
        type_id: this.caregiverDetails.id,
        notice: this.caregiverDetails.notice,
      },
    })
  }

  private setDefaultTabAsActive(url: string): void {
    this.defaultTabIsActive = [
      `/caregivers/${this.currentCaregiverId}/contract-data`,
      `/caregivers/${this.currentCaregiverId}/qualifications`,
      `/caregivers/${this.currentCaregiverId}`,
    ].includes(url)
  }

  public loadCaregiverDetails(): void {
    this.caregiverService
      .details(this.caregiverId)
      .subscribe((data: CaregiverDetailModel) => {
        this.caregiverDetails = data
        this.isEmployeed =
          this.caregiverDetails.current_status.status === 'EMPLOYMENT'

        this.titleService.setTitle(
          `${data.form_of_address} ${data.first_name} ${data.last_name}`
        )
      })
  }

  private getNextCaregiverUrl(currentId: any, nextId: any): string {
    const currentUrl = this.router.url
    let newUrl = ''

    // Auf der Startseite der BK Details ist kein "/" am Ende vorhanden.
    if (currentUrl === `/caregivers/${this.caregiverDetails.id}`) {
      newUrl = currentUrl.replace(`/${currentId}`, `/${nextId}`)
    } else {
      newUrl = currentUrl.replace(`/${currentId}/`, `/${nextId}/`)
    }

    return newUrl
  }

  private listenForEventbus(): void {
    this.eventBusSubscription = this.eventbus.subject.subscribe((event) => {
      switch (event) {
        case GlobalEvent.NoticeChanged:
        case GlobalEvent.CaregiverStatusChanged:
          this.loadCaregiverDetails()
          break
      }
    })
  }
}
