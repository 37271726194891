import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core'
import { AuthService } from '../../../../services/auth.service'
import { AccountingReturnModel } from '../../../../models/accounting/accounting-return.model'
import { AccountingReturnService } from '../../../../services/accounting-return.service'
import { DocumentService } from '../../../../services/document.service'
import { AddAccountingReturnDialogComponent } from '../../../../components/dialogs/add-accounting-return-dialog/add-accounting-return-dialog.component'
import { DialogService } from 'primeng/dynamicdialog'
import {
  EventBusService,
  GlobalEvent,
} from '../../../../services/eventbus.service'
import { Subscription } from 'rxjs'
import { HelperService } from '../../../../services/helper.service'
import { PatientService } from '../../../../services/patient.service'

@Component({
  selector: 'app-accounting-returns-created',
  templateUrl: './accounting-returns-created.component.html',
})
export class AccountingReturnsCreatedComponent implements OnInit, OnDestroy {
  @ViewChild('dt') table: ElementRef | any = null

  public data: AccountingReturnModel[] = []
  public loading = true
  public globalFilterValue = ''
  public firstPage = 0

  public correctionReasons: any = {}

  private eventBusSubscription: Subscription = new Subscription()

  constructor(
    private accountingService: AccountingReturnService,
    public authService: AuthService,
    private dialogService: DialogService,
    public patientService: PatientService,
    public documentService: DocumentService,
    private eventbus: EventBusService,
    private helperService: HelperService
  ) {}

  ngOnInit(): void {
    this.loadAccountingReturns()
    this.listenForEventbus()

    this.helperService.dependencies$.subscribe((data: any) => {
      data['accounting_return_reasons'].forEach((reason: any) => {
        this.correctionReasons[reason.value] = reason.label
      })
    })
  }

  ngOnDestroy(): void {
    this.eventBusSubscription.unsubscribe()
  }

  public openAddAccountingReturnDialog(
    accounting_return: AccountingReturnModel
  ): void {
    this.dialogService.open(AddAccountingReturnDialogComponent, {
      header: 'Rückläufer bearbeiten',
      closeOnEscape: false,
      closable: false,
      width: '820px',
      styleClass: 'dialog-container dialog-with-own-close',
      data: {
        accounting_return,
      },
    })
  }

  private listenForEventbus(): void {
    this.eventBusSubscription = this.eventbus.subject.subscribe((event) => {
      switch (event) {
        case GlobalEvent.AccountingReturnChanged:
          this.loadAccountingReturns(false)
          break
      }
    })
  }

  public filterTableGlobal(event: any): void {
    this.table && this.table.filterGlobal(event.target.value.trim(), 'contains')
  }

  public loadAccountingReturns(withLoading: boolean = true): void {
    if (withLoading) {
      this.loading = true
    }

    this.accountingService
      .loadCreated()
      .subscribe((data: AccountingReturnModel[]) => {
        this.data = data

        if (withLoading) {
          this.loading = false
        }

        setTimeout(() => {
          if (this.table) {
            const totalItems = this.table._totalRecords

            this.globalFilterValue = this.table.filters.global?.value ?? ''

            if (
              this.table._first > totalItems ||
              this.table._rows > totalItems
            ) {
              this.firstPage = 0
            }
          }
        })
      })
  }
}
