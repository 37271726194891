<form #form="ngForm" class="dialog-form">
  <div class="dialog-sidebar space-between">
    <div class="p-fluid p-formgrid p-grid without-border">
      <div class="p-field p-col-12">
        <div class="dialog-text-link">
          <span (click)="openAddDocumentDialog()">Dokument hochladen</span>
        </div>
      </div>
    </div>
    <div class="p-fluid p-formgrid p-grid without-border">
      <div class="p-field p-col-12">
        <label class="highlight-label">Hochgeladene Dokumente</label>
        <div class="dialog-no-message" *ngIf="damage.documents.length === 0">
          Keine Dokumente hochgeladen
        </div>

        <div class="document-list" *ngFor="let document of damage.documents">
          <span
            >{{ document.name }}
            <a
              [href]="documentService.getDocumentDownloadLink(document.uuid)"
              target="_blank"
              >(ansehen)</a
            ></span
          >
        </div>
      </div>
    </div>
  </div>

  <div class="dialog-form-body">
    <div class="dialog-detail-information-body">
      <div class="dialog-detail-information">
        <div class="dialog-detail-key"><span>Status</span></div>
        <div class="dialog-detail-value selectbutton-small">
          <p-selectButton
            [options]="statusOptions"
            [(ngModel)]="damage.car_damage.status"
            name="status"
            (onChange)="changeStatus()"
          >
            <ng-template let-item>
              <span [style]="{ backgroundColor: item.backgroundColor }">{{
                  item.label
                }}</span>
            </ng-template>
          </p-selectButton>
        </div>
      </div>

      <div class="dialog-detail-information">
        <div class="dialog-detail-key"><span>Schaden am</span></div>
        <div class="dialog-detail-value">
          <strong
            >{{ damage.car_damage.date | date: "dd.MM.y" }}
            {{
              damage.car_damage.mileage
                ? "bei Kilometerstand von " + damage.car_damage.mileage
                : ""
            }}</strong
          >
        </div>
      </div>
      <div class="dialog-detail-information">
        <div class="dialog-detail-key"><span>Aufgenommen am</span></div>
        <div class="dialog-detail-value">
          <strong
            >{{ damage.car_damage.created_at | date: "dd.MM.y" }} von
            {{ damage.car_damage.tracked_by.name }}</strong
          >
        </div>
      </div>
      <div class="dialog-detail-information">
        <div class="dialog-detail-key"><span>Schuld</span></div>
        <div class="dialog-detail-value">
          <strong>{{ damage.car_damage.guilt_text }}</strong>
        </div>
      </div>
      <div class="dialog-detail-information">
        <div class="dialog-detail-key"><span>Betreuungskraft</span></div>
        <div class="dialog-detail-value">
          <strong>{{ damage.car_damage.caregiver?.full_name || "-" }}</strong>
        </div>
      </div>
      <div class="dialog-detail-information">
        <div class="dialog-detail-key"><span>Bemerkung</span></div>
        <div class="dialog-detail-value">
          <strong style="white-space: pre-wrap">{{
            damage.car_damage.comment || "-"
          }}</strong>
        </div>
      </div>
    </div>
  </div>
  <div class="dialog-form-body without-padding">
    <div class="dialog-comments">
      <div
        class="dialog-comment dialog-no-comment"
        *ngIf="damage.car_damage.comments.length === 0"
      >
        Noch keine Kommentare
      </div>
      <div
        class="dialog-comment"
        *ngFor="let comment of damage.car_damage.comments"
      >
        <!-- Den Kommentar darf nur der Ersteller bearbeiten -->
        <i
          *ngIf="currentUser?.id === comment.user_id"
          class="pi pi-pencil"
          pTooltip="Bearbeiten"
          (click)="openEditMode(comment)"
        ></i>
        <strong>{{ comment.comment }}</strong>
        <span
          >{{ comment.created_from.name }} am
          {{ comment.created_at | date: "dd.MM.y, H:mm" }} Uhr</span
        >
      </div>
    </div>
  </div>
  <div class="dialog-form-footer dialog-form-footer-textarea">
    <textarea
      *ngIf="!editComment"
      id="comment"
      name="comment"
      [(ngModel)]="values.comment"
      [rows]="2"
      [required]="true"
      pInputTextarea
      #comment="ngModel"
      [class.p-invalid]="comment.invalid && (comment.dirty || comment.touched)"
    ></textarea>

    <textarea
      *ngIf="editComment"
      id="comment_edit"
      name="comment_edit"
      [(ngModel)]="values.comment"
      [rows]="2"
      [required]="true"
      pInputTextarea
    ></textarea>

    <div class="dialog-form-footer-actions">
      <i
        *ngIf="!editComment"
        class="pi pi-pencil"
        (click)="openDamageDialog()"
        pTooltip="Schaden bearbeiten"
      ></i>

      <button
        *ngIf="editComment"
        pButton
        label="Abbrechen"
        class="p-button-text button-without-padding"
        type="button"
        (click)="cancelEditMode()"
        icon="pi pi-times"
      ></button>

      <button
        *ngIf="!editComment"
        (click)="save()"
        pButton
        label="Kommentar erstellen"
        type="button"
        [disabled]="submitted"
        [icon]="submitted ? 'pi pi-spin pi-spinner' : 'pi pi-check'"
      ></button>

      <button
        *ngIf="editComment"
        (click)="update()"
        pButton
        label="Kommentar bearbeiten"
        type="button"
        [disabled]="submitted"
        [icon]="submitted ? 'pi pi-spin pi-spinner' : 'pi pi-check'"
      ></button>
    </div>
  </div>
</form>
