import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { environment } from '../../environments/environment'
import { Observable } from 'rxjs'
import {
  UserSystemModel,
  UserSystemSimpleModel,
} from '../models/user/user-system.model'
import { UserSystemFormModel } from '../models/user/user-system-form.model'
import { UserSystemDetailModel } from '../models/user/user-system-detail.model'
import { UserSystemDetailGeneralModel } from '../models/user/user-system-detail-general.model'
import { HistoryListModel } from '../models/history/history-list.model'
import { AuthSettingModel } from '../models/user/auth.model'

@Injectable({
  providedIn: 'root',
})
export class UserSystemService {
  constructor(private http: HttpClient) {}

  public list(): Observable<UserSystemModel[]> {
    return this.http.get<UserSystemModel[]>(`${environment.api}/users-system`)
  }

  public listActiveSimple(): Observable<UserSystemSimpleModel[]> {
    return this.http.get<UserSystemSimpleModel[]>(
      `${environment.api}/users-system/active/simple`
    )
  }

  public loadThcData(mondayDate: string, users: any[]): Observable<any[]> {
    return this.http.get<any[]>(`${environment.api}/users-system/thc-dates`, {
      params: {
        monday_date: mondayDate,
        user_ids: JSON.stringify(users),
      },
    })
  }

  public loadDatesForDay(id: string | null, date: string): Observable<string[]> {
    return this.http.get<string[]>(
      `${environment.api}/users-system/${id}/dates-for-day`,
      {
        params: {
          date,
        },
      }
    )
  }

  public load(id: string | null): Observable<UserSystemDetailGeneralModel> {
    return this.http.get<UserSystemDetailGeneralModel>(
      `${environment.api}/users-system/${id}`
    )
  }

  public loadForEditMode(id: string | null): Observable<UserSystemFormModel> {
    return this.http.get<UserSystemFormModel>(
      `${environment.api}/users-system/${id}/edit`
    )
  }

  public history(id: string | null): Observable<HistoryListModel[]> {
    return this.http.get<HistoryListModel[]>(
      `${environment.api}/users-system/${id}/history`
    )
  }

  public details(id: string | null): Observable<UserSystemDetailModel> {
    return this.http.get<UserSystemDetailModel>(
      `${environment.api}/users-system/${id}/details`
    )
  }

  public update(data: UserSystemFormModel, individual_permission_ids: number[] = []): Observable<UserSystemFormModel> {
    return this.http.put<UserSystemFormModel>(
      `${environment.api}/users-system/${data.id}`,
      {
        ...data,
      }
    )
  }

  public updateOwnData(data: UserSystemModel): Observable<[]> {
    return this.http.put<[]>(`${environment.api}/users-system/own-data`, data)
  }

  public updateOwnPassword(data: AuthSettingModel): Observable<[]> {
    return this.http.put<[]>(`${environment.api}/own-password`, data)
  }

  public store(data: UserSystemFormModel, individual_permission_ids: number[] = []): Observable<UserSystemFormModel> {
    return this.http.post<UserSystemFormModel>(
      `${environment.api}/users-system`,
      {
        ...data,
      }
    )
  }

  public deactivate(id: string | null): Observable<UserSystemDetailModel> {
    return this.http.put<UserSystemDetailModel>(
      `${environment.api}/users-system/${id}/deactivate`,
      {}
    )
  }

  public activate(id: string | null): Observable<UserSystemDetailModel> {
    return this.http.put<UserSystemDetailModel>(
      `${environment.api}/users-system/${id}/activate`,
      {}
    )
  }

  public getCalendarDatesPdfUrl(date: string, users: any[]): string {
    const userIds = JSON.stringify(users)

    return `${environment.api}/users-system/thc-dates/pdf?date=${date}&user_ids=${userIds}`
  }
}
