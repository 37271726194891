<form #form="ngForm" class="dialog-form">
  <div class="dialog-form-body">
    <div class="p-fluid p-formgrid p-grid">
      <div class="p-field p-col-12">
        <label for="budget_value">Budget *</label>
        <p-inputNumber
          mode="currency"
          currency="EUR"
          locale="de-DE"
          inputId="budget_value"
          name="budget_value"
          [(ngModel)]="values.budget_new"
          [required]="true"
          #budget="ngModel"
          [class.p-invalid]="budget.invalid && (budget.dirty || budget.touched)"
        ></p-inputNumber>
        <div
          *ngIf="budget.invalid && (budget.dirty || budget.touched)"
          class="p-invalid"
        >
          <div *ngIf="budget.errors?.required">
            <small>Bitte geben Sie einen Wert ein</small>
          </div>
        </div>
      </div>
    </div>

    <div class="p-fluid p-formgrid p-grid">
      <div class="p-field p-col-12">
        <label for="comment">Bemerkung *</label>
        <textarea
          id="comment"
          name="comment"
          [rows]="3"
          pInputTextarea
          [required]="true"
          [(ngModel)]="values.comment"
          #comment="ngModel"
          [class.p-invalid]="
            comment.invalid && (comment.dirty || comment.touched)
          "
        ></textarea>
        <div
          *ngIf="comment.invalid && (comment.dirty || comment.touched)"
          class="p-invalid"
        >
          <div *ngIf="comment.errors?.required">
            <small>Bitte geben Sie eine Bemerkung ein</small>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="dialog-form-footer">
    <i></i>
    <button
      pButton
      label="Budget ändern"
      type="submit"
      (click)="save()"
      [disabled]="submitted"
      [icon]="submitted ? 'pi pi-spin pi-spinner' : 'pi pi-check'"
    ></button>
  </div>
</form>
