import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { environment } from '../../environments/environment'
import { Observable } from 'rxjs'

@Injectable({
  providedIn: 'root',
})
export class CaregiverPlanningService {
  constructor(private http: HttpClient) {}

  public getDriveTimeForDay(caregiverId: any, persplanId: any, time: any, date: any): Observable<any>  {
    return this.http.get<any>(`${environment.api}/caregiver-planning/${caregiverId}/get-drive-time-for-day`, {
      params: {
        persplanId,
        time,
        date,
      }
    })
  }

  public load(
    caregiverId: string | null,
    month: string,
    year: string
  ): Observable<any> {
    return this.http.get<any>(
      `${environment.api}/caregiver-planning/${caregiverId}`,
      {
        params: {
          month,
          year,
        },
      }
    )
  }

  public loadAnalysesForMonth(
    caregiverId: string | null,
    month: string,
    year: string
  ): Observable<any> {
    return this.http.get<any>(
      `${environment.api}/caregiver-planning/${caregiverId}/analyses-for-month`,
      {
        params: {
          month,
          year,
        },
      }
    )
  }

  public loadAmountPatients(
    caregiverId: string | null,
    month: string,
    year: string
  ): Observable<any> {
    return this.http.get<any>(
      `${environment.api}/caregiver-planning/${caregiverId}/amount-patients`,
      {
        params: {
          month,
          year,
        },
      }
    )
  }
}
