import { Component, OnInit, ViewChild } from '@angular/core'
import { ActivatedRoute, ParamMap, Router } from '@angular/router'
import { ToastService } from 'src/app/services/toast.service'
import { DirtyComponent } from 'src/app/guards/dirty-check.guard'
import { NgForm } from '@angular/forms'
import { ConfirmationService } from 'primeng/api'
import { TitleService } from 'src/app/services/title.service'
import { PermissionsNamespaced } from '../../../../models/permission/permission.model'
import { RolePermissionService } from '../../../../services/role-permission.service'
import { RoleModel } from '../../../../models/permission/role.model'
import { HttpErrorResponse } from '@angular/common/http'
import { StatusCodes } from 'http-status-codes'

@Component({
  selector: 'app-roles-form',
  templateUrl: './roles-form.component.html',
  styleUrls: ['./roles-form.component.scss'],
})
export class RolesFormComponent implements OnInit, DirtyComponent {
  @ViewChild('form', { static: true }) form!: NgForm

  public isDirty = false
  public isEditMode = false
  public roleId: string | null = null
  public submitted = false
  public loading = false

  public values = {
    name: '',
    comment: '',
    permission_ids: [],
  }

  public permissions: PermissionsNamespaced = new PermissionsNamespaced()

  constructor(
    private rolePermissionService: RolePermissionService,
    private router: Router,
    private route: ActivatedRoute,
    private toastService: ToastService,
    private confirmationService: ConfirmationService,
    private titleService: TitleService
  ) {}

  ngOnInit(): void {
    this.loadData()

    this.titleService.setTitle(
      this.isEditMode ? 'Rolle bearbeiten' : 'Neue Rolle'
    )

    this.form.valueChanges?.subscribe(() => {
      if (!this.form.pristine) {
        this.isDirty = true
      }
    })
  }

  canDeactivate(): boolean {
    return this.isDirty
  }

  public save(): void {
    if (!this.form.form.valid) {
      this.submitted = false
      this.form.form.markAllAsTouched()
      this.toastService.error('Bitte füllen Sie alle Pflichtfelder aus')

      return
    }

    this.submitted = true

    const subscription = this.isEditMode
      ? this.rolePermissionService.update(this.values)
      : this.rolePermissionService.store(this.values)

    subscription.subscribe(
      (data: RoleModel) => {
        this.isDirty = false
        this.router.navigate(['/roles', data.id])
      },
      (error: HttpErrorResponse) => {
        if (error.status === StatusCodes.UNPROCESSABLE_ENTITY) {
          this.toastService.error('Bitte füllen Sie alle Pflichtfelder aus')
        } else {
          this.toastService.error(
            'Etwas ist schief gelaufen...',
            'Bitte wenden Sie sich an den Support'
          )
        }

        this.submitted = false
      }
    )
  }

  private loadRole(): void {
    this.rolePermissionService
      .role(this.roleId)
      .subscribe((data: RoleModel) => {
        this.values = data

        this.loading = false
      })
  }

  private loadData(): void {
    this.loading = true

    this.route.paramMap.subscribe((params: ParamMap) => {
      this.roleId = params.get('id')
      this.isEditMode = params.has('id')

      this.rolePermissionService
        .permissions()
        .subscribe((data: PermissionsNamespaced) => {
          this.permissions = data

          if (this.isEditMode) {
            this.loadRole()
          } else {
            this.loading = false
          }
        })
    })
  }
}
