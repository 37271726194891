<app-loader [loading]="loading"></app-loader>

<div class="content" *ngIf="!loading">
  <header class="content-header">
    <span>
      <i class="pi pi-flag"></i>
      Veto Einträge
    </span>

    <div class="content-header-buttons">
      <button
        pButton
        (click)="openVetoDialog()"
        label="Neuer Eintrag"
        type="button"
        class="p-button-outlined"
        icon="pi pi-plus"
        iconPos="left"
      ></button>

      <div class="table-search-container" *ngIf="vetos.length > 0">
        <div class="p-input-icon-left">
          <i class="pi pi-search"></i>
          <input
            pInputText
            (input)="filterTableGlobal($event)"
            placeholder="Suche..."
            [value]="globalFilterValue"
          />
        </div>
      </div>
    </div>
  </header>
</div>

<div class="content" style="margin: 0" *ngIf="!loading">
  <div class="content-body auto-height" *ngIf="vetos.length === 0">
    <span class="content-no-message">Keine Veto Einträge vorhanden</span>
  </div>
  <div
    class="content-body without-padding auto-height"
    *ngIf="vetos.length > 0"
  >
    <p-table
      #dt
      [value]="vetos"
      [rowHover]="true"
      [globalFilterFields]="[
        'patient.full_name',
        'user.first_name',
        'user.last_name',
        'comment'
      ]"
    >
      <ng-template pTemplate="header">
        <tr class="table-headline-highlight">
          <th pSortableColumn="patient_id">
            Patient <p-sortIcon field="patient_id"></p-sortIcon>
          </th>
          <th style="width: 200px" pSortableColumn="user.first_name">
            Erstellt von
            <p-sortIcon field="user.first_name"></p-sortIcon>
          </th>
          <th style="width: 200px" pSortableColumn="created_at">
            Erstellt am <p-sortIcon field="created_at"></p-sortIcon>
          </th>
          <th>Kommentar</th>
          <th style="width: 50px"></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-veto>
        <tr>
          <td>
            <a routerLink="/patients/{{ veto.patient_id }}">
              {{ veto.patient.first_name }} {{ veto.patient.last_name }}
            </a>
          </td>
          <td>{{ veto.user.first_name }} {{ veto.user.last_name }}</td>
          <td>{{ veto.created_at | date: "dd.MM.y" }}</td>
          <td>
            <p class="history-text">{{ veto.comment }}</p>
          </td>
          <td class="table-action">
            <i (click)="openVetoDialog(veto)" class="pi pi-pencil"></i>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
