import { Injectable } from '@angular/core'
import { HttpClient, HttpParams } from '@angular/common/http'
import { Observable } from 'rxjs'
import { environment } from '../../environments/environment'

@Injectable({
  providedIn: 'root',
})
export class GlobalAnalysesService {
  constructor(private http: HttpClient) {}

  public caregivers(
    month: string,
    year: string,
    selectedPlanner: number,
    isSorted: boolean
  ): Observable<any> {
    return this.http.get<any>(`${environment.api}/analyses/caregivers`, {
      params: {
        month,
        year,
        selectedPlanner,
        isSorted,
      },
    })
  }

  public plannings(year: string): Observable<any> {
    return this.http.get<any>(`${environment.api}/analyses/plannings`, {
      params: {
        year,
      },
    })
  }

  public recommendations(filters: any): Observable<any> {
    return this.http.get<any>(`${environment.api}/analyses/recommendations`, {
      params: {
        ...filters,
      },
    })
  }

  public phoneCalls(filters: any): Observable<any> {
    return this.http.get<any>(`${environment.api}/analyses/phone-calls`, {
      params: {
        selected: filters.selected,
        dates: JSON.stringify(filters.dates),
      },
    })
  }

  public progress(year: string): Observable<any> {
    return this.http.get<any>(`${environment.api}/analyses/progress`, {
      params: {
        year,
      },
    })
  }

  public budgets(
    month: string,
    year: string,
    onlyMinusBudgets: boolean
  ): Observable<any> {
    return this.http.get<any>(`${environment.api}/analyses/budgets`, {
      params: {
        onlyMinusBudgets,
        year,
        month,
      },
    })
  }

  public loadAverageHourPerPatient(
    month: string,
    year: string
  ): Observable<any> {
    return this.http.get<any>(
      `${environment.api}/analyses/average-hour-per-patient`,
      {
        params: {
          year,
          month,
        },
      }
    )
  }

  public loadOverUnderTimeFromLastMonth(
    caregiverIds: any,
    month: string,
    year: string
  ): Observable<any> {
    return this.http.get<any>(
      `${environment.api}/analyses/undertime-last-month`,
      {
        params: {
          ids: JSON.stringify(caregiverIds),
          year,
          month,
        },
      }
    )
  }

  public exportCaregiversUrls(
    month: string,
    year: string,
    isSorted: boolean,
    selectedPlanner: number
  ): string {
    return `${environment.api}/analyses/caregivers/export?year=${year}&month=${month}&selectedPlanner=${selectedPlanner}&isSorted=${isSorted}`
  }
}
