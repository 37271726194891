import { Injectable } from '@angular/core'
import { HttpClient, HttpParams } from '@angular/common/http'
import { Observable } from 'rxjs'
import { environment } from '../../environments/environment'
import { AccountingReturnModel } from '../models/accounting/accounting-return.model'

@Injectable({
  providedIn: 'root',
})
export class InvoiceService {
  constructor(private http: HttpClient) {}

  // TODO: invoice model erstellen
  public loadDrafts(): Observable<any[]> {
    return this.http.get<AccountingReturnModel[]>(
      `${environment.api}/accounting/invoices/drafts`
    )
  }

  // TODO: invoice model erstellen
  public loadDraftsAppointments(): Observable<any[]> {
    return this.http.get<AccountingReturnModel[]>(
      `${environment.api}/accounting/invoices/drafts/appointments`
    )
  }

  public searchAppointments(query: string): Observable<any[]> {
    return this.http.get<any[]>(
      `${environment.api}/accounting/invoices/appointments/search?query=${query}`
    )
  }

  // TODO: invoice model erstellen
  public loadCreditAppointments(): Observable<any[]> {
    return this.http.get<AccountingReturnModel[]>(
      `${environment.api}/accounting/invoices/credits/appointments`
    )
  }

  // TODO: invoice model erstellen
  public loadStornoAppointments(): Observable<any[]> {
    return this.http.get<AccountingReturnModel[]>(
      `${environment.api}/accounting/invoices/storno/appointments`
    )
  }

  // TODO: invoice model erstellen
  public loadReadyKmInvoices(): Observable<any[]> {
    return this.http.get<AccountingReturnModel[]>(
      `${environment.api}/accounting/invoices/ready/km`
    )
  }

  // TODO: invoice model erstellen
  public loadReadyConsultingInvoices(): Observable<any[]> {
    return this.http.get<AccountingReturnModel[]>(
      `${environment.api}/accounting/invoices/ready/consulting`
    )
  }

  // TODO: invoice model erstellen
  public loadOpenedKm(): Observable<any[]> {
    return this.http.get<AccountingReturnModel[]>(
      `${environment.api}/accounting/invoices/opened/km`
    )
  }

  // TODO: invoice model erstellen
  public loadOpenedConsulting(): Observable<any[]> {
    return this.http.get<AccountingReturnModel[]>(
      `${environment.api}/accounting/invoices/opened/consulting`
    )
  }

  // TODO: invoice model erstellen
  public loadOpenedAppointments(filters: any): Observable<any[]> {
    return this.http.get<any[]>(
      `${environment.api}/accounting/invoices/opened/appointments`,
      {
        params: {
          ...filters,
          accounting_type: JSON.stringify(filters.accounting_type),
        },
      }
    )
  }

  // TODO: invoice model erstellen
  public loadFinishedAppointments(filters: any): Observable<any[]> {
    return this.http.get<AccountingReturnModel[]>(
      `${environment.api}/accounting/invoices/finished/appointments`,
      {
        params: {
          ...filters,
          accounting_type: JSON.stringify(filters.accounting_type),
        },
      }
    )
  }

  // TODO: invoice model erstellen
  public loadFinishedKm(): Observable<any[]> {
    return this.http.get<AccountingReturnModel[]>(
      `${environment.api}/accounting/invoices/finished/km`
    )
  }

  // TODO: invoice model erstellen
  public loadFinishedConsulting(): Observable<any[]> {
    return this.http.get<AccountingReturnModel[]>(
      `${environment.api}/accounting/invoices/finished/consulting`
    )
  }

  // TODO: invoice model erstellen
  public loadStornoKm(): Observable<any[]> {
    return this.http.get<AccountingReturnModel[]>(
      `${environment.api}/accounting/invoices/storno/km`
    )
  }

  // TODO: invoice model erstellen
  public loadStornoConsulting(): Observable<any[]> {
    return this.http.get<AccountingReturnModel[]>(
      `${environment.api}/accounting/invoices/storno/consulting`
    )
  }

  // TODO: invoice model erstellen
  public loadCreditsKm(): Observable<any[]> {
    return this.http.get<AccountingReturnModel[]>(
      `${environment.api}/accounting/invoices/credits/km`
    )
  }

  public loadReminders(invoiceId: any): Observable<any> {
    return this.http.get<any>(
      `${environment.api}/accounting/invoices/reminders/${invoiceId}`
    )
  }

  // TODO: invoice model erstellen
  public loadCreditsConsulting(): Observable<any[]> {
    return this.http.get<AccountingReturnModel[]>(
      `${environment.api}/accounting/invoices/credits/consulting`
    )
  }

  public getPdfPreviewLink(values: any): string {
    const params = new HttpParams({ fromObject: values }).toString()

    return `${environment.api}/accounting/invoices/preview/pdf/${values.id}?${params}`
  }

  public getMailPreviewLink(values: any): string {
    const params = new HttpParams({ fromObject: values }).toString()

    return `${environment.api}/accounting/invoices/preview/mail/${values.id}?${params}`
  }

  public getReminderMailPreviewLink(invoiceId: any, values: any): string {
    const params = new HttpParams({ fromObject: values }).toString()

    return `${environment.api}/accounting/invoices/reminder/preview/mail/${invoiceId}?${params}`
  }

  public getReminderMailLink(invoiceReminderId: any): string {
    return `${environment.api}/accounting/invoices/reminder-mail/${invoiceReminderId}`
  }

  public getReminderPdfPreviewLink(invoiceId: any, values: any): string {
    const params = new HttpParams({ fromObject: values }).toString()

    return `${environment.api}/accounting/invoices/reminder/preview/pdf/${invoiceId}?${params}`
  }

  public update(invoiceId: string, values: any): Observable<any> {
    return this.http.put<any>(
      `${environment.api}/accounting/invoices/${invoiceId}`,
      values
    )
  }

  public stornoInvoice(
    invoiceId: string,
    reason: string,
    with_new_draft = false
  ): Observable<any> {
    return this.http.put<any>(
      `${environment.api}/accounting/invoices/${invoiceId}/storno`,
      { reason, with_new_draft }
    )
  }

  public stornoProofStatus(invoiceId: string, reason: string): Observable<any> {
    return this.http.put<any>(
      `${environment.api}/accounting/invoices/${invoiceId}/storno-proof-status`,
      { reason }
    )
  }

  public resetCompletedStatus(invoiceId: string): Observable<any> {
    return this.http.put<any>(
      `${environment.api}/accounting/invoices/${invoiceId}/reset-completed-status`,
      {}
    )
  }

  public markInvoiceAsCompleted(invoiceId: string): Observable<any> {
    return this.http.put<any>(
      `${environment.api}/accounting/invoices/${invoiceId}/mark-as-completed`,
      {}
    )
  }

  public resetReadyInvoice(invoiceId: string): Observable<any> {
    return this.http.put<any>(
      `${environment.api}/accounting/invoices/${invoiceId}/reset-ready`,
      {}
    )
  }

  public deleteReadyInvoice(invoiceId: string): Observable<any> {
    return this.http.put<any>(
      `${environment.api}/accounting/invoices/${invoiceId}/delete-ready`,
      {}
    )
  }

  public updateKmInvoice(values: any): Observable<any> {
    return this.http.put<any>(
      `${environment.api}/accounting/invoices/km`,
      values
    )
  }

  public createInvoice(invoiceId: string, values: any): Observable<any> {
    return this.http.put<any>(
      `${environment.api}/accounting/invoices/${invoiceId}/create`,
      values
    )
  }

  public createOpenedAppointmentInvoice(
    data: any,
    invoiceValues: any
  ): Observable<any> {
    return this.http.post<any>(
      `${environment.api}/accounting/invoices/appointment`,
      {
        ...data,
        invoiceValues,
      }
    )
  }

  public createReminder(invoiceId: any, values: any): Observable<any> {
    return this.http.post<any>(
      `${environment.api}/accounting/invoices/${invoiceId}/reminder`,
      {
        ...values,
      }
    )
  }

  public storeKmInvoice(values: any): Observable<any> {
    return this.http.post<any>(
      `${environment.api}/accounting/invoices/km`,
      values
    )
  }

  public addPayment(invoiceId: number, payment: string): Observable<any> {
    return this.http.post<any>(
      `${environment.api}/accounting/invoices/${invoiceId}/add-payment`,
      { payment }
    )
  }

  public updatePayment(
    partPaymentId: number,
    payment: string
  ): Observable<any> {
    return this.http.put<any>(
      `${environment.api}/accounting/invoices/${partPaymentId}/update-payment`,
      { payment }
    )
  }

  public saveInternComment(
    invoiceId: number,
    comment: string
  ): Observable<any> {
    return this.http.put<any>(
      `${environment.api}/accounting/invoices/${invoiceId}/intern-comment`,
      { comment }
    )
  }

  public openInvoice(invoiceId: string, values: any): Observable<any> {
    return this.http.put<any>(
      `${environment.api}/accounting/invoices/${invoiceId}/open`,
      values
    )
  }
}
