import { Component, OnInit } from '@angular/core'
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog'
import { EventBusService, GlobalEvent } from 'src/app/services/eventbus.service'
import { ToastService } from 'src/app/services/toast.service'
import { NoticeService } from '../../../services/notice.service'
import { ConfirmationService } from 'primeng/api'
import { ProofsService } from '../../../services/proofs.service'

@Component({
  selector: 'app-change-patient-budget-proof-dialog',
  templateUrl: './change-patient-budget-proof-dialog.component.html',
})
export class ChangePatientBudgetProofDialogComponent implements OnInit {
  submitted = false

  public loading = true

  public budget = ''
  public budgetOptions: any = []

  constructor(
    private ref: DynamicDialogRef,
    private toastService: ToastService,
    private config: DynamicDialogConfig,
    private noticeService: NoticeService,
    private eventbus: EventBusService,
    private proofService: ProofsService
  ) {}

  public ngOnInit(): void {
    this.budget = this.config.data.item.budget

    this.proofService
      .loadBudgetsForPatient(
        this.config.data.item.patient_id,
        this.config.data.item.month,
        this.config.data.item.year
      )
      .subscribe((response: any) => {
        this.budgetOptions = response

        setTimeout(() => {
          this.loading = false
        }, 200)
      })
  }

  /**
   * Speichert eine Notiz.
   */
  public save(): void {
    this.submitted = true

    this.proofService
      .changeUploadedItemBudget(this.config.data.item.id, this.budget)
      .subscribe(
        (response: any) => {
          this.submitted = false
          this.ref.close(response)
        },
        () => {
          this.toastService.error(
            'Etwas ist schiefgelaufen...',
            'Bitte wenden Sie sich an den Support'
          )

          this.submitted = false
        }
      )
  }
}
