import { Component, ElementRef, OnInit, ViewChild } from '@angular/core'
import { UserSystemService } from '../../../../services/user-system.service'
import { UserSystemModel } from '../../../../models/user/user-system.model'
import { TitleService } from 'src/app/services/title.service'
import { HttpErrorResponse } from '@angular/common/http'
import { StatusCodes } from 'http-status-codes'
import { ToastService } from '../../../../services/toast.service'
import { AuthService } from '../../../../services/auth.service'
import { environment } from '../../../../../environments/environment'

@Component({
  selector: 'app-users-list',
  templateUrl: './users-list.component.html',
})
export class UsersListComponent implements OnInit {
  @ViewChild('dt') table: ElementRef | any = null

  public isSuperAdmin = false

  public loading = false
  public users: UserSystemModel[] = []
  public globalFilterValue = ''

  public hasPermission = true

  constructor(
    private toastService: ToastService,
    private userSystemService: UserSystemService,
    private authService: AuthService,
    private titleService: TitleService
  ) {}

  ngOnInit(): void {
    this.isSuperAdmin = this.authService.isSuperAdmin()

    this.hasPermission = this.authService.can(
      'Verwaltung.Systembenutzer verwalten'
    )

    if (this.hasPermission) {
      this.loadUsers()
    }

    this.titleService.setTitle('Benutzer')
  }

  public loginUsingId(id: number): void {
    if (window.confirm('Wirklich als Benutzer einloggen?')) {
      this.authService.setImpersonation()

      this.authService.loginUsingId(id).subscribe(() => {
        window.location.href = '/'
      })
    }
  }

  public filterTableGlobal(event: any): void {
    this.table && this.table.filterGlobal(event.target.value.trim(), 'contains')
  }

  private loadUsers(): void {
    this.loading = true

    this.userSystemService.list().subscribe(
      (data: UserSystemModel[]) => {
        this.users = data

        this.loading = false

        setTimeout(() => {
          this.table && this.table.filterGlobal('', 'contains')
        })
      },
      (error: HttpErrorResponse) => {
        if (error.status === StatusCodes.FORBIDDEN) {
          this.toastService.error(
            'Keine Berechtigung',
            'Es fehlen Rechte für diese Ansicht'
          )

          this.hasPermission = false
        } else {
          this.toastService.error(
            'Etwas ist schief gelaufen...',
            'Bitte wenden Sie sich an den Support'
          )
        }

        this.loading = false
      }
    )
  }
}
