<form #form="ngForm" class="dialog-form">
  <div
    class="dialog-sidebar secondary-dialog-sidebar middle-dialog-sidebar"
    *ngIf="values.status === 'INACTIVE'"
  >
    <span class="content-sub-headline">Einsätze zur Umplanung</span>

    <small class="color-gray" *ngIf="!values.employed_until_string"
      >Bitte Datum auswählen</small
    >

    <small
      class="color-gray"
      *ngIf="values.employed_until_string && appointments.length === 0"
      >Keine Einsätze vorhanden</small
    >

    <p-table
      *ngIf="appointments.length > 0"
      #dt
      [value]="appointments"
      [rowHover]="true"
      [autoLayout]="true"
      [globalFilterFields]="[]"
    >
      <ng-template pTemplate="header">
        <tr>
          <th style="min-width: 150px" pSortableColumn="patient.last_name">
            Patient <p-sortIcon field="patient.last_name"></p-sortIcon>
          </th>
          <th pSortableColumn="real_from">
            Datum <p-sortIcon field="real_from"></p-sortIcon>
          </th>
          <th>Uhrzeit</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-appointment>
        <tr>
          <td>
            <a
              class="color-main1"
              target="_blank"
              href="/patients/{{ appointment.patient.id }}/planning"
            >
              {{ appointment.patient.full_name }}
            </a>
          </td>
          <td>
            {{ appointment.real_from | date: "dd.MM.y" }} ({{
              appointment.weekday
            }})
          </td>
          <td>{{ appointment.real_from_h }} - {{ appointment.real_to_h }}</td>
        </tr>
      </ng-template>
    </p-table>
  </div>

  <div class="dialog-form-body">
    <div class="p-fluid p-formgrid p-grid" *ngIf="!isEdit">
      <!-- Status -->
      <div class="p-field p-col-12">
        <div class="p-formgroup-inline">
          <div
            class="p-field-radiobutton"
            *ngIf="caregiver.current_status.status === 'EMPLOYMENT'"
          >
            <p-radioButton
              label="Inaktiv"
              name="type"
              [(ngModel)]="values.status"
              value="INACTIVE"
              required
            ></p-radioButton>
          </div>
          <div
            class="p-field-radiobutton"
            *ngIf="caregiver.current_status.status !== 'EMPLOYMENT' && caregiver.current_status.status !== 'EDIT'"
          >
            <p-radioButton
              label="In Bearbeitung"
              name="type"
              [(ngModel)]="values.status"
              value="EDIT"
              required
            ></p-radioButton>
          </div>
          <div
            class="p-field-radiobutton"
            *ngIf="
              caregiver.current_status.status !== 'EMPLOYMENT' &&
              caregiver.current_status.status !== 'JOB_INTERVIEW' &&
              caregiver.current_status.status !== 'HOSPITATION'
            "
          >
            <p-radioButton
              label="Bewerbungsgespräch"
              name="type"
              [(ngModel)]="values.status"
              value="JOB_INTERVIEW"
              required
            ></p-radioButton>
          </div>
          <div
            class="p-field-radiobutton"
            *ngIf="caregiver.current_status.status !== 'EMPLOYMENT'"
          >
            <p-radioButton
              label="Absage / KI"
              name="type"
              [(ngModel)]="values.status"
              value="CANCELED"
              required
            ></p-radioButton>
          </div>
          <div
            class="p-field-radiobutton"
            *ngIf="
              caregiver.current_status.status !== 'HOSPITATION' &&
              caregiver.current_status.status !== 'HOSPITATION_STARTED' &&
              caregiver.current_status.status !== 'EMPLOYMENT'
            "
          >
            <p-radioButton
              label="Hospitation starten"
              name="type"
              [(ngModel)]="values.status"
              value="HOSPITATION_STARTED"
              required
            ></p-radioButton>
          </div>
          <div
            class="p-field-radiobutton"
            *ngIf="
              caregiver.current_status.status === 'HOSPITATION_STARTED'
            "
          >
            <p-radioButton
              label="Hospitation"
              name="type"
              [(ngModel)]="values.status"
              value="HOSPITATION"
              required
            ></p-radioButton>
          </div>
          <div
            class="p-field-radiobutton"
            *ngIf="caregiver.current_status.status !== 'EMPLOYMENT'"
          >
            <p-radioButton
              label="Anstellung"
              name="type"
              [(ngModel)]="values.status"
              value="EMPLOYMENT"
              required
            ></p-radioButton>
          </div>
        </div>
      </div>
    </div>

    <div
      class="p-fluid p-formgrid p-grid without-border"
      *ngIf="values.status === 'INACTIVE'"
    >
      <!-- Inaktiv seit -->
      <div class="p-field p-col-6">
        <label for="inactive_since_string">Inaktiv seit *</label>
        <p-calendar
          [firstDayOfWeek]="1"
          [showWeek]="true"
          inputId="inactive_since_string"
          name="inactive_since_string"
          dateFormat="dd.mm.yy"
          appendTo="body"
          [(ngModel)]="values.inactive_since_string"
          dataType="string"
          [showIcon]="true"
          [required]="true"
          #inactive_since_string="ngModel"
          [class.p-invalid]="
            inactive_since_string.invalid &&
            (inactive_since_string.dirty || inactive_since_string.touched)
          "
        ></p-calendar>
      </div>

      <!-- Angestellt bis -->
      <div class="p-field p-col-6">
        <label for="employed_until_string">Angestellt bis *</label>
        <p-calendar
          (onSelect)="dayEmployedUntilSelected($event)"
          (onBlur)="dayEmployedUntilSelected($event)"
          [firstDayOfWeek]="1"
          [showWeek]="true"
          inputId="employed_until_string"
          name="employed_until_string"
          dateFormat="dd.mm.yy"
          appendTo="body"
          [(ngModel)]="values.employed_until_string"
          dataType="string"
          [showIcon]="true"
          [required]="true"
          #employed_until_string="ngModel"
          [class.p-invalid]="
            employed_until_string.invalid &&
            (employed_until_string.dirty || employed_until_string.touched)
          "
        ></p-calendar>
      </div>
    </div>

    <div
      class="p-fluid p-formgrid p-grid without-border"
      *ngIf="values.status === 'JOB_INTERVIEW'"
    >
      <!-- Bewerbungsgespräch am -->
      <div class="p-field p-col-6">
        <label for="job_interview_at_string">Bewerbungsgespräch am *</label>
        <p-calendar
          [firstDayOfWeek]="1"
          [showWeek]="true"
          inputId="job_interview_at_string"
          name="job_interview_at_string"
          [(ngModel)]="values.job_interview_at_string"
          dateFormat="dd.mm.yy,"
          [showIcon]="true"
          [showTime]="true"
          hourFormat="24"
          appendTo="body"
          dataType="string"
          [required]="true"
          #job_interview_at_string="ngModel"
          [class.p-invalid]="
            job_interview_at_string.invalid &&
            (job_interview_at_string.dirty || job_interview_at_string.touched)
          "
        ></p-calendar>
      </div>

      <div class="p-field p-col-6">
        <label for="job_interview_with">Bewerbungsgespräch mit</label>
        <input
          id="job_interview_with"
          name="job_interview_with"
          [(ngModel)]="values.job_interview_with"
          type="text"
          pInputText
        />
      </div>
    </div>

    <div
      class="p-fluid p-formgrid p-grid without-border"
      *ngIf="values.status === 'CANCELED'"
    >
      <!-- Wer hat abgesagt? -->
      <div class="p-field p-col-6">
        <label>Abgesagt von *</label>
        <div class="p-formgroup-inline">
          <div class="p-field-radiobutton">
            <p-radioButton
              label="Betreuungskraft"
              name="canceled_from_type"
              [(ngModel)]="values.canceled_from_type"
              value="CAREGIVER"
              #canceled_from_type="ngModel"
              required
            ></p-radioButton>
          </div>
          <div class="p-field-radiobutton">
            <p-radioButton
              label="SBG"
              name="canceled_from_type"
              [(ngModel)]="values.canceled_from_type"
              value="USER_SYSTEM"
              #canceled_from_type="ngModel"
              required
            ></p-radioButton>
          </div>
        </div>
        <div
          *ngIf="
            canceled_from_type.invalid &&
            (canceled_from_type.dirty || canceled_from_type.touched)
          "
          class="p-invalid"
        >
          <div *ngIf="canceled_from_type.errors?.required">
            <small>Bitte wählen Sie etwas aus</small>
          </div>
        </div>
      </div>

      <!-- Abgesagt am -->
      <div class="p-field p-col-6">
        <label for="canceled_at_string">Abgesagt am *</label>
        <p-calendar
          [firstDayOfWeek]="1"
          [showWeek]="true"
          [showTime]="true"
          inputId="canceled_at_string"
          name="canceled_at_string"
          hourFormat="24"
          dateFormat="dd.mm.yy,"
          appendTo="body"
          [(ngModel)]="values.canceled_at_string"
          dataType="string"
          [showIcon]="true"
          [required]="true"
          #canceled_at_string="ngModel"
          [class.p-invalid]="
            canceled_at_string.invalid &&
            (canceled_at_string.dirty || canceled_at_string.touched)
          "
        ></p-calendar>
      </div>
    </div>

    <div
      class="p-fluid p-formgrid p-grid without-border"
      *ngIf="
        values.canceled_from_type === 'USER_SYSTEM' &&
        values.status === 'CANCELED'
      "
    >
      <!-- Aufgenommen von -->
      <div class="p-field p-col-6">
        <label for="canceled_from_user_id">Aufgenommen von *</label>
        <p-dropdown
          [filter]="true"
          scrollHeight="360px"
          inputId="canceled_from_user_id"
          name="canceled_from_user_id"
          [(ngModel)]="values.canceled_from_user_id"
          appendTo="body"
          placeholder="Bitte auswählen"
          [resetFilterOnHide]="true"
          [options]="userSystemOptions"
          optionLabel="full_name"
          optionValue="id"
          [required]="true"
          #canceled_from_user_id="ngModel"
          [class.p-invalid]="
            canceled_from_user_id.invalid &&
            (canceled_from_user_id.dirty || canceled_from_user_id.touched)
          "
        ></p-dropdown>
      </div>

      <!-- Abgesagt durch -->
      <div class="p-field p-col-6">
        <label for="canceled_type">Abgesagt durch *</label>
        <p-dropdown
          scrollHeight="360px"
          inputId="canceled_type"
          name="canceled_type"
          [(ngModel)]="values.canceled_type"
          appendTo="body"
          placeholder="Bitte auswählen"
          [options]="canceledTypes"
          [required]="true"
          #canceled_type="ngModel"
          [class.p-invalid]="
            canceled_type.invalid &&
            (canceled_type.dirty || canceled_type.touched)
          "
        ></p-dropdown>
      </div>
    </div>

    <div
      class="p-fluid p-formgrid p-grid without-border"
      *ngIf="values.status === 'CANCELED'"
    >
      <div class="p-field p-col-12">
        <label for="canceled_reason">Grund *</label>
        <textarea
          id="canceled_reason"
          name="canceled_reason"
          [(ngModel)]="values.canceled_reason"
          [rows]="3"
          pInputTextarea
          [required]="true"
          #canceled_reason="ngModel"
          [class.p-invalid]="
            canceled_reason.invalid &&
            (canceled_reason.dirty || canceled_reason.touched)
          "
        ></textarea>
      </div>
    </div>

    <div
      class="p-fluid p-formgrid p-grid without-border"
      *ngIf="values.status === 'EDIT'"
    >
      <div class="p-field p-col-12">
        <label for="edit_info">Bemerkung</label>
        <textarea
          id="edit_info"
          name="edit_info"
          [(ngModel)]="values.edit_info"
          [rows]="3"
          pInputTextarea
        ></textarea>
      </div>
    </div>

    <div
      class="p-fluid p-formgrid p-grid without-border"
      *ngIf="values.status === 'INACTIVE'"
    >
      <div class="p-field p-col-12">
        <label for="inactive_reason">Grund *</label>
        <textarea
          id="inactive_reason"
          name="inactive_reason"
          [(ngModel)]="values.inactive_reason"
          [rows]="3"
          pInputTextarea
          [required]="true"
          #inactive_reason="ngModel"
          [class.p-invalid]="
            inactive_reason.invalid &&
            (inactive_reason.dirty || inactive_reason.touched)
          "
        ></textarea>
      </div>
    </div>

    <div
      class="p-fluid p-formgrid p-grid without-border"
      *ngIf="
        (values.status === 'CANCELED' &&
          caregiver.current_status.status === 'HOSPITATION') ||
        (values.status === 'CANCELED' && isEdit)
      "
    >
      <div class="p-field p-col-12">
        <label for="hospitation_feedback">Feedback Hospitation *</label>
        <textarea
          id="hospitation_feedback"
          name="hospitation_feedback"
          [(ngModel)]="values.hospitation_feedback"
          [rows]="3"
          pInputTextarea
          [required]="true"
          #hospitation_feedback="ngModel"
          [class.p-invalid]="
            hospitation_feedback.invalid &&
            (hospitation_feedback.dirty || hospitation_feedback.touched)
          "
        ></textarea>
      </div>
    </div>

    <div
      class="p-fluid p-formgrid p-grid without-border"
      *ngIf="values.status === 'HOSPITATION_STARTED'"
    >
      <!-- Hospitation mit -->
      <div class="p-field p-col-12">
        <label for="hospitation_caregiver_id">Hospitation mit *</label>
        <p-dropdown
          [filter]="false"
          scrollHeight="360px"
          inputId="hospitation_caregiver_id"
          name="hospitation_caregiver_id"
          [(ngModel)]="values.hospitation_caregiver_id"
          appendTo="body"
          placeholder="Bitte auswählen"
          [resetFilterOnHide]="true"
          [options]="activeCaregiverOptions"
          optionLabel="full_name"
          optionValue="id"
          [required]="true"
          #hospitation_caregiver_id="ngModel"
          [class.p-invalid]="
            hospitation_caregiver_id.invalid &&
            (hospitation_caregiver_id.dirty || hospitation_caregiver_id.touched)
          "
        ></p-dropdown>
      </div>
    </div>

    <div
      class="p-fluid p-formgrid p-grid without-border"
      *ngIf="values.status === 'HOSPITATION'"
    >
      <!-- Hospitation mit -->
      <div class="p-field p-col-12">
        <label for="hospitation_caregiver_id">Hospitation mit</label>
        <strong>{{ this.caregiver.current_status.hospitation_with_caregiver?.full_name || '??' }}</strong>
      </div>
    </div>

    <div
      class="p-fluid p-formgrid p-grid without-border"
      *ngIf="values.status === 'HOSPITATION'"
    >
      <!-- Hospitation von -->
      <div class="p-field p-col-6">
        <label for="hospitation_from_string">Hospitation von *</label>
        <p-calendar
          [firstDayOfWeek]="1"
          [showWeek]="true"
          inputId="hospitation_from_string"
          name="hospitation_from_string"
          dateFormat="dd.mm.yy"
          appendTo="body"
          [(ngModel)]="values.hospitation_from_string"
          dataType="string"
          [showIcon]="true"
          [required]="true"
          #hospitation_from_string="ngModel"
          [class.p-invalid]="
            hospitation_from_string.invalid &&
            (hospitation_from_string.dirty || hospitation_from_string.touched)
          "
        ></p-calendar>
      </div>

      <!-- Hospitation bis -->
      <div class="p-field p-col-6">
        <label for="hospitation_to_string">Hospitation bis *</label>
        <p-calendar
          [firstDayOfWeek]="1"
          [showWeek]="true"
          inputId="hospitation_to_string"
          name="hospitation_to_string"
          dateFormat="dd.mm.yy"
          appendTo="body"
          [(ngModel)]="values.hospitation_to_string"
          dataType="string"
          [showIcon]="true"
          [required]="true"
          #hospitation_to_string="ngModel"
          [class.p-invalid]="
            hospitation_to_string.invalid &&
            (hospitation_to_string.dirty || hospitation_to_string.touched)
          "
        ></p-calendar>
      </div>
    </div>

    <div
      class="p-fluid p-formgrid p-grid without-border"
      *ngIf="values.status === 'HOSPITATION'"
    >
      <!-- Koordination von -->
      <div class="p-field p-col-4">
        <label for="hospitation_coordination_user_id">Koordination von</label>
        <strong>Betreuungskraft</strong>
      </div>

      <!-- Koordination am -->
      <div class="p-field p-col-4">
        <label for="hospitation_coordination_at_string"
          >Koordination am *</label
        >
        <p-calendar
          [firstDayOfWeek]="1"
          [showWeek]="true"
          inputId="hospitation_coordination_at_string"
          name="hospitation_coordination_at_string"
          dateFormat="dd.mm.yy"
          appendTo="body"
          [(ngModel)]="values.hospitation_coordination_at_string"
          dataType="string"
          [showIcon]="true"
          [required]="true"
          #hospitation_coordination_at_string="ngModel"
          [class.p-invalid]="
            hospitation_coordination_at_string.invalid &&
            (hospitation_coordination_at_string.dirty ||
              hospitation_coordination_at_string.touched)
          "
        ></p-calendar>
      </div>

      <!-- Koordination durch -->
      <div class="p-field p-col-4">
        <label for="hospitation_coordination_type">Koordination durch *</label>
        <p-dropdown
          scrollHeight="360px"
          inputId="hospitation_coordination_type"
          name="hospitation_coordination_type"
          [(ngModel)]="values.hospitation_coordination_type"
          placeholder="Bitte auswählen"
          [options]="canceledTypes"
          [required]="true"
          appendTo="body"
          #hospitation_coordination_type="ngModel"
          [class.p-invalid]="
            hospitation_coordination_type.invalid &&
            (hospitation_coordination_type.dirty ||
              hospitation_coordination_type.touched)
          "
        ></p-dropdown>
      </div>
    </div>

    <div
      class="p-fluid p-formgrid p-grid without-border"
      *ngIf="values.status === 'EMPLOYMENT'"
    >
      <div class="p-field p-col-6">
        <label for="job_scope">Stellenumfang *</label>
        <p-dropdown
          name="job_scope"
          inputId="job_scope"
          placeholder="Bitte auswählen"
          [(ngModel)]="caregiver.first_job_scope.job_scope"
          scrollHeight="300px"
          [options]="dependencies.job_scopes"
          #job_scope="ngModel"
          appendTo="body"
          [required]="true"
          [class.p-invalid]="
            job_scope.invalid && (job_scope.dirty || job_scope.touched)
          "
        ></p-dropdown>
      </div>
      <div class="p-field p-col-6">
        <label for="job_day">Arbeitszeit *</label>
        <p-dropdown
          name="job_day"
          inputId="job_day"
          placeholder="Bitte auswählen"
          [(ngModel)]="caregiver.first_job_days.job_days"
          [options]="dependencies.job_days"
          #job_days="ngModel"
          scrollHeight="300px"
          appendTo="body"
          [required]="true"
          [class.p-invalid]="
            job_days.invalid && (job_days.dirty || job_days.touched)
          "
        ></p-dropdown>
      </div>
    </div>

    <div
      class="p-fluid p-formgrid p-grid without-border"
      *ngIf="values.status === 'EMPLOYMENT'"
    >
      <div class="p-field p-col-6">
        <label for="salary">Gehalt *</label>
        <p-dropdown
          name="salary"
          inputId="salary"
          placeholder="Bitte auswählen"
          [(ngModel)]="caregiver.salary"
          [options]="dependencies.salaries"
          scrollHeight="300px"
          #salary="ngModel"
          appendTo="body"
          [required]="true"
          [class.p-invalid]="salary.invalid && (salary.dirty || salary.touched)"
        ></p-dropdown>
      </div>
      <div class="p-field p-col-6">
        <label for="vacation_days">Urlaub *</label>
        <p-dropdown
          name="vacation_days"
          inputId="vacation_days"
          placeholder="Bitte auswählen"
          [(ngModel)]="caregiver.vacation_days"
          [options]="dependencies.vacation_days"
          scrollHeight="300px"
          appendTo="body"
          #vacation_days="ngModel"
          [required]="true"
          [class.p-invalid]="
            vacation_days.invalid &&
            (vacation_days.dirty || vacation_days.touched)
          "
        ></p-dropdown>
      </div>
    </div>

    <div
      style="margin-top: 40px"
      class="p-fluid p-formgrid p-grid without-border"
      *ngIf="values.status === 'EMPLOYMENT'"
    >
      <div class="p-field p-col-12">
        <div class="p-field-checkbox">
          <p-checkbox
            name="employed_temporary"
            [binary]="true"
            (onChange)="employedTemporaryChanged($event)"
            [(ngModel)]="caregiver.current_employment.employed_temporary"
            label="Befristetes Arbeitsverhältnis"
          ></p-checkbox>
        </div>
      </div>

      <div class="p-field p-col-6">
        <label for="employed-since">Angestellt seit *</label>
        <p-calendar
          [firstDayOfWeek]="1"
          [showWeek]="true"
          inputId="employed-since"
          name="employed-since"
          [showButtonBar]="true"
          appendTo="body"
          [(ngModel)]="caregiver.current_employment.employed_since_date_string"
          dateFormat="dd.mm.yy"
          [showIcon]="true"
          dataType="string"
          #employed_since="ngModel"
          [required]="true"
          [class.p-invalid]="
            employed_since.invalid &&
            (employed_since.dirty || employed_since.touched)
          "
        ></p-calendar>
      </div>

      <div class="p-field p-col-6">
        <label for="employed-until"
          >Angestellt bis
          {{
            caregiver.current_employment.employed_temporary ? "*" : ""
          }}</label
        >
        <p-calendar
          [firstDayOfWeek]="1"
          [showWeek]="true"
          inputId="employed-until"
          name="employed-until"
          [(ngModel)]="caregiver.current_employment.employed_until_date_string"
          dateFormat="dd.mm.yy"
          [showButtonBar]="true"
          (onSelect)="untilDateChanged($event)"
          (onBlur)="untilDateChanged($event)"
          [showIcon]="true"
          appendTo="body"
          [required]="caregiver.current_employment.employed_temporary"
          dataType="string"
          #employed_until="ngModel"
          [class.p-invalid]="
            employed_until.invalid &&
            (employed_until.dirty || employed_until.touched)
          "
        ></p-calendar>
      </div>
    </div>

    <div
      class="p-fluid p-formgrid p-grid without-border"
      *ngIf="
        values.status === 'EMPLOYMENT' &&
        caregiver.current_status.status === 'HOSPITATION'
      "
    >
      <div class="p-field p-col-12">
        <label for="hospitation_feedback">Feedback Hospitation *</label>
        <textarea
          id="hospitation_feedback"
          name="hospitation_feedback"
          [(ngModel)]="values.hospitation_feedback"
          [rows]="3"
          pInputTextarea
          [required]="true"
          #hospitation_feedback="ngModel"
          [class.p-invalid]="
            hospitation_feedback.invalid &&
            (hospitation_feedback.dirty || hospitation_feedback.touched)
          "
        ></textarea>
      </div>
    </div>
  </div>
  <div class="dialog-form-footer">
    <i *ngIf="values.status === 'CANCELED' || isEdit"></i>

    <div *ngIf="values.status === 'EMPLOYMENT' && !isEdit">
      Dokumente werden generiert
      <i
        class="pi pi-info-circle"
        pTooltip="Arbeitsvertrag, Personalfragebogen, Bestätigung Führungszeugnis"
      ></i>
    </div>

    <div class="p-field-checkbox">
      <p-checkbox
        *ngIf="values.status === 'HOSPITATION' && !isEdit"
        name="todo"
        [binary]="true"
        [(ngModel)]="values.with_todo"
        label="Todo erstellen"
      ></p-checkbox>

      <p-checkbox
        *ngIf="values.status === 'JOB_INTERVIEW'"
        [disabled]="!caregiver.email"
        label="E-Mail senden"
        name="with_email"
        [pTooltip]="!caregiver.email ? 'Keine E-Mail vorhanden' : ''"
        [binary]="true"
        [(ngModel)]="values.with_email"
      ></p-checkbox>
    </div>

    <div class="dialog-footer-action-container">
      <a
        *ngIf="values.with_email"
        target="_blank"
        [href]="
          caregiverService.getJobInterviewMailPreviewLink(caregiver.id, values)
        "
      >
        <button
          pButton
          label="Mail Vorschau"
          type="button"
          icon="pi pi-eye"
          iconPos="left"
          class="p-button p-button-info"
        ></button>
      </a>

      <button
        pButton
        label="Status ändern"
        type="submit"
        (click)="save()"
        [disabled]="submitted"
        [icon]="submitted ? 'pi pi-spin pi-spinner' : 'pi pi-check'"
      ></button>
    </div>
  </div>
</form>
