<form #form="ngForm" class="dialog-form">
  <div
    class="dialog-sidebar white-sidebar space-between"
    *ngIf="
      values.date_type === 'INTERN' ||
      values.date_type === 'TRAINING' ||
      data.isEdit
    "
  >
    <!-- Auswahl Adresse -->
    <div
      class="p-fluid p-formgrid p-grid without-border"
      *ngIf="values.date_type === 'INTERN' || values.date_type === 'TRAINING'"
    >
      <div class="p-field p-col-12">
        <span class="content-sub-headline">Adresse</span>
        <div class="p-formgroup-inline">
          <div class="p-field-radiobutton">
            <p-radioButton
              label="Büro"
              name="address_type"
              [(ngModel)]="values.address_type"
              value="OFFICE"
              required
              #address_type="ngModel"
            ></p-radioButton>
          </div>
          <div class="p-field-radiobutton">
            <p-radioButton
              label="Individuell"
              name="address_type"
              [(ngModel)]="values.address_type"
              value="INDIVIDUAL"
              required
              #address_type="ngModel"
            ></p-radioButton>
          </div>
          <div class="p-field-radiobutton">
            <p-radioButton
              label="Online"
              name="address_type"
              [(ngModel)]="values.address_type"
              value="ONLINE"
              required
              #address_type="ngModel"
            ></p-radioButton>
          </div>
        </div>
        <div
          *ngIf="
            address_type.invalid && (address_type.dirty || address_type.touched)
          "
        >
          <div *ngIf="address_type.errors?.required">
            <small class="p-invalid">Bitte wählen Sie etwas aus</small>
          </div>
        </div>
      </div>

      <div
        class="p-fluid p-formgrid p-grid without-border"
        *ngIf="values.address_type === 'INDIVIDUAL'"
      >
        <div class="p-field p-col-12">
          <label for="street">Straße *</label>
          <input
            id="street"
            name="street"
            [(ngModel)]="values.address_street_house_number"
            type="text"
            pInputText
            required
            #address_street_house_number="ngModel"
            [class.p-invalid]="
              address_street_house_number.invalid &&
              (address_street_house_number.dirty ||
                address_street_house_number.touched)
            "
          />
        </div>
        <div class="p-field p-col-4">
          <label for="zipcode">PLZ *</label>
          <input
            (change)="loadCity()"
            id="zipcode"
            name="zipcode"
            [(ngModel)]="values.address_zipcode"
            type="text"
            pInputText
            required
            #address_zipcode="ngModel"
            [class.p-invalid]="
              address_zipcode.invalid &&
              (address_zipcode.dirty || address_zipcode.touched)
            "
          />
        </div>
        <div class="p-field p-col-8">
          <label for="city">Ort *</label>
          <input
            id="city"
            name="city"
            [(ngModel)]="values.address_city"
            type="text"
            pInputText
            required
            #address_city="ngModel"
            [class.p-invalid]="
              address_city.invalid &&
              (address_city.dirty || address_city.touched)
            "
          />
        </div>
      </div>
    </div>

    <!-- Dokumente -->
    <div class="p-fluid p-formgrid p-grid without-border" *ngIf="data.isEdit">
      <div class="p-field p-col-12">
        <label class="highlight-label">Aktuelles Dokument</label>
        <div class="dialog-no-message" *ngIf="!data.date.media">
          Kein Dokument hochgeladen
        </div>
        <div class="document-list" *ngIf="data.date.media">
          <span>
            <a
              [href]="
                caregiverService.getDocumentDownloadLink(data.date.media.uuid)
              "
              target="_blank"
              >{{ data.date.media.name }}
              <i class="pi pi-download" style="font-size: 12px"></i></a
          ></span>
        </div>
      </div>
    </div>
  </div>

  <div
    class="dialog-sidebar secondary-dialog-sidebar middle-dialog-sidebar without-padding"
    *ngIf="values.date_type === 'ILL' || values.date_type === 'VACATION'"
  >
    <span class="content-sub-headline" style="padding: 20px 20px 0 20px"
      >Einsätze zur Umplanung</span
    >

    <small
      class="color-gray"
      style="padding: 20px 20px 0 20px"
      *ngIf="!values.from || !values.to"
      >Bitte Datum auswählen</small
    >

    <small
      class="color-gray"
      style="padding: 20px 20px 0 20px"
      *ngIf="values.from && values.to && appointments.length === 0"
      >Keine Einsätze vorhanden</small
    >

    <p-table
      *ngIf="appointments.length > 0"
      #dt
      [value]="appointments"
      [rowHover]="true"
      [autoLayout]="true"
      [globalFilterFields]="[]"
    >
      <ng-template pTemplate="header">
        <tr>
          <th style="min-width: 150px" pSortableColumn="patient.last_name">
            Patient <p-sortIcon field="patient.last_name"></p-sortIcon>
          </th>
          <th pSortableColumn="real_from">
            Datum <p-sortIcon field="real_from"></p-sortIcon>
          </th>
          <th>Uhrzeit</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-appointment>
        <tr>
          <td>
            <a
              class="color-main1"
              target="_blank"
              href="/patients/{{ appointment.patient.id }}/planning"
            >
              {{ appointment.patient.full_name }}
            </a>
          </td>
          <td>
            {{ appointment.real_from | date: "dd.MM.y" }} ({{
              appointment.weekday
            }})
          </td>
          <td>{{ appointment.real_from_h }} - {{ appointment.real_to_h }}</td>
        </tr>
      </ng-template>
    </p-table>
  </div>

  <div class="dialog-form-body">
    <div class="p-field" *ngIf="displayName">
      <label>Betreuungskraft</label>
      <strong>{{ displayName }}</strong>
    </div>

    <div class="p-field">
      <label for="file"
        >Datei auswählen<i
          class="pi pi-info-circle"
          *ngIf="data.isEdit"
          tooltipStyleClass="tooltip-wider"
          pTooltip="Neue Datei auswählen um vorhandene zu überschreiben"
        ></i
      ></label>
      <input
        id="file"
        name="file"
        type="file"
        (change)="setSelectedFile($event)"
        pInputText
        [(ngModel)]="values.selected_file"
      />
    </div>

    <div class="p-field" *ngIf="!data.single_type">
      <label for="date_type">Termin *</label>
      <p-dropdown
        inputId="date_type"
        name="date_type"
        placeholder="Bitte auswählen"
        [options]="dateTypes"
        [(ngModel)]="values.date_type"
        [required]="true"
        (onChange)="dateTypeChanged()"
        scrollHeight="360px"
        #date_type="ngModel"
        [class.p-invalid]="
          date_type.invalid && (date_type.dirty || date_type.touched)
        "
      ></p-dropdown>
    </div>

    <!-- Typ für Fortbildung -->
    <div class="p-field" *ngIf="values.date_type === 'TRAINING'">
      <label for="type">Typ *</label>
      <p-dropdown
        inputId="type"
        name="type"
        placeholder="Bitte auswählen"
        [options]="options"
        [(ngModel)]="values.type"
        [required]="true"
        scrollHeight="360px"
        #type="ngModel"
        [class.p-invalid]="type.invalid && (type.dirty || type.touched)"
      ></p-dropdown>
    </div>

    <!-- Typ für Intern -->
    <div class="p-field" *ngIf="values.date_type === 'INTERN'">
      <label for="type">Typ *</label>
      <p-dropdown
        inputId="type"
        name="type"
        placeholder="Bitte auswählen"
        [options]="optionsIntern"
        [(ngModel)]="values.type"
        [required]="true"
        scrollHeight="360px"
        #type="ngModel"
        [class.p-invalid]="type.invalid && (type.dirty || type.touched)"
      ></p-dropdown>
    </div>

    <!-- BK Auswahl für Intern -->
    <div
      class="p-field"
      *ngIf="
        !data.isEdit &&
        values.date_type === 'INTERN' &&
        ['teamsitzung', 'übergabe', 'event'].includes(values.type)
      "
    >
      <label for="caregivers">Betreuungskräfte *</label>
      <p-multiSelect
        inputId="caregivers"
        [options]="caregivers"
        optionLabel="last_first_name"
        [filter]="true"
        [showHeader]="true"
        name="caregivers"
        [(ngModel)]="values.caregiver_id"
        optionValue="id"
        scrollHeight="300px"
        appendTo="body"
        [required]="true"
        #caregiver="ngModel"
        [class.p-invalid]="
          caregiver.invalid && (caregiver.dirty || caregiver.touched)
        "
      ></p-multiSelect>
    </div>

    <!-- Tag -->
    <div
      class="p-fluid p-formgrid p-grid without-border"
      *ngIf="values.date_type"
      style="padding: 0"
    >
      <div class="p-field p-col-6">
        <label for="from" style="display: flex; justify-content: space-between"
          >Tag von *

          <!-- Uhrzeit checkbox nur für bestimmte Typen anzeigen -->
          <div
            class="p-formgroup-inline"
            *ngIf="
              values.date_type === 'FREE_TIME_REQUEST' ||
              values.date_type === 'OTHER'
            "
          >
            <div class="p-field-checkbox" style="margin: 0 !important">
              <p-checkbox
                label="mit Uhrzeit"
                name="with_time"
                [binary]="true"
                [(ngModel)]="values.with_time"
              ></p-checkbox>
            </div></div
        ></label>
        <p-calendar
          (onSelect)="dayFromSelected($event)"
          (onBlur)="dayFromSelected($event)"
          [firstDayOfWeek]="1"
          [showWeek]="true"
          inputId="from"
          name="from"
          dateFormat="dd.mm.yy"
          appendTo="body"
          [(ngModel)]="values.from"
          dataType="string"
          [showButtonBar]="true"
          [required]="true"
          #from="ngModel"
          [class.p-invalid]="from.invalid && (from.dirty || from.touched)"
        ></p-calendar>
      </div>

      <!-- Tag bis soll nur für AU und Urlaub angezeigt werden -->
      <div
        class="p-field p-col-6"
        *ngIf="values.date_type === 'ILL' || values.date_type === 'VACATION'"
      >
        <label for="to">Tag bis *</label>
        <p-calendar
          (onSelect)="dayToSelected($event)"
          (onBlur)="dayToSelected($event)"
          [firstDayOfWeek]="1"
          [showWeek]="true"
          inputId="to"
          name="to"
          dateFormat="dd.mm.yy"
          appendTo="body"
          [showButtonBar]="true"
          [(ngModel)]="values.to"
          dataType="string"
          [required]="true"
          #to="ngModel"
          [class.p-invalid]="to.invalid && (to.dirty || to.touched)"
        ></p-calendar>
      </div>

      <!-- von -->
      <div class="p-field p-col-3" *ngIf="values.with_time">
        <label for="from_time">von *</label>
        <p-calendar
          (onBlur)="checkForTimes($event, 'from_time')"
          [stepMinute]="15"
          inputId="from_time"
          name="from_time"
          [timeOnly]="true"
          appendTo="body"
          [(ngModel)]="values.from_time"
          dataType="string"
          [required]="true"
          #from_time="ngModel"
          [showButtonBar]="false"
          [class.p-invalid]="
            from_time.invalid && (from_time.dirty || from_time.touched)
          "
        ></p-calendar>
      </div>

      <!-- bis -->
      <div class="p-field p-col-3" *ngIf="values.with_time">
        <label
          for="to_time"
          style="display: flex; justify-content: space-between"
          >bis *
          <i
            (click)="setEndTime()"
            *ngIf="values.from"
            style="cursor: pointer"
            class="pi pi-clock"
            pTooltip="Tagesende"
          ></i>
        </label>
        <p-calendar
          (onBlur)="checkForTimes($event, 'to_time')"
          [stepMinute]="15"
          inputId="to_time"
          name="to_time"
          [timeOnly]="true"
          appendTo="body"
          [(ngModel)]="values.to_time"
          dataType="string"
          [required]="true"
          #to_time="ngModel"
          [showButtonBar]="false"
          [class.p-invalid]="
            to_time.invalid && (to_time.dirty || to_time.touched)
          "
        ></p-calendar>
      </div>
    </div>

    <div
      class="p-fluid p-formgrid p-grid without-border"
      *ngIf="values.date_type === 'TRAINING'"
    >
      <div class="p-field p-col-12">
        <label for="valid_until">Gültig bis *</label>
        <div class="p-field-checkbox checkbox-with-calendar">
          <div>
            <p-calendar
              [firstDayOfWeek]="1"
              [showWeek]="true"
              [disabled]="values.valid_unlimited"
              inputId="valid_until"
              name="valid_until"
              dateFormat="dd.mm.yy"
              [showButtonBar]="true"
              appendTo="body"
              [(ngModel)]="values.valid_until"
              dataType="string"
              [required]="!values.valid_unlimited"
              #valid_until="ngModel"
              [class.p-invalid]="
                valid_until.invalid &&
                (valid_until.dirty || valid_until.touched)
              "
            ></p-calendar>
          </div>

          <p-checkbox
            name="important"
            [binary]="true"
            label="Unbefristet"
            [(ngModel)]="values.valid_unlimited"
          ></p-checkbox>

          <i></i>
        </div>
      </div>
    </div>

    <div class="p-field">
      <label for="comment">Bemerkung</label>
      <textarea
        id="comment"
        name="comment"
        [(ngModel)]="values.comment"
        [rows]="3"
        pInputTextarea
      ></textarea>
      <small
        style="display: flex; align-items: center; gap: 5px"
        class="color-blue"
        ><i class="pi pi-info-circle"></i>Betreuungskraft sieht diesen
        Kommentar</small
      >
    </div>

    <div class="p-field" *ngIf="values.date_type === 'ILL'">
      <label for="child_ill">Kind-AU?</label>
      <div class="p-formgroup-inline">
        <div class="p-field-radiobutton">
          <p-radioButton
            label="Nein"
            name="child_ill"
            [(ngModel)]="values.child_ill"
            value="NEIN"
            required
            #childIll="ngModel"
          ></p-radioButton>
        </div>
        <div class="p-field-radiobutton">
          <p-radioButton
            label="Ja"
            name="child_ill"
            [(ngModel)]="values.child_ill"
            value="JA"
            required
            #childIll="ngModel"
          ></p-radioButton>
        </div>
      </div>
      <div
        *ngIf="childIll.invalid && (childIll.dirty || childIll.touched)"
        class="p-invalid"
      >
        <div *ngIf="childIll.errors?.required">
          <small>Bitte wählen Sie etwas aus</small>
        </div>
      </div>
    </div>

    <div class="detail-information-banner" *ngIf="hasVacation">
      <span
        >In diesem Zeitraum ist bereits <b>Urlaub</b> eingetragen. Es wurde eine
        ToDo erstellt.</span
      >
    </div>
  </div>
  <div class="dialog-form-footer">
    <i *ngIf="!data.isEdit"></i>
    <i
      class="pi pi-trash"
      *ngIf="data.isEdit"
      [class.pi-trash]="!submittedDelete"
      [class.pi-spin]="submittedDelete"
      [class.pi-spinner]="submittedDelete"
      tooltipPosition="left"
      tooltipZIndex="20000"
      [pTooltip]="submittedDelete ? 'Wird gelöscht' : 'Termin löschen'"
      (click)="remove($event)"
    ></i>

    <button
      *ngIf="hasVacation"
      (click)="closeDialog()"
      pButton
      label="OK"
      type="button"
      icon="pi pi-check"
    ></button>

    <button
      *ngIf="!hasVacation"
      (click)="upload()"
      pButton
      [label]="data.isEdit ? 'Speichern' : 'Hochladen'"
      type="button"
      [disabled]="submitted"
      [icon]="submitted ? 'pi pi-spin pi-spinner' : 'pi pi-upload'"
    ></button>
  </div>
</form>

<p-confirmPopup></p-confirmPopup>
