import { Component, OnInit } from '@angular/core'
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog'
import { EventBusService, GlobalEvent } from 'src/app/services/eventbus.service'
import { ToastService } from 'src/app/services/toast.service'
import { PatientService } from '../../../services/patient.service'

@Component({
  selector: 'app-change-patient-appointment-types-dialog',
  templateUrl: './change-patient-appointment-types-dialog.component.html',
})
export class ChangePatientAppointmentTypesDialogComponent implements OnInit {
  submitted = false
  submittedDelete = false

  public receiverOptions: any[] = []

  public types = []
  public comment = ''
  public receiver = []

  constructor(
    private patientService: PatientService,
    private ref: DynamicDialogRef,
    private config: DynamicDialogConfig,
    private eventbus: EventBusService,
    private toastService: ToastService
  ) {}

  public ngOnInit(): void {
    this.comment = this.config.data.patient.appointment_type_comment
    this.types = this.config.data.patient.appointment_types.map(
      (type: any) => type.type
    )
    this.receiver = this.config.data.patient.appointment_receiver_values

    this.buildReceiverOptions()
  }

  public save(): void {
    this.submitted = true

    this.patientService
      .changeAppointmentTypes(
        this.config.data.patient.id,
        this.types,
        this.comment,
        this.receiver
      )
      .subscribe(
        () => {
          this.submitted = false
          this.eventbus.emit(GlobalEvent.PatientStatusChanged)
          this.ref.close()
          this.toastService.success(
            'Terminversand geändert',
            'Der Terminversand wurden erfolgreich geändert'
          )
        },
        () => {
          this.toastService.error(
            'Etwas ist schiefgelaufen...',
            'Bitte wenden Sie sich an den Support'
          )

          this.submitted = false
        }
      )
  }

  public buildReceiverOptions(): void {
    this.receiverOptions.push({
      value: `patient_${this.config.data.patient.id}_`,
      label: `${this.config.data.patient.full_name} - Patient`,
    })

    if (this.config.data.patient.customer) {
      for (const contactPerson of this.config.data.patient.customer
        .contact_persons) {
        this.receiverOptions.push({
          value: `ap_${contactPerson.id}_`,
          label: `${contactPerson.full_name} - AP`,
        })
      }
    }
  }
}
