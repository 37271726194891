import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core'
import { Router } from '@angular/router'
import { TitleService } from 'src/app/services/title.service'
import { MediaModel } from '../../models/document/media.model'
import { DocumentService } from '../../services/document.service'
import { AddDocumentDialogComponent } from '../../components/dialogs/add-document-dialog/add-document-dialog.component'
import { AddGlobalDocumentDialogComponent } from '../../components/dialogs/add-global-document-dialog/add-global-document-dialog.component'
import { DialogService } from 'primeng/dynamicdialog'
import { EventBusService, GlobalEvent } from '../../services/eventbus.service'
import { Subscription } from 'rxjs'
import { GlobalDocumentModel } from '../../models/document/global-document.model'
import {AuthService} from "../../services/auth.service";

@Component({
  selector: 'app-global-documents',
  templateUrl: './global-documents.component.html',
})
export class GlobalDocumentsComponent implements OnInit, OnDestroy {
  @ViewChild('dt') table: ElementRef | any = null

  public globalFilterValue = ''
  public firstPage = 0

  private eventBusSubscription: Subscription = new Subscription()

  public onlyArchived = false

  public loading = false
  public documents: GlobalDocumentModel[] = []

  constructor(
    public authService: AuthService,
    public documentService: DocumentService,
    private dialogService: DialogService,
    private router: Router,
    private eventbus: EventBusService,
    private titleService: TitleService
  ) {}

  ngOnInit(): void {
    this.loadDocuments()
    this.listenForEventbus()
    this.titleService.setTitle('Dokumente')
  }

  ngOnDestroy(): void {
    this.eventBusSubscription.unsubscribe()
  }

  public filterTableGlobal(event: any): void {
    this.table && this.table.filterGlobal(event.target.value.trim(), 'contains')
  }

  public openAddGlobalDocumentDialog(document = null): void {
    this.dialogService.open(AddGlobalDocumentDialogComponent, {
      header: document
        ? 'Globales Dokument bearbeiten'
        : 'Globales Dokument hochladen',
      styleClass: 'dialog-container',
      dismissableMask: false,
      width: '450px',
      data: {
        document,
      },
    })
  }

  public openDownloadTab(document: MediaModel): void {
    window.open(this.documentService.getDocumentDownloadLink(document.uuid))
  }

  private listenForEventbus(): void {
    this.eventBusSubscription = this.eventbus.subject.subscribe(
      (event: GlobalEvent) => {
        switch (event) {
          case GlobalEvent.DocumentListReload:
            this.loadDocuments(false)
        }
      }
    )
  }

  public loadDocuments(withLoading: boolean = true): void {
    if (withLoading) {
      this.loading = true
    }

    this.documentService
      .loadGlobalDocuments(this.onlyArchived)
      .subscribe((data: GlobalDocumentModel[]) => {
        this.documents = data

        if (withLoading) {
          this.loading = false
        }

        setTimeout(() => {
          if (this.table) {
            const totalItems = this.table._totalRecords

            this.globalFilterValue = this.table.filters.global?.value ?? ''

            if (
              this.table._first > totalItems ||
              this.table._rows > totalItems
            ) {
              this.firstPage = 0
            }
          }
        })
      })
  }
}
