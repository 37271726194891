<section class="auth-section">
  <i class="blob-1"></i>
  <i class="blob-2"></i>

  <h2><strong>SBG Saar</strong> Webportal</h2>

  <h2 *ngIf="systemType">{{ systemType }}</h2>

  <div class="login-body">
    <form (ngSubmit)="form.form.valid && login()" #form="ngForm">
      <div class="p-field">
        <label for="email">Kennung</label>
        <input id="email" pInputText [(ngModel)]="data.email" name="email" />
      </div>
      <div class="p-field">
        <label for="password">Passwort</label>
        <input
          type="password"
          id="password"
          pInputText
          [(ngModel)]="data.password"
          name="password"
        />
      </div>

      <small class="color-alert auth-message" *ngIf="errorMessage">
        {{ errorMessage }}
      </small>

      <div class="login-footer">
        <a tabindex="-1" routerLink="/password-reset">Passwort vergessen?</a>

        <button
          pButton
          label="Einloggen"
          type="submit"
          iconPos="right"
          [icon]="submitted ? 'pi pi-spin pi-spinner' : 'pi pi-arrow-right'"
        ></button>
      </div>
    </form>
  </div>
</section>
