<app-loader [loading]="loading"></app-loader>

<div class="content-actions">
  <h2>
    <i class="tio">remaining_time</i>
    Freie Kapazität
  </h2>
</div>

<div class="content" *ngIf="!loading">
  <header class="content-header">
    <span>
      <i (click)="goToPreviousWeek()" class="pi pi-chevron-left"></i>
      {{ week.monday }} - {{ week.sunday }}
      <i
        (click)="goToNextWeek()"
        class="pi pi-chevron-right"
        style="margin: 0 10px 0 10px"
      ></i>
      <p-calendar
        styleClass="datepicker-as-icon"
        inputId="date"
        [showButtonBar]="true"
        [firstDayOfWeek]="1"
        [showWeek]="true"
        (onSelect)="goToSelectedWeek($event)"
        name="date"
        dateFormat="dd.mm.yy"
        [(ngModel)]="week.monday"
        [showIcon]="true"
      ></p-calendar>

      <p-dropdown
        [style]="{ width: '180px' }"
        (onChange)="loadCapacity(false)"
        [showClear]="true"
        name="planner_id"
        inputId="planner_id"
        [(ngModel)]="selectedPlanner"
        placeholder="Betreuer"
        [options]="plannerUsers"
        optionValue="id"
        optionLabel="full_name"
        scrollHeight="300px"
      ></p-dropdown>
    </span>
  </header>

  <div
    class="content-body without-padding"
    [class.content-loading]="contentLoading"
  >
    <div class="capacity-week">
      <div class="table-search-container">
        <div class="p-input-icon-left">
          <i class="pi pi-search"></i>
          <input
            name="search"
            [(ngModel)]="searchQuery"
            pInputText
            placeholder="Suche Name"
          />
        </div>
      </div>

      <!--      TODO: loop -->
      <div class="capacity-weeks">
        <div [class.background-hightlight-blue]="week.monday === currentDay">
          <strong
            >Montag {{ week.monday === currentDay ? "- HEUTE" : "" }}</strong
          >
          <span>{{ week.monday }} </span>
        </div>
        <div [class.background-hightlight-blue]="week.tuesday === currentDay">
          <strong
            >Dienstag {{ week.tuesday === currentDay ? "- HEUTE" : "" }}</strong
          >
          <span>{{ week.tuesday }} </span>
        </div>
        <div [class.background-hightlight-blue]="week.wednesday === currentDay">
          <strong
            >Mittwoch
            {{ week.wednesday === currentDay ? "- HEUTE" : "" }}</strong
          >
          <span>{{ week.wednesday }} </span>
        </div>
        <div [class.background-hightlight-blue]="week.thursday === currentDay">
          <strong
            >Donnerstag
            {{ week.thursday === currentDay ? "- HEUTE" : "" }}</strong
          >
          <span>{{ week.thursday }} </span>
        </div>
        <div [class.background-hightlight-blue]="week.friday === currentDay">
          <strong
            >Freitag {{ week.friday === currentDay ? "- HEUTE" : "" }}</strong
          >
          <span>{{ week.friday }} </span>
        </div>
        <div [class.background-hightlight-blue]="week.saturday === currentDay">
          <strong
            >Samstag {{ week.saturday === currentDay ? "- HEUTE" : "" }}</strong
          >
          <span>{{ week.saturday }} </span>
        </div>
      </div>
    </div>

    <div class="capacity-lists" *ngFor="let time of capacityKeys">
      <span class="capacity-divider">{{ time }}</span>
      <div
        class="capacity-list"
        [class.active]="searchedForCaregiver(item.caregiver_name)"
        *ngFor="let item of data[time]"
      >
        <div class="capacity-caregiver">
          <i
            class="pi pi-info-circle"
            [escape]="false"
            tooltipPosition="right"
            tooltipStyleClass="tooltip-wider"
            [pTooltip]="item.about_and_times_table || '-'"
          ></i>

          <a routerLink="/caregivers/{{ item.caregiver_id }}" target="_blank">
            {{ item.caregiver_name }}
          </a>

          <span class="caregiver-overtimes">
            <i pTooltip="Aufgelaufen">{{ item.accumulated_single }}</i>
            <i pTooltip="Überstunden">{{ item.overtime }}</i>
          </span>
        </div>
        <div class="capacity-times-container">
          <!-- Montag -->
          <div
            class="capacity-times"
            [class.background-hightlight-main1]="
              item.data.monday.free_time_request.length > 0 ||
              item.data.monday.training.length > 0 ||
              item.data.monday.other.length > 0
            "
            [class.background-hightlight-gray]="
              item.data.monday.intern.length > 0
            "
            [class.background-hightlight-blue]="
              item.data.monday.vacation.length > 0
            "
            [class.background-hightlight-alert]="
              item.data.monday.ill.length > 0
            "
            [class.background-hightlight-orange]="
              item.data.monday.holiday.length > 0
            "
          >
            <div>
              <span *ngIf="item.data.monday.capacities.length === 0">-</span>
              <span
                [class.color-gray]="time == 'Keine Planung'"
                [class.color-alert]="time == 'Keine Arbeitszeit'"
                *ngFor="let time of item.data.monday.capacities"
              >
                {{ time }}
              </span>
            </div>

            <div class="capacity-icons">
              <i
                class="pi pi-heart color-alert"
                tooltipStyleClass="tooltip-wider"
                [escape]="false"
                *ngIf="item.data.monday.ill.length > 0"
                [pTooltip]="item.data.monday.ill.join('<br>')"
              ></i>

              <i
                class="pi pi-sun color-main1"
                tooltipStyleClass="tooltip-wider"
                [escape]="false"
                *ngIf="item.data.monday.free_time_request.length > 0"
                [pTooltip]="item.data.monday.free_time_request.join('<br>')"
              ></i>

              <i
                class="pi pi-sun color-main1"
                tooltipStyleClass="tooltip-wider"
                [escape]="false"
                *ngIf="item.data.monday.other.length > 0"
                [pTooltip]="item.data.monday.other.join('<br>')"
              ></i>

              <i
                class="pi pi-book color-main1"
                tooltipStyleClass="tooltip-wider"
                [escape]="false"
                *ngIf="item.data.monday.training.length > 0"
                [pTooltip]="item.data.monday.training.join('<br>')"
              ></i>

              <i
                class="pi pi-home color-dark"
                tooltipStyleClass="tooltip-wider"
                [escape]="false"
                *ngIf="item.data.monday.intern.length > 0"
                [pTooltip]="item.data.monday.intern.join('<br>')"
              ></i>

              <i
                class="pi pi-sun color-gray"
                tooltipStyleClass="tooltip-wider"
                [escape]="false"
                *ngIf="item.data.monday.holiday.length > 0"
                [pTooltip]="item.data.monday.holiday.join('<br>')"
              ></i>

              <i
                class="pi pi-sun color-blue"
                tooltipStyleClass="tooltip-wider"
                [escape]="false"
                *ngIf="item.data.monday.vacation.length > 0"
                [pTooltip]="item.data.monday.vacation.join('<br>')"
              ></i>

              <i
                tooltipPosition="bottom"
                tooltipStyleClass="tooltip-wider"
                [escape]="false"
                [pTooltip]="persplanData[item.caregiver_id + week.monday]"
                class="pi pi-info-circle color-gray"
                *ngIf="item.data.monday.planed"
                (mouseenter)="
                  getPersplanForCaregiver(item.caregiver_id, week.monday)
                "
              ></i>
            </div>
          </div>

          <!-- Dienstag -->
          <div
            class="capacity-times"
            [class.background-hightlight-main1]="
              item.data.tuesday.free_time_request.length > 0 ||
              item.data.tuesday.training.length > 0 ||
              item.data.tuesday.other.length > 0
            "
            [class.background-hightlight-gray]="
              item.data.tuesday.intern.length > 0
            "
            [class.background-hightlight-blue]="
              item.data.tuesday.vacation.length > 0
            "
            [class.background-hightlight-alert]="
              item.data.tuesday.ill.length > 0
            "
            [class.background-hightlight-orange]="
              item.data.tuesday.holiday.length > 0
            "
          >
            <div>
              <span *ngIf="item.data.tuesday.capacities.length === 0">-</span>
              <span
                [class.color-gray]="time == 'Keine Planung'"
                [class.color-alert]="time == 'Keine Arbeitszeit'"
                *ngFor="let time of item.data.tuesday.capacities"
              >
                {{ time }}
              </span>
            </div>

            <div class="capacity-icons">
              <i
                class="pi pi-heart color-alert"
                tooltipStyleClass="tooltip-wider"
                [escape]="false"
                *ngIf="item.data.tuesday.ill.length > 0"
                [pTooltip]="item.data.tuesday.ill.join('<br>')"
              ></i>

              <i
                class="pi pi-sun color-main1"
                tooltipStyleClass="tooltip-wider"
                [escape]="false"
                *ngIf="item.data.tuesday.free_time_request.length > 0"
                [pTooltip]="item.data.tuesday.free_time_request.join('<br>')"
              ></i>

              <i
                class="pi pi-sun color-main1"
                tooltipStyleClass="tooltip-wider"
                [escape]="false"
                *ngIf="item.data.tuesday.other.length > 0"
                [pTooltip]="item.data.tuesday.other.join('<br>')"
              ></i>

              <i
                class="pi pi-book color-main1"
                tooltipStyleClass="tooltip-wider"
                [escape]="false"
                *ngIf="item.data.tuesday.training.length > 0"
                [pTooltip]="item.data.tuesday.training.join('<br>')"
              ></i>

              <i
                class="pi pi-home color-dark"
                tooltipStyleClass="tooltip-wider"
                [escape]="false"
                *ngIf="item.data.tuesday.intern.length > 0"
                [pTooltip]="item.data.tuesday.intern.join('<br>')"
              ></i>

              <i
                class="pi pi-sun color-gray"
                tooltipStyleClass="tooltip-wider"
                [escape]="false"
                *ngIf="item.data.tuesday.holiday.length > 0"
                [pTooltip]="item.data.tuesday.holiday.join('<br>')"
              ></i>

              <i
                class="pi pi-sun color-blue"
                tooltipStyleClass="tooltip-wider"
                [escape]="false"
                *ngIf="item.data.tuesday.vacation.length > 0"
                [pTooltip]="item.data.tuesday.vacation.join('<br>')"
              ></i>

              <i
                tooltipPosition="bottom"
                tooltipStyleClass="tooltip-wider"
                [escape]="false"
                [pTooltip]="persplanData[item.caregiver_id + week.tuesday]"
                class="pi pi-info-circle color-gray"
                *ngIf="item.data.tuesday.planed"
                (mouseenter)="
                  getPersplanForCaregiver(item.caregiver_id, week.tuesday)
                "
              ></i>
            </div>
          </div>

          <!-- Mittwoch -->
          <div
            class="capacity-times"
            [class.background-hightlight-main1]="
              item.data.wednesday.free_time_request.length > 0 ||
              item.data.wednesday.training.length > 0 ||
              item.data.wednesday.other.length > 0
            "
            [class.background-hightlight-gray]="
              item.data.wednesday.intern.length > 0
            "
            [class.background-hightlight-blue]="
              item.data.wednesday.vacation.length > 0
            "
            [class.background-hightlight-alert]="
              item.data.wednesday.ill.length > 0
            "
            [class.background-hightlight-orange]="
              item.data.wednesday.holiday.length > 0
            "
          >
            <div>
              <span *ngIf="item.data.wednesday.capacities.length === 0">-</span>
              <span
                [class.color-gray]="time == 'Keine Planung'"
                [class.color-alert]="time == 'Keine Arbeitszeit'"
                *ngFor="let time of item.data.wednesday.capacities"
              >
                {{ time }}
              </span>
            </div>

            <div class="capacity-icons">
              <i
                class="pi pi-heart color-alert"
                tooltipStyleClass="tooltip-wider"
                [escape]="false"
                *ngIf="item.data.wednesday.ill.length > 0"
                [pTooltip]="item.data.wednesday.ill.join('<br>')"
              ></i>

              <i
                class="pi pi-sun color-main1"
                tooltipStyleClass="tooltip-wider"
                [escape]="false"
                *ngIf="item.data.wednesday.free_time_request.length > 0"
                [pTooltip]="item.data.wednesday.free_time_request.join('<br>')"
              ></i>

              <i
                class="pi pi-sun color-main1"
                tooltipStyleClass="tooltip-wider"
                [escape]="false"
                *ngIf="item.data.wednesday.other.length > 0"
                [pTooltip]="item.data.wednesday.other.join('<br>')"
              ></i>

              <i
                class="pi pi-book color-main1"
                tooltipStyleClass="tooltip-wider"
                [escape]="false"
                *ngIf="item.data.wednesday.training.length > 0"
                [pTooltip]="item.data.wednesday.training.join('<br>')"
              ></i>

              <i
                class="pi pi-home color-dark"
                tooltipStyleClass="tooltip-wider"
                [escape]="false"
                *ngIf="item.data.wednesday.intern.length > 0"
                [pTooltip]="item.data.wednesday.intern.join('<br>')"
              ></i>

              <i
                class="pi pi-sun color-gray"
                tooltipStyleClass="tooltip-wider"
                [escape]="false"
                *ngIf="item.data.wednesday.holiday.length > 0"
                [pTooltip]="item.data.wednesday.holiday.join('<br>')"
              ></i>

              <i
                class="pi pi-sun color-blue"
                tooltipStyleClass="tooltip-wider"
                [escape]="false"
                *ngIf="item.data.wednesday.vacation.length > 0"
                [pTooltip]="item.data.wednesday.vacation.join('<br>')"
              ></i>

              <i
                tooltipPosition="bottom"
                tooltipStyleClass="tooltip-wider"
                [escape]="false"
                [pTooltip]="persplanData[item.caregiver_id + week.wednesday]"
                class="pi pi-info-circle color-gray"
                *ngIf="item.data.wednesday.planed"
                (mouseenter)="
                  getPersplanForCaregiver(item.caregiver_id, week.wednesday)
                "
              ></i>
            </div>
          </div>

          <!-- Donnerstag -->
          <div
            class="capacity-times"
            [class.background-hightlight-main1]="
              item.data.thursday.free_time_request.length > 0 ||
              item.data.thursday.training.length > 0 ||
              item.data.thursday.other.length > 0
            "
            [class.background-hightlight-gray]="
              item.data.thursday.intern.length > 0
            "
            [class.background-hightlight-blue]="
              item.data.thursday.vacation.length > 0
            "
            [class.background-hightlight-alert]="
              item.data.thursday.ill.length > 0
            "
            [class.background-hightlight-orange]="
              item.data.thursday.holiday.length > 0
            "
          >
            <div>
              <span *ngIf="item.data.thursday.capacities.length === 0">-</span>
              <span
                [class.color-gray]="time == 'Keine Planung'"
                [class.color-alert]="time == 'Keine Arbeitszeit'"
                *ngFor="let time of item.data.thursday.capacities"
              >
                {{ time }}
              </span>
            </div>

            <div class="capacity-icons">
              <i
                class="pi pi-heart color-alert"
                [escape]="false"
                tooltipStyleClass="tooltip-wider"
                *ngIf="item.data.thursday.ill.length > 0"
                [pTooltip]="item.data.thursday.ill.join('<br>')"
              ></i>

              <i
                class="pi pi-sun color-main1"
                [escape]="false"
                tooltipStyleClass="tooltip-wider"
                *ngIf="item.data.thursday.free_time_request.length > 0"
                [pTooltip]="item.data.thursday.free_time_request.join('<br>')"
              ></i>

              <i
                class="pi pi-sun color-main1"
                [escape]="false"
                tooltipStyleClass="tooltip-wider"
                *ngIf="item.data.thursday.other.length > 0"
                [pTooltip]="item.data.thursday.other.join('<br>')"
              ></i>

              <i
                class="pi pi-book color-main1"
                [escape]="false"
                tooltipStyleClass="tooltip-wider"
                *ngIf="item.data.thursday.training.length > 0"
                [pTooltip]="item.data.thursday.training.join('<br>')"
              ></i>

              <i
                class="pi pi-home color-dark"
                tooltipStyleClass="tooltip-wider"
                [escape]="false"
                *ngIf="item.data.thursday.intern.length > 0"
                [pTooltip]="item.data.thursday.intern.join('<br>')"
              ></i>

              <i
                class="pi pi-sun color-gray"
                tooltipStyleClass="tooltip-wider"
                [escape]="false"
                *ngIf="item.data.thursday.holiday.length > 0"
                [pTooltip]="item.data.thursday.holiday.join('<br>')"
              ></i>

              <i
                class="pi pi-sun color-blue"
                [escape]="false"
                tooltipStyleClass="tooltip-wider"
                *ngIf="item.data.thursday.vacation.length > 0"
                [pTooltip]="item.data.thursday.vacation.join('<br>')"
              ></i>

              <i
                tooltipPosition="bottom"
                tooltipStyleClass="tooltip-wider-left"
                [escape]="false"
                [pTooltip]="persplanData[item.caregiver_id + week.thursday]"
                class="pi pi-info-circle color-gray"
                *ngIf="item.data.thursday.planed"
                (mouseenter)="
                  getPersplanForCaregiver(item.caregiver_id, week.thursday)
                "
              ></i>
            </div>
          </div>

          <!-- Freitag -->
          <div
            class="capacity-times"
            [class.background-hightlight-main1]="
              item.data.friday.free_time_request.length > 0 ||
              item.data.friday.training.length > 0 ||
              item.data.friday.other.length > 0
            "
            [class.background-hightlight-gray]="
              item.data.friday.intern.length > 0
            "
            [class.background-hightlight-blue]="
              item.data.friday.vacation.length > 0
            "
            [class.background-hightlight-alert]="
              item.data.friday.ill.length > 0
            "
            [class.background-hightlight-orange]="
              item.data.friday.holiday.length > 0
            "
          >
            <div>
              <span *ngIf="item.data.friday.capacities.length === 0">-</span>
              <span
                [class.color-gray]="time == 'Keine Planung'"
                [class.color-alert]="time == 'Keine Arbeitszeit'"
                *ngFor="let time of item.data.friday.capacities"
              >
                {{ time }}
              </span>
            </div>

            <div class="capacity-icons">
              <i
                class="pi pi-heart color-alert"
                [escape]="false"
                tooltipPosition="left"
                tooltipStyleClass="tooltip-wider-left"
                *ngIf="item.data.friday.ill.length > 0"
                [pTooltip]="item.data.friday.ill.join('<br>')"
              ></i>

              <i
                class="pi pi-sun color-main1"
                [escape]="false"
                tooltipPosition="left"
                tooltipStyleClass="tooltip-wider-left"
                *ngIf="item.data.friday.free_time_request.length > 0"
                [pTooltip]="item.data.friday.free_time_request.join('<br>')"
              ></i>

              <i
                class="pi pi-sun color-main1"
                [escape]="false"
                tooltipPosition="left"
                tooltipStyleClass="tooltip-wider-left"
                *ngIf="item.data.friday.other.length > 0"
                [pTooltip]="item.data.friday.other.join('<br>')"
              ></i>

              <i
                class="pi pi-sun color-gray"
                tooltipStyleClass="tooltip-wider"
                [escape]="false"
                *ngIf="item.data.friday.holiday.length > 0"
                [pTooltip]="item.data.friday.holiday.join('<br>')"
              ></i>

              <i
                class="pi pi-book color-main1"
                [escape]="false"
                tooltipPosition="left"
                tooltipStyleClass="tooltip-wider-left"
                *ngIf="item.data.friday.training.length > 0"
                [pTooltip]="item.data.friday.training.join('<br>')"
              ></i>

              <i
                class="pi pi-home color-dark"
                tooltipStyleClass="tooltip-wider"
                [escape]="false"
                *ngIf="item.data.friday.intern.length > 0"
                [pTooltip]="item.data.friday.intern.join('<br>')"
              ></i>

              <i
                class="pi pi-sun color-blue"
                [escape]="false"
                tooltipPosition="left"
                tooltipStyleClass="tooltip-wider-left"
                *ngIf="item.data.friday.vacation.length > 0"
                [pTooltip]="item.data.friday.vacation.join('<br>')"
              ></i>

              <i
                tooltipPosition="bottom"
                tooltipStyleClass="tooltip-wider-left"
                [escape]="false"
                [pTooltip]="persplanData[item.caregiver_id + week.friday]"
                class="pi pi-info-circle color-gray"
                *ngIf="item.data.friday.planed"
                (mouseenter)="
                  getPersplanForCaregiver(item.caregiver_id, week.friday)
                "
              ></i>
            </div>
          </div>

          <!-- Samstag -->
          <div
            class="capacity-times"
            [class.background-hightlight-main1]="
              item.data.saturday.free_time_request.length > 0 ||
              item.data.saturday.training.length > 0 ||
              item.data.saturday.other.length > 0
            "
            [class.background-hightlight-gray]="
              item.data.saturday.intern.length > 0
            "
            [class.background-hightlight-blue]="
              item.data.saturday.vacation.length > 0
            "
            [class.background-hightlight-alert]="
              item.data.saturday.ill.length > 0
            "
            [class.background-hightlight-orange]="
              item.data.saturday.holiday.length > 0
            "
          >
            <div>
              <span *ngIf="item.data.saturday.capacities.length === 0">-</span>
              <span
                [class.color-gray]="time == 'Keine Planung'"
                [class.color-alert]="time == 'Keine Arbeitszeit'"
                *ngFor="let time of item.data.saturday.capacities"
              >
                {{ time }}
              </span>
            </div>

            <div class="capacity-icons">
              <i
                class="pi pi-heart color-alert"
                [escape]="false"
                tooltipPosition="left"
                tooltipStyleClass="tooltip-wider-left"
                *ngIf="item.data.saturday.ill.length > 0"
                [pTooltip]="item.data.saturday.ill.join('<br>')"
              ></i>

              <i
                class="pi pi-sun color-main1"
                [escape]="false"
                tooltipPosition="left"
                tooltipStyleClass="tooltip-wider-left"
                *ngIf="item.data.saturday.free_time_request.length > 0"
                [pTooltip]="item.data.saturday.free_time_request.join('<br>')"
              ></i>

              <i
                class="pi pi-sun color-main1"
                [escape]="false"
                tooltipPosition="left"
                tooltipStyleClass="tooltip-wider-left"
                *ngIf="item.data.saturday.other.length > 0"
                [pTooltip]="item.data.saturday.other.join('<br>')"
              ></i>

              <i
                class="pi pi-book color-main1"
                [escape]="false"
                tooltipPosition="left"
                tooltipStyleClass="tooltip-wider-left"
                *ngIf="item.data.saturday.training.length > 0"
                [pTooltip]="item.data.saturday.training.join('<br>')"
              ></i>

              <i
                class="pi pi-home color-dark"
                tooltipStyleClass="tooltip-wider"
                [escape]="false"
                *ngIf="item.data.saturday.intern.length > 0"
                [pTooltip]="item.data.saturday.intern.join('<br>')"
              ></i>

              <i
                class="pi pi-sun color-gray"
                tooltipStyleClass="tooltip-wider"
                [escape]="false"
                *ngIf="item.data.saturday.holiday.length > 0"
                [pTooltip]="item.data.saturday.holiday.join('<br>')"
              ></i>

              <i
                class="pi pi-sun color-blue"
                [escape]="false"
                tooltipPosition="left"
                tooltipStyleClass="tooltip-wider-left"
                *ngIf="item.data.saturday.vacation.length > 0"
                [pTooltip]="item.data.saturday.vacation.join('<br>')"
              ></i>

              <i
                tooltipPosition="bottom"
                tooltipStyleClass="tooltip-wider-left"
                [escape]="false"
                [pTooltip]="persplanData[item.caregiver_id + week.saturday]"
                class="pi pi-info-circle color-gray"
                *ngIf="item.data.saturday.planed"
                (mouseenter)="
                  getPersplanForCaregiver(item.caregiver_id, week.saturday)
                "
              ></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
