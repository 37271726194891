export class PatientDetailModel {
  id: number | null = null
  customer_id: number | null = null
  form_of_address = ''
  first_name = ''
  last_name = ''
  full_name = ''
  status = ''
  status_string = ''
  is_paused = false
  deleted_at: Date | null = null
  initial_documents_generated = true
  is_pflegeherzen_customer = false
}
