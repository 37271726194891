<form class="dialog-form">
  <div class="dialog-form-body" *ngIf="histories.length === 0">
    <span class="content-no-message">Keine Historie vorhanden</span>
  </div>

  <div
    class="content-body dialog-max-height without-padding"
    *ngIf="histories.length > 0"
  >
    <p-table [value]="histories" [rowHover]="true" [autoLayout]="true">
      <ng-template pTemplate="header">
        <tr>
          <th style="min-width: 150px" pSortableColumn="type_text">
            Typ <p-sortIcon field="type_text"></p-sortIcon>
          </th>
          <th pSortableColumn="created_at">
            am <p-sortIcon field="created_at"></p-sortIcon>
          </th>
          <th style="width: 50px"></th>
          <th>Informationen</th>
          <th></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-history>
        <tr>
          <td>
            <strong>
              {{ history.type_text }}
            </strong>
          </td>

          <td>{{ history.occurred_at_string }}</td>

          <td style="width: 50px">
            <i
              tooltipPosition="left"
              [pTooltip]="history.phone_call.comment"
              class="pi pi-info-circle"
              *ngIf="history.phone_call?.comment"
            ></i>
          </td>
          <td>
            <span
              class="history-information"
              *ngIf="history.type === 'PHONE_CALL'"
            >
              <i><strong>Dauer:</strong> {{ history.phone_call.duration }}</i>
            </span>

            <span
              class="history-information"
              *ngIf="
                history.type === 'CONTACT_PERSON_CREATED' ||
                history.type === 'CONTACT_PERSON_UPDATED' ||
                history.type === 'PATIENT_CHANGED' ||
                history.type === 'CONTACT_PERSON_DEACTIVATED'
              "
            >
              <i><strong>Name:</strong> {{ history.manual_description }}</i>
            </span>

            <span
              class="history-information">
              <i
                ><b>{{ history.manual_title }}</b></i
              >
              <p class="history-text" [innerHTML]="history.manual_description" *ngIf="history.manual_description"></p>
              <p
                class="history-text"
                *ngIf="history.appointment_html_table"
                [innerHTML]="history.appointment_html_table"
              ></p>
            </span>
          </td>
          <td class="table-action"></td>
        </tr>
      </ng-template>
    </p-table>
  </div>

  <div class="dialog-form-footer">
    <i></i>
    <button
      pButton
      label="OK"
      type="submit"
      (click)="confirm($event)"
      icon="pi pi-check"
    ></button>
  </div>
</form>
