import { Component, OnDestroy, OnInit } from '@angular/core'
import { CaregiverService } from '../../../../../services/caregivers.service'
import { ActivatedRoute, ParamMap, Router } from '@angular/router'
import { HistoryService } from '../../../../../services/history.service'
import { LatestChangeModel } from '../../../../../models/history/latest-change.model'
import { CaregiverDetailGeneralModel } from '../../../../../models/caregiver/caregiver-detail-general.model'
import { DialogService } from 'primeng/dynamicdialog'
import {
  EventBusService,
  GlobalEvent,
} from '../../../../../services/eventbus.service'
import { Subscription } from 'rxjs'
import { PhoneCallService } from '../../../../../services/phone-call.service'
import { DiffViewHistoryDialogComponent } from '../../../../../components/dialogs/diff-view-history-dialog/diff-view-history-dialog.component'

@Component({
  selector: 'app-caregivers-detail-qualifications',
  templateUrl: './caregivers-detail-qualifications.component.html',
})
export class CaregiversDetailQualificationsComponent
  implements OnInit, OnDestroy {
  public loading = true
  public caregiver: CaregiverDetailGeneralModel = new CaregiverDetailGeneralModel()
  public latestChanges: LatestChangeModel = new LatestChangeModel()

  public caregiverId: string | null = null

  private routeSubscription: any = null
  private eventBusSubscription: Subscription = new Subscription()

  constructor(
    private caregiverService: CaregiverService,
    public phoneCallService: PhoneCallService,
    private route: ActivatedRoute,
    private eventbus: EventBusService,
    private router: Router,
    private historyService: HistoryService,
    private dialogService: DialogService
  ) {}

  ngOnInit(): void {
    if (this.route.parent) {
      this.routeSubscription = this.route.parent.paramMap.subscribe(
        (params: ParamMap) => {
          this.caregiverId = params.get('id')

          this.loadCaregiver()
        }
      )
    }

    this.listenForEventbus()
  }

  ngOnDestroy(): void {
    this.eventBusSubscription.unsubscribe()
    this.routeSubscription && this.routeSubscription.unsubscribe()
  }

  public getLatestChange(
    id: number | string | null | undefined,
    type: 'caregiver' | 'contact_person',
    data: { [key: string]: any }
  ): void {
    const keys = Object.keys(data)

    for (let key of keys) {
      data[key] = data[key] != null ? encodeURIComponent(data[key]) : ''
    }

    this.historyService
      .getLatestChangeMultiple(id || '', type, data)
      .subscribe((date: string) => {
        for (let key of keys) {
          this.latestChanges[key] = date
        }
      })
  }

  public openDiffViewHistory(
    id: number | string | null | undefined,
    type: 'caregiver' | 'contact_person',
    data: { [key: string]: any }
  ): void {
    const keys = Object.keys(data)

    for (let key of keys) {
      data[key] = data[key] != null ? encodeURIComponent(data[key]) : ''
    }

    this.dialogService.open(DiffViewHistoryDialogComponent, {
      data: {
        id,
        type,
        data,
      },
      header: 'Änderungen ansehen',
      styleClass: 'dialog-diff-view',
      dismissableMask: true,
    })
  }

  public loadCaregiver(withLoading: boolean = true): void {
    if (withLoading) {
      this.loading = true
    }

    this.caregiverService
      .load(this.caregiverId)
      .subscribe((data: CaregiverDetailGeneralModel) => {
        this.caregiver = data

        if (withLoading) {
          this.loading = false
        }
      })
  }

  private listenForEventbus(): void {
    this.eventBusSubscription = this.eventbus.subject.subscribe(
      (event: GlobalEvent) => {
        switch (event) {
          case GlobalEvent.CaregiverDetailReload:
          case GlobalEvent.TimesChanged:
          case GlobalEvent.NoticeChanged:
            this.loadCaregiver(false)
        }
      }
    )
  }
}
