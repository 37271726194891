<form #form="ngForm" class="dialog-form">
  <div class="dialog-form-body">
    <div
      class="p-fluid p-formgrid p-grid without-border"
      *ngIf="!data.isEdit && patients.length > 0"
    >
      <div class="p-field p-col-12">
        <label for="patient">Patient *</label>
        <p-dropdown
          inputId="patient"
          name="patient"
          placeholder="Bitte auswählen"
          [options]="patients"
          [(ngModel)]="values.patient_id"
          optionLabel="full_name"
          optionValue="id"
          (onChange)="loadAppointments()"
          [required]="true"
          #patient="ngModel"
          [class.p-invalid]="
            patient.invalid && (patient.dirty || patient.touched)
          "
        ></p-dropdown>
      </div>
    </div>

    <div class="p-fluid p-formgrid p-grid without-border" *ngIf="!data.isEdit">
      <div class="p-field p-col-7">
        <label for="month">Monat *</label>
        <p-dropdown
          scrollHeight="500px"
          inputId="month"
          name="month"
          appendTo="body"
          (onChange)="loadAppointments()"
          [options]="monthOptions"
          [(ngModel)]="values.month"
        ></p-dropdown>
      </div>

      <div class="p-field p-col-5">
        <label for="year">Jahr *</label>
        <p-dropdown
          inputId="year"
          name="year"
          (onChange)="loadAppointments()"
          [options]="yearOptions"
          [(ngModel)]="values.year"
        ></p-dropdown>
      </div>
    </div>

    <div class="p-field" *ngIf="!data.isEdit">
      <label for="appointments">Auswahl der Termine *</label>
      <p-multiSelect
        inputId="appointments"
        [options]="appointmentOptions"
        optionLabel="label"
        (onChange)="appointmentChanged()"
        scrollHeight="400px"
        [filter]="true"
        emptyMessage="Keine Termine vorhanden"
        appendTo="body"
        [showHeader]="false"
        name="appointments"
        [(ngModel)]="values.appointments"
        [required]="!values.appointment_saved"
        #appointments="ngModel"
        [class.p-invalid]="
          appointments.invalid && (appointments.dirty || appointments.touched)
        "
      ></p-multiSelect>
    </div>

    <div class="p-fluid p-formgrid p-grid without-border" *ngIf="data.isEdit">
      <div class="p-field p-col-4">
        <label>Patient</label>
        <strong>{{ data.date.cancelled_date.patient.full_name }}</strong>
      </div>

      <div class="p-field p-col-4">
        <label>Betreuungskraft</label>
        <strong>{{ data.date.caregiver.full_name }}</strong>
      </div>

      <div class="p-field p-col-4">
        <label>Termin</label>
        <strong
          >{{ data.date.date_with_time | date: "dd.MM.y, H:mm" }} -
          {{ data.date.to }}</strong
        >
      </div>
    </div>

    <div class="p-field">
      <label for="reason">Grund</label>
      <p-dropdown
        *ngIf="!data.isEdit || !values.from_storno"
        scrollHeight="360px"
        inputId="reason"
        name="reason"
        placeholder="Bitte auswählen"
        [options]="reasons"
        [(ngModel)]="values.cancelled_reason_id"
        optionLabel="name"
        optionValue="id"
        appendTo="body"
      ></p-dropdown>

      <p-dropdown
        *ngIf="data.isEdit && values.from_storno"
        scrollHeight="360px"
        inputId="reason"
        name="reason"
        placeholder="Bitte auswählen"
        [options]="stornoReasons"
        [(ngModel)]="values.storno_reason_id"
        optionLabel="reason"
        optionValue="id"
        appendTo="body"
      ></p-dropdown>
    </div>

    <div class="p-field" *ngIf="isCurrentMonth && lnwIsAvailable">
      <label for="lnw">Was ist mit LNW? *</label>
      <textarea
        id="lnw"
        name="lnw"
        [(ngModel)]="values.lnw"
        [rows]="2"
        pInputTextarea
        [required]="true"
        #lnw="ngModel"
        [class.p-invalid]="lnw.invalid && (lnw.dirty || lnw.touched)"
      ></textarea>
      <small
        style="display: flex; align-items: center; gap: 5px"
        class="color-blue"
        ><i class="pi pi-info-circle"></i>Es wird eine automatische Todo
        erstellt</small
      >
    </div>

    <div class="p-field">
      <label for="comment">Bemerkung</label>
      <textarea
        id="comment"
        name="comment"
        [(ngModel)]="values.comment"
        [rows]="2"
        pInputTextarea
      ></textarea>
    </div>
  </div>
  <div class="dialog-form-footer">
    <i *ngIf="!data.isEdit"></i>
    <i
      class="pi pi-trash"
      *ngIf="data.isEdit"
      [class.pi-trash]="!submittedDelete"
      [class.pi-spin]="submittedDelete"
      [class.pi-spinner]="submittedDelete"
      tooltipPosition="left"
      tooltipZIndex="20000"
      [pTooltip]="submittedDelete ? 'Wird gelöscht' : 'Absage löschen'"
      (click)="remove($event)"
    ></i>

    <button
      (click)="store()"
      pButton
      [label]="data.isEdit ? 'Speichern' : 'Erstellen'"
      type="button"
      [disabled]="submitted"
      [icon]="submitted ? 'pi pi-spin pi-spinner' : 'pi pi-check'"
    ></button>
  </div>
</form>

<p-confirmPopup></p-confirmPopup>
