import { Component, OnInit } from '@angular/core'
import { DocumentService } from '../../../services/document.service'
import {
  DialogService,
  DynamicDialogConfig,
  DynamicDialogRef,
} from 'primeng/dynamicdialog'
import { PatientService } from '../../../services/patient.service'
import { HistoryListModel } from '../../../models/history/history-list.model'

@Component({
  selector: 'app-show-histories-dialog',
  templateUrl: './show-histories-dialog.component.html',
})
export class ShowHistoriesDialogComponent implements OnInit {
  public histories: HistoryListModel[] = []

  constructor(
    public documentService: DocumentService,
    private ref: DynamicDialogRef,
    private patientService: PatientService,
    private config: DynamicDialogConfig,
    private dialogService: DialogService
  ) {}

  public ngOnInit(): void {
    this.histories = this.config.data.histories

    console.log(this.histories);
  }

  public confirm(event: any): void {
    event.preventDefault()

    this.ref.close()
  }
}
