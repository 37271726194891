import { Component, ElementRef, OnInit, ViewChild } from '@angular/core'
import { Router } from '@angular/router'
import { TitleService } from 'src/app/services/title.service'
import { DialogService } from 'primeng/dynamicdialog'
import { DesiredDateService } from '../../../services/desired-date.service'
import { PhoneCallService } from '../../../services/phone-call.service'
import {
  EventBusService,
  GlobalEvent,
} from '../../../services/eventbus.service'
import { Subscription } from 'rxjs'
import { CancelledDateService } from '../../../services/cancelled-date.service'
import { AddCancelledDateDialogComponent } from '../../../components/dialogs/add-cancelled-date-dialog/add-cancelled-date-dialog.component'

@Component({
  selector: 'app-cancelled-dates-last',
  templateUrl: './cancelled-dates-last.component.html',
})
export class CancelledDatesLastComponent implements OnInit {
  @ViewChild('dt') table: ElementRef | any = null
  public firstPage = 0

  public filters = {
    current_month: false,
    next_month: false,
  }

  public globalFilterValue = ''
  public loading = false
  public contentLoading = true

  private eventBusSubscription: Subscription = new Subscription()

  // TODO: model erstellen
  public cancelledDateAppointments: any = []

  constructor(
    public phoneCallService: PhoneCallService,
    private dialogService: DialogService,
    private eventbus: EventBusService,
    private cancelledDateService: CancelledDateService
  ) {}

  ngOnInit(): void {
    this.loadData()
    this.listenForEventbus()
  }

  public filterTableGlobal(event: any): void {
    this.table && this.table.filterGlobal(event.target.value.trim(), 'contains')
  }

  // TODO: model erstellen
  public openAddCancelledDateDialog(cancelledDateAppointment: any): void {
    this.dialogService.open(AddCancelledDateDialogComponent, {
      header: 'Absage bearbeiten',
      width: '640px',
      styleClass: 'dialog-container',
      data: {
        isEdit: true,
        date: cancelledDateAppointment,
      },
    })
  }

  private listenForEventbus(): void {
    this.eventBusSubscription = this.eventbus.subject.subscribe(
      (event: GlobalEvent) => {
        switch (event) {
          case GlobalEvent.CancelledDateChanged:
            this.loadData(false)
        }
      }
    )
  }

  public loadData(withLoading: boolean = true): void {
    this.contentLoading = true

    if (withLoading) {
      this.loading = true
    }

    // TODO: model erstellen
    this.cancelledDateService.loadLast(this.filters).subscribe((data: any) => {
      this.cancelledDateAppointments = data

      this.loading = false
      this.contentLoading = false

      setTimeout(() => {
        if (this.table) {
          const totalItems = this.table._totalRecords

          this.globalFilterValue = this.table.filters.global?.value ?? ''

          if (this.table._first > totalItems || this.table._rows > totalItems) {
            this.firstPage = 0
          }
        }
      })
    })
  }
}
