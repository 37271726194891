<app-loader [loading]="loading"></app-loader>

<div class="content" *ngIf="!loading">
  <header class="content-header">
    <span>Alle deaktivierten Bewerber</span>

    <div class="content-header-buttons">
      <a routerLink="/caregivers/new"
         *ngIf="authService.can('Betreuungskraft.Betreuungskraft erstellen')"
      >
        <button
          pButton
          label="Neuer Bewerber"
          type="button"
          icon="pi pi-plus"
          iconPos="left"
          class="p-button-outlined"
        ></button>
      </a>
      <div
        class="table-search-container"
        [class.searchfield-active]="globalFilterValue != ''"
        *ngIf="caregivers.length > 0"
      >
        <div class="p-input-icon-left">
          <i class="pi pi-search"></i>
          <input
            pInputText
            (input)="filterTableGlobal($event)"
            placeholder="Suche..."
            [(ngModel)]="globalFilterValue"
          />
        </div>
      </div>
    </div>
  </header>
</div>

<div class="content" style="margin-bottom: 0" *ngIf="!loading">
  <div class="content-body" *ngIf="caregivers.length == 0">
    <span class="content-no-message"
      >Keine deaktivierten Betreuungskräfte vorhanden</span
    >
  </div>

  <div
    class="content-body content-body-full-height-with-tabs without-padding"
    *ngIf="caregivers.length > 0"
  >
    <p-table
      #dt
      [value]="caregivers"
      [globalFilterFields]="[
        'id',
        'persplan_id',
        'first_name',
        'last_name',
        'zipcode',
        'city'
      ]"
      stateStorage="local"
      stateKey="state-caregivers-list-applicants-deactivated-2"
      [(first)]="firstPage"
      [rowHover]="true"
      [showCurrentPageReport]="true"
      currentPageReportTemplate="{totalRecords} deaktiviert"
      [rows]="50"
      [paginator]="true"
      scrollHeight="100%"
      [scrollable]="true"
    >
      <ng-template pTemplate="header">
        <tr class="table-headline-highlight">
          <th pSortableColumn="last_name">
            Name <p-sortIcon field="last_name"></p-sortIcon>
          </th>
          <th pSortableColumn="city">
            Wohnort <p-sortIcon field="city"></p-sortIcon>
          </th>
          <th pSortableColumn="last_deactivation.created_at">
            Deaktiviert am
            <p-sortIcon field="last_deactivation.created_at"></p-sortIcon>
          </th>
          <th>
            Deaktiviert von
            <p-sortIcon></p-sortIcon>
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-caregiver>
        <tr>
          <td>
            <a class="color-main1" routerLink="/caregivers/{{ caregiver.id }}">
              {{ caregiver.last_first_name }}
            </a>
          </td>
          <td>{{ caregiver.city }}</td>
          <td>
            {{ caregiver.last_deactivation.created_at | date: "dd.MM.y, H:mm" }}
            Uhr
          </td>
          <td>{{ caregiver.last_deactivation.created_from_name }}</td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
