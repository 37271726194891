<form #form="ngForm" class="dialog-form">
  <div class="dialog-sidebar secondary-dialog-sidebar smaller-dialog-sidebar">
    <div class="p-fluid p-formgrid p-grid without-border">
      <div class="p-field p-col-12">
        <button
          (click)="selectMessage()"
          pButton
          label="Neue Mitteilung"
          class="p-button-outlined"
          type="button"
        ></button>
      </div>
    </div>

    <div class="p-fluid p-formgrid p-grid without-border">
      <div class="p-field p-col-12">Vergangene Mitteilungen</div>

      <div class="p-field p-col-12" *ngIf="systemMessages.length === 0">
        <small class="color-gray">Noch keine Mitteilungen versendet</small>
      </div>

      <div class="p-field p-col-12" *ngIf="systemMessages.length > 0">
        <div
          [class.highlighted]="selectedMessage?.id === message.id"
          class="dialog-sidebar-comment selectable"
          (click)="selectMessage(message)"
          *ngFor="let message of systemMessages"
        >
          <strong>{{ message.subject }}</strong>
          <span
            >{{ message.created_from.name }} am
            {{ message.created_at | date: "dd.MM.y, H:mm" }} Uhr</span
          >
        </div>
      </div>
    </div>
  </div>

  <div
    class="dialog-sidebar space-between smaller-dialog-sidebar"
    *ngIf="!selectedMessage"
  >
    <div class="p-fluid p-formgrid p-grid without-border">
      <div class="p-field p-col-12">
        <div class="dialog-text-link">
          <span (click)="openAddDocumentDialog()">Dokument hochladen</span>
        </div>
      </div>
    </div>
    <div class="p-fluid p-formgrid p-grid without-border">
      <div class="p-field p-col-12">
        <label class="highlight-label">Hochgeladene Dokumente</label>
        <div class="dialog-no-message" *ngIf="documents.length === 0">
          Keine Dokumente hochgeladen
        </div>
        <div class="document-list" *ngFor="let document of documents">
          <span
            >{{ document.name }}
            <a
              [href]="documentService.getDocumentDownloadLink(document.uuid)"
              target="_blank"
              >(ansehen)</a
            ></span
          >
        </div>
      </div>
    </div>
  </div>

  <div class="dialog-sidebar smaller-dialog-sidebar" *ngIf="selectedMessage">
    <div class="p-fluid p-formgrid p-grid without-border">
      <div class="p-field p-col-12">
        <label class="highlight-label">Versendete Dokumente</label>
        <div class="dialog-no-message" *ngIf="documents.length === 0">
          Keine Dokumente versendet
        </div>
        <div class="document-list" *ngFor="let document of documents">
          <span
            >{{ document.name }}
            <a
              [href]="documentService.getDocumentDownloadLink(document.uuid)"
              target="_blank"
              >(ansehen)</a
            ></span
          >
        </div>
      </div>
    </div>

    <div
      class="p-fluid p-formgrid p-grid without-border full-height-file-input"
    >
      <div class="p-field p-col-12">
        <div
          class="system-message-assignments"
          *ngFor="let assignment of selectedMessage.assignments"
        >
          <div>
            <div class="system-message-name">
              {{ assignment.caregiver.first_name }}
              {{ assignment.caregiver.last_name }}
            </div>
            <span class="system-message-value">
              {{ assignment.email || "KEINE E-MAIL" }}
            </span>
          </div>
          <i
            class="pi"
            [class.pi-check]="assignment.sent_status === 'DISPATCHED'"
            [class.pi-times]="assignment.sent_status === 'ERROR'"
            [class.color-alert]="assignment.sent_status === 'ERROR'"
            [pTooltip]="
              assignment.sent_status === 'DISPATCHED' ? 'Versendet' : 'Fehler'
            "
          ></i>
        </div>
      </div>
    </div>
  </div>

  <div class="dialog-form-body">
    <div
      class="p-fluid p-formgrid p-grid without-border"
      *ngIf="!selectedMessage"
    >
      <div class="p-field p-col-12">
        <label for="caregivers">Betreuungskräfte *</label>
        <p-multiSelect
          inputId="caregivers"
          [options]="caregiverOptions"
          optionLabel="label"
          [filter]="true"
          [showHeader]="true"
          name="caregivers"
          [(ngModel)]="values.caregivers"
          optionValue="id"
          scrollHeight="500px"
          appendTo="body"
          [required]="true"
          #caregivers="ngModel"
          [class.p-invalid]="
            caregivers.invalid && (caregivers.dirty || caregivers.touched)
          "
        ></p-multiSelect>
        <div
          *ngIf="caregivers.invalid && (caregivers.dirty || caregivers.touched)"
          class="p-invalid"
        >
          <div *ngIf="caregivers.errors?.required">
            <small>Bitte wählen Sie eine Betreuungskraft aus</small>
          </div>
        </div>
      </div>

      <div class="p-field p-col-12">
        <label for="patient">Patient </label>
        <p-autoComplete
          field="full_name"
          inputId="patient"
          name="patient"
          appendTo="body"
          [forceSelection]="true"
          [(ngModel)]="values.patient"
          [suggestions]="patients"
          (completeMethod)="searchPatients($event)"
        >
          <ng-template let-patient pTemplate="item">
            <div class="search-result-data">
              <span class="search-result-name">
                <b>{{ patient.full_name }}</b>
                <span class="search-result-id">#{{ patient.id }}</span>
                <i
                  *ngIf="patient.status"
                  class="p-tag p-tag-rounded p-tag-{{ patient.status }}"
                  >{{ patient.status_string }}</i
                >
              </span>
              <span class="search-result-address">
                {{ patient.zipcode }} {{ patient.city }}
              </span>
            </div>
          </ng-template>
        </p-autoComplete>
      </div>
    </div>

    <div class="p-fluid p-formgrid p-grid without-border">
      <div class="p-field p-col-12" *ngIf="!selectedMessage">
        <label for="subject">Betreff *</label>
        <input
          id="subject"
          name="subject"
          type="text"
          [(ngModel)]="values.subject"
          pInputText
          #subject="ngModel"
          required
        />
        <div
          *ngIf="subject.invalid && (subject.dirty || subject.touched)"
          class="p-invalid"
        >
          <div *ngIf="subject.errors?.required">
            <small>Bitte geben Sie einen Betreff ein</small>
          </div>
        </div>
      </div>

      <div class="p-field p-col-12" *ngIf="selectedMessage">
        <label for="subject">Betreff</label>
        <strong>{{ selectedMessage.subject }}</strong>
      </div>

      <div
        class="p-field p-col-12"
        *ngIf="selectedMessage && selectedMessage.patient"
      >
        <label for="subject">Patient</label>
        <a
          target="_blank"
          style="color: currentColor"
          routerLink="/patients/{{ selectedMessage.patient_id }}"
        >
          <strong
            >{{ selectedMessage.patient.first_name }}
            {{ selectedMessage.patient.last_name }}</strong
          ><i
            style="font-size: 12px; margin: 0 0 0 5px"
            class="color-gray pi pi-arrow-up-right"
          ></i>
        </a>
      </div>
    </div>

    <div
      class="p-fluid p-formgrid p-grid without-border bigger-jodit-font-size"
    >
      <div class="p-field p-col-12" *ngIf="!selectedMessage">
        <label for="text"
          >Inhalt *
          <i
            class="pi pi-info-circle"
            tooltipStyleClass="tooltip-wider"
            pTooltip="Platzhalter:<br><br>$anrede = Die Anrede<br>$vorname = Der Vorname<br>$nachname = Der Nachname"
            [escape]="false"
          ></i
        ></label>

        <ngx-jodit [(value)]="values.text" [options]="options"></ngx-jodit>
      </div>

      <div
        class="p-field p-col-12"
        style="min-height: 380px"
        *ngIf="selectedMessage"
      >
        <label for="text"
          >Inhalt
          <i
            class="pi pi-info-circle"
            tooltipStyleClass="tooltip-wider"
            pTooltip="Platzhalter:<br><br>$anrede = Die Anrede<br>$vorname = Der Vorname<br>$nachname = Der Nachname"
            [escape]="false"
          ></i
        ></label>
        <div
          style="white-space: pre-wrap"
          [innerHTML]="selectedMessage.text"
        ></div>
      </div>
    </div>
  </div>
  <div class="dialog-form-footer" *ngIf="!selectedMessage">
    <a
      target="_blank"
      (click)="clickedOnPreview = true"
      [href]="systemMessageService.getMailPreviewLink(values)"
    >
      <button
        pButton
        label="Mail Vorschau"
        type="button"
        icon="pi pi-eye"
        iconPos="left"
        class="p-button p-button-info"
      ></button>
    </a>

    <button
      pButton
      label="Versenden"
      type="submit"
      (click)="save()"
      [disabled]="submitted || !clickedOnPreview"
      [icon]="submitted ? 'pi pi-spin pi-spinner' : 'pi pi-check'"
    ></button>
  </div>
</form>
