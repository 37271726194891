import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core'
import { AuthService } from '../../../../services/auth.service'
import { AccountingReturnModel } from '../../../../models/accounting/accounting-return.model'
import { DocumentService } from '../../../../services/document.service'
import { DialogService } from 'primeng/dynamicdialog'
import {
  EventBusService,
  GlobalEvent,
} from '../../../../services/eventbus.service'
import { Subscription } from 'rxjs'
import { HelperService } from '../../../../services/helper.service'
import { PatientService } from '../../../../services/patient.service'
import { InvoiceService } from '../../../../services/invoice.service'
import { InvoiceViewDialogComponent } from '../../../../components/dialogs/invoice-view-dialog/invoice-view-dialog.component'

@Component({
  selector: 'app-invoices-consultings-opened',
  templateUrl: './invoices-consultings-opened.component.html',
})
export class InvoicesConsultingsOpenedComponent implements OnInit, OnDestroy {
  @ViewChild('dt') table: ElementRef | any = null

  public data: AccountingReturnModel[] = []
  public loading = true
  public firstPage = 0
  public globalFilterValue = ''

  public correctionReasons: any = {}

  private eventBusSubscription: Subscription = new Subscription()

  constructor(
    private invoiceService: InvoiceService,
    public authService: AuthService,
    private dialogService: DialogService,
    public patientService: PatientService,
    public documentService: DocumentService,
    private eventbus: EventBusService,
    private helperService: HelperService
  ) {}

  ngOnInit(): void {
    this.loadData()
    this.listenForEventbus()

    // this.helperService.dependencies$.subscribe((data: any) => {
    //   data['accounting_return_reasons'].forEach((reason: any) => {
    //     this.correctionReasons[reason.value] = reason.label
    //   })
    // })
  }

  ngOnDestroy(): void {
    this.eventBusSubscription.unsubscribe()
  }

  public openInvoiceDialog(invoice: any): void {
    this.dialogService.open(InvoiceViewDialogComponent, {
      header: 'Rechnung',
      width: '96%',
      height: '90%',
      styleClass: 'dialog-container',
      data: {
        invoice,
      },
    })
  }

  private listenForEventbus(): void {
    this.eventBusSubscription = this.eventbus.subject.subscribe((event) => {
      switch (event) {
        case GlobalEvent.InvoiceChanged:
          this.loadData(false)
          break
      }
    })
  }

  public filterTableGlobal(event: any): void {
    this.table && this.table.filterGlobal(event.target.value.trim(), 'contains')
  }

  public loadData(withLoading: boolean = true): void {
    if (withLoading) {
      this.loading = true
    }

    this.invoiceService.loadOpenedConsulting().subscribe((data: any[]) => {
      this.data = data

      if (withLoading) {
        this.loading = false
      }

      setTimeout(() => {
        if (this.table) {
          const totalItems = this.table._totalRecords

          this.globalFilterValue = this.table.filters.global?.value ?? ''

          if (this.table._first > totalItems || this.table._rows > totalItems) {
            this.firstPage = 0
          }
        }
      })
    })
  }
}
