<app-loader [loading]="loading"></app-loader>

<div class="content" *ngIf="!loading">
  <header class="content-header is-sticky">
    <span>
      <i class="pi pi-clock"></i>
      Komplette Historie
    </span>

    <div class="content-header-buttons">
      <p-checkbox
        label="Benutzer Aktivitäten anzeigen"
        [binary]="true"
        [(ngModel)]="withUserActivities"
        name="with_user_activities"
      ></p-checkbox>

      <p-checkbox
        label="nur wichtige Ereignisse"
        [binary]="true"
        [(ngModel)]="onlyImportant"
        name="only_assigned"
      ></p-checkbox>

      <button
        pButton
        (click)="openManualHistoryDialog(false)"
        label="Neuer Eintrag"
        type="button"
        class="p-button-outlined"
        icon="pi pi-plus"
        iconPos="left"
      ></button>

      <div
        class="table-search-container"
        [class.searchfield-active]="globalFilterValue != ''"
        *ngIf="histories.length > 0"
      >
        <div class="p-input-icon-left">
          <i class="pi pi-search"></i>
          <input
            pInputText
            (input)="filterTableGlobal($event)"
            placeholder="Suche..."
            [(ngModel)]="globalFilterValue"
          />
        </div>
      </div>
    </div>
  </header>
</div>

<div class="content" style="margin: 0" *ngIf="!loading">
  <div class="content-body auto-height" *ngIf="histories.length === 0">
    <span class="content-no-message">Keine History vorhanden</span>
  </div>
  <div
    class="content-body without-padding auto-height"
    *ngIf="histories.length > 0"
  >
    <p-table
      #dt
      [value]="histories"
      [rowHover]="true"
      [globalFilterFields]="[
        'type_text',
        'phone_call.comment',
        'manual_title',
        'manual_description'
      ]"
    >
      <ng-template pTemplate="header">
        <tr class="table-headline-highlight">
          <th style="width: 200px" pSortableColumn="type_text">
            Typ <p-sortIcon field="type_text"></p-sortIcon>
          </th>
          <th style="width: 200px" pSortableColumn="created_at">
            am <p-sortIcon field="created_at"></p-sortIcon>
          </th>
          <th style="width: 200px" pSortableColumn="created_from_name">
            von <p-sortIcon field="created_from_name"></p-sortIcon>
          </th>
          <th style="width: 50px"></th>
          <th>Informationen</th>
          <th style="width: 100px"></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-history>
        <tr
          [class.background-hightlight-yellow]="history.important"
          [hidden]="
            (onlyImportant && !history.important) ||
            (!withUserActivities && history.type === 'USER_CAREGIVER_ACTIVITY')
          "
        >
          <td>
            <strong>
              <!--              <i-->
              <!--                class="pi pi-star color-orange"-->
              <!--                *ngIf="history.important"-->
              <!--                pTooltip="Wichtiges Ereignis"-->
              <!--              ></i>-->

              {{ history.type_text }}
            </strong>
          </td>

          <td>{{ history.occurred_at_string }} Uhr</td>

          <td>{{ history.created_from_name || "??" }}</td>

          <td></td>
          <td>
            <span
              class="history-information"
              *ngIf="history.type === 'PHONE_CALL'"
            >
              <i
                ><strong>Dauer:</strong>
                {{ history.phone_call.duration || "N/A" }}</i
              >
              <strong
                class="history-text"
                *ngIf="history.phone_call?.not_reached"
                >Nicht erreicht</strong
              >
              <p class="history-text" *ngIf="history.phone_call?.comment">
                {{ history.phone_call.comment }}
              </p>
            </span>

            <span
              class="history-information"
              *ngIf="
                history.type === 'CONTACT_PERSON_CREATED' ||
                history.type === 'CONTACT_PERSON_UPDATED' ||
                history.type === 'CONTACT_PERSON_DEACTIVATED'
              "
            >
              <i><strong>Name:</strong> {{ history.manual_description }}</i>
            </span>

            <span
              class="history-information"
              *ngIf="
                history.type === 'MANUAL' ||
                history.type === 'CAREGIVER_ACCULUMATED_UNRELEASED' ||
                history.type === 'CAREGIVER_ACCULUMATED_RELEASED' ||
                history.type === 'TODO_CREATED' ||
                history.type === 'TODO_DELETED' ||
                history.type === 'TODO_FINISHED' ||
                history.type === 'TODO_UPDATED' ||
                history.type === 'VETO_CREATED' ||
                history.type === 'VETO_DELETED' ||
                history.type === 'VETO_UPDATED' ||
                history.type === 'VACATION_CREATED' ||
                history.type === 'VACATION_DELETED' ||
                history.type === 'VACATION_UPDATED' ||
                history.type === 'TRAINING_CREATED' ||
                history.type === 'TRAINING_DELETED' ||
                history.type === 'TRAINING_UPDATED' ||
                history.type === 'ILL_CREATED' ||
                history.type === 'ILL_DELETED' ||
                history.type === 'ILL_UPDATED' ||
                history.type === 'FREE_TIME_REQUEST_CREATED' ||
                history.type === 'FREE_TIME_REQUEST_DELETED' ||
                history.type === 'FREE_TIME_REQUEST_UPDATED' ||
                history.type === 'APPROVED_PLANNING_CREATED' ||
                history.type === 'APPROVED_PLANNING_DELETED' ||
                history.type === 'APPROVED_PLANNING_UPDATED' ||
                history.type === 'INTERN_DATE_CREATED' ||
                history.type === 'INTERN_DATE_DELETED' ||
                history.type === 'INTERN_DATE_UPDATED' ||
                history.type === 'OTHER_DATE_CREATED' ||
                history.type === 'OTHER_DATE_DELETED' ||
                history.type === 'OTHER_DATE_UPDATED' ||
                history.type === 'DOCUMENT_CHANGED' ||
                history.type === 'DOCUMENT_REMOVED' ||
                history.type === 'DOCUMENT_ARCHIVED' ||
                history.type === 'DOCUMENT_UNARCHIVED' ||
                history.type === 'CAR_ASSIGNED_TO_CAREGIVER' ||
                history.type === 'CAR_ASSIGNMENT_TO_CAREGIVER_CHANGED' ||
                history.type === 'DRIVE_TIME_MANUALLY_CHANGED' ||
                history.type === 'CAREGIVER_TIME_CORRECTION' ||
                history.type === 'CAREGIVER_PERSPLAN_TIME_CORRECTION' ||
                history.type === 'JOB_SCOPE_ADDED' ||
                history.type === 'JOB_DAYS_ADDED' ||
                history.type === 'EMPLOYMENT_ADDED' ||
                history.type === 'ADMIN_OVERVIEW_ACTIVITY' ||
                history.type === 'EMPLOYMENT_UPDATED' ||
                history.type === 'EMPLOYMENT_DELETED' ||
                history.type === 'STATUS_CHANGED' ||
                history.type === 'CAREGIVER_EMAIL_SENT' ||
                history.type === 'CAR_ASSIGNMENT_DELETED' ||
                history.type === 'CREATED' ||
                history.type === 'CAREGIVER_PROOF_CREATED' ||
                history.type === 'USER_CAREGIVER_ACTIVITY' ||
                history.type === 'CAREGIVER_TIMES_CHANGED' ||
                history.type === 'APPOINTMENT_FEEDBACK_FINISHED_WITH_HISTORY' ||
                history.type === 'SYSTEM_MESSAGE_SENT'
              "
            >
              <i
                ><b>{{ history.manual_title }}</b
                ><i
                  class="pi pi-file"
                  tooltipPosition="left"
                  pTooltip="Mit Dokument"
                  *ngIf="history.documents.length > 0"
                ></i
              ></i>
              <p
                class="history-text"
                [innerHTML]="history.manual_description"
                *ngIf="history.manual_description"
              ></p>
            </span>
          </td>
          <td class="table-action" style="width: 100px">
            <i
              (click)="openDiffView(history.id)"
              tooltipPosition="left"
              pTooltip="Änderungen ansehen"
              *ngIf="
                history.type === 'CHANGED' ||
                history.type === 'NOTICE_CHANGED' ||
                history.type === 'CONTACT_PERSON_UPDATED' ||
                history.type === 'CAR_ASSIGNMENT_TO_CAREGIVER_CHANGED'
              "
              class="pi pi-eye"
            ></i>
            <i
              *ngIf="
                history.type === 'TODO_CREATED' ||
                history.type === 'TODO_FINISHED' ||
                history.type === 'TODO_UPDATED'
              "
              class="pi pi-eye"
              tooltipPosition="left"
              pTooltip="Todo ansehen"
              (click)="openShowTodoDialog(history.todo)"
            ></i>
            <i
              *ngIf="history.type === 'PHONE_CALL'"
              class="pi pi-pencil"
              (click)="openPhonecallEditModal(history.phone_call)"
            ></i>
            <i
              *ngIf="history.type === 'MANUAL'"
              (click)="openManualHistoryDialog(true, history)"
              class="pi pi-pencil"
            ></i>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
