<form #form="ngForm" class="dialog-form">
  <div class="dialog-form-body">
    <div
      class="content-no-message simple-text"
      *ngIf="config.data.type === 'LOCK'"
    >
      Terminversand für <b>{{ config.data.patient.full_name }}</b> sperren?
    </div>

    <div
      class="content-no-message simple-text"
      *ngIf="config.data.type === 'UNLOCK'"
    >
      Terminversand für <b>{{ config.data.patient.full_name }}</b> entsperren?
    </div>
  </div>
  <div class="dialog-form-footer">
    <i *ngIf="config.data.type === 'LOCK'"></i>

    <div class="p-field-checkbox" *ngIf="config.data.type === 'UNLOCK'">
      <p-checkbox
        name="todo"
        [binary]="true"
        [(ngModel)]="values.with_todo"
        label="Todo erstellen"
      ></p-checkbox>
    </div>

    <button
      *ngIf="config.data.type === 'UNLOCK'"
      pButton
      label="Entsperren"
      type="submit"
      (click)="change()"
      [disabled]="submitted"
      [icon]="submitted ? 'pi pi-spin pi-spinner' : 'pi pi-unlock'"
    ></button>

    <button
      *ngIf="config.data.type === 'LOCK'"
      pButton
      label="Sperren"
      type="submit"
      (click)="change()"
      [disabled]="submitted"
      [icon]="submitted ? 'pi pi-spin pi-spinner' : 'pi pi-lock'"
    ></button>
  </div>
</form>
