export class ContactPersonModel {
  id: number | null = null
  full_name = ''
  form_of_address = ''
  first_name = ''
  last_name = ''
  relation = ''
  street_house_number = ''
  zipcode = ''
  city = ''
  local_specialties = ''
  city_part = ''
  county = ''
  state = ''
  email_private = ''
  email_work = ''
  email_private_info = ''
  email_work_info = ''
  birthday: Date | null = null
  birthday_string = ''
  phone_1 = ''
  phone_2 = ''
  phone_1_info = ''
  phone_2_info = ''
  comment = ''
  informations_string = ''
  is_for_appointments = false
  is_for_invoices = false
  is_at_home = false
  is_social_office = false
  is_emergency = false
  is_same_house = false
  contact_personable_id = 0
  contact_personable_type = ''
  deleted_at: Date | null = null
  created_at: Date | null = null
  updated_at: Date | null = null
}
