import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core'
import { AuthService } from '../../../../services/auth.service'
import { AccountingReturnModel } from '../../../../models/accounting/accounting-return.model'
import { AccountingReturnService } from '../../../../services/accounting-return.service'
import { DocumentService } from '../../../../services/document.service'
import { AddAccountingReturnDialogComponent } from '../../../../components/dialogs/add-accounting-return-dialog/add-accounting-return-dialog.component'
import { DialogService } from 'primeng/dynamicdialog'
import {
  EventBusService,
  GlobalEvent,
} from '../../../../services/eventbus.service'
import { Subject, Subscription } from 'rxjs'
import { HelperService } from '../../../../services/helper.service'
import { PatientService } from '../../../../services/patient.service'
import { InvoiceService } from '../../../../services/invoice.service'
import { InvoiceDialogComponent } from '../../../../components/dialogs/invoice-dialog/invoice-dialog.component'
import { switchMap } from 'rxjs/operators'

@Component({
  selector: 'app-invoices-appointments-credit',
  templateUrl: './invoices-appointments-credit.component.html',
})
export class InvoicesAppointmentsCreditComponent implements OnInit, OnDestroy {
  @ViewChild('dt') table: ElementRef | any = null

  private loadTrigger$ = new Subject<void>()
  private subscription: Subscription = new Subscription()

  public data: any = {}
  public loading = true
  public firstPage = 0

  public contentLoading = true

  public globalFilterValue = ''

  public correctionReasons: any = {}

  private eventBusSubscription: Subscription = new Subscription()

  constructor(
    private invoiceService: InvoiceService,
    public authService: AuthService,
    private dialogService: DialogService,
    public patientService: PatientService,
    public documentService: DocumentService,
    private eventbus: EventBusService,
    private helperService: HelperService
  ) {}

  ngOnInit(): void {
    this.activateDataLoading()

    this.loadData()
    this.listenForEventbus()
  }

  ngOnDestroy(): void {
    this.eventBusSubscription.unsubscribe()
    this.subscription.unsubscribe()
  }

  private listenForEventbus(): void {
    this.eventBusSubscription = this.eventbus.subject.subscribe((event) => {
      switch (event) {
        case GlobalEvent.InvoiceChanged:
          this.loadData(false)
          break
      }
    })
  }

  public filterTableGlobal(event: any): void {
    this.table && this.table.filterGlobal(event.target.value.trim(), 'contains')
  }

  public loadData(withLoading: boolean = true): void {
    this.contentLoading = true

    if (withLoading) {
      this.loading = true
    }

    this.loadTrigger$.next()
  }

  private activateDataLoading(): void {
    this.subscription.add(
      this.loadTrigger$
        .pipe(switchMap(() => this.invoiceService.loadCreditAppointments()))
        .subscribe((data: any) => {
          this.data = data

          this.contentLoading = false
          this.loading = false

          setTimeout(() => {
            if (this.table) {
              const totalItems = this.table._totalRecords

              this.globalFilterValue = this.table.filters.global?.value ?? ''

              if (
                this.table._first > totalItems ||
                this.table._rows > totalItems
              ) {
                this.firstPage = 0
              }
            }
          })
        })
    )
  }
}
