import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core'
import {
  DialogService,
  DynamicDialogConfig,
  DynamicDialogRef,
} from 'primeng/dynamicdialog'
import { EventBusService, GlobalEvent } from 'src/app/services/eventbus.service'
import { ToastService } from 'src/app/services/toast.service'
import { NgForm } from '@angular/forms'
import { Subscription } from 'rxjs'
import { PatientService } from '../../../services/patient.service'
import { SearchService } from '../../../services/search.service'
import { SearchResultModel } from '../../../models/search/search-result.model'
import { SelectItem } from 'primeng/api'
import * as dayjs from 'dayjs'
import * as currency from 'currency.js'
import { SendLetterService } from '../../../services/send-letter.service'
import { DocumentService } from '../../../services/document.service'
import { AddDocumentDialogComponent } from '../add-document-dialog/add-document-dialog.component'
import { DocumentModel } from '../../../models/document/document.model'
import { MediaModel } from '../../../models/document/media.model'
import { AccountingReturnPositionModel } from '../../../models/accounting/accounting-return-position.model'
import { HelperService } from '../../../services/helper.service'
import { CaregiverDetailModel } from '../../../models/caregiver/caregiver-detail.model'
import { SystemMessageService } from '../../../services/system-message.service'
import { AuthService } from '../../../services/auth.service'
import { CaregiverService } from '../../../services/caregivers.service'

@Component({
  selector: 'app-send-message-to-caregiver-dialog',
  templateUrl: './send-message-to-caregiver-dialog.component.html',
})
export class SendMessageToCaregiverDialogComponent
  implements OnInit, OnDestroy {
  @ViewChild('form', { static: true }) form!: NgForm

  private formSubscription: Subscription | null | undefined = null
  private isDirty = false

  submitted = false

  public options = {
    buttons: ['bold', 'underline', 'italic'],
    toolbarAdaptive: false,
    toolbar: true,
    enter: 'br' as any,
    placeholder: '',
    statusbar: false,
    addNewLine: false,
    language: 'de',
    showBrowserColorPicker: false,
    allowResizeTags: [],
    table: {
      allowCellResize: false
    }
  }

  public caregiverOptions: any[] = []
  public clickedOnPreview = false

  public values: any = {
    subject: 'Ihre Bewerbung bei der Seniorenbetreuung Saar',
    text: '',
  }
  public documents: any[] = []
  public caregivers: SelectItem[] = []
  public hasEmail = true

  public data: any
  public response: any
  public systemMessages: any = []
  public selectedMessage: any = null

  constructor(
    private dialogService: DialogService,
    public caregiverService: CaregiverService,
    private ref: DynamicDialogRef,
    private config: DynamicDialogConfig,
    private searchService: SearchService,
    private patientService: PatientService,
    private eventbus: EventBusService,
    public documentService: DocumentService,
    private authService: AuthService,
    private toastService: ToastService,
    private helperService: HelperService
  ) {}

  public ngOnInit(): void {
    this.data = this.config.data

    this.values.subject += ' | ' + this.data.caregiver.full_name.trim()

    this.hasEmail = this.data.caregiver.email

    this.formSubscription = this.form.valueChanges?.subscribe(() => {
      if (!this.form.pristine) {
        this.isDirty = true
      }
    })

    this.loadData()
  }

  ngOnDestroy(): void {
    this.formSubscription?.unsubscribe()
  }

  public save(): void {
    if (!this.form.form.valid) {
      this.submitted = false
      this.form.form.markAllAsTouched()
      return
    }

    this.submitted = true

    this.caregiverService
      .sendMessage(this.data.caregiver.id, this.values)
      .subscribe(
        (response: any) => {
          this.submitted = false

          this.ref.close()

          this.eventbus.emit(GlobalEvent.CaregiverDetailReload)

          this.toastService.success(
            'E-Mail wurde versendet',
            'Die E-Mail wurde erfolgreich versendet'
          )
        },
        () => {
          this.toastService.error(
            'Etwas ist schiefgelaufen...',
            'Bitte wenden Sie sich an den Support'
          )

          this.submitted = false
        }
      )
  }

  private loadData(): void {
    this.caregiverService
      .loadDataForMessage(this.config.data.caregiver.id)
      .subscribe((data: any) => {
        this.values.text = data.message
      })
  }
}
