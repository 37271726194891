import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core'
import {
  DialogService,
  DynamicDialogConfig,
  DynamicDialogRef,
} from 'primeng/dynamicdialog'
import { EventBusService, GlobalEvent } from 'src/app/services/eventbus.service'
import { ToastService } from 'src/app/services/toast.service'
import { NgForm } from '@angular/forms'
import { Subscription } from 'rxjs'
import { ConfirmationService } from 'primeng/api'
import { HelperService } from '../../../services/helper.service'
import { CarDamageService } from '../../../services/car-damage.service'
import { UserSystemService } from '../../../services/user-system.service'
import { AuthService } from '../../../services/auth.service'
import { DocumentService } from '../../../services/document.service'
import { CarCostModel } from '../../../models/car/car-cost.model'
import { CarCostService } from '../../../services/car-cost.service'
import { HttpErrorResponse } from '@angular/common/http'
import { StatusCodes } from 'http-status-codes'

@Component({
  selector: 'app-add-car-cost-dialog',
  templateUrl: './add-car-cost-dialog.component.html',
})
export class AddCarCostDialogComponent implements OnInit, OnDestroy {
  @ViewChild('form', { static: true }) form!: NgForm

  private formSubscription: Subscription | null | undefined = null
  private isDirty = false

  public data: any = {}
  public values: CarCostModel = new CarCostModel()

  public submitted = false
  public submittedDelete = false

  constructor(
    private ref: DynamicDialogRef,
    private authService: AuthService,
    private dialogService: DialogService,
    private config: DynamicDialogConfig,
    private helperService: HelperService,
    private carCostService: CarCostService,
    private eventbus: EventBusService,
    private toastService: ToastService,
    public documentService: DocumentService,
    private confirmationService: ConfirmationService
  ) {}

  ngOnInit(): void {
    this.data = this.config.data

    this.formSubscription = this.form.valueChanges?.subscribe(() => {
      if (!this.form.pristine) {
        this.isDirty = true
      }
    })

    if (this.data.isEdit) {
      this.loadCost()
    }
  }

  ngOnDestroy(): void {
    this.formSubscription?.unsubscribe()
  }

  public save(): void {
    if (!this.form.form.valid) {
      this.submitted = false
      this.form.form.markAllAsTouched()
      return
    }

    this.submitted = true

    const subscription = this.data.isEdit
      ? this.carCostService.update(this.data.cost_id, this.values)
      : this.carCostService.store(this.data.car_id, this.values)

    subscription.subscribe(
      () => {
        this.submitted = false
        this.eventbus.emit(GlobalEvent.CarCostChanged)
        this.ref.close()
        this.toastService.success(
          'Faktor gespeichert',
          'Der Kostenfaktor wurde erfolgreich gespeichert'
        )
      },
      (error: HttpErrorResponse) => {
        if (error.status === StatusCodes.UNPROCESSABLE_ENTITY) {
          this.toastService.error('Bitte füllen Sie alle Pflichtfelder aus')
        } else {
          this.toastService.error(
            'Etwas ist schief gelaufen...',
            'Bitte wenden Sie sich an den Support'
          )
        }
        this.submitted = false
      }
    )
  }

  private loadCost(): void {
    this.carCostService
      .load(this.data.cost_id)
      .subscribe((response: CarCostModel) => {
        this.values = response
      })
  }

  public remove(event: any): void {
    this.confirmationService.confirm({
      target: event.target ?? undefined,
      message: 'Faktor wirklich löschen?',
      accept: () => {
        this.submittedDelete = true

        this.carCostService.delete(this.data.cost_id).subscribe(
          () => {
            this.submittedDelete = false
            this.eventbus.emit(GlobalEvent.CarCostChanged)
            this.ref.close()
            this.toastService.success(
              'Faktor gelöscht',
              'Der Faktor wurde erfolgreich gelöscht'
            )
          },
          () => {
            this.submittedDelete = false
            this.toastService.error(
              'Löschen fehlgeschlagen',
              'Der Eintrag konnte nicht gelöscht werden'
            )
          }
        )
      },
      reject: () => {},
    })
  }
}
