export class CaregiverAllergyModel {
  id = 0
  caregiver_id = 0
  animal_allergy = false
  animal_allergy_dogs = false
  animal_allergy_cats = false
  animal_allergy_other_info = ''
  dust_allergy = false
  other = false
  other_info = ''
}
