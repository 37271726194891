import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { environment } from '../../environments/environment'
import { Observable } from 'rxjs'

@Injectable({
  providedIn: 'root',
})
export class CaregiverOverviewService {
  constructor(private http: HttpClient) {}

  public calendar(caregiverId: string | null, year: string): Observable<any> {
    return this.http.get<any>(
      `${environment.api}/overview/calendar/${caregiverId}`,
      {
        params: {
          year,
        },
      }
    )
  }

  public storeTripAsAdmin(
    persplanId: string | number,
    values: any
  ): Observable<any> {
    return this.http.post<any>(
      `${environment.api}/overview/trip/${persplanId}`,
      {
        ...values,
      }
    )
  }

  public storeCheckComment(
    caregiverId: number | string,
    data: any,
    comment: string
  ): Observable<[]> {
    return this.http.post<[]>(
      `${environment.api}/overview/check-comment/${caregiverId}`,
      {
        ...data,
        comment,
      }
    )
  }

  public updateCheckComment(
    checkCommentId: number | string,
    comment: string
  ): Observable<[]> {
    return this.http.put<[]>(
      `${environment.api}/overview/check-comment/${checkCommentId}`,
      {
        comment,
      }
    )
  }

  public confirmRelease(caregiverApprovedOverviewId: number): Observable<[]> {
    return this.http.put<[]>(
      `${environment.api}/overview/confirm-release/${caregiverApprovedOverviewId}`,
      {}
    )
  }

  public reopenRelease(caregiverApprovedOverviewId: number): Observable<[]> {
    return this.http.put<[]>(
      `${environment.api}/overview/reopen-release/${caregiverApprovedOverviewId}`,
      {}
    )
  }

  public list(
    caregiverId: string | null,
    month: string,
    year: string
  ): Observable<any> {
    return this.http.get<any>(
      `${environment.api}/overview/list/${caregiverId}`,
      {
        params: {
          month,
          year,
        },
      }
    )
  }

  public generateDriveTimeDocument(
    caregiverId: string | null,
    month: string,
    year: string,
    withIll: boolean
  ): Observable<string> {
    return this.http.get(
      `${environment.api}/overview/list/${caregiverId}/pdf`,
      {
        responseType: 'text',
        params: {
          month,
          year,
          withIll,
        },
      }
    )
  }
}
