import { Component, OnInit } from '@angular/core'
import { ToastService } from '../../../services/toast.service'
import { TitleService } from '../../../services/title.service'
import { UserCaregiverService } from '../../../services/user-caregiver.service'
import {DocumentService} from "../../../services/document.service";
import {
  AddDocumentDialogComponent
} from "../../../components/dialogs/add-document-dialog/add-document-dialog.component";
import {HospitationDialogComponent} from "../../../components/dialogs/hospitation-dialog/hospitation-dialog.component";
import {DialogService} from "primeng/dynamicdialog";

@Component({
  selector: 'app-user-caregiver-hospitation',
  templateUrl: './user-caregiver-hospitation.component.html',
})
export class UserCaregiverHospitationComponent implements OnInit {
  public loading = true
  public contentLoading = true

  public data: any[] = []

  constructor(
    public userCaregiverService: UserCaregiverService,
    private toastService: ToastService,
    private dialogService: DialogService,
    public documentService: DocumentService,
    private titleService: TitleService
  ) {}

  ngOnInit(): void {
    document.body.classList.remove('mobile-nav-visible')

    this.titleService.setTitle(`Empfehlungen`)

    this.loadData()
  }

  public loadData(withLoading: boolean = true): void {
    if (withLoading) {
      this.loading = true
    }

    this.contentLoading = true

    this.userCaregiverService
      .loadHospitations()
      .subscribe((data: any[]) => {
        this.data = data

        console.log(data);

        this.loading = false
        this.contentLoading = false
      })
  }

  public openHospitationDialog(caregiver: any): void {
    if (caregiver.current_status.status !== 'HOSPITATION' && caregiver.current_status.status !== 'HOSPITATION_STARTED') {
      return
    }

    const ref = this.dialogService.open(HospitationDialogComponent, {
      data: {
        ...caregiver
      },
      header: 'Hospitation',
      styleClass: 'dialog-container',
      dismissableMask: false,
      width: '620px',
    })

    ref.onClose.subscribe((response: any) => {
      if (response) {
        this.loadData(false)
      }
    })
  }
}
