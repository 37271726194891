<app-loader [loading]="loading"></app-loader>

<div class="content" *ngIf="!loading">
  <header class="content-header">
    <span> </span>

    <div
      class="table-search-container"
      [class.searchfield-active]="globalFilterValue != ''"
      *ngIf="data.length > 0"
    >
      <div class="p-input-icon-left">
        <i class="pi pi-search"></i>
        <input
          pInputText
          (input)="filterTableGlobal($event)"
          placeholder="Suche..."
          [(ngModel)]="globalFilterValue"
        />
      </div>
    </div>
  </header>
</div>

<div class="content" style="margin-bottom: 0" *ngIf="!loading">
  <div class="content-body" *ngIf="data.length === 0">
    <span class="content-no-message">Keine Empfehlungen vorhanden</span>
  </div>

  <div
    class="content-body content-body-full-height-with-tabs without-padding"
    *ngIf="data.length > 0"
    [class.content-loading]="contentLoading"
  >
    <p-table
      #dt
      [value]="data"
      scrollHeight="100%"
      [scrollable]="true"
      [paginator]="true"
      [rows]="50"
      [(first)]="firstPage"
      [rowHover]="true"
      [globalFilterFields]="[
        'last_name',
        'first_name',
        'customer.recommendation.last_name',
        'customer.recommendation.first_name'
      ]"
      sortMode="multiple"
    >
      <ng-template pTemplate="header">
        <tr class="table-headline-highlight">
          <th style="width: 160px" pSortableColumn="status_string">
            Status
            <p-sortIcon field="status_string"></p-sortIcon>
          </th>
          <th pSortableColumn="last_name">
            Name <p-sortIcon field="last_name"></p-sortIcon>
          </th>
          <th pSortableColumn="city">
            Wohnort <p-sortIcon field="city"></p-sortIcon>
          </th>
          <th pSortableColumn="customer.recommendation.last_name">
            Betreuungskraft
            <p-sortIcon field="customer.recommendation.last_name"></p-sortIcon>
          </th>
          <th pSortableColumn="customer.created_at">
            Erstellt am
            <p-sortIcon field="customer.created_at"></p-sortIcon>
          </th>
          <th pSortableColumn="is_payable">
            Kriterium erfüllt
            <p-sortIcon field="is_payable"></p-sortIcon>
          </th>
          <th pSortableColumn="caregiver_paid_recommendation.paid_at">
            <div style="display: flex; align-items: center; gap: 5px">
              <i style="font-size: 12px" class="pi pi-euro"></i>
              Bezahlt
              <p-sortIcon
                field="caregiver_paid_recommendation.paid_at"
              ></p-sortIcon>
            </div>
          </th>
          <th></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-patient>
        <tr>
          <td>
            <!-- Wenn Quali noch offen ist, soll der Workflow Status angezeigt werden -->
            <span
              *ngIf="!patient.status && !patient.is_paused"
              class="p-tag p-tag-rounded p-tag-{{ patient.workflow_status }}"
              >{{ patient.workflow_status_string }}</span
            >

            <!-- Falls der Patient pausiert ist -->
            <span
              *ngIf="patient.is_paused"
              class="p-tag p-tag-rounded p-tag-PAUSED"
              >Pausiert</span
            >

            <!-- Ansonsten soll der richtige Status angezeigt werden -->
            <span
              *ngIf="patient.status && !patient.is_paused"
              class="p-tag p-tag-rounded p-tag-{{ patient.status }}"
              >{{ patient.status_string }}
            </span>
          </td>
          <td>
            <a class="color-main1" routerLink="/patients/{{ patient.id }}">
              {{ patient.last_name }}, {{ patient.first_name }}
            </a>
          </td>
          <td>
            <i
              style="font-size: 14px; margin: 3px 3px 0 0"
              class="pi pi-info-circle"
              *ngIf="patient.city"
              [pTooltip]="patient.full_address"
            ></i>
            {{ patient.city || "-" }}
          </td>
          <td>
            <a
              class="color-main1"
              routerLink="/caregivers/{{ patient.customer.recommendation_id }}"
            >
              {{ patient.customer.recommendation.last_name }},
              {{ patient.customer.recommendation.first_name }}
            </a>
          </td>
          <td>
            {{ patient.customer.created_at | date: "dd.MM.y, H:mm" }}
          </td>
          <td>
            <span
              *ngIf="patient.is_payable"
              class="p-tag p-tag-rounded p-tag-success"
            >
              <i class="pi pi-check"></i>
              Erfüllt
            </span>
          </td>
          <td>
            <span *ngIf="patient.caregiver_paid_recommendation">
              {{
                patient.caregiver_paid_recommendation.paid_at
                  | date: "dd.MM.y, H:mm"
              }}
            </span>
          </td>
          <td class="table-action" style="width: 100px">
            <i
              *ngIf="authService.isSuperAdmin()"
              tooltipPosition="left"
              [pTooltip]="
                patient.caregiver_paid_recommendation
                  ? 'Bezahlung zurückziehen'
                  : 'Als bezahlt markieren'
              "
              (click)="togglePaid(patient)"
              class="pi"
              [class.pi-euro]="!patient.caregiver_paid_recommendation"
              [class.pi-times]="patient.caregiver_paid_recommendation"
            ></i>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
