<app-loader [loading]="loading"></app-loader>

<div class="content" *ngIf="!loading">
  <header class="content-header without-border">
    <span>
      <i class="pi pi-car"></i>
      PKW
    </span>
    <div class="setting-menu-container" #settingContainer>
      <button
        pButton
        label="Einstellungen"
        type="button"
        class="p-button-outlined"
        icon="pi pi-chevron-down"
        iconPos="right"
        (click)="menu.toggle($event)"
      ></button>
      <p-menu #menu [popup]="true" [model]="settingMenuItems"></p-menu>
    </div>
  </header>
</div>

<div class="content" *ngIf="!loading">
  <div class="content-body without-padding p-flex-row">
    <div class="detail-information-column border-right">
      <div class="detail-information-container">
        <div class="detail-information-header">Daten</div>
        <div class="detail-information-body">
          <div class="detail-information">
            <div class="detail-key">
              <span>Status</span>
            </div>
            <div class="detail-value p-jc-between p-align-center">
              <strong class="p-d-flex p-align-center" *ngIf="car.current_status"
                ><i
                  style="margin: 0 10px 0 0"
                  [class.parking-icon]="car.current_status.status === 'PARKING'"
                  [class.shop-icon]="car.current_status.status === 'SHOP'"
                  [class.heart-icon-small]="
                    car.current_status.status === 'ACTIVE'
                  "
                ></i
                >{{ car.current_status.status_text }} (seit
                {{ car.current_status.since | date: "dd.MM.y" }})</strong
              >
              <strong *ngIf="!car.current_status">-</strong>

              <div class="detail-value-actions">
                <i
                  class="pi pi-plus color-gray"
                  (click)="openAddCarStatusDialog()"
                  pTooltip="Ändern"
                ></i>
                <i
                  class="pi color-gray"
                  [class.pi-chevron-down]="!openedDetails.status"
                  [class.pi-chevron-up]="openedDetails.status"
                  [pTooltip]="openedDetails.status ? 'Schließen' : 'Anzeigen'"
                  (click)="openedDetails.status = !openedDetails.status"
                ></i>
              </div>
            </div>
          </div>
          <div
            class="detail-information without-padding"
            *ngIf="openedDetails.status"
          >
            <p-table
              styleClass="highlight-header"
              [value]="car.status"
              [rowHover]="true"
            >
              <ng-template pTemplate="header">
                <tr>
                  <th>Status</th>
                  <th>KM</th>
                  <th>seit</th>
                  <th>Kommentar</th>
                  <!--                  <th style="width: 50px"></th>-->
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-status>
                <tr>
                  <td>
                    <div class="p-d-flex p-align-center">
                      <i
                        style="margin: 0 10px 0 0"
                        [class.parking-icon]="status.status === 'PARKING'"
                        [class.shop-icon]="status.status === 'SHOP'"
                        [class.heart-icon-small]="status.status === 'ACTIVE'"
                      ></i>
                      {{ status.status_text }}
                    </div>
                  </td>
                  <td>{{ status.mileage }}</td>
                  <td>{{ status.since | date: "dd.MM.y" }}</td>
                  <td>{{ status.comment }}</td>
                  <!--                  <td>-->
                  <!--                    <i-->
                  <!--                      class="pi pi-pencil"-->
                  <!--                      (click)="openAddCaregiverCarDialog(car)"-->
                  <!--                    ></i>-->
                  <!--                  </td>-->
                </tr>
              </ng-template>
            </p-table>
          </div>

          <div class="detail-information">
            <div class="detail-key">
              <span>Kilometerstand</span>
            </div>
            <div class="detail-value p-jc-between p-align-center">
              <strong *ngIf="car.current_mileage"
                >{{ car.current_mileage.mileage }} ({{
                  car.current_mileage.since | date: "dd.MM.y"
                }})</strong
              >
              <strong *ngIf="!car.current_mileage">-</strong>

              <div class="detail-value-actions">
                <i
                  class="pi pi-plus color-gray"
                  (click)="openAddCarMileageDialog()"
                  pTooltip="Ändern"
                ></i>
                <i
                  class="pi color-gray"
                  [class.pi-chevron-down]="!openedDetails.mileages"
                  [class.pi-chevron-up]="openedDetails.mileages"
                  [pTooltip]="openedDetails.mileages ? 'Schließen' : 'Anzeigen'"
                  (click)="openedDetails.mileages = !openedDetails.mileages"
                ></i>
              </div>
            </div>
          </div>
          <div
            class="detail-information without-padding"
            *ngIf="openedDetails.mileages"
          >
            <p-table
              styleClass="highlight-header"
              [value]="car.mileages"
              [rowHover]="true"
            >
              <ng-template pTemplate="header">
                <tr>
                  <th>KM</th>
                  <th>seit</th>
                  <th>Kommentar</th>
                  <!--                  <th style="width: 50px"></th>-->
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-mileage>
                <tr>
                  <td>{{ mileage.mileage }}</td>
                  <td>{{ mileage.since | date: "dd.MM.y" }}</td>
                  <td>{{ mileage.comment }}</td>
                  <!--                  <td>-->
                  <!--                    <i-->
                  <!--                      class="pi pi-pencil"-->
                  <!--                      (click)="openAddCaregiverCarDialog(car)"-->
                  <!--                    ></i>-->
                  <!--                  </td>-->
                </tr>
              </ng-template>
            </p-table>
          </div>

          <div class="detail-information">
            <div class="detail-key clickable">
              <span
                tooltipPosition="left"
                (mouseenter)="
                  getLatestChange(car.id, 'car', {
                    brand: car.brand
                  })
                "
                (click)="
                  openDiffViewHistory(car.id, 'car', {
                    brand: car.brand
                  })
                "
                [pTooltip]="latestChanges.brand"
                >Marke</span
              >
            </div>
            <div class="detail-value">
              <strong>{{ car.brand }}</strong>
            </div>
          </div>
          <div class="detail-information">
            <div class="detail-key clickable">
              <span
                tooltipPosition="left"
                (mouseenter)="
                  getLatestChange(car.id, 'car', {
                    model: car.model
                  })
                "
                (click)="
                  openDiffViewHistory(car.id, 'car', {
                    model: car.model
                  })
                "
                [pTooltip]="latestChanges.model"
                >Modell</span
              >
            </div>
            <div class="detail-value">
              <strong>{{ car.model }}</strong>
            </div>
          </div>
          <div class="detail-information">
            <div class="detail-key clickable">
              <span
                tooltipPosition="left"
                (mouseenter)="
                  getLatestChange(car.id, 'car', {
                    license_plate: car.license_plate
                  })
                "
                (click)="
                  openDiffViewHistory(car.id, 'car', {
                    license_plate: car.license_plate
                  })
                "
                [pTooltip]="latestChanges.license_plate"
                >Kennzeichen</span
              >
            </div>
            <div class="detail-value">
              <strong>{{ car.license_plate }}</strong>
            </div>
          </div>
          <div class="detail-information">
            <div class="detail-key clickable">
              <span
                tooltipPosition="left"
                (mouseenter)="
                  getLatestChange(car.id, 'car', {
                    initial_registration_string: car.initial_registration_string
                  })
                "
                (click)="
                  openDiffViewHistory(car.id, 'car', {
                    initial_registration_string: car.initial_registration_string
                  })
                "
                [pTooltip]="latestChanges.initial_registration_string"
                >Erstzulassung</span
              >
            </div>
            <div class="detail-value">
              <strong>{{ car.initial_registration_string || "-" }}</strong>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="detail-information-column border-right">
      <div class="detail-information-container">
        <div class="detail-information-header">Zusatz</div>
        <div class="detail-information-body">
          <div class="detail-information">
            <div class="detail-key clickable">
              <span
                tooltipPosition="left"
                (mouseenter)="
                  getLatestChange(car.id, 'car', {
                    private_use_allowed: car.private_use_allowed
                  })
                "
                (click)="
                  openDiffViewHistory(car.id, 'car', {
                    private_use_allowed: car.private_use_allowed
                  })
                "
                [pTooltip]="latestChanges.private_use_allowed"
                >Privatnutzung erlaubt?</span
              >
            </div>
            <div class="detail-value">
              <strong>
                {{ car.private_use_allowed || "-" }}
              </strong>
            </div>
          </div>
          <div class="detail-information">
            <div class="detail-key clickable">
              <span
                tooltipPosition="left"
                (mouseenter)="
                  getLatestChange(car.id, 'car', {
                    navigation: car.navigation
                  })
                "
                (click)="
                  openDiffViewHistory(car.id, 'car', {
                    navigation: car.navigation
                  })
                "
                [pTooltip]="latestChanges.navigation"
                >Navi?</span
              >
            </div>
            <div class="detail-value">
              <strong>
                {{ car.navigation || "-" }}
              </strong>
            </div>
          </div>
          <div class="detail-information">
            <div class="detail-key clickable">
              <span
                tooltipPosition="left"
                (mouseenter)="
                  getLatestChange(car.id, 'car', {
                    navigation_number: car.navigation_number,
                    portable_navigation: car.portable_navigation
                  })
                "
                (click)="
                  openDiffViewHistory(car.id, 'car', {
                    navigation_number: car.navigation_number,
                    portable_navigation: car.portable_navigation
                  })
                "
                [pTooltip]="
                  latestChanges.navigation_number ||
                  latestChanges.portable_navigation
                "
                >Portables Navi?</span
              >
            </div>
            <div class="detail-value">
              <strong>
                {{ car.portable_navigation || "-" }}
                {{
                  car.navigation_number ? "(" + car.navigation_number + ")" : ""
                }}
              </strong>
            </div>
          </div>
          <div class="detail-information">
            <div class="detail-key clickable">
              <span
                tooltipPosition="left"
                (mouseenter)="
                  getLatestChange(car.id, 'car', {
                    fuel_card_number: car.fuel_card_number,
                    fuel_card: car.fuel_card
                  })
                "
                (click)="
                  openDiffViewHistory(car.id, 'car', {
                    fuel_card_number: car.fuel_card_number,
                    fuel_card: car.fuel_card
                  })
                "
                [pTooltip]="
                  latestChanges.fuel_card_number || latestChanges.fuel_card
                "
                >Tankkarte?</span
              >
            </div>
            <div class="detail-value">
              <strong>
                {{ car.fuel_card || "-" }}
                {{
                  car.fuel_card_number ? "(" + car.fuel_card_number + ")" : ""
                }}
              </strong>
            </div>
          </div>
          <div class="detail-information">
            <div class="detail-key clickable">
              <span
                tooltipPosition="left"
                (mouseenter)="
                  getLatestChange(car.id, 'car', {
                    summer_tires_warehouse: car.summer_tires_warehouse
                  })
                "
                (click)="
                  openDiffViewHistory(car.id, 'car', {
                    summer_tires_warehouse: car.summer_tires_warehouse
                  })
                "
                [pTooltip]="latestChanges.summer_tires_warehouse"
                >Sommerreifen Lager</span
              >
            </div>
            <div class="detail-value">
              <strong>
                {{ car.summer_tires_warehouse || "-" }}
              </strong>
            </div>
          </div>
          <div class="detail-information">
            <div class="detail-key clickable">
              <span
                tooltipPosition="left"
                (mouseenter)="
                  getLatestChange(car.id, 'car', {
                    winter_tires_warehouse: car.winter_tires_warehouse
                  })
                "
                (click)="
                  openDiffViewHistory(car.id, 'car', {
                    winter_tires_warehouse: car.winter_tires_warehouse
                  })
                "
                [pTooltip]="latestChanges.winter_tires_warehouse"
                >Winterreifen Lager</span
              >
            </div>
            <div class="detail-value">
              <strong>
                {{ car.winter_tires_warehouse || "-" }}
              </strong>
            </div>
          </div>
          <div class="detail-information">
            <div class="detail-key clickable">
              <span
                tooltipPosition="left"
                (mouseenter)="
                  getLatestChange(car.id, 'car', {
                    current_place: car.current_place
                  })
                "
                (click)="
                  openDiffViewHistory(car.id, 'car', {
                    current_place: car.current_place
                  })
                "
                [pTooltip]="latestChanges.current_place"
                >Aktueller Standort</span
              >
            </div>
            <div class="detail-value">
              <strong>
                {{ car.current_place || "-" }}
              </strong>
            </div>
          </div>
        </div>
      </div>
      <div class="detail-information-container">
        <div class="detail-information-header">Todos</div>

        <div class="detail-information-body" *ngIf="car.todos.length === 0">
          <span class="content-no-message">Keine Todos vorhanden</span>
        </div>

        <div
          *ngIf="car.todos.length > 0"
          style="max-height: 230px; height: 100%"
          class="detail-information-body without-padding"
        >
          <p-table
            [value]="car.todos"
            [rowHover]="true"
            [scrollable]="true"
            scrollHeight="100%"
          >
            <ng-template pTemplate="header">
              <tr>
                <th style="width: 120px" pSortableColumn="status">
                  Status <p-sortIcon field="status"></p-sortIcon>
                </th>
                <th pSortableColumn="title">
                  Titel <p-sortIcon field="title"></p-sortIcon>
                </th>
                <th style="max-width: 50px"></th>
                <th pSortableColumn="for_when">
                  Zu erledigen <p-sortIcon field="for_when"></p-sortIcon>
                </th>
                <th>Kommentare</th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-todo>
              <tr class="clickable" (click)="openShowTodoDialog(todo)">
                <td style="width: 120px">
                  <span class="p-tag p-tag-rounded p-tag-{{ todo.status }}">{{
                    todo.status_string
                  }}</span>
                </td>
                <td>{{ todo.title }}</td>
                <td style="max-width: 50px">
                  <i
                    tooltipPosition="left"
                    [pTooltip]="todo.comment"
                    class="pi pi-info-circle"
                    *ngIf="todo.comment"
                  ></i>
                </td>
                <td>
                  {{ todo.for_when_type === "FINISH_AT" ? "am" : "bis" }}
                  {{ todo.for_when_string }}
                </td>
                <td>
                  {{ todo.comments_count }}
                </td>
              </tr>
            </ng-template>
          </p-table>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="content" *ngIf="!loading">
  <header class="content-header">
    <span>
      <i class="pi pi-user"></i>
      Betreuungskräfte
    </span>

    <button
      pButton
      (click)="openAddCaregiverCarDialog()"
      label="Hinzufügen"
      type="button"
      class="p-button-outlined"
      icon="pi pi-plus"
      iconPos="left"
    ></button>
  </header>
  <div
    class="content-body content-base-height"
    *ngIf="car.assignments.length === 0"
  >
    <span class="content-no-message">Kein Benutzer zugewiesen</span>
  </div>
  <div
    class="content-body content-base-height without-padding"
    *ngIf="car.assignments.length > 0"
  >
    <p-table [value]="car.assignments" [rowHover]="true">
      <ng-template pTemplate="header">
        <tr>
          <th>Name</th>
          <th style="width: 130px">seit</th>
          <th style="width: 130px">bis</th>
          <th>Kilometerstand</th>
          <th>Kommentar</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-assignment>
        <tr>
          <td>
            <a
              *ngIf="assignment.assignable_type === 'App\\Models\\Caregiver'"
              routerLink="/caregivers/{{ assignment.assignable_id }}"
              >{{ assignment.assignable.full_name }}</a
            >

            <a
              *ngIf="assignment.assignable_type === 'App\\Models\\UserSystem'"
              routerLink="/administration/users/{{ assignment.assignable_id }}"
              >{{ assignment.assignable.full_name }}</a
            >
          </td>
          <td>{{ assignment.since | date: "dd.MM.y" }}</td>
          <td *ngIf="assignment.until">
            {{ assignment.until | date: "dd.MM.y" }}
          </td>
          <td *ngIf="!assignment.until">-</td>
          <td>{{ assignment.mileage || "-" }}</td>
          <td>
            <i
              *ngIf="assignment.comment"
              class="pi pi-info-circle"
              [pTooltip]="assignment.comment"
            ></i>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
