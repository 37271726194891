import { Component, OnInit } from '@angular/core'
import { DialogService } from 'primeng/dynamicdialog'
import {
  CaregiverProofGroupedModel,
  CaregiverProofModel,
} from '../../../models/caregiver/caregiver-proof.model'
import { CaregiverService } from '../../../services/caregivers.service'
import { MediaModel } from '../../../models/document/media.model'
import { UserCaregiverService } from '../../../services/user-caregiver.service'
import { TitleService } from '../../../services/title.service'

@Component({
  selector: 'app-user-caregiver-proofs',
  templateUrl: './user-caregiver-proofs.component.html',
  providers: [DialogService],
})
export class UserCaregiverProofsComponent implements OnInit {
  public loading = true
  public proofs: CaregiverProofGroupedModel = new CaregiverProofGroupedModel()
  public keys = Object.keys
  public caregiverId: string | null = null
  public openedAccordions: any = {}

  constructor(
    public caregiverService: CaregiverService,
    private titleService: TitleService,
    public userCaregiverService: UserCaregiverService
  ) {}

  ngOnInit(): void {
    document.body.classList.remove('mobile-nav-visible')

    this.titleService.setTitle(`Leistungsnachweise`)
    this.loadProofs()
  }

  public openZipDownload(key: string): void {
    const [month, year] = key.split(' ')

    window.open(this.userCaregiverService.getZipOfProofsLink(month, year))
  }

  public openDownloadTab(document: MediaModel): void {
    window.open(
      this.userCaregiverService.getDownloadProofDocumentLink(document.uuid)
    )
  }

  public loadProofs(withLoading: boolean = true): void {
    if (withLoading) {
      this.loading = true
    }

    this.userCaregiverService
      .loadProofs()
      .subscribe((data: CaregiverProofGroupedModel) => {
        this.proofs = data

        const keys = Object.keys(data)

        if (keys.length) {
          const firstDate = keys[0]
          this.openedAccordions[firstDate] = true
        }

        if (withLoading) {
          this.loading = false
        }
      })
  }
}
