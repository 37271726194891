<form #form="ngForm" class="dialog-form">
  <div class="dialog-form-body">
    <div class="p-fluid p-formgrid p-grid without-border">
      <div class="p-field p-col-12">
        <label>Bewerber</label>
        <strong>{{ data.full_name }}</strong>
      </div>
    </div>

    <div class="p-fluid p-formgrid p-grid without-border">
      <div class="p-field p-col-6">
        <label for="from">Hospitation am *</label>
<!--        <strong *ngIf="isFrozen">{{ values.hospitation_from_string }}</strong>-->
        <p-calendar
          [firstDayOfWeek]="1"
          [showWeek]="true"
          inputId="from"
          name="from"
          dateFormat="dd.mm.yy"
          appendTo="body"
          [(ngModel)]="values.hospitation_from_string"
          dataType="string"
          [required]="true"
          #from="ngModel"
          [showButtonBar]="true"
          [class.p-invalid]="from.invalid && (from.dirty || from.touched)"
        ></p-calendar>
      </div>

      <div class="p-field p-col-6">
        <label for="to">Hospitation bis *</label>
<!--        <strong *ngIf="isFrozen">{{ values.hospitation_to_string }}</strong>-->
        <p-calendar
          [firstDayOfWeek]="1"
          [showWeek]="true"
          inputId="to"
          name="to"
          dateFormat="dd.mm.yy"
          appendTo="body"
          [(ngModel)]="values.hospitation_to_string"
          dataType="string"
          [required]="true"
          #to="ngModel"
          [showButtonBar]="true"
          [class.p-invalid]="to.invalid && (to.dirty || to.touched)"
        ></p-calendar>
      </div>
    </div>

    <div class="p-fluid p-formgrid p-grid without-border">
      <div class="p-field p-col-6">
        <label for="coordination_at"
        >Koordination am *</label
        >
<!--        <strong *ngIf="isFrozen">{{ values.hospitation_to_string }}</strong>-->

        <p-calendar
          [firstDayOfWeek]="1"
          [showWeek]="true"
          inputId="coordination_at"
          name="coordination_at"
          dateFormat="dd.mm.yy"
          appendTo="body"
          [(ngModel)]="values.hospitation_coordination_at_string"
          dataType="string"
          [required]="true"
          #coordination_at="ngModel"
          [class.p-invalid]="
            coordination_at.invalid &&
            (coordination_at.dirty ||
              coordination_at.touched)
          "
        ></p-calendar>
      </div>

      <div class="p-field p-col-6">
        <label for="coordination_type">Koordination durch *</label>
<!--        <strong *ngIf="isFrozen">{{ values.hospitation_coordination_type }}</strong>-->
        <p-dropdown
          scrollHeight="360px"
          inputId="coordination_type"
          name="coordination_type"
          [(ngModel)]="values.hospitation_coordination_type"
          placeholder="Bitte auswählen"
          [options]="canceledTypes"
          [required]="true"
          appendTo="body"
          #coordination_type="ngModel"
          [class.p-invalid]="
            coordination_type.invalid &&
            (coordination_type.dirty ||
              coordination_type.touched)
          "
        ></p-dropdown>
      </div>
    </div>

    <div class="p-fluid p-formgrid p-grid without-border">
      <div class="p-field p-col-12">
        <label>Bewerbungsunterlagen</label>
        <div class="dialog-no-message color-gray" *ngIf="data.application_documents.length === 0">
          Kein Dokument hochgeladen
        </div>
        <div class="document-list" *ngIf="data.application_documents.length > 0">
          <span *ngFor="let document of data.application_documents">
            <a
              [href]="
                userCaregiverService.getDownloadApplicationDocumentLink(document.uuid)
              "
              target="_blank"
            >{{ document.name }}
              <i class="pi pi-download" style="font-size: 12px"></i></a
            ></span>
        </div>
      </div>
    </div>
  </div>

  <div class="dialog-form-footer">
    <button
      pButton
      (click)="openShowHistoriesDialog()"
      class="p-button-outlined"
      label="Historie"
    ></button>

    <div class="dialog-footer-action-container">
      <button
        *ngIf="isConfirmed"
        (click)="openFeedbackDialog()"
        pButton
        label="Feedback"
        type="button"
        [disabled]="submitted"
        [icon]="submitted ? 'pi pi-spin pi-spinner' : 'pi pi-star'"
      ></button>

      <button
        (click)="save()"
        pButton
        [label]="isEdit ? 'Speichern' : 'Erstellen'"
        type="button"
        [disabled]="submitted"
        [icon]="submitted ? 'pi pi-spin pi-spinner' : 'pi pi-check'"
      ></button>
    </div>
  </div>
</form>

<p-confirmPopup></p-confirmPopup>
