import { DisciplineModel } from './multiplier-dependencies.model'

export class MultiplierFormModel {
  id: number | null = null
  name = ''
  email = ''
  phone_central = ''
  mobile = ''
  fax = ''
  zipcode = ''
  city = ''
  city_part = ''
  state = ''
  county = ''
  notice = ''
  pflege_ik = ''
  kassen_ik = ''
  call_permission = false
  user_id = 0
  ph_id = 0
  street_house_number = ''
  disciplines: DisciplineModel[] = []
  created_at: Date | null = null
  updated_at: Date | null = null
  deleted_at: Date | null = null
}
