import { Component, OnInit, ViewChild } from '@angular/core'
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog'
import { EventBusService, GlobalEvent } from 'src/app/services/eventbus.service'
import { ToastService } from 'src/app/services/toast.service'
import { NgForm } from '@angular/forms'
import { PatientService } from '../../../services/patient.service'

@Component({
  selector: 'app-lock-patient-dialog',
  templateUrl: './lock-patient-dialog.component.html',
})
export class LockPatientDialogComponent implements OnInit {
  @ViewChild('form', { static: true }) form!: NgForm

  submitted = false

  public values = {
    with_todo: true,
  }

  constructor(
    private ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private patientService: PatientService,
    private eventbus: EventBusService,
    private toastService: ToastService
  ) {}

  public ngOnInit(): void {}

  public change(): void {
    this.submitted = true

    const subscription =
      this.config.data.type === 'LOCK'
        ? this.patientService.lockAppointments(this.config.data.patient.id)
        : this.patientService.unlockAppointments(
            this.config.data.patient.id,
            this.values
          )

    subscription.subscribe(
      () => {
        this.submitted = false
        this.eventbus.emit(GlobalEvent.PatientStatusChanged)
        this.ref.close()
        this.toastService.success(
          'Terminsperre geändert',
          'Die Terminsperre wurde erfolgreich geändert'
        )
      },
      () => {
        this.toastService.error(
          'Etwas ist schiefgelaufen...',
          'Bitte wenden Sie sich an den Support'
        )

        this.submitted = false
      }
    )
  }
}
