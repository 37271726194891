import { HttpErrorResponse } from '@angular/common/http'
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core'
import { StatusCodes } from 'http-status-codes'
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog'
import { EventBusService, GlobalEvent } from 'src/app/services/eventbus.service'
import { ToastService } from 'src/app/services/toast.service'
import { NgForm } from '@angular/forms'
import { Subscription } from 'rxjs'
import { SearchService } from '../../../services/search.service'
import { PatientService } from '../../../services/patient.service'
import { CancelledDateService } from '../../../services/cancelled-date.service'
import { ConfirmationService } from 'primeng/api'
import { HelperService } from '../../../services/helper.service'

@Component({
  selector: 'app-add-cancelled-date-dialog',
  templateUrl: './add-cancelled-date-dialog.component.html',
})
export class AddCancelledDateDialogComponent implements OnInit, OnDestroy {
  @ViewChild('form', { static: true }) form!: NgForm

  private formSubscription: Subscription | null | undefined = null
  private isDirty = false

  appointmentOptions = []

  public reasons: any = []
  public stornoReasons: any = []
  public patients: any = []

  public lnwIsAvailable = false
  public isCurrentMonth = false

  public data: any = {}
  public values = {
    appointments: [],
    from_storno: false,
    appointment_saved: false,
    cancelled_reason_id: null,
    storno_reason_id: null,
    patient_id: '',
    comment: '',
    lnw: '',
    month: '',
    year: '',
  }
  public monthOptions: any = []
  public yearOptions = [
    { label: '2019', value: '2019' },
    { label: '2020', value: '2020' },
    { label: '2021', value: '2021' },
    { label: '2022', value: '2022' },
    { label: '2023', value: '2023' },
    { label: '2024', value: '2024' },
  ]

  public submitted = false
  public submittedDelete = false

  constructor(
    private ref: DynamicDialogRef,
    private searchService: SearchService,
    private config: DynamicDialogConfig,
    private cancelledDateService: CancelledDateService,
    private eventbus: EventBusService,
    private helperService: HelperService,
    private toastService: ToastService,
    private patientService: PatientService,
    private confirmationService: ConfirmationService
  ) {}

  ngOnInit(): void {
    this.data = this.config.data

    const date = new Date()
    let currentYear = date.getFullYear()
    let nextMonth = date.getMonth() + 1

    // Wenn der aktuelle Monat einen index von 11 hatte (also bereits Dezember),
    // müssen wir den nächsten Monat auf Januar (also index 0) setzen und das Jahr erhöhen.
    if (nextMonth === 12) {
      nextMonth = 0
      currentYear++
    }

    this.helperService.dependencies$.subscribe((data: any) => {
      this.monthOptions = data.months
      this.values.month = this.monthOptions[nextMonth].label
      this.stornoReasons = data.appointment_storno_reasons
    })

    this.values.year = currentYear.toString()

    this.formSubscription = this.form.valueChanges?.subscribe(() => {
      if (!this.form.pristine) {
        this.isDirty = true
      }
    })

    this.loadReasons()

    if (this.data.isEdit) {
      this.values = this.data.date.cancelled_date
    } else {
      // Falls der Dialog vom Telefonfenster geöffnet wurde, müssen wir vorher die Patienten laden.
      if (this.data.from_phone) {
        this.loadPatients()
      } else {
        this.values.patient_id = this.data.patient_id
        this.loadAppointments()
      }
    }
  }

  public appointmentChanged(): void {
    this.lnwIsAvailable = false

    // Falls alle Termine ausgewählt sind.
    if (this.values.appointments.length === this.appointmentOptions.length) {
      this.lnwIsAvailable = true
    }

    const lastAppointment = this.appointmentOptions.slice(-1) as any

    const lastAppointmentIsSelected = this.values.appointments.find(
      (appointment: any) => {
        return appointment.id === lastAppointment[0].id
      }
    )

    // Falls der letzte Termin des Monats ausgewählt wurde.
    if (lastAppointmentIsSelected) {
      this.lnwIsAvailable = true
    }
  }

  public loadReasons(): void {
    this.cancelledDateService.reasons().subscribe((reasons: []) => {
      this.reasons = reasons
    })
  }

  private loadPatients(): void {
    this.patientService
      .loadPatientsFromCustomerId(this.data.customer_id)
      .subscribe((patients: any) => {
        this.patients = patients

        this.values.patient_id = patients[0].id

        this.loadAppointments()
      })
  }

  public loadAppointments(): void {
    this.values.appointments = []
    this.lnwIsAvailable = false

    this.patientService
      .getPersplanMonthForSelect(
        this.values.patient_id,
        this.values.month,
        this.values.year
      )
      .subscribe((appointments: any) => {
        this.appointmentOptions = appointments.appointments
        this.isCurrentMonth = appointments.is_current_month
      })
  }

  ngOnDestroy(): void {
    this.formSubscription?.unsubscribe()
  }

  public store(): void {
    if (!this.form.form.valid) {
      this.submitted = false
      this.form.form.markAllAsTouched()
      return
    }

    this.submitted = true

    const subscription = this.data.isEdit
      ? this.cancelledDateService.update(this.data.date.id, this.values)
      : this.cancelledDateService.store(this.values)

    subscription.subscribe(
      () => {
        this.submitted = false
        this.eventbus.emit(GlobalEvent.CancelledDateChanged)
        this.ref.close()
        this.toastService.success(
          'Absage gespeichert',
          'Die Absage wurde erfolgreich gespeichert'
        )
      },
      (error: HttpErrorResponse) => {
        if (error.status === StatusCodes.UNPROCESSABLE_ENTITY) {
          this.toastService.error('Bitte füllen Sie alle Pflichtfelder aus')
        } else {
          this.toastService.error(
            'Etwas ist schief gelaufen...',
            'Bitte wenden Sie sich an den Support'
          )
        }
        this.submitted = false
      }
    )
  }

  public remove(event: any): void {
    this.confirmationService.confirm({
      target: event.target ?? undefined,
      message:
        'Absage wirklich löschen? Der Termin wird NICHT wiederhergestellt!',
      accept: () => {
        this.submittedDelete = true

        this.cancelledDateService
          .delete(this.data.date.cancelled_date.id)
          .subscribe(
            () => {
              this.submittedDelete = false
              this.eventbus.emit(GlobalEvent.CancelledDateChanged)
              this.ref.close()
              this.toastService.success(
                'Absage gelöscht',
                'Die Absage wurde erfolgreich gelöscht'
              )
            },
            () => {
              this.submittedDelete = false
              this.toastService.error(
                'Löschen fehlgeschlagen',
                'Der Eintrag konnte nicht gelöscht werden'
              )
            }
          )
      },
      reject: () => {},
    })
  }
}
