<form #form="ngForm" class="dialog-form">
  <!-- Erster Patient -->
  <div class="dialog-form-body smaller-grid p-d-flex without-padding">
    <!-- Linke Seite -->
    <div class="dialog-left-side">
      <div class="p-field">
        <label for="first-person-persplan-id">Person 1</label>
        <div>
          {{ data.first_patient.form_of_address }}
          {{ data.first_patient.first_name }} {{ data.first_patient.last_name }}
        </div>
      </div>

      <!-- Persplan ID Patient 1 -->
      <div class="p-fluid p-formgrid p-grid without-border">
        <div class="p-field p-col-12">
          <label for="first-person-persplan-id">Persplan ID</label>
          <p-inputNumber
            inputId="first-person-persplan-id"
            name="first-person-persplan-id"
            [useGrouping]="false"
            [required]="false"
            [(ngModel)]="data.first_patient.persplan_id"
            #first_persplan_id="ngModel"
            [class.p-invalid]="
              first_persplan_id.invalid &&
              (first_persplan_id.dirty || first_persplan_id.touched)
            "
          ></p-inputNumber>

          <div
            *ngIf="
              first_persplan_id.invalid &&
              (first_persplan_id.dirty || first_persplan_id.touched)
            "
            class="p-invalid"
          >
            <div *ngIf="first_persplan_id.errors?.required">
              <small>Bitte geben Sie eine Persplan ID ein</small>
            </div>
          </div>
        </div>
      </div>

      <div class="p-fluid p-formgrid p-grid without-border">
        <div class="p-field p-col-6">
          <label>Abrechnungsart</label>
          <b>{{ data.first_patient.insured_via }}</b>
        </div>

        <div class="p-field p-col-6">
          <label>Pflegegrad</label>
          <b>
            {{ data.first_patient.degree_of_care }} seit
            {{ data.first_patient.degree_of_care_since_string || "???" }}
          </b>
        </div>
      </div>

      <div class="p-fluid p-formgrid p-grid without-border">
        <div class="p-field p-col-6">
          <label>Pflegeperson</label>
          <b>{{ data.first_patient.nursing_person || "Kein Eintrag" }}</b>
        </div>

        <div class="p-field p-col-6">
          <label>IK Nummer</label>
          <b>
            {{ data.first_patient.care_insurance.pflege_ik || "Kein Eintrag" }}
            {{ data.first_patient.care_insurance.name }}
          </b>
        </div>
      </div>

      <div class="p-fluid p-formgrid p-grid without-border">
        <div class="p-field p-col-6">
          <label>VS Nummer</label>
          <b>
            {{ data.first_patient.insurer_number || "Kein Eintrag" }}
          </b>
        </div>

        <div class="p-field p-col-6">
          <label>Leistungsart</label>
          <b>
            {{ data.first_patient.service_for || "Kein Eintrag" }}
          </b>
        </div>
      </div>
    </div>

    <!-- Rechte Seite -->
    <div class="dialog-right-side">
      <!-- DLV Patient 1 -->
      <div class="p-fluid p-formgrid p-grid without-border">
        <div class="p-field p-col-6">
          <label for="first_patient_dlv">DLV</label>
          <input
            id="first_patient_dlv"
            name="first_patient_dlv"
            type="file"
            (change)="setSelectedFile($event, 'first_patient', 'dlv')"
            pInputText
            [(ngModel)]="values.first_patient.selected_dlv"
          />
        </div>
        <div class="p-field p-col-6">
          <label for="first_patient_dlv_name">DLV Name</label>
          <input
            id="first_patient_dlv_name"
            name="first_patient_dlv_name"
            type="text"
            [(ngModel)]="fileNames.first_patient_dlv"
            pInputText
          />
        </div>
      </div>

      <!-- SEPA Patient 1 -->
      <div class="p-fluid p-formgrid p-grid without-border">
        <div class="p-field p-col-6">
          <label for="first_patient_sepa">SEPA</label>
          <input
            id="first_patient_sepa"
            name="first_patient_sepa"
            type="file"
            (change)="setSelectedFile($event, 'first_patient', 'sepa')"
            pInputText
            [(ngModel)]="values.first_patient.selected_sepa"
          />
        </div>
        <div class="p-field p-col-6">
          <label for="first_patient_sepa_name">SEPA Name</label>
          <input
            id="first_patient_sepa_name"
            name="first_patient_sepa_name"
            type="text"
            [(ngModel)]="fileNames.first_patient_sepa"
            pInputText
          />
        </div>
      </div>

      <!-- UV Patient 1 -->
      <div
        class="p-fluid p-formgrid p-grid without-border"
        *ngIf="
          data.first_patient.insured_via.includes('UV') ||
          data.first_patient.insured_via.includes('GKV')
        "
      >
        <div class="p-field p-col-6">
          <label for="first_patient_uv">Kostenzusage UV</label>
          <!--          <div class="p-formgroup-inline">-->
          <!--            <div class="p-field-checkbox">-->
          <!--              <p-checkbox-->
          <!--                [(ngModel)]="values.first_patient.no_need_uv"-->
          <!--                [binary]="true"-->
          <!--                label="nicht nötig"-->
          <!--                name="first_patient_no_need_uv"-->
          <!--              ></p-checkbox>-->
          <!--            </div>-->
          <!--          </div>-->
          <input
            id="first_patient_uv"
            name="first_patient_uv"
            type="file"
            (change)="setSelectedFile($event, 'first_patient', 'uv')"
            pInputText
            [(ngModel)]="values.first_patient.selected_uv"
          />
        </div>
        <div class="p-field p-col-6">
          <label for="first_patient_uv_name">Kostenzusage UV Name</label>
          <input
            id="first_patient_uv_name"
            name="first_patient_uv_name"
            type="text"
            [(ngModel)]="fileNames.first_patient_uv"
            pInputText
          />
        </div>
      </div>

      <!-- Einzelfallentscheidung Patient 1 -->
      <div
        class="p-fluid p-formgrid p-grid without-border"
        *ngIf="
          data.first_patient.insured_via.includes('SGB V') ||
          data.first_patient.insured_via.includes('GKV')
        "
      >
        <div class="p-field p-col-6">
          <label for="first_patient_einzelfallentscheidung"
            >Einzelfallentscheidung</label
          >
          <!--          <div class="p-formgroup-inline">-->
          <!--            <div class="p-field-checkbox">-->
          <!--              <p-checkbox-->
          <!--                [(ngModel)]="values.first_patient.no_need_einzelfallentscheidung"-->
          <!--                [binary]="true"-->
          <!--                label="nicht nötig"-->
          <!--                name="first_patient_no_need_einzelfallentscheidung"-->
          <!--              ></p-checkbox>-->
          <!--            </div>-->
          <!--          </div>-->

          <input
            id="first_patient_einzelfallentscheidung"
            name="first_patient_einzelfallentscheidung"
            type="file"
            (change)="
              setSelectedFile($event, 'first_patient', 'einzelfallentscheidung')
            "
            pInputText
            [(ngModel)]="
              values.first_patient.selected_einzelfallentscheidung &&
              !values.first_patient.no_need_einzelfallentscheidung
            "
          />
        </div>
        <div class="p-field p-col-6">
          <label for="first_patient_einzelfallentscheidung_name"
            >Einzelfallentscheidung Name</label
          >
          <input
            id="first_patient_einzelfallentscheidung_name"
            name="first_patient_einzelfallentscheidung_name"
            type="text"
            pInputText
            [(ngModel)]="fileNames.first_patient_einzelfallentscheidung"
          />
        </div>
      </div>

      <!-- Kostenzusage Kostenträger Patient 1 -->
      <div
        class="p-fluid p-formgrid p-grid without-border"
        *ngIf="data.first_patient.insured_via.includes('Kostenträger')"
      >
        <div class="p-field p-col-6">
          <label for="first_patient_kostentraeger"
            >Kostenzusage Kostenträger</label
          >
          <!--          <div class="p-formgroup-inline">-->
          <!--            <div class="p-field-checkbox">-->
          <!--              <p-checkbox-->
          <!--                [(ngModel)]="values.first_patient.no_need_kostentraeger"-->
          <!--                [binary]="true"-->
          <!--                label="nicht nötig"-->
          <!--                name="first_patient_no_need_kostentraeger"-->
          <!--              ></p-checkbox>-->
          <!--            </div>-->
          <!--          </div>-->

          <input
            id="first_patient_kostentraeger"
            name="first_patient_kostentraeger"
            type="file"
            (change)="setSelectedFile($event, 'first_patient', 'kostentraeger')"
            pInputText
            [(ngModel)]="values.first_patient.selected_kostentraeger"
          />
        </div>
        <div class="p-field p-col-6">
          <label for="first_patient_kostentraeger_name"
            >Kostenzusage Kostenträger Name</label
          >
          <input
            id="first_patient_kostentraeger_name"
            name="first_patient_kostentraeger_name"
            type="text"
            pInputText
            [(ngModel)]="fileNames.first_patient_kostentraeger"
          />
        </div>
      </div>

      <!-- Abtretung Patient 1 -->
      <div
        class="p-fluid p-formgrid p-grid without-border"
        *ngIf="data.first_patient.insured_via.includes('GKV')"
      >
        <div class="p-field p-col-6">
          <label
            for="first_patient_abtretung"
            style="justify-content: space-between"
            >Abtretung
            <span class="color-alert">Wird an opta data versendet!</span></label
          >
          <!--          <div class="p-formgroup-inline">-->
          <!--            <div class="p-field-checkbox">-->
          <!--              <p-checkbox-->
          <!--                [(ngModel)]="values.first_patient.no_need_abtretung"-->
          <!--                [binary]="true"-->
          <!--                label="nicht nötig"-->
          <!--                name="first_patient_no_need_abtretung"-->
          <!--              ></p-checkbox>-->
          <!--            </div>-->
          <!--          </div>-->

          <input
            id="first_patient_abtretung"
            name="first_patient_abtretung"
            type="file"
            (change)="setSelectedFile($event, 'first_patient', 'abtretung')"
            pInputText
            [(ngModel)]="values.first_patient.selected_abtretung"
          />
        </div>
        <div class="p-field p-col-6">
          <label for="first_patient_abtretung_name">Abtretung Name</label>
          <input
            id="first_patient_abtretung_name"
            name="first_patient_abtretung_name"
            type="text"
            [(ngModel)]="fileNames.first_patient_abtretung"
            pInputText
          />
        </div>
      </div>

      <!-- Entbindung Patient 1 -->
      <div
        class="p-fluid p-formgrid p-grid without-border"
        *ngIf="data.first_patient.insured_via.includes('GKV')"
      >
        <div class="p-field p-col-6">
          <label for="first_patient_entbindung">Entbindung</label>
          <input
            id="first_patient_entbindung"
            name="first_patient_entbindung"
            type="file"
            (change)="setSelectedFile($event, 'first_patient', 'entbindung')"
            pInputText
            [(ngModel)]="values.first_patient.selected_entbindung"
          />
        </div>
        <div class="p-field p-col-6">
          <label for="first_patient_entbindung_name">Entbindung Name</label>
          <input
            id="first_patient_entbindung_name"
            name="first_patient_entbindung_name"
            type="text"
            [(ngModel)]="fileNames.first_patient_entbindung"
            pInputText
          />
        </div>
      </div>

      <!-- Vollmacht Patient 1 -->
      <div
        class="p-fluid p-formgrid p-grid without-border"
        *ngIf="data.first_patient.insured_via.includes('GKV')"
      >
        <div class="p-field p-col-6">
          <label for="first_patient_vollmacht">Vollmacht</label>
          <input
            id="first_patient_vollmacht"
            name="first_patient_vollmacht"
            type="file"
            (change)="setSelectedFile($event, 'first_patient', 'vollmacht')"
            pInputText
            [(ngModel)]="values.first_patient.selected_vollmacht"
          />
        </div>
        <div class="p-field p-col-6">
          <label for="first_patient_vollmacht_name">Vollmacht Name</label>
          <input
            id="first_patient_vollmacht_name"
            name="first_patient_vollmacht_name"
            type="text"
            [(ngModel)]="fileNames.first_patient_vollmacht"
            pInputText
          />
        </div>
      </div>
    </div>
  </div>

  <!-- Zweiter Patient -->
  <div
    class="dialog-form-body border-top smaller-grid p-d-flex without-padding"
    *ngIf="data.second_patient"
  >
    <!-- Linke Seite -->
    <div class="dialog-left-side">
      <div class="p-field">
        <label for="second-person-persplan-id">Person 2</label>
        <div>
          {{ data.second_patient.form_of_address }}
          {{ data.second_patient.first_name }}
          {{ data.second_patient.last_name }}
        </div>
      </div>

      <!-- Persplan ID Patient 2 -->
      <div class="p-fluid p-formgrid p-grid without-border">
        <div class="p-field p-col-12">
          <label for="second-person-persplan-id">Persplan ID</label>
          <p-inputNumber
            inputId="second-person-persplan-id"
            name="second-person-persplan-id"
            [useGrouping]="false"
            [required]="false"
            [(ngModel)]="data.second_patient.persplan_id"
            #second_persplan_id="ngModel"
            [class.p-invalid]="
              second_persplan_id.invalid &&
              (second_persplan_id.dirty || second_persplan_id.touched)
            "
          ></p-inputNumber>

          <div
            *ngIf="
              second_persplan_id.invalid &&
              (second_persplan_id.dirty || second_persplan_id.touched)
            "
            class="p-invalid"
          >
            <div *ngIf="second_persplan_id.errors?.required">
              <small>Bitte geben Sie eine Persplan ID ein</small>
            </div>
          </div>
        </div>
      </div>

      <div class="p-fluid p-formgrid p-grid without-border">
        <div class="p-field p-col-6">
          <label>Abrechnungsart</label>
          <b>{{ data.second_patient.insured_via }}</b>
        </div>

        <div class="p-field p-col-6">
          <label>Pflegegrad</label>
          <b>
            {{ data.second_patient.degree_of_care }} seit
            {{ data.second_patient.degree_of_care_since_string || "???" }}
          </b>
        </div>
      </div>

      <div class="p-fluid p-formgrid p-grid without-border">
        <div class="p-field p-col-6">
          <label>Pflegeperson</label>
          <b>{{ data.second_patient.nursing_person || "Kein Eintrag" }}</b>
        </div>

        <div class="p-field p-col-6">
          <label>IK Nummer</label>
          <b>
            {{ data.second_patient.care_insurance.pflege_ik || "Kein Eintrag" }}
            {{ data.second_patient.care_insurance.name }}
          </b>
        </div>
      </div>

      <div class="p-fluid p-formgrid p-grid without-border">
        <div class="p-field p-col-6">
          <label>VS Nummer</label>
          <b>
            {{ data.second_patient.insurer_number || "Kein Eintrag" }}
          </b>
        </div>

        <div class="p-field p-col-6">
          <label>Leistungsart</label>
          <b>
            {{ data.second_patient.service_for || "Kein Eintrag" }}
          </b>
        </div>
      </div>
    </div>

    <!-- Rechte Seite -->
    <div class="dialog-right-side">
      <!-- DLV Patient 1 -->
      <div class="p-fluid p-formgrid p-grid without-border">
        <div class="p-field p-col-6">
          <label for="second_patient_dlv">DLV</label>
          <input
            id="second_patient_dlv"
            name="second_patient_dlv"
            type="file"
            (change)="setSelectedFile($event, 'second_patient', 'dlv')"
            pInputText
            [(ngModel)]="values.second_patient.selected_dlv"
          />
        </div>
        <div class="p-field p-col-6">
          <label for="second_patient_dlv_name">DLV Name</label>
          <input
            id="second_patient_dlv_name"
            name="second_patient_dlv_name"
            type="text"
            [(ngModel)]="fileNames.second_patient_dlv"
            pInputText
          />
        </div>
      </div>

      <!-- SEPA Patient 1 -->
      <div class="p-fluid p-formgrid p-grid without-border">
        <div class="p-field p-col-6">
          <label for="second_patient_sepa">SEPA</label>
          <input
            id="second_patient_sepa"
            name="second_patient_sepa"
            type="file"
            (change)="setSelectedFile($event, 'second_patient', 'sepa')"
            pInputText
            [(ngModel)]="values.second_patient.selected_sepa"
          />
        </div>
        <div class="p-field p-col-6">
          <label for="second_patient_sepa_name">SEPA Name</label>
          <input
            id="second_patient_sepa_name"
            name="second_patient_sepa_name"
            type="text"
            [(ngModel)]="fileNames.second_patient_sepa"
            pInputText
          />
        </div>
      </div>

      <!-- UV Patient 1 -->
      <div
        class="p-fluid p-formgrid p-grid without-border"
        *ngIf="
          data.second_patient.insured_via.includes('UV') ||
          data.second_patient.insured_via.includes('GKV')
        "
      >
        <div class="p-field p-col-6">
          <label for="second_patient_uv">Kostenzusage UV</label>
          <!--          <div class="p-formgroup-inline">-->
          <!--            <div class="p-field-checkbox">-->
          <!--              <p-checkbox-->
          <!--                [(ngModel)]="values.second_patient.no_need_uv"-->
          <!--                [binary]="true"-->
          <!--                label="nicht nötig"-->
          <!--                name="second_patient_no_need_uv"-->
          <!--              ></p-checkbox>-->
          <!--            </div>-->
          <!--          </div>-->
          <input
            id="second_patient_uv"
            name="second_patient_uv"
            type="file"
            (change)="setSelectedFile($event, 'second_patient', 'uv')"
            pInputText
            [(ngModel)]="values.second_patient.selected_uv"
          />
        </div>
        <div class="p-field p-col-6">
          <label for="second_patient_uv_name">Kostenzusage UV Name</label>
          <input
            id="second_patient_uv_name"
            name="second_patient_uv_name"
            type="text"
            [(ngModel)]="fileNames.second_patient_uv"
            pInputText
          />
        </div>
      </div>

      <!-- Einzelfallentscheidung Patient 1 -->
      <div
        class="p-fluid p-formgrid p-grid without-border"
        *ngIf="
          data.second_patient.insured_via.includes('SGB V') ||
          data.second_patient.insured_via.includes('GKV')
        "
      >
        <div class="p-field p-col-6">
          <label for="second_patient_einzelfallentscheidung"
            >Einzelfallentscheidung</label
          >
          <!--          <div class="p-formgroup-inline">-->
          <!--            <div class="p-field-checkbox">-->
          <!--              <p-checkbox-->
          <!--                [(ngModel)]="values.second_patient.no_need_einzelfallentscheidung"-->
          <!--                [binary]="true"-->
          <!--                label="nicht nötig"-->
          <!--                name="second_patient_no_need_einzelfallentscheidung"-->
          <!--              ></p-checkbox>-->
          <!--            </div>-->
          <!--          </div>-->

          <input
            id="second_patient_einzelfallentscheidung"
            name="second_patient_einzelfallentscheidung"
            type="file"
            (change)="
              setSelectedFile(
                $event,
                'second_patient',
                'einzelfallentscheidung'
              )
            "
            pInputText
            [(ngModel)]="
              values.second_patient.selected_einzelfallentscheidung &&
              !values.second_patient.no_need_einzelfallentscheidung
            "
          />
        </div>
        <div class="p-field p-col-6">
          <label for="second_patient_einzelfallentscheidung_name"
            >Einzelfallentscheidung Name</label
          >
          <input
            id="second_patient_einzelfallentscheidung_name"
            name="second_patient_einzelfallentscheidung_name"
            type="text"
            pInputText
            [(ngModel)]="fileNames.second_patient_einzelfallentscheidung"
          />
        </div>
      </div>

      <!-- Kostenzusage Kostenträger Patient 2 -->
      <div
        class="p-fluid p-formgrid p-grid without-border"
        *ngIf="data.second_patient.insured_via.includes('Kostenträger')"
      >
        <div class="p-field p-col-6">
          <label for="second_patient_kostentraeger"
            >Kostenzusage Kostenträger</label
          >
          <!--          <div class="p-formgroup-inline">-->
          <!--            <div class="p-field-checkbox">-->
          <!--              <p-checkbox-->
          <!--                [(ngModel)]="values.second_patient.no_need_kostentraeger"-->
          <!--                [binary]="true"-->
          <!--                label="nicht nötig"-->
          <!--                name="second_patient_no_need_kostentraeger"-->
          <!--              ></p-checkbox>-->
          <!--            </div>-->
          <!--          </div>-->

          <input
            id="second_patient_kostentraeger"
            name="second_patient_kostentraeger"
            type="file"
            (change)="
              setSelectedFile($event, 'second_patient', 'kostentraeger')
            "
            pInputText
            [(ngModel)]="values.second_patient.selected_kostentraeger"
          />
        </div>
        <div class="p-field p-col-6">
          <label for="second_patient_kostentraeger_name"
            >Kostenzusage Kostenträger Name</label
          >
          <input
            id="second_patient_kostentraeger_name"
            name="second_patient_kostentraeger_name"
            type="text"
            pInputText
            [(ngModel)]="fileNames.second_patient_kostentraeger"
          />
        </div>
      </div>

      <!-- Abtretung Patient 2 -->
      <div
        class="p-fluid p-formgrid p-grid without-border"
        *ngIf="data.second_patient.insured_via.includes('GKV')"
      >
        <div class="p-field p-col-6">
          <label
            for="second_patient_abtretung"
            style="justify-content: space-between"
            >Abtretung
            <span class="color-alert">Wird an opta data versendet!</span>
          </label>
          <!--          <div class="p-formgroup-inline">-->
          <!--            <div class="p-field-checkbox">-->
          <!--              <p-checkbox-->
          <!--                [(ngModel)]="values.second_patient.no_need_abtretung"-->
          <!--                [binary]="true"-->
          <!--                label="nicht nötig"-->
          <!--                name="second_patient_no_need_abtretung"-->
          <!--              ></p-checkbox>-->
          <!--            </div>-->
          <!--          </div>-->

          <input
            id="second_patient_abtretung"
            name="second_patient_abtretung"
            type="file"
            (change)="setSelectedFile($event, 'second_patient', 'abtretung')"
            pInputText
            [(ngModel)]="values.second_patient.selected_abtretung"
          />
        </div>
        <div class="p-field p-col-6">
          <label for="second_patient_abtretung_name">Abtretung Name</label>
          <input
            id="second_patient_abtretung_name"
            name="second_patient_abtretung_name"
            type="text"
            [(ngModel)]="fileNames.second_patient_abtretung"
            pInputText
          />
        </div>
      </div>

      <!-- Entbindung Patient 1 -->
      <div
        class="p-fluid p-formgrid p-grid without-border"
        *ngIf="data.second_patient.insured_via.includes('GKV')"
      >
        <div class="p-field p-col-6">
          <label for="second_patient_entbindung">Entbindung</label>
          <input
            id="second_patient_entbindung"
            name="second_patient_entbindung"
            type="file"
            (change)="setSelectedFile($event, 'second_patient', 'entbindung')"
            pInputText
            [(ngModel)]="values.second_patient.selected_entbindung"
          />
        </div>
        <div class="p-field p-col-6">
          <label for="second_patient_entbindung_name">Entbindung Name</label>
          <input
            id="second_patient_entbindung_name"
            name="second_patient_entbindung_name"
            type="text"
            [(ngModel)]="fileNames.second_patient_entbindung"
            pInputText
          />
        </div>
      </div>

      <!-- Vollmacht Patient 1 -->
      <div
        class="p-fluid p-formgrid p-grid without-border"
        *ngIf="data.second_patient.insured_via.includes('GKV')"
      >
        <div class="p-field p-col-6">
          <label for="second_patient_vollmacht">Vollmacht</label>
          <input
            id="second_patient_vollmacht"
            name="second_patient_vollmacht"
            type="file"
            (change)="setSelectedFile($event, 'second_patient', 'vollmacht')"
            pInputText
            [(ngModel)]="values.second_patient.selected_vollmacht"
          />
        </div>
        <div class="p-field p-col-6">
          <label for="second_patient_vollmacht_name">Vollmacht Name</label>
          <input
            id="second_patient_vollmacht_name"
            name="second_patient_vollmacht_name"
            type="text"
            [(ngModel)]="fileNames.second_patient_vollmacht"
            pInputText
          />
        </div>
      </div>
    </div>
  </div>

  <div class="dialog-form-footer">
    <i></i>
    <button
      pButton
      (click)="upload()"
      label="Qualifizierung beenden"
      type="submit"
      [disabled]="submitted"
      [icon]="submitted ? 'pi pi-spin pi-spinner' : 'pi pi-check'"
    ></button>
  </div>
</form>
