export class CaregiverEmployeedModel {
  id = 0
  caregiver_id = 0
  employed_since_date: Date | null = null
  employed_until_date: Date | null = null
  employed_since_date_string = ''
  employed_until_date_string = ''
  employed_temporary = false
  comment = ''
  deleted_at: Date | null = null
  created_at: Date | null = null
  updated_at: Date | null = null
}
