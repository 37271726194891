<div class="content-actions">
  <h2>
    {{ customerDetail.first_patient.form_of_address }}
    {{ customerDetail.first_patient.first_name }}
    {{ customerDetail.first_patient.last_name }}

    <span *ngIf="customerDetail.first_patient.id"
      >#{{ customerDetail.first_patient.id }}</span
    >
  </h2>

  <a
    *ngIf="customerDetail.id && !customerDetail.admin_update_clicked_at"
    routerLink="/neu/{{ customerDetail.id }}"
  >
    <button
      pButton
      label="Bearbeiten"
      class="p-button-text"
      type="button"
      icon="pi pi-pencil"
      iconPos="right"
    ></button>
  </a>
</div>

<header class="content-header-tabs" [class.disabled]="!customerDetail.id">
  <a
    routerLink="/patienten/{{ customerDetail.first_patient.id }}"
    routerLinkActive="active"
    [routerLinkActiveOptions]="{ exact: true }"
    >Allgemeines</a
  >
  <a
    routerLink="/patienten/{{ customerDetail.first_patient.id }}/historie"
    routerLinkActive="active"
    [routerLinkActiveOptions]="{ exact: true }"
    >Historie</a
  >
</header>

<router-outlet></router-outlet>
