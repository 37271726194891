import { Component, OnInit } from '@angular/core'
import { TitleService } from 'src/app/services/title.service'
import { RolePermissionService } from '../../../../services/role-permission.service'
import { RoleModel } from '../../../../models/permission/role.model'
import { AuthService } from '../../../../services/auth.service'

@Component({
  selector: 'app-roles-list',
  templateUrl: './roles-list.component.html',
})
export class RolesListComponent implements OnInit {
  public loading = false

  public roles: RoleModel[] = []
  public hasPermission = true

  public globalFilterValue = ''

  constructor(
    private rolePermissionService: RolePermissionService,
    private authService: AuthService,
    private titleService: TitleService
  ) {}

  ngOnInit(): void {
    this.loadRoles()

    this.titleService.setTitle('Rollen')
  }

  private loadRoles(): void {
    this.loading = true

    this.rolePermissionService.roles().subscribe((data: RoleModel[]) => {
      this.roles = data

      this.loading = false
    })
  }
}
