<div class="dialog-loader" *ngIf="loading">
  <i class="loading-logo"></i>
  ÄNDERUNGEN WERDEN GELADEN
</div>

<div class="diff-header" *ngIf="!loading">
  <div>Vorher</div>
  <div>Nachher</div>
</div>
<div class="diff-line" *ngFor="let content of data.content; let i = index">
  <div>
    <b>{{ data.content_before[i].name }}</b
    ><br />
    {{ data.content_before[i].value || "-" }}
  </div>
  <div>
    <b>{{ content.name }}</b
    ><br />
    {{ content.value || "-" }}
  </div>
</div>
