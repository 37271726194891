import { Component, OnInit } from '@angular/core'
import { TitleService } from 'src/app/services/title.service'
import { AuthService } from '../../services/auth.service'

@Component({
  selector: 'app-invoices',
  templateUrl: './invoices.component.html',
})
export class InvoicesComponent implements OnInit {
  constructor(
    private titleService: TitleService,
    public authService: AuthService
  ) {}

  ngOnInit(): void {
    this.titleService.setTitle(`Rechnungen`)
  }
}
