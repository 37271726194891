import { Component, OnInit } from '@angular/core'
import { AuthService } from '../../../services/auth.service'
import { ActivatedRoute } from '@angular/router'
import { HttpErrorResponse } from '@angular/common/http'
import { StatusCodes } from 'http-status-codes'

@Component({
  selector: 'app-change-initial-password',
  templateUrl: './change-initial-password.component.html',
})
export class ChangeInitialPasswordComponent implements OnInit {
  public submitted = false
  public isSuccess = false
  public errorMessage = ''

  public data = {
    password: '',
    password_confirmation: '',
  }

  constructor(
    private authService: AuthService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {}

  public confirm(): void {
    this.isSuccess = false
    this.errorMessage = ''

    if (this.data.password !== this.data.password_confirmation) {
      this.errorMessage = 'Die Passwörter stimmen nicht überein.'
      return
    }

    this.submitted = true

    this.authService.changeInitialPassword(this.data).subscribe(
      () => {
        this.isSuccess = true
        this.submitted = false

        window.location.href = '/'
      },
      (error: HttpErrorResponse) => {
        if (error.status === StatusCodes.UNPROCESSABLE_ENTITY) {
          this.errorMessage =
            'Bitte prüfen Sie Ihr Passwort nach den gewünschten Regeln.'
        } else if (error.status === StatusCodes.EXPECTATION_FAILED) {
          this.errorMessage = 'Ihr initiales Passwort wurde bereits geändert.'
        } else {
          this.errorMessage =
            'Etwas ist schief gelaufen. Bitte wenden Sie sich an den Support.'
        }

        this.submitted = false
      }
    )
  }
}
