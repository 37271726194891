<app-loader [loading]="loading"></app-loader>

<div class="content" *ngIf="!loading">
  <header class="content-header is-sticky">
    <div class="content-header-patients-tabs">
      <span
        (click)="changePatient('first_patient')"
        [class.active]="selectedPatient === 'first_patient'"
      >
        <i class="pi pi-user"></i>
        {{ patients.first_patient.full_name }}

        <i
          *ngIf="patients.first_patient.status"
          class="p-tag p-tag-rounded p-tag-{{ patients.first_patient.status }}"
          >{{ patients.first_patient.status_string }}</i
        >

        <i
          *ngIf="patients.first_patient.is_paused"
          class="p-tag p-tag-rounded p-tag-PAUSED"
          >Pausiert</i
        >
      </span>
      <span
        (click)="changePatient('second_patient')"
        [class.active]="selectedPatient === 'second_patient'"
        *ngIf="patients.second_patient"
      >
        <i class="pi pi-users"></i>
        {{ patients.second_patient.full_name }}

        <i
          *ngIf="patients.second_patient.status"
          class="p-tag p-tag-rounded p-tag-{{ patients.second_patient.status }}"
          >{{ patients.second_patient.status_string }}</i
        >

        <i
          *ngIf="patients.second_patient.is_paused"
          class="p-tag p-tag-rounded p-tag-PAUSED"
          >Pausiert</i
        >
      </span>
    </div>
    <div class="content-header-buttons">
      <div
        class="table-search-container"
        *ngIf="patients[this.selectedPatient].histories.length > 0"
      >
        <div class="p-input-icon-left">
          <i class="pi pi-search"></i>
          <input
            pInputText
            (input)="filterTableGlobal($event)"
            placeholder="Suche..."
            [value]="globalFilterValue"
          />
        </div>
      </div>
    </div>
  </header>
  <div class="content-body without-padding auto-height">
    <p-table
      #dt
      [globalFilterFields]="[
        'type_text',
        'manual_title',
        'manual_description',
        'appointment_html_table',
      ]"
      [value]="patients[this.selectedPatient].histories"
      [rowHover]="true"
    >
      <ng-template pTemplate="header">
        <tr>
          <th style="width: 200px" pSortableColumn="type_text">
            Typ <p-sortIcon field="type_text"></p-sortIcon>
          </th>
          <th style="width: 200px" pSortableColumn="created_at">
            am <p-sortIcon field="created_at"></p-sortIcon>
          </th>
<!--          <th style="width: 200px" pSortableColumn="created_from_name">-->
<!--            von <p-sortIcon field="created_from_name"></p-sortIcon>-->
<!--          </th>-->
          <th style="width: 50px"></th>
          <th>Informationen</th>
          <th style="width: 100px"></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-history>
        <!-- TODO: Achtung bei [hidden] -> Falls irgendwann Pagination reinkommt, wird das nicht mehr funktionieren -->
        <tr
          [class.background-hightlight-yellow]="history.important"
          [hidden]="
            (onlyImportant && !history.important) || typeIsHidden[history.type]
          "
        >
          <td>
            <strong>
              {{ history.type_text }}
            </strong>
          </td>

          <td>{{ history.occurred_at_string }} Uhr</td>

<!--          <td>{{ history.created_from_name || "??" }}</td>-->

          <td></td>
          <td>
            <span
              class="history-information"
              *ngIf="
                history.type === 'MANUAL' ||
                history.type === 'STATUS_CHANGED' ||
                history.type === 'CUSTOMER_SERVICE_CHANGED' ||
                history.type === 'TODO_CREATED' ||
                history.type === 'TODO_DELETED' ||
                history.type === 'TODO_FINISHED' ||
                history.type === 'TODO_UPDATED' ||
                history.type === 'VETO_CREATED' ||
                history.type === 'VETO_DELETED' ||
                history.type === 'VETO_UPDATED' ||
                history.type === 'BUDGET_CHANGED' ||
                history.type === 'OFFER_SENT' ||
                history.type === 'DOCUMENT_CHANGED' ||
                history.type === 'DOCUMENT_REMOVED' ||
                history.type === 'PAUSED' ||
                history.type === 'PAUSE_REACTIVATED' ||
                history.type === 'APPOINTMENTS_SENT' ||
                history.type === 'DESIRED_DATE_CREATED' ||
                history.type === 'DESIRED_DATE_UPDATED' ||
                history.type === 'DESIRED_DATE_DELETED' ||
                history.type === 'CANCELLED_DATE_CREATED' ||
                history.type === 'CANCELLED_DATE_UPDATED' ||
                history.type === 'CANCELLED_DATE_DELETED' ||
                history.type === 'CREATED' ||
                history.type === 'ACCOUNTING_RETURN_CREATED' ||
                history.type === 'ACCOUNTING_RETURN_DELETED' ||
                history.type === 'LETTER_SENT' ||
                history.type === 'PATIENT_TIMES_CHANGED' ||
                history.type === 'APPOINTMENT_FEEDBACK_FINISHED_WITH_HISTORY'
              "
            >
              <i
                ><b>{{ history.manual_title }}</b></i
              >
              <p
                class="history-text"
                [innerHTML]="history.manual_description"
                *ngIf="history.manual_description"
              ></p>
              <p
                class="history-text"
                *ngIf="history.appointment_html_table"
                [innerHTML]="history.appointment_html_table"
              ></p>
            </span>
          </td>
          <td class="table-action"></td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
