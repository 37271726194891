<app-loader [loading]="loading"></app-loader>

<div class="content" *ngIf="!loading">
  <header class="content-header without-border">
    <span>
      <i class="pi pi-user"></i>
      Systembenutzer
    </span>
    <div class="setting-menu-container" #settingContainer>
      <button
        pButton
        label="Einstellungen"
        type="button"
        class="p-button-outlined"
        icon="pi pi-chevron-down"
        iconPos="right"
        (click)="menu.toggle($event)"
      ></button>
      <p-menu #menu [popup]="true" [model]="settingMenuItems"></p-menu>
    </div>
  </header>
</div>

<div class="content" style="margin: 0" *ngIf="!loading">
  <div class="content-body without-padding p-flex-row">
    <div class="detail-information-column border-right">
      <div class="detail-information-container">
        <div class="detail-information-header">
          <span><i class="pi pi-bars"></i>Daten</span>
        </div>
        <div class="detail-information-body">
          <div class="detail-information">
            <div class="detail-key clickable">
              <span
                tooltipPosition="left"
                (mouseenter)="
                  getLatestChange(userSystem.id, 'user_system', {
                    as_supervisor: userSystem.as_supervisor
                  })
                "
                (click)="
                  openDiffViewHistory(userSystem.id, 'user_system', {
                    as_supervisor: userSystem.as_supervisor
                  })
                "
                [pTooltip]="latestChanges.as_supervisor"
                >Als Betreuer</span
              >
            </div>
            <div class="detail-value p-align-center">
              <i
                style="font-size: 14px"
                class="pi pi-check color-main1"
                *ngIf="userSystem.as_supervisor"
              ></i>
              <i
                style="font-size: 14px"
                class="pi pi-times color-gray"
                *ngIf="!userSystem.as_supervisor"
              ></i>
            </div>
          </div>
          <div class="detail-information">
            <div class="detail-key clickable">
              <span
                tooltipPosition="left"
                (mouseenter)="
                  getLatestChange(userSystem.id, 'user_system', {
                    for_consulting: userSystem.for_consulting
                  })
                "
                (click)="
                  openDiffViewHistory(userSystem.id, 'user_system', {
                    for_consulting: userSystem.for_consulting
                  })
                "
                [pTooltip]="latestChanges.for_consulting"
                >Für Beratungseinsätze</span
              >
            </div>
            <div class="detail-value p-align-center">
              <i
                style="font-size: 14px"
                class="pi pi-check color-main1"
                *ngIf="userSystem.for_consulting"
              ></i>
              <i
                style="font-size: 14px"
                class="pi pi-times color-gray"
                *ngIf="!userSystem.for_consulting"
              ></i>
            </div>
          </div>
          <div class="detail-information">
            <div class="detail-key clickable">
              <span
                tooltipPosition="left"
                (mouseenter)="
                  getLatestChange(userSystem.id, 'user_system', {
                    for_thc: userSystem.for_thc
                  })
                "
                (click)="
                  openDiffViewHistory(userSystem.id, 'user_system', {
                    for_thc: userSystem.for_thc
                  })
                "
                [pTooltip]="latestChanges.for_thc"
                >Für THC</span
              >
            </div>
            <div class="detail-value p-align-center">
              <i
                style="font-size: 14px"
                class="pi pi-check color-main1"
                *ngIf="userSystem.for_thc"
              ></i>
              <i
                style="font-size: 14px"
                class="pi pi-times color-gray"
                *ngIf="!userSystem.for_thc"
              ></i>
            </div>
          </div>

          <div class="detail-information">
            <div class="detail-key clickable">
              <span
                tooltipPosition="left"
                (mouseenter)="
                  getLatestChange(userSystem.id, 'user_system', {
                    form_of_address: userSystem.form_of_address
                  })
                "
                (click)="
                  openDiffViewHistory(userSystem.id, 'user_system', {
                    form_of_address: userSystem.form_of_address
                  })
                "
                [pTooltip]="latestChanges.form_of_address"
                >Anrede</span
              >
            </div>
            <div class="detail-value">
              <strong>{{ userSystem.form_of_address }}</strong>
            </div>
          </div>
          <div class="detail-information">
            <div class="detail-key clickable">
              <span
                tooltipPosition="left"
                (mouseenter)="
                  getLatestChange(userSystem.id, 'user_system', {
                    first_name: userSystem.first_name
                  })
                "
                (click)="
                  openDiffViewHistory(userSystem.id, 'user_system', {
                    first_name: userSystem.first_name
                  })
                "
                [pTooltip]="latestChanges.first_name"
                >Vorname</span
              >
            </div>
            <div class="detail-value">
              <strong>{{ userSystem.first_name }}</strong>
            </div>
          </div>
          <div class="detail-information">
            <div class="detail-key clickable">
              <span
                tooltipPosition="left"
                (mouseenter)="
                  getLatestChange(userSystem.id, 'user_system', {
                    last_name: userSystem.last_name
                  })
                "
                (click)="
                  openDiffViewHistory(userSystem.id, 'user_system', {
                    last_name: userSystem.last_name
                  })
                "
                [pTooltip]="latestChanges.last_name"
                >Nachname</span
              >
            </div>
            <div class="detail-value">
              <strong>{{ userSystem.last_name }}</strong>
            </div>
          </div>
          <div class="detail-information">
            <div class="detail-key clickable">
              <span
                tooltipPosition="left"
                (mouseenter)="
                  getLatestChange(userSystem.id, 'user_system', {
                    birthday_date_string: userSystem.birthday_date_string
                  })
                "
                (click)="
                  openDiffViewHistory(userSystem.id, 'user_system', {
                    birthday_date_string: userSystem.birthday_date_string
                  })
                "
                [pTooltip]="latestChanges.birthday_date_string"
                >Geburtsdatum</span
              >
            </div>
            <div class="detail-value">
              <strong>{{ userSystem.birthday_date_string }}</strong>
            </div>
          </div>
          <div class="detail-information">
            <div class="detail-key clickable">
              <span
                tooltipPosition="left"
                (mouseenter)="
                  getLatestChange(userSystem.id, 'user_system', {
                    phone_identifier: userSystem.phone_identifier
                  })
                "
                (click)="
                  openDiffViewHistory(userSystem.id, 'user_system', {
                    phone_identifier: userSystem.phone_identifier
                  })
                "
                [pTooltip]="latestChanges.phone_identifier"
                >Telefon Kennung</span
              >
            </div>
            <div class="detail-value">
              <strong>{{ userSystem.phone_identifier || "-" }}</strong>
            </div>
          </div>
          <div class="detail-information">
            <div class="detail-key clickable">
              <span
                tooltipPosition="left"
                (mouseenter)="
                  getLatestChange(userSystem.id, 'user_system', {
                    phone_1: userSystem.phone_1
                  })
                "
                (click)="
                  openDiffViewHistory(userSystem.id, 'user_system', {
                    phone_1: userSystem.phone_1
                  })
                "
                [pTooltip]="latestChanges.phone_1"
                >Telefon 1</span
              >
            </div>
            <div class="detail-value">
              <strong *ngIf="!userSystem.phone_1">-</strong>
              <strong>
                <a
                  *ngIf="userSystem.phone_1"
                  (click)="phoneCallService.createCalling(userSystem.phone_1)"
                  href="tel:{{ userSystem.phone_1 }}"
                >
                  {{ userSystem.phone_1 }}
                </a>
              </strong>
            </div>
          </div>
          <div class="detail-information">
            <div class="detail-key clickable">
              <span
                tooltipPosition="left"
                (mouseenter)="
                  getLatestChange(userSystem.id, 'user_system', {
                    phone_2: userSystem.phone_2
                  })
                "
                (click)="
                  openDiffViewHistory(userSystem.id, 'user_system', {
                    phone_2: userSystem.phone_2
                  })
                "
                [pTooltip]="latestChanges.phone_2"
                >Telefon 2</span
              >
            </div>
            <div class="detail-value">
              <strong *ngIf="!userSystem.phone_2">-</strong>
              <strong>
                <a
                  *ngIf="userSystem.phone_2"
                  (click)="phoneCallService.createCalling(userSystem.phone_2)"
                  href="tel:{{ userSystem.phone_2 }}"
                >
                  {{ userSystem.phone_2 }}
                </a>
              </strong>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="detail-information-column">
      <div class="detail-information-container">
        <div class="detail-information-header">
          <span><i class="pi pi-key"></i>Account</span>
        </div>
        <div class="detail-information-body">
          <div class="detail-information">
            <div class="detail-key">
              <span>Letzter Login</span>
            </div>
            <div class="detail-value">
              <strong *ngIf="userSystem.user.last_login">
                {{ userSystem.user.last_login.created_at | date: "dd.MM.y, H:mm:ss" }} Uhr
              </strong>
              <strong *ngIf="!userSystem.user.last_login">
                -
              </strong>
            </div>
          </div>

          <div class="detail-information">
            <div class="detail-key clickable">
              <span
                tooltipPosition="left"
                (mouseenter)="
                  getLatestChange(userSystem.id, 'user_system', {
                    username: userSystem.user.name
                  })
                "
                (click)="
                  openDiffViewHistory(userSystem.id, 'user_system', {
                    username: userSystem.user.name
                  })
                "
                [pTooltip]="latestChanges.username"
                >Benutzername</span
              >
            </div>
            <div class="detail-value">
              <strong>
                {{ userSystem.user.name }}
              </strong>
            </div>
          </div>
          <div class="detail-information">
            <div class="detail-key clickable">
              <span
                tooltipPosition="left"
                (mouseenter)="
                  getLatestChange(userSystem.id, 'user_system', {
                    email: userSystem.user.email
                  })
                "
                (click)="
                  openDiffViewHistory(userSystem.id, 'user_system', {
                    email: userSystem.user.email
                  })
                "
                [pTooltip]="latestChanges.email"
                >E-Mail</span
              >
            </div>
            <div class="detail-value">
              <strong>
                <a href="mailto:{{ userSystem.user.email }}">
                  {{ userSystem.user.email }}
                </a>
              </strong>
            </div>
          </div>
          <div class="detail-information">
            <div class="detail-key clickable">
              <span
                tooltipPosition="left"
                (mouseenter)="
                  getLatestChange(userSystem.id, 'user_system', {
                    role_names: userSystem.role_names
                  })
                "
                (click)="
                  openDiffViewHistory(userSystem.id, 'user_system', {
                    role_names: userSystem.role_names
                  })
                "
                [pTooltip]="latestChanges.role_names"
                >Rollen</span
              >
            </div>

            <div class="detail-value">
              <strong>
                {{ userSystem.role_names || "-" }}
              </strong>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
