import { AuthGuard } from '../guards/auth.guard'
import { MultipliersListComponent } from '../views/multipliers/list/multipliers-list.component'
import { MultipliersFormComponent } from '../views/multipliers/form/multipliers-form.component'
import { DirtyCheckGuard } from '../guards/dirty-check.guard'
import { MultipliersDetailComponent } from '../views/multipliers/detail/multipliers-detail.component'
import { MultipliersDetailGeneralComponent } from '../views/multipliers/detail/general/multipliers-detail-general.component'
import { MultipliersDetailHistoryComponent } from '../views/multipliers/detail/history/multipliers-detail-history.component'

export const multipliersRoutes = [
  {
    path: 'multipliers',
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: MultipliersListComponent,
      },
      {
        path: 'new',
        component: MultipliersFormComponent,
        canDeactivate: [DirtyCheckGuard],
      },
      {
        path: ':id',
        component: MultipliersDetailComponent,
        children: [
          {
            path: '',
            component: MultipliersDetailGeneralComponent,
          },
          {
            path: 'history',
            component: MultipliersDetailHistoryComponent,
          },
        ],
      },
      {
        path: ':id/edit',
        component: MultipliersFormComponent,
        canDeactivate: [DirtyCheckGuard],
      },
    ],
  },
]
