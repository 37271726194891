import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core'
import { CaregiverService } from '../../../../../services/caregivers.service'
import { ActivatedRoute, ParamMap } from '@angular/router'
import { DialogService } from 'primeng/dynamicdialog'
import {
  EventBusService,
  GlobalEvent,
} from '../../../../../services/eventbus.service'
import { Subscription } from 'rxjs'
import { CaregiverDatesModel } from '../../../../../models/caregiver/caregiver-dates.model'
import { AddCaregiverDateDialogComponent } from '../../../../../components/dialogs/add-caregiver-date-dialog/add-caregiver-date-dialog.component'
import { PatientModel } from '../../../../../models/customer-patient/patient.model'
import {AuthService} from "../../../../../services/auth.service";

@Component({
  selector: 'app-caregivers-detail-recommendations',
  templateUrl: './caregivers-detail-recommendations.component.html',
  providers: [DialogService],
})
export class CaregiversDetailRecommendationsComponent
  implements OnInit, OnDestroy {
  @ViewChild('dt') table: ElementRef | any = null

  public loading = true
  public recommendations: PatientModel[] = []
  public caregiverId: string | null = null
  public globalFilterValue = ''

  private eventBusSubscription: Subscription = new Subscription()
  private routeSubscription: any = null

  constructor(
    public authService: AuthService,
    public caregiverService: CaregiverService,
    private route: ActivatedRoute,
    private eventbus: EventBusService
  ) {}

  ngOnInit(): void {
    this.loadData()
    this.listenForEventbus()
  }

  ngOnDestroy(): void {
    this.eventBusSubscription.unsubscribe()
    this.routeSubscription && this.routeSubscription.unsubscribe()
  }

  private listenForEventbus(): void {
    this.eventBusSubscription = this.eventbus.subject.subscribe(
      (event: GlobalEvent) => {
        switch (event) {
          case GlobalEvent.CaregiverDateChanged:
            this.loadData(false)
        }
      }
    )
  }

  public filterTableGlobal(event: any): void {
    this.table && this.table.filterGlobal(event.target.value.trim(), 'contains')
  }

  public togglePaid(patient: any): void {
    if (patient.caregiver_paid_recommendation) {
      if (window.confirm('Bezahlung zurückziehen?')) {
        this.caregiverService
          .removePaidRecommendation(patient.caregiver_paid_recommendation.id)
          .subscribe(() => {
            this.loadData(false)
          })
      }
    } else {
      if (window.confirm('Als bezahlt markieren?')) {
        this.caregiverService
          .addPaidRecommendation(this.caregiverId, patient.id)
          .subscribe(() => {
            this.loadData(false)
          })
      }
    }
  }

  public loadData(withLoading: boolean = true): void {
    if (withLoading) {
      this.loading = true
    }

    if (this.route.parent?.parent) {
      this.routeSubscription = this.route.parent.parent.paramMap.subscribe(
        (params: ParamMap) => {
          this.caregiverId = params.get('id')

          this.caregiverService
            .recommendations(this.caregiverId)
            .subscribe((data: PatientModel[]) => {
              this.recommendations = data

              if (withLoading) {
                this.loading = false
              }
            })
        }
      )
    }
  }
}
