import { HttpErrorResponse } from '@angular/common/http'
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core'
import { NgForm } from '@angular/forms'
import { StatusCodes } from 'http-status-codes'
import { SelectItem } from 'primeng/api'
import {
  DialogService,
  DynamicDialogConfig,
  DynamicDialogRef,
} from 'primeng/dynamicdialog'
import { Subscription } from 'rxjs'
import { EventBusService, GlobalEvent } from 'src/app/services/eventbus.service'
import { HistoryService } from 'src/app/services/history.service'
import { ToastService } from 'src/app/services/toast.service'
import { HistoryType } from '../../../models/history/history-list.model'
import * as dayjs from 'dayjs'
import { UserSystemSimpleModel } from '../../../models/user/user-system.model'
import { UserSystemService } from '../../../services/user-system.service'
import { AddDocumentDialogComponent } from '../add-document-dialog/add-document-dialog.component'
import { DocumentService } from '../../../services/document.service'
import {AuthService} from "../../../services/auth.service";
import {HelperService} from "../../../services/helper.service";

@Component({
  selector: 'app-history-manual-dialog',
  templateUrl: './history-manual-dialog.component.html',
})
export class HistoryManualDialogComponent implements OnInit, OnDestroy {
  @ViewChild('form', { static: true }) form!: NgForm

  private formSubscription: Subscription | null | undefined = null
  private isDirty = false

  public canEdit = false
  public data: any = {}
  public submitted = false
  public submittedDelete = false
  public withDocuments = true

  public hasRecommendation = false

  public patientOptions: any[] = []
  public userSystemOptions: any[] = []
  public historyTypeOptions: SelectItem[] = [
    { label: 'Telefonat', value: HistoryType.PHONE_CALL },
    { label: 'Planung', value: HistoryType.PLANING },
    { label: 'E-Mail', value: HistoryType.EMAIL },
    { label: 'WhatsApp', value: HistoryType.WHATSAPP },
    { label: 'Geburtstagswünsche', value: HistoryType.BIRTHDAY_WISHES },
    { label: 'Persönliches Gespräch', value: HistoryType.PERSONAL_TALK },
    { label: 'THC', value: HistoryType.THC },
    { label: 'Rückläufer', value: HistoryType.RETURNS },
    { label: 'QM 1', value: HistoryType.QM1 },
    { label: 'QM 2', value: HistoryType.QM2 },
    { label: 'Rechnung', value: HistoryType.INVOICE },
    { label: 'Sonstiges', value: HistoryType.MISC },
  ]

  public todoCategories = []

  public todo = {
    title: '',
    category_id: null,
    is_possible: false,
    create: false,
    for_when: null,
    priority: 2,
    for_when_type: 'FINISH_UNTIL',
    for_when_string: dayjs().format('DD.MM.YYYY'),
    user_systems: [],
  }

  public priorityOptions = [
    { label: 'Hoch', value: 1 },
    { label: 'Normal', value: 2 },
    { label: 'Niedrig', value: 3 },
  ]

  constructor(
    public documentService: DocumentService,
    private authService: AuthService,
    private helperService: HelperService,
    private ref: DynamicDialogRef,
    private config: DynamicDialogConfig,
    private userSystemService: UserSystemService,
    private historyService: HistoryService,
    private eventbus: EventBusService,
    private dialogService: DialogService,
    private toastService: ToastService
  ) {}

  ngOnInit(): void {
    this.data = this.config.data

    this.setCanEdit()

    this.helperService.dependencies$.subscribe((data: any) => {
      this.todoCategories = data['todo_categories']
    })

    // Manchmal soll man keine Dokumente hochladen können (z.B. bei Multi oder bei Systembenutzer).
    if (this.data.without_documents) {
      this.withDocuments = false
    }

    if (!this.data.history.id) {
      this.data.history.manual_type = HistoryType.MISC
      this.data.history.occurred_at_string = dayjs().format('DD.MM.YYYY, HH:mm')
    }

    if (this.data.is_birthday_wish) {
      this.data.history.manual_type = HistoryType.BIRTHDAY_WISHES
    }

    this.formSubscription = this.form.valueChanges?.subscribe(() => {
      if (!this.form.pristine) {
        this.isDirty = true
      }
    })

    if (this.data.type === 'patients' && !this.data.is_birthday_wish) {
      this.buildPatientOptions()

      // Prüft, ob der Kunde eine Empfehlung durch einen Multiplikator hat.
      if (
        this.data.patients.customer.recommendation_type?.includes('Multiplier')
      ) {
        this.hasRecommendation = true
      }
    }

    // To-Dos können nur für Patienten, BKs oder PKW erstellt werden und nur bei einer neuen History.
    if (
      ['patients', 'caregivers', 'car'].includes(this.data.type) &&
      !this.data.edit &&
      !this.data.is_birthday_wish
    ) {
      this.loadAllUserSystem()
      this.todo.is_possible = true
    }
  }

  ngOnDestroy(): void {
    this.formSubscription?.unsubscribe()
  }

  public openAddDocumentDialog(): void {
    const ref = this.dialogService.open(AddDocumentDialogComponent, {
      data: {
        type_id: this.data.type_id,
        type: this.data.type,
        default_document_type: 'misc',

        // Benötigen wir zusätzlich bei Patienten.
        patients: this.data.patients,
        contact_persons: this.data.patients?.contact_persons,
      },
      header: 'Dokument hochladen',
      styleClass: 'dialog-container',
      dismissableMask: false,
      width: '450px',
    })

    ref.onClose.subscribe((document: any) => {
      if (document) {
        this.data.history.documents.push(document.media)
      }
    })
  }

  private buildPatientOptions(): void {
    this.historyTypeOptions.push({
      label: 'Terminübergabe',
      value: HistoryType.APPOINTMENT_TRANSFER,
    })

    this.patientOptions.push({
      id: this.data.patients.first_patient.id,
      full_name: this.data.patients.first_patient.full_name,
    })

    if (this.data.patients.second_patient) {
      this.patientOptions.push({
        id: this.data.patients.second_patient.id,
        full_name: this.data.patients.second_patient.full_name,
      })
    }
  }

  // Die History darf nur der Ersteller oder Super-Admin bearbeiten.
  private setCanEdit(): void {
    const user = this.authService.getUser()

    this.canEdit = this.data.edit
      ? this.authService.isSuperAdmin() || this.data.history.created_from === user?.id
      : true
  }

  public setTodoTitle(): void {
    if (this.todo.create && !this.todo.title) {
      this.todo.title = this.data.history.manual_title
    }
  }

  /**
   * Speichert / updated den History-Eintrag.
   */
  public save(): void {
    if (!this.form.form.valid) {
      this.submitted = false
      this.form.form.markAllAsTouched()
      return
    }

    this.submitted = true

    const subscription = this.data.edit
      ? this.historyService.update(this.data.history)
      : this.historyService.store(
          {
            ...this.data.history,
            historiable_id: this.data.type_id,
            historiable_type: this.data.type,
          },
          this.todo
        )

    subscription.subscribe(
      () => {
        this.submitted = false
        this.eventbus.emit(GlobalEvent.HistoryListReload)

        if (this.data.is_birthday_wish) {
          this.eventbus.emit(GlobalEvent.BirthdayWished)
        }

        this.ref.close()
        this.toastService.success(
          'Eintrag gespeichert',
          'Der Eintrag wurde erfolgreich in der Historie gespeichert'
        )
      },
      (error: HttpErrorResponse) => {
        if (error.status === StatusCodes.UNPROCESSABLE_ENTITY) {
          this.toastService.error('Bitte füllen Sie alle Pflichtfelder aus')
        } else {
          this.toastService.error(
            'Etwas ist schief gelaufen...',
            'Bitte wenden Sie sich an den Support'
          )
        }
        this.submitted = false
      }
    )
  }

  private loadAllUserSystem(): void {
    this.userSystemService
      .listActiveSimple()
      .subscribe((data: UserSystemSimpleModel[]) => {
        for (let userSystem of data) {
          this.userSystemOptions.push({
            id: userSystem.id,
            full_name: userSystem.first_name + ' ' + userSystem.last_name,
          })
        }
      })
  }
}
