import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { environment } from '../../environments/environment'
import { Observable } from 'rxjs'
import { DiffViewModel } from '../models/history/diff-view.model'
import { HistoryListModel } from '../models/history/history-list.model'

@Injectable({
  providedIn: 'root',
})
export class HistoryService {
  constructor(private http: HttpClient) {}

  public getLatestChange(
    model_id: string,
    model_type: string,
    key: string,
    value: string
  ): Observable<string> {
    return this.http.get(`${environment.api}/history/latest-change`, {
      responseType: 'text',
      params: {
        model_id,
        model_type,
        key,
        value: encodeURIComponent(value),
      },
    })
  }

  public getLatestChangeMultiple(
    id: number | string | null,
    type:
      | 'patient'
      | 'qm'
      | 'household'
      | 'customer'
      | 'user_system'
      | 'contact_person'
      | 'caregiver'
      | 'car',
    data: { [key: string]: string }
  ): Observable<string> {
    const modelId = id ? id.toString() : ''

    return this.http.get(`${environment.api}/history/latest-change-multiple`, {
      responseType: 'text',
      params: {
        model_id: modelId,
        model_type: type,
        ...data,
      },
    })
  }

  public delete(id: number | null): Observable<[]> {
    return this.http.delete<[]>(`${environment.api}/history/${id}`)
  }

  public getDiff(id: number): Observable<DiffViewModel> {
    return this.http.get<DiffViewModel>(`${environment.api}/history/${id}/diff`)
  }

  // TODO: Model erstellen
  public getDiffHistory(
    id: number | string | null,
    type: 'patient' | 'qm' | 'household' | 'customer' | 'contact_person',
    data: { [key: string]: string }
  ): Observable<[]> {
    const modelId = id ? id.toString() : ''

    return this.http.get<[]>(`${environment.api}/history/diff-history`, {
      params: {
        model_id: modelId,
        model_type: type,
        ...data,
      },
    })
  }

  public store(
    data: HistoryListModel,
    todo: any = {}
  ): Observable<HistoryListModel> {
    return this.http.post<HistoryListModel>(`${environment.api}/history`, {
      ...data,
      todo,
    })
  }

  public update(data: HistoryListModel): Observable<HistoryListModel> {
    return this.http.put<HistoryListModel>(
      `${environment.api}/history/${data.id}`,
      { ...data }
    )
  }
}
