import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core'
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog'
import { EventBusService, GlobalEvent } from 'src/app/services/eventbus.service'
import { ToastService } from 'src/app/services/toast.service'
import { NgForm } from '@angular/forms'
import { Subscription } from 'rxjs'
import { PatientService } from '../../../services/patient.service'

@Component({
  selector: 'app-change-budget-dialog',
  templateUrl: './change-budget-dialog.component.html',
})
export class ChangeBudgetDialogComponent implements OnInit, OnDestroy {
  @ViewChild('form', { static: true }) form!: NgForm

  private formSubscription: Subscription | null | undefined = null
  private isDirty = false

  submitted = false
  submittedDelete = false

  public patientOptions: any[] = []
  public values = {
    budget_new: '',
    budget_before: '',
    comment: '',
    date: '',
    type: '',
  }

  constructor(
    private ref: DynamicDialogRef,
    private config: DynamicDialogConfig,
    private patientService: PatientService,
    private eventbus: EventBusService,
    private toastService: ToastService
  ) {}

  public ngOnInit(): void {
    this.values.budget_new = this.normalizeCurrency(
      this.config.data.budget.budget_start
    )
    this.values.date = this.config.data.date
    this.values.type = this.config.data.type
    this.values.budget_before = this.normalizeCurrency(
      this.config.data.budget.budget_start
    )

    this.formSubscription = this.form.valueChanges?.subscribe(() => {
      if (!this.form.pristine) {
        this.isDirty = true
      }
    })
  }

  ngOnDestroy(): void {
    this.formSubscription?.unsubscribe()
  }

  private normalizeCurrency(value: string): string {
    return value
      .replace('€', '')
      .replace('(', '')
      .replace(')', '')
      .replace('.', '')
      .replace(',', '.')
      .trim()
  }

  public save(): void {
    if (!this.form.form.valid) {
      this.submitted = false
      this.form.form.markAllAsTouched()
      return
    }

    this.submitted = true

    this.patientService
      .changeBudget(this.config.data.patient_id, this.values)
      .subscribe(
        () => {
          this.submitted = false
          this.eventbus.emit(GlobalEvent.BudgetChanged)
          this.ref.close()
          this.toastService.success(
            'Budget geändert',
            'Das Budget wurden erfolgreich geändert'
          )
        },
        () => {
          this.toastService.error(
            'Etwas ist schiefgelaufen...',
            'Bitte wenden Sie sich an den Support'
          )

          this.submitted = false
        }
      )
  }
}
