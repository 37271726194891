import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core'
import { DialogService, DynamicDialogConfig } from 'primeng/dynamicdialog'
import { NgForm } from '@angular/forms'
import { ProofsService } from '../../../services/proofs.service'
import { SelectPatientForProofDialogComponent } from '../select-patient-for-proof-dialog/select-patient-for-proof-dialog.component'
import {
  EventBusService,
  GlobalEvent,
} from '../../../services/eventbus.service'

@Component({
  selector: 'app-upload-proofs-history-dialog',
  templateUrl: './upload-proofs-history-dialog.component.html',
})
export class UploadProofsHistoryDialogComponent implements OnInit {
  @ViewChild('form', { static: true }) form!: NgForm
  @ViewChild('dt') table: ElementRef | any = null

  submitted = false
  generated = false
  public globalFilterValue = ''

  public data: any = {}

  constructor(
    private dialogService: DialogService,
    public proofService: ProofsService,
    private eventbus: EventBusService,
    public config: DynamicDialogConfig
  ) {}

  public ngOnInit(): void {
    this.data = this.config.data
  }

  public openSelectPatientForProofDialog(item: any): void {
    const ref = this.dialogService.open(SelectPatientForProofDialogComponent, {
      header: 'Scan bearbeiten',
      width: '360px',
      styleClass: 'dialog-container',
      data: {
        item,
        cover_key: this.data.cover_key,
        year: this.data.year,
        month: this.data.month,
      },
    })

    ref.onClose.subscribe((response: any) => {
      if (response) {
        item.edited_at = response.edited_at
        item.month = response.month
        item.year = response.year
        item.budget_type = response.budget_type
        item.patient_name = response.patient_name

        this.eventbus.emit(GlobalEvent.ProofsChanged)
      }
    })
  }

  public filterTableGlobal(event: any): void {
    this.table && this.table.filterGlobal(event.target.value.trim(), 'contains')
  }
}
