import { Injectable } from '@angular/core'
import { HttpClient, HttpParams } from '@angular/common/http'
import { environment } from '../../environments/environment'
import { Observable } from 'rxjs'

@Injectable({
  providedIn: 'root',
})
export class SendAppointmentsService {
  constructor(private http: HttpClient) {}

  public send(values: any): Observable<[]> {
    return this.http.post<[]>(
      `${environment.api}/send-appointments/${values.patient_id}`,
      values
    )
  }

  public getAppointmentsMailPreviewLink(values: any): string {
    const params = new HttpParams({ fromObject: values }).toString()

    return `${environment.api}/send-appointments/preview/mail/${values.patient_id}?${params}`
  }

  public getMailLink(id: string): string {
    return `${environment.api}/send-appointments/appointments-mail/${id}`
  }
}
