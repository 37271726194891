import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, ParamMap } from '@angular/router'
import { DialogService } from 'primeng/dynamicdialog'
import { DiffViewDialogComponent } from '../../../../../components/dialogs/diff-view-dialog/diff-view-dialog.component'
import { HistoryListModel } from '../../../../../models/history/history-list.model'
import { EventBusService } from '../../../../../services/eventbus.service'
import { RolePermissionService } from '../../../../../services/role-permission.service'

@Component({
  selector: 'app-roles-detail-history',
  templateUrl: './roles-detail-history.component.html',
  providers: [DialogService],
})
export class RolesDetailHistoryComponent implements OnInit {
  public loading = true
  public histories: HistoryListModel[] = []
  public roleId: string | null = null
  public onlyImportant = false

  constructor(
    private rolePermissionService: RolePermissionService,
    private route: ActivatedRoute,
    private eventbus: EventBusService,
    private dialogService: DialogService
  ) {}

  ngOnInit(): void {
    this.loadHistory()
  }

  public openDiffView(id: number): void {
    this.dialogService.open(DiffViewDialogComponent, {
      data: {
        id,
      },
      header: 'Änderungen ansehen',
      styleClass: 'dialog-diff-view',
      dismissableMask: true,
    })
  }

  public loadHistory(withLoading: boolean = true): void {
    if (withLoading) {
      this.loading = true
    }

    if (this.route.parent) {
      this.route.parent.paramMap.subscribe((params: ParamMap) => {
        this.roleId = params.get('id')

        this.rolePermissionService
          .history(this.roleId)
          .subscribe((data: HistoryListModel[]) => {
            this.histories = data

            if (withLoading) {
              this.loading = false
            }
          })
      })
    }
  }
}
