import { Component, ElementRef, OnInit, ViewChild } from '@angular/core'
import { DocumentService } from '../../../services/document.service'
import {
  DialogService,
  DynamicDialogConfig,
  DynamicDialogRef,
} from 'primeng/dynamicdialog'
import { PatientService } from '../../../services/patient.service'
import { NgForm } from '@angular/forms'
import { GlobalAnalysesService } from '../../../services/global-analyses.service'

@Component({
  selector: 'app-average-budget-hours-patient-dialog',
  templateUrl: './average-budget-hours-patient-dialog.component.html',
})
export class AverageBudgetHoursPatientDialogComponent implements OnInit {
  @ViewChild('form', { static: true }) form!: NgForm
  @ViewChild('dt') table: ElementRef | any = null

  public loading = true
  public globalFilterValue = ''

  // TODO: Model erstellen
  public data = {
    month: '',
    year: '',
    patients: [],
  }

  constructor(
    public globalAnalysesService: GlobalAnalysesService,
    private ref: DynamicDialogRef,
    private patientService: PatientService,
    private config: DynamicDialogConfig
  ) {}

  public filterTableGlobal(event: any): void {
    this.table && this.table.filterGlobal(event.target.value.trim(), 'contains')
  }

  public ngOnInit(): void {
    this.globalAnalysesService
      .loadAverageHourPerPatient(
        this.config.data.monthNumber,
        this.config.data.year
      )
      .subscribe((data: any) => {
        this.data = data

        this.loading = false
      })
  }
}
