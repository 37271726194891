import { Component, OnInit } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { EchoService } from './services/echo.service'
import { PrimeNGConfig } from 'primeng/api'
import { AuthService } from './services/auth.service'
import { HelperService } from './services/helper.service'
import { environment } from '../environments/environment'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
  public loading = true

  public isProd = true
  public isLocal = false
  public isStaging = false
  public isDemo = false
  public isLoggedIn: boolean | null = null

  constructor(
    private http: HttpClient,
    private helperService: HelperService,
    private echoService: EchoService,
    private primeConfig: PrimeNGConfig,
    private authService: AuthService
  ) {}

  public ngOnInit(): void {
    this.isProd = environment.env === 'production'
    this.isStaging = environment.env === 'staging'
    this.isDemo = environment.env === 'demo'
    this.isLocal = environment.env === 'local'

    document.body.classList.add('login-view')

    // Damit verhindern wir, dass der Drag Drop bei der BK-Planung
    // aus Versehen woanders rein gedropped werden kann.
    // Funktioniert aber irgendwie nicht immer korrekt...
    document.body.addEventListener('drop', (event: any) => {
      // Beim Hineinziehen von Dateien müssen wir das Verhalten erlauben.
      if (event.target.type !== 'file') {
        event.preventDefault()
      }
    })

    // Nav und Header werden so lange ausgeblendet, bis wir eingeloggt sind.
    this.authService.isLoggedIn$.subscribe((isLoggedIn: null | boolean) => {
      this.isLoggedIn = isLoggedIn

      if (this.isLoggedIn) {
        // @ts-ignore
        if (typeof Notification !== 'undefined') {
          // @ts-ignore
          window.webNotification.requestPermission(function onRequest() {})
        }

        this.echoService.init()
        this.toggleAppRootClass()
        // this.helperService.init()
      }
    })

    // TODO: in eigenen service oder config service oder sowas
    this.primeConfig.setTranslation({
      dayNames: [
        'Sonntag',
        'Montag',
        'Dienstag',
        'Mittwoch',
        'Donnerstag',
        'Freitag',
        'Samstag',
      ],
      dayNamesMin: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],
      monthNames: [
        'Januar',
        'Februar',
        'März',
        'April',
        'Mai',
        'Juni',
        'Juli',
        'August',
        'September',
        'Oktober',
        'November',
        'Dezember',
      ],
      monthNamesShort: [
        'Jan',
        'Feb',
        'März',
        'Apr',
        'Mai',
        'Jun',
        'Jul',
        'Aug',
        'Sept',
        'Okt',
        'Nov',
        'Dez',
      ],
      today: 'Heute',
      weekHeader: 'KW',
      accept: 'Ja',
      reject: 'Nein',
      cancel: 'Abbrechen',
      dateIs: 'Datum ist',
      dateIsNot: 'Datum ist nicht',
      dateBefore: 'Datum ist vorher',
      dateAfter: 'Datum ist danach',
      noFilter: 'Kein Filter',
    })
  }

  /**
   * Die Ansicht bei der Betreuungskraft oder Multiplikator soll etwas anders sein.
   */
  private toggleAppRootClass(): void {
    const user = this.authService.getUser()

    document.body.classList.remove('login-view')

    if (user?.is_caregiver || user?.is_multiplier) {
      document.body.classList.add('secondary-view')
    } else {
      document.body.classList.remove('secondary-view')
    }

    if (!this.isProd) {
      document.body.classList.add('local-system')
    }
  }
}
