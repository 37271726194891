import { MultiplierFormModel } from '../multiplier/multiplier-form.model'

export class SearchResultModel {
  id: number | null = null
  uuid? = ''
  zipcode = ''
  city = ''
  city_part? = ''
  full_name = ''
  insurer_number? = ''
  care_insurance?: MultiplierFormModel = new MultiplierFormModel()
  type = ''
  status? = ''
  status_string? = ''
  is_paused? = false
}
