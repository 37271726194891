import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { environment } from 'src/environments/environment'
import { Observable } from 'rxjs'
import { SearchResultModel } from '../models/search/search-result.model'
import { MultiplierFormModel } from '../models/multiplier/multiplier-form.model'
import { ZipcodeSearchResultModel } from '../models/search/zipcode-search-result.model'

@Injectable({
  providedIn: 'root',
})
export class SearchService {
  constructor(private http: HttpClient) {}

  public findCity(zipcode: string): Observable<ZipcodeSearchResultModel> {
    return this.http.get<ZipcodeSearchResultModel>(
      `${environment.api}/city/${zipcode}`
    )
  }

  public search(query: string): Observable<SearchResultModel[]> {
    return this.http.get<SearchResultModel[]>(`${environment.api}/search`, {
      params: {
        q: encodeURIComponent(query),
      },
    })
  }

  public findCareInsurance(nr: string): Observable<MultiplierFormModel[]> {
    return this.http.get<MultiplierFormModel[]>(
      `${environment.api}/care-insurance/${nr}`
    )
  }

  public findCostUnit(query: string): Observable<MultiplierFormModel[]> {
    return this.http.get<MultiplierFormModel[]>(
      `${environment.api}/search/cost-unit`,
      {
        params: {
          q: encodeURIComponent(query),
        },
      }
    )
  }

  public findCaregivers(query: string): Observable<SearchResultModel[]> {
    return this.http.get<SearchResultModel[]>(
      `${environment.api}/search/caregivers`,
      {
        params: {
          q: encodeURIComponent(query),
        },
      }
    )
  }

  public findCars(query: string): Observable<SearchResultModel[]> {
    return this.http.get<SearchResultModel[]>(
      `${environment.api}/search/cars`,
      {
        params: {
          q: encodeURIComponent(query),
        },
      }
    )
  }

  public findMultipliers(query: string): Observable<SearchResultModel[]> {
    return this.http.get<SearchResultModel[]>(
      `${environment.api}/search/multipliers`,
      {
        params: {
          q: encodeURIComponent(query),
        },
      }
    )
  }

  public findCaregiversOnlyActive(
    query: string
  ): Observable<SearchResultModel[]> {
    return this.http.get<SearchResultModel[]>(
      `${environment.api}/search/caregivers-active`,
      {
        params: {
          q: encodeURIComponent(query),
        },
      }
    )
  }

  public findCaregiversOnlyActiveForPatient(
    patientId: any,
    query: string
  ): Observable<SearchResultModel[]> {
    return this.http.get<SearchResultModel[]>(
      `${environment.api}/search/caregivers-active/${patientId}`,
      {
        params: {
          q: encodeURIComponent(query),
        },
      }
    )
  }

  public findPatients(query: string): Observable<SearchResultModel[]> {
    return this.http.get<SearchResultModel[]>(
      `${environment.api}/search/patients`,
      {
        params: {
          q: encodeURIComponent(query),
        },
      }
    )
  }
}
