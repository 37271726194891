<form #form="ngForm" class="dialog-form">
  <div class="dialog-form-body">
    <div class="p-field">
      <label for="notice">Notiz</label>
      <textarea
        id="notice"
        name="notice"
        [rows]="15"
        [(ngModel)]="notice"
        pInputTextarea
      ></textarea>
    </div>
  </div>
  <div class="dialog-form-footer">
    <i *ngIf="!notice"></i>
    <i
      class="pi pi-trash"
      *ngIf="notice"
      [class.pi-trash]="!submittedDelete"
      [class.pi-spin]="submittedDelete"
      [class.pi-spinner]="submittedDelete"
      tooltipPosition="left"
      tooltipZIndex="20000"
      [pTooltip]="submittedDelete ? 'Wird gelöscht' : 'Notiz löschen'"
      (click)="remove($event)"
    ></i>
    <button
      pButton
      label="Speichern"
      type="submit"
      (click)="save()"
      [disabled]="submitted"
      [icon]="submitted ? 'pi pi-spin pi-spinner' : 'pi pi-check'"
    ></button>
  </div>
</form>
<p-confirmPopup></p-confirmPopup>
