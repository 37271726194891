import { AuthGuard } from '../guards/auth.guard'
import { RolesFormComponent } from '../views/administration/roles/form/roles-form.component'
import { DirtyCheckGuard } from '../guards/dirty-check.guard'
import { RolesDetailComponent } from '../views/administration/roles/detail/roles-detail.component'
import { RolesDetailHistoryComponent } from '../views/administration/roles/detail/history/roles-detail-history.component'
import { RolesDetailGeneralComponent } from '../views/administration/roles/detail/general/roles-detail-general.component'

export const rolesRoutes = [
  {
    path: 'roles',
    canActivate: [AuthGuard],
    children: [
      {
        path: 'new',
        component: RolesFormComponent,
        canDeactivate: [DirtyCheckGuard],
      },
      {
        path: ':id',
        component: RolesDetailComponent,
        children: [
          {
            path: '',
            component: RolesDetailGeneralComponent,
          },
          {
            path: 'history',
            component: RolesDetailHistoryComponent,
          },
        ],
      },
      {
        path: ':id/edit',
        component: RolesFormComponent,
        canDeactivate: [DirtyCheckGuard],
      },
    ],
  },
]
