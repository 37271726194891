import { PatientTimeDaysModel } from './patient-time-days.model'
import { PatientDetailModel } from './patient-detail.model'

export class PatientTimeModel {
  id = 0
  comment = ''
  patient_id = 0
  since: Date | null = null
  until: Date | null = null
  deleted_at: Date | null = null
  created_at: Date | null = null
  updated_at: Date | null = null
  days_as_html_table = ''
  days_as_string = ''
  only_days_as_string = ''

  patient: PatientDetailModel = new PatientDetailModel()
  days: PatientTimeDaysModel[] = []
  days_with_key: PatientTimeDaysWithKeyModel = new PatientTimeDaysWithKeyModel()
}

export class PatientTimeDaysWithKeyModel {
  monday: PatientTimeDaysModel = new PatientTimeDaysModel()
  tuesday: PatientTimeDaysModel = new PatientTimeDaysModel()
  wednesday: PatientTimeDaysModel = new PatientTimeDaysModel()
  thursday: PatientTimeDaysModel = new PatientTimeDaysModel()
  friday: PatientTimeDaysModel = new PatientTimeDaysModel()
  saturday: PatientTimeDaysModel = new PatientTimeDaysModel()
}
