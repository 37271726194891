import { AuthGuard } from '../guards/auth.guard'
import { PlansComponent } from '../views/plans/plans.component'
import { PlansApprovalsComponent } from '../views/plans/approvals/plans-approvals.component'
import { PlansFeedbackComponent } from '../views/plans/feedback/plans-feedback.component'
import { PlansFeedbackCreatedComponent } from '../views/plans/feedback/created/plans-feedback-created.component'
import { PlansFeedbackEditedComponent } from '../views/plans/feedback/edited/plans-feedback-edited.component'
import { PlansFeedbackFinishedComponent } from '../views/plans/feedback/finished/plans-feedback-finished.component'

export const plansRoutes = [
  {
    path: 'plans',
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: PlansComponent,
        children: [
          {
            path: '',
            redirectTo: 'feedback',
            pathMatch: 'full',
          },
          {
            path: 'feedback',
            component: PlansFeedbackComponent,
            children: [
              {
                path: '',
                component: PlansFeedbackCreatedComponent,
              },
              {
                path: 'edited',
                component: PlansFeedbackEditedComponent,
              },
              {
                path: 'finished',
                component: PlansFeedbackFinishedComponent,
              },
            ],
          },
          {
            path: 'approvals',
            component: PlansApprovalsComponent,
          },
        ],
      },
    ],
  },
]
