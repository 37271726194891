import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs'
import { environment } from '../../environments/environment'
import { AccountingReturnModel } from '../models/accounting/accounting-return.model'
import { AccountingReturnHistoryModel } from '../models/accounting/accounting-return-history.model'

@Injectable({
  providedIn: 'root',
})
export class AccountingReturnService {
  constructor(private http: HttpClient) {}

  public uploadReturn(patientId: number, form: FormData): Observable<any> {
    return this.http.post<any>(
      `${environment.api}/accounting/return/${patientId}/upload`,
      form
    )
  }

  public storeReturn(
    patientId: number,
    form: FormData
  ): Observable<AccountingReturnModel> {
    return this.http.post<AccountingReturnModel>(
      `${environment.api}/accounting/return/${patientId}`,
      form
    )
  }

  public updateReturn(
    accountingReturnId: string,
    form: FormData
  ): Observable<AccountingReturnModel> {
    return this.http.post<AccountingReturnModel>(
      `${environment.api}/accounting/return/${accountingReturnId}/update`,
      form
    )
  }

  public loadUserHistories(
    accountingReturnId: string
  ): Observable<AccountingReturnHistoryModel[]> {
    return this.http.get<AccountingReturnHistoryModel[]>(
      `${environment.api}/accounting/return/${accountingReturnId}/user-histories`
    )
  }

  public search(query: string): Observable<AccountingReturnModel[]> {
    return this.http.get<AccountingReturnModel[]>(
      `${environment.api}/accounting/return/search?query=${query}`
    )
  }

  public loadCreated(): Observable<AccountingReturnModel[]> {
    return this.http.get<AccountingReturnModel[]>(
      `${environment.api}/accounting/return/created`
    )
  }

  public loadEdited(): Observable<AccountingReturnModel[]> {
    return this.http.get<AccountingReturnModel[]>(
      `${environment.api}/accounting/return/edited`
    )
  }

  public loadFinished(): Observable<AccountingReturnModel[]> {
    return this.http.get<AccountingReturnModel[]>(
      `${environment.api}/accounting/return/finished`
    )
  }

  public loadForPatient(patientId: any): Observable<AccountingReturnModel[]> {
    return this.http.get<AccountingReturnModel[]>(
      `${environment.api}/accounting/return/patient/${patientId}`
    )
  }

  public loadForPatientNotFinished(
    patientId: any
  ): Observable<AccountingReturnModel[]> {
    return this.http.get<AccountingReturnModel[]>(
      `${environment.api}/accounting/return/patient/${patientId}/not-finished`
    )
  }

  // TODO: Model erstellen
  public loadKmForPatient(patientId: string): Observable<any[]> {
    return this.http.get<any[]>(
      `${environment.api}/accounting/invoices/km/${patientId}`
    )
  }

  // TODO: Model erstellen
  public loadConsultingsForPatient(patientId: string): Observable<any[]> {
    return this.http.get<any[]>(
      `${environment.api}/accounting/invoices/consulting/${patientId}`
    )
  }

  // TODO: Model erstellen
  public loadAppointmentsForPatient(patientId: string): Observable<any[]> {
    return this.http.get<any[]>(
      `${environment.api}/accounting/invoices/appointments/${patientId}`
    )
  }

  public remove(accountingReturnId: number | string): Observable<[]> {
    return this.http.delete<[]>(
      `${environment.api}/accounting/return/${accountingReturnId}`
    )
  }

  public changeReturnStatus(
    accountingReturnId: number | string,
    status: string
  ): Observable<[]> {
    return this.http.put<[]>(
      `${environment.api}/accounting/return/${accountingReturnId}/status`,
      {
        status,
      }
    )
  }

  public storeHistoryComment(
    accountingReturnId: number | string,
    result: string,
    comment: string
  ): Observable<[]> {
    return this.http.post<[]>(
      `${environment.api}/accounting/return/${accountingReturnId}/history`,
      {
        result,
        comment,
      }
    )
  }

  public updateHistoryComment(
    accountingReturnHistoryId: number | string,
    result: string,
    comment: string
  ): Observable<[]> {
    return this.http.put<[]>(
      `${environment.api}/accounting/return/${accountingReturnHistoryId}/history`,
      {
        result,
        comment,
      }
    )
  }
}
