import { CarsDetailModel } from './cars-detail.model'
import { CaregiverDetailModel } from '../caregiver/caregiver-detail.model'
import { UserModel } from '../user/user.model'
import { CarDamageCommentModel } from './car-damage-comment.model'
import { MediaModel } from '../document/media.model'

export class CarDamageResponseModel {
  documents: MediaModel[] = []
  car_damage: CarDamageModel = new CarDamageModel()
}

export class CarDamageModel {
  id: number | null = null
  car_id: number | null = null
  tracked_by_id: number | undefined | null = null
  caregiver_id: number | null = null
  mileage = null
  comment = ''
  guilt = ''
  guilt_text = ''
  created_at: Date | null = null
  updated_at: Date | null = null
  deleted_at: Date | null = null
  date: Date | string = ''
  comments_count = 0
  status: null | string = null

  tracked_by: UserModel = new UserModel()
  car: CarsDetailModel = new CarsDetailModel()
  caregiver: CaregiverDetailModel = new CaregiverDetailModel()
  comments: CarDamageCommentModel[] = []
}
