import { Component, OnDestroy, OnInit } from '@angular/core'
import { ActivatedRoute, ParamMap } from '@angular/router'
import { TitleService } from 'src/app/services/title.service'
import { UserSystemDetailModel } from '../../../../models/user/user-system-detail.model'
import { UserSystemService } from '../../../../services/user-system.service'
import { NoticeDialogComponent } from '../../../../components/dialogs/notice-dialog/notice-dialog.component'
import { DialogService } from 'primeng/dynamicdialog'
import {
  EventBusService,
  GlobalEvent,
} from '../../../../services/eventbus.service'
import { Subscription } from 'rxjs'
import { ToastService } from '../../../../services/toast.service'
import { AuthService } from '../../../../services/auth.service'

@Component({
  selector: 'app-users-detail',
  templateUrl: './users-detail.component.html',
  styleUrls: ['./users-detail.component.scss'],
})
export class UsersDetailComponent implements OnInit, OnDestroy {
  public userSystemDetail: UserSystemDetailModel = new UserSystemDetailModel()
  private eventBusSubscription: Subscription = new Subscription()

  public hasPermission = true

  constructor(
    private route: ActivatedRoute,
    private dialogService: DialogService,
    private eventbus: EventBusService,
    private userSystemService: UserSystemService,
    private titleService: TitleService,
    private toastService: ToastService,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    this.hasPermission = this.authService.can(
      'Verwaltung.Systembenutzer verwalten'
    )

    if (this.hasPermission) {
      this.loadUserDetails()
      this.listenForEventbus()
    }
  }

  ngOnDestroy(): void {
    this.eventBusSubscription.unsubscribe()
  }

  public loadUserDetails(): void {
    this.route.paramMap.subscribe((params: ParamMap) => {
      this.userSystemService
        .details(params.get('id'))
        .subscribe((data: UserSystemDetailModel) => {
          this.userSystemDetail = data
          this.titleService.setTitle(
            `${data.form_of_address} ${data.first_name} ${data.last_name}`
          )
        })
    })
  }

  private listenForEventbus(): void {
    this.eventBusSubscription = this.eventbus.subject.subscribe((event) => {
      switch (event) {
        case GlobalEvent.NoticeChanged:
          this.loadUserDetails()
          break
      }
    })
  }

  public openNoticeDialog(): void {
    this.dialogService.open(NoticeDialogComponent, {
      header: 'Notiz bearbeiten',
      width: '450px',
      styleClass: 'dialog-container',
      data: {
        type: 'user_system',
        type_id: this.userSystemDetail.id,
        notice: this.userSystemDetail.notice,
      },
    })
  }
}
