import { Component, OnInit } from '@angular/core'
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog'
import { ToastService } from 'src/app/services/toast.service'
import { HelperService } from '../../../services/helper.service'
import { CaregiverHospitationFeedbackModel } from '../../../models/caregiver/caregiver-hospitation-feedback.model'
import { UserCaregiverService } from '../../../services/user-caregiver.service'
import { CaregiverService } from '../../../services/caregivers.service'

@Component({
  selector: 'app-hospitation-feedback-dialog',
  templateUrl: './hospitation-feedback-dialog.component.html',
})
export class HospitationFeedbackDialogComponent implements OnInit {
  submitted = false
  submittedDelete = false

  public familyStatus = []

  public isFrozen = false
  public isEdit = false
  public data: any
  public values = new CaregiverHospitationFeedbackModel()

  constructor(
    private ref: DynamicDialogRef,
    private caregiverService: CaregiverService,
    private userCaregiverService: UserCaregiverService,
    private config: DynamicDialogConfig,
    private toastService: ToastService,
    private helperService: HelperService
  ) {}

  public ngOnInit(): void {
    this.data = this.config.data
    this.isFrozen = this.data.is_frozen

    this.helperService.dependencies$.subscribe((data: any) => {
      this.familyStatus = data['family_status']
    })

    if (this.data.current_status.caregiver_hospitation_feedback) {
      this.isEdit = true
      this.values = this.data.current_status.caregiver_hospitation_feedback

      // Wenn ein Systembenutzer das Feedback öffnet, soll dieser als gelesen markiert werden.
      if (this.isFrozen) {
        this.caregiverService
          .readHospitationFeedback(
            this.data.current_status.caregiver_hospitation_feedback.id
          )
          .subscribe()
      }
    }
  }

  /**
   * Speichert eine Notiz.
   */
  public save(): void {
    this.submitted = true

    const subscription = this.isEdit
      ? this.userCaregiverService.updateHospitationFeedback(this.values)
      : this.userCaregiverService.storeHospitationFeedback(
          this.data.current_status.id,
          this.values
        )

    subscription.subscribe(
      () => {
        this.submitted = false
        this.ref.close(true)
        this.toastService.success('Feedback gespeichert')
      },
      () => {
        this.toastService.error(
          'Etwas ist schiefgelaufen...',
          'Bitte wenden Sie sich an den Support'
        )

        this.submitted = false
      }
    )
  }
}
