<form #form="ngForm" class="dialog-form">
  <div class="dialog-form-body">
    <div class="content-no-message simple-text">
      Erstellen von 10 neuen Dokumenten
    </div>
  </div>
  <div class="dialog-form-footer">
    <i></i>

    <a *ngIf="generated" target="_blank" [href]="documentLink">
      <button
        pButton
        label="Dokument herunterladen"
        type="button"
        icon="pi pi-download"
        iconPos="left"
        class="p-button p-button-info"
      ></button>
    </a>

    <button
      pButton
      *ngIf="!generated"
      label="Erstellen"
      type="submit"
      (click)="save()"
      [disabled]="submitted"
      [icon]="submitted ? 'pi pi-spin pi-spinner' : 'pi pi-check'"
    ></button>
  </div>
</form>
