import { Injectable } from '@angular/core'
import { HttpClient, HttpParams } from '@angular/common/http'
import { Observable } from 'rxjs'
import { environment } from '../../environments/environment'
import { AccountingReturnModel } from '../models/accounting/accounting-return.model'
import { AccountingReturnHistoryModel } from '../models/accounting/accounting-return-history.model'

@Injectable({
  providedIn: 'root',
})
export class ProofsService {
  constructor(private http: HttpClient) {}

  public load(
    month: string,
    year: string,
    selectedPlanner: number,
    unreleased: boolean,
    noProofs: boolean,
    currentCaregiverId: number,
    selectedCities: any,
    reworking: boolean,
    multipleProofs: boolean,
    onlyGv: boolean,
    onlyInvoiceDraft: boolean
  ): Observable<any> {
    return this.http.get<any>(`${environment.api}/proofs`, {
      params: {
        month,
        year,
        selectedPlanner,
        currentCaregiverId,
        unreleased,
        noProofs,
        onlyGv,
        onlyInvoiceDraft,
        multipleProofs,
        reworking,
        selectedCities: JSON.stringify(selectedCities),
      },
    })
  }

  public getPreviewLink(
    type: string,
    proofStatusId: any,
    values: any,
    otherValues: any
  ): string {
    const params = new HttpParams({ fromObject: values }).toString()
    const params2 = new HttpParams({ fromObject: otherValues }).toString()

    return `${environment.api}/proofs/${type}-preview/${proofStatusId}?${params}&${params2}`
  }

  public loadSpecific(proofStatusId: any): Observable<any> {
    return this.http.get<any>(
      `${environment.api}/proofs/specific/${proofStatusId}`
    )
  }

  public loadBudgetsForPatient(
    patientId: any,
    month: any,
    year: any
  ): Observable<any> {
    return this.http.get<any>(
      `${environment.api}/proofs/patient-budgets?patient_id=${patientId}&month=${month}&year=${year}`
    )
  }

  public upload(form: FormData): Observable<any> {
    return this.http.post<any>(`${environment.api}/proofs/upload`, form)
  }

  public initProofStatus(values: any): Observable<any> {
    return this.http.post<any>(`${environment.api}/proofs/init-proof-status`, {
      ...values,
    })
  }

  public replaceUpload(form: FormData): Observable<any> {
    return this.http.post<any>(`${environment.api}/proofs/replace-upload`, form)
  }

  public loadHistory(): Observable<[]> {
    return this.http.get<[]>(`${environment.api}/proofs/history`)
  }

  public processUpload(proofUploaded: any): Observable<any> {
    return this.http.get<any>(
      `${environment.api}/proofs/start-process/${proofUploaded.id}`
    )
  }

  public loadProofUploaded(proofUploadedId: number): Observable<any> {
    return this.http.get<any>(
      `${environment.api}/proofs/proof-uploaded/${proofUploadedId}`
    )
  }

  public getCoverDownloadLink(ids: []): string {
    return `${environment.api}/proofs/covers?ids=${JSON.stringify(ids)}`
  }

  public getImageDownloadLink(name: string): string {
    return `${environment.api}/proofs/image?name=${name}`
  }

  public saveCheckedStatus(
    data: any,
    dataForCheckStatusIsValid: boolean
  ): Observable<any> {
    return this.http.put<[]>(`${environment.api}/proofs/save-checked-status`, {
      ...data,
      dataForCheckStatusIsValid,
    })
  }

  public saveBudgetStatus(
    data: any,
    dataForBudgetStatusIsValid: boolean
  ): Observable<any> {
    return this.http.put<[]>(`${environment.api}/proofs/save-budget-status`, {
      ...data,
      dataForBudgetStatusIsValid,
    })
  }

  public saveInvoiceStatus(data: any, invoiceValues: any): Observable<any> {
    return this.http.put<[]>(`${environment.api}/proofs/save-invoice-status`, {
      ...data,
      invoiceValues,
    })
  }

  public changeUploadedItemBudget(id: any, budget: any): Observable<any> {
    return this.http.put<[]>(
      `${environment.api}/proofs/change-uploaded-item-budget/${id}`,
      {
        budget,
      }
    )
  }

  public changeIssueUploadedItem(
    id: any,
    patientId: any,
    budget: any,
    month: any,
    year: any,
    cover_key: any
  ): Observable<any> {
    return this.http.put<[]>(
      `${environment.api}/proofs/change-issue-uploaded-item/${id}`,
      {
        patientId,
        budget,
        month,
        year,
        cover_key,
      }
    )
  }

  public finishProofRework(data: any): Observable<any> {
    return this.http.put<[]>(
      `${environment.api}/proofs/rework-finish/${data.id}`,
      {
        ...data,
      }
    )
  }

  public createCovers(month: string, year: string): Observable<any> {
    return this.http.post<[]>(`${environment.api}/proofs/covers`, {
      year,
      month,
    })
  }

  public addProofRework(
    proofStatusId: number,
    type: any,
    comment: string
  ): Observable<any> {
    return this.http.post<[]>(
      `${environment.api}/proofs/rework/${proofStatusId}`,
      {
        type,
        comment,
      }
    )
  }

  public saveUpload(data: any): Observable<any> {
    return this.http.post<[]>(`${environment.api}/proofs/save-upload`, {
      data,
    })
  }
}
