import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core'
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog'
import { EventBusService, GlobalEvent } from 'src/app/services/eventbus.service'
import { ToastService } from 'src/app/services/toast.service'
import { NgForm } from '@angular/forms'
import { ProofsService } from '../../../services/proofs.service'
import { HttpErrorResponse } from '@angular/common/http'
import { StatusCodes } from 'http-status-codes'

@Component({
  selector: 'app-upload-single-proof-dialog',
  templateUrl: './upload-single-proof-dialog.component.html',
})
export class UploadSingleProofDialogComponent implements OnInit, OnDestroy {
  @ViewChild('form', { static: true }) form!: NgForm

  submitted = false
  generated = false
  documentLink = ''

  public data: any = {}

  public hasMedia = false

  public proofUploaded: any = {}

  private processInterval: any = null

  public processedPages = 0
  public processedPagesInPercent = 0
  public isParsed = false
  public isParsing = false
  public isUploading = false
  public isProcessing = false

  public coverKey = ''

  constructor(
    private ref: DynamicDialogRef,
    public proofService: ProofsService,
    public config: DynamicDialogConfig,
    private eventbus: EventBusService,
    private toastService: ToastService
  ) {}

  public ngOnInit(): void {
    this.hasMedia = this.config.data.proofs.length > 0
  }

  public ngOnDestroy(): void {}

  public setSelectedFile(event: any): any {
    this.isParsed = false
    this.isUploading = true
    this.isProcessing = false
    this.isParsing = true

    this.processedPages = 0
    this.processedPagesInPercent = 0

    const formData = new FormData()
    formData.append('file', event.target.files[0])

    let mediaId = '0'

    if (this.config.data.proofs.length) {
      mediaId = this.config.data.proofs[0].id
    }

    formData.append('media_id', mediaId)
    formData.append('proof_status_id', this.config.data.proof_status.id)
    formData.append('cover_key', this.coverKey)
    formData.append('patient_id', this.config.data.patient_id)
    formData.append('month', this.config.data.month)
    formData.append('year', this.config.data.year)
    formData.append('budget', this.config.data.proof_status.budget_type)

    this.proofService.replaceUpload(formData).subscribe(
      (response: any) => {
        if (response.has_issue) {
          alert(
            'Achtung: Das Dokument wurde erfolgreich hochgeladen. Aber der QR-Code konnte nicht gelesen werden. Bitte überprüfen Sie den Leistungsnachweis nach korrekten Daten.'
          )
        }

        this.ref.close(response)

        this.eventbus.emit(GlobalEvent.ProofsChanged)

        this.toastService.success('LNW wurde ersetzt')
      },
      (error: HttpErrorResponse) => {
        if (error.status === StatusCodes.UNPROCESSABLE_ENTITY) {
          this.toastService.error('Das Dokument muss eine PDF sein')
        } else if (error.status === StatusCodes.CONFLICT) {
          this.toastService.error('Daten aus dem QR-Code stimmen nicht überein')
        } else if (error.status === StatusCodes.NOT_FOUND) {
          this.toastService.error('Das Kennzeichen existiert nicht')
        } else {
          this.toastService.error(
            'Etwas ist schief gelaufen...',
            'Bitte wenden Sie sich an den Support'
          )
        }

        this.isProcessing = false
        this.isUploading = false
        this.isParsing = false
        this.submitted = false
      }
    )
  }
}
