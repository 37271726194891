<app-loader [loading]="loading"></app-loader>

<div class="content" *ngIf="!loading">
  <div class="content-body without-padding p-flex-row">
    <div class="detail-information-column border-right">
      <div class="detail-information-container">
        <div class="detail-information-header">
          <span><i class="pi pi-bars"></i> Daten</span>
        </div>

        <div class="detail-information-body">
          <div class="detail-information">
            <div class="detail-key">
              <span>Erfahrungen</span>
            </div>
            <div class="detail-value">
              <strong>{{ data.experiences_string || "-" }}</strong>
            </div>
          </div>
          <div class="detail-information">
            <div class="detail-key">
              <span>Grundausbildung als</span>
            </div>
            <div class="detail-value">
              <strong>{{ data.education_as || "-" }}</strong>
            </div>
          </div>
          <div class="detail-information">
            <div class="detail-key">
              <span>Erstschulung vollständig?</span>
            </div>
            <div class="detail-value">
              <strong
                >{{ data.initial_training }}
                {{
                  data.last_training_date_string
                    ? "(" + data.last_training_date_string + ")"
                    : ""
                }}</strong
              >
            </div>
          </div>
          <div class="detail-information">
            <div class="detail-key">
              <span>Erste Hilfe-Kurs?</span>
            </div>
            <div class="detail-value">
              <strong
                >{{ data.first_aid }}
                {{
                  data.first_aid_date_string
                    ? "(" + data.first_aid_date_string + ")"
                    : ""
                }}</strong
              >
            </div>
          </div>
          <div class="detail-information">
            <div class="detail-key">
              <span>Einarbeitung Administration?</span>
            </div>
            <div class="detail-value">
              <strong
                >{{ data.training_administration }}
                {{
                  data.training_administration_date_string
                    ? "(" +
                      data.training_administration_date_string +
                      " von " +
                      (data.training_administration_from || "??") +
                      ")"
                    : ""
                }}</strong
              >
            </div>
          </div>

          <div class="detail-information">
            <div class="detail-key">
              <span>Belehrung Infektionsschutzgesetz?</span>
            </div>
            <div class="detail-value">
              <strong
                >{{ data.instruction_infection_act || "-" }}
                {{
                  data.instruction_infection_act_date_string
                    ? "(" + data.instruction_infection_act_date_string + ")"
                    : ""
                }}</strong
              >
            </div>
          </div>

          <div class="detail-information">
            <div class="detail-key">
              <span>Erweitertes Führungszeugnis?</span>
            </div>
            <div class="detail-value">
              <strong
                >{{ data.extended_certificate_of_good_conduct || "-" }}
                {{
                  data.extended_certificate_of_good_conduct_date_string
                    ? "(" +
                      data.extended_certificate_of_good_conduct_date_string +
                      ")"
                    : ""
                }}
              </strong>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
