import { PhoneCallModel } from '../phone-call/phone-call.model'
import { CaregiverDetailModel } from '../caregiver/caregiver-detail.model'
import { UserSystemDetailModel } from '../user/user-system-detail.model'
import { CarsDetailModel } from '../car/cars-detail.model'
import { MultiplierDetailModel } from '../multiplier/multiplier-detail.model'
import { TodoModel } from '../todo/todo.model'
import { MediaModel } from '../document/media.model'

export class HistoryListModel {
  id: number | null = null
  type = ''
  type_text = ''
  phone_call_id: number | null = null
  todo_id: number | null = null
  historiable_id: number | null = null
  historiable_type = ''
  historiable?:
    | CaregiverDetailModel
    | CarsDetailModel
    | MultiplierDetailModel
    | UserSystemDetailModel = undefined
  phone_call?: PhoneCallModel = undefined
  todo?: TodoModel = undefined
  created_from_name = ''
  // user_system?: UserSystemModel = new UserSystemModel()
  manual_title?: string
  manual_description?: string
  manual_type?: string
  important = false
  available_for_recommendation = false
  appointment_transfer = ''
  appointment_transfer_date: Date | null = null
  occurred_at: Date = new Date()
  occurred_at_string = ''
  appointment_html_table = ''
  documents: MediaModel[] = []
}

export enum HistoryType {
  PHONE_CALL = 'phone_call',
  EMAIL = 'email',
  WHATSAPP = 'whatsapp',
  BIRTHDAY_WISHES = 'birthday_wishes',
  PERSONAL_TALK = 'personal_talk',
  PLANING = 'planing',
  APPOINTMENT_TRANSFER = 'appointment_transfer',
  MISC = 'misc',
  THC = 'thc',
  RETURNS = 'returns',
  QM1 = 'qm1',
  QM2 = 'qm2',
  INVOICE = 'invoice',
}
