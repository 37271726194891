import { UserSystemModel } from '../user/user-system.model'
import { SearchResultModel } from '../search/search-result.model'
import * as dayjs from 'dayjs'
import { UserModel } from '../user/user.model'
import { TodoCommentModel } from './todo-comment.model'
import {TodoCategoryModel} from "./todo-category.model";
import {MediaModel} from "../document/media.model";

export class TodoModel {
  id?: number = undefined
  name = ''
  title = ''
  comment = ''
  status = 'OPEN'
  status_string = ''
  priority = 2
  priority_string = ''
  assignment_type = 'GENERAL'
  type = 'MANUAL'
  for_when: Date | null = null
  for_when_type = 'FINISH_UNTIL'
  exceeded = false
  for_when_string = dayjs().format('DD.MM.YYYY')
  done_at: Date | null = null
  deleted_at: Date | null = null
  created_at: Date | null = null
  updated_at: Date | null = null
  done_from = ''
  category_id: number | null = null
  created_from = ''
  assigned_to_string = ''
  from_history_id: number | null = null
  comments_count = 0
  from_caregiver_plan_type: null | string = null
  from_caregiver_plan_id: null | number = null

  media: MediaModel[] = []
  last_comment: TodoCommentModel | null = null
  category: TodoCategoryModel | null = null
  user: UserModel | null = null
  done_from_user: UserModel | null = null
  caregiver: SearchResultModel | null = null
  car: SearchResultModel | null = null
  patient: SearchResultModel | null = null
  user_systems: UserSystemModel[] | any[] = []
  comments: TodoCommentModel[] = []
}
