<app-loader [loading]="loading"></app-loader>

<div class="content" *ngIf="!loading">
  <header class="content-header">
    <span>
      <i class="pi pi-file"></i>
      Alle Dokumente
    </span>

    <div class="content-header-buttons">
      <p-checkbox
        label="nur archivierte"
        [binary]="true"
        (onChange)="loadDocuments(false)"
        [(ngModel)]="onlyArchived"
        name="only_archived"
      ></p-checkbox>
      <button
        pButton
        label="Dokument erstellen"
        (click)="openAddDocumentDialog()"
        type="button"
        class="p-button-outlined"
        icon="pi pi-plus"
        iconPos="left"
      ></button>
    </div>
  </header>
</div>

<div class="content" style="margin: 0" *ngIf="!loading">
  <ng-container *ngFor="let type of documentTypes">
    <div class="detail-information-header">
      {{ type.name }}
    </div>
    <div class="content-body without-padding auto-height">
      <span
        class="content-no-message with-padding"
        *ngIf="type.documents.length === 0"
      >
        Keine Dokumente hinterlegt
      </span>

      <p-table
        [value]="type.documents"
        [rowHover]="true"
        *ngIf="type.documents.length > 0"
      >
        <ng-template pTemplate="header">
          <tr>
            <th>Name</th>
            <th style="width: 200px">Erstellt am</th>
            <th style="width: 200px">Erstellt von</th>
            <th style="width: 100px"></th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-document>
          <tr class="clickable">
            <td (click)="openDownloadTab(document)">{{ document.name }}</td>
            <td (click)="openDownloadTab(document)">
              {{ document.created_at | date: "dd.MM.y, H:mm" }} Uhr
            </td>
            <td (click)="openDownloadTab(document)">
              {{ document.custom_properties.created_from_name || "-" }}
            </td>
            <td class="table-action" style="width: 100px">
              <i
                (click)="openAddDocumentDialog(document, type.name)"
                class="pi pi-pencil with-margin-right"
                pTooltip="Bearbeiten"
              ></i>

              <a
                [href]="carService.getDocumentDownloadLink(document.uuid)"
                target="_blank"
              >
                <i pTooltip="Herunterladen" class="pi pi-download"></i>
              </a>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </ng-container>
</div>
