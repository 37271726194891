import { Component, OnInit } from '@angular/core'
import { TitleService } from 'src/app/services/title.service'
import { AuthService } from '../../services/auth.service'

@Component({
  selector: 'app-plans',
  templateUrl: './plans.component.html',
})
export class PlansComponent implements OnInit {
  constructor(
    private titleService: TitleService,
    public authService: AuthService
  ) {}

  ngOnInit(): void {
    this.titleService.setTitle(`Dienstpläne`)
  }
}
