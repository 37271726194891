import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core'
import { NgForm } from '@angular/forms'
import {
  DialogService,
  DynamicDialogConfig,
  DynamicDialogRef,
} from 'primeng/dynamicdialog'
import { Subscription } from 'rxjs'
import { EventBusService, GlobalEvent } from 'src/app/services/eventbus.service'
import { HistoryService } from 'src/app/services/history.service'
import { ToastService } from 'src/app/services/toast.service'
import { UserSystemService } from '../../../services/user-system.service'
import { DocumentService } from '../../../services/document.service'
import { AuthService } from '../../../services/auth.service'
import { HttpErrorResponse } from '@angular/common/http'
import { StatusCodes } from 'http-status-codes'

@Component({
  selector: 'account-multiplier-dialog',
  templateUrl: './account-multiplier-dialog.component.html',
})
export class AccountMultiplierDialogComponent implements OnInit, OnDestroy {
  @ViewChild('form', { static: true }) form!: NgForm

  private formSubscription: Subscription | null | undefined = null
  private isDirty = false

  public data: any = {}
  public submitted = false

  public values = {
    name: '',
    initial_password: '',
    email: '',
    is_active: true,
  }

  constructor(
    public documentService: DocumentService,
    private ref: DynamicDialogRef,
    private authService: AuthService,
    private config: DynamicDialogConfig,
    private userSystemService: UserSystemService,
    private historyService: HistoryService,
    private eventbus: EventBusService,
    private dialogService: DialogService,
    private toastService: ToastService
  ) {}

  ngOnInit(): void {
    this.data = this.config.data

    // Falls noch kein Benutzer erstellt ist, werden
    // einige Standardwerte vom Multiplikatoren benutzt.
    if (!this.data.multiplier.user) {
      this.values.email = this.data.multiplier.email || 'BITTE E-MAIL EINTRAGEN'
      this.values.name = this.data.multiplier.name || ''
    } else {
      this.values.email = this.data.multiplier.user.email
      this.values.name = this.data.multiplier.user.name
      this.values.is_active = !this.data.multiplier.user?.deleted_at
    }

    this.formSubscription = this.form.valueChanges?.subscribe(() => {
      if (!this.form.pristine) {
        this.isDirty = true
      }
    })
  }

  ngOnDestroy(): void {
    this.formSubscription?.unsubscribe()
  }

  public save(): void {
    if (!this.form.form.valid) {
      this.submitted = false
      this.form.form.markAllAsTouched()
      return
    }

    this.submitted = true

    this.authService
      .updateMultiplierAccount(this.data.multiplier.id, this.values)
      .subscribe(
        () => {
          this.submitted = false
          this.eventbus.emit(GlobalEvent.MultiplierStatusChanged)
          this.ref.close()
          this.toastService.success(
            'Account gespeichert',
            'Die Änderung wurde erfolgreich gespeichert'
          )
        },
        (error: HttpErrorResponse) => {
          if (error.status === StatusCodes.UNPROCESSABLE_ENTITY) {
            this.toastService.error('Bitte füllen Sie alle Pflichtfelder aus')
          } else {
            this.toastService.error(
              'Etwas ist schief gelaufen...',
              'Bitte wenden Sie sich an den Support'
            )
          }
          this.submitted = false
        }
      )
  }
}
