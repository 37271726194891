import { Injectable } from '@angular/core'
import { HttpClient, HttpParams } from '@angular/common/http'
import { environment } from '../../environments/environment'
import { Observable } from 'rxjs'
import { DocumentUuidModel } from '../models/document/document-uuid.model'

@Injectable({
  providedIn: 'root',
})
export class SendOfferService {
  constructor(private http: HttpClient) {}

  public send(values: any): Observable<DocumentUuidModel> {
    return this.http.post<DocumentUuidModel>(
      `${environment.api}/send-offer/${values.patient_id}`,
      values
    )
  }

  public getOfferMailPreviewLink(values: any): string {
    values.send_documents_json = JSON.stringify(values.send_documents)

    const params = new HttpParams({ fromObject: values }).toString()

    return `${environment.api}/send-offer/preview/mail/${values.patient_id}?${params}`
  }

  public getOfferPdfPreviewLink(values: any): string {
    values.send_documents_json = JSON.stringify(values.send_documents)

    const params = new HttpParams({ fromObject: values }).toString()

    return `${environment.api}/send-offer/preview/pdf/${values.patient_id}?${params}`
  }

  public getMailLink(id: string): string {
    return `${environment.api}/send-offer/offer-mail/${id}`
  }
}
