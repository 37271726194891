<form #form="ngForm" class="dialog-form">
  <!-- Vorhandener Patient -->
  <div
    class="dialog-sidebar"
    *ngIf="values.type === 'CURRENT' && !data.fromCustomer"
  >
    <div class="p-fluid p-formgrid p-grid without-border">
      <div class="p-field p-col-12">
        <label for="patient">Patient * </label>
        <p-autoComplete
          field="full_name"
          inputId="patient"
          name="patient"
          appendTo="body"
          [forceSelection]="true"
          [(ngModel)]="values.patient"
          [suggestions]="patients"
          (completeMethod)="searchPatients($event)"
          [required]="true"
          #patient="ngModel"
          [class.p-invalid]="
            patient.invalid && (patient.dirty || patient.touched)
          "
        >
          <ng-template let-patient pTemplate="item">
            <div class="search-result-data">
              <span class="search-result-name">
                <b>{{ patient.full_name }}</b>
                <span class="search-result-id">#{{ patient.id }}</span>
                <i
                  *ngIf="patient.status"
                  class="p-tag p-tag-rounded p-tag-{{ patient.status }}"
                  >{{ patient.status_string }}</i
                >
              </span>
              <span class="search-result-address">
                {{ patient.zipcode }} {{ patient.city }}
              </span>
            </div>
          </ng-template>
        </p-autoComplete>
      </div>
    </div>

    <div
      class="p-fluid p-formgrid p-grid without-border"
      *ngIf="values.patient"
    >
      <div class="p-field p-col-12">
        <label class="highlight-label">{{ values.patient.full_name }}</label>
        <label class="highlight-label"
          >{{ values.patient.zipcode }} {{ values.patient.city }}</label
        >
        <label class="highlight-label"
          >Telefon: {{ values.patient.phone_1 || "-" }}</label
        >
      </div>
    </div>
  </div>

  <!-- Aus Patient Details aktiviert -->
  <div class="dialog-sidebar" *ngIf="data.fromCustomer">
    <div class="p-fluid p-formgrid p-grid without-border">
      <div class="p-field p-col-12">
        <label class="highlight-label">{{ data.patient.full_name }}</label>
        <label class="highlight-label"
          >{{ data.patient.zipcode }} {{ data.patient.city }}</label
        >
      </div>
    </div>
  </div>

  <!-- Neukunde -->
  <div
    class="dialog-sidebar"
    *ngIf="values.type === 'NEW' && !data.fromCustomer"
  >
    <div class="p-fluid p-formgrid p-grid without-border">
      <div class="p-field p-col-6">
        <label for="first_name">Vorname *</label>
        <input
          id="first_name"
          name="first_name"
          [(ngModel)]="values.first_name"
          type="text"
          pInputText
          #first_name="ngModel"
          required
          [class.p-invalid]="
            first_name.invalid && (first_name.dirty || first_name.touched)
          "
        />
        <div
          *ngIf="first_name.invalid && (first_name.dirty || first_name.touched)"
          class="p-invalid"
        >
          <div *ngIf="first_name.errors?.required">
            <small>Bitte ausfüllen</small>
          </div>
        </div>
      </div>
      <div class="p-field p-col-6">
        <label for="last_name">Nachname *</label>
        <input
          id="last_name"
          name="last_name"
          [(ngModel)]="values.last_name"
          type="text"
          pInputText
          #last_name="ngModel"
          required
          [class.p-invalid]="
            last_name.invalid && (last_name.dirty || last_name.touched)
          "
        />
        <div
          *ngIf="last_name.invalid && (last_name.dirty || last_name.touched)"
          class="p-invalid"
        >
          <div *ngIf="last_name.errors?.required">
            <small>Bitte ausfüllen</small>
          </div>
        </div>
      </div>
    </div>
    <div class="p-fluid p-formgrid p-grid without-border">
      <div class="p-field p-col-4">
        <label for="zipcode">PLZ *</label>
        <input
          id="zipcode"
          name="zipcode"
          [(ngModel)]="values.zipcode"
          type="text"
          pInputText
          #zipcode="ngModel"
          required
          [class.p-invalid]="
            zipcode.invalid && (zipcode.dirty || zipcode.touched)
          "
        />
        <div
          *ngIf="zipcode.invalid && (zipcode.dirty || zipcode.touched)"
          class="p-invalid"
        >
          <div *ngIf="zipcode.errors?.required">
            <small>Bitte ausfüllen</small>
          </div>
        </div>
      </div>
      <div class="p-field p-col-8">
        <label for="city">Ort *</label>
        <input
          id="city"
          name="city"
          [(ngModel)]="values.city"
          type="text"
          pInputText
          #city="ngModel"
          required
          [class.p-invalid]="city.invalid && (city.dirty || city.touched)"
        />
        <div
          *ngIf="city.invalid && (city.dirty || city.touched)"
          class="p-invalid"
        >
          <div *ngIf="city.errors?.required">
            <small>Bitte ausfüllen</small>
          </div>
        </div>
      </div>
    </div>
    <div class="p-fluid p-formgrid p-grid without-border">
      <div class="p-field p-col-12">
        <label for="phone_number">Telefon</label>
        <input
          id="phone_number"
          name="phone_number"
          [(ngModel)]="values.phone_number"
          type="text"
          pInputText
          #phone_number="ngModel"
          [class.p-invalid]="
            phone_number.invalid && (phone_number.dirty || phone_number.touched)
          "
        />
        <div
          *ngIf="
            phone_number.invalid && (phone_number.dirty || phone_number.touched)
          "
          class="p-invalid"
        >
          <div *ngIf="phone_number.errors?.required">
            <small>Bitte ausfüllen</small>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="dialog-form-body">
    <div class="p-fluid p-formgrid p-grid without-border">
      <div class="p-field p-col-12" *ngIf="!data.fromCustomer">
        <div class="p-formgroup-inline">
          <div class="p-field-radiobutton">
            <p-radioButton
              label="Neukunde"
              name="type"
              [(ngModel)]="values.type"
              value="NEW"
            ></p-radioButton>
          </div>
          <div class="p-field-radiobutton">
            <p-radioButton
              label="Vorhandener Patient"
              name="type"
              [(ngModel)]="values.type"
              value="CURRENT"
            ></p-radioButton>
          </div>
        </div>
      </div>

      <div
        style="display: flex; width: 100%"
        *ngFor="let time of values.times; let i = index"
      >
        <div class="p-field p-col-5">
          <label
            [for]="'date' + i"
            style="display: flex; justify-content: space-between"
            >Termin für *
            <div class="p-formgroup-inline">
              <div class="p-field-checkbox" style="margin: 0 !important">
                <p-checkbox
                  [name]="'no-time' + i"
                  [binary]="true"
                  label="Keine Uhrzeit"
                  [(ngModel)]="time.no_time"
                ></p-checkbox>
              </div></div
          ></label>

          <p-calendar
            [inputId]="'date' + i"
            [name]="'date' + i"
            appendTo="body"
            dataType="string"
            dateFormat="dd.mm.yy"
            [firstDayOfWeek]="1"
            [showWeek]="true"
            [(ngModel)]="time.date_at"
            [required]="true"
            #date="ngModel"
            [showButtonBar]="true"
            [class.p-invalid]="date.invalid && (date.dirty || date.touched)"
          ></p-calendar>
        </div>

        <div class="p-field p-col-3" *ngIf="!time.no_time">
          <label [for]="'from' + i">von *</label>
          <p-calendar
            (onBlur)="checkForTimes($event, i, 'time_from')"
            [inputId]="'from' + i"
            [name]="'from' + i"
            [timeOnly]="true"
            appendTo="body"
            [(ngModel)]="time.time_from"
            dataType="string"
            [required]="true"
            #from="ngModel"
            [showButtonBar]="false"
            [class.p-invalid]="from.invalid && (from.dirty || from.touched)"
          ></p-calendar>
        </div>

        <div class="p-field p-col-3" *ngIf="!time.no_time">
          <label [for]="'to' + i">bis *</label>
          <p-calendar
            (onBlur)="checkForTimes($event, i, 'time_to')"
            [inputId]="'to' + i"
            [name]="'to' + i"
            [timeOnly]="true"
            appendTo="body"
            [(ngModel)]="time.time_to"
            dataType="string"
            [required]="true"
            #to="ngModel"
            [showButtonBar]="false"
            [class.p-invalid]="to.invalid && (to.dirty || to.touched)"
          ></p-calendar>
        </div>

        <div
          *ngIf="values.times.length > 1"
          class="p-field p-col-1 remove-position-container"
          style="margin-left: auto"
        >
          <i
            style="margin-top: 20px"
            class="pi pi-times"
            pTooltip="Entfernen"
            (click)="removePosition(time)"
          ></i>
        </div>
      </div>

      <div
        class="p-field p-col-12 add-position-container"
        style="margin-top: -10px"
        *ngIf="!data.isEdit"
      >
        <i
          class="pi pi-plus"
          pTooltip="Termin hinzufügen"
          (click)="addPosition()"
        ></i>
      </div>

      <div class="p-field p-col-12">
        <label for="comment">Bemerkung</label>
        <textarea
          id="comment"
          name="comment"
          [(ngModel)]="values.comment"
          [rows]="3"
          pInputTextarea
        ></textarea>
      </div>

      <div class="p-field p-col-12" *ngIf="data.isEdit">
        <label for="caregiver">Betreuungskraft </label>
        <p-autoComplete
          field="full_name"
          inputId="caregiver"
          name="caregiver"
          appendTo="body"
          [forceSelection]="true"
          [(ngModel)]="values.caregiver"
          [suggestions]="caregivers"
          (completeMethod)="searchCaregivers($event)"
        >
          <ng-template let-caregiver pTemplate="item">
            <div>{{ caregiver.full_name }}</div>
          </ng-template>
        </p-autoComplete>
      </div>
    </div>
  </div>
  <div class="dialog-form-footer">
    <i *ngIf="!data.isEdit"></i>
    <i
      class="pi pi-trash"
      *ngIf="data.isEdit"
      [class.pi-trash]="!submittedDelete"
      [class.pi-spin]="submittedDelete"
      [class.pi-spinner]="submittedDelete"
      tooltipPosition="left"
      tooltipZIndex="20000"
      [pTooltip]="submittedDelete ? 'Wird gelöscht' : 'Wunsch löschen'"
      (click)="remove($event)"
    ></i>

    <button
      (click)="store()"
      pButton
      [label]="data.isEdit ? 'Speichern' : 'Erstellen'"
      type="button"
      [disabled]="submitted"
      [icon]="submitted ? 'pi pi-spin pi-spinner' : 'pi pi-check'"
    ></button>
  </div>
</form>

<p-confirmPopup></p-confirmPopup>
