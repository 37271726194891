import { HttpErrorResponse } from '@angular/common/http'
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core'
import { StatusCodes } from 'http-status-codes'
import { SelectItem } from 'primeng/api'
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog'
import { EventBusService, GlobalEvent } from 'src/app/services/eventbus.service'
import { ToastService } from 'src/app/services/toast.service'
import { DocumentService } from '../../../services/document.service'
import { NgForm } from '@angular/forms'
import { Subscription } from 'rxjs'
import { OfferService } from '../../../services/offer.service'
import { Router } from '@angular/router'
import { CustomerDetailGeneralModel } from '../../../models/customer-patient/customer-detail-general.model'
import { PatientService } from '../../../services/patient.service'

@Component({
  selector: 'app-change-customer-service-dialog',
  templateUrl: './change-customer-service-dialog.component.html',
})
export class ChangeCustomerServiceDialogComponent implements OnInit, OnDestroy {
  @ViewChild('form', { static: true }) form!: NgForm

  private formSubscription: Subscription | null | undefined = null
  private isDirty = false
  public customer: CustomerDetailGeneralModel = new CustomerDetailGeneralModel()

  public submitted = false
  public submittedDelete = false

  public customerServiceOptions = [
    { label: 'Hör', value: 'Hör' },
    { label: 'Müller', value: 'Müller' },
    { label: 'Schorr', value: 'Schorr' },
    { label: 'Harb', value: 'Harb' },
    { label: 'Kohlenberg', value: 'Kohlenberg' },
    { label: 'Petry', value: 'Petry' },
  ]

  public values = {
    comment: '',
    customer_service: '',
  }

  constructor(
    private ref: DynamicDialogRef,
    private config: DynamicDialogConfig,
    private documentService: DocumentService,
    private eventbus: EventBusService,
    private router: Router,
    private patientService: PatientService,
    private toastService: ToastService
  ) {}

  ngOnInit(): void {
    this.customer = this.config.data.customer
    this.values.customer_service = this.customer.customer.customer_service

    this.formSubscription = this.form.valueChanges?.subscribe(() => {
      if (!this.form.pristine) {
        this.isDirty = true
      }
    })
  }

  ngOnDestroy(): void {
    this.formSubscription?.unsubscribe()
  }

  public save(): void {
    if (!this.form.form.valid) {
      this.submitted = false
      this.form.form.markAllAsTouched()
      return
    }

    this.submitted = true

    const subscription = this.patientService.changeCustomerService(
      this.customer.customer.id,
      this.values
    )

    subscription.subscribe(
      () => {
        this.isDirty = false
        this.submitted = false
        this.eventbus.emit(GlobalEvent.CustomerServiceChanged)
        this.ref.close()
        this.toastService.success(
          'Kundenbetreuer geändert',
          'Der Kundenbetreuer wurde erfolgreich geändert'
        )
      },
      (error: HttpErrorResponse) => {
        if (error.status === StatusCodes.UNPROCESSABLE_ENTITY) {
          this.toastService.error('Bitte füllen Sie alle Pflichtfelder aus')
        } else {
          this.toastService.error(
            'Etwas ist schief gelaufen...',
            'Bitte wenden Sie sich an den Support'
          )
        }
        this.submitted = false
      }
    )
  }
}
